import { DateTime } from "luxon";

// Should use new york as banking timezone
export const zone = "America/New_York";

// TODO: Can be improved to be more generic for any holidays on last/first days of month
// but skipping for now given that's not the case for any current banking holidays outside
// new years which has been accounted for.
const holidayDateFactoringInObservedDates = ({
  day,
  month,
  year,
  isLastDayOfYear = false,
}: {
  day: number;
  month: number;
  year: number;
  isLastDayOfYear?: boolean;
}) => {
  const holidayDate = DateTime.fromObject(
    {
      day,
      month,
      year,
    },
    { zone },
  );

  /**
   * Return observed holiday date based of weekday (1-7 where 1 is monday and 7 is sunday)
   */
  switch (holidayDate.weekday) {
    // If it falls on a Sunday, observed on Monday.
    case 7:
      return DateTime.fromObject(
        {
          day: day + 1,
          month,
          year,
        },
        { zone },
      );
    // If it falls on a Saturday, observed on Friday.
    case 6:
      return DateTime.fromObject(
        {
          day: isLastDayOfYear ? 31 : day - 1,
          month: isLastDayOfYear ? 12 : month,
          year: isLastDayOfYear ? year - 1 : year,
        },
        { zone },
      );
    // If it falls on a weekday, observed same date
    default:
      return holidayDate;
  }
};

export const getFirstWeekdayOfMonth = ({
  year,
  month,
  weekday,
}: {
  year: number;
  month: number;
  weekday: number;
}) => {
  const firstDayOfMonth = DateTime.fromObject(
    {
      day: 1,
      month,
      year,
    },
    { zone },
  );

  // First day of month is greater than your weekday
  if (firstDayOfMonth.weekday > weekday) {
    const daysDiff = firstDayOfMonth.weekday - weekday;
    return firstDayOfMonth.plus({ days: 7 - daysDiff });
  }

  // If first day of month is less than or equal to your weekday
  // Then you need to just find the days difference and add to first day
  const daysDiff = weekday - firstDayOfMonth.weekday;
  return firstDayOfMonth.plus({ days: daysDiff });
};

type NthWeekday = 1 | 2 | 3 | 4 | 5;

export const getNthWeekdayOfMonth = ({
  year,
  month,
  weekday,
  nthWeekday,
}: {
  year: number;
  month: number;
  weekday: number;
  nthWeekday: NthWeekday;
}) => {
  const firstWeekdayOfMonth = getFirstWeekdayOfMonth({
    year,
    month,
    weekday,
  });
  const nthInstance = firstWeekdayOfMonth.plus({ days: (nthWeekday - 1) * 7 });
  // First four weeks just add multiple of 7 to get nth instance of weekday
  if (nthWeekday <= 4) {
    return nthInstance;
  }
  // For 5th instance ensure it's in the same month otherwise return 4th instance
  return nthInstance.month === month
    ? nthInstance
    : firstWeekdayOfMonth.plus({ days: 21 });
};

// US BANKING HOLIDAYS
// "New Year's Day", "Martin Luther King Jr. Day", "President's Day",
// "Memorial Day", "Independence Day", "Labor Day", "Columbus Day",
// "Veterans Day", "Thanksgiving Day", "Christmas Day"

// January 1st
export const newYearsDay = (year: number) =>
  holidayDateFactoringInObservedDates({
    day: 1,
    month: 1,
    year,
    isLastDayOfYear: true,
  });

/**
 * Third Monday of January
 */
export const martinLutherKingJrDay = (year: number) =>
  getNthWeekdayOfMonth({
    year,
    month: 1,
    weekday: 1,
    nthWeekday: 3,
  });

/**
 * Third Monday of February
 */
export const presidentsDay = (year: number) =>
  getNthWeekdayOfMonth({
    year,
    month: 2,
    weekday: 1,
    nthWeekday: 3,
  });

/**
 * Last Monday of May
 */
export const memorialDay = (year: number) =>
  getNthWeekdayOfMonth({
    year,
    month: 5,
    weekday: 1,
    nthWeekday: 5,
  });

/**
 * July 4th
 */
export const independenceDay = (year: number) =>
  holidayDateFactoringInObservedDates({ day: 4, month: 7, year });

/**
 * First Monday of September
 */
export const laborDay = (year: number) =>
  getNthWeekdayOfMonth({
    year,
    month: 9,
    weekday: 1,
    nthWeekday: 1,
  });

/**
 * Second Monday of October
 */
export const columbusDay = (year: number) =>
  getNthWeekdayOfMonth({
    year,
    month: 10,
    weekday: 1,
    nthWeekday: 2,
  });

/**
 *
 * November 11th
 */
export const veteransDay = (year: number) =>
  holidayDateFactoringInObservedDates({ day: 11, month: 11, year });

/**
 * Fourth Thursday of November
 */
export const thanksgivingDay = (year: number) =>
  getNthWeekdayOfMonth({
    year,
    month: 11,
    weekday: 4,
    nthWeekday: 4,
  });

/**
 * December 25th
 */
export const christmasDay = (year: number) =>
  holidayDateFactoringInObservedDates({ day: 25, month: 12, year });

export const isUSBankingHoliday = ({
  date,
  year,
}: {
  date: DateTime;
  year: number;
}) =>
  date.equals(newYearsDay(year)) ||
  // Factor in edge case where next new years could be observed in current year
  date.equals(newYearsDay(year + 1)) ||
  date.equals(martinLutherKingJrDay(year)) ||
  date.equals(presidentsDay(year)) ||
  date.equals(memorialDay(year)) ||
  date.equals(independenceDay(year)) ||
  date.equals(laborDay(year)) ||
  date.equals(columbusDay(year)) ||
  date.equals(veteransDay(year)) ||
  date.equals(thanksgivingDay(year)) ||
  date.equals(christmasDay(year));
