// Naming convention for future additions, feature flags should be prefixed
// with a domain identifier, ex: card.[feature-flag-name]

/**
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 */
export default [
  ["employee-editable-categorization", false],
  ["netsuite-batch-entity-settings", false],
  ["qb-migration-homepage", false],
  ["integrations-dd-fields", false],
  ["empower-expense-categories", false],
  ["precompute-integration-lens", "OFF"],
  ["enable-ns-transaction-typed-export-supported", false],
  ["sage-intacct-export-options-settings-supported", false],
  ["integrations-multi-entity-billing", false],
  ["integrations.accrual_basis_reimbursements_phase_1", false],
  ["integrations.qbd-codat-customer-fetch-enabled", false],
  ["integrations.is_ipaas_enabled", false],
  ["integrations.entity-custom-rule-category", false],
  ["netsuite-funding-source-gl-mapping", false],
  ["show-all-merchant-category-gl-account-mappings", false],
] as const;
