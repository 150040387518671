import * as React from "react";
import { AdaContext, defaultData } from "@/features/Ada/contexts/AdaContext";
import type { AdaContextValue } from "@/features/Ada/sharedTypes";

const LazilyInitialedAdaProvider = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LazilyInitializedAda" */
      "@/features/Ada/contexts/LazilyInitializedAda"
    ),
);

export const AdaProvider: React.FC = ({ children }) => {
  const [adaContextData, setAdaContextData] =
    React.useState<AdaContextValue>(defaultData);
  const [showProvider, setShowProvider] = React.useState(false);

  const value = React.useMemo(
    () => ({
      ...adaContextData,
      initializeProvider: () => {
        setShowProvider(true);
      },
    }),
    [adaContextData],
  );

  return (
    <AdaContext.Provider value={value}>
      <React.Suspense fallback={null}>
        {showProvider && (
          <LazilyInitialedAdaProvider onContextChange={setAdaContextData} />
        )}
      </React.Suspense>
      {children}
    </AdaContext.Provider>
  );
};
