import { datadogLogs } from "@datadog/browser-logs";
import * as Sentry from "@sentry/browser";
import React from "react";
import type { JsonObject } from "type-fest";
import { useControllerPerformanceContext } from "@/domains/App/components/Controller/ControllerContext";
import { logRocketConsole } from "@/features/Analytics/helpers/logRocket";
import { internalTrackError } from "@/helpers/errorTracking";

const logLevels = ["debug", "info", "warn"] as const;
type LogLevel = (typeof logLevels)[number];

/**
 * Collect logs in third party services
 *
 * Use this function directly if
 * collecting logs in functions not
 * exposed externally to controllers, components, or hooks.
 *
 * const logger = createLogger()
 * logger.info(msg, {})
 *
 * @param message Custom log name
 * @param context Optional log context
 */
export const createLogger = () => {
  const sendLog = (level: LogLevel, message: string, context?: JsonObject) => {
    try {
      // send to datadog
      datadogLogs.logger[level](message, context);

      // send to logrocket
      logRocketConsole[level](message, context);

      // send to sentry
      Sentry.addBreadcrumb({
        category: "logger",
        level: level as Sentry.SeverityLevel,
        message,
        data: context,
      });
    } catch (err) {
      internalTrackError({
        errorName: "Failed to send log",
        error: err as Error,
      });
    }
  };

  return logLevels.reduce(
    (acc, level) => ({
      ...acc,
      [level]: (message: string, context?: JsonObject) =>
        sendLog(level, message, context),
    }),
    {} as Record<LogLevel, (message: string, context?: JsonObject) => void>,
  );
};

/**
 * Hook for submitting logs to third party services
 *
 * This hook automatically adds controller context to the log.
 * Use this for collecting logs within controllers and components.
 *
 * const logger = useLogger()
 * logger.info(msg, {})
 *
 * @param message Custom log name
 * @param context Optional log context
 */
export const useLogger = () => {
  const { ancestorControllerNames, controllerName, controllerSessionId } =
    useControllerPerformanceContext();

  const logger = React.useMemo(() => createLogger(), []);

  return React.useMemo(
    () =>
      Object.keys(logger).reduce(
        (acc, level) => ({
          ...acc,
          [level]: (message: string, properties?: JsonObject) =>
            logger[level as keyof typeof logger](message, {
              ...properties,
              context: {
                ancestorControllerNames,
                controllerName,
                controllerSessionId,
              },
            }),
        }),
        {} as typeof logger,
      ),
    [ancestorControllerNames, controllerName, controllerSessionId, logger],
  );
};
