import { ErrorType } from "@/data/sharedTypes";

export const transformUnauthenticatedErrorType = (error: string) => {
  switch (error) {
    case "user_disabled":
    case "user_deleted":
      return ErrorType.LOGIN_USER_DISABLED;
    case "account_disabled":
      return ErrorType.LOGIN_ACCOUNT_DISABLED;
    case "account_rejected":
      return ErrorType.LOGIN_ACCOUNT_REJECTED;
    default:
      return null;
  }
};
