import type { GenerateSpotnanaOauthToken } from "@/domains/Travel/data/__generated__/mutations.generated";
import { generateSpotnanaOauthToken } from "@/domains/Travel/data/mutations";
import type { ApolloError } from "@/features/Apollo";
import { useMutation } from "@/features/Apollo";

export const useGenerateSpotnanaOauthToken = ({
  onCompleted,
  onError,
}: {
  onCompleted?: (payload: GenerateSpotnanaOauthToken) => void;
  onError?: (error: ApolloError) => void;
}) =>
  useMutation<GenerateSpotnanaOauthToken>(generateSpotnanaOauthToken, {
    onCompleted,
    onError,
  });
