import * as React from "react";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { UnauthorizedLayoutLoading } from "@/features/UnauthorizedLayout/components/UnauthorizedLayoutLoading";

export const UnauthorizedLayoutLoadingController: React.FC = () => (
  <UnauthorizedLayoutLoading />
);

export default withControllerPerformanceMetrics(
  UnauthorizedLayoutLoadingController,
);
