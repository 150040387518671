import { useAtomValue } from "jotai";
import * as React from "react";
import type { SeenFlagKey } from "@/__generated__/globalTypes";
import {
  refetchSeenFlagsAtom,
  seenFlagsHasErrorAtom,
  seenFlagsLoadingAtom,
  seenFlagsMapAtom,
  seenFlagsMapAtomWithoutSuspense,
} from "@/features/SeenFlag/atoms/seenFlagsAtoms";
import type { SeenFlags } from "@/features/SeenFlag/sharedTypes";
import { useDeepMemo } from "@/hooks/useDeepMemo";

export interface SeenFlagsHookOptions {
  suspense?: boolean;
}

const defaultOptions: SeenFlagsHookOptions = {
  suspense: window.localStorage.getItem("SEEN_FLAGS_SUSPENSE") !== "false",
};

/**
 * Hook to get the values of multiple seenFlags
 */
export const useSeenFlags = (
  keys: SeenFlagKey[],
  { suspense = defaultOptions.suspense }: SeenFlagsHookOptions = {},
) => {
  const isLoading = useAtomValue(seenFlagsLoadingAtom);
  const hasError = useAtomValue(seenFlagsHasErrorAtom);
  const seenFlagsMap = useAtomValue(
    suspense ? seenFlagsMapAtom : seenFlagsMapAtomWithoutSuspense,
  );
  const { refetch: seenFlagsRefetch } = useAtomValue(refetchSeenFlagsAtom) ?? {
    refetch: async () => {},
  };

  const stableKeys = useDeepMemo(keys);
  const providerSeenFlags = React.useMemo(() => {
    return stableKeys.reduce((seenFlagObj, seenFlag) => {
      return {
        ...seenFlagObj,
        [seenFlag]: seenFlagsMap.get(seenFlag),
      };
    }, {} as SeenFlags);
  }, [stableKeys, seenFlagsMap]);

  return {
    seenFlags: providerSeenFlags,
    isLoading,
    hasError,
    refetch: seenFlagsRefetch,
  };
};
