export const selectFundingSourceParam = "selectFundingSource";
export const addBankAccountsParam = "addBankAccounts";
export const addExternalAccountParam = "addExternalAccount";
export const removeBankConnectionParam = "removeBankConnectionId";
export const removeBankAccountParam = "removeBankAccountId";
export const fixBankConnectionParam = "fixBankConnectionId";
export const reverifyParam = "reverifyId";
export const connectedBankConnectionIdParam = "connectedFinancialSourceId";
export const reverifyBankAccountParam = "reverifyBankAccountId";
export const verifyAllowListParam = "verifyAllowList";
export const addBankConnectionParam = "addBankConnection";
export const fixBankConnectionsParam = "fixBankConnections";
export const migrateToTellerParam = "migrateToTeller";
export const addManualBankAccountParam = "addManualBankAccount";
export const addUnderwritingAccount = "addUnderwritingAccount";
export const addCashAccountParam = "addCashAccount";
export const selectReimbursementParam = "selectReimbursement";
export const billingGroupIdParam = "billingGroupId";
export const verifyProofOfAccountOwnershipParam =
  "verifyProofOfAccountOwnership";
export const financialAccountIdParam = "financialAccountId";
export const migrateConditionParam = "migrateCondition";
export const localAutopayNewAccountParam = "localAutopayNewAccount";
export const localAutopayVerifyPennyPullParam = "localAutopayVerifyPennyPull";
export const localAutopayVerifyMicroDepositsParam =
  "localAutopayVerifyMicroDeposits";
export const localAutopayVerifyPoaoParam = "localAutopayVerifyPoao";
export const creditPolicyWarningParam = "creditPolicyWarning";
