import * as React from "react";
import { useLocation } from "react-router-dom";
import { UnauthorizedLayout } from "./components/UnauthorizedLayout";
import type { UnauthorizedLayoutProps } from "./components/UnauthorizedLayout";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { useSetInitialLanguageUnauthenticated } from "@/domains/App/features/Localization/hooks/useSetInitialLanguage";
import { useHelpshift } from "@/features/Helpshift/hooks/useHelpshift";
import { UnauthorizedLayoutLoading } from "@/features/UnauthorizedLayout/components/UnauthorizedLayoutLoading";
import { urls } from "@/helpers/urls";

export const UnauthorizedLayoutController: React.FC<
  UnauthorizedLayoutProps
> = ({
  secondaryAction,
  secondaryActionMessage,
  primaryLayout,
  preFooter,
  children,
}) => {
  const { setCustomStyles, setShowLauncher } = useHelpshift();
  const { pathname } = useLocation();

  React.useEffect(() => {
    // disable custom styles
    setCustomStyles(false);
    // We don't want to show the default helpshift launcher
    // during onboarding. Onboarding has a personialized concerige helpshift
    // app that is used instead. The configuration is in the useConceirgeHelpshift file.
    // https://brexhq.atlassian.net/browse/GROWONB-985
    if (pathname !== urls.onboarding.productApplication()) {
      // show the Helpshift launcher
      setShowLauncher(true);
    }
    return () => {
      // re-enable custom styles
      setCustomStyles(true);
      // hide the launcher
      setShowLauncher(false);
    };
  }, [pathname, setCustomStyles, setShowLauncher]);
  // hide header and footer when on web browser in native mobile app.

  const { initialLanguageLoaded } = useSetInitialLanguageUnauthenticated();

  if (!initialLanguageLoaded) {
    return <UnauthorizedLayoutLoading />;
  }

  return (
    <UnauthorizedLayout
      secondaryAction={secondaryAction}
      secondaryActionMessage={secondaryActionMessage}
      primaryLayout={primaryLayout}
      preFooter={preFooter}
      showLanguageSwitcher
    >
      {children}
    </UnauthorizedLayout>
  );
};

export default withControllerPerformanceMetrics(UnauthorizedLayoutController);
