import * as React from "react";
import type { HelpshiftContextValue } from "@/features/Helpshift/sharedTypes";
import noOp from "@/helpers/noOp";

export const defaultData = {
  contactSupport: noOp,
  identify: noOp,
  isAdaReady: false,
  isHelpshiftReady: false,
  replaceCustomIssueFields: noOp,
  setCustomIssueFields: noOp,
  setCustomStyles: noOp,
  setShowLauncher: () => Promise.resolve(),
  updateConfig: noOp,
  unread: false,
  customStyles: false,
  initializeProvider: noOp,
};
export const HelpshiftContext =
  React.createContext<HelpshiftContextValue>(defaultData);
