import * as React from "react";
import { TrackedErrorBoundary } from "@/components/Error";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import AuthenticatedContexts from "@/domains/App/contexts/Authenticated";
import { SkeletonLoadersProvider } from "@/domains/App/contexts/SkeletonLoadersProvider";
import AuthenticationController from "@/features/Authentication/AuthenticationController";
import { ComplianceProvider } from "@/features/Compliance/contexts/Compliance";
import { StatsigProvider } from "@/features/Statsig/contexts/Statsig";

const SessionTimeoutModalController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SessionTimeoutModalController" */
      "@/features/SessionTimeoutModal/SessionTimeoutModalController"
    ),
);

/**
 * DO NOT ADD ANY LOGIC!!!
 *
 * If you need to run code post-authentication, but before rendering the
 * Dashboard, add it to the AuthenticatedRouter component in the App domain.
 */
const AuthenticatedRouterController = () => (
  <TrackedErrorBoundary __DROP_TRACK_ORIGIN="AuthenticatedRouterController">
    <AuthenticationController>
      <SkeletonLoadersProvider>
        {/* ADD PROVIDERS AND COMPONENTS HERE THAT REQUIRE AUTHENTICATION */}
        <StatsigProvider>
          <ComplianceProvider>
            <AuthenticatedContexts />

            <React.Suspense fallback={null}>
              <SessionTimeoutModalController />
            </React.Suspense>
          </ComplianceProvider>
        </StatsigProvider>
      </SkeletonLoadersProvider>
    </AuthenticationController>
  </TrackedErrorBoundary>
);

export default withControllerPerformanceMetrics(AuthenticatedRouterController);
