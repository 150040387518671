import { useCallback, useRef } from "react";

/**
 * useRefCallback is not safe when used to create Render Function props,
 * or in other cases where side effects depend on the reference changing.
 */
export function useRefCallback<T extends React.ReactElement>(
  callback: (...args: any[]) => T,
): unknown;

/**
 * An alternative to `useCallback` that is always referentially the same and is always up to date.
 * It achieves that by storing the latest received callback in a ref, and returning a wrapper
 * function that invokes the callback in the ref.
 */
export function useRefCallback<Fn extends Function>(callback: Fn): Fn;

export function useRefCallback<Args extends unknown[], Ret>(
  callback: (...args: Args) => Ret,
): (...args: Args) => Ret {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  return useCallback((...args: Args) => callbackRef.current(...args), []);
}
