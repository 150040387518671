import type { ManageAutopayAccount } from "@/features/ManageBankAccounts/sharedTypes";

export type RenderComponent = () => string | JSX.Element | null;

export type ComponentRenderer = {
  renderSecondaryContent?: RenderComponent;
  renderContent?: RenderComponent;
  renderHeader?: RenderComponent;
  renderActions?: () => JSX.Element[] | null;
};
export interface SelectFundingSourceMachineContext {
  fundingSources: ManageAutopayAccount[];
  selectedFundingSourceId: string;
}

export enum SelectFundingSourceMachineStates {
  selectFundingSource = "selectFundingSource",
  exitToPrevious = "exitToPrevious",
  final = "final",
}

export enum SelectFundingSourceStates {
  idle = "idle",
  loading = "loading",
  error = "error",
  setPrimaryFundingSource = "setPrimaryFundingSource",
  verifyAllowList = "verifyAllowList",
}

export enum SelectFundingSourceMachineEvents {
  nextStep = "nextStep",
  prevStep = "prevStep",
  selectFundingSourceOption = "selectFundingSourceOption",
  chooseFundingSourceOption = "chooseFundingSourceOption",
}

export type SelectFundingSourceMachineEvent =
  | { type: SelectFundingSourceMachineEvents.nextStep }
  | { type: SelectFundingSourceMachineEvents.prevStep }
  | {
      type: SelectFundingSourceMachineEvents.selectFundingSourceOption;
      fundingSourceId: string;
    }
  | {
      type: SelectFundingSourceMachineEvents.chooseFundingSourceOption;
    };

export const AnalyticsEvents = {
  OnboardingSelectFundingSourceState:
    "underwriting.select_funding_source.state",
} as const;

type EmptyPayload = {};

export type EventSchemaMap = {
  [AnalyticsEvents.OnboardingSelectFundingSourceState]: EmptyPayload;
};
