import { useStore } from "jotai";
import * as React from "react";
import { DefaultError, TrackedErrorBoundary } from "@/components/Error";
import { FullScreenLoadingPage } from "@/components/FullScreenLoadingPage";
import { getRefreshAtom } from "@/features/Jotai";

export type RenderError = (options: {
  retry: () => void;
  retries: number;
  error: any;
}) => React.ReactElement;

type QueryBoundaryProps = {
  /* A function to return the JSX element to display as the suspense fallback. Renders FullScreenLoadingPage by default.  */
  renderLoading?: () => React.ReactElement | null;
  /* Whether to catch the error using TrackedErrorBoundary or not. Default value is `true`. */
  children: React.ReactNode;
} & (
  | { catchError: false }
  | ({
      catchError?: never | true;
      /* A function to return the JSX element to display when an error is thrown. Renders DefaultError by default.  */
      renderError?: RenderError;
    } & Partial<
      Pick<
        React.ComponentProps<typeof TrackedErrorBoundary>,
        "onError" | "__DROP_TRACK_ORIGIN"
      >
    >)
);

export const QueryBoundary = ({
  renderLoading = () => <FullScreenLoadingPage />,
  children,
  ...rest
}: QueryBoundaryProps) => {
  const [retries, setRetries] = React.useState(0);
  const store = useStore();

  const retry = React.useCallback(
    (error: Error) => {
      const refreshAtom = getRefreshAtom(error);
      if (refreshAtom) {
        store.set(refreshAtom, store.get(refreshAtom) + 1);
      }

      setRetries((prev) => prev + 1);
    },
    [store],
  );

  const baseRenderError = "renderError" in rest ? rest.renderError : undefined;
  const renderError = React.useCallback<RenderError>(
    (params) => {
      if (baseRenderError) {
        return baseRenderError(params);
      }

      return (
        <DefaultError
          onClickRetry={params.retries < 3 ? params.retry : undefined}
        />
      );
    },
    [baseRenderError],
  );

  const customRenderError = React.useCallback(
    ({ resetError, error }) =>
      renderError({
        retries,
        error,
        retry: () => {
          retry(error);
          resetError();
        },
      }),
    [renderError, retries, retry],
  );

  const suspenseContent = (
    <React.Suspense fallback={renderLoading()}>{children}</React.Suspense>
  );

  if (rest.catchError !== false) {
    return (
      <TrackedErrorBoundary
        __DROP_TRACK_ORIGIN={rest.__DROP_TRACK_ORIGIN}
        onError={rest.onError}
        renderError={customRenderError}
      >
        {suspenseContent}
      </TrackedErrorBoundary>
    );
  }

  return suspenseContent;
};
