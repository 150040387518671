import type {
  ApprovalsReviewStatus,
  DistanceUnit,
  ProductType,
} from "@/__generated__/globalTypes";
import type { ReimbursementSetupFlowSteps } from "@/domains/Spend/features/RequestsSetupFlow/features/ReimbursementsSetup/sharedTypes";

export enum AnalyticsEvents {
  BulkSelectModalMounted = "bulk_select.modal.mounted",
  ExpensesManageSettingsClicked = "expenses.manage_settings.clicked",
  ExpensesManageSettingsTakeOverSaved = "expenses.manage_settings_take_over.saved",
  ExpensesManageSettingsTakeOverClosed = "expenses.manage_settings_take_over.closed",
  ExpensesPolicyPageMounted = "expenses.policy_page.mounted",
  ExpensesPolicyPageDetailsOpen = "expenses.policy_page.details_opened",
  ExpensesPolicyPageDismissItem = "expenses.policy_page.details_dismiss_item",
  ExpensesPolicyPageUndoDismiss = "expenses.policy_page.undo_dimiss",
  ExpensesPolicyPageFollowUpClicked = "expenses.policy_page.follow_up_clicked",
  ExpensesReviewPageEmptyMounted = "expenses.review_page.empty_mounted",
  ExpensesReviewPageNoPolicyEmptyMounted = "expenses.review_page.no_policy_empty_mounted",
  ExpensesReviewPageEmptyCreatePolicyClicked = "expenses.review_page.empty_create_policy_clicked",
  ExpensesReviewPageDateRangeChanged = "expenses.review_page.date_range.changed",
  ExpensesReviewPageDirectReportsFilterChanged = "expenses.review_page.direct_reports_filters.changed",
  ExpensesReviewPageIssuesFilterChanged = "expenses.review_page.issues_filter.changed",
  ExpensesSpendAlertsSectionEmptyMounted = "expenses.spend_alerts_section.empty_mounted",
  ExpensesTableSeeMoreClicked = "expenses.table.see_more.clicked",
  ExpensesTableSortClicked = "expenses.table.sort.clicked",
  ExpensesTableRowSelectClicked = "expenses.table.row_select.clicked",
  ExpensesTableIssuesPillHovered = "expenses.table.issues_pill.hovered",
  ExpensesOutOfPolicySectionEmptyMounted = "expenses.out_of_policy_section.empty_mounted",
  ExpensesOutOfPolicySectionNoPolicyMounted = "expenses.out_of_policy_section.no_policy_mounted",
  ExpensesOutOfPolicySectionPausedPolicyMounted = "expenses.out_of_policy_section.paused_policy_mounted",
  ExpensesOutOfPolicySectionCreatePolicyClicked = "expenses.out_of_policy_section.create_policy_clicked",
  ExpensesOutOfPolicySectionEnablePolicyClicked = "expenses.out_of_policy_section.enable_policy_clicked",
  ExpensesAddToSlackClicked = "expenses.add_to_slack.clicked",
  ExpensesBeforeAddingSlackMounted = "expenses.before_adding_slack.mounted",
  ExpensesBeforeAddingSlackSetupPolicyClicked = "expenses.before_adding_slack.setup.clicked",
  ExpensesViewPolicyClicked = "expenses.view_policy_clicked",
  ExceptionsCreateButtonClicked = "exceptions.modal_opened",
  ExceptionCreated = "exception.created",
  ExceptionDeleted = "exception.deleted",
  MileageSettingsMounted = "mileage.settings.mounted",
  MileageSettingsErrorMounted = "mileage.settings.error.mounted",
  MileageSettingsSaveClicked = "mileage.settings.save.clicked",
  MileageSettingsDiscardClicked = "mileage.settings.discard.clicked",
  MileageSettingsAddRateClicked = "mileage.settings.add_rate.clicked",
  MileageSettingsCountrySelected = "mileage.settings.country.selected",
  MileageSettingsDistanceUnitChanged = "mileage.settings.distance_unit.changed",
  ReimbursementAddPaymentSubmitClicked = "reimbursement.add_payment_submit.clicked",
  ReimbursementSaveAsDraftClicked = "reimbursement.save_as_draft.clicked",
  ReimbursementCloseCreateModal = "reimbursement.close_create_modal_and_save",
  ReimbursementSubmitClicked = "reimbursement.submit.clicked",
  ReimbursementSubmitDraftClicked = "reimbursement.submit_draft.clicked",
  ReimbursementSubmitDraftSuccess = "reimbursement.submit_draft.success",
  ReimbursementModifyClicked = "reimbursement.modify.clicked",
  ReimbursementApproveClicked = "reimbursement.approve.clicked",
  ReimbursementDenyClicked = "reimbursement.deny.clicked",
  ReimbursementBulkApproveClicked = "reimbursement.bulk_approve.clicked",
  ReimbursementBulkDenyClicked = "reimbursement.bulk_deny.clicked",
  ReimbursementNewReimbursementClicked = "reimbursement.new_reimbursement.clicked",
  ReimbursementEditBankAccountClicked = "reimbursement.edit_bank_account.clicked",
  ReimbursementEmptyStateSubmitNewExpenseClicked = "reimbursement.empty_state_submit_new_expense.clicked",
  ReimbursementBulkInviteClicked = "reimbursement.bulk_invite.clicked",
  ReimbursementBulkInviteSkipClicked = "reimbursement.bulk_invite.skip.clicked",
  ReimbursementCSVExportCreate = "reimbursement.csv_export.created",
  ReimbursementCSVExportDownloadClicked = "reimbursement.csv_export.download",
  RequestsPageMounted = "preapproval.reviews.mounted",
  RequestsPageSetupMounted = "requests.setup.mounted",
  RequestsPageSetupClicked = "requests.setup.clicked",
  RequestsReviewRequest = "preapproval.review_request",
  RequestsReviewRequestDetailsClicked = "requests.pending_details.clicked",
  RequestsReviewRequestDetailsMounted = "requests.pending_details.mounted",
  RequestsReviewManageCardClicked = "requests.manage_card",
  RequestsReviewManageUserClicked = "requests.manage_user",
  RequestsVendorCardTakeover = "preapproval.takeover.origin",
  RequestsModifyVendorCardRequestMounted = "requests.modify.vendor_card.mounted",
  RequestsModifyVendorCardRequestSuccess = "requests.modify.vendor_card.success",
  RequestsModifyUserLimitIncreaseRequestMounted = "requests.modify.user_limit_increase.mounted",
  RequestsModifyUserLimitIncreaseRequestSuccess = "requests.modify.user_limit_increase.success",
  RequestsApprovedUserLimitIncreaseTakeoverMounted = "requests.approved_user_limit_increase.mounted",
  RequestsApprovedUserLimitIncreaseClicked = "requests.approved_user_limit_increase.clicked",
  RequestsPendingUserLimitIncreaseClicked = "requests.pending_user_limit_increase.clicked",
  RequestsPendingUserLimitIncreaseTakeoverMounted = "requests.pending_user_limit_increase.mounted",
  RequestsPendingUserLimitIncreaseCanceled = "requests.pending_user_limit_increase.canceled",
  RequestsPrimaryApproverUpdated = "primary_approver.updated",
  RequestsSetupMounted = "requests.setup.modal.mounted",
  RequestsSetupComplete = "requests.setup.complete",
  RequestsEmailCompany = "requests.email_company",
  SpendAlertsAlertOpened = "spend_alerts.alert_details.opened",
  SpendAlertsAlertClosed = "spend_alerts.alert_details.closed",
  SpendAlertsChartHover = "spend_alerts.chart.hover",
  SpendAlertsFollowUpOpened = "spend_alerts.follow_up.opened",
  SpendAlertsFollowUpClosed = "spend_alerts.follow_up.closed",
  SpendAlertsFollowUpSubmitted = "spend_alerts.follow_up.submitted",
  SpendAlertsMarkReviewedOpened = "spend_alerts.mark_reviewed.opened",
  SpendAlertsMarkReviewedClosed = "spend_alerts.mark_reviewed.closed",
  SpendAlertsMarkReviewedSubmitted = "spend_alerts.mark_reviewed.submitted",
  BulkSpendAlertsMarkReviewedSubmitted = "spend_alerts.bulk_mark_reviewed.submitted",
  BulkSpendOutOfPolicyMarkReviewedSubmitted = "expenses.out_of_policy_section.bulk_mark_reviewed.submitted",
  BulkSpendOutOfPolicyFollowUpSubmitted = "expenses.out_of_policy_section.bulk_follow_up.submitted",
  SpendAlertsOnboardingComplete = "spend_alerts.onboarding.complete",
  SpendAlertsSeeTransactionsClicked = "spend_alerts.see_transactions.clicked",
  SpendAlertsTableRendered = "spend_alerts.table.rendered",
  SpendAlertsTableColumnSorted = "spend_alerts.table.column.sorted",
  SpendAlertsTableSeeMoreClicked = "spend_alerts.table.see_more.clicked",
  SpendAlertsTableInfoPillHovered = "spend_alerts.table.info_pill.hovered",
  SpendAlertsTableRowSelectClicked = "spend_alerts.table.row_select.clicked",
  SpendAlertsUndoReviewSubmitted = "spend_alerts.undo_review.submitted",
  SpendAlertsCreateExceptionOpened = "spend_alerts.create_exception.opened",
  SpendAlertsCreateExceptionClosed = "spend_alerts.create_exception.closed",
  SpendAlertsCreateExceptionSubmitted = "spend_alerts.create_exception.submitted",
  SpendAlertsAlertCountLoaded = "spend_alerts.alert_count.loaded",
  SpendAlertsExceptionsTableMounted = "spend_alerts.exceptions_table.mounted",
  SpendRequestsSettingsSetupClicked = "spend_requests.settings_setup.clicked",
  SpendRequestsSettingsTurnOffClicked = "spend_requests.settings_turn_off.clicked",
  SpendRequestsSettingsTurnOffConfirmClicked = "spend_requests.settings_turn_off_confirm.clicked",
  PremiumSettingsActivateExpensePolicy = "premium.settings.activate_policy.clicked",
  UnifiedRequestsSetupModalChooseRequestTypeMounted = "unified_requests.setup.modal.choose_request_type.mounted",
  UnifiedRequestsSetupModalChooseCardRequestsClicked = "unified_requests.setup.modal.choose_request_type.card_requests.clicked",
  UnifiedRequestsSetupModalChooseReimbursementsClicked = "unified_requests.setup.modal.choose_request_type.reimbursements.clicked",
  UnifiedRequestsReimbursementsSetupModalMounted = "unified_requests.reimbursements.setup.modal.mounted",
  UnifiedRequestsReimbursementsSetupComplete = "unified_requests.reimbursements.setup.complete",
  UnifiedRequestsCardRequestsOptOutClicked = "unified_requests.card_requests.opt_out.clicked",
  UnifiedRequestsCardRequestsOptOutComplete = "unified_requests.card_requests.opt_out.complete",
  UnifiedRequestsReimbursementsSettingsModalMounted = "unified_requests.reimbursements.settings.modal.mounted",
  UnifiedRequestsReimbursementsUpdateAccountClicked = "unified_requests.reimbursements.update_account.clicked",
  UnifiedRequestsReimbursementsOptOutClicked = "unified_requests.reimbursements.opt_out.clicked",
  UnifiedRequestsReimbursementsOptOutComplete = "unified_requests.reimbursements.opt_out.complete",
  UnifiedRequestsEmptyPageEnableRequestsClicked = "unified_requests.requests_page.requests.enable.clicked",
  UnifiedRequestsRequestsPageEnableCardRequestsClicked = "unified_requests.requests_page.card_requests.enable.clicked",
  UnifiedRequestsRequestsPageDismissCardRequestsClicked = "unified_requests.requests_page.card_requests.dismiss.clicked",
  UnifiedRequestsRequestsPageEnableReimbursementsClicked = "unified_requests.requests_page.reimbursements.enable.clicked",
  UnifiedRequestsRequestsPageDismissReimbursementsClicked = "unified_requests.requests_page.reimbursements.dismiss.clicked",
  UnifiedRequestsApprovalRulesEditorMounted = "unified_requests.approval_rules_editor.mounted",
  UnifiedRequestsApprovalRulesEditorChangeProductTypeClicked = "unified_requests.approval_rules_editor.change_product_type.clicked",
  UnifiedRequestsApprovalRulesEditorAddNewRuleClicked = "unified_requests.approval_rules_editor.rule.add",
  UnifiedRequestsApprovalRulesEditorDeleteRuleClicked = "unified_requests.approval_rules_editor.rule.delete",
  UnifiedRequestsApprovalRulesEditorAddRuleStep = "unified_requests.approval_rules_editor.rule.step.add",
  UnifiedRequestsApprovalRulesEditorDeleteRuleStep = "unified_requests.approval_rules_editor.rule.step.delete",
  UnifiedRequestsApprovalRulesEditorSetStepUsers = "unified_requests.approval_rules_editor.rule.step.users.set",
  UnifiedRequestsApprovalRulesEditorSetRuleAmount = "unified_requests.approval_rules_editor.rule.amount.set",
  UnifiedRequestsApprovalRulesEditorCancelEditClicked = "unified_requests.approval_rules_editor.cancel_edit.clicked",
  UnifiedRequestsApprovalRulesEditorSubmitPolicy = "unified_requests.approval_rules_editor.submit.clicked",
  UnifiedRequestsCardRequestReviewComplete = "unified_requests.card_requests.review.complete",
  UnifiedRequestsLimitIncreaseReviewComplete = "unified_requests.limit_increase.review.complete",
  UnifiedRequestsReimbursementReviewComplete = "unified_requests.reimbursement.review.complete",
  UnifiedRequestsBulkApproveClicked = "unified_requests.bulk_approve.clicked",
  UnifiedRequestsBulkDenyClicked = "unified_requests.bulk_deny.clicked",
}

type EmptyPayload = {};

type ExpensesPolicyPageUndoDismiss = {
  transactionId: string;
  currentTab: string;
};

type ExpensesPolicyPageDismissItem = {
  transactionId: string;
  from: string;
  keyboardShortcutUsed: boolean;
  hideModalForFuture?: any;
  currentTab: string;
};

type ExpensesPolicyPageFollowUpClicked = {
  transactionId: string;
  message: string;
  currentTab: string;
};

type ExpensesPolicyPageDetailsOpen = {
  transactionId: string;
  currentTab: string;
};

type ExpensesPolicyPageMounted = {
  pageType: "company" | "team" | "user";
};

type ExpensesAddToSlackClicked = {
  source: "banner" | "settings" | "integrations";
};

type ExceptionPayload = {
  exceptionType: string;
  exceptionValue: string | number;
};

type ExpensesTableSortClicked = {
  column?: string;
  direction?: string;
};

type ExpensesTableRowSelectClicked = {
  isSelecting: boolean;
};

type ExpensesReviewPageDateRangeChanged = {
  start?: string;
  end?: string;
};

type ExpensesReviewPageDirectReportsFilterChanged = {
  value: string;
};

type ExpensesReviewPageIssuesFilterChanged = {
  value: string;
};

type ExpensesTableIssuesPillHovered = {
  transactionId: string;
};

type CardPayload = {
  cardId?: string | null;
};

type UserIdPayload = {
  userId: string;
};

type UnifiedRequestsReimbursementSetupModal = {
  step: ReimbursementSetupFlowSteps;
};

type UnifiedRequestsReimbursementsSetupComplete = {
  isBrexSelected: boolean;
  externalBankEnabled: boolean;
};

type ReimbursementSubmitDraftSuccessPayload = {
  reimbursementId: string;
};

type RequestsPage = {
  tab: string;
};

type RequestsPrimaryApproverUpdated = {
  origin: string;
  oldApprover: string;
  newApprover: string;
};

type RequestsVendorCardTakeover = {
  origin: string;
  type: string;
};

type ModifyVendorCardPayload = {
  requestId?: string;
  newReason?: string;
  newDisplayName?: string;
  newAmount?: number;
  newAutoLockDate?: string;
};

type ModifyUserLimitIncreaseRequestPayload = {
  requestId: string;
  newAmount?: string;
  newEndsAt?: string;
  newReason?: string;
};

type RequestsPendingUserLimitIncreaseTakeoverMounted = {
  step: string;
  requestId: string;
};

type RequestsReviewRequest = {
  action: ApprovalsReviewStatus;
  reviewId: string;
};

type RequestsReviewRequestDetailsPayload = {
  reviewId?: string;
};

type SpendAlertsBasePayload = {
  alertId: string | null;
  currentTab?: string;
};

type SpendAlertsAlertCountLoadedPayload = {
  count: number;
};

type SpendAlertsTableRendered = {
  rowCount: number;
};

type SpendAlertsTableColumnSorted = {
  column?: string;
  direction?: string;
};

type SpendAlertsTableInfoPillHovered = {
  alertId: string;
};

type SpendAlertsTableRowSelectClicked = {
  isSelecting: boolean;
};

type SpendAlertsMarkReviewedSubmitted = {
  alertId: string;
  reason: string | null;
  description?: string;
};

type BulkSpendAlertsMarkReviewedSubmitted = {
  alertIds: string[];
  reason?: string | null;
  description?: string;
  currentTab: string;
  count: number;
};

type BulkSpendOutOfPolicyMarkReviewedSubmitted = {
  insightIds: string[];
  description?: string;
  currentTab: string;
  count: number;
};

type SpendAlertsFollowUpSubmitted = {
  alertId: string;
  comment: string;
};

type BulkSpendOutOfPolicyFollowUpSubmitted = {
  insightIds: string[];
  description: string;
  currentTab: string;
  count: number;
};

type SpendAlertsCreateExceptionSubmitted = {
  alertId: string;
  insightType: string;
  muteColumn: string;
  muteValue: string;
};

type BulkSelectModalMounted = {
  numSelected: number;
};

type ApprovalRulesEditorChangeProductTypePayload = {
  productType: ProductType;
};

type ApprovalRulesEditorSetStepUsersPayload = {
  numberOfApprovers: number;
};

type UnifiedRequestsBulkApproveClickedPayload = {
  numberOfRequests: number;
};
type UnifiedRequestsBulkDenyClickedPayload = {
  numberOfRequests: number;
};

type MileageSettingsPayload = {
  numberOfCountries: number;
};

type MileageDistanceUnitChangedPayload = {
  countryCode: string;
  distanceUnit: DistanceUnit;
};

type MileageCountrySelectPayload = {
  countryCode: string;
};

export type EventSchemaMap = {
  [AnalyticsEvents.BulkSelectModalMounted]: BulkSelectModalMounted;
  [AnalyticsEvents.ExpensesManageSettingsClicked]: EmptyPayload;
  [AnalyticsEvents.ExpensesManageSettingsTakeOverSaved]: EmptyPayload;
  [AnalyticsEvents.ExpensesManageSettingsTakeOverClosed]: EmptyPayload;
  [AnalyticsEvents.ExpensesPolicyPageMounted]: ExpensesPolicyPageMounted;
  [AnalyticsEvents.ExpensesPolicyPageDetailsOpen]: ExpensesPolicyPageDetailsOpen;
  [AnalyticsEvents.ExpensesPolicyPageDismissItem]: ExpensesPolicyPageDismissItem;
  [AnalyticsEvents.ExpensesPolicyPageUndoDismiss]: ExpensesPolicyPageUndoDismiss;
  [AnalyticsEvents.ExpensesPolicyPageFollowUpClicked]: ExpensesPolicyPageFollowUpClicked;
  [AnalyticsEvents.ExpensesReviewPageEmptyMounted]: EmptyPayload;
  [AnalyticsEvents.ExpensesReviewPageNoPolicyEmptyMounted]: EmptyPayload;
  [AnalyticsEvents.ExpensesReviewPageEmptyCreatePolicyClicked]: EmptyPayload;
  [AnalyticsEvents.ExpensesSpendAlertsSectionEmptyMounted]: EmptyPayload;
  [AnalyticsEvents.ExpensesOutOfPolicySectionEmptyMounted]: EmptyPayload;
  [AnalyticsEvents.ExpensesOutOfPolicySectionNoPolicyMounted]: EmptyPayload;
  [AnalyticsEvents.ExpensesOutOfPolicySectionPausedPolicyMounted]: EmptyPayload;
  [AnalyticsEvents.ExpensesOutOfPolicySectionCreatePolicyClicked]: EmptyPayload;
  [AnalyticsEvents.ExpensesOutOfPolicySectionEnablePolicyClicked]: EmptyPayload;
  [AnalyticsEvents.ExpensesAddToSlackClicked]: ExpensesAddToSlackClicked;
  [AnalyticsEvents.ExpensesBeforeAddingSlackMounted]: EmptyPayload;
  [AnalyticsEvents.ExpensesBeforeAddingSlackSetupPolicyClicked]: EmptyPayload;
  [AnalyticsEvents.ExpensesViewPolicyClicked]: EmptyPayload;
  [AnalyticsEvents.ExpensesTableSeeMoreClicked]: EmptyPayload;
  [AnalyticsEvents.ExpensesTableSortClicked]: ExpensesTableSortClicked;
  [AnalyticsEvents.ExpensesTableRowSelectClicked]: ExpensesTableRowSelectClicked;
  [AnalyticsEvents.ExpensesTableIssuesPillHovered]: ExpensesTableIssuesPillHovered;
  [AnalyticsEvents.ExpensesReviewPageDateRangeChanged]: ExpensesReviewPageDateRangeChanged;
  [AnalyticsEvents.ExpensesReviewPageDirectReportsFilterChanged]: ExpensesReviewPageDirectReportsFilterChanged;
  [AnalyticsEvents.ExpensesReviewPageIssuesFilterChanged]: ExpensesReviewPageIssuesFilterChanged;
  [AnalyticsEvents.ExceptionsCreateButtonClicked]: EmptyPayload;
  [AnalyticsEvents.ExceptionCreated]: ExceptionPayload;
  [AnalyticsEvents.ExceptionDeleted]: ExceptionPayload;
  [AnalyticsEvents.MileageSettingsMounted]: EmptyPayload;
  [AnalyticsEvents.MileageSettingsErrorMounted]: EmptyPayload;
  [AnalyticsEvents.MileageSettingsSaveClicked]: MileageSettingsPayload;
  [AnalyticsEvents.MileageSettingsDiscardClicked]: EmptyPayload;
  [AnalyticsEvents.MileageSettingsAddRateClicked]: EmptyPayload;
  [AnalyticsEvents.MileageSettingsCountrySelected]: MileageCountrySelectPayload;
  [AnalyticsEvents.MileageSettingsDistanceUnitChanged]: MileageDistanceUnitChangedPayload;
  [AnalyticsEvents.ReimbursementAddPaymentSubmitClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementSaveAsDraftClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementCloseCreateModal]: EmptyPayload;
  [AnalyticsEvents.ReimbursementSubmitClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementModifyClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementSubmitDraftClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementSubmitDraftSuccess]: ReimbursementSubmitDraftSuccessPayload;
  [AnalyticsEvents.ReimbursementApproveClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementDenyClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementBulkApproveClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementBulkDenyClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementNewReimbursementClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementEditBankAccountClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementBulkInviteClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementCSVExportCreate]: EmptyPayload;
  [AnalyticsEvents.ReimbursementCSVExportDownloadClicked]: EmptyPayload;
  [AnalyticsEvents.ReimbursementBulkInviteSkipClicked]: EmptyPayload;
  [AnalyticsEvents.RequestsPageMounted]: RequestsPage;
  [AnalyticsEvents.RequestsPageSetupMounted]: EmptyPayload;
  [AnalyticsEvents.RequestsPageSetupClicked]: EmptyPayload;
  [AnalyticsEvents.RequestsReviewRequest]: RequestsReviewRequest;
  [AnalyticsEvents.RequestsReviewRequestDetailsClicked]: RequestsReviewRequestDetailsPayload;
  [AnalyticsEvents.RequestsReviewRequestDetailsMounted]: RequestsReviewRequestDetailsPayload;
  [AnalyticsEvents.RequestsReviewManageCardClicked]: CardPayload;
  [AnalyticsEvents.RequestsReviewManageUserClicked]: UserIdPayload;
  [AnalyticsEvents.RequestsVendorCardTakeover]: RequestsVendorCardTakeover;
  [AnalyticsEvents.RequestsModifyVendorCardRequestMounted]: ModifyVendorCardPayload;
  [AnalyticsEvents.RequestsModifyVendorCardRequestSuccess]: ModifyVendorCardPayload;
  [AnalyticsEvents.RequestsModifyUserLimitIncreaseRequestMounted]: ModifyUserLimitIncreaseRequestPayload;
  [AnalyticsEvents.RequestsModifyUserLimitIncreaseRequestSuccess]: ModifyUserLimitIncreaseRequestPayload;
  [AnalyticsEvents.RequestsApprovedUserLimitIncreaseTakeoverMounted]: EmptyPayload;
  [AnalyticsEvents.RequestsApprovedUserLimitIncreaseClicked]: EmptyPayload;
  [AnalyticsEvents.RequestsPendingUserLimitIncreaseClicked]: EmptyPayload;
  [AnalyticsEvents.RequestsPendingUserLimitIncreaseTakeoverMounted]: RequestsPendingUserLimitIncreaseTakeoverMounted;
  [AnalyticsEvents.RequestsPendingUserLimitIncreaseCanceled]: EmptyPayload;
  [AnalyticsEvents.RequestsPrimaryApproverUpdated]: RequestsPrimaryApproverUpdated;
  [AnalyticsEvents.RequestsSetupMounted]: EmptyPayload;
  [AnalyticsEvents.RequestsSetupComplete]: EmptyPayload;
  [AnalyticsEvents.RequestsEmailCompany]: EmptyPayload;
  [AnalyticsEvents.SpendAlertsAlertOpened]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsAlertClosed]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsChartHover]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsFollowUpOpened]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsFollowUpClosed]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsFollowUpSubmitted]: SpendAlertsFollowUpSubmitted;
  [AnalyticsEvents.BulkSpendOutOfPolicyFollowUpSubmitted]: BulkSpendOutOfPolicyFollowUpSubmitted;
  [AnalyticsEvents.SpendAlertsMarkReviewedOpened]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsMarkReviewedClosed]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsMarkReviewedSubmitted]: SpendAlertsMarkReviewedSubmitted;
  [AnalyticsEvents.BulkSpendAlertsMarkReviewedSubmitted]: BulkSpendAlertsMarkReviewedSubmitted;
  [AnalyticsEvents.BulkSpendOutOfPolicyMarkReviewedSubmitted]: BulkSpendOutOfPolicyMarkReviewedSubmitted;
  [AnalyticsEvents.SpendAlertsOnboardingComplete]: EmptyPayload;
  [AnalyticsEvents.SpendAlertsSeeTransactionsClicked]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsTableColumnSorted]: SpendAlertsTableColumnSorted;
  [AnalyticsEvents.SpendAlertsTableRendered]: SpendAlertsTableRendered;
  [AnalyticsEvents.SpendAlertsTableSeeMoreClicked]: {};
  [AnalyticsEvents.SpendAlertsTableInfoPillHovered]: SpendAlertsTableInfoPillHovered;
  [AnalyticsEvents.SpendAlertsTableRowSelectClicked]: SpendAlertsTableRowSelectClicked;
  [AnalyticsEvents.SpendAlertsUndoReviewSubmitted]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsCreateExceptionOpened]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsCreateExceptionClosed]: SpendAlertsBasePayload;
  [AnalyticsEvents.SpendAlertsCreateExceptionSubmitted]: SpendAlertsCreateExceptionSubmitted;
  [AnalyticsEvents.SpendAlertsAlertCountLoaded]: SpendAlertsAlertCountLoadedPayload;
  [AnalyticsEvents.SpendAlertsExceptionsTableMounted]: EmptyPayload;
  [AnalyticsEvents.SpendRequestsSettingsSetupClicked]: EmptyPayload;
  [AnalyticsEvents.SpendRequestsSettingsTurnOffClicked]: EmptyPayload;
  [AnalyticsEvents.SpendRequestsSettingsTurnOffConfirmClicked]: EmptyPayload;
  [AnalyticsEvents.PremiumSettingsActivateExpensePolicy]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsSetupModalChooseRequestTypeMounted]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsSetupModalChooseCardRequestsClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsSetupModalChooseReimbursementsClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsReimbursementsSetupModalMounted]: UnifiedRequestsReimbursementSetupModal;
  [AnalyticsEvents.UnifiedRequestsReimbursementsSetupComplete]: UnifiedRequestsReimbursementsSetupComplete;
  [AnalyticsEvents.UnifiedRequestsCardRequestsOptOutClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsCardRequestsOptOutComplete]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsReimbursementsSettingsModalMounted]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsReimbursementsUpdateAccountClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsReimbursementsOptOutClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsReimbursementsOptOutComplete]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsEmptyPageEnableRequestsClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsRequestsPageEnableCardRequestsClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsRequestsPageDismissCardRequestsClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsRequestsPageEnableReimbursementsClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsRequestsPageDismissReimbursementsClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorMounted]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorChangeProductTypeClicked]: ApprovalRulesEditorChangeProductTypePayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorAddNewRuleClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorDeleteRuleClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorSetRuleAmount]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorAddRuleStep]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorDeleteRuleStep]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorSetStepUsers]: ApprovalRulesEditorSetStepUsersPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorCancelEditClicked]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsApprovalRulesEditorSubmitPolicy]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsCardRequestReviewComplete]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsLimitIncreaseReviewComplete]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsReimbursementReviewComplete]: EmptyPayload;
  [AnalyticsEvents.UnifiedRequestsBulkApproveClicked]: UnifiedRequestsBulkApproveClickedPayload;
  [AnalyticsEvents.UnifiedRequestsBulkDenyClicked]: UnifiedRequestsBulkDenyClickedPayload;
};
