import type { CreateStorage } from "./createStorage";
import { createStorage } from "./createStorage";
import type {
  LocalPersistedStateKeys,
  JsonSerializable,
  GuardedJsonSerializable,
  CacheKey,
} from "./types";
import { usePersistedState } from "./usePersistedState";

export const getCacheKey = (key: LocalPersistedStateKeys): CacheKey =>
  `__BREX__${key}`;

const localStorageProvider = createStorage(window.localStorage);

/**
 * Get a value in the persisted state by its key
 */
export const getPersistedStateValue = (
  key: LocalPersistedStateKeys,
  defaultValue: JsonSerializable = null,
) => localStorageProvider.get(getCacheKey(key), defaultValue);

/**
 * This is a just a wrapper around `usePersistedState` that uses localStorage
 * for the persisted state and automatically prefixes state keys.
 */
export const useLocalPersistedState = <T extends JsonSerializable>(
  key: LocalPersistedStateKeys,
  initialState: T,
) => {
  return usePersistedState<GuardedJsonSerializable<T>>({
    initialState: initialState as unknown as GuardedJsonSerializable<T>,
    key: getCacheKey(key),
    storage: localStorageProvider as unknown as CreateStorage<
      GuardedJsonSerializable<T>
    >,
  });
};
