interface ProductLogMediaSharedProps {
  name: string;
  previewUrl: string;
  previewImg: string;
}

export enum ProductLogMediaAssetType {
  Video = "VIDEO",
  Image = "IMAGE",
}

export enum ProductLogDomainTags {
  Classic = "9a381503-c742-41f1-a676-ed7fd5bc85e5",
  Empower = "7ec923ad-7031-481d-8cee-7edb9d23fe7d",
}

export interface Image extends ProductLogMediaSharedProps {
  type: ProductLogMediaAssetType.Image;
  datUrl: string;
  aspectRatio: number;
}

export interface Video extends ProductLogMediaSharedProps {
  type: ProductLogMediaAssetType.Video;
  videoUrl?: string;
}

export type ProductLogMediaAsset = Image | Video;

export interface ProductLogEntryStatus {
  _id: string;
  name: string;
  labelColor: {
    hex: string;
  };
}

interface ProductLogRoles {
  _id: string;
  name: string;
}

export interface ProductLogDomain {
  _id: string;
  name: string;
}

export interface PinnedProductLogPosts {
  productLogPosts: ProductLogEntry[];
}

export interface ProductLogEntry {
  _id: string;
  productLogPostContent: {
    title: string;
    date: string; // ISO date string
    status: ProductLogEntryStatus;
    media?: ProductLogMediaAsset | null;
    roles?: ProductLogRoles;
    domains?: ProductLogDomain[];
    hideProductLog?: boolean | null;
  };
  meta: {
    description?: string;
  };
}

export interface SiteUrl {
  slug: {
    current: string;
  };
  page: {
    _type?: "productLogPost" | string;
    _id?: string;
  } | null;
}

export type ProductLogSlugsById = Record<string, string>;

export enum AnalyticsEvents {
  ProductUpdatesIndicatorDismissed = "product.updates.indicator.dismissed",
  ProductUpdatesViewed = "product.updates.viewed",
  ProductUpdatesExternalLinkClicked = "product.updates.external.link.clicked",
}

type EmptyPayload = {};

export type EventSchemaMap = {
  [AnalyticsEvents.ProductUpdatesIndicatorDismissed]: EmptyPayload;
  [AnalyticsEvents.ProductUpdatesViewed]: EmptyPayload;
  [AnalyticsEvents.ProductUpdatesExternalLinkClicked]: EmptyPayload;
};
