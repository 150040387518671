import type { JsonSerializable } from "./types";

export type CreateStorage<T extends JsonSerializable> = {
  get: (key: string, defaultValue: T) => T;
  set: (key: string, value: T) => void;
};

/**
 * Create a getter and setter for values in a storage provider.
 *
 * The getter returns a default value if nothing exists in the storage
 * provider, or a JSON parsed value if it exists.
 *
 * The setter JSON stringifies values before storing them in the storage
 * provider.
 */
export const createStorage = <T extends JsonSerializable>(
  storageProvider: Storage,
): CreateStorage<T> => ({
  get(key: string, defaultValue: T) {
    const json = storageProvider.getItem(key);
    const value =
      json === null || typeof json === "undefined"
        ? defaultValue
        : JSON.parse(json);
    return value as T;
  },
  set(key: string, value: T) {
    storageProvider.setItem(key, JSON.stringify(value));
  },
});
