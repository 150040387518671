export const AnalyticsEvents = {
  mobileUpsellClick: "mobile_upsell.click",
  mobileUpsellDismiss: "mobile_upsell.dismiss",
  mobileUpsellEligible: "mobile_upsell.eligible",
  mobileUpsellImpression: "mobile_upsell.impression",
  mobileUpsellViewed: "mobile_upsell.viewed",
} as const;

export type AnalyticsEventsName =
  (typeof AnalyticsEvents)[keyof typeof AnalyticsEvents];

export enum MobileUpsellOrigin {
  mobileUpsellModal = "mobileUpsellModal",
}

export interface MobileUpsellInteractionPayload {
  origin?: MobileUpsellOrigin;
}

export type EventSchemaMap = {
  [AnalyticsEvents.mobileUpsellClick]: MobileUpsellInteractionPayload;
  [AnalyticsEvents.mobileUpsellDismiss]: MobileUpsellInteractionPayload;
  [AnalyticsEvents.mobileUpsellEligible]: MobileUpsellInteractionPayload;
  [AnalyticsEvents.mobileUpsellImpression]: MobileUpsellInteractionPayload;
  [AnalyticsEvents.mobileUpsellViewed]: MobileUpsellInteractionPayload;
};
