import { useAtomValue } from "jotai";
import * as React from "react";
import {
  AccountingExportResourceAttribute,
  ResourceAction,
} from "@/__generated__/globalTypes";
import { resourceOperationMappingAtom } from "@/domains/App/features/Permissions/atoms/resourceOperationsQueryAtoms";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";

export const useAccountingExportResourceOperations = () => {
  const { accountingExport } = useAtomValue(resourceOperationMappingAtom);
  const { hideAccountingJournalUnexport } = useFeatureFlags();

  const canUpdateExportTargetData = React.useMemo(
    () =>
      accountingExport.hasAccountingExportResourceOperation(
        ResourceAction.UPDATE,
        AccountingExportResourceAttribute.EXPORT_TARGET_DATA,
      ),
    [accountingExport],
  );

  return {
    canUpdateExportTargetData:
      canUpdateExportTargetData && !hideAccountingJournalUnexport,
  };
};
