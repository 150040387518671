import * as React from "react";
import { DefaultError } from "@/components/Error/DefaultError";
import type {
  ErrorBoundaryProps,
  ErrorBoundaryState,
} from "@/components/Error/sharedTypes";

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { error: null };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (this.props.onError) {
      this.props.onError(error, info);
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  resetError = () => {
    this.setState({ error: null });
  };

  render() {
    const {
      children,
      renderError = ({ resetError }) => (
        // TODO: Remove this, this is wrong, we should not track errors based on
        // a specific component being rendered
        <DefaultError
          onClickRetry={resetError}
          errorOrigin={this.props.__DROP_TRACK_ORIGIN}
        />
      ),
    } = this.props;
    return this.state.error
      ? renderError({
          resetError: this.resetError,
          error: this.state.error,
        })
      : children;
  }
}
