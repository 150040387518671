import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "travel.announcement.view": false,
  "travel.events.view": false,
  "travel.trips.view.any": false,
  "travel.view": false,
  "travel.view.insights": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {
    "travel.view": true,
  } as PartialPermissions,
  [UserRole.BOOKKEEPER]: {
    "travel.view": true,
  } as PartialPermissions,
  [UserRole.ADMIN]: {
    "travel.announcement.view": true,
    "travel.events.view": true,
    "travel.trips.view.any": true,
    "travel.view": true,
    "travel.view.insights": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {
    "travel.view": true,
  } as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {
    "travel.view": true,
  } as PartialPermissions,
  [DepositsRole.ADMIN]: {
    "travel.events.view": true,
    "travel.trips.view.any": true,
    "travel.view": true,
    "travel.view.insights": true,
  } as PartialPermissions,
  null: {
    "travel.view": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {
    "travel.view": true,
  } as PartialPermissions,
  true: {
    "team.view": true,
  } as PartialPermissions,
};

// (lauren.liu - 8/16/22): permissionsEligibleForCopilot determines what permissions are
// eligible for use in Copilot mode.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "travel.announcement.view": false,
  "travel.events.view": true,
  "travel.trips.view.any": false,
  "travel.view": true,
  "travel.view.insights": false,
};
