import { getTokenExpirationTime } from "@/features/Authentication/helpers/getTokenExpirationTime";
import type { TokenExchangeResponse } from "@/features/Authentication/sharedTypes";

/**
 * Computes the date offset from the JWT token. This function must be called right after token exchange.
 * The second argument is the timestamp before the token exchange request was initiated used to do a correction on network latency.
 *
 * @param tokenExchangeResponse the token exchange response object
 * @param tokenExchangeInitiationTime the timestamp before inititating the JWT token request
 */
export const computeDateOffsetFromToken = (
  {
    expiresIn,
    accessToken,
  }: Pick<TokenExchangeResponse, "expiresIn" | "accessToken">,
  tokenExchangeInitiationTime = Date.now(),
) => {
  const expirationTime = getTokenExpirationTime(accessToken);
  const offset = expirationTime
    ? expirationTime -
      expiresIn * 1000 -
      (tokenExchangeInitiationTime + Date.now()) / 2
    : 0;
  // Ignore less than a second offsets
  return Math.abs(offset) <= 1000 ? 0 : offset;
};
