import styled from "@emotion/styled";
import { position } from "polished";

export const StyledPageErrorMessage = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  ...position("absolute", 0, 0, 0, 0),
});
