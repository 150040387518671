import * as React from "react";
import {
  LazyComponentContext,
  LazyComponentPrefetchType,
} from "@/features/LazyComponents/contexts/LazyComponentContext";

// NOTE: This is an experimental API, please contact frontend platform before using

type Loader<T> = () => Promise<{ default: T }>;

type OnError = (error: string) => void;

interface LazyComponentOptions<PropsType, PrefetchType> {
  load: Loader<React.JSXElementConstructor<PropsType>>;
  usePrefetch?: () => PrefetchType;
  loadUsePrefetch?: Loader<() => PrefetchType>;
}

interface LazyComponentLoader<PropsType> extends React.FC<PropsType> {
  preload: Loader<React.ComponentType<PropsType>>;
  Prefetch: React.FC<{ onError?: OnError }>;
}

function PrefetchFromHook<PrefetchType>({
  usePrefetch,
}: {
  usePrefetch: () => PrefetchType;
}): ReturnType<React.VFC> {
  usePrefetch();
  return null;
}

function PrefetchFromLoader<PrefetchType>({
  loadUsePrefetch,
  onError,
}: {
  loadUsePrefetch: Loader<() => PrefetchType>;
  onError?: OnError;
}): ReturnType<React.VFC> {
  const [module, setModule] = React.useState<{ default: () => PrefetchType }>();

  React.useEffect(() => {
    loadUsePrefetch().then(setModule).catch(onError);
  }, [loadUsePrefetch, onError]);

  return module ? <PrefetchFromHook usePrefetch={module.default} /> : null;
}

/**
 * @deprecated Use createView instead
 */
export function createLazyComponent<PropsType, PrefetchType>({
  load,
  usePrefetch,
  loadUsePrefetch,
}: LazyComponentOptions<
  PropsType,
  PrefetchType
>): LazyComponentLoader<PropsType> {
  const LazyComponent = React.lazy(load);

  const Prefetch = React.memo<{ onError?: OnError }>(({ onError }) => (
    <>
      {usePrefetch && <PrefetchFromHook usePrefetch={usePrefetch} />}
      {loadUsePrefetch && (
        <PrefetchFromLoader
          loadUsePrefetch={loadUsePrefetch}
          onError={onError}
        />
      )}
    </>
  ));

  const WrappedComponent = (props: PropsType) => {
    const [error, setError] = React.useState<string | null>(null);
    const handleError = (e: string) => setError(e.toString());
    const value = React.useMemo(
      () => ({
        prefetchError: error,
        lazy: true,
        prefetch: usePrefetch
          ? LazyComponentPrefetchType.Hook
          : loadUsePrefetch
            ? LazyComponentPrefetchType.Loader
            : null,
      }),
      [error],
    );
    return (
      <LazyComponentContext.Provider value={value}>
        <Prefetch onError={handleError} />
        <LazyComponent
          {...(props as JSX.IntrinsicAttributes &
            React.ComponentProps<typeof LazyComponent>)}
        />
      </LazyComponentContext.Provider>
    );
  };

  WrappedComponent.displayName = "LazyComponentLoader";
  WrappedComponent.preload = () => load();
  WrappedComponent.Prefetch = Prefetch;

  return WrappedComponent;
}
