import type {
  InboxTaskType,
  MetricCategoryType,
  SurfaceGroup,
  SurfaceTaskState,
  ValueAddSuggestionType,
} from "@/__generated__/globalTypes";

export enum AccountManagementAnalyticsEvents {
  AccountManagementViewed = "account-management.viewed",
  AccountManagementObjectiveCompletedViewed = "account-management.objective.completed.viewed",
  AccountManagementObjectiveClicked = "account-management.objective.clicked",
  AccountManagementTaskViewed = "account-management.task.viewed",
  AccountManagementTaskDismissed = "account-management.task.dismissed",
  AccountManagementTaskClicked = "account-management.task.clicked",
  AccountManagementTaskManualComplete = "account-management.task.manual-complete",

  // Value Metrics Dashboard
  ValueMetricsDashboardViewed = "account-management.value-metrics.dashboard.viewed",
  ValueMetricsDashboardCalcExplanationClicked = "account-management.value-metrics.dashboard.calculation-explanation.clicked",
  ValueMetricsDashboardDateRangeSelected = "account-management.value-metrics.dashboard.daterange.selected",
  ValueMetricsMetricsSummaryCardExpandClicked = "account-management.value-metrics.dashboard.summary-card.expand.clicked",
  ValueMetricsMetricsSummaryCardLineItemClicked = "account-management.value-metrics.dashboard.summary-card.line-item.clicked",
  ValueMetricsMetricsSummaryCardSuggestionClicked = "account-management.value-metrics.dashboard.summary-card.suggestion.clicked",
  ValueMetricsMetricsDetailsPaneMounted = "account-management.value-metrics.dashboard.details-pane.metrics.mounted",
  ValueMetricsMetricsDetailsPaneClosed = "account-management.value-metrics.dashboard.details-pane.metrics.closed",
  ValueMetricsMetricsDetailsPaneRecommendationMounted = "account-management.value-metrics.dashboard.details-pane.metrics.recommendation.mounted",
  ValueMetricsMetricsDetailsPaneRecommendationClicked = "account-management.value-metrics.dashboard.details-pane.metrics.recommendation.clicked",
  ValueMetricsMetricsDetailsPaneL2OptionDropdownClicked = "account-management.value-metrics.dashboard.details-pane.metrics.l2-dropdown-option.clicked",
  ValueMetricsMetricsDetailsPaneL3OptionButtonClicked = "account-management.value-metrics.dashboard.details-pane.metrics.l3-option-button.clicked",
  ValueMetricsMetricsDetailsPaneRecommendationCTAClicked = "account-management.value-metrics.dashboard.details-pane.recommendation.cta.clicked",
  ValueMetricsMetricsDetailsPaneRecommendationDetailsBackClicked = "account-management.value-metrics.dashboard.details-pane.recommendation.back.clicked",
  ValueMetricsMetricsReleaseNoteClicked = "account-management.value-metrics.dashboard.release-note.clicked",
  ValueMetricsMetricsReleaseNoteCarouselSlideChanged = "account-management.value-metrics.dashboard.release-note.slide.changed",
  ValueMetricsMetricssDetailsPaneReleaseCTAClicked = "account-management.value-metrics.dashboard.details-pane.release.cta.clicked",
  ValueMetricsMetricssDetailsPaneReleaseClosed = "account-management.value-metrics.dashboard.details-pane.release.closed",
  ValueMetricsMetricsDetailsPaneDrillDownMounted = "account-management.value-metrics.dashboard.details-pane.metrics.drill-downs.mounted",
  ValueMetricsMetricsDetailsPaneDrillDownClicked = "account-management.value-metrics.dashboard.details-pane.metrics.drill-downs.clicked",
}

type EmptyPayload = {};

type ObjectiveClickedPayload = {
  objectiveType: SurfaceGroup;
};

export type TaskInteractionPayload = {
  objectiveType: SurfaceGroup;
  taskType: InboxTaskType;
  status: SurfaceTaskState | undefined;
};

type TaskCompletionPayload = {
  taskType: InboxTaskType;
};

type CVMDateRangePayload = {
  dateRange?: string;
};

type MetricsCategoryEventPayload = {
  categoryType?: MetricCategoryType;
  suggestionType?: ValueAddSuggestionType | null;
};

type ReleaseNoteEventPayload = {
  title?: string | null;
};

type ReleaseNoteCarouselEventPayload = {
  index: number;
};

export type AccountManagementEventSchemaMap = {
  [AccountManagementAnalyticsEvents.AccountManagementViewed]: EmptyPayload;
  [AccountManagementAnalyticsEvents.AccountManagementObjectiveCompletedViewed]: ObjectiveClickedPayload;
  [AccountManagementAnalyticsEvents.AccountManagementObjectiveClicked]: ObjectiveClickedPayload;
  [AccountManagementAnalyticsEvents.AccountManagementTaskViewed]: TaskInteractionPayload;
  [AccountManagementAnalyticsEvents.AccountManagementTaskDismissed]: TaskInteractionPayload;
  [AccountManagementAnalyticsEvents.AccountManagementTaskClicked]: TaskInteractionPayload;
  [AccountManagementAnalyticsEvents.AccountManagementTaskManualComplete]: TaskCompletionPayload;

  // Value Metrics Dashboard
  [AccountManagementAnalyticsEvents.ValueMetricsDashboardViewed]: EmptyPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsDashboardCalcExplanationClicked]: EmptyPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsDashboardDateRangeSelected]: CVMDateRangePayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsSummaryCardExpandClicked]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsSummaryCardLineItemClicked]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsSummaryCardSuggestionClicked]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneMounted]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneClosed]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneRecommendationMounted]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneRecommendationClicked]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneL2OptionDropdownClicked]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneL3OptionButtonClicked]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneRecommendationCTAClicked]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneRecommendationDetailsBackClicked]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsReleaseNoteClicked]: ReleaseNoteEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsReleaseNoteCarouselSlideChanged]: ReleaseNoteCarouselEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricssDetailsPaneReleaseCTAClicked]: ReleaseNoteEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricssDetailsPaneReleaseClosed]: ReleaseNoteEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneDrillDownMounted]: MetricsCategoryEventPayload;
  [AccountManagementAnalyticsEvents.ValueMetricsMetricsDetailsPaneDrillDownClicked]: MetricsCategoryEventPayload;
};
