import { getTokenExpirationTime } from "@/features/Authentication/helpers/getTokenExpirationTime";

const countdownThresholdInSeconds = 10;
const countdownDurationInSeconds = 60;

export const getTiming = (
  getAccessToken: () => string | null,
  getCurrentDate: () => Date,
) => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return;
  }

  // Compute the timestamp at which the access token expires
  const sessionExpiresAt = getTokenExpirationTime(accessToken);
  if (!sessionExpiresAt) {
    return;
  }

  // We make an assumption that the token expires `countdownThresholdInSeconds=10` seconds earlier than it's actually supposed to.
  // This is to account for the time it might take to refresh token by triggering a new gql query.
  const sessionExpiresAtWithThreshold =
    sessionExpiresAt - countdownThresholdInSeconds * 1000;
  // Compute the timestamp at which we want the countdown to start.
  const countdownStartsAt =
    sessionExpiresAtWithThreshold - countdownDurationInSeconds * 1000;
  const now = getCurrentDate().getTime();

  return {
    countdownStartsAt,
    now,
    accessToken,
    sessionExpiresAt,
    sessionExpiresAtWithThreshold,
    countdownDurationInSeconds,
  };
};
