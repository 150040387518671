// Naming convention for future additions, feature flags should be prefixed
// with a domain identifier, ex: app.[feature-flag-name]

import type { IncidentBannerObject } from "@/domains/App/sharedTypes";

/**
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 */
export default [
  ["developer-settings", false],
  ["dev-panel-access", false],
  ["enable-maintenance-page", false],
  ["settings-page-block-phone-number-update", true],
  ["sanctions-possibly-impacted-users", false],
  ["expense-detail-expense-fields-all-users", false],
  ["ada-test-mode", false],
  ["language-switcher", false],
  ["enable-react-profiler", false],
  ["react-profiler-sample-rate", 0.1],
  ["advanced-reimbursement-gating", false],
  ["incident-banner", {} as IncidentBannerObject],
  ["enable-relay-apollo-syncing", false],
] as const;
