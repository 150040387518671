import * as React from "react";

const NavigationMenuContext = React.createContext<
  | {
      registeredPaths: {
        [key: string]: string;
      };
      registerPath: (path: string) => void;
      unregisterPath: (path: string) => void;
    }
  | undefined
>(undefined);

export const useNavigationMenu = () => React.useContext(NavigationMenuContext);

export const NavigationMenuProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [registeredPaths, setRegisteredPaths] = React.useState<{
    [key: string]: string;
  }>({});

  const registerPath = React.useCallback(
    (path: string) =>
      setRegisteredPaths((existingPaths) => ({
        ...existingPaths,
        [path]: path,
      })),
    [],
  );

  const unregisterPath = React.useCallback(
    (path: string) =>
      setRegisteredPaths(
        ({ [path]: _unregisteredPath, ...remainingPaths }) => remainingPaths,
      ),
    [],
  );

  return (
    <NavigationMenuContext.Provider
      value={React.useMemo(
        () => ({
          registeredPaths,
          registerPath,
          unregisterPath,
        }),
        [registerPath, registeredPaths, unregisterPath],
      )}
    >
      {children}
    </NavigationMenuContext.Provider>
  );
};
