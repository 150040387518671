import { SideNavigation } from "@brexhq/metal-navigation";
import styled from "@emotion/styled";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useNavigationMenu } from "@/domains/App/features/Navigation/contexts/NavigationMenuContext";
import { isItemActive } from "@/domains/App/features/Navigation/helpers/isItemActive";
import { useNavigationLinkProps } from "@/domains/App/features/Navigation/hooks/useNavigationLinkProps";
import type { NavigationLinkV2Props } from "@/domains/App/features/Navigation/sharedTypes";

const DisabledMenuItemWrapper = styled.div<{ disabled?: boolean }>(
  ({ disabled }) => [
    disabled && {
      span: {
        cursor: "not-allowed",
      },
    },
  ],
);

// NavigationLinkV2 is only used for Empower Navigation, use NavigationLink for Classic
export const NavigationLinkV2: React.FC<NavigationLinkV2Props> = ({
  isActive,
  to,
  onClick,
  disabled,
  ...props
}) => {
  const navMenu = useNavigationMenu();
  const location = useLocation();
  const active = isActive ? isActive() : isItemActive(location, to);
  const inNavigationMenu = !!navMenu;
  const registerPath = navMenu?.registerPath;
  const unregisterPath = navMenu?.unregisterPath;

  React.useEffect(() => {
    if (!inNavigationMenu) {
      return;
    }

    registerPath?.(to);

    return () => unregisterPath?.(to);
  }, [inNavigationMenu, registerPath, unregisterPath, to]);

  const navigationLinkProps = useNavigationLinkProps({ to, onClick });
  return (
    <DisabledMenuItemWrapper disabled={disabled}>
      <SideNavigation.MenuItem
        isActive={active}
        disabled={disabled}
        {...navigationLinkProps}
        {...props}
      />
    </DisabledMenuItemWrapper>
  );
};
