export enum ActivateUserAnalyticsEvents {
  MobileActivationDelegateRedirected = "mobile_activation_delegate.redirected",
  MobileActivationDelegateViewed = "mobile_activation_delegate.viewed",
}

type EmptyPayload = {};

export type ActivateUserEventSchemaMap = {
  [ActivateUserAnalyticsEvents.MobileActivationDelegateRedirected]: EmptyPayload;
  [ActivateUserAnalyticsEvents.MobileActivationDelegateViewed]: EmptyPayload;
};
