// This helper closely resembles the one in the Mobile repo:
// https://github.com/brexhq/mobile/blob/development/src/legacy/helpers/queryHelpers.ts#L6-L39

import type { QueryResult } from "@/features/Apollo/helpers/client";
import { NetworkStatus } from "@/features/Apollo/helpers/client";
import { isDefined } from "@/helpers/typeHelpers";

export type QueryLoadingInfo = {
  initialLoading: boolean;
  revalidating: boolean;
  fetchingMore: boolean;
  refetching: boolean;
  polling: boolean;
};

export const createLoadingInfoFromQueryResult = <
  T extends Pick<QueryResult<any, any>, "data" | "networkStatus">,
>({
  data,
  networkStatus,
}: T): QueryLoadingInfo => {
  const hasCachedData = isDefined(data);
  return {
    initialLoading:
      // The query is in-flight and there is no data cached matching it
      (networkStatus === NetworkStatus.loading && !hasCachedData) ||
      // The query is in-flight but has previously run with a different set
      // of arguments and there is no data cached matching it
      (networkStatus === NetworkStatus.setVariables && !hasCachedData),
    revalidating:
      // The query is in-flight and there is already cached data matching it
      (networkStatus === NetworkStatus.loading && hasCachedData) ||
      // The query is in-flight but has previously run with a different set
      // of arguments and there is already cached data matching it
      (networkStatus === NetworkStatus.setVariables && hasCachedData),
    // The query is in-flight because fetchMore() was called
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    // The query is in-flight because refetch() was called
    refetching: networkStatus === NetworkStatus.refetch,
    // The query is in-flight because polling was configured for it
    polling: networkStatus === NetworkStatus.poll,
  };
};
