export const METAL_PACKAGE_PREFIX = "metal-";
export const WEB_PACKAGE_NAMESPACE_AFFIX = "~web";
export const COMPONENT_LIBRARY = "component-library";

/**
 * Transforms the short "metal-*" package name to the long "node_modules~@brexhq~metal-*~dist" namespace
 */
export const transformToMetalFolderName = (requestedNamespace: string) => {
  const packageName = requestedNamespace.split("~")[0];
  const packageNameWithPrefix =
    packageName === COMPONENT_LIBRARY ? packageName : `metal-${packageName}`;
  const namespace = `node_modules~@brexhq~${packageNameWithPrefix}~dist`;
  return namespace;
};

/**
 * Transforms the long "node_modules~@brexhq~metal-*~dist" namespace to the proper "metal-*" package name
 */
export const transformToMetalPackageName = (namespace: string) =>
  namespace.replace("node_modules~@brexhq~", "").replace("~dist", "");

export const transformToMetalNamespaceName = (packageName: string) => {
  if (packageName === COMPONENT_LIBRARY) {
    return packageName;
  }

  const [, metalPackage] = packageName.split("-");
  return `${metalPackage}~web`;
};
