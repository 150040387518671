import { css } from "@emotion/react";

// eslint-disable-next-line @emotion/syntax-preference
export const fallbackFocusStateStylesheet = css`
  /* Setting a fallback focus state for all non-input elements */
  /* Ideally components should provide their own custom focus state, but
  this provides a reasonable fallback for when they fail to do so */
  *:focus:not(input):not(textarea):not(select) {
    /* This overrides browser default focus state */
    outline: none;
  }
`;
