import * as React from "react";
import { useLocation } from "react-router-dom";
import { useUpdateSardineContext } from "@/hooks/useUpdateSardineContext";

export const useSardine = () => {
  const { pathname } = useLocation();
  const updateConfig = useUpdateSardineContext();
  React.useEffect(() => {
    updateConfig(pathname);
  }, [pathname, updateConfig]);
};
