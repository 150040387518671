// Naming convention for future additions, feature flags should be prefixed
// with a domain identifier, ex: onboarding.[feature-flag-name]

/**
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 */
export default [
  ["document-upload-summary-screen", false],
  ["virtual-card-callout", false],
  ["ca-disclosure-agreement", false],
  ["use-mobile-onboarding-activation-links", false],
  ["skip-phone-number-requirement", false],
] as const;
