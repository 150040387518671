import * as React from "react";

type AnyComponent = React.ComponentType<any>;

// Declaring and exporting the same function multiple times is a way to declare functions with
// multiple signatures using Typescript (e.g. like styled.div`.class { display: none }` vs
// styled.div(`.class { display: none }`)). The first two exports are just types, and the last one
// is the actual implementation.

// this signature makes sure the 2nd argument is only optional if the module has a default export
export function lazyImportHelper<Module extends { default: AnyComponent }>(
  importerFunction: () => Promise<Module>,
  namedImport?: "default",
): React.LazyExoticComponent<Module["default"]>;

export function lazyImportHelper<
  ExportName extends string,
  Module extends { [exportName in ExportName]: AnyComponent },
>(
  importerFunction: () => Promise<Module>,
  namedImport: ExportName,
): React.LazyExoticComponent<Module[ExportName]>;

export function lazyImportHelper<
  ExportName extends string,
  Module extends { [exportName in ExportName]: AnyComponent },
>(
  /** An anonymous function to import the component, like: `() => import('@someAlias/xxxxxxx')` */
  importerFunction: () => Promise<Module>,
  /** Variable name of the named export to use. If not provided the "default export" will be used */
  namedImport: ExportName = "default" as ExportName,
) {
  return React.lazy(() =>
    importerFunction().then((importObject) => ({
      default: importObject[namedImport],
    })),
  );
}
