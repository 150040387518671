import gql from "graphql-tag";
import type { PermissionsQuery } from "./__generated__/queries.generated";
import { useQuery } from "@/features/Apollo";
import type { QueryHookOptions } from "@/features/Apollo";
import { createMockableHook } from "@/helpers/createMockableHook";

export const permissionsQuery = gql`
  query PermissionsQuery {
    user {
      id
      role
      depositsRole
      isManager
    }
  }
`;

export const usePermissionsQuery = (queryOptions?: QueryHookOptions) =>
  useQuery<PermissionsQuery>(permissionsQuery, {
    fetchPolicy: "network-only",
    ...queryOptions,
  });

export const corporateCardAccessQuery = gql`
  query CorporateCardAccessQuery {
    user {
      id
      canAccessCorporateCards
    }
  }
`;

const useCorporateCardAccessQuery = (queryOptions?: QueryHookOptions) => {
  const { fetchPolicy, ...rest } = queryOptions || {};
  return useQuery(corporateCardAccessQuery, {
    fetchPolicy: fetchPolicy || "network-only",
    ...rest,
  });
};

export const [
  useMockableCorporateCardAccessQuery,
  MockCorporateCardAccessProvider,
] = createMockableHook(useCorporateCardAccessQuery);

export const resourceOperationsQuery = gql`
  query ResourceOperationsQuery {
    resourceOperations {
      ... on UserResourceOperation {
        __typename
        action
        userAttribute: attribute
      }
      ... on OrganizationResourceOperation {
        __typename
        action
        organizationAttribute: attribute
      }
      ... on LegalEntityResourceOperation {
        __typename
        action
        legalEntityAttribute: attribute
      }
      ... on TravelResourceOperation {
        __typename
        action
        travelAttribute: attribute
      }
      ... on BillPayResourceOperation {
        __typename
        action
        billPayAttribute: attribute
      }
      ... on VendorResourceOperation {
        __typename
        action
        vendorAttribute: attribute
      }
      ... on AccountResourceOperation {
        __typename
        action
        accountAttribute: attribute
      }
      ... on GroupResourceOperation {
        __typename
        action
      }
      ... on AccountingExportResourceOperation {
        __typename
        action
        accountingExportAttribute: attribute
      }
    }
  }
`;
