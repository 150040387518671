import type { WithoutClientMutationId as WithoutClientMutationIdUtil } from "./graphql";

export const capitalizeString = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const uncapitalizeString = (string: string) =>
  string.charAt(0).toLowerCase() + string.slice(1);

export { isPaymentDateDisabled } from "./businessDays";
export { createMerchantIconUrl } from "./createMerchantIconUrl";
export { getEnvironment } from "./environment";
export {
  formatDate,
  sortDatesAscending,
  queryDateToUtc,
  formatISODate,
} from "./date";
export {
  connectionToNodes,
  nodesToMap,
  connectionToNodesMap,
  toRawEntityId,
  encodeEntityId,
  decodeEntityId,
} from "./graphql";
export type WithoutClientMutationId<Input> = WithoutClientMutationIdUtil<Input>;
export { formatVendorName } from "./integrations";
export {
  asciiRegex,
  emailRegex,
  emailExtractionRegex,
  formatAddress,
  poBoxRegex,
  zipCodeRegex,
} from "./address";
export { getKeysExtendType, mapNullToUndefined } from "./typeHelpers";
