import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "rewards.nav.dropdown.read": false,
  "rewards.nav.main.read": false,
  "rewards.overview.read": false,
  "rewards.perks_and_discounts.read": false,
  "rewards.points.read": false,
  "rewards.redemption.cash.read": false,
  "rewards.redemption.read": false,
  "rewards.redemption.travel.read": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {
    "rewards.nav.main.read": true,
    "rewards.perks_and_discounts.read": true,
  } as PartialPermissions,
  [UserRole.BOOKKEEPER]: {
    "rewards.nav.main.read": true,
    "rewards.perks_and_discounts.read": true,
  } as PartialPermissions,
  [UserRole.ADMIN]: {
    "rewards.nav.main.read": true,
    "rewards.overview.read": true,
    "rewards.perks_and_discounts.read": true,
    "rewards.points.read": true,
    "rewards.redemption.read": true,
    "rewards.redemption.travel.read": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {
    "rewards.nav.main.read": true,
    "rewards.perks_and_discounts.read": true,
  } as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {
    "rewards.nav.main.read": true,
    "rewards.perks_and_discounts.read": true,
  } as PartialPermissions,
  [DepositsRole.ADMIN]: {
    "rewards.nav.main.read": true,
    "rewards.overview.read": true,
    "rewards.perks_and_discounts.read": true,
    "rewards.points.read": true,
    "rewards.redemption.cash.read": true,
    "rewards.redemption.read": true,
    "rewards.redemption.travel.read": true,
  } as PartialPermissions,
  null: {} as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {} as PartialPermissions,
  true: {} as PartialPermissions,
};

// permissionsEligibleForCopilot determines what permissions are eligible for use in Copilot mode.
// Please use caution around what should be set to true and see go/td-wiki for details.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "rewards.nav.dropdown.read": false,
  "rewards.nav.main.read": false,
  "rewards.overview.read": false,
  "rewards.perks_and_discounts.read": false,
  "rewards.points.read": false,
  "rewards.redemption.cash.read": false,
  "rewards.redemption.read": false,
  "rewards.redemption.travel.read": false,
};
