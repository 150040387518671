export enum PrimitivesTeamTab {
  UsersCompany = "users/company",
  UsersDirectReports = "users/reports",
  AccountingFirms = "accounting-firms",
  Departments = "departments",
  Locations = "locations",
  LegalEntities = "legal-entities",
  CostCenters = "cost-centers",
  Titles = "titles",
  Groups = "groups",
  /** @deprecated Cards was removed from the team tabs. This is used for redirection only. */
  Cards = "cards",
}

export enum RewardsGiftCardsTab {
  Catalog = "catalog",
  History = "history",
}

export enum SupportedBudgetAndSpendLimitPaths {
  Budgets = "budgets",
  CardsAndLimits = "cards-and-limits",
}

const resolveBudgetsPath = (pathSuffix: string) => {
  const supportedPaths = Object.values(SupportedBudgetAndSpendLimitPaths);
  const pathname = window.location.pathname;
  const supportedPath = supportedPaths.find((x) => pathname.includes(x));
  const mainPath = supportedPath
    ? supportedPath
    : SupportedBudgetAndSpendLimitPaths.Budgets;
  return `/p/${mainPath}/${pathSuffix}`;
};

const budgetsUrls = () => {
  // not shared URLs only exist on /p/budgets
  const notSharedUrls = {
    budgetsMain: "/p/budgets" as const,
    budgets: (
      tab?:
        | "all-budgets"
        | "budgets-you-manage"
        | "your-budgets"
        | "budget-setup",
    ) => `/p/budgets${tab ? `/${tab}` : ""}`,
  };

  // shared URLs exist between /p/budgets and /p/cards-and-limits
  const sharedUrls = {
    budgetDetails: (tab?: string, id?: string) =>
      resolveBudgetsPath(
        `details${tab ? `/${tab}` : ""}${id ? `?budgetId=${id}` : ""}`,
      ),
    budgetCreate: () => resolveBudgetsPath("create"),
    budgetEdit: () => resolveBudgetsPath("edit"),
    budgetLimitIncreaseMain: () => resolveBudgetsPath("limit-increase"),
    budgetLimitIncreaseRequest: () =>
      resolveBudgetsPath("limit-increase/request"),
    budgetLimitIncreaseEdit: () => resolveBudgetsPath("limit-increase/edit"),
    budgetLimitIncrease: () => resolveBudgetsPath("limit-increase/increase"),
    budgetAutomations: () => resolveBudgetsPath("automations"),
    budgetAllocationDetails: (id?: string) =>
      resolveBudgetsPath(
        `details/this-budget/allocation-detail${id ? `?budgetId=${id}` : ""}`,
      ),
    budgetTemplates: {
      main: (id?: string) =>
        resolveBudgetsPath(`budget-templates${id ? `?id=${id}` : ""}`),
      expensePolicy: () =>
        resolveBudgetsPath("budget-templates/expense-policy"),
      budgetRequestOrganization: () =>
        resolveBudgetsPath("budget-templates/budget-request-organization"),
      availability: () => resolveBudgetsPath("budget-templates/availability"),
    },
    budgetOnboarding: {
      explorerEducation: () =>
        resolveBudgetsPath("onboarding/explorer-education"),
    },
    budgetOffboarding: () => resolveBudgetsPath("offboarding"),
    budgetSetupHelpers: () => resolveBudgetsPath("budget-setup/helpers"),
    budgetPrograms: {
      main: () => resolveBudgetsPath("budget-programs"),
      create: () => resolveBudgetsPath("budget-programs/create"),
      edit: () => resolveBudgetsPath("budget-programs/edit"),
    },
    spendLimit: {
      details: (params?: { id?: string; groupId?: string }) => {
        const { id, groupId } = params || {};
        if (id) {
          return resolveBudgetsPath(
            `spend-limits/details?id=${id}` +
              (groupId ? `&groupId=${groupId}` : ""),
          );
        }
        if (groupId) {
          return resolveBudgetsPath(`spend-limits/details?groupId=${groupId}`);
        }
        return resolveBudgetsPath("spend-limits/details");
      },
      groupDetails: (groupId?: string) =>
        resolveBudgetsPath(
          `spend-limits/details${groupId ? "?groupId=" + groupId : ""}`,
        ),
    },
  };

  return {
    ...notSharedUrls,
    ...sharedUrls,
  };
};

export const urls = {
  account: {
    settings: () => "/my-account",
    statementUpload: () => "/my-account/statement-upload",
    mfa: () => "/my-account/mfa",
    productUpdates: () => "https://www.brex.com/product-announcements/",
    accountManagement: () => "/account-management",
    valueMetrics: () => "/account-management/metrics",
  },
  accounting: {
    root: () => `${urls.primitives.main()}/accounting` as const,
    accountingFields: {
      main: () => "/p/accounting/accounting-fields",
      create: () => "/p/accounting/accounting-fields/create",
      customize: () => "/p/accounting/accounting-fields/customize",
      editValues: () => "/p/accounting/accounting-fields/edit-values",
    },
    customRules: {
      main: () => "/p/accounting/custom-rules",
      create: () => "/p/accounting/custom-rules/create",
    },
    entitySettings: () => "/p/accounting/entity-settings",
    // Pre-datagrid reviewStatus = READY_FOR_EXPORT view
    export: {
      main: () => "/p/accounting/export",
    },
    // Datagrid reviewStatus = EXPORTED view
    exported: {
      main: () => "/p/accounting/exported",
    },
    exportHistory: {
      root: () => "/p/accounting/export-history",
      main: (tab?: "exports" | "exported-transactions") =>
        `/p/accounting/export-history${tab ? `/${tab}` : ""}`,
      export: () => "/p/accounting/export-history/export/transactions",
    },
    fundingSources: {
      main: () => "/p/accounting/payment-accounts",
    },
    mapping: () => "/p/accounting/mapping",
    onboarding: {
      main: () => `${urls.accounting.root()}/onboarding` as const,
      automation: () =>
        `${urls.accounting.onboarding.main()}/automation` as const,
      customFields: () =>
        `${urls.accounting.onboarding.main()}/custom-fields` as const,
      entities: () => `${urls.accounting.onboarding.main()}/entities` as const,
      universalCsv: () =>
        `${urls.accounting.onboarding.main()}/universal-csv` as const,
    },
    prepare: {
      main: () => "/p/accounting/prepare",
    },
    // Datagrid reviewStatus = READY_FOR_EXPORT view
    review: {
      main: () => "/p/accounting/review",
    },
    setup: {
      main: () => "/p/accounting/setup",
      user: () => "/p/accounting/setup/user",
      custom: () => "/p/accounting/setup/custom",
      mapping: () => "/p/accounting/setup/mapping",
      customFields: () => "/p/accounting/setup/custom-fields",
    },
  },
  auth: {
    activate: () => "/signup/activate",
    forgot: () => "/forgot-password",
    login: () => "/login",
    loginWithSession: () => "/login-with-session",
    loginFinish: () => "/login/finish",
    loginEmailRoot: () => "/login/email-link",
    logout: () => "/logout",
    signup: () => "/signup",
    switchTenant: () => "/switch-account",
    externalApi: () => "/external-api-auth.html",
  },
  bankFeedSetup: {
    quickbooks: () => "https://c10.qbo.intuit.com/app/banking",
  },
  bills: {
    bills: () => "/bills",
    billPayCreate: () => "/bills/create",
    billPayEdit: () => "/bills/edit",
    billPaySuccess: () => "/bills/pay/success",
    primitives: () => "/p/bills",
    primitivesBillPaySetup: () => "/p/bills/setup",
    billsSyncErrors: () => "/p/bills/sync-errors",
    primitivesCreate: (step = ":step") =>
      `/p/bills/create${step ? `/${step}` : ""}`,
    primitivesEdit: (step = ":step") =>
      `/p/bills/edit${step ? `/${step}` : ""}`,
  },
  banking: {
    root: () => "/banking",
    migration: () => "/banking/dda-migration",
    accounts: (id?: string) =>
      `/banking/accounts${id ? `?accountId=${id}` : ""}`,
    automaticTransfers: () => "/banking/automatic-transfers",
    /** Details panel with additional settings for banking (eg. payroll connection, payment approvals, ACH debit allow list).  */
    settings: () =>
      "/banking/accounts?bankingAccountDetailsPaneSegment=settings",
  },
  browserExtensions: {
    chrome: () =>
      "https://chrome.google.com/webstore/detail/brex/aioheomkldpmjlfdjnookgcpekdegamn?hl=en&u_producttype=chrome-extension-3762&gclid=Cj0KCQjwhLKUBhDiARIsAMaTLnEDgsh-IlhYsi_Qsu5PSC8O72oY7qbH2xAn8QAxbAiY2iOQIT348v4aAmONEALw_wcB",
  },
  card: {
    cards: (tab?: string) => `/card/cards${tab ? `/${tab}` : ""}`,
    addVirtual: () => "/card/virtual-activate",
    addPhysical: () => "/card/physical-activate",
    overview: () => "/card/overview",
    transactions: (tab?: string) => `/card/transactions${tab ? `/${tab}` : ""}`,
    companyTransactions: () => "/card/transactions/company",
    payments: () => "/card/payments",
    root: () => "/card",
    statements: () => "/card/statements",
    yourTransactions: () => "/card/transactions/yours",
    fedexIndiaKYC: () => "https://kyc.fedex.com/#/kyc-microsite",
  },
  cash: {
    accountsPayable: () => "/cash/accounts-payable",
    addAccount: () => "/cash/add-account",
    application: () => "/cash/application",
    automaticTransfers: () => "/cash/automatic-transfers",
    paymentThresholdsApprovals: () => "/cash/payment-thresholds-approvals",
    paymentReviewPolicy: () => "/cash/payment-review-policy",
    fund: () => "/cash/transactions/fund",
    createBookTransfer: () => "/cash/transactions/book-transfer",
    createBookTransferSuccess: () => "/cash/transactions/book-transfer/success",
    linkPayrollProvider: () => "/cash/transactions/link-payroll-provider",
    payments: () => "/cash/payments",
    payrollProviders: {
      ripplingLogin: () => "https://app.rippling.com/login",
      trinetLogin: () => "https://identity.trinet.com/",
      adpLogin: () => "https://www.adp.com/",
      paychexLogin: () => "http://mypaychex.com",
      quickbooksLogin: () => "https://quickbooks.intuit.com/",
    },
    processPayrollGusto: () =>
      "https://manage.gusto.com/payroll_admin/payrolls/new",
    root: () => "/cash",
    send: () => "/cash/transactions/send",
    sendSuccess: () => "/cash/transactions/send/success",
    payBillCreate: () => "/cash/accounts-payable/pay-bill/create",
    payBillEdit: () => "/cash/accounts-payable/pay-bill/edit",
    payBillSuccess: () => "/cash/accounts-payable/pay-bill/success",
    payBillTermsAndConditions: () =>
      "https://www.brex.com/legal/payments-agreement",
    statements: () => "/cash/statements",
    vendors: () => "/cash/vendors",
    addVendor: () => "/cash/vendors/add",
    uploadVendors: () => "/cash/vendors/upload",
    uploadVendorsSuccess: () => "/cash/vendors/upload/success",
    addPaymentInstrument: () => "/cash/vendors/payment-instrument/add",
    termsAndConditions: () =>
      "https://www.brex.com/legal/product-terms/treasury/agreement",
    transaction: (transactionId?: string) =>
      `/cash/transactions/transaction/${transactionId ?? ":transactionId"}`,
    transactions: () => "/cash/transactions",
  },
  connectShopify: {
    callback: () => "/connect-shopify/callback",
  },
  email: {
    support: () => "mailto:support@brex.com",
    startupReview: () => "mailto:startup-review@brex.com",
  },
  error: () => "/error",
  files: {
    full: () => "/files/:id",
    shortened: () => "/f/:id",
  },
  helpCenter: {
    assistant: () => "https://www.brex.com/support/brex-assistant",
    mainPage: () => "https://support.brex.com/",
    budgetTemplates: () =>
      "https://www.brex.com/support/what-are-budget-templates",
    budgetValues: () => "https://www.brex.com/support/budget-values",
    concurSetup: () =>
      "https://support.brex.com/how-do-i-connect-brex-to-concur/",
    coupaSetup: () =>
      "https://www.brex.com/support/how-does-brex-integrate-with-coupa-pay/",
    creditLimits: () =>
      "https://support.brex.com/how-do-brex-credit-limits-work/",
    expensifySetup: () =>
      "https://support.brex.com/how-do-i-set-up-the-expensify-integration",
    expenseCategories: () => "https://www.brex.com/support/expense-categories/",
    netsuiteSetup: () =>
      "https://support.brex.com/s/article/How-do-I-set-up-the-NetSuite-integration",
    xeroSetup: () =>
      "https://support.brex.com/how-do-i-add-brex-to-my-xero-bank-feed/",
    pilotSetup: () =>
      "https://support.brex.com/how-do-i-set-up-the-pilot-integration/",
    quickbooksSetup: () =>
      "https://support.brex.com/how-do-i-add-brex-to-my-quickbooks-online-bank-feed",
    intacctSetup: () =>
      "https://support.brex.com/how-do-i-set-up-the-sage-intacct-integration",
    userRoles: () =>
      "https://www.brex.com/support/brex-role-types-and-permissions",
    updateCompanyAddress: () =>
      "https://support.brex.com/how-can-i-update-my-company-s-address/",
    updateCompanyTimezone: () =>
      "https://www.brex.com/support/can-i-change-the-time-zone-that-my-account-operates-in",
    connectPayroll: () =>
      "https://support.brex.com/how-do-i-connect-my-payroll-to-brex-cash/",
    gustoSetup: () =>
      "https://support.brex.com/how-do-i-set-up-the-gusto-integration/",
    fundingSource: () =>
      "https://support.brex.com/how-do-i-manage-the-funding-source-for-brex-card-payments/",
    fixPennyPullFailure: () =>
      "https://support.brex.com/how-do-i-fix-a-failed-penny-pull/",
    disputes: () => "https://support.brex.com/what-is-a-dispute/",
    einLetter: () =>
      "https://support.brex.com/tell-me-more-about-the-terms-used-on-the-brex-application/",
    depositLimits: () => "https://support.brex.com/brex-deposit-limits/",
    paymentApprovals: () =>
      "https://support.brex.com/how-do-i-manage-admin-approvals-on-brex-cash/",
    svbConnectivityIssues: () =>
      "https://support.brex.com/silicon-valley-bank-connectivity-issues/",
    frbConnectivityIssues: () =>
      "https://www.brex.com/support/i-m-having-trouble-linking-my-first-republic-bank-frb-account-to-brex/",
    successfulPayments: () =>
      "https://support.brex.com/how-can-i-ensure-successful-payments-to-brex/",
    microDepositsLearnMore: () =>
      "https://www.brex.com/support/what-are-micro-deposits-trial-deposits",
    connectBankIssue: () =>
      "https://support.brex.com/i-m-having-issues-connecting-my-bank-account-to-brex/",
    wireInstructions: () =>
      "https://support.brex.com/how-do-I-send-an-international-wire-to-my-brex-cash-account/",
    intlWireInstructionRequirements: () =>
      "https://www.brex.com/support/international-wire-instruction-requirements",
    zapierSetup: () =>
      "https://support.brex.com/how-does-brex-integrate-with-zapier/",
    contactBrex: () => "https://www.brex.com/support/how-can-i-contact-brex/",
    staleBankConnection: () =>
      "https://support.brex.com/why-is-my-bank-connection-out-of-date-or-stale/",
    onePasswordSetup: () =>
      "https://support.brex.com/how-do-i-set-up-the-1password-integration/",
    sanctions: () =>
      "https://www.brex.com/support/how-do-the-current-sanctions-on-russia-impact-my-brex-account/",
    hrisConnection: () =>
      "https://www.brex.com/support/how-do-i-connect-my-hris-to-brex/",
    csvSetup: () => "https://www.brex.com/support/accounting-tab-guide",
    brexRequirements: () =>
      "https://www.brex.com/support/what-are-the-requirements-for-brex",
    manageAutopay: () =>
      "https://www.brex.com/support/how-do-i-manage-the-autopay-account-for-brex-card-payments/",
    employeeRepayments: () =>
      "https://www.brex.com/support/how-do-employee-repayments-work/",
    underwritingAccounts: () =>
      "https://www.brex.com/support/what-type-of-bank-accounts-can-i-use-for-underwriting",
    complianceRating: () => "https://www.brex.com/support/compliance-rating",
    statementUnderwritingFAQ: () =>
      "https://www.brex.com/support/what-is-statement-underwriting",
    manualUploadStatements: () =>
      "https://www.brex.com/support/how-do-i-manually-upload-statements",
    bbaPaymentApprovals: () => "https://www.brex.com/support/payment-approvals",
    introducingSpendLimits: () =>
      "https://www.brex.com/support/introducing-spend-limits",
    rolesAndPermissions: () =>
      "https://www.brex.com/support/brex-role-types-and-permissions",
    ssoGuide: () =>
      "https://www.brex.com/support/signing-into-brex#single-sign-on-sso",
    enterpriseIdpLogin: () =>
      "https://www.brex.com/support/signing-into-brex#enterprise-idp-login",
    brexAndSlack: () => "https://www.brex.com/support/brex-and-slack",
    payStatementManageAutopayAccount: () =>
      "https://www.brex.com/support/paying-brex-statements#manage-your-autopay-account",
    authorizeBrexOrganizationIds: () =>
      "https://www.brex.com/support/allowlist-authorize-brex",
    spendMigrationMigratingSpend: () =>
      "https://www.brex.com/support/vendor-spend-migration#migrating-spend-in-your-dashboard",
    quickBooksBankFeed: () =>
      "https://www.brex.com/support/quickbooks-online-integration#bank-feed-vs-expense-data",
    payOutsideOfBrex: () => "https://www.brex.com/support/pay-outside-of-brex",
  },
  hr: {
    main: () => "/hr",
    connect: () => "/hr/connect",
    invite: () => "/hr/invite",
    manage: () => "/hr/manage",
    suspend: () => "/hr/suspend",
    configure: () => "/hr/configure",
  },
  kyc: {
    periodicReview: () => "/kyc/review",
  },
  legal: {
    main: () => "https://brex.com/legal",
    archive: {
      cashAgreement: () => "https://www.brex.com/legal/archive/cash-agreement",
      cardTerms: () => "https://www.brex.com/legal/archive/card-terms",
      paymentsAgreement: () => "https://www.brex.com/legal/payments-agreement",
    },
    productTerms: {
      travel: () => "https://www.brex.com/legal/product-terms/travel",
    },
  },
  mobile: {
    download: ({ isUpsell = false } = {}) => {
      if (!isUpsell) {
        return "https://brex.app.link/mobile";
      }

      return "https://brex.app.link/dashboard-mobile-upsell-mlp";
    },
    checkDeposit: () => "https://brex.app.link/08o4ASw0m7",
  },
  oauth: {
    main: () => "/oauth",
    quickbooks: () => "/oauth/quickbooks",
    xero: () => "/oauth/xero",
    plaid: () => "/oauth/plaid",
    google: {
      mobile: () => "/oauth/google/mobile",
    },
  },
  offboarding: {
    main: () => "/close-account",
    claimFunds: () => "/close-account/claim-funds",
    claimFundsSuccess: () => "/close-account/funds-sent",
    withdrawFunds: () => "/close-account/withdraw-funds",
    accountClosureCash: () => "/close-account/cash",
  },
  onboarding: {
    main: () => "/onboarding",
    activateAccount: () => "/onboarding/activate-email",
    productSelection: () => "/onboarding/product-selection",
    productApplication: () => "/onboarding/product",
    changeRoleV2: () => "/onboarding/change-role-v2",
    fundingSourceSelection: () => "/onboarding/funding-source-selection",
    rewards: () => "https://brex.com/rewards",
    platformAgreement: () => "https://brex.com/legal",
    privacyPolicy: () => "https://brex.com/privacy/",
    cashCustomerAgreement: () =>
      "https://www.brex.com/legal/bba-treasury-terms",
    brex1Card: () => "https://www.brex.com/legal",
    cashProgramDisclosures: () =>
      "https://www.brex.com/legal/brex-cash-sweep-disclosure",
    fundProspectus: () =>
      "https://im.bnymellon.com/content/dam/im/documents/compliancedocs/prospectus/annual/cmgt_inv.pdf",
    finra: () => "https://www.finra.org",
    sipc: () => "https://www.sipc.org",
    irsBackupWitholding: () =>
      "https://www.irs.gov/businesses/small-businesses-self-employed/backup-withholding",
    rejected: () => "/onboarding/unable-to-approve",
    developerPortal: () => "/onboarding/developer-portal",
    developerPortalAgreement: () => "https://brex.com/legal/developer-portal",
    whatIsEin: () => "https://www.brex.com/lp/what-is-an-ein/",
    sboAgreement: () =>
      "https://www.brex.com/legal/sbo-agreement-and-personal-guarantee",
    consumerReportAuthorization: () =>
      "https://www.brex.com/legal/consumer-report-authorization",
    cardProgramTerms: () => "https://www.brex.com/legal/product-terms/card",
    svbEmergencyPayrollLoan: () =>
      "https://gz711a2vho7.typeform.com/to/IMxM82SP",
    paymentsAgreement: () => "https://brex.com/legal/payments-agreement",
    columnDemandDepositAccountAgreement: () =>
      "https://www.brex.com/legal/column-dda-agreement",
    columnIcsAgreement: () =>
      "https://www.brex.com/legal/column-sweep-agreement",
    cardTerms: () => "https://www.brex.com/legal/card",

    application: () => "/onboarding/apply",
    verify: () => "/onboarding/verify",
    delayedEIN: () => "/onboarding/ein",
    subsidiaryVerify: () => "/onboarding/subsidiary",
    cardApplication: () => "/onboarding/card-application",
    cashUpsell: () => "/onboarding/cash",
    billPayApprover: () => "/onboarding/bill-pay-approver",
    lendingDisclosure: () => "/onboarding/lending-disclosure",
    primitives: {
      main: () => "/p/onboarding",
      verify: () => "/p/onboarding/verify",
      subsidiaryVerify: () => "/p/onboarding/subsidiary",
      cardApplication: () => "/p/onboarding/card-application",
      cardHelp: () => "https://www.brex.com/product/credit-card",
      cashHelp: () => "https://www.brex.com/product/business-account",
      reimbursementsHelp: () =>
        "https://www.brex.com/support/expense-reimbursements",
      billPayHelp: () => "https://www.brex.com/support/bill-pay-overview",
      travelHelp: () =>
        "https://www.brex.com/support/brex-travel-getting-started",
      application: () => "/p/onboarding/apply",
      cashUpsell: () => "/p/onboarding/cash",
      billPayApprover: () => "/p/onboarding/bill-pay-approver",
      delayedEIN: () => "/p/onboarding/ein",
    },
  },
  referrals: () => "/referrals",
  rewards: {
    main: () => "/rewards",
    points: () => "/rewards/points",
    perksAndDiscounts: () => "/rewards/perks-and-discounts",
    miles: {
      history: () => "/rewards/miles/history",
      main: () => "/rewards/miles",
    },
    overview: () => "/rewards/overview",
    donation: () => "/rewards/redeem-for-code2040",
    offsites: () => "/rewards/redeem-for-offsites",
    executiveCoaching: () => "/rewards/redeem-for-executive-coaching",
    billboards: () => "/rewards/redeem-for-billboards",
    mentalHealthCare: () => "/rewards/redeem-for-mental-health-care",
    inkle: () => "/rewards/redeem-for-inkle",
    giftCards: {
      main: () => "/rewards/gift-cards",
      tab: (tab: RewardsGiftCardsTab) => `/rewards/gift-cards/${tab}`,
    },
  },
  limitExperience: {
    root: () => "/limits",
    limitIncreaseRequest: () => "/limits/limit-increase-request",
  },
  root: () => "/",
  settings: {
    main: (tab?: string) => `/settings${tab ? `/${tab}` : ""}`,
    expenses: () => "/settings/expenses",
    brexAssistant: () => "/settings/assistant",
    statementUpload: () => "/settings/personal/statement-upload",
    mfa: () => "/settings/personal/mfa",
    company: () => "/settings/company",
    personal: {
      main: () => "/settings/personal",
      activity: () => "/settings/personal/activity",
      mfa: () => "/settings/personal/multi-factor",
      updateBankAccount: () => "/settings/personal/updateBankAccount",
    },
    financialDocuments: () => "/settings/financial-documents",
    connectedAccounts: () => "/settings/bankaccounts",
    developer: () => "/settings/developer",
    root: () => "/settings",
    notifications: () => "/settings/notifications",
    plan: () => "/settings/plan",
    managePaymentMethods: () => "/settings/manage-payment-methods",
  },
  integrations: {
    callback: () => "/integrations/callback",
    custom: () => "/integrations/custom",
    customRules: () => "/integrations/custom-rules",
    export: (tab?: string) => `/integrations/export${tab ? `/${tab}` : ""}`,
    vendorSettings: (vendor = ":vendor") => `/integrations/v/${vendor}`,
    main: () => "/integrations",
    mapping: () => "/integrations/mapping",
    qboDisconnected: () => "/quickbooks-integration-disconnected",
    reconnect: () => "/integrations/reconnect",
    legacy: () => "/settings/integrations",
    oauth: {
      quickbooks: () => "/integrations/oauth/quickbooks",
      xero: () => "/integrations/oauth/xero",
    },
  },
  reports: {
    root: () => "/reports",
    main: (tab?: "spend" | "stock" | "custom", id?: string) =>
      `/reports/${tab}${id ? `?id=${id}` : ""}`,
    details: (tab?: string) => `/reports/details${tab ? `/${tab}` : ""}`,
  },
  expenses: {
    root: () => "/expenses/",
    requests: (tab?: string) => `/expenses/requests${tab ? `/${tab}` : ""}`,
    review: (tab?: string) => `/expenses/review${tab ? `/${tab}` : ""}`,
    transaction: (tab?: string) =>
      `/expenses/review${tab ? `/${tab}` : ""}/transaction/:statementEntryId`,
    reimbursements: () => "/expenses/reimbursements",
  },
  receipts: {
    gmailFilter: () => "/receipts/gmail-filter",
  },
  team: {
    departments: () => "/team/departments",
    locations: () => "/team/locations",
    partners: () => "/team/partners",
    addPartner: () => "/team/partners/add",
    inviteUser: () => "/team/invite-user",
    users: (tab?: string) => `/team/users${tab ? `/${tab}` : ""}`,
    usersAddOwnPhysical: () => "/team/own-physical-activate",
    main: () => "/team",
    bulkUserUpload: () => "/team/bulk-user-upload",
  },
  travel: {
    booking: () => "/travel/booking",
    bookingSuccess: (
      tripId = ":tripId",
      spotnanaBookingId = ":spotnanaBookingId",
    ) => `/travel/trips/${tripId}/bookings/s/${spotnanaBookingId}/success`,
    bookingForm: () => "/travel/booking/form",
    groupEventDetails: (eventId = ":eventId") =>
      `/travel/events/details/${eventId}`,
    groupEventEdit: (eventId = ":eventId") => `/travel/events/edit/${eventId}`,
    groupEventRoomBlockUpload: (eventId = ":eventId") =>
      `/travel/events/details/${eventId}/room-block-upload`,
    groupEventsCreate: () => "/travel/events/create",
    groupEventsList: () => "/travel/events",
    tripDetailsPane: (tripId = ":tripId") =>
      `/travel/trips?detailType=tripDetails&detailId=${tripId}`,
    eventTripDetail: (eventId = ":eventId") =>
      `/travel/trips?detailType=groupEvent&detailId=${eventId}`,
    insights: (category = ":category") => `/travel/insights/${category}`,
    profile: () => "/travel/profile",
    root: () => "/travel",
    tripDetail: (tripId = ":tripId") => `/travel/trips/${tripId}`,
    drawerTripDetail: (tripId = ":tripId") =>
      `/travel/trips?detailType=tripDetails&detailId=${tripId}`,
    spotnanaTripDetailRedirect: (spotnanaTripId = ":spotnanaTripId") =>
      `/travel/trips/s/${spotnanaTripId}`,
    trips: () => "/travel/trips",
    company: {
      tripDetailsPane: (tripId = ":tripId") =>
        `/travel/company/trips?detailType=tripDetails&detailId=${tripId}`,
      bookingDetailsPane: (bookingId = ":bookingId") =>
        `/travel/company/trips?detailType=bookingDetails&detailId=${bookingId}`,
    },
    companyRoot: () => "/travel/company",
    companyTrips: () => "/travel/company/trips",
    companyGroupEvents: () => "/travel/company/events",
  },
  primitives: {
    main: () => "/p" as const,
    walletMain: "/p/wallet",
    wallet: (type?: string, id?: string) =>
      `/p/wallet${type && id ? `/?type=${type}&id=${id}` : ""}`,
    walletRedesign: () => "/p/wallet/my-brex",
    security: {
      main: () => "/p/security",
      authentication: () => "/p/security/authentication",
      trustPortal: () => "https://trust-portal.brex.com/",
    },
    accounts: {
      main: () => "/p/accounts",
      externalAccounts: () => "/p/accounts/externalAccounts",
      updateBankAccount: (id?: string) =>
        `/p/accounts/externalAccounts/updateBankAccount${id ? `/${id}` : ""}`,
    },
    cardsAndLimitsMain: "/p/cards-and-limits" as const,
    cardsAndLimits: (tab: "cards" | "spend-limits" | "create-card" = "cards") =>
      `/p/cards-and-limits/${tab}`,
    requestWorkflows: {
      main: () => "/p/request-workflows",
      create: () => "/p/request-workflows/create",
      edit: (id?: string) =>
        `/p/request-workflows/edit${id ? `?id=${id}` : ""}`,
    },
    ...budgetsUrls(),
    invoices: {
      main: (id?: string) => `/p/invoices${id ? `/${id}` : ""}`,
      create: () => "/p/invoices/create",
      edit: (invoiceId?: string) =>
        `/p/invoices/edit${invoiceId ? `/${invoiceId}` : ""}`,
      editTemplate: (id?: string) =>
        `/p/invoices/edit-template${id ? `/${id}` : ""}`,
    },
    expenses: (tab?: string) => `/p/expenses${tab ? `/${tab}` : ""}`,
    dataGridPlayground: (tab?: string) =>
      `/p/datagrid-playground${tab ? `/${tab}` : ""}`,
    google: {
      calendar: {
        oauthSuccess: () => "/p/google-calendar-oauth-success",
      },
    },
    integrations: {
      main: () => "/p/integrations",
      connect: (vendor = ":vendor") => `/p/integrations/connect/${vendor}`,
      setup: () => "/p/integrations/callback",
      erpList: () => "/p/integrations/erp",
      reconnect: () => "/p/integrations/reconnect",
      vendorSettings: (vendor = ":vendor") => `/p/integrations/v/${vendor}`,
      hris: (id = ":id") => `/p/integrations/hris/${id}`,
      uber: {
        connect: () => "/p/integrations/uber",
        redirect: () => "/p/integrations/uberLinkRedirect",
      },
    },
    clients: {
      main: () => "/p/clients",
    },
    team: {
      main: () => "/p/team",
      tab: (tab: PrimitivesTeamTab) => `/p/team${tab ? `/${tab}` : ""}`,
      inviteUser: () => "/p/team/invite-user",
      bulkUserUpload: () => "/p/team/bulk-user-upload",
      hrisConnect: () => "/p/team/hris-connect",
      hrisSuspend: (id: string) => `/p/team/hris-suspend/${id}`,
      invite: () => "/p/team/invite",
      autoInviteSettings: () => "/p/team/auto-invite-settings",
      autoInviteCreate: () => "/p/team/auto-invite-settings/create",
      autoInviteEdit: (id: string) => `/p/team/auto-invite-settings/${id}`,
      inviteExternalPartner: () => "/p/team/invite-external-partner",
      attributeMapping: () => "/p/team/attribute-mapping",
      createGroup: () => "/p/team/create-group",
      editGroup: (id: string) => `/p/team/edit-group/${id}`,
      superAdminKyc: () => "/p/team/super-admin-kyc",
      entityDetail: (id: string) => `/p/team/legal-entities/${id}`,
    },
    billPayBilling: "/p/bills",
    creditMain: "/p/credit",
    creditBilling: "/p/credit/billing",
    creditBillingOverview: "/p/credit/billing-overview",
    financialStatementUpload: (source: string) =>
      `/p/credit/transactions?modalType=creditLimitEducation&detailPaneTab=documents&openSubmitDocumentsTakeover=true&source=${source}`,
    reimbursementsMain: "/p/reimbursements",
    reimbursements: (tab?: "overview" | "payments" | "statements") =>
      `/p/reimbursements${tab ? `/${tab}` : ""}`,
    reimbursementsBilling: "/p/reimbursements/billing",
    reimbursementsBillingOverview: "/p/reimbursements/billing-overview",
    reimbursementsTracking: "/p/reimbursements/tracked-billing",
    reimbursementsAirwallexVerification:
      "/p/reimbursements/airwallex-verification",
    reimbursementsSetup: "/p/accounts/setup",
    request: {
      main: "/p/request",
      reimbursement: () => "/p/request/reimbursement",
      mileageReimbursement: () => "/p/request/mileage-reimbursement",
      budget: () => "/p/request/budget",
      bulkReimbursement: () => "/p/request/reimbursement/bulk",
    },
    onboarding: () => "/p/onboarding",
    adminCardShipping: () => "/p/onboarding/setup-card",
    policies: {
      main: "/p/policies",
      list: (tab?: string) => `/p/policies${tab ? `/${tab}` : ""}`,
      create: () => "/p/policies/create",
      edit: () => "/p/policies/edit",
      builder: () => "/p/policies/builder",
    },
    rewards: {
      main: () => "/p/rewards",
      points: () => "/p/rewards/points",
      perksAndDiscounts: () => "/p/rewards/perks-and-discounts",
      donation: () => "/p/rewards/redeem-for-code2040",
      offsites: () => "/p/rewards/redeem-for-offsites",
      executiveCoaching: () => "/p/rewards/redeem-for-executive-coaching",
      billboards: () => "/p/rewards/redeem-for-billboards",
      mentalHealthCare: () => "/p/rewards/redeem-for-mental-health-care",
      inkle: () => "/p/rewards/redeem-for-inkle",
      miles: {
        history: () => "/p/rewards/miles/history",
        main: () => "/p/rewards/miles",
      },
      giftCards: {
        main: () => "/p/rewards/gift-cards",
        tab: (tab: RewardsGiftCardsTab) => `/p/rewards/gift-cards/${tab}`,
      },
    },
    referrals: () => "/p/referrals",
    inbox: {
      main: () => "/p/inbox",
      criticalTasks: () => "/p/inbox/critical",
      account: () => "/p/inbox/account",
      yourTasks: () => "/p/inbox/your-tasks",
      yourExpenses: () => "/p/inbox/your-expenses",
      yourTrips: () => "/p/inbox/your-trips",
      comments: () => "/p/inbox/comments",
      yourDrafts: () => "/p/inbox/your-drafts",
      yourPending: () => "/p/inbox/your-pending",
      yourRequests: () => "/p/inbox/your-requests",
      yourSpend: () => "/p/inbox/your-spend",
      reimbursementRequests: () => "/p/inbox/reimbursement-requests",
      cardExpenses: () => "/p/inbox/card-expenses",
      spendInsights: () => "/p/inbox/spend-insights",
      budgetRequests: (tab?: string) =>
        `/p/inbox/budget-requests${tab ? `/${tab}` : ""}`,
      yourInvites: () => "/p/inbox/your-invites",
      bills: () => "/p/inbox/bills",
      bba: () => "/p/inbox/business-accounts",
      travel: () => "/p/inbox/travel",
    },
    sandbox: () => "/p/sandbox",
    spendInsights: {
      settings: () => "/p/spend-insights/settings",
    },
    spendMigration: {
      main: () => "/p/spend-migration",
      migrateVendor: (id?: string) =>
        `/p/spend-migration/migrate-vendor${id ? `/${id}` : ""}`,
    },
  },
  users: {
    activate: () => "/users/activate",
    activateMobile: () => "/users/activate/mobile",
    passwordReset: () => "/users/password_reset",
    emailChange: () => "/users/email-change",
  },
  website: {
    cash: () => "https://brex.com/cash",
    cashManagementAccount: () =>
      "https://www.brex.com/product/cash-management-account/",
    cashRatesAndFees: () =>
      "https://www.brex.com/product/cash-management-account/rates-fees/",
    contactUs: () => "https://brex.com/contact-us/",
    fsp: () => "https://brex.com/referral/fsp",
    legal: {
      main: () => "https://brex.com/legal",
      card: () => "https://brex.com/legal/product-terms/card",
      privacyPolicy: () => "https://www.brex.com/legal/privacy",
      mobileServiceTerms: () =>
        "https://www.brex.com/legal/mobile-terms-of-service/",
      rewards: {
        main: () => "https://brex.com/legal/rewards",
        rewards: () => "https://brex.com/legal/rewards/rewards",
        billboards: () => "https://brex.com/legal/rewards/billboards",
      },
      userTerms: () => "https://www.brex.com/legal/user-terms",
      prohibitedActivities: () =>
        "https://www.brex.com/legal/prohibited-restricted-activities",
      paymentsAgreement: () => "https://www.brex.com/legal/payments-agreement",
      travel: () => "https://www.brex.com/legal/product-terms/travel",
      spotnanaPrivacyPolicy: () => "https://www.spotnana.com/privacy-policy",
    },
    privacy: () => "https://brex.com/privacy",
    proAccess: () => "https://www.brex.com/product/pro-access",
    rewards: () => "https://brex.com/rewards",
    developer: () => "https://developer.brex.com",
  },
  legacy: {
    cards: () => "/cards",
    transactions: () => "/transactions",
    payments: () => "/payments",
    statements: () => "/statements",
    cash: {
      activity: () => "/cash/activity",
    },
    budgetsInbox: () => "/p/budgets/inbox",
  },
  slack: {
    oauth: (origin: string) => {
      if (origin === "https://dashboard.brex.com") {
        return "https://slack.brexapps.com/brex/oauth/start";
      }
      if (
        origin === "https://dashboard.staging.brexapps.com" ||
        origin === "http://localhost:3000"
      ) {
        return "https://slack.staging.brexapps.com/brex/oauth/start";
      }

      return "";
    },
  },
  support: {
    helpshiftLiveChatWindow: () => "/lc",
  },
  implementationGuides: {
    budgets: () => "https://www.brex.com/implementation#Budgets",
  },
  vendors: {
    main: () => "/p/vendors",
    add: () => "/p/vendors/add",
    upload: () => "/p/vendors/upload",
    uploadSuccess: () => "/p/vendors/upload/success",
    edit: (contactId = ":contactId") => `/p/vendors/edit/${contactId}`,
    request: (vendorId = ":vendorId") => `/p/vendors/request/${vendorId}`,
    /** external link for vendors to add info */
    pay: () => "/pay",
    /** external link for sending card to a vendor */
    card: () => "/card",
  },
  fraudAlerts: {
    dismissAlert: () => "/dismiss-alert",
  },
};
