import React from "react";
import type {
  Fiber,
  ReactInstance,
} from "@/domains/App/components/Controller/sharedTypes";

export function getReactComponentInternals(
  instance: ReactInstance | null,
): Fiber | null {
  if (!instance) {
    return null;
  }
  if ("_reactInternalFiber" in instance) {
    return instance._reactInternalFiber;
  }

  return instance._reactInternals;
}

// This component is only used to be able to generate and mount a ref that wee can hold on to afterwards
// Sadly, we cannot do this withough a class component as the
export class PerformanceInternalReactRefComponent extends React.Component {
  render() {
    return this.props.children;
  }
}
