import { atom, createStore, useAtomValue } from "jotai";

const initialState: {
  currentClient: "travel";
  isVisible: boolean;
  unreadCount: number;
} = {
  currentClient: "travel",
  isVisible: false,
  unreadCount: 0,
};

export const chatBubbleAtom = atom(initialState);

const chatBubbleStore = createStore();
chatBubbleStore.set(chatBubbleAtom, initialState);

export const setChatBubble = (nextState: Partial<typeof initialState>) => {
  chatBubbleStore.set(chatBubbleAtom, {
    ...chatBubbleStore.get(chatBubbleAtom),
    ...nextState,
  });
};

export const useChatBubble = () => ({
  ...useAtomValue(chatBubbleAtom, { store: chatBubbleStore }),
  setChatBubble,
});
