import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { AppLocationWatcher } from "@/domains/App/components/AppLocationWatcher";
import { SkeletonErrorHandler } from "@/domains/App/features/Skeleton";
import { AdaProvider } from "@/features/Ada/contexts/Ada";
import { AnalyticsProvider } from "@/features/Analytics/contexts/Analytics";
import { AuthenticationContextProvider } from "@/features/Authentication/contexts/Authentication";
import { FreshchatProvider } from "@/features/Freshchat/contexts/FreshchatProvider";
import { HelpshiftProvider } from "@/features/Helpshift/contexts/Helpshift";
import { UnauthorizedLayoutLoading } from "@/features/UnauthorizedLayout";
import { ThemeProvider } from "@/helpers/styles";
import EmotionCacheProvider from "@/helpers/styles/emotionCache";
import { GlobalStyles } from "@/helpers/styles/global";

// guarantee availability of analytics through context
export const AppWithAnalytics: React.VFC = () => {
  return (
    <React.Suspense fallback={null}>
      <SkeletonErrorHandler>
        <BrowserRouter>
          <AnalyticsProvider>
            <EmotionCacheProvider>
              <HelpshiftProvider>
                <AdaProvider>
                  <FreshchatProvider>
                    <ThemeProvider>
                      <React.Suspense fallback={<UnauthorizedLayoutLoading />}>
                        <GlobalStyles />
                        <AuthenticationContextProvider>
                          {/**
                           * Any hooks that watch location should go here to prevent
                           * the app content from continuously re-rendering
                           */}
                          <AppLocationWatcher />
                          <App />
                        </AuthenticationContextProvider>
                      </React.Suspense>
                    </ThemeProvider>
                  </FreshchatProvider>
                </AdaProvider>
              </HelpshiftProvider>
            </EmotionCacheProvider>
          </AnalyticsProvider>
        </BrowserRouter>
      </SkeletonErrorHandler>
    </React.Suspense>
  );
};

export default AppWithAnalytics;
