import { useMatch } from "react-router-dom";
import { DepositsAccountStatus } from "@/__generated__/globalTypes";
import { useAccountStates } from "@/domains/App/hooks/useAccountStates";
import { urls } from "@/helpers/urls";

const cashUnavailableUrls = {
  [urls.cash.fund()]: true,
  [urls.cash.send()]: true,
};

const cardUnavailableUrls = {
  [urls.card.payments()]: true,
  [urls.card.cards()]: true,
  [urls.account.settings()]: true,
  [urls.travel.root()]: true,
};

// urls blacklist
export const useAllowedPath = (pathname: string, canSeeCard: boolean) => {
  const {
    accountStates: { cashAccountState },
  } = useAccountStates();
  const cashUnavailable =
    cashAccountState === DepositsAccountStatus.CLOSED ||
    cashAccountState === DepositsAccountStatus.SUSPENDED;

  const isIntegrations = useMatch(urls.integrations.main());
  const isRewardsMain = useMatch(urls.rewards.main());
  const isRewardsOverview = useMatch(urls.rewards.overview());
  const isRewards = !!isRewardsMain || !!isRewardsOverview;
  const isTeam = useMatch(urls.team.main());
  if (!canSeeCard) {
    // redirect all integrations
    if (isIntegrations) {
      return false;
    }
    // redirect all rewards
    if (isRewards) {
      return false;
    }
    // redirect all team
    if (isTeam) {
      return false;
    }
    // redirect individual urls blacklisted above
    if (cardUnavailableUrls[pathname]) {
      return false;
    }
  }

  if (cashUnavailable) {
    if (cashUnavailableUrls[pathname]) {
      return false;
    }
  }

  return true;
};
