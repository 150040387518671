import type {
  PaginationInput,
  VerificationAnalysesFilterInput,
} from "@/__generated__/globalTypes";
import type { WatchQueryFetchPolicy } from "@/features/Apollo";
import { useLazyQuery, useQuery } from "@/features/Apollo";
import { createMockableHook } from "@/helpers/createMockableHook";
import type {
  ListVerificationAnalysesQuery,
  ListVerificationAnalysesQueryVariables,
} from "@/routes/Onboarding/data/__generated__/queries.generated";
import { listVerificationAnalysesQuery } from "@/routes/Onboarding/data/queries";

type ListVerificationAnalysesProps = {
  filter: VerificationAnalysesFilterInput;
  page?: PaginationInput;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};
const useListVerificationAnalyses = ({
  filter,
  page,
  skip = false,
  fetchPolicy = "network-only",
}: ListVerificationAnalysesProps) => {
  const { data, loading, error, refetch } = useQuery<
    ListVerificationAnalysesQuery,
    ListVerificationAnalysesQueryVariables
  >(listVerificationAnalysesQuery, {
    variables: {
      filter: filter,
      page: page,
    },
    skip,
    fetchPolicy,
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};

export const [
  useMockableListVerificationAnalyses,
  MockListVerificationAnalysesProvider,
] = createMockableHook(useListVerificationAnalyses);

export const useListVerificationAnalysesLazyQuery = (
  filter: VerificationAnalysesFilterInput,
) => {
  return useLazyQuery<ListVerificationAnalysesQuery, {}>(
    listVerificationAnalysesQuery,
    {
      variables: {
        filter: filter,
      },
      fetchPolicy: "network-only",
    },
  );
};

export const [
  useListVerificationAnalysesLazy,
  ListVerificationAnalysesLazyProvider,
] = createMockableHook(useListVerificationAnalysesLazyQuery);
