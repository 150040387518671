import * as React from "react";
import type { SeenFlagKey } from "@/__generated__/globalTypes";
import { useMutation } from "@/features/Apollo";
import type {
  TurnOffSeenFlag,
  TurnOffSeenFlagVariables,
} from "@/features/SeenFlag/data/mutations";
import { turnOffSeenFlag } from "@/features/SeenFlag/data/mutations";
import { mutationInput } from "@/helpers/graphql";

/**
 * Hook to reset the key to be "unseen"
 */
export const useTurnOffSeenFlag = ({
  key,
  onCompleted,
}: {
  key?: SeenFlagKey;
  onCompleted?: (data?: TurnOffSeenFlag) => void;
}) => {
  const [turnOffSeenFlagMutation, { loading, error }] = useMutation<
    TurnOffSeenFlag,
    TurnOffSeenFlagVariables
  >(turnOffSeenFlag, { onCompleted });
  const handleTurnOffSeenFlagInternal = React.useCallback(
    async (turnOffKey?: SeenFlagKey) => {
      if (!turnOffKey) {
        return;
      }
      try {
        await turnOffSeenFlagMutation(mutationInput({ key: turnOffKey }));
      } catch (e) {
        console.error(`Turn off seen flag failed: ${turnOffKey}`, e);
      }
    },
    [turnOffSeenFlagMutation],
  );

  const handleTurnOffSeenFlag = React.useCallback(() => {
    return handleTurnOffSeenFlagInternal(key);
  }, [handleTurnOffSeenFlagInternal, key]);

  const handleTurnOffSeenFlagOverride = React.useCallback(
    (keyOverride: SeenFlagKey) => {
      if (!key) {
        handleTurnOffSeenFlagInternal(keyOverride);
      }
    },
    [handleTurnOffSeenFlagInternal, key],
  );
  return {
    /**
     * When this hook is passed an optional "key", handleTurnOffSeenFlag
     * will turn off the seen flag with that key.
     */
    handleTurnOffSeenFlag,
    /**
     * When this hook is not passed an optional "key", handleTurnOffSeenFlagOverride
     * takes a parameter called "keyOverride" and turns off the seen flag whose key
     * corresponds to that parameter value.
     */
    handleTurnOffSeenFlagOverride,
    isLoading: loading,
    hasError: !!error,
  };
};
