import type { DocumentNode } from "graphql";
import { useControllerPerformanceContext } from "@/domains/App/components/Controller/ControllerContext";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
  skipToken,
  useSuspenseQuery as useApolloSuspenseQuery,
} from "@/features/Apollo/helpers/client";
import type {
  OperationVariables,
  SuspenseQueryHookOptions,
  SkipToken,
} from "@/features/Apollo/helpers/client";

type SuspenseQueryType = typeof useApolloSuspenseQuery;

/** useSuspenseQuery suspends the component until the query has loaded.
 *
 * It should be used inside of a QueryBoundary, which will show a loading UI while the query loads, and handle errors.
 *
 * useSuspenseQuery is best used when your component only depends on a single query. Multiple
 * useSuspenseQuery calls in sequence will create a query 'waterfall', where each waits for the next.
 * To avoid that, you can use `useBackgroundQuery` to issue queries in parallel.
 *
 * Apollo Docs:
 * - https://www.apollographql.com/docs/react/api/react/hooks/#usesuspensequery
 * - https://www.apollographql.com/docs/react/data/suspense/#fetching-with-suspense
 */
export const useSuspenseQuery = (<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  options?: SkipToken | SuspenseQueryHookOptions<TData, TVariables>,
) => {
  const controllerContext = useControllerPerformanceContext();

  const augmentedOptions =
    options === skipToken
      ? skipToken
      : {
          ...options,
          context: {
            ...options?.context,
            controllerContext,
          },
        };

  return useApolloSuspenseQuery<TData, TVariables>(query, augmentedOptions);
}) as SuspenseQueryType;
