import { createContext, useContext, useEffect } from "react";
import type { To } from "react-router-dom";

export type ShouldBlockNavigationParams =
  | {
      /** The location that the user is navigating to */
      location: To;
      isBeforeUnloadEvent: false;
    }
  | {
      /** This is true when the user is trying to close the browser tab */
      isBeforeUnloadEvent: true;
    };

export type ModalConfig = {
  /** This is text that will be shown on the alert body */
  modalText: string;
  /** This is the path in which your configuration will be valid. All paths below this one will share the same configuration. For instance, if we pass /reports, all navigation attempts initiated in this path will use the provided modalText and shouldBlock function. If the user navigates to a different path, the configuration will be reset to a default state. */
  installPath: string;
};

export type ConfigureFn = (config: ModalConfig) => void;

export type ShouldBlockFn = (params: ShouldBlockNavigationParams) => boolean;

export const UnsavedChangesContext = createContext<{
  configure: ConfigureFn;
  shouldOpenModal: (to: To, bypassUnsavedChangesModal?: boolean) => boolean;
  openModal: (to: To) => void;
  setShouldBlockFn: (fn: ShouldBlockFn) => void;
}>({
  configure: () => {},
  shouldOpenModal: () => false,
  openModal: () => {},
  setShouldBlockFn: () => {},
});

const useUnsavedChangesContext = () => useContext(UnsavedChangesContext);

export type UnsavedChangesConfig = ModalConfig & {
  /** This function will be used to decide whether or not navigation to a location will be blocked. It also receives isBeforeUnloadEvent */
  shouldBlock: ShouldBlockFn;
};

export const useConfigureUnsavedChanges = ({
  modalText,
  installPath,
  shouldBlock,
}: UnsavedChangesConfig) => {
  const { configure, setShouldBlockFn } = useUnsavedChangesContext();

  useEffect(() => {
    configure({ modalText, installPath });
  }, [configure, modalText, installPath]);

  setShouldBlockFn(shouldBlock);
};

export const useUnsavedChangesModal = () => {
  const { shouldOpenModal, openModal } = useUnsavedChangesContext();

  return {
    shouldOpenModal,
    openModal,
  };
};
