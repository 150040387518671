import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "card.account.create": false,
  "card.accountClosedBanner.read": false,
  "card.accountSuspendedBanner.read": false,
  "card.companyTransactions.read": false,
  "card.connectFundingSourceButton.read": false,
  "card.fullNavigation.read": false,
  "card.notificationsLimitDrop.edit": false,
  "card.notificationsSpendLimitApproaching.edit": false,
  "card.notificationsSpendLimitReached.edit": false,
  "card.notificationsStatementRequest.edit": false,
  "card.ownPhysicalCard.read": false,
  "card.payments.read": false,
  "card.reportsTransactions.read": false,
  "card.statements.read": false,
  "card.yourTransactions.read": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {
    "card.yourTransactions.read": true,
  } as PartialPermissions,
  [UserRole.BOOKKEEPER]: {
    "card.companyTransactions.read": true,
    "card.fullNavigation.read": true,
    "card.notificationsStatementRequest.edit": true,
    "card.payments.read": true,
    "card.statements.read": true,
  } as PartialPermissions,
  [UserRole.ADMIN]: {
    "card.account.create": true,
    "card.accountClosedBanner.read": true,
    "card.accountSuspendedBanner.read": true,
    "card.companyTransactions.read": true,
    "card.connectFundingSourceButton.read": true,
    "card.fullNavigation.read": true,
    "card.notificationsLimitDrop.edit": true,
    "card.notificationsSpendLimitApproaching.edit": true,
    "card.notificationsSpendLimitReached.edit": true,
    "card.notificationsStatementRequest.edit": true,
    "card.ownPhysicalCard.read": true,
    "card.payments.read": true,
    "card.statements.read": true,
    "card.yourTransactions.read": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {
    "card.fullNavigation.read": true,
  } as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {} as PartialPermissions,
  [DepositsRole.ADMIN]: {
    "card.fullNavigation.read": true,
    "card.ownPhysicalCard.read": true,
  } as PartialPermissions,
  null: {} as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {} as PartialPermissions,
  true: {
    "card.reportsTransactions.read": true,
  } as PartialPermissions,
};

// permissionsEligibleForCopilot determines what permissions are eligible for use in Copilot mode.
// Please use caution around what should be set to true and see go/td-wiki for details.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "card.account.create": false,
  "card.accountClosedBanner.read": false,
  "card.accountSuspendedBanner.read": false,
  "card.companyTransactions.read": false,
  "card.connectFundingSourceButton.read": false,
  "card.fullNavigation.read": false,
  "card.notificationsLimitDrop.edit": false,
  "card.notificationsSpendLimitApproaching.edit": false,
  "card.notificationsSpendLimitReached.edit": false,
  "card.notificationsStatementRequest.edit": false,
  "card.ownPhysicalCard.read": false,
  "card.payments.read": false,
  "card.reportsTransactions.read": false,
  "card.statements.read": false,
  "card.yourTransactions.read": false,
};
