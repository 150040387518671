import { useAccountInfo } from "@/domains/Primitives/shared/hooks/useAccountInfo";

export const useAccountStatus = () => {
  const { data: accountData, loading, error } = useAccountInfo();
  return {
    accountStatus: accountData?.account?.status,
    isLoading: loading,
    hasError: !!error,
  };
};
