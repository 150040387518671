import type { userStatusQuery as UserStatusQuery } from "@/data/__generated__/queries.generated";
import { userStatusQuery } from "@/data/queries";
import { UserStatus } from "@/data/sharedTypes";
import { useQuery } from "@/features/Apollo";

/**
 * N.B do not call usePermission in this hook. An instance of this hook is used
 * before the context provider is rendered.
 */
export const useHasEmailBeenVerified = () => {
  const { data, loading, error } = useQuery<UserStatusQuery>(userStatusQuery);

  const { user } = data ?? {};

  const mustSeeEmailVerificationScreen =
    user?.status == UserStatus.PENDING_ACTIVATION;

  return {
    mustSeeEmailVerificationScreen,
    loading,
    data,
    error,
  };
};
