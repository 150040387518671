export const getLastPerformanceMark = (markName: string) => {
  if (window.performance?.getEntriesByName) {
    const mark = window.performance.getEntriesByName(markName, "mark")?.pop();

    if (mark && !(mark instanceof PerformanceMark)) {
      // this should never happen, and it's only here to please TS
      throw new Error(
        `Expected performance.getEntriesByName to return a PerformanceMark when given "mark" as an argument, but got ${mark.entryType} instead`,
      );
    }

    return mark;
  }

  return undefined;
};

export const hasPerformanceMark = (markName: string) => {
  if (window.performance?.getEntriesByName) {
    const markEntries = window.performance.getEntriesByName(markName, "mark");
    return markEntries?.length > 0;
  }

  return false;
};

export const controllerMountPerformanceMark = (
  controllerSessionId: string | undefined,
) => `performanceMarks.${controllerSessionId}.mount`;
