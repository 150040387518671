import type {
  DepositsPaymentStatus,
  PositivePayStatus,
} from "@/__generated__/globalTypes";
import type { CurrencyCode, PaymentTypes } from "@/data/sharedTypes";
import type {
  BillsPageTab,
  BillsPageTabs,
  BillPayIntegrationPayload,
  BillPayResyncPayload,
} from "@/domains/Cash/features/AccountsPayable/sharedTypes";
import type {
  LinkPayrollProviderSteps,
  LinkPayrollMethods,
} from "@/domains/Cash/features/LinkPayrollProvider/sharedTypes";
import type {
  AuthorizationFormFields,
  ReverseWireSteps,
} from "@/domains/Cash/features/ReverseWire/sharedTypes";
import type { SortByCategory } from "@/domains/Cash/features/Vendors/sharedTypes";
import type { CashAccountTabs } from "@/domains/Primitives/features/CashAccountPicker/sharedTypes";
import type { CashTransactionDecisionSource } from "@/domains/Primitives/features/CashTransactionsDetailsPane/sharedTypes";

export enum CashAnalyticsEvents {
  BankingTaxDocumentDownloadClicked = "banking.tax-document-download.clicked",
  CashUpsellStart = "cash.upsell.start_application",
  PayrollModalClosed = "payroll.modal.closed",
  PayrollModalOpened = "payroll.modal.opened",
  PayrollModalInteracted = "payroll.modal.interacted",
  CashBillDraftDetailsEditClicked = "cash.bill.draft-details-edit-clicked",
  CashBillDraftDetailsReviewClicked = "cash.bill.draft-details-review-clicked",
  CashBillPayPageDateFiltered = "cash.bills-pay-page-date.filtered",
  CashBillPayPageShown = "cash.bill-pay-page.shown",
  CashBillPayDashboardMounted = "cash.bill.dashboard.mounted",
  CashBillsExportClicked = "cash.bills.export-clicked",
  CashBillsIntegrationAuthenticationErrorBannerClicked = "cash.bill.integration-authentication-error-banner.clicked",
  CashBillsIntegrationAuthenticationErrorBannerMounted = "cash.bill.integration-authentication-error-banner.mounted",
  CashBillsIntegrationEmptyGLCategoriesErrorBannerMounted = "cash.bill.integration-empty-gl-categories-error-banner.mounted",
  CashBillsIntegrationResyncBillsErrorBannerClicked = "cash.bill.integration-resync-bills-error-banner.clicked",
  CashBillsIntegrationResyncBillsErrorBannerMounted = "cash.bill.integration-resync-bills-error-banner.mounted",
  CashBillsIntegrationsAccountSetupBannerClicked = "cash.bill.integrations-account-setup-banner.clicked",
  CashBillsIntegrationsAccountSetupBannerDismissed = "cash.bill.integrations-account-setup-banner.dismissed",
  CashBillsIntegrationsAccountSetupBannerMounted = "cash.bill.integrations-account-setup-banner.mounted",
  CashBillsIntegrationVersionErrorBannerClicked = "cash.bill.integration-version-error-banner.clicked",
  CashBillsIntegrationVersionErrorBannerMounted = "cash.bill.integration-version-error-banner.mounted",
  CashBillsQboResyncBillsErrorBannerResyncFailure = "cash.bill.qbo-resync-bills-error-banner-resync-failure",
  CashBillsQboResyncBillsErrorBannerResyncSuccess = "cash.bill.qbo-resync-bills-error-banner-resync-success",
  CashBillsMarkNotDuplicateAttempt = "cash.bill.mark-not-duplicate.attempt",
  CashBillsMarkNotDuplicateSuccess = "cash.bill.mark-not-duplicate.success",
  CashBillsMarkNotDuplicateFailure = "cash.bill.mark-not-duplicate.failure",
  CashBillsMarkPaidAttempt = "cash.bill.mark-paid.attempt",
  CashBillsMarkPaidSuccess = "cash.bill.mark-paid.success",
  CashBillsMarkPaidFailure = "cash.bill.mark-paid.failure",
  CashBillsSyncErrorsModalMounted = "cash.bill.sync-errors-modal.mounted",
  CashBillsSyncErrorsModalResolveClicked = "cash.bill.sync-errors-modal-resolve.clicked",
  CashBillReopenCanceledBillClicked = "cash.bill.reopen-canceled-bill.clicked",
  CashBillRevertDraftAttempt = "cash.bill.revert-draft.attempt",
  CashBillRevertDraftSuccess = "cash.bill.revert-draft.success",
  CashBillRevertDraftFailure = "cash.bill.revert-draft.failure",
  CashBillRevertExternallyPaidToDraftAttempt = "cash.bill.revert-externally-paid-to-draft.attempt",
  CashBillRevertExternallyPaidToDraftSuccess = "cash.bill.revert-externally-paid-to-draft.success",
  CashBillRevertExternallyPaidToDraftFailure = "cash.bill.revert-externally-paid-to-draft.failure",
  CashPayablesTablesTabClicked = "cash.bill.tables-tab.clicked",
  CashGrowthExperimentEmptyStateShown = "cash-growth.experiment.empty-state.shown",
  CashGrowthExperimentEmptyStateInteracted = "cash-growth.experiment.empty-state.interacted",
  CashHelpCenterDepositLimits = "cash.help.center.deposit.limits",
  CashAddFundsUnavailablePageMounted = "cash.add.funds.unavailable.page.shown",
  CashVendorAddVendorButtonClicked = "cash.vendor_add_vendors_button.clicked",
  CashVendorAddVendorStepMounted = "cash.vendor_add_vendors_step.mounted",
  CashVendorReviewAndImportVendorsStepMounted = "cash.vendor_review_and_import_vendors_step.mounted",
  CashVendorBulkVendorUploadAddViaCsvButtonClicked = "cash.vendor_bulk_vendor_upload_add_via_csv_button.clicked",
  CashVendorBulkVendorUploadReviewSectionGoBackButtonClicked = "cash.vendor_bulk_vendor_upload_review_section_go_back_button.clicked",
  CashVendorBulkVendorUploadUploadSectionNextButtonClicked = "cash.vendor_bulk_vendor_upload_upload_section_next_button.clicked",
  CashVendorBulkVendorUploadUploadSectionDownloadTemplateButtonClicked = "cash.vendor_bulk_vendor_upload_upload_section_download_template_button.clicked",
  CashVendorsQueryCount = "cash.vendors.query.count",
  CashVendorsSortBy = "cash.vendors.sort-by",
  CashVendorsSendPaymentDropdownClicked = "cash.vendors.send-payment-dropdown.clicked",
  CashVendorInitiatedPayment = "cash.vendor_initiated_payment.clicked",
  CashVendorCreated = "cash.vendor.created",
  CashVendorCreatedError = "cash.vendor.created-error",
  CashVendorEdited = "cash.vendor.edited",
  CashVendorEditedError = "cash.vendor.edited-error",
  CashVendorPaymentInstrumentCreated = "cash.vendor_payment_instrument.created",
  CashVendorPaymentInstrumentCreatedError = "cash.vendor_payment_instrument.created-error",
  CashVendorPaymentInstrumentEdited = "cash.vendor_payment_instrument.edited",
  CashVendorPaymentInstrumentEditedError = "cash.vendor_payment_instrument.edited-error",
  CashVendorNotificationCheckboxToggled = "cash.vendor_notification_checkbox.toggled",
  CashTransactionsShowAccountDetailsClicked = "cash.transactions.account-details.clicked",
  CashTransactionsShowAccountBalanceClicked = "cash.transactions.account-balance.clicked",
  CashTransactionsManageAllocationsClicked = "cash.transactions.manage-allocations-clicked",
  CashTransactionsLifetimeEarnedYieldTooltipHover = "cash.transactions.lifetime-earned-yield-tooltip-hover",
  CashTransactionsShowMoreClicked = "cash.transactions.show-more.clicked",
  CashTransactionsTableRowClicked = "cash.transactions.table-row.clicked",
  CashTransactionsFundsDropdownClicked = "cash.transactions.funds-dropdown.clicked",
  CashTransactionsAddFundsClicked = "cash.transactions.add-funds.clicked",
  CashTransactionsCreateBookTransferClicked = "cash.transactions.create-book-transfer.clicked",
  CashTransactionsSendPaymentClicked = "cash.transactions.send-payment.clicked",
  CashTransactionsPayBillClicked = "cash.transactions.pay-bill.clicked",
  CashTransactionsReverseWireClicked = "cash.transactions.reverse-wire.clicked",
  CashTransactionsLinkPayrollProviderClicked = "cash.transactions.link-payroll-provider.clicked",
  CashAccountPickerClicked = "cash.account-picker.clicked",
  CashFDICAnnouncementModalClosed = "cash.fdic-mmf.announcement-modal.closed",
  CashFDICAnnouncementModalDefaultClicked = "cash.fdic-mmf.announcement-modal.default-button.clicked",
  CashFDICAnnouncementModalToSettingsPage = "cash.fdic-mmf.announcement-modal.settings-page.clicked",
  CashIntlWireBankInfoChangedToJpmModalDismissed = "cash.wire-info-changed-to-jpm.announcement-modal.closed",
  CashIntlWireBankInfoChangedToJpmModalSeeMoreClicked = "cash.wire-info-changed-to-jpm.announcement-modal.default-button.clicked",
  CashIntlWireBankInfoChangedToJpmModalShown = "cash.wire-info-changed-to-jpm.announcement-modal.see-details.clicked",
  CashIntlWireAskUpdateJpmCountryConfigModalDismissed = "cash.wire-ask-update-jpm-country-config.announcement-modal.dismiss.clicked",
  CashIntlWireAskUpdateJpmCountryConfigModalShown = "cash.wire-ask-update-jpm-country-config.announcement-modal.shown",
  CashTransactionsApiVersion = "cash.transactions.api-version",
  CashTransactionsAddFundsByCheckClicked = "cash.transactions.add-funds-by-check.clicked",
  CashTransactionsBookTransferClicked = "cash.transactions.book-transfer.clicked",
  CashTransactionsFilterChanged = "cash.transactions.filter.changed",
  CashTransactionsDownloadWireDetailsClicked = "cash.transactions.download-wire-details.clicked",
  CashTransactionsDownloadVerificationLetterClicked = "cash.transactions.download-verification-letter.clicked",
  CashTransactionsBlockCounterpartyConfirm = "cash.transactions.positive-pay-block.confirm",
  CashTransactionsTrustCounterpartyConfirm = "cash.transactions.positive-pay-trust.confirm",
  CashTransactionsClearPositivePayConfirm = "cash.transactions.positive-pay-clear.confirm",
  CashTransactionsAccountBalanceModalAddFundsClicked = "cash.transactions.account-balance-modal-add-funds.clicked",
  CashTransactionsAccountBalanceModalAddFundsByCheckClicked = "cash.transactions.account-balance-modal-add-funds-by-check.clicked",
  CashTransactionsAccountBalanceModalBookTransferClicked = "cash.transactions.account-balance-modal-book-transfer.clicked",
  CashTransactionsTabChanged = "cash.transactions.tab.changed",
  CashTransactionsTourInteraction = "cash.transactions-tour.interaction",
  CashTransactionsPaymentDetailsPaneShown = "cash.transactions.payment-details.shown",
  CashTransactionsV2PageShown = "cash.transactions-v2-page.shown",
  CashAccountHoldingsSettingsClicked = "cash.account-holdings.settings.clicked",
  CashAccountHoldingsInvestmentTypeSelected = "cash.account-holdings.investment-type.selected",
  CashAccountHoldingsPrimaryButtonClicked = "cash.account-holdings.primary-button.clicked",
  CashApprovalViewInDashboardClicked = "cash.approval.view-in-dashboard.clicked",
  CashApprovalApprovePaymentClicked = "cash.approval.approve-payment.clicked",
  CashApprovalApprovePaymentSuccess = "cash.approval.approve-payment.success",
  CashApprovalApprovePaymentError = "cash.approval.approve-payment.error",
  CashApprovalDenyPaymentClicked = "cash.approval.deny-payment.clicked",
  CashApprovalDenyPaymentSuccess = "cash.approval.deny-payment.success",
  CashApprovalDenyPaymentError = "cash.approval.deny-payment.error",
  CashFundAddFinancialAccountClicked = "cash.fund.add-financial-account.clicked",
  CashFundSectionButtonClicked = "cash.fund.section-button.clicked",
  CashFundByCheckSectionClicked = "cash.fund-by-cheque.section-button.clicked",
  CashFundByCheckInactiveDepositsAccount = "cash.fund-by-cheque.inactive-deposits-account",
  CashFundByCheckMissingPermissions = "cash.fund-by-cheque.missing-permissions",
  CashFundByCheckSuccessPageShown = "cash.fund-by-cheque.success-page.shown",
  CashFundDepositMethodClicked = "cash.fund.deposit_method.clicked",
  CashFundTakeoverExit = "cash.fund.takeover.exit",
  CashBookTransferPageLoaded = "cash.book-transfer.page.loaded",
  CashBookTransferSectionButtonClicked = "cash.book-transfer.section-button.clicked",
  CashBookTransferSubmitButtonClicked = "cash.book-transfer.submit-button.clicked",
  CashFundByDirectDepositShown = "cash.fund.by-direct-deposit.shown",
  CashFundACHSuccess = "cash.fund.ach.success",
  CashSafetyListWarningBannerDismissed = "cash.safety-list-warning-banner.dismissed",
  CashSafetyListWarningConfirmationClicked = "cash.safety-list-warning-confirmation.clicked",
  CashSendSectionButtonClick = "cash.send.section-button.clicked",
  CashSendPaymentType = "cash.send.payment-type",
  CashSendAccountType = "cash.send.account-type",
  CashSendPaymentScheduled = "cash.send.payment-scheduled",
  CashSendPaymentRecurring = "cash.send.payment-recurring",
  CashSendCreateCounterPartySuccess = "cash.send.create-counterparty.success",
  CashSendCreateCounterPartyError = "cash.send.create-counterparty.error",
  CashSendCreatePaymentSuccess = "cash.send.create-payment.success",
  CashSendCreatePaymentError = "cash.send.create-payment.error",
  CashSendEditPaymentSuccess = "cash.send.edit-payment.success",
  CashSendEditPaymentError = "cash.send.edit-payment.error",
  CashSendUpdateContactAlertShown = "cash.send.update-contact-alert.shown",
  CashSendExistingPaymentInstrumentModalShown = "cash.send.existing-payment-instrument-modal.shown",
  CashSendSuccessPageShown = "cash.send.success-page.shown",
  CashSendRoutingNumberFundsEligibilityErrorShown = "cash.send.routing-number-funds-eligibility-error.shown",
  CashSendDisabledInternationalWireContactSupportClicked = "cash.send.disabled-international-wire-contact-support.clicked",
  CashSendMoneyPageLoaded = "cash.sendMoney.page-loaded",
  CashSendMoneySubmitSuccess = "cash.sendMoney.submit.success",
  CashSendMoneySubmitError = "cash.sendMoney.submit.error",
  CashSendMoneyEditPaymentInstrumentShown = "cash.sendMoney.editPaymentInstrument.shown",
  CashStatementsDownloadClicked = "cash.statements.download.clicked",
  CashPositivePayRestrictAchDebitChanged = "cash.positive-pay.restrict-ach-debit.changed",
  CashAccountClosureSettingClicked = "cash.account-closure.setting.clicked",
  CashAccountClosureSettingModalConfirmationClicked = "cash.account-closure.setting-modal-confirmation.clicked",
  CashPaymentsCancelPaymentClicked = "cash.payments.cancel-payment.clicked",
  CashPaymentsEditPaymentClicked = "cash.payments.edit-payment.clicked",
  CashPaymentsViewProcessedRecurringClicked = "cash.payments.view-processed-recurring.clicked",
  CashPaymentsPositivePayModalOpened = "cash.payments.positive-pay-modal.opened",
  CashOnboardingWelcomeModalShown = "cash.onboarding-welcome-modal.shown",
  CashOnboardingWelcomeModalCtaClicked = "cash.onboarding-welcome-modal-cta.clicked",
  CashCloseAccountFormStep = "cash.close_account.step",
  CashConnectPayrollGustoBenefits = "cash.connect_payroll.gusto_benefits",
  CashConnectPayrollConnectToGusto = "cash.connect_payroll.connect_to_gusto",
  CashConnectPayrollGustoWithCashSteps = "cash.connect_payroll.gusto_with_cash_steps",
  CashConnectPayrollRipplingWithCashSteps = "cash.connect_payroll.rippling_with_cash_steps",
  CashConnectPayrollTrinetWithCashSteps = "cash.connect_payroll.trinet_with_cash_steps",
  CashConnectPayrollAdpWithCashSteps = "cash.connect_payroll.adp_with_cash_steps",
  CashConnectPayrollQuickbooksWithCashSteps = "cash.connect_payroll.quickbooks_with_cash_steps",
  CashConnectPayrollPaychexWithCashSteps = "cash.connect_payroll.paychex_with_cash_steps",
  CashOnboardingSkipAddFunds = "cash.onboarding.skip_add_funds",
  CashOnboardingGoToAddFunds = "cash.onboarding.go_to_add_funds",
  CashTakeoverBackButtonClicked = "cash.takeover.back_button.clicked",
  CashTakeoverNextButtonClicked = "cash.takeover.next_button.clicked",
  // Multiple Cash accounts
  CashMultipleAccountsOnboardingIntroGetStartedClicked = "cash.multiple.accounts.onboarding.intro.get.started.clicked",
  CashMultipleAccountsOnboardingIntroLearnMoreClicked = "cash.multiple.accounts.onboarding.intro.learn.more.clicked",
  CashMultipleAccountsOnboardingIntroCloseClicked = "cash.multiple.accounts.onboarding.intro.close.clicked",
  CashMultipleAccountsOnboardingHowItWorksAddAccountClicked = "cash.multiple.accounts.onboarding.how.it.works.add.account.clicked",
  CashMultipleAccountsOnboardingHowItWorksLearnMoreClicked = "cash.multiple.accounts.onboarding.how.it.works.learn.more.clicked",
  CashMultipleAccountsOnboardingHowItWorksCloseClicked = "cash.multiple.accounts.onboarding.how.it.works.close.clicked",
  CashMultipleAccountsOnboardingAccountNameNextClicked = "cash.multiple.accounts.onboarding.account.name.next.clicked",
  CashMultipleAccountsOnboardingAccountNameLearnMoreClicked = "cash.multiple.accounts.onboarding.account.name.learn.more.clicked",
  CashMultipleAccountsOnboardingAccountNameCloseClicked = "cash.multiple.accounts.onboarding.account.name.close.clicked",
  CashMultipleAccountsOnboardingTermsFinishClicked = "cash.multiple.accounts.onboarding.terms.finish.clicked",
  CashMultipleAccountsOnboardingTermsLearnMoreClicked = "cash.multiple.accounts.onboarding.terms.learn.more.clicked",
  CashMultipleAccountsOnboardingTermsCloseClicked = "cash.multiple.accounts.onboarding.terms.close.clicked",
  CashMultipleAccountsOnboardingSuccessTransferFundsClicked = "cash.multiple.accounts.onboarding.success.transfer.funds.clicked",
  CashMultipleAccountsOnboardingSuccessCloseClicked = "cash.multiple.accounts.onboarding.success.close.clicked",
  CashMultipleAccountsToggleAccount = "cash.transactions.toggle.account",
  CashMultipleAccountsAddAccountTooltipShown = "cash.transactions.add.account.tooltip.shown",
  CashMultipleAccountsAddAccountTooltipClicked = "cash.transactions.add.account.tooltip.clicked",
  CashMultipleAccountsAddAccountCTAClicked = "cash.transactions.add.account.cta.clicked",
  CashMultipleAccountsHeaderAddAccountButtonClicked = "cash.transactions.add.account.header.button.clicked",
  CashMultipleAccountsToggleAllAccounts = "cash.transactions.toggle.all.accounts",
  // Individual Account Offboarding
  CashIndividualAccountOffboardingCloseAccountClicked = "cash.individual.account.offboarding.close.account.clicked",
  CashIndividualAccountOffboardingIntroModalShown = "cash.individual.account.offboarding.intro.modal.shown",
  CashIndividualAccountOffboardingNextPageClicked = "cash.individual.account.offboarding.next.page.clicked",
  CashIndividualAccountOffboardingPreviousPageClicked = "cash.individual.account.offboarding.previous.page.clicked",
  CashIndividualAccountOffboardingOverviewModalShown = "cash.individual.account.offboarding.overview.modal.shown",
  CashIndividualAccountOffboardingSubmitted = "cash.individual.account.offboarding.submitted",
  CashIndividualAccountOffboardingSuccessPageShown = "cash.individual.account.offboarding.success.page.shown",
  CashIndividualAccountOffboardingModalClosed = "cash.individual.account.offboarding.modal.closed",
  // FBO Modal
  CashFBOModalContinued = "cash.insights.fbomodal.continued",
  CashFBOModalOpened = "cash.insights.fbomodal.opened",
  // Column Migration
  ColumnMigrationOutdatedAccountDetailsBannerViewNewDetailsClicked = "column.migration.outdated.account.details.banner.view.new.details.clicked",
  ColumnMigrationOutdatedAccountDetailsBannerDismissed = "column.migration.outdated.account.details.banner.dismissed",
  // Reverse wire
  CashReverseWireControllerMounted = "cash.reverse_wire.controller.mounted",
  CashReverseWireUpdateCompanySettingsClicked = "cash.reverse_wire.update_company_settings.clicked",
  CashReverseWireNextButtonClicked = "cash.reverse_wire.next_button.clicked",
  CashReverseWirePreviousButtonClicked = "cash.reverse_wire.previous_button.clicked",
  CashReverseWirePayrollProviderClicked = "cash.reverse_wire.payroll_provider.clicked",
  CashReverseWireAuthorizationAmountTypeClicked = "cash.reverse_wire.authorization_amount.clicked",
  CashReverseWireAuthorizationSubmitClicked = "cash.reverse_wire.authorization.submit.clicked",
  CashReverseWirePayrollBannerLearnMoreClicked = "cash.reverse_wire_payroll_banner.learn_more.clicked",
  CashReverseWirePayrollBannerSetUpClicked = "cash.reverse_wire_payroll_banner.set_up.clicked",
  // Link Payroll Provider
  CashLinkPayrollProviderControllerMounted = "cash.link_payroll_provider.controller.mounted",
  CashLinkPayrollProviderBackClicked = "cash.link_payroll_provider.back.clicked",
  CashLinkPayrollProviderAchDebitLearnMoreClicked = "cash.link_payroll_provider.ach_debit_learn_more.clicked",
  CashLinkPayrollProviderReverseWireLearnMoreClicked = "cash.link_payroll_provider.reverse_wire_learn_more.clicked",
  CashLinkPayrollProviderExitToCashAccountsClicked = "cash.link_payroll_provider.exit_to_cash_accounts.clicked",
  CashLinkPayrollProviderExistingPayrollVendor = "cash.link_payroll_provider.existing_payroll_vendor.mounted",
  CashLinkPayrollProviderSelectPayrollProviderNextClicked = "cash.link_payroll_provider.select_payroll_provider.next_button.clicked",
  CashLinkPayrollProviderPayrollInformationSupportClicked = "cash.link_payroll_provider.payroll_information.support.clicked",
  CashLinkPayrollProviderLinkPayrollMethodClicked = "cash.link_payroll_provider.link_payroll_method.clicked",
  CashLinkPayrollProviderPayrollInformationDoneClicked = "cash.link_payroll_provider.payroll_information.done.clicked",
  CashLinkPayrollProviderAuthorizationFormDownloadClicked = "cash.link_payroll_provider.authorization_form_download.clicked",
  CashLinkPayrollProviderViewExistingReverseWireVendorClicked = "cash.link_payroll_provider.view_existing_reverse_wire_vendor.clicked",
  CashLinkPayrollProviderViewPayrollProviderWebsiteClicked = "cash.link_payroll_provider.view_payroll_provider_website.clicked",
  CashLinkPayrollProviderAccountInfoDownloadClicked = "cash.link_payroll_provider.account_info_download.clicked",
  CashLinkPayrollProviderPayrollProviderSuggestionClicked = "cash.link_payroll_provider.payroll_provider_suggestion.clicked",
  // Payment approvals
  CashTransactionApprovedSourceClicked = "cash.transaction_approved_source.clicked",
  CashTransactionDeniedSourceClicked = "cash.transaction_denied_source.clicked",
  CashPaymentApprovalsTakeoverSetupClicked = "cash.payment_approvals_takeover_setup.clicked",
  CashPaymentApprovalsTakeoverRemindMeLaterClicked = "cash.payment_approvals_takeover.remind_me_later.clicked",
  CashPaymentApprovalsTakeoverBackClicked = "cash.payment_approvals_takeover.back.clicked",
  CashPaymentApprovalsSettingsToggle = "cash.payment_approvals_settings.toggle",
  CashPaymentApprovalsSettingsManageButtonClicked = "cash.payment_approvals_settings.manage_button.clicked",
  CashPaymentApprovalsSettingsReviewRequestButtonClicked = "cash.payment_approvals_settings.review_request_button.clicked",
  CashPaymentApprovalsSendClicked = "cash.payment_approvals_send.clicked",
  CashPaymentApprovalsTakeoverMounted = "cash.payment_approvals_takeover.mounted",
  CashPaymentApprovalsTakeoverIntroConfirm = "cash.payment_approvals_takeover.intro.confirm",
  CashPaymentApprovalsTakeoverConfigureAddThresholdClicked = "cash.payment_approvals_takeover.configure.add_threshold.clicked",
  CashPaymentApprovalsTakeoverConfigureDeleteThresholdClicked = "cash.payment_approvals_takeover.configure.delete_threshold.clicked",
  CashPaymentApprovalsTakeoverConfigureApprovalStepAdd = "cash.payment_approvals_takeover.configure.approval_step.add",
  CashPaymentApprovalsTakeoverConfigureApprovalStepDelete = "cash.payment_approvals_takeover.configure.approval_step.delete",
  CashPaymentApprovalsTakeoverReviewConfirm = "cash.payment_approvals_takeover.review.confirm",
  CashPaymentApprovalsTakeoverReviewPolicyChangeApprove = "cash.payment_approvals_takeover.review_policy_change.approve",
  CashPaymentApprovalsTakeoverReviewPolicyChangeDeny = "cash.payment_approvals_takeover.review_policy_change.deny",
  CashPaymentApprovalsModalTurnOffConfirm = "cash.payment_approvals_modal.turn_off.confirm",
  CashPaymentApprovalsModalTurnOffReviewApprove = "cash.payment_approvals_modal.turn_off_review.approve",
  CashPaymentApprovalsModalTurnOffReviewDeny = "cash.payment_approvals_modal.turn_off_review.deny",
  // Automatic transfers
  CashAutomaticTransfersCtaClicked = "cash.automatic_transfers_cta.clicked",
  CashAutomaticTransfersCtaDismissed = "cash.automatic_transfers_cta.dismissed",
  CashAutomaticTransfersSetUpSuccess = "cash.automatic_transfers.setup.success",
  CashAutomaticTransfersUpdateSuccess = "cash.automatic_transfers.update.success",
  CashAutomaticTransferSettingsDisabled = "cash.automatic_transfer_settings.disabled",
  CashAutomaticTransfersManageAccountActionClicked = "cash.automatic_transfers.manage_account.action.clicked",
  // Local currency wires
  CashLCWBeneficiaryCurrencyFocused = "cash.local_currency_wires.beneficiary_currency.focused",
  CashLCWBeneficiaryCurrencyChanged = "cash.local_currency_wires.beneficiary_currency.changed",
  // [Next batch of analytics goes here]
}

type EmptyPayload = {};

type LocalCurrencyWiresPayload = {
  version: number;
};

type LocalCurrencyWiresValuePayload = LocalCurrencyWiresPayload & {
  value: string;
};

type CashVendorsSortByPayload = {
  sortBy: SortByCategory;
};

type PayrollModalOpened = {
  allowOtherPayroll: boolean;
  overrideProvider: string | null;
  type: string;
};

type BillIdPayload = {
  billId?: string;
};

type BillSyncErrorsPayload = {
  billIds: string[] | undefined;
};

type PayrollModalInteracted = {
  action?: string;
  step: string;
  type?: string;
};

type CashGrowthExperimentEmptyState = {
  value?: string;
  action?: string;
  experimentLocation: string;
};

type CashPayablesTablesTabClicked = {
  currentTab: BillsPageTab;
};

type CashBillPayPageDateFilteredPayload = {
  currentTab: BillsPageTabs;
  endDate: Date | null;
  presetId?: string | undefined;
  startDate: Date | null;
};

type QueryPayload = {
  queryCount?: number;
  queryText?: string;
};

type VendorInitiatedPaymentActionPayload = {
  vendorId?: string;
};

type VendorActionPayload = {
  vendorId?: string;
  location?: string;
};

type PaymentInstrumentActionPayload = {
  vendorId?: string;
  location: string;
};

type CashTransactionsAPIPayload = {
  transactionsApiVersion: 1 | 2;
};

type CashTransactionsTableRowPayload = {
  transactionId: string | null;
  type?: string;
  warningState?: boolean;
};

type CashTransactionsActionPayload = {
  type: PositivePayStatus | null | string;
};

type CashTransactionsFundsDropdownPayload = {
  type: string;
};

type CashTransactionsAddFundsPayload = {
  version?: number;
};

type CashAccountHoldingsInvestmentTypePayload = {
  type: string;
};

type CashAccountHoldingsPrimaryButtonPayload = {
  step: string;
};

type CashApprovalViewInDashboardClickedPayload = {
  type: "bills" | "transactions";
};

type CashFundSectionButtonPayload = {
  section: string;
  version?: number;
};

type CashFundByCheckSectionClickedPayload = {
  section: string;
};

type CashSendSectionButtonClickPayload = {
  section: string;
  localCurrencyWiresEnabled?: boolean;
  currencyCode?: CurrencyCode;
};

type CashFundDepositMethodPayload = {
  method: PaymentTypes;
};

type CashFundACHSuccessPayload = {
  transactionId: string;
};

type CashSafetyListWarningConfirmationPayload = {
  value: string;
};

type CashBookTransferVersionPayload = {
  version?: number;
};

type CashBookTransferSectionButtonPayload = CashBookTransferVersionPayload & {
  section: string;
};

type CashSendMoneyPayload = {
  action?: "create" | "edit";
  version?: number;
  transactionId?: string;
  isBillPayConversionEnabled?: boolean;
};

type CashSendPaymentTypePayload = {
  type?: string;
};

type CashSendAccountTypePayload = {
  type?: string;
  class?: string;
};

type CashSendPaymentRecurringPayload = {
  frequency?: string;
};

type CashPositivePayRestrictAchDebitPayload = {
  enabled: boolean;
};

type CashTabPayload = {
  tab: string;
};

type CashTransactionsTourDismissedPayload = {
  status: "dismissed" | "initiated";
};

type CashTransactionsPaymentDetailsPayload = {
  status?: DepositsPaymentStatus;
};

type CashCloseAccountFormStepPayload = {
  step: string;
};

type MultipleAccountsOnboardingPayload = {
  onboardingAccountId: string;
};

type CashTakeoverButtonPayload = {
  currentStep: string;
  additionalInfo?: {
    [key: string]: string;
  };
};

type CashTransactionDecisionSourceClickedPayload = {
  source: CashTransactionDecisionSource;
  version?: string;
};

interface MultipleAccountsOnboardingWithNamePayload
  extends MultipleAccountsOnboardingPayload {
  accountName: string;
}

interface MultipleAccountsOnboardingCompletedPayload
  extends MultipleAccountsOnboardingWithNamePayload {
  accountId: string;
}

interface MultipleAccountsToggleAccountPayload {
  accountId: string;
}

interface CashFBOModalPayload {
  origin: string;
}
interface IndividualAccountOffboardingPayload {
  accountId: string;
}

interface CashPaymentApprovalsTakeoverSetupClickedPayload {
  optionValue?: string;
}

interface CashPaymentApprovalsSettingsTogglePayload {
  toggleValue: Boolean;
  version?: string;
}

interface CashPaymentApprovalsTakeoverMountedPayload {
  currentStep: "intro" | "configure_thresholds" | "exception_list" | "review";
}

interface CashPaymentApprovalsTakeoverIntroConfirmPayload {
  optionValue: "default" | "customized" | "turn_off";
}

interface CashPaymentApprovalsTakeoverBackClickedPayload {
  currentStep?: "intro" | "configure_thresholds" | "exception_list" | "review";
}

interface CashAutomaticTransfersCtaClickedPayload {
  value: "setup" | "update";
}

interface CashAutomaticTransfersActionClickedPayload {
  value: "setup" | "update";
}

interface CashAutomaticTransfersValuesPayload {
  minimumBalance?: string;
  businessDay?: string;
}

interface CashAccountPickerPayload {
  accountId?: string;
  allAccounts?: boolean;
  isPrimary?: boolean;
  tab: CashAccountTabs;
}

// Reverse Wire Payloads
interface ReverseWirePayrollProviderClickedPayload {
  providerName: string;
}

interface ReverseWireControllerMountedPayload {
  providerName?: string;
}

interface ReverseWireNextClickedPayload {
  step: ReverseWireSteps;
}

interface ReverseWireAuthorizationAmountTypeClickedPayload {
  type: AuthorizationFormFields["authorizationType"];
}

// LinkPayrollProvider Payloads
interface CashLinkPayrollSelectPayrollProviderClickedPayload {
  payrollMethod: LinkPayrollMethods;
  payrollProvider: string;
}

interface CashLinkPayrollMethodOptionsClickedPayload {
  payrollMethod: LinkPayrollMethods;
}

interface CashLinkPayrollProviderBackClickedPayload {
  step: LinkPayrollProviderSteps;
}

interface CashLinkPayrollProviderExistingPayrollVendorPayload {
  vendorId?: string;
  providerName: string;
}

interface CashSendPaymentPayload {
  version?: number;
}

export type CashEventSchemaMap = {
  [CashAnalyticsEvents.BankingTaxDocumentDownloadClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashOnboardingSkipAddFunds]: EmptyPayload;
  [CashAnalyticsEvents.CashOnboardingGoToAddFunds]: EmptyPayload;
  [CashAnalyticsEvents.CashTakeoverBackButtonClicked]: CashTakeoverButtonPayload;
  [CashAnalyticsEvents.CashTakeoverNextButtonClicked]: CashTakeoverButtonPayload;
  [CashAnalyticsEvents.CashUpsellStart]: EmptyPayload;
  [CashAnalyticsEvents.PayrollModalClosed]: PayrollModalInteracted;
  [CashAnalyticsEvents.PayrollModalOpened]: PayrollModalOpened;
  [CashAnalyticsEvents.PayrollModalInteracted]: PayrollModalInteracted;
  [CashAnalyticsEvents.CashPayablesTablesTabClicked]: CashPayablesTablesTabClicked;
  [CashAnalyticsEvents.CashBillPayDashboardMounted]: EmptyPayload;
  [CashAnalyticsEvents.CashBillsMarkNotDuplicateAttempt]: BillIdPayload;
  [CashAnalyticsEvents.CashBillsMarkNotDuplicateSuccess]: BillIdPayload;
  [CashAnalyticsEvents.CashBillsMarkNotDuplicateFailure]: BillIdPayload;
  [CashAnalyticsEvents.CashBillsMarkPaidAttempt]: BillIdPayload;
  [CashAnalyticsEvents.CashBillsMarkPaidSuccess]: BillIdPayload;
  [CashAnalyticsEvents.CashBillsMarkPaidFailure]: BillIdPayload;
  [CashAnalyticsEvents.CashBillsIntegrationAuthenticationErrorBannerClicked]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsIntegrationAuthenticationErrorBannerMounted]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsIntegrationEmptyGLCategoriesErrorBannerMounted]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsIntegrationResyncBillsErrorBannerClicked]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsIntegrationResyncBillsErrorBannerMounted]: EmptyPayload;
  [CashAnalyticsEvents.CashBillsIntegrationsAccountSetupBannerClicked]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsIntegrationsAccountSetupBannerDismissed]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsIntegrationsAccountSetupBannerMounted]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsIntegrationVersionErrorBannerClicked]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsIntegrationVersionErrorBannerMounted]: BillPayIntegrationPayload;
  [CashAnalyticsEvents.CashBillsQboResyncBillsErrorBannerResyncFailure]: BillPayResyncPayload;
  [CashAnalyticsEvents.CashBillsQboResyncBillsErrorBannerResyncSuccess]: BillPayResyncPayload;
  [CashAnalyticsEvents.CashBillsSyncErrorsModalMounted]: BillSyncErrorsPayload;
  [CashAnalyticsEvents.CashBillsSyncErrorsModalResolveClicked]: BillIdPayload;
  [CashAnalyticsEvents.CashBillPayPageShown]: EmptyPayload;
  [CashAnalyticsEvents.CashBillPayPageDateFiltered]: CashBillPayPageDateFilteredPayload;
  [CashAnalyticsEvents.CashBillDraftDetailsEditClicked]: BillIdPayload;
  [CashAnalyticsEvents.CashBillDraftDetailsReviewClicked]: BillIdPayload;
  [CashAnalyticsEvents.CashBillRevertDraftAttempt]: BillIdPayload;
  [CashAnalyticsEvents.CashBillRevertDraftSuccess]: BillIdPayload;
  [CashAnalyticsEvents.CashBillRevertDraftFailure]: BillIdPayload;
  [CashAnalyticsEvents.CashBillRevertExternallyPaidToDraftAttempt]: BillIdPayload;
  [CashAnalyticsEvents.CashBillRevertExternallyPaidToDraftSuccess]: BillIdPayload;
  [CashAnalyticsEvents.CashBillRevertExternallyPaidToDraftFailure]: BillIdPayload;
  [CashAnalyticsEvents.CashBillsExportClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashBillReopenCanceledBillClicked]: BillIdPayload;
  [CashAnalyticsEvents.CashGrowthExperimentEmptyStateInteracted]: CashGrowthExperimentEmptyState;
  [CashAnalyticsEvents.CashGrowthExperimentEmptyStateShown]: CashGrowthExperimentEmptyState;
  [CashAnalyticsEvents.CashVendorAddVendorButtonClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorAddVendorStepMounted]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorReviewAndImportVendorsStepMounted]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorBulkVendorUploadAddViaCsvButtonClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorBulkVendorUploadReviewSectionGoBackButtonClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorBulkVendorUploadUploadSectionNextButtonClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorBulkVendorUploadUploadSectionDownloadTemplateButtonClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorsQueryCount]: QueryPayload;
  [CashAnalyticsEvents.CashVendorsSendPaymentDropdownClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorInitiatedPayment]: VendorInitiatedPaymentActionPayload;
  [CashAnalyticsEvents.CashVendorCreated]: VendorActionPayload;
  [CashAnalyticsEvents.CashVendorCreatedError]: VendorActionPayload;
  [CashAnalyticsEvents.CashVendorEdited]: VendorActionPayload;
  [CashAnalyticsEvents.CashVendorEditedError]: VendorActionPayload;
  [CashAnalyticsEvents.CashVendorPaymentInstrumentCreated]: PaymentInstrumentActionPayload;
  [CashAnalyticsEvents.CashVendorPaymentInstrumentEdited]: PaymentInstrumentActionPayload;
  [CashAnalyticsEvents.CashVendorPaymentInstrumentCreatedError]: PaymentInstrumentActionPayload;
  [CashAnalyticsEvents.CashVendorPaymentInstrumentEditedError]: PaymentInstrumentActionPayload;
  [CashAnalyticsEvents.CashVendorNotificationCheckboxToggled]: EmptyPayload;
  [CashAnalyticsEvents.CashHelpCenterDepositLimits]: EmptyPayload;
  [CashAnalyticsEvents.CashAddFundsUnavailablePageMounted]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsShowAccountDetailsClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsShowAccountBalanceClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsManageAllocationsClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsLifetimeEarnedYieldTooltipHover]: EmptyPayload;
  [CashAnalyticsEvents.CashFDICAnnouncementModalClosed]: EmptyPayload;
  [CashAnalyticsEvents.CashFDICAnnouncementModalDefaultClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashFDICAnnouncementModalToSettingsPage]: EmptyPayload;
  [CashAnalyticsEvents.CashIntlWireBankInfoChangedToJpmModalDismissed]: EmptyPayload;
  [CashAnalyticsEvents.CashIntlWireBankInfoChangedToJpmModalSeeMoreClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashIntlWireBankInfoChangedToJpmModalShown]: EmptyPayload;
  [CashAnalyticsEvents.CashIntlWireAskUpdateJpmCountryConfigModalDismissed]: EmptyPayload;
  [CashAnalyticsEvents.CashIntlWireAskUpdateJpmCountryConfigModalShown]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsApiVersion]: CashTransactionsAPIPayload;
  [CashAnalyticsEvents.CashTransactionsShowMoreClicked]: CashTransactionsActionPayload;
  [CashAnalyticsEvents.CashTransactionsTableRowClicked]: CashTransactionsTableRowPayload;
  [CashAnalyticsEvents.CashTransactionsFundsDropdownClicked]: CashTransactionsFundsDropdownPayload;
  [CashAnalyticsEvents.CashTransactionsAddFundsClicked]: CashTransactionsAddFundsPayload;
  [CashAnalyticsEvents.CashTransactionsCreateBookTransferClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsAddFundsByCheckClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsBookTransferClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsSendPaymentClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsPayBillClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsReverseWireClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsLinkPayrollProviderClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashAccountPickerClicked]: CashAccountPickerPayload;
  [CashAnalyticsEvents.CashTransactionsFilterChanged]: CashTransactionsActionPayload;
  [CashAnalyticsEvents.CashTransactionsDownloadWireDetailsClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsDownloadVerificationLetterClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsBlockCounterpartyConfirm]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsTrustCounterpartyConfirm]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsClearPositivePayConfirm]: CashTransactionsActionPayload;
  [CashAnalyticsEvents.CashTransactionsAccountBalanceModalAddFundsClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsAccountBalanceModalAddFundsByCheckClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsAccountBalanceModalBookTransferClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsTourInteraction]: CashTransactionsTourDismissedPayload;
  [CashAnalyticsEvents.CashTransactionsPaymentDetailsPaneShown]: CashTransactionsPaymentDetailsPayload;
  [CashAnalyticsEvents.CashTransactionsV2PageShown]: EmptyPayload;
  [CashAnalyticsEvents.CashAccountHoldingsSettingsClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashAccountHoldingsInvestmentTypeSelected]: CashAccountHoldingsInvestmentTypePayload;
  [CashAnalyticsEvents.CashAccountHoldingsPrimaryButtonClicked]: CashAccountHoldingsPrimaryButtonPayload;
  [CashAnalyticsEvents.CashApprovalViewInDashboardClicked]: CashApprovalViewInDashboardClickedPayload;
  [CashAnalyticsEvents.CashApprovalApprovePaymentClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashApprovalApprovePaymentSuccess]: EmptyPayload;
  [CashAnalyticsEvents.CashApprovalApprovePaymentError]: EmptyPayload;
  [CashAnalyticsEvents.CashApprovalDenyPaymentClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashApprovalDenyPaymentSuccess]: EmptyPayload;
  [CashAnalyticsEvents.CashApprovalDenyPaymentError]: EmptyPayload;
  [CashAnalyticsEvents.CashFundAddFinancialAccountClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashFundSectionButtonClicked]: CashFundSectionButtonPayload;
  [CashAnalyticsEvents.CashFundByCheckSectionClicked]: CashFundByCheckSectionClickedPayload;
  [CashAnalyticsEvents.CashFundByCheckInactiveDepositsAccount]: EmptyPayload;
  [CashAnalyticsEvents.CashFundByCheckMissingPermissions]: EmptyPayload;
  [CashAnalyticsEvents.CashFundByCheckSuccessPageShown]: EmptyPayload;
  [CashAnalyticsEvents.CashFundDepositMethodClicked]: CashFundDepositMethodPayload;
  [CashAnalyticsEvents.CashFundTakeoverExit]: EmptyPayload;
  [CashAnalyticsEvents.CashBookTransferSectionButtonClicked]: CashBookTransferSectionButtonPayload;
  [CashAnalyticsEvents.CashBookTransferPageLoaded]: CashBookTransferVersionPayload;
  [CashAnalyticsEvents.CashBookTransferSubmitButtonClicked]: CashBookTransferVersionPayload;
  [CashAnalyticsEvents.CashFundByDirectDepositShown]: EmptyPayload;
  [CashAnalyticsEvents.CashFundACHSuccess]: CashFundACHSuccessPayload;
  [CashAnalyticsEvents.CashSafetyListWarningBannerDismissed]: EmptyPayload;
  [CashAnalyticsEvents.CashSafetyListWarningConfirmationClicked]: CashSafetyListWarningConfirmationPayload;
  [CashAnalyticsEvents.CashSendSectionButtonClick]: CashSendSectionButtonClickPayload;
  [CashAnalyticsEvents.CashSendPaymentType]: CashSendPaymentTypePayload;
  [CashAnalyticsEvents.CashSendAccountType]: CashSendAccountTypePayload;
  [CashAnalyticsEvents.CashSendPaymentScheduled]: EmptyPayload;
  [CashAnalyticsEvents.CashSendPaymentRecurring]: CashSendPaymentRecurringPayload;
  [CashAnalyticsEvents.CashSendCreateCounterPartySuccess]: EmptyPayload;
  [CashAnalyticsEvents.CashSendCreateCounterPartyError]: EmptyPayload;
  [CashAnalyticsEvents.CashSendCreatePaymentSuccess]: CashSendPaymentPayload;
  [CashAnalyticsEvents.CashSendCreatePaymentError]: CashSendPaymentPayload;
  [CashAnalyticsEvents.CashSendEditPaymentSuccess]: CashSendPaymentPayload;
  [CashAnalyticsEvents.CashSendEditPaymentError]: CashSendPaymentPayload;
  [CashAnalyticsEvents.CashSendUpdateContactAlertShown]: EmptyPayload;
  [CashAnalyticsEvents.CashSendExistingPaymentInstrumentModalShown]: EmptyPayload;
  [CashAnalyticsEvents.CashSendSuccessPageShown]: EmptyPayload;
  [CashAnalyticsEvents.CashSendDisabledInternationalWireContactSupportClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashSendRoutingNumberFundsEligibilityErrorShown]: EmptyPayload;
  [CashAnalyticsEvents.CashSendMoneyPageLoaded]: CashSendMoneyPayload;
  [CashAnalyticsEvents.CashSendMoneySubmitSuccess]: CashSendMoneyPayload;
  [CashAnalyticsEvents.CashSendMoneySubmitError]: CashSendMoneyPayload;
  [CashAnalyticsEvents.CashSendMoneyEditPaymentInstrumentShown]: CashSendMoneyPayload;
  [CashAnalyticsEvents.CashStatementsDownloadClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPositivePayRestrictAchDebitChanged]: CashPositivePayRestrictAchDebitPayload;
  [CashAnalyticsEvents.CashPaymentsCancelPaymentClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashAccountClosureSettingClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashAccountClosureSettingModalConfirmationClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentsEditPaymentClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentsViewProcessedRecurringClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashTransactionsTabChanged]: CashTabPayload;
  [CashAnalyticsEvents.CashPaymentsPositivePayModalOpened]: EmptyPayload;
  [CashAnalyticsEvents.CashVendorsSortBy]: CashVendorsSortByPayload;
  [CashAnalyticsEvents.CashOnboardingWelcomeModalShown]: EmptyPayload;
  [CashAnalyticsEvents.CashOnboardingWelcomeModalCtaClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashCloseAccountFormStep]: CashCloseAccountFormStepPayload;
  [CashAnalyticsEvents.CashConnectPayrollGustoBenefits]: EmptyPayload;
  [CashAnalyticsEvents.CashConnectPayrollConnectToGusto]: EmptyPayload;
  [CashAnalyticsEvents.CashConnectPayrollGustoWithCashSteps]: EmptyPayload;
  [CashAnalyticsEvents.CashConnectPayrollRipplingWithCashSteps]: EmptyPayload;
  [CashAnalyticsEvents.CashConnectPayrollTrinetWithCashSteps]: EmptyPayload;
  [CashAnalyticsEvents.CashConnectPayrollAdpWithCashSteps]: EmptyPayload;
  [CashAnalyticsEvents.CashConnectPayrollQuickbooksWithCashSteps]: EmptyPayload;
  [CashAnalyticsEvents.CashConnectPayrollPaychexWithCashSteps]: EmptyPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingIntroGetStartedClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingIntroLearnMoreClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingIntroCloseClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingHowItWorksAddAccountClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingHowItWorksLearnMoreClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingHowItWorksCloseClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingAccountNameNextClicked]: MultipleAccountsOnboardingWithNamePayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingAccountNameLearnMoreClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingAccountNameCloseClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingTermsFinishClicked]: MultipleAccountsOnboardingWithNamePayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingTermsLearnMoreClicked]: MultipleAccountsOnboardingWithNamePayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingTermsCloseClicked]: MultipleAccountsOnboardingCompletedPayload;
  [CashAnalyticsEvents.CashMultipleAccountsToggleAccount]: MultipleAccountsToggleAccountPayload;
  [CashAnalyticsEvents.CashMultipleAccountsAddAccountTooltipShown]: EmptyPayload;
  [CashAnalyticsEvents.CashMultipleAccountsAddAccountTooltipClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashMultipleAccountsAddAccountCTAClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsHeaderAddAccountButtonClicked]: MultipleAccountsOnboardingPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingSuccessTransferFundsClicked]: MultipleAccountsOnboardingCompletedPayload;
  [CashAnalyticsEvents.CashMultipleAccountsOnboardingSuccessCloseClicked]: MultipleAccountsOnboardingCompletedPayload;
  [CashAnalyticsEvents.CashMultipleAccountsToggleAllAccounts]: EmptyPayload;
  [CashAnalyticsEvents.CashFBOModalContinued]: CashFBOModalPayload;
  [CashAnalyticsEvents.CashFBOModalOpened]: CashFBOModalPayload;
  [CashAnalyticsEvents.CashIndividualAccountOffboardingCloseAccountClicked]: IndividualAccountOffboardingPayload;
  [CashAnalyticsEvents.CashIndividualAccountOffboardingIntroModalShown]: IndividualAccountOffboardingPayload;
  [CashAnalyticsEvents.CashIndividualAccountOffboardingNextPageClicked]: IndividualAccountOffboardingPayload;
  [CashAnalyticsEvents.CashIndividualAccountOffboardingPreviousPageClicked]: IndividualAccountOffboardingPayload;
  [CashAnalyticsEvents.CashIndividualAccountOffboardingOverviewModalShown]: IndividualAccountOffboardingPayload;
  [CashAnalyticsEvents.CashIndividualAccountOffboardingSubmitted]: IndividualAccountOffboardingPayload;
  [CashAnalyticsEvents.CashIndividualAccountOffboardingSuccessPageShown]: IndividualAccountOffboardingPayload;
  [CashAnalyticsEvents.CashIndividualAccountOffboardingModalClosed]: IndividualAccountOffboardingPayload;
  [CashAnalyticsEvents.ColumnMigrationOutdatedAccountDetailsBannerViewNewDetailsClicked]: EmptyPayload;
  [CashAnalyticsEvents.ColumnMigrationOutdatedAccountDetailsBannerDismissed]: EmptyPayload;
  // Cash Reverse Wire
  [CashAnalyticsEvents.CashReverseWireControllerMounted]: ReverseWireControllerMountedPayload;
  [CashAnalyticsEvents.CashReverseWireUpdateCompanySettingsClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashReverseWireNextButtonClicked]: ReverseWireNextClickedPayload;
  [CashAnalyticsEvents.CashReverseWirePreviousButtonClicked]: ReverseWireNextClickedPayload;
  [CashAnalyticsEvents.CashReverseWirePayrollProviderClicked]: ReverseWirePayrollProviderClickedPayload;
  [CashAnalyticsEvents.CashReverseWireAuthorizationAmountTypeClicked]: ReverseWireAuthorizationAmountTypeClickedPayload;
  [CashAnalyticsEvents.CashReverseWireAuthorizationSubmitClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashReverseWirePayrollBannerLearnMoreClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashReverseWirePayrollBannerSetUpClicked]: EmptyPayload;
  // Cash Link Payroll Provider
  [CashAnalyticsEvents.CashLinkPayrollProviderControllerMounted]: EmptyPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderAchDebitLearnMoreClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderReverseWireLearnMoreClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderExitToCashAccountsClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderExistingPayrollVendor]: CashLinkPayrollProviderExistingPayrollVendorPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderSelectPayrollProviderNextClicked]: CashLinkPayrollSelectPayrollProviderClickedPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderPayrollInformationSupportClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderLinkPayrollMethodClicked]: CashLinkPayrollMethodOptionsClickedPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderPayrollInformationDoneClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderAuthorizationFormDownloadClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderViewExistingReverseWireVendorClicked]: VendorInitiatedPaymentActionPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderViewPayrollProviderWebsiteClicked]: CashLinkPayrollSelectPayrollProviderClickedPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderAccountInfoDownloadClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderPayrollProviderSuggestionClicked]: ReverseWirePayrollProviderClickedPayload;
  [CashAnalyticsEvents.CashLinkPayrollProviderBackClicked]: CashLinkPayrollProviderBackClickedPayload;
  // Cash Payment Approvals
  [CashAnalyticsEvents.CashTransactionApprovedSourceClicked]: CashTransactionDecisionSourceClickedPayload;
  [CashAnalyticsEvents.CashTransactionDeniedSourceClicked]: CashTransactionDecisionSourceClickedPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverSetupClicked]: CashPaymentApprovalsTakeoverSetupClickedPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverRemindMeLaterClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverBackClicked]: CashPaymentApprovalsTakeoverBackClickedPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsSettingsToggle]: CashPaymentApprovalsSettingsTogglePayload;
  [CashAnalyticsEvents.CashPaymentApprovalsSettingsManageButtonClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsSettingsReviewRequestButtonClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsSendClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverMounted]: CashPaymentApprovalsTakeoverMountedPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverIntroConfirm]: CashPaymentApprovalsTakeoverIntroConfirmPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverConfigureAddThresholdClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverConfigureDeleteThresholdClicked]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverConfigureApprovalStepAdd]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverConfigureApprovalStepDelete]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverReviewConfirm]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverReviewPolicyChangeApprove]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsTakeoverReviewPolicyChangeDeny]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsModalTurnOffConfirm]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsModalTurnOffReviewApprove]: EmptyPayload;
  [CashAnalyticsEvents.CashPaymentApprovalsModalTurnOffReviewDeny]: EmptyPayload;
  // Cash Automatic Transfers
  [CashAnalyticsEvents.CashAutomaticTransfersCtaClicked]: CashAutomaticTransfersCtaClickedPayload;
  [CashAnalyticsEvents.CashAutomaticTransfersCtaDismissed]: EmptyPayload;
  [CashAnalyticsEvents.CashAutomaticTransfersSetUpSuccess]: CashAutomaticTransfersValuesPayload;
  [CashAnalyticsEvents.CashAutomaticTransfersUpdateSuccess]: CashAutomaticTransfersValuesPayload;
  [CashAnalyticsEvents.CashAutomaticTransferSettingsDisabled]: EmptyPayload;
  [CashAnalyticsEvents.CashAutomaticTransfersManageAccountActionClicked]: CashAutomaticTransfersActionClickedPayload;
  // Cash LCW Beneficiary Currency
  [CashAnalyticsEvents.CashLCWBeneficiaryCurrencyFocused]: LocalCurrencyWiresPayload;
  [CashAnalyticsEvents.CashLCWBeneficiaryCurrencyChanged]: LocalCurrencyWiresValuePayload;
};
