import { endOfDay, isValid, parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { DateTime } from "luxon";

const INVALID_DATE = "Invalid Date";

/**
 * IMPORTANT: when using posted_at or settled_at, we MUST display that as dates ONLY (without times) and in UTC to be
 * consistent with our backend. Otherwise users will be confused why transactions are off in their accounting books.
 * See threads post https://threads.com/34371620455 or PR https://github.com/brexhq/credit_card/pull/5879
 * @deprecated formatDate relies on Luxon, which is to be deprecated [https://brexhq.atlassian.net/browse/UXT-793].
 * Please use formatISODate instead.
 * */
export const formatDate = (
  date: string,
  options: any = {
    weekday: undefined,
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    year: undefined,
    hour12: true,
    inUTC: false,
  },
) => {
  let dateObj = DateTime.fromISO(date);
  if (options.inUTC) {
    // if inUTC is true, use UTC time
    dateObj = dateObj.setZone("utc");
  } else {
    // by default, date is in local time
    dateObj = dateObj.toLocal();
  }
  return dateObj.toLocaleString({
    weekday: options.weekday,
    month: options.month,
    day: options.day,
    hour: options.hour,
    minute: options.minute,
    hour12: options.hour12,
    year: options.year,
  });
};

export const sortDatesAscending = (dateA: string, dateB: string) =>
  new Date(dateA).getTime() - new Date(dateB).getTime();

type QueryDateToUtc = {
  (dt: null | undefined): null;
  (dt: DateTime): DateTime;
  (dt: DateTime | null | undefined): null | DateTime;
};

// DatePicker returns `DateTime`s using the local timezone, but we want to
// query for the chosen date as a UTC datetime. See https://github.com/brexhq/credit_card/pull/5879
export const queryDateToUtc = ((dt: DateTime | null | undefined) => {
  if (!dt) {
    return null;
  }
  return dt.setZone("utc").startOf("day");
}) as QueryDateToUtc;

export const formatUtc = (date: Date, formatFormat: string): string =>
  format(utcToZonedTime(date, "utc"), formatFormat);

/**
 *
 * @param date ISO date string
 * @param formatString format of date string
 * @returns Formatted date string or "Invalid Date" string
 */
export const formatISODate = (
  date: string,
  formatString = "MMM dd, yyyy",
  inUtc = false,
): string => {
  if (!isValid(parseISO(date))) {
    return INVALID_DATE;
  }
  return inUtc
    ? formatUtc(parseISO(date), formatString)
    : format(parseISO(date), formatString);
};

export const getISOString = (dateStr?: string, isEndOfDay?: boolean) => {
  if (dateStr) {
    const date = isEndOfDay ? endOfDay(parseISO(dateStr)) : parseISO(dateStr);
    return isValid(date) ? date.toISOString() : null;
  }

  return null;
};

export const getUtcString = (dateStr?: string, isEndOfDay?: boolean) => {
  const date = dateStr ? DateTime.fromISO(dateStr) : null;
  if (!date || !date.isValid) {
    return null;
  }
  return isEndOfDay
    ? date.toFormat("yyyy-MM-dd'T'23:59:59.999999'Z'")
    : date?.toFormat("yyyy-MM-dd'T'00:00:00.000000'Z'");
};

// Get the Date corresponding to the start or end of the current UTC date
// Example:
//   '2021-10-02T03:00:00.000Z' (EST: Oct 1, UTC: Oct 2)
//      -> (isEndOfDay = false) '2021-10-02T23:59:59.999999Z'
//      -> (isEndOfDay = true)  '2021-10-02T00:00:00.000000Z'
export const getUtcDate = (date: Date, isEndOfDay?: boolean): Date =>
  isEndOfDay
    ? new Date(formatUtc(date, "yyyy-MM-dd'T'23:59:59.999999'Z'"))
    : new Date(formatUtc(date, "yyyy-MM-dd'T'00:00:00.000000'Z'"));

export const toIso8601 = (date: Date): string => format(date, "yyyy-MM-dd");

/**
 *
 * @param day date string formatted as "2021-09-01"
 * @returns Formatted date as "Tue Aug 31 2021 20:00:00 GMT-0400"
 */
export const buildUtcDate = (day: string): Date =>
  new Date(`${day}T00:00:00.000Z`);

export const MANUAL_REVIEW_DURATION_IN_DAYS = "2-3"; // 2-3 business days to manually review application

export type GraphQLDateTime =
  | GQLScalars.Date
  | GQLScalars.LocalDateTime
  | GQLScalars.Time;

export const commonDateFormatters = {
  /**
   * Formats a date string to `MMM d, yyyy` format.
   *
   * "2021-09-01" -> "Sep 1, 2021"
   * @param date date string
   * @returns formatted date string
   */
  mmmDYyyy: (date: string | GraphQLDateTime) =>
    formatISODate(date, "MMM d, yyyy"),
};
