import { StatusMessage, SecondaryButton } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import * as React from "react";
import AlmostDoneIllustration from "@assets/illustrations/empower/spot/almost-done.png";
import { StyledPageErrorMessage } from "@/components/Error/StyledPageErrorMessage";
import { useHelpshift } from "@/features/Helpshift/hooks/useHelpshift";
import { UnauthorizedLayout } from "@/features/UnauthorizedLayout";

export const MaintenancePage = () => {
  const { t } = useTranslation();

  const { contactSupport } = useHelpshift();
  return (
    <UnauthorizedLayout>
      <StyledPageErrorMessage>
        <StatusMessage
          graphic={AlmostDoneIllustration}
          title={t("system-maintenance")}
          description={t(
            "well-be-back-soon-for-urgent-requests-please-contact-support",
          )}
          actions={[
            {
              id: "contactSupport",
              action: (
                <SecondaryButton onClick={contactSupport}>
                  {t("contact-support")}
                </SecondaryButton>
              ),
            },
          ]}
        />
      </StyledPageErrorMessage>
    </UnauthorizedLayout>
  );
};
