import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "account.sso.update": false,
  "trust.mfaModal.read": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {
    "trust.mfaModal.read": true,
  } as PartialPermissions,
  [UserRole.BOOKKEEPER]: {} as PartialPermissions,
  [UserRole.ADMIN]: {
    "account.sso.update": true,
    "trust.mfaModal.read": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {
    "trust.mfaModal.read": true,
  } as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {} as PartialPermissions,
  [DepositsRole.ADMIN]: {
    "trust.mfaModal.read": true,
  } as PartialPermissions,
  null: {} as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {} as PartialPermissions,
  true: {} as PartialPermissions,
};

// permissionsEligibleForCopilot determines what permissions are eligible for use in Copilot mode.
// Please use caution around what should be set to true and see go/td-wiki for details.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "account.sso.update": false,
  "trust.mfaModal.read": false,
};
