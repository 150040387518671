import { isWeekend, formatISO } from "date-fns";
import type { DateTime } from "luxon";
import { isUSBankingHoliday } from "./bankHolidays";

/**
 * @param date
 * @returns `false` if the day is a weekend day or a holiday
 */
export const isBusinessDay = (date: DateTime) =>
  !isUSBankingHoliday({ date, year: date.year }) && date.weekday < 6;

type AddBusinessDays = {
  date: DateTime;
  days: number;
};

export const addBusinessDaysHelper: (args: AddBusinessDays) => AddBusinessDays =
  ({ date, days }) => {
    // If no more business days to add return final date
    if (days === 0) {
      return { date, days };
    }
    const nextDate = date.plus({ days: 1 });
    // If next day is business day, recurse with one fewer business days to add
    if (isBusinessDay(nextDate)) {
      return addBusinessDaysHelper({ date: nextDate, days: days - 1 });
    }
    // If next day is not business day, recurse with same business days to add
    return addBusinessDaysHelper({ date: nextDate, days });
  };

export const addBusinessDays = ({ date, days }: AddBusinessDays) => {
  if (days === 0) {
    return isBusinessDay(date)
      ? date
      : addBusinessDaysHelper({ date, days: 1 }).date;
  }
  return addBusinessDaysHelper({ date, days }).date;
};

export const isPaymentDateDisabled = (
  date: Date,
  disabledIsoDates?: string[],
): boolean =>
  isWeekend(date) ||
  (disabledIsoDates ?? []).includes(
    formatISO(date, { representation: "date" }),
  );
