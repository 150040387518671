import React, { useContext, useEffect, useMemo } from "react";
import {
  Statsig,
  StatsigContext,
  StatsigProvider as StatsigInternalProvider,
} from "statsig-react";
import { useUserPropertiesForAnalytics } from "@/features/Analytics/contexts/Analytics";
import { filterUserProperties } from "@/features/Analytics/helpers/filterUserProperties";
import { getEnvironment } from "@/helpers";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const APP_ENV_MAP = {
  prod: "production",
  stg: "staging",
  dev: "development",
  localhost: "development",
};

export const StatsigProvider = ({ children }: Props): JSX.Element => {
  const { analyticsUserProperties } = useUserPropertiesForAnalytics();

  const filteredUserProperties = useMemo(
    () =>
      analyticsUserProperties
        ? filterUserProperties(analyticsUserProperties)
        : undefined,
    [analyticsUserProperties],
  );

  const statsigCustomProperties = useMemo(() => {
    return {
      companyId: filteredUserProperties?.customerAccountId,
      role: filteredUserProperties?.role,
      productTier: filteredUserProperties?.productTier,
      isInternalSignup: filteredUserProperties?.isInternalSignup,
      signupDatetime: filteredUserProperties?.signupDatetime,
    };
  }, [filteredUserProperties]);

  const statsigCustomIDProperties = useMemo(() => {
    return {
      accountID: filteredUserProperties?.customerAccountId ?? "",
    };
  }, [filteredUserProperties]);

  return (
    <StatsigInternalProvider
      sdkKey={getEnvironment("STATSIG_API_KEY") as string}
      waitForInitialization={false}
      user={{
        userID: filteredUserProperties?.customerUserId ?? undefined,
        custom: statsigCustomProperties,
        customIDs: statsigCustomIDProperties,
      }}
      options={{
        environment: {
          tier: APP_ENV_MAP[
            getEnvironment("APP_ENV") as "prod" | "stg" | "dev" | "localhost"
          ],
        },
      }}
    >
      {children}
    </StatsigInternalProvider>
  );
};

export type MockStatsigConfig = Props & {
  gateOverrides?: {
    gateName: string;
    value: boolean;
  };
  configOverrides?: {
    configName: string;
    value: object;
  };
};

/**
 * Allows to Mock Statsig overrides for local testing.
 *
 * Usage:
 * `<MockStatsigOverrides gateOverrides={{ gateName: "gateName", value: true }}>
 *   <Component />
 * </MockStatsigOverrides>`
 *
 * Ensure that the component is wrapped in a StatsigProvider (which is already done in the AppWithAnalytics.tsx)
 */
export const MockStatsigOverrides = ({
  gateOverrides,
  configOverrides,
  children,
}: MockStatsigConfig): JSX.Element | null => {
  const statsigContext = useContext(StatsigContext);
  useEffect(() => {
    if (gateOverrides) {
      Statsig.overrideGate(gateOverrides.gateName, gateOverrides.value);
    }
    if (configOverrides) {
      Statsig.overrideConfig(configOverrides.configName, configOverrides.value);
    }
  }, [configOverrides, gateOverrides, statsigContext.initialized]);

  return statsigContext.initialized ? <> {children} </> : null;
};
