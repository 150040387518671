import { useSetAtom } from "jotai";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { currentFeatureFlagsAtom } from "@/features/DevPanel/atoms/currentFeatureFlags";
import type { FeatureFlagsSet } from "@/features/LaunchDarkly/helpers/allFeatureFlags";
import type { CamelCasedProperties } from "@/features/LaunchDarkly/sharedTypes";

export const useFeatureFlagTracker = <
  Flags extends Partial<CamelCasedProperties<FeatureFlagsSet>>,
>(
  featureFlags: Flags,
  // `ignoreInFeatureFlagTracker` is passed in certain cases to prevent reads of feature flags
  // from being saved in the centralized store for tracking. This is especially necessary for
  // reads in the Dev Panel, since it reads ALL flags -- which would defeat the purpose of
  // tracking in the first place.
  ignoreInFeatureFlagTracker?: boolean,
) => {
  const setCurrentFeatureFlags = useSetAtom(currentFeatureFlagsAtom);
  const [currentComponentFlags, setCurrentComponentFlags] = React.useState<
    string[]
  >([]);

  // Currently-used feature flags are tracked in an internal store per each invocation
  // of the `useFeatureFlags` hook. This enables us to use the useEffect cleanup
  // functionality to remove specific feature flag usage on unmount.
  const usageId = React.useRef(uuid());

  // When the feature flags read from this invocation of the `useFeatureFlags` hook changes,
  // we save the set of currently-used flags to the internal store. A cleanup function is
  // returned to remove flags used in this invocation of `useFeatureFlags`.
  React.useEffect(() => {
    const componentId = usageId.current;
    if (currentComponentFlags.length) {
      setCurrentFeatureFlags((all) => ({
        ...all,
        [componentId]: currentComponentFlags,
      }));
    }
    return () => {
      setCurrentFeatureFlags(({ [componentId]: _, ...rest }) => ({
        ...rest,
      }));
    };
  }, [currentComponentFlags, setCurrentFeatureFlags]);

  // We build a proxy to intercept reads from the feature flags object, so that we
  // can track which keys are read in this invocation of `useFeatureFlags`.
  return React.useMemo(
    () =>
      new Proxy(featureFlags, {
        get: (target, prop: string, receiver) => {
          if (
            !ignoreInFeatureFlagTracker &&
            !currentComponentFlags.includes(prop)
          ) {
            setCurrentComponentFlags([...currentComponentFlags, prop]);
          }
          return Reflect.get(target, prop, receiver);
        },
      }),
    [currentComponentFlags, featureFlags, ignoreInFeatureFlagTracker],
  );
};
