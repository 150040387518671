import * as React from "react";
import { useLocation } from "react-router-dom";
import { urls } from "@/helpers/urls";

// @see https://reacttraining.com/react-router/web/guides/scroll-restoration/scroll-to-top
export const ScrollToTop: React.FC = () => {
  const [lastPath, setLastPath] = React.useState("");
  const { pathname } = useLocation();
  React.useEffect(() => {
    // we only want this effect once, when the path has changed
    if (lastPath !== pathname) {
      // when clicking on a cash transaction, we update the URI to reflect
      // the individual transaction ID. this allows deep linking of
      // transactions, which we currently use in e-mails
      // however, updating the URI in this case causes an unwanted scroll
      // to top. so, we prevent any automated scrolling on cash transaction
      // pages. The same is true for spend management transactions.
      if (
        !(
          lastPath.startsWith(urls.cash.transactions()) &&
          pathname.startsWith(urls.cash.transactions())
        ) &&
        !(
          lastPath.startsWith(urls.primitives.invoices.main()) &&
          pathname.startsWith(urls.primitives.invoices.main())
        ) &&
        !(
          lastPath.startsWith(urls.expenses.review()) &&
          pathname.startsWith(urls.expenses.review())
        ) &&
        !(
          lastPath.startsWith(urls.reports.details()) &&
          pathname.startsWith(urls.reports.details())
        )
      ) {
        window.scrollTo(0, 0);
      }
      setLastPath(pathname);
    }
  }, [lastPath, pathname]);
  return null;
};
