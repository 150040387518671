import { atom, getDefaultStore, useSetAtom } from "jotai";
import * as React from "react";
import { TrackedErrorBoundary } from "@/components/Error";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { setChatBubble } from "@/features/ChatBubble/hooks/useChatBubble";
import { Freshchat } from "@/features/Freshchat/helpers/freshchat";
import { useFreshchat } from "@/features/Freshchat/hooks/useFreshchat";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import SpotnanaTwilioFlexChatStyles from "@/features/TravelSupport/components/SpotnanaTwilioFlexStyles";
import { useSpotnanaTwilioChat } from "@/features/TravelSupport/hooks/useSpotnanaTwilioFlex";
import type { TravelSupportClient } from "@/features/TravelSupport/sharedTypes";

const travelSupportClientAtom = atom<TravelSupportClient>({
  isInitialized: false,
  openChat: Promise.resolve,
  dismissChat: Promise.resolve,
  initialize: Promise.resolve,
  clear: Promise.resolve,
});

/**
 * The `travelSupportClient` is a proxy that allows us to expose the chat client
 * to the rest of the app globally via Jotai. This makes it easy to call the
 * chat client methods from anywhere in the app.
 */
export const travelSupportClient = new Proxy(travelSupportClientAtom.init, {
  get: (_, prop: keyof TravelSupportClient) =>
    getDefaultStore().get(travelSupportClientAtom)[prop],
});

const TravelSupportController = () => {
  const setClient = useSetAtom(travelSupportClientAtom);
  const freshchat = useFreshchat();
  const twilioFlex = useSpotnanaTwilioChat();
  const trackEvent = useTrackEvent();
  const { travelSpotnanaTwilioChatEnabled: isSpotnanaTwilioChatEnabled } =
    useFeatureFlags();
  const provider = isSpotnanaTwilioChatEnabled ? "twilio" : "freshchat";

  const isInitialized = React.useMemo(() => {
    if (isSpotnanaTwilioChatEnabled) {
      return twilioFlex.isInitialized;
    }
    return freshchat.isFreshchatInitialized;
  }, [
    isSpotnanaTwilioChatEnabled,
    freshchat.isFreshchatInitialized,
    twilioFlex.isInitialized,
  ]);

  const handleInitialize = React.useCallback(async () => {
    trackEvent(AnalyticsEvents.TravelSupportChatInitialized, { provider });
    if (isSpotnanaTwilioChatEnabled) {
      await twilioFlex.handleInitialize();
    } else {
      // Freshchat is initialized on mount in LazilyInitializedFreshchatProvider
    }
  }, [isSpotnanaTwilioChatEnabled, trackEvent, twilioFlex, provider]);

  const handleOpenChat = React.useCallback(async () => {
    trackEvent(AnalyticsEvents.TravelSupportChatOpened, { provider });
    // Attempt to hide Ada chat if it's open
    try {
      await window?.adaEmbed?.stop();
    } catch {}

    // Open the chat based on the feature flag
    if (isSpotnanaTwilioChatEnabled) {
      twilioFlex.handleOpenChat();
    } else {
      freshchat.openSupport();
    }
    // Close the bubble and reset unread count
    setChatBubble({
      isVisible: false,
      unreadCount: 0,
    });
  }, [
    freshchat,
    isSpotnanaTwilioChatEnabled,
    twilioFlex,
    provider,
    trackEvent,
  ]);

  const handleCloseChat = React.useCallback(async () => {
    trackEvent(AnalyticsEvents.TravelSupportChatClosed, { provider });

    if (isSpotnanaTwilioChatEnabled) {
      twilioFlex.handleCloseChat();
    } else {
      Freshchat.closeAndHideWidget();
    }
    // Close the bubble
    setChatBubble({
      isVisible: false,
    });
  }, [isSpotnanaTwilioChatEnabled, twilioFlex, provider, trackEvent]);

  const handleClear = React.useCallback(async () => {
    trackEvent(AnalyticsEvents.TravelSupportChatCleared, { provider });

    if (isSpotnanaTwilioChatEnabled) {
      twilioFlex.handleClear();
    } else {
      await freshchat.clearUser();
    }
  }, [
    freshchat,
    isSpotnanaTwilioChatEnabled,
    twilioFlex,
    provider,
    trackEvent,
  ]);

  /**
   * On mount, we'll attach the correct client methods based on the feature flag
   * to the atom, exposing the client to the rest of the app globally via Jotai.
   */
  React.useEffect(() => {
    setClient({
      isInitialized,
      openChat: handleOpenChat,
      dismissChat: handleCloseChat,
      initialize: handleInitialize,
      clear: handleClear,
    });
  }, [
    handleClear,
    handleCloseChat,
    handleInitialize,
    handleOpenChat,
    isInitialized,
    setClient,
  ]);

  /**
   * On mount or FF change initialize the chat client.
   * On unmount or FF change, clear the chat client.
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to re-run on ff changes
  React.useEffect(() => {
    void travelSupportClient.initialize();
    return () => {
      void travelSupportClient.clear();
    };
  }, [isSpotnanaTwilioChatEnabled]);

  return (
    <>
      {isSpotnanaTwilioChatEnabled ? <SpotnanaTwilioFlexChatStyles /> : null}
      {/* <FreshchatProvider /> wraps the app and is mounted at AppWithAnalytics */}
    </>
  );
};

const TravelSupportControllerWithErrorBoundary = React.memo(() => {
  return (
    <TrackedErrorBoundary>
      <TravelSupportController />
    </TrackedErrorBoundary>
  );
});

export default withControllerPerformanceMetrics(
  TravelSupportControllerWithErrorBoundary,
);
