import deepEqual from "fast-deep-equal";
import { useState, useEffect, useRef, useCallback } from "react";
import type { CreateGlobalState } from "./createGlobalState";
import { createGlobalState } from "./createGlobalState";
import type { CreateStorage } from "./createStorage";
import type { CacheKey, JsonSerializable } from "./types";

type NewState<T extends JsonSerializable> = T | ((oldState: T) => T);

type UsePersistedStateProps<T extends JsonSerializable> = {
  initialState: T;
  key: CacheKey;
  storage: CreateStorage<T>;
};
/**
 * Hook for managing state that's persisted in a storage provider
 */
export const usePersistedState = <T extends JsonSerializable>({
  initialState,
  key,
  storage,
}: UsePersistedStateProps<T>): [T, (newState: NewState<T>) => void] => {
  const globalState = useRef<CreateGlobalState<T> | null>(null);
  const [state, setState] = useState<T>(() => storage.get(key, initialState));
  const stateRef = useRef<T>(storage.get(key, initialState));

  // this is a workaround for not constantly re-creating the memoized
  // callbacks below
  stateRef.current = state;

  // persist a new state and publish the update
  const persistedSetState = useCallback(
    (newState: NewState<T>) => {
      const newStateValue =
        typeof newState === "function" ? newState(stateRef.current) : newState;
      // persist to local storage provider and state
      storage.set(key, newStateValue);
      setState(newStateValue);
      // publish value update to other hooks using it
      globalState.current?.publish(newStateValue);
    },
    [key, storage],
  );

  // when the storage provider in another tab changes and the key matches,
  // update the value in the current tab and publish the change
  const handleStorageEvent = useCallback(
    ({ key: k, newValue }) => {
      if (k === key) {
        const newState = JSON.parse(newValue);
        if (!deepEqual(stateRef.current, newState)) {
          setState(newState);
          globalState.current?.publish(newState);
        }
      }
    },
    [key],
  );

  // (un)subscribe to `storage` change events on (un)mount
  useEffect(() => {
    window.addEventListener("storage", handleStorageEvent);
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [handleStorageEvent]);

  // (un)subscribe to value changes of the state key
  useEffect(() => {
    globalState.current = createGlobalState<T>(
      key,
      setState,
      storage.get(key, initialState),
    );
    return () => {
      globalState.current?.unsubscribe();
    };
  }, [initialState, key, storage]);

  return [state, persistedSetState];
};
