import { Loader } from "@brexhq/component-library";
import styled from "@emotion/styled";
import * as React from "react";
import { UnauthorizedLayout } from "./UnauthorizedLayout";

const StyledContainer = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const UnauthorizedLayoutLoading: React.FC = () => (
  <React.Suspense fallback={null}>
    <UnauthorizedLayout key="UnauthorizedLayoutLoading" primaryLayout>
      <StyledContainer>
        <Loader />
      </StyledContainer>
    </UnauthorizedLayout>
  </React.Suspense>
);
