// regex pattern found here: https://stackoverflow.com/questions/2332811/capitalize-words-in-string
export const capitalize = (str: string) =>
  str
    .toLocaleLowerCase()
    .replace(/(?:^|\s|["'([{])+\S/, (c) => c.toLocaleUpperCase());

// capitalizes every word
export const capitalizeTitleCase = (str: string) =>
  str.split(" ").map(capitalize).join(" ");

// kebab-case from camel-case words
export const kebabFromCamelCase = (str: string) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? "-" : "") + $.toLowerCase(),
  );
