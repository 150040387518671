import type { ImportRuleType, UserRole } from "@/__generated__/globalTypes";

export enum AnalyticsEvents {
  HrisBannerCtaClicked = "hris.banner.cta.clicked",
  HrisConnectConnectionCreatedSuccess = "hris.connect.connection_created_success",
  HrisConnectReconnectionSuccess = "hris.connect.reconnection_success",
  HrisConnectGetStartedClicked = "hris.connect.get_started.clicked",
  HrisConnectReconnectClicked = "hris.connect.reconnect.clicked",
  HrisInviteEmployeeSettingsSelected = "hris.invite.employee_settings.selected",
  HrisInviteEmployeesSelected = "hris.invite.employees.selected",
  HrisInviteImportTypeSelected = "hris.invite.import_type.selected",
  HrisInviteSubmit = "hris.invite.submit",
  HrisInviteSuccessAddMoreEmployeesClicked = "hris.invite.success.add_more_employees.clicked",
  HrisInviteSuccessDoneClicked = "hris.invite.success.done.clicked",
  HrisManageDisconnectConfirmClicked = "hris.manage.disconnect_confirm.clicked",
  HrisManageDisconnectHrisClicked = "hris.manage.disconnect_hris.clicked",
  HrisManageInviteEmployeesClicked = "hris.manage.invite_employees.clicked",
  HrisManageRemoveEmployeesClicked = "hris.manage.remove_employees.clicked",
  HrisRemoveEmployeesSelected = "hris.remove.employees.selected",
  HrisRemoveSubmit = "hris.remove.submit",
  HrisRemoveSuccessDoneClicked = "hris.remove.success.done.clicked",
  HrisSettingsConnectClicked = "hris.settings.connect.clicked",
  HrisSettingsManageClicked = "hris.settings.manage.clicked",
  HrisSyncingBackToSettingsClicked = "hris.syncing.back_to_settings.clicked",
  HrisTileConnectClicked = "hris.integrations_tile.connect.clicked",
  HrisTileManageClicked = "hris.integrations_tile.manage.clicked",
  HrisUsersInviteClicked = "hris.users.invite.clicked",
  HrisUsersFinishSetupClicked = "hris.users.finish_setup.clicked",
  HrisUsersSuspendClicked = "hris.users.suspend.clicked",
}

type EmptyPayload = {};

type HrisConnectionPayload = {
  hrisConnectionId: string;
};

type HrisEmployeesPayload = {
  hrisConnectionId: string;
  employeeIds: string[];
};

type HrisImportTypePayload = {
  hrisConnectionId: string;
  importType: ImportRuleType;
};

type HrisUserSettingsPayload = {
  hrisConnectionId: string;
  role: UserRole;
  allowCorporateCard: boolean;
  monthlyLimitInCents?: number;
};

type HrisUsersPayload = {
  hrisConnectionId: string;
  userIds: string[];
};

export type EventSchemaMap = {
  [AnalyticsEvents.HrisBannerCtaClicked]: EmptyPayload;
  [AnalyticsEvents.HrisConnectConnectionCreatedSuccess]: HrisConnectionPayload;
  [AnalyticsEvents.HrisConnectGetStartedClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisConnectReconnectClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisConnectReconnectionSuccess]: HrisConnectionPayload;
  [AnalyticsEvents.HrisInviteEmployeeSettingsSelected]: HrisUserSettingsPayload;
  [AnalyticsEvents.HrisInviteEmployeesSelected]: HrisEmployeesPayload;
  [AnalyticsEvents.HrisInviteImportTypeSelected]: HrisImportTypePayload;
  [AnalyticsEvents.HrisInviteSubmit]: HrisConnectionPayload;
  [AnalyticsEvents.HrisInviteSuccessAddMoreEmployeesClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisInviteSuccessDoneClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisManageDisconnectConfirmClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisManageDisconnectHrisClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisManageInviteEmployeesClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisManageRemoveEmployeesClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisRemoveEmployeesSelected]: HrisUsersPayload;
  [AnalyticsEvents.HrisRemoveSubmit]: HrisConnectionPayload;
  [AnalyticsEvents.HrisRemoveSuccessDoneClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisSettingsConnectClicked]: EmptyPayload;
  [AnalyticsEvents.HrisSettingsManageClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisSyncingBackToSettingsClicked]: EmptyPayload;
  [AnalyticsEvents.HrisTileConnectClicked]: EmptyPayload;
  [AnalyticsEvents.HrisTileManageClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisUsersInviteClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisUsersFinishSetupClicked]: HrisConnectionPayload;
  [AnalyticsEvents.HrisUsersSuspendClicked]: HrisConnectionPayload;
};
