import { UserRole, DepositsRole } from "@/__generated__/globalTypes";
import { UserRole as CardRole } from "@/data/sharedTypes";
import type { PermissionsQuery } from "@/domains/App/features/Permissions/data/__generated__/queries.generated";
// Using namespaced imports here, as using named imports would result in 6x the
// lines for each domain and push actual implementation below the fold on most
// reasonably sized screens.
import * as appPermissions from "@/domains/App/helpers/appPermissions";
import * as billPayPermissions from "@/domains/BillPay/helpers/billPayPermissions";
import * as cardPermissions from "@/domains/Card/helpers/cardPermissions";
import * as cashPermissions from "@/domains/Cash/helpers/cashPermissions";
import * as integrationsPermissions from "@/domains/Integrations/helpers/integrationsPermissions";
import * as kycPermissions from "@/domains/Kyc/helpers/kycPermissions";
import * as limitExperiencePermissions from "@/domains/LimitExperience/shared/permissions";
import { primitivesPermissions } from "@/domains/Primitives";
import * as spendPermissions from "@/domains/Spend/helpers/spendPermissions";
import * as teamPermissions from "@/domains/Team/helpers/teamPermissions";
import * as travelPermissions from "@/domains/Travel/shared/permissions";
import * as trustPermissions from "@/domains/Trust/helpers/trustPermissions";
import * as underwritingPermissions from "@/domains/Underwriting/helpers/underwritingPermissions";
import { pick } from "@/helpers/pick";
import * as rewardsPermissions from "@/routes/Rewards/helpers/rewardsPermissions";

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
const defaultPermissions = {
  ...appPermissions.defaultPermissions,
  ...cardPermissions.defaultPermissions,
  ...cashPermissions.defaultPermissions,
  ...spendPermissions.defaultPermissions,
  ...integrationsPermissions.defaultPermissions,
  ...billPayPermissions.defaultPermissions,
  ...underwritingPermissions.defaultPermissions,
  ...teamPermissions.defaultPermissions,
  ...trustPermissions.defaultPermissions,
  ...rewardsPermissions.defaultPermissions,
  ...kycPermissions.defaultPermissions,
  ...limitExperiencePermissions.defaultPermissions,
  ...primitivesPermissions.defaultPermissions,
  ...travelPermissions.defaultPermissions,
};

// TODO: EA is still determining which permissions should be
// revoked for the Expense Admin.
// This array enumerates permissions granted by CardRole.Admin
// that would be removed for a Expense Admin
const permissionsToRevokeForExpenseAdmin: PermissionsKey[] = [];

export type Permissions = typeof defaultPermissions;
export type PermissionsKey = keyof Permissions;

type AllPermissions = { [key in PermissionsKey]: boolean };

type PartialPermissions = Partial<{ [key in PermissionsKey]: true }>;

/**
 * Merges permissions sets so every key has a boolean value based on the
 * defaults and sets of separate permissions for role, deposits role, and
 * manager.
 *
 * Maps through entire set of default permissions and sets values to true when
 * encountering a truthy value for the specific key in any of the supplied
 * permissionsSets or the default value.
 */
const mergePermissionsSets = (
  permissionsSets: PartialPermissions[],
  permissionsEligibleForCopilot?: AllPermissions,
): Permissions => {
  return (
    Object.entries(defaultPermissions)
      .map<[PermissionsKey, boolean]>(([key, defaultValue]) => [
        key as PermissionsKey,
        permissionsSets.some(
          (permissionsSet) => permissionsSet[key as PermissionsKey],
        ) || defaultValue,
      ])
      // Object.fromEntries alternative due to no Edge <18 support
      .reduce(
        (permissions, [key, value]) => ({
          ...permissions,
          [key]: !!permissionsEligibleForCopilot
            ? permissionsEligibleForCopilot[key] && value
            : value,
        }),
        {} as Permissions,
        // Resulting outputs have full runtime test coverage of entire matrix of
        // inputs, so we can afford to assert the types here.
      )
  );
};

export const selectPermissions = ({
  data,
  isInCopilotMode,
  isDemoAccount = false,
  userRole,
}: {
  data: PermissionsQuery;
  isInCopilotMode: boolean | undefined;
  isDemoAccount?: boolean;
  userRole?: UserRole | null;
}): Permissions => {
  const { user } = data;
  if (!user) {
    throw new Error("PermissionsQuery data is missing user");
  }
  if (!user.role) {
    throw new Error("PermissionsQuery data is missing user role");
  }

  let cardRole = user.role as CardRole;
  let depositsRole = user.depositsRole;
  // Product Security introduced a new custom role for a customer: Expense Admin.
  // It behaves similarly to how the frontend treats a Card Admin with
  // some permissions removed.
  const userIsExpenseAdmin = userRole === UserRole.EXPENSE_ADMIN;
  if (userIsExpenseAdmin) {
    cardRole = CardRole.ADMIN;
  }

  // If account is a demo account and the user is a card admin, mock user as a deposits admin.
  if (isDemoAccount && cardRole === CardRole.ADMIN) {
    depositsRole = DepositsRole.ADMIN;
  }

  /* eslint sort-keys: ["error", "asc"] */
  const permissionsByRole: PartialPermissions = {
    [CardRole.REGULAR]: {
      ...appPermissions.permissionsByRole[CardRole.REGULAR],
      ...cardPermissions.permissionsByRole[CardRole.REGULAR],
      ...cashPermissions.permissionsByRole[CardRole.REGULAR],
      ...spendPermissions.permissionsByRole[CardRole.REGULAR],
      ...billPayPermissions.permissionsByRole[CardRole.REGULAR],
      ...underwritingPermissions.permissionsByRole[CardRole.REGULAR],
      ...teamPermissions.permissionsByRole[CardRole.REGULAR],
      ...trustPermissions.permissionsByRole[CardRole.REGULAR],
      ...rewardsPermissions.permissionsByRole[CardRole.REGULAR],
      ...kycPermissions.permissionsByRole[CardRole.REGULAR],
      ...limitExperiencePermissions.permissionsByRole[CardRole.REGULAR],
      ...primitivesPermissions.permissionsByRole[CardRole.REGULAR],
      ...travelPermissions.permissionsByRole[CardRole.REGULAR],
    } as const,
    [CardRole.BOOKKEEPER]: {
      ...appPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...cardPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...cashPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...spendPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...integrationsPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...billPayPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...underwritingPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...teamPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...trustPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...rewardsPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...kycPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...limitExperiencePermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...primitivesPermissions.permissionsByRole[CardRole.BOOKKEEPER],
      ...travelPermissions.permissionsByRole[CardRole.BOOKKEEPER],
    } as const,
    [CardRole.ADMIN]: {
      ...appPermissions.permissionsByRole[CardRole.ADMIN],
      ...cardPermissions.permissionsByRole[CardRole.ADMIN],
      ...cashPermissions.permissionsByRole[CardRole.ADMIN],
      ...spendPermissions.permissionsByRole[CardRole.ADMIN],
      ...integrationsPermissions.permissionsByRole[CardRole.ADMIN],
      ...billPayPermissions.permissionsByRole[CardRole.ADMIN],
      ...underwritingPermissions.permissionsByRole[CardRole.ADMIN],
      ...teamPermissions.permissionsByRole[CardRole.ADMIN],
      ...trustPermissions.permissionsByRole[CardRole.ADMIN],
      ...rewardsPermissions.permissionsByRole[CardRole.ADMIN],
      ...kycPermissions.permissionsByRole[CardRole.ADMIN],
      ...limitExperiencePermissions.permissionsByRole[CardRole.ADMIN],
      ...primitivesPermissions.permissionsByRole[CardRole.ADMIN],
      ...travelPermissions.permissionsByRole[CardRole.ADMIN],
    } as const,
  }[cardRole];

  if (userIsExpenseAdmin) {
    permissionsToRevokeForExpenseAdmin.forEach((permission) => {
      delete permissionsByRole[permission];
    });
  }

  if (!permissionsByRole) {
    throw new Error("selectPermissions: invalid user role");
  }

  if (user.depositsRole === undefined) {
    throw new Error("PermissionsQuery data is missing user depositsRole");
  }

  /* eslint sort-keys: ["error", "asc"] */
  const permissionsByDepositsRole: PartialPermissions = {
    [DepositsRole.USER]: {
      ...appPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...cardPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...cashPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...spendPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...billPayPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...underwritingPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...teamPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...trustPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...rewardsPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...kycPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...limitExperiencePermissions.permissionsByDepositsRole[
        DepositsRole.USER
      ],
      ...primitivesPermissions.permissionsByDepositsRole[DepositsRole.USER],
      ...travelPermissions.permissionsByDepositsRole[DepositsRole.USER],
    } as const,
    [DepositsRole.BOOKKEEPER]: {
      ...appPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...cardPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...cashPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...spendPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...billPayPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...underwritingPermissions.permissionsByDepositsRole[
        DepositsRole.BOOKKEEPER
      ],
      ...teamPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...trustPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...rewardsPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...kycPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
      ...limitExperiencePermissions.permissionsByDepositsRole[
        DepositsRole.BOOKKEEPER
      ],
      ...primitivesPermissions.permissionsByDepositsRole[
        DepositsRole.BOOKKEEPER
      ],
      ...travelPermissions.permissionsByDepositsRole[DepositsRole.BOOKKEEPER],
    } as const,
    [DepositsRole.ADMIN]: {
      ...appPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...cardPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...cashPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...spendPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...integrationsPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...billPayPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...underwritingPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...teamPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...trustPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...rewardsPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...kycPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...limitExperiencePermissions.permissionsByDepositsRole[
        DepositsRole.ADMIN
      ],
      ...primitivesPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
      ...travelPermissions.permissionsByDepositsRole[DepositsRole.ADMIN],
    } as const,
    null: {
      ...appPermissions.permissionsByDepositsRole.null,
      ...cardPermissions.permissionsByDepositsRole.null,
      ...cashPermissions.permissionsByDepositsRole.null,
      ...spendPermissions.permissionsByDepositsRole.null,
      ...billPayPermissions.permissionsByDepositsRole.null,
      ...underwritingPermissions.permissionsByDepositsRole.null,
      ...teamPermissions.permissionsByDepositsRole.null,
      ...trustPermissions.permissionsByDepositsRole.null,
      ...rewardsPermissions.permissionsByDepositsRole.null,
      ...kycPermissions.permissionsByDepositsRole.null,
      ...limitExperiencePermissions.permissionsByDepositsRole.null,
      ...primitivesPermissions.permissionsByDepositsRole.null,
      ...travelPermissions.permissionsByDepositsRole.null,
    } as const,
  }[depositsRole ?? "null"];

  if (!permissionsByDepositsRole) {
    throw new Error("selectPermissions: invalid user depositsRole");
  }

  if ((user.isManager ?? undefined) === undefined) {
    throw new Error("PermissionsQuery data is missing user isManager");
  }

  /* eslint sort-keys: ["error", "asc"] */
  const permissionsByIsManager: PartialPermissions = {
    false: {
      ...appPermissions.permissionsByIsManager.false,
      ...cardPermissions.permissionsByIsManager.false,
      ...cashPermissions.permissionsByIsManager.false,
      ...spendPermissions.permissionsByIsManager.false,
      ...billPayPermissions.permissionsByIsManager.false,
      ...underwritingPermissions.permissionsByIsManager.false,
      ...teamPermissions.permissionsByIsManager.false,
      ...trustPermissions.permissionsByIsManager.false,
      ...rewardsPermissions.permissionsByIsManager.false,
      ...kycPermissions.permissionsByIsManager.false,
      ...limitExperiencePermissions.permissionsByIsManager.false,
      ...primitivesPermissions.permissionsByIsManager.false,
      ...travelPermissions.permissionsByIsManager.false,
    } as const,
    true: {
      ...appPermissions.permissionsByIsManager.true,
      ...cardPermissions.permissionsByIsManager.true,
      ...cashPermissions.permissionsByIsManager.true,
      ...spendPermissions.permissionsByIsManager.true,
      ...billPayPermissions.permissionsByIsManager.true,
      ...underwritingPermissions.permissionsByIsManager.true,
      ...teamPermissions.permissionsByIsManager.true,
      ...trustPermissions.permissionsByIsManager.true,
      ...rewardsPermissions.permissionsByIsManager.true,
      ...kycPermissions.permissionsByIsManager.true,
      ...limitExperiencePermissions.permissionsByIsManager.true,
      ...primitivesPermissions.permissionsByIsManager.true,
      ...travelPermissions.permissionsByIsManager.true,
    } as const,
  }[user.isManager ? "true" : "false"];

  /* eslint sort-keys: ["error", "asc"] */
  const permissionsEligibleForCopilot: AllPermissions | undefined =
    isInCopilotMode
      ? {
          ...appPermissions.permissionsEligibleForCopilot,
          ...billPayPermissions.permissionsEligibleForCopilot,
          ...cardPermissions.permissionsEligibleForCopilot,
          ...cashPermissions.permissionsEligibleForCopilot,
          ...integrationsPermissions.permissionsEligibleForCopilot,
          ...kycPermissions.permissionsEligibleForCopilot,
          ...limitExperiencePermissions.permissionsEligibleForCopilot,
          ...primitivesPermissions.permissionsEligibleForCopilot,
          ...rewardsPermissions.permissionsEligibleForCopilot,
          ...spendPermissions.permissionsEligibleForCopilot,
          ...teamPermissions.permissionsEligibleForCopilot,
          ...trustPermissions.permissionsEligibleForCopilot,
          ...underwritingPermissions.permissionsEligibleForCopilot,
          ...travelPermissions.permissionsEligibleForCopilot,
        }
      : undefined;

  return mergePermissionsSets(
    [permissionsByRole, permissionsByDepositsRole, permissionsByIsManager],
    permissionsEligibleForCopilot,
  );
};

export const permissionsTestsByDomain = (
  domain:
    | "app"
    | "card"
    | "cash"
    | "integrations"
    | "billPay"
    | "kyc"
    | "limitExperience"
    | "spend"
    | "team"
    | "trust"
    | "underwriting"
    | "rewards"
    | "primitives"
    | "travel",
) => {
  const isManagerValues = [true, false];

  const permissionsQueryDataMatrix = Object.values(CardRole).flatMap((role) =>
    [...Object.values(DepositsRole), null].flatMap((depositsRole) =>
      isManagerValues.map((isManager) => ({
        depositsRole,
        isManager,
        role,
      })),
    ),
  );

  return permissionsQueryDataMatrix.map((permissionsQueryData) => {
    const data = {
      user: {
        id: "user",
        ...permissionsQueryData,
      },
    };
    const title = Object.entries(permissionsQueryData)
      .map(([key, value]) => `${key}: ${value}`)
      .join(" | ");

    const permissions = pick(
      selectPermissions({ data, isInCopilotMode: false }),
      ([key]) => key.startsWith(`${domain}.`),
    );
    const copilotPermissions = pick(
      selectPermissions({ data, isInCopilotMode: true }),
      ([key]) => key.startsWith(`${domain}.`),
    );

    return {
      copilotPermissions,
      description: `computes expected ${domain} permissions for ${title}`,
      permissions,
    };
  });
};
