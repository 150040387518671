import { type DocumentNode, type SelectionNode } from "graphql";
import gql from "graphql-tag";
import * as React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import type { PayloadData } from "relay-runtime";
import { createOperationDescriptor, getRequest } from "relay-runtime";
import type { UseControllerContextReturn } from "@/domains/App/components/Controller/sharedTypes";
import type {
  ErrorResponse,
  FetchResult,
  NextLink,
  Observable,
  Operation,
  OperationVariables,
  ServerError,
  ServerParseError,
} from "@/features/Apollo";
import ApolloClient, {
  createHttpLink,
  ApolloLink,
  setContext,
  ApolloProvider,
  onError,
} from "@/features/Apollo";
import {
  errorLoggingLink,
  loggingLink,
} from "@/features/Apollo/helpers/analytics";
import {
  apolloRetryGqlErrorsLink,
  apolloRetryNetworkErrorsLink,
} from "@/features/Apollo/helpers/apolloRetryLink";
import { TokenRefreshLink } from "@/features/Apollo/helpers/apolloTokenRefreshLink";
import { createCache } from "@/features/Apollo/helpers/cache";
import { useGetCustomHeaders } from "@/features/Apollo/helpers/getCustomHeaders";
import getUri from "@/features/Apollo/helpers/getUri";
import {
  getLoadingControllerNames,
  getIsControllerReady,
  getRequestTimeDifferencesFromControllerMount,
  getRequestTimeDifferenceFromViewControllerMount,
} from "@/features/Apollo/helpers/performanceLoggingHelpers";
import type { GqlMetadata } from "@/features/Apollo/sharedTypes";
import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { useBeginOpenIDAuthorization } from "@/features/Authentication/hooks/useBeginOpenIDAuthorization";
import { useFeatureFlag } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { convertDocumentNodeToRelayAST } from "@/features/Relay/helpers/relayAST";
import { useGetRelayEnvironment } from "@/features/Relay/hooks/useGetRelayEnvironment";
import type { OnRelayQuery } from "@/features/Relay/hooks/useGetRelayEnvironment";
import { useTrackError } from "@/helpers/errorTracking";
import {
  addDatadogRUMGraphqlRequestMetadata,
  useGetErrorHandler,
} from "@/helpers/graphqlRequests";
import { useRefCallback } from "@/hooks/useRefCallback";

class OnApolloQueryLink extends ApolloLink {
  constructor(private handler: OnApolloQuery) {
    super();
  }

  override request(
    operation: Operation,
    forward?: NextLink | undefined,
  ): Observable<FetchResult> | null {
    const observable = forward!(operation);

    return observable.map((result) => {
      if (!result.errors || result.errors.length === 0) {
        this.handler(operation.query, operation.variables, result.data);
      }

      return result;
    });
  }
}

const useCustomHeaderLink = () => {
  const getCustomHeaders = useGetCustomHeaders();

  return React.useMemo(
    () =>
      setContext((operation, context) => ({
        ...context,
        headers: {
          ...context.headers,
          ...getCustomHeaders({
            operationName: operation.operationName,
            graphqlClient: "apollo",
            controllerName: context.controllerContext?.controllerName,
            productSurfaceSuffix: context.productSurfaceSuffix,
            isMutation: operation.query.definitions.some(
              (definition) =>
                definition.kind === "OperationDefinition" &&
                definition.operation === "mutation",
            ),
            // this lets getCustomHeaders generate its own requestId. the param is present
            // because relay's requestId is pre-generated
            requestId: undefined,
          }),
        },
      })),
    [getCustomHeaders],
  );
};

const useGetApolloErrorHandler = () => {
  const handleError = useGetErrorHandler();
  return useRefCallback(function handleApolloError({
    graphQLErrors,
    forward,
    operation,
    networkError,
  }: ErrorResponse) {
    // This is needed because the apollo-link-error type for networkError is mistakenly
    // typed as Error: https://github.com/apollographql/apollo-link/issues/536
    const statusCode =
      (networkError as { statusCode: number } | undefined)?.statusCode ?? 200;

    // the type annotation makes TS verify for us that our check is enough for the type to
    // be narrowed to the one we want (ServerError / ServerParseError)
    const serverParseError: ServerParseError | undefined =
      networkError && "bodyText" in networkError ? networkError : undefined;
    const serverError: ServerError | undefined =
      networkError && "result" in networkError ? networkError : undefined;

    handleError({
      errors: graphQLErrors,
      retry: () => forward!(operation),
      headers: operation.getContext().response?.headers,
      statusCode,
      rumMetadata: operation.getContext().metadata,
      failedToParse: !!serverParseError,
      isApolloServerError: !!serverError,
    });
  });
};

const useRefreshTokenLink = () => {
  const { getAccessToken, getIsLocked, refreshToken } =
    useAuthenticationContext();
  const beginOpenIDAuthorization = useBeginOpenIDAuthorization();
  const { trackError } = useTrackError();

  const handleError = React.useCallback(
    (e: Error) => {
      // We could consider loging out the user here, but for now, it's better if
      // we let our downstream apollo-link to handle the subsequent 401 responses,
      // and proceed to logout the user there
      trackError("Error refreshing access token", e);
      beginOpenIDAuthorization({
        interactive: true,
      });
    },
    [beginOpenIDAuthorization, trackError],
  );

  const onExpired = React.useCallback(
    () => beginOpenIDAuthorization({ interactive: true }),
    [beginOpenIDAuthorization],
  );

  // We never want this class reference to make, so we use a ref instead.
  const tokenRefreshLink = React.useRef(
    new TokenRefreshLink({
      getAccessToken,
      getIsRefreshingToken: getIsLocked,
      refreshAccessToken: refreshToken,
      onExpired,
      handleError,
    }),
  );

  return tokenRefreshLink.current;
};

const gatherFields = (fieldsArray: ReadonlyArray<SelectionNode>) => {
  const finalListOfFields: string[] = [];
  const traverseAndExtract = (
    selections: ReadonlyArray<SelectionNode>,
    parentKey: string,
  ) => {
    selections.forEach((selection) => {
      if (selection.kind !== "Field") {
        return;
      }
      const fieldKey = [parentKey, selection.name.value]
        .filter(Boolean)
        .join(".");
      finalListOfFields.push(fieldKey);
      if (selection?.selectionSet?.selections) {
        traverseAndExtract(selection?.selectionSet?.selections, fieldKey);
      }
    });
  };
  traverseAndExtract(fieldsArray, "");
  return finalListOfFields;
};

// since RUM works outside of the react rendering tree, we need a way to access
// it that doesn't depend on it. So we use this hook to grab graphql-related
// metadata and put it into localstorage, which then RUM looks for at a
// different point in time
const useDatadogRUMStorageLink = () => {
  const defaultContext = React.useRef(
    new ApolloLink((_operation, forward) => {
      const context = _operation.getContext();
      const requestId = context.headers?.["x-brex-request-id"];
      const controllerContext =
        context?.controllerContext as UseControllerContextReturn;
      let isQuery = false;
      let isMutation = false;
      let isSubscription = false;
      let hasFragments = false;
      let amountOfFragments = 0;
      const fragmentNames: string[] = [];
      _operation.query.definitions.forEach((definition) => {
        if (definition.kind === "OperationDefinition") {
          if (definition.operation === "query") {
            isQuery = true;
          } else if (definition.operation === "mutation") {
            isMutation = true;
          } else if (definition.operation === "subscription") {
            isSubscription = true;
          }
        } else if (definition.kind === "FragmentDefinition") {
          fragmentNames.push(definition.name.value);
          hasFragments = true;
          amountOfFragments += 1;
        }
      });
      const logRocketUrl = context.headers?.["x-logrocket-url"];
      const definitions = _operation.query.definitions[0];
      let operationFields: string[] = [];
      if (definitions.kind === "OperationDefinition") {
        operationFields = gatherFields(definitions.selectionSet.selections);
      }

      // when true, the request will excluded from page load metrics
      // by default, this is false
      const shouldExcludeFromBusyElements =
        context?.shouldExcludeFromBusyElements || false;

      const metadata: GqlMetadata = {
        operationName: _operation?.operationName,
        queryText: undefined,
        requestType: _operation?.query?.kind,
        controllerName: controllerContext?.controllerName,
        ancestorControllerNames: controllerContext?.ancestorControllerNames,
        controllerSessionId: controllerContext?.controllerSessionId,
        operationFields,
        fragmentNames,
        isUsingSuspense: controllerContext?.getIsUsingSuspense(),
        isQuery,
        isMutation,
        isUsingJotai: Boolean(controllerContext?.isUsingJotai),
        typeOfError: undefined,
        errorCode: undefined,
        requestId,
        logRocketUrl,
        hasFragments,
        graphqlErrors: [],
        numberOfErrors: 0,
        isSubscription,
        amountOfFragments,
        shouldExcludeFromBusyElements,
        loadingControllerNames: getLoadingControllerNames(controllerContext),
        isControllerReady: getIsControllerReady(controllerContext),
        requestStartTimesRelativeToControllerMounts:
          getRequestTimeDifferencesFromControllerMount(controllerContext),
        requestStartTimeRelativeToViewControllerMount:
          getRequestTimeDifferenceFromViewControllerMount(controllerContext),
      };
      //TODO: We're keeping metadata in operation context as ErrorLink also consumes it
      // Another option can be to recreate it in there out of Operation one more time
      _operation.setContext({ metadata: metadata });
      // ---

      const addDatadogRUMGraphqlResponseMetadata =
        addDatadogRUMGraphqlRequestMetadata({
          ..._operation.getContext(),
          metadata,
        });

      return forward(_operation).map((response) => {
        addDatadogRUMGraphqlResponseMetadata({
          response,
          headers: _operation.getContext().response?.headers,
          fetchTimingInfo: _operation.getContext().metadata ?? {},
        });
        return response;
      });
    }),
  );
  return defaultContext.current;
};

const useApolloClient = (onApolloQuery: OnApolloQuery) => {
  const handleError = useGetApolloErrorHandler();
  const refreshTokenLink = useRefreshTokenLink();
  const datadogRUMStorageLink = useDatadogRUMStorageLink();
  const customHeaderLink = useCustomHeaderLink();

  return React.useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          customHeaderLink,
          loggingLink,
          errorLoggingLink,
          refreshTokenLink,
          datadogRUMStorageLink,
          apolloRetryNetworkErrorsLink,
          apolloRetryGqlErrorsLink,
          new OnApolloQueryLink(onApolloQuery),
          onError(handleError),
          createHttpLink({
            uri: getUri,
            credentials: "same-origin",
          }),
        ]),
        cache: createCache(),
        connectToDevTools: process.env.APP_ENV !== "prod",
        defaultOptions: {
          watchQuery: {
            errorPolicy: "all",
          },
          query: {
            errorPolicy: "all",
          },
        },
      }),
    [
      refreshTokenLink,
      datadogRUMStorageLink,
      handleError,
      onApolloQuery,
      customHeaderLink,
    ],
  );
};

type OnApolloQuery = (
  query: DocumentNode,
  variables: OperationVariables,
  data: unknown,
) => void;

export const BrexDataProvider: React.FC = ({ children }) => {
  const onApolloQueryRef = React.useRef<OnApolloQuery>();
  const onApolloQuery = React.useCallback<OnApolloQuery>((...args) => {
    try {
      return onApolloQueryRef.current?.(...args);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error handling Apollo query", error);
    }
  }, []);
  const onRelayQueryRef = React.useRef<OnRelayQuery>();
  const onRelayQuery = React.useCallback<OnRelayQuery>((...args) => {
    try {
      return onRelayQueryRef.current?.(...args);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error handling Relay query", error);
    }
  }, []);

  const hookedClient = useApolloClient(onApolloQuery);
  const relayEnvironment = useGetRelayEnvironment(onRelayQuery);

  const isSyncingEnabled = useFeatureFlag("enableRelayApolloSyncing");

  onApolloQueryRef.current = (query, variables, data) => {
    if (!isSyncingEnabled) return;

    const queryDescriptor = createOperationDescriptor(
      getRequest(convertDocumentNodeToRelayAST(query, data)),
      variables,
    );
    relayEnvironment.commitPayload(queryDescriptor, data as PayloadData);
    relayEnvironment.checkForDuplicateIds();
  };

  onRelayQueryRef.current = (query, variables, data) => {
    if (!isSyncingEnabled) return;

    hookedClient.writeQuery({ query: gql(query), data, variables });
    // We wait for the next tick to wait for the query to be written to Relay's cache
    setTimeout(() => relayEnvironment.checkForDuplicateIds(), 0);
  };

  return (
    <ApolloProvider client={hookedClient}>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        {children}
      </RelayEnvironmentProvider>
    </ApolloProvider>
  );
};

BrexDataProvider.displayName = "brexData.Provider";
