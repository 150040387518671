import * as React from "react";
import { initSardine } from "@/domains/App/helpers/sardine";
import type { SardineContextObject } from "@/domains/App/helpers/types";
import {
  useAccountId,
  useUserId,
} from "@/features/Authentication/contexts/UserProperties";
import { getEnvironment, toRawEntityId } from "@/helpers";
import { getSecureHash } from "@/helpers/crypto";
import noOp from "@/helpers/noOp";
import useScript from "@/hooks/useScript";

export const SardineContext = React.createContext<SardineContextObject>({
  updateConfig: noOp,
});

export const SardineContextProvider: React.FC = React.memo(({ children }) => {
  const sardineHost = getEnvironment("SARDINE_HOST") ?? "api.dev.sardine.ai";

  // once it's loaded, we should be able to start using `window._Sardine`
  // to do tings like calling `window._Sardine.createContext`
  // @see https://docs.sardine.ai/web/
  const [loading, error] = useScript({
    checkForExisting: true,
    src: `https://${sardineHost}/assets/loader.min.js`,
    type: "text/javascript",
    async: true,
  });
  const [sardineContextObject, setSardineContextObject] =
    React.useState<SardineContextObject>({ updateConfig: noOp });

  const customerAccountId = toRawEntityId(useAccountId());
  const customerUserId = toRawEntityId(useUserId());
  React.useEffect(() => {
    // hashAndSign returns a promise and therefore this logic has been turned into
    // an async function
    // @see https://stackoverflow.com/a/53332372
    async function maybeInitSardine() {
      if (!loading && !error && customerAccountId && customerUserId) {
        try {
          const hashedCustomerAccountId =
            await getSecureHash(customerAccountId);
          const hashedCustomerUserId = await getSecureHash(customerUserId);
          const context = initSardine(
            hashedCustomerAccountId,
            hashedCustomerUserId,
          );
          if (context) {
            setSardineContextObject(context);
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
    maybeInitSardine();
  }, [customerAccountId, customerUserId, error, loading]);

  return (
    <SardineContext.Provider value={sardineContextObject}>
      {children}
    </SardineContext.Provider>
  );
});
