import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "underwriting.bankConnections.read": false,
  "underwriting.companyStatementsUploadBackdoor.upload": false,
  "underwriting.connectBankNotice.read": false,
  "underwriting.disconnectedBankAccountsNotifications.edit": false,
  "underwriting.financialDocuments.read": false,
  "underwriting.financialDocuments.upload": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {} as PartialPermissions,
  [UserRole.BOOKKEEPER]: {
    "underwriting.companyStatementsUploadBackdoor.upload": true,
    "underwriting.financialDocuments.read": true,
    "underwriting.financialDocuments.upload": true,
  } as PartialPermissions,
  [UserRole.ADMIN]: {
    "underwriting.bankConnections.read": true,
    "underwriting.companyStatementsUploadBackdoor.upload": true,
    "underwriting.connectBankNotice.read": true,
    "underwriting.disconnectedBankAccountsNotifications.edit": true,
    "underwriting.financialDocuments.read": true,
    "underwriting.financialDocuments.upload": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {} as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {} as PartialPermissions,
  [DepositsRole.ADMIN]: {} as PartialPermissions,
  null: {} as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {} as PartialPermissions,
  true: {} as PartialPermissions,
};

// permissionsEligibleForCopilot determines what permissions are eligible for use in Copilot mode.
// Please use caution around what should be set to true and see go/td-wiki for details.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "underwriting.bankConnections.read": false,
  "underwriting.companyStatementsUploadBackdoor.upload": false,
  "underwriting.connectBankNotice.read": false,
  "underwriting.disconnectedBankAccountsNotifications.edit": false,
  "underwriting.financialDocuments.read": false,
  "underwriting.financialDocuments.upload": false,
};
