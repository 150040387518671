import { atom, useSetAtom, useAtomValue } from "jotai";
import * as React from "react";
import { useLocation } from "react-router-dom";

const locationAtom = atom<string | undefined>(undefined);

export const useSetOriginalLocationAtom = () => {
  const location = useLocation();
  const originalLocationRef = React.useRef(
    `${location.pathname}${location.search}${location.hash}`,
  );
  const setLocationAtom = useSetAtom(locationAtom);
  // we record the original location when we mount the app, in order to
  // persist it later in "state" whenever we execute an OpenID Authorization flow.
  // This allows us to restore it when coming back from oauth redirect.
  React.useEffect(() => {
    setLocationAtom(originalLocationRef.current);
  }, [setLocationAtom]);
};

export const useOriginalLocation = () => useAtomValue(locationAtom);
