// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import type {
  InboxTasksQuery,
  InboxTasksQueryVariables,
  InitialMarketSegmentQuery,
  PrimitivePremiumQuery,
  UserRoleQuery,
} from "@/data/__generated__/queries.generated";
import {
  billPayOnEmpowerMigratedFragment,
  deadlineSettingFragment,
  merchantFragment,
  productConfigFeaturesFragment,
  productConfigFeaturesFragmentV2,
} from "@/data/fragments";
import { financialAccountFragment } from "@/domains/Cash/data/fragments";
import { useQuery } from "@/features/Apollo";
import { createMockableHook } from "@/helpers/createMockableHook";

export const accountRewardsBalanceQuery = gql`
  query AccountRewardsBalanceQuery {
    account {
      id
      pointsBalanceV2
      rewardsStatusV2
    }
  }
`;

export const premiumQuery = gql`
  query PrimitivePremiumQuery {
    premiumSetting {
      id
      status
      insertedAt
      pricingScheme
    }
    expensesSettings(first: 1) {
      edges {
        node {
          id
          status
          insertedAt
        }
      }
    }
  }
`;

const usePremiumQuery = ({
  onCompleted,
}: {
  onCompleted?: (data: PrimitivePremiumQuery) => void;
}) =>
  useQuery<PrimitivePremiumQuery, {}>(premiumQuery, {
    fetchPolicy: "cache-first",
    onCompleted,
  });

export const [useMockablePremiumQuery, MockedPremiumQueryProvider] =
  createMockableHook(usePremiumQuery);

export const fundByBankTransferPageQuery = gql`
  query FundByBankTransferPageQuery($accountLimit: Int!) {
    getBankingHolidays {
      bankingHolidays
    }
    user {
      id
      firstName
      lastName
      depositsRole
    }
    financialAccounts(first: 100, excludeCustomerHidden: true) {
      edges {
        node {
          id
          ...FinancialAccountFragment
        }
      }
    }
    account {
      id
      legalName
      dbaName
      isInKillYellowTier
      isInSvbCashRestriction
      officeAddress {
        address1
        address2
        city
        country
        state
        zip
      }
      achDebitLimits {
        achDebitPayoutDelay
        achTotalLimitUsdCents
        achRemainingLimitToday
        firstAvailableBusinessDay
        achRemainingLimitTomorrow
        secondAvailableBusinessDay
      }
      depositsAccounts(first: $accountLimit) {
        edges {
          node {
            id
            name
            nickname
            accountNumber
            routingNumber
            balance
            displayColor
            status
            canAddFunds
            canAchDebit
            hasAchPayoutDelay
            hasBrexOriginatedAchDebit
          }
        }
      }
    }
  }
  ${financialAccountFragment}
`;

export const accountSummaryQuery = gql`
  query AccountSummaryQuery {
    account {
      id
      balanceSummary {
        globalLimit
        usedBalance
      }
      depositsAccounts(first: 1) {
        edges {
          cursor
          node {
            id
            balance
            status
            canAddFunds
          }
        }
      }
    }
  }
`;

export const unsafeFlagQuery = gql`
  query ApprovalsUnsafeFlagQuery($flagName: String!) {
    approvalsUnsafeFlag(keyType: CUSTOMER_ACCOUNT_ID, flagName: $flagName) {
      value
    }
  }
`;

export const reimbursementHealthQuery = gql`
  query ReimbursementHealthQuery($customerUserId: ID) {
    reimbursementHealth(customerUserId: $customerUserId) {
      healthStatus
      healthStatusDetails
    }
  }
`;

export const searchMerchants = gql`
  query SearchMerchants($filter: String!, $pageSize: Int) {
    searchMerchants(
      searchMerchantsInput: { name: $filter, pageSize: $pageSize }
    ) {
      ...MerchantFragment
    }
  }
  ${merchantFragment}
`;

export const userRoleQuery = gql`
  query UserRoleQuery {
    user {
      id
      role
      userRole
      depositsRole
      isManager
    }
  }
`;

export const useUserRoleQuery = () => useQuery<UserRoleQuery>(userRoleQuery);

export const initialMarketSegmentQuery = gql`
  query InitialMarketSegmentQuery {
    user {
      id
      account {
        id
        initialMarketSegment
      }
    }
  }
`;

export const useInitialMarketSegmentQuery = () =>
  useQuery<InitialMarketSegmentQuery>(initialMarketSegmentQuery);

export const cashStatementsQuery = gql`
  query CashStatementsQuery($accountLimit: Int!, $statementLimit: Int!) {
    user {
      id
      account {
        id
        depositsAccounts(first: $accountLimit) {
          edges {
            node {
              id
              statements(last: $statementLimit) {
                edges {
                  node {
                    id
                    periodStartTime
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const depositsAccountsDetailsQuery = gql`
  query DepositsAccountsDetailsQuery($accountLimit: Int!) {
    account {
      id
      depositsAccounts(first: $accountLimit) {
        edges {
          node {
            id
            name
            nickname
            accountNumber
            displayColor
            priority
            balanceBreakdown {
              availableBalance
            }
            canOffboard {
              enabled
              issues
            }
            status
          }
        }
      }
    }
  }
`;

/**
 * This query fetches the product configuration for a user.
 * This tells us which features we should hide/show for the user, similar to feature flags.
 */
export const productConfigQuery = gql`
  query ProductConfigQuery {
    productConfig {
      ...ProductConfigFeaturesFragment
      ...ProductConfigFeaturesFragmentV2
    }
  }
  ${productConfigFeaturesFragment}
  ${productConfigFeaturesFragmentV2}
`;

export const billPayOnEmpowerMigratedQuery = gql`
  query BillPayOnEmpowerMigratedQuery {
    migratedAccount {
      ...BillPayOnEmpowerMigratedFragment
    }
  }
  ${billPayOnEmpowerMigratedFragment}
`;

export const deadlineSettingsQuery = gql`
  query ComplianceDeadlineSettingsQuery {
    submitter: requestDeadlineSettings(type: SUBMITTER_COMPLIANCE) {
      id
      ccManagersEnabled
      remindersEnabled
      ...ComplianceDeadlineSettingsFragment
    }

    reviewer: requestDeadlineSettings(type: REVIEWER_COMPLIANCE) {
      id
      ...ComplianceDeadlineSettingsFragment
    }
  }
  ${deadlineSettingFragment}
`;

export const AirAutocompleteFragment = gql`
  fragment AirAutocompleteFragment on AirAutocompleteResult {
    airportCode
    airportName
    cityCode
    location {
      country
      countryCode
      state
      stateCode
      city
      coordinates {
        latitude
        longitude
      }
      addressLine1
      googlePlaceId
    }
  }
`;

export const airAutocompleteQuery = gql`
  query AirAutocomplete($filter: AirAutocompleteFilterInput) {
    airAutocomplete(filter: $filter) {
      results {
        ...AirAutocompleteFragment
      }
    }
  }
  ${AirAutocompleteFragment}
`;

export const defaultPolicyQuery = gql`
  query DefaultPolicyQuery {
    defaultPolicy(requestType: EXPENSE) {
      id
      eligibleWorkflows
    }
  }
`;

export const engagementSettingsQuery = gql`
  query EngagementSettings {
    engagementSetting {
      id
      complianceRatingProductStatus
    }
  }
`;

export const creditBalanceQuery = gql`
  query CreditBalanceQuery($filters: BillingGroupFilterInput) {
    account {
      id
      balanceSummary {
        globalLimit
        # usedBalance -- not using this field anymore - for global USD amounts, we sum usdTotalBalance from all billingGroups instead
        remainingSpend
      }
      creditedPendingCollectionAmount
      estimatedNextDueAmount {
        dueAt
      }
    }
    billingGroups(filters: $filters) {
      edges {
        node {
          id
          productDetails {
            __typename
            ... on CardProductDetails {
              balances {
                billingCurrencyUtilization {
                  quantity
                  instrumentCodeString
                }
                pendingBalance {
                  quantity
                  instrumentCodeString
                }
                usdTotalBalance {
                  quantity
                  instrumentCodeString
                }
                usdPendingBalance {
                  quantity
                  instrumentCodeString
                }
                clearedBalance {
                  quantity
                  instrumentCodeString
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const isAccounting1BStackEnabledQuery = gql`
  query IsAccounting1BStackEnabledQuery(
    $vendorToEnable: AccountingIntegrationVendor
  ) {
    isAccounting1BStackEnabled(vendorToEnable: $vendorToEnable) {
      isEnabled
    }
  }
`;

/**
 * Queries that can be used to update the task inbox tables/lists
 * and counts when a task is completed
 */
export const inboxTasksQuery: TypedDocumentNode<
  InboxTasksQuery,
  InboxTasksQueryVariables
> = gql`
  query InboxTasksQuery(
    $page: PaginationInput
    $filter: InboxTaskClientFilter!
    $sortBy: InboxTaskSortFilter
  ) {
    inboxTasksV2(page: $page, filter: $filter, sortBy: $sortBy) {
      edges {
        node {
          id
          title
          subtitle
          date
          icon {
            localMetalIcon
            accessibilityText
            remoteIcon {
              lightUrl
              darkUrl
            }
          }
          taskType
          taskState
          encodedRequestId
          taskEntityDetails {
            encodedEntityId
            entityId
            ... on BudgetRequestInboxTaskEntityDetails {
              templateType
            }
            ... on ReimbursementDraftInboxTaskEntityDetails {
              isMileageReimbursement
            }
            ... on ExpenseInboxTaskEntityDetails {
              __typename
              expenseType
            }
          }
          badge {
            style
            label
          }
          ctas {
            primary {
              style
              label
            }
            dismiss {
              style
              label
            }
            resolve {
              label
              style
            }
          }
          criticality
          requestActions {
            id
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const getTaskByTypeQuery = gql`
  query getTaskByTypeQuery($filter: InboxTaskClientFilter!) {
    inboxTasksV2(filter: $filter) {
      edges {
        node {
          id
          taskType
          taskState
          encodedRequestId
          requestActions {
            id
          }
        }
      }
    }
  }
`;

/**
 * InboxTaskCountQuery is using the tasks service
 * and powers the count for the overview page (personal & company),
 * requests, drafts and spend insights
 */
export const getInboxTasksCount = gql`
  query InboxTaskCountQuery {
    inboxTaskCounts {
      total
      webCounts {
        type
        total
      }
    }
  }
`;

export const budgetNameQuery = gql`
  query BudgetNameQuery($budgetId: ID!) {
    budget(id: $budgetId) {
      id
      name
    }
  }
`;

export const firstAchDebitToFinancialAccountQuery = gql`
  query FirstAchDebitToFinancialAccountQuery(
    $depositsAccountId: String!
    $financialAccountId: String!
  ) {
    cashTransactionIsFirstAchDebit(
      depositsAccountId: $depositsAccountId
      financialAccountId: $financialAccountId
    ) {
      isFirstAchDebit
    }
  }
`;

export const hrisConnectionsQuery = gql`
  query HrConnectionsQuery(
    $filter: HrConnectionFilterInput
    $page: PaginationInput
  ) {
    hrConnections(filter: $filter, page: $page) {
      edges {
        node {
          hrisStatus
          hrisSystem
          hrisSystemName
          id
          lastSyncAt
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const getScimConnections = gql`
  query GetScimConnections {
    scimConnections {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const getBudgetConfigurationFlags = gql`
  query GetBudgetConfigurationFlags {
    budgetConfigurationFlags(
      flags: [
        BIFURCATION
        STRUCTURE_RECOMMENDATION
        REUSABLE_APPROVAL_CHAINS
        TEMPLATES_SIMPLIFICATION
        LIMIT_GROUPS
        SPEND_LIMITS_AND_USER_GROUPS_FLATTENING
      ]
    ) {
      status
      type
    }
  }
`;

export const isSuperAdminEnabledQuery = gql`
  query IsSuperAdminEnabledQuery {
    isSuperAdminEnabled
  }
`;

export const isSsoEnabledQuery = gql`
  query SsoMeta {
    ssoMeta {
      enabled
    }
  }
`;

/**
 * AMS Surface queries
 */

export const surfaceTasksCountQuery = gql`
  query SurfaceTasksCountQuery(
    $surface: Surface!
    $groups: [String!]
    $taskStates: [SurfaceTaskState!]
  ) {
    surfaceTasks(surface: $surface, groups: $groups, taskStates: $taskStates) {
      totalCount
    }
  }
`;

export const surfaceTasksQuery = gql`
  query SurfaceTasksQuery(
    $page: PaginationInput
    $surface: Surface!
    $groups: [String!]
    $taskStates: [SurfaceTaskState!]
    $sortBy: SurfaceTaskSortFilter
  ) {
    surfaceTasks(
      page: $page
      surface: $surface
      groups: $groups
      taskStates: $taskStates
      sortBy: $sortBy
    ) {
      totalCount
      edges {
        node {
          group
          tasks {
            id
            date
            requestId
            taskType
            requestActions {
              id
              resolvedAt
              status
            }
            taskState
            criticality
            requestId
            surfaceTaskMaterializedView {
              title
              subtitle
              taskEntityDetails {
                ... on AccountManagementTaskEntityDetails {
                  __typename
                  encodedEntityId
                  isHidden
                  additionalTaskState
                  transactionType
                  submittedAt
                  monthlyLimit
                  reimbursementsBilling
                  cashBalance
                  autopayAccountName
                  autopayDate
                  cardLast4
                  payrollProvider
                  migratedVendorCount
                  userCount
                  transactionCount
                }
              }
              icon {
                localMetalIcon
                accessibilityText
                remoteIcon {
                  lightUrl
                  darkUrl
                }
              }
              badge {
                style
                label
              }
              ctas {
                primary {
                  style
                  label
                }
                dismiss {
                  style
                  label
                }
                resolve {
                  label
                  style
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const userStatusQuery = gql`
  query userStatusQuery {
    user {
      id
      status
    }
  }
`;
