import type {
  LegalEntityResourceAttribute,
  OrganizationResourceAttribute,
  ResourceAction,
  TravelResourceAttribute,
  UserResourceAttribute,
  BillPayResourceAttribute,
  VendorResourceAttribute,
  AccountResourceAttribute,
  AccountingExportResourceAttribute,
} from "@/__generated__/globalTypes";
import type {
  ResourceOperationsQuery,
  ResourceOperationsQuery_resourceOperations_LegalEntityResourceOperation as LegalEntityResourceOperation,
  ResourceOperationsQuery_resourceOperations_TravelResourceOperation as TravelResourceOperation,
  ResourceOperationsQuery_resourceOperations_OrganizationResourceOperation as OrganizationResourceOperation,
  ResourceOperationsQuery_resourceOperations_UserResourceOperation as UserResourceOperation,
  ResourceOperationsQuery_resourceOperations_BillPayResourceOperation as BillPayResourceOperation,
  ResourceOperationsQuery_resourceOperations_VendorResourceOperation as VendorResourceOperation,
  ResourceOperationsQuery_resourceOperations_AccountResourceOperation as AccountResourceOperation,
  ResourceOperationsQuery_resourceOperations_AccountingExportResourceOperation as AccountingExportResourceOperation,
} from "@/domains/App/features/Permissions/data/__generated__/queries.generated";
import type { ResourceOperations } from "@/domains/App/features/Permissions/sharedTypes";
import { selectQueryAtomWithSuspense, atomsWithQuery } from "@/features/Jotai";

export const resourceOperationsQueryAtoms =
  atomsWithQuery<ResourceOperationsQuery>({ suspensePolicy: "initial" });

export const selectTravelResourceOperations = (
  operations: ResourceOperations,
) => {
  const travelResourceOperations = operations.filter(
    (operation) =>
      (operation as TravelResourceOperation).__typename ===
      "TravelResourceOperation",
  ) as TravelResourceOperation[];

  const hasTravelResourceOperation = (
    action: ResourceAction,
    attribute: TravelResourceAttribute | null = null,
  ) => {
    return travelResourceOperations.some(
      (operation) =>
        operation.action === action && operation.travelAttribute === attribute,
    );
  };

  return {
    resourceOperations: travelResourceOperations,
    hasTravelResourceOperation,
  };
};

const selectLegalEntityResourceOperations = (
  operations: ResourceOperations,
) => {
  const legalEntityResourceOperations = operations.filter(
    (operation) =>
      (operation as LegalEntityResourceOperation).__typename ===
      "LegalEntityResourceOperation",
  ) as LegalEntityResourceOperation[];

  const hasLegalEntityResourceOperation = (
    action: ResourceAction,
    attribute: LegalEntityResourceAttribute | null = null,
  ) => {
    return legalEntityResourceOperations.some(
      (operation) =>
        operation.action === action &&
        operation.legalEntityAttribute === attribute,
    );
  };

  return {
    resourceOperations: legalEntityResourceOperations,
    hasLegalEntityResourceOperation,
  };
};

const selectOrganizationResourceOperations = (
  operations: ResourceOperations,
) => {
  const organizationResourceOperations = operations.filter(
    (operation) =>
      (operation as OrganizationResourceOperation).__typename ===
      "OrganizationResourceOperation",
  ) as OrganizationResourceOperation[];

  const hasOrganizationResourceOperation = (
    action: ResourceAction,
    attribute: OrganizationResourceAttribute | null = null,
  ) => {
    return organizationResourceOperations.some(
      (operation) =>
        operation.action === action &&
        operation.organizationAttribute === attribute,
    );
  };

  return {
    resourceOperations: organizationResourceOperations,
    hasOrganizationResourceOperation,
  };
};

const selectUserResourceOperations = (operations: ResourceOperations) => {
  const userResourceOperations = operations.filter(
    (operation) =>
      (operation as UserResourceOperation).__typename ===
      "UserResourceOperation",
  ) as UserResourceOperation[];

  const hasUserResourceOperation = (
    action: ResourceAction,
    attribute: UserResourceAttribute | null = null,
  ) => {
    return userResourceOperations.some(
      (operation) =>
        operation.action === action && operation.userAttribute === attribute,
    );
  };

  return {
    resourceOperations: userResourceOperations,
    hasUserResourceOperation,
  };
};

const selectBillPayResourceOperations = (operations: ResourceOperations) => {
  const billPayResourceOperations = operations.filter(
    (operation) =>
      (operation as BillPayResourceOperation).__typename ===
      "BillPayResourceOperation",
  ) as BillPayResourceOperation[];

  const hasBillPayResourceOperation = (
    action: ResourceAction,
    attribute: BillPayResourceAttribute | null = null,
  ) => {
    return billPayResourceOperations.some(
      (operation) =>
        operation.action === action && operation.billPayAttribute === attribute,
    );
  };

  return {
    resourceOperations: billPayResourceOperations,
    hasBillPayResourceOperation,
  };
};

const selectVendorResourceOperations = (operations: ResourceOperations) => {
  const vendorResourceOperations = operations.filter(
    (operation) =>
      (operation as VendorResourceOperation).__typename ===
      "VendorResourceOperation",
  ) as VendorResourceOperation[];

  const hasVendorResourceOperation = (
    action: ResourceAction,
    attribute: VendorResourceAttribute | null = null,
  ) => {
    return vendorResourceOperations.some(
      (operation) =>
        operation.action === action && operation.vendorAttribute === attribute,
    );
  };

  return {
    resourceOperations: vendorResourceOperations,
    hasVendorResourceOperation,
  };
};

const selectAccountResourceOperations = (operations: ResourceOperations) => {
  const accountResourceOperations = operations.filter(
    (operation) =>
      (operation as AccountResourceOperation).__typename ===
      "AccountResourceOperation",
  ) as AccountResourceOperation[];

  const hasAccountResourceOperation = (
    action: ResourceAction,
    attribute: AccountResourceAttribute | null = null,
  ) => {
    return accountResourceOperations.some(
      (operation) =>
        operation.action === action && operation.accountAttribute === attribute,
    );
  };

  return {
    resourceOperations: accountResourceOperations,
    hasAccountResourceOperation,
  };
};

const selectAccountingExportResourceOperations = (
  operations: ResourceOperations,
) => {
  const accountingExportResourceOperations = operations.filter(
    (operation) =>
      (operation as AccountingExportResourceOperation).__typename ===
      "AccountingExportResourceOperation",
  ) as AccountingExportResourceOperation[];

  const hasAccountingExportResourceOperation = (
    action: ResourceAction,
    attribute: AccountingExportResourceAttribute | null = null,
  ) => {
    return accountingExportResourceOperations.some(
      (operation) =>
        operation.action === action &&
        operation.accountingExportAttribute === attribute,
    );
  };

  return {
    resourceOperations: accountingExportResourceOperations,
    hasAccountingExportResourceOperation,
  };
};

export const resourceOperationMappingAtom = selectQueryAtomWithSuspense(
  resourceOperationsQueryAtoms,
  ({ data }) => {
    const resourceOperations =
      (data?.resourceOperations as ResourceOperations) ?? [];

    return {
      legalEntity: selectLegalEntityResourceOperations(resourceOperations),
      organization: selectOrganizationResourceOperations(resourceOperations),
      travel: selectTravelResourceOperations(resourceOperations),
      user: selectUserResourceOperations(resourceOperations),
      billPay: selectBillPayResourceOperations(resourceOperations),
      vendor: selectVendorResourceOperations(resourceOperations),
      account: selectAccountResourceOperations(resourceOperations),
      accountingExport:
        selectAccountingExportResourceOperations(resourceOperations),
    };
  },
);

export const createMockResourceOperationsAtom = (
  resourceOperations: ResourceOperations,
) => ({
  legalEntity: selectLegalEntityResourceOperations(resourceOperations),
  organization: selectOrganizationResourceOperations(resourceOperations),
  travel: selectTravelResourceOperations(resourceOperations),
  user: selectUserResourceOperations(resourceOperations),
  billPay: selectBillPayResourceOperations(resourceOperations),
  vendor: selectVendorResourceOperations(resourceOperations),
  account: selectAccountResourceOperations(resourceOperations),
  accountingExport:
    selectAccountingExportResourceOperations(resourceOperations),
});
