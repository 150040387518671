import { useAtomValue } from "jotai";
import {
  getDelegatorUserIdAtom,
  setDelegatorUserIdAtom,
  getDelegateUserIdAtom,
  setDelegateUserIdAtom,
  getIsInCopilotModeAtom,
} from "@/features/Copilot/atoms";

export const useGetDelegatorUserIdValue = () =>
  useAtomValue(getDelegatorUserIdAtom);
export const useSetDelegatorUserIdValue = () =>
  useAtomValue(setDelegatorUserIdAtom);
export const useGetDelegateUserIdValue = () =>
  useAtomValue(getDelegateUserIdAtom);
export const useSetDelegateUserIdValue = () =>
  useAtomValue(setDelegateUserIdAtom);
export const useGetIsInCopilotModeValue = () =>
  useAtomValue(getIsInCopilotModeAtom);

export const useCopilot = () => {
  const getDelegatorUserId = useGetDelegatorUserIdValue();
  const setDelegatorUserId = useSetDelegatorUserIdValue();
  const getDelegateUserId = useGetDelegateUserIdValue();
  const setDelegateUserId = useSetDelegateUserIdValue();
  const getIsInCopilotMode = useGetIsInCopilotModeValue();

  return {
    getDelegatorUserId,
    setDelegatorUserId,
    getDelegateUserId,
    setDelegateUserId,
    getIsInCopilotMode,
  };
};
