import * as React from "react";
import { SardineContext } from "@/domains/App/contexts/Sardine";

export const useUpdateSardineContext = () => {
  const sardineContextObject = React.useContext(SardineContext);
  return React.useCallback(
    (flow: string) => {
      sardineContextObject.updateConfig({ flow });
    },
    [sardineContextObject],
  );
};
