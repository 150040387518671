import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import { useEffect, useState, useCallback } from "react";
import type { PossibleMessages } from "@/features/Authentication/helpers/leaderShipElectionMessages";
import {
  messageToNotifyRefreshLock,
  messageToNotifyReleaseRefreshLock,
  messageForOtherFramesToUpdateToken,
  messageForOtherFramesToForceLogout,
  messageForOtherFramesToDeleteTheirTokens,
} from "@/features/Authentication/helpers/leaderShipElectionMessages";
import { refreshAccessToken } from "@/features/Authentication/helpers/tokenManagerApi";
import {
  getToken,
  setToken,
  deleteToken,
} from "@/features/Authentication/helpers/tokenStorage";
import type {
  TokenExchangeResponse,
  TokenType,
} from "@/features/Authentication/sharedTypes";
import {
  useComputeAndUpdateServerTime,
  useRefreshServerTimeState,
} from "@/features/ServerTime/contexts/ServerTime";
import { useTrackError } from "@/helpers/errorTracking";

const channelName = "BREX_AUTH_TOKEN_MANAGMENT_BROADCAST_CHANNEL";

const tokenChannel = new BroadcastChannel<string>(channelName, {
  // We set this to false since we know that the broadcast channel will not be
  // used in a WebWorker (increases performance)
  webWorkerSupport: false,
});

const elector = createLeaderElection(tokenChannel, {
  /**
   * This value decides how often instances will renegotiate who is leader.
   * Should be ~2 times the response time to accomodate for multiple tabs
   */
  fallbackInterval: 1500,
  /**
   * This timer value is used when resolving which instance should be leader.
   * We are increasing this to account for dashboard "quite big" loading times
   */
  responseTime: 750,
});

const requestThatOtherTabsRefreshToken = (
  token: TokenExchangeResponse,
  requestInitiationTime: number,
) => {
  tokenChannel.postMessage(
    JSON.stringify(
      messageForOtherFramesToUpdateToken(token, requestInitiationTime),
    ),
  );
};

const requestForLock = () => {
  tokenChannel.postMessage(JSON.stringify(messageToNotifyRefreshLock()));
};

const releaseLock = () => {
  tokenChannel.postMessage(JSON.stringify(messageToNotifyReleaseRefreshLock()));
};

export const forceCrossTabLogout = () => {
  tokenChannel.postMessage(
    JSON.stringify(messageForOtherFramesToDeleteTheirTokens()),
  );
  tokenChannel.postMessage(
    JSON.stringify(messageForOtherFramesToForceLogout()),
  );
};

/**
 * This hook is used to select a leader among all the tabs/windows (frames)
 * which will be the one executing authentication token refresh and token
 * propagation
 *
 * The leadership election approach helps us avoid using a mutex/lock, landing
 * the dashboard on deadlocks, as well as avoiding the need lock timeouts,
 * and coordinating between multiple threads.
 *
 * useLeadershipElection exposes a "requestLeader" function so we can await
 * for leadership election as part of our dashboard initialization process (or
 * re-run the leader selection process)
 *
 * This hook also takes care of re-running the leadership selection process in
 * case the current leader abdicates (Tab is closed, is put to sleep, gets
 * throttled, etc) and updating the state.
 */
export const useLeadershipElection = ({
  refreshToken,
  setIsLocked,
  setAuthenticationContext,
  resetAuthenticationContext,
}: {
  refreshToken: React.MutableRefObject<() => Promise<void>>;
  setIsLocked: (isLocked: boolean) => void;
  setAuthenticationContext: (tokenExchange: TokenType) => void;
  resetAuthenticationContext: () => void;
}) => {
  const [isLeader, setIsLeader] = useState(false);
  const { trackError } = useTrackError();
  const lock = useCallback(() => {
    requestForLock();
    setIsLocked(true);
  }, [setIsLocked]);

  const release = useCallback(() => {
    releaseLock();
    setIsLocked(false);
  }, [setIsLocked]);

  const computeAndUpdateServerTime = useComputeAndUpdateServerTime();
  const refreshRefreshServerTimeState = useRefreshServerTimeState();

  /**
   * TODO: Gather datapoints to figureout if this function neededs to be throttled.
   */
  refreshToken.current = async () => {
    // When this token does not exist or is invalid, it is safe for us to assume that the refresh-token is also empty.
    // If this check is not here, dashboard can still try to do a refresh if the user does not have valid tokens.
    // For example, if the user logs out on one tab, the tokens are removed from localstorage and cookies.
    // Then some other tab might still want to run a refresh, if that's the case then we get a failure of token
    // refreshment.
    const currentAccessToken = getToken();
    if (!currentAccessToken) {
      return;
    }

    // Lock refreshes - anounce we are executing a refresh
    lock();
    const requestInitiationTime = Date.now();
    // Refresh token
    const accessToken = await refreshAccessToken();
    // Save token
    setToken(accessToken);
    // Update auth context
    setAuthenticationContext(accessToken);

    // Compute server-client time sync offset
    computeAndUpdateServerTime(accessToken, requestInitiationTime);

    // Propagate changes
    requestThatOtherTabsRefreshToken(accessToken, requestInitiationTime);
    // Release Lock - anounce we are done with the refreh
    release();
  };

  const updateTokenInAuthenticationContext = useCallback(
    async (
      parsedMessage: ReturnType<typeof messageForOtherFramesToUpdateToken>,
    ) => {
      const messageToken = parsedMessage.tokenResponse;
      const parsedToken = getToken();
      if (messageToken) {
        setAuthenticationContext(parsedMessage.tokenResponse);
        computeAndUpdateServerTime(
          parsedMessage.tokenResponse,
          parsedMessage.requestInitiationTime,
        );
      } else if (parsedToken) {
        // Fallback to token parsing from localstorage incase the message token doesn't work for some reason
        setAuthenticationContext(parsedToken);
        refreshRefreshServerTimeState();
      }
    },
    [
      setAuthenticationContext,
      refreshRefreshServerTimeState,
      computeAndUpdateServerTime,
    ],
  );

  const clearTokenAndAuthenticationContext = useCallback(async () => {
    deleteToken();
    resetAuthenticationContext();
  }, [resetAuthenticationContext]);

  const handleForceLogout = useCallback(async () => {
    // Forcing a reload seems like the easiest way to avoid the issues that
    // multiple tabs logging out cause. (Multiple token revocation, triggering
    // multiple oauth/logout flows, etc)
    window.location.reload();
  }, []);

  /**
   * As soon as this hook is loaded, we request a leader to be chosen.
   * If the leader is already present, nothing will happen, if not, a new leader
   * will be elected.
   */
  useEffect(() => {
    const requestLeader = async () => {
      try {
        await elector.awaitLeadership();
        setIsLeader(true);
      } catch (e) {
        // This is not an actual error, but the spec for
        // elector.awaitLeadership() throws if the current frame is not the
        // leader. So we have to catch it to set the state.
        setIsLeader(false);
      }
    };
    requestLeader();
  }, []);

  /**
   * As soon as this hook is loaded, (wether the frame is the leader or not) we
   * start listening for messages from other tabs.
   *
   * If there is a request for a token refresh, the leader will execute it and propagate the results asking followers to update their tokens.
   *
   * If a follower is requested to update their token, they'll look into the storage for it, or pull from the message being sent to them as a fallback
   *
   * TODO: Handle multiple requests for refresh in a short amount of time
   */
  useEffect(() => {
    const onMessage = async (message: string) => {
      const parsedMessage = JSON.parse(message) as PossibleMessages;
      // This is what will handle the messages from other frames
      switch (parsedMessage.message) {
        case "notify_refresh_lock": {
          setIsLocked(true);
          break;
        }
        case "notify_release_of_refresh_lock": {
          setIsLocked(false);
          break;
        }
        case "notify_of_token_update": {
          await updateTokenInAuthenticationContext(parsedMessage);
          break;
        }
        case "notify_of_token_delete": {
          await clearTokenAndAuthenticationContext();
          break;
        }
        case "force_logout": {
          await handleForceLogout();
          break;
        }
        default: {
          // This should never happen!
          // But if it does... we are tracking it 😁
          trackError(
            "Unhandled cross-tab message",
            new Error(
              `Unhandled cross-tab message, received: ${
                (parsedMessage as any).message
              }`,
            ),
            {
              parsedMessage,
              rawMessage: message,
            },
          );
        }
      }
    };

    tokenChannel.addEventListener("message", onMessage);
    return () => {
      tokenChannel.removeEventListener("message", onMessage);
    };
  }, [
    clearTokenAndAuthenticationContext,
    updateTokenInAuthenticationContext,
    handleForceLogout,
    isLeader,
    setIsLocked,
    trackError,
  ]);
};
