import type { UserIntegrationsQuery } from "@/features/Authentication/data/__generated__/queries.generated";
import { connectionToNodes } from "@/helpers";

export const selectUserIntegrationsProperties = (
  integrationsData?: UserIntegrationsQuery,
) => {
  return {
    activeIntegrationVendor: connectionToNodes(
      integrationsData?.integrations,
    ).find(
      (integration) =>
        integration?.credential?.status !== "disconnected" &&
        integration.status !== "disabled" &&
        integration.status !== "disconnected",
    )?.vendor,
  };
};
