import { Banner } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import * as React from "react";

interface DefaultErrorBannerProps {
  description?: string;
}

export const DefaultErrorBanner: React.FC<DefaultErrorBannerProps> = ({
  description,
}) => {
  const { t } = useTranslation();

  return (
    <Banner variant="error" title={t("error")}>
      {description ??
        t("something-went-wrong-please-try-again-or-contact-support")}
    </Banner>
  );
};
