import * as React from "react";
import { useRef } from "react";
import { isCookieBasedAuth } from "@/features/Authentication/helpers/oauth";
import { getToken } from "@/features/Authentication/helpers/tokenStorage";
import { useLeadershipElection } from "@/features/Authentication/hooks/useLeadershipElection";
import type { TokenType } from "@/features/Authentication/sharedTypes";
import noOp from "@/helpers/noOp";

type AuthenticationContextType = {
  refreshToken: () => Promise<void>;
  getIsLocked: () => boolean;
  resetAuthenticationContext: () => void;
  getAccessToken: () => string | null;
  getIdToken: () => string | null;
  setAuthenticationContext: (args: TokenType) => void;
};

const AuthenticationContext = React.createContext<AuthenticationContextType>({
  refreshToken: async () => {},
  getIsLocked: () => false,
  getAccessToken: noOp,
  getIdToken: noOp,
  resetAuthenticationContext: noOp,
  setAuthenticationContext: noOp,
});

/**
 * Never import this context, if you feel you reachout for this context for any
 * solution beside the dashboard authentication purposes, please reach out to the
 * UX/Web Core/Web Systems team
 */
export const useAuthenticationContext = () =>
  React.useContext(AuthenticationContext);

function TokenAuthenticationContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const token = getToken();

  // Refs
  const accessToken = useRef(token?.accessToken ?? null);
  const isLocked = useRef(false);
  const refreshToken = useRef(async () => {});
  const idToken = useRef(token?.idToken ?? null);

  // Getters
  const getAccessToken = useRef(() => accessToken.current);
  const getIdToken = useRef(() => idToken.current);
  const getIsLocked = useRef(() => isLocked.current);

  // Setters
  const setAccessToken = useRef((newToken: string | null) => {
    accessToken.current = newToken;
  });
  const setIsLocked = useRef((newIsLocked: boolean) => {
    isLocked.current = newIsLocked;
  });
  const setIdToken = useRef((newIdToken: string | null) => {
    idToken.current = newIdToken;
  });
  const resetAuthenticationContext = useRef(() => {
    setAccessToken.current(null);
  });
  const setAuthenticationContext = useRef((tokenExchange: TokenType) => {
    setAccessToken.current(tokenExchange.accessToken);
    setIdToken.current(tokenExchange.idToken);
  });

  // LeaderShipElection Hook
  useLeadershipElection({
    refreshToken,
    setIsLocked: setIsLocked.current,
    setAuthenticationContext: setAuthenticationContext.current,
    resetAuthenticationContext: resetAuthenticationContext.current,
  });

  const value = React.useMemo<AuthenticationContextType>(
    () => ({
      refreshToken: refreshToken.current,
      getAccessToken: getAccessToken.current,
      getIdToken: getIdToken.current,
      getIsLocked: getIsLocked.current,
      resetAuthenticationContext: resetAuthenticationContext.current,
      setAuthenticationContext: setAuthenticationContext.current,
    }),
    [],
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}

/**
 * We should be very selective about what to add to this context.
 *
 * It should be reserved for data that's only used for authentication purposes.
 * e.g. access token value, token expiration, idToken and scope/
 *
 * Don't add any data from other sources to make it easier to authenticate or validate the user, this context is only used to provide authentication related information.
 *
 * ATM. Information on this provider is used to:
 * - Provide token expiration date so that we can show  SessionTimeoutModalController properly
 * - Populate the authentication header on graphql call.
 *
 */
export const AuthenticationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  if (!isCookieBasedAuth()) {
    return (
      <TokenAuthenticationContextProvider>
        {children}
      </TokenAuthenticationContextProvider>
    );
  }

  return <>{children}</>;
};
