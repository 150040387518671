import { useAwaitQueryWithAtoms } from "@brexhq/jotai-utils";
import type { PermissionsSummaryQuery } from "@/features/Authorization/data/__generated__/queries.generated";
import { permissionsSummaryQuery } from "@/features/Authorization/data/queries";
import { atomsWithQuery } from "@/features/Jotai";

export const permissionsSummaryQueryAtom =
  atomsWithQuery<PermissionsSummaryQuery>({
    suspensePolicy: "initial",
  });

export const usePermissionsSummaryQueryWithAtom = () => {
  return useAwaitQueryWithAtoms(permissionsSummaryQuery, {
    atomsWithQuery: permissionsSummaryQueryAtom,
    fetchPolicy: "cache-and-network",
  });
};
