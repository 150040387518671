import styled from "@emotion/styled";
import { position } from "polished";

export const FullScreenPageContainer = styled.div({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  overflow: "auto",
  scrollBehavior: "smooth",
  ...position("absolute", 0, 0, 0, 0),
});
