export enum AnalyticsEvents {
  SessionTimeoutModalRefreshSession = "SessionTimeoutModal.refreshSession",
  SessionTimeoutModalStart = "SessionTimeoutModal.start",
}

type EmptyPayload = {};

type RefreshSessionPayload = {
  countdown?: number;
};

export type EventSchemaMap = {
  [AnalyticsEvents.SessionTimeoutModalRefreshSession]: RefreshSessionPayload;
  [AnalyticsEvents.SessionTimeoutModalStart]: EmptyPayload;
};
