import type { BaseButtonProps } from "@brexhq/component-library";
import * as React from "react";
import { useLinkClickHandler, useHref, useLocation } from "react-router-dom";
import { useUnsavedChangesModal } from "@/domains/App/contexts/UnsavedChangesContext";
import { maybeUpdateWorkflowId } from "@/features/Analytics/helpers/workflowSessionManager";
import { useDeepMemo } from "@/hooks/useDeepMemo";
import { makeAppNavigationState } from "@/hooks/useNavigate";

type LimitedMouseEvent = Pick<
  MouseEvent,
  "button" | "metaKey" | "altKey" | "ctrlKey" | "shiftKey"
>;

function isModifiedEvent(event: LimitedMouseEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

// From react-router-dom
// https://github.com/remix-run/react-router/blob/f9b3dbd9cbf513366c456b33d95227f42f36da63/packages/react-router-dom/dom.ts#L27-L45
// We use the same logic as them to determine whether or not the user is performing a normal click.
// Unfortunately, react-router-dom does not export this function.
function shouldProcessLinkClick(event: LimitedMouseEvent, target?: string) {
  return (
    event.button === 0 && // Ignore everything but left clicks
    (!target || target === "_self") && // Let browser handle "target=_blank" etc.
    !isModifiedEvent(event) // Ignore clicks with modifier keys
  );
}

type UseInternalLinkPropsArgs = {
  /** The base URL to point to */
  href: string | undefined;
  /** A query parameter string to add to the URL */
  queryParams?: string;
  /** Additional state to add to browser history state, accessible via useLocation */
  state?: any;
  /** When true, update the tracking Workflow ID */
  shouldUpdateWorkflowId?: boolean;
  /** A callback called before trying to navigate. The callback can cancel navigation with e.preventDefault() */
  onClick?: BaseButtonProps["onClick"];
};

/**
 * useInternalLinkProps provides an onClick handler for Link components
 * which navigates without page reloads on normal clicks, but also handles
 * keyboard and mouse modifiers to open links in new tabs or windows.
 */
export const useInternalLinkProps = ({
  onClick,
  href: pathname,
  queryParams,
  state,
  shouldUpdateWorkflowId = false,
}: UseInternalLinkPropsArgs) => {
  const { shouldOpenModal, openModal } = useUnsavedChangesModal();
  const { pathname: currentPathname, search: currentQueryParams } =
    useLocation();
  const newState = makeAppNavigationState({
    state,
    pathname: currentPathname,
    search: currentQueryParams,
  });

  const fullHref = useHref({ pathname: pathname, search: queryParams });

  const stableNewState = useDeepMemo(newState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLinkClick = React.useCallback(
    useLinkClickHandler(
      { pathname, search: queryParams },
      { state: stableNewState },
    ),
    [pathname, queryParams, stableNewState],
  );

  const handleClick = React.useCallback(
    (e: React.MouseEvent<any>) => {
      const to = { pathname, search: queryParams };

      // The user-supplied onClick function can block the link
      onClick?.(e);
      if (e.defaultPrevented) {
        return;
      }

      // Unsaved changes can block the link
      if (
        shouldOpenModal(to) &&
        // ... unless opening a new tab
        shouldProcessLinkClick(e)
      ) {
        e.preventDefault();
        openModal(to);
        return;
      }

      handleLinkClick(e);

      const openedInNewTab = e.ctrlKey || e.metaKey;
      if (!openedInNewTab && shouldUpdateWorkflowId) {
        maybeUpdateWorkflowId(pathname);
      }
    },
    [
      handleLinkClick,
      pathname,
      onClick,
      openModal,
      queryParams,
      shouldOpenModal,
      shouldUpdateWorkflowId,
    ],
  );
  return {
    href: fullHref,
    onClick: handleClick,
    type: "link" as const,
  };
};
