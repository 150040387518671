import gql from "graphql-tag";
import { verificationRequestConfigFragment } from "@/routes/Onboarding/data/fragments";

export const listVerificationAnalysesQuery = gql`
  query ListVerificationAnalysesQuery(
    $filter: VerificationAnalysesFilterInput
    $page: PaginationInput
  ) {
    verificationAnalyses(filter: $filter, page: $page) {
      totalCount
      edges {
        node {
          id
          customerAccountId
          subjectId
          result
          status
          applicantCustomerUserId
          submittedAt
          requirement {
            name
          }
          requests {
            id
            status
            requestType
            payload
            config {
              ...VerificationRequestConfigFragment
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    user {
      id
    }
  }
  ${verificationRequestConfigFragment}
`;

export const getVerificationAnalysisQuery = gql`
  query GetVerificationAnalysisQuery($id: ID!) {
    verificationAnalysis(id: $id) {
      id
      customerAccountId
      subjectId
      result
      status
      applicantCustomerUserId
      submittedAt
      requirement {
        name
      }
      requests {
        id
        status
        requestType
        payload
        config {
          ...VerificationRequestConfigFragment
        }
      }
    }
  }
  ${verificationRequestConfigFragment}
`;
