import * as React from "react";
import { LoadPermissions } from "@/domains/App/features/Permissions/components/LoadPermissions";
import { LoadResourceOperations } from "@/domains/App/features/Permissions/components/LoadResourceOperations";
import { LoadSandboxQueryWithAtom } from "@/domains/App/features/Sandbox/components/LoadSandboxQueryWithAtom";
import { usePermissionsSummaryQueryWithAtom } from "@/features/Authorization/hooks/usePermissionsSummaryQueryWithAtom";
import { useFetchCustomBrandingData } from "@/features/CustomThemeBranding/hooks/useCustomBranding";
import { LoadFspConfigQueryWithAtom } from "@/features/ProAccess";
import { useSeenFlagsQueryWithAtoms } from "@/features/SeenFlag/data/queries";

/**
 * Warm up the cache for seenFlagsQueryAtoms
 */
const SeenFlagsPreloader: React.FC = React.memo(() => {
  useSeenFlagsQueryWithAtoms();
  return null;
});

SeenFlagsPreloader.displayName = "SeenFlagsPreloader";

/**
 * Warm up the cache for customBranding
 */
const CustomBrandingLoader: React.FC = React.memo(() => {
  useFetchCustomBrandingData();
  return null;
});

CustomBrandingLoader.displayName = "CustomBrandingLoader";

/**
 * Warm up the cache for permissionsSummaryQueryAtoms
 */
const PermissionsSummaryPreloader: React.FC = React.memo(() => {
  usePermissionsSummaryQueryWithAtom();
  return null;
});

PermissionsSummaryPreloader.displayName = "PermissionsSummaryPreloader";

export const SkeletonLoadersProvider: React.FC = ({ children }) => {
  // Load components that call queryWithAtoms hooks here to preload in parallel
  return (
    <>
      {/* TODO: add error boundary for retries failed preloader queries  with a generic error fallback*/}
      <React.Suspense fallback={null}>
        <LoadPermissions />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <SeenFlagsPreloader />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <LoadResourceOperations />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <LoadFspConfigQueryWithAtom />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <LoadSandboxQueryWithAtom />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <CustomBrandingLoader />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <PermissionsSummaryPreloader />
      </React.Suspense>
      {children}
    </>
  );
};
