import type { JsonObject } from "type-fest";
// React doesn't expose types for Tags, so we copied from
// https://github.com/facebook/react/blob/master/packages/shared/ReactWorkTag.js
const enum Tag {
  FunctionComponent = 0,
  ClassComponent = 1,
  IndeterminateComponent = 2,
  HostRoot = 3,
  HostPortal = 4,
  HostComponent = 5,
  HostText = 6,
  Fragment = 7,
  Mode = 8,
  ContextConsumer = 9,
  ContextProvider = 10,
  ForwardRef = 11,
  Profiler = 12,
  SuspenseComponent = 13,
  MemoComponent = 14,
  SimpleMemoComponent = 15,
  LazyComponent = 16,
  IncompleteClassComponent = 17,
  DehydratedSuspenseComponent = 18,
}

export const VIEW_CONTROLLER_DISPLAY_NAME = "ProtectedPage";

// React doesn't expose types for Fiber or pendingProps, so we type our own.
export interface Fiber {
  tag: Tag;
  key: null | string;
  elementType: React.ComponentType | string | null;
  type: React.ComponentType | string | null;
  stateNode: any;
  return: Fiber | null;
  child: Fiber | null;
  sibling: Fiber | null;
  index: number;
  ref: React.LegacyRef<unknown>;
  pendingProps: {
    // React doesn't expose types for Fiber or pendingProps, so we type our own
    children: { type: { name: string; displayName: string } };
  };
  memoizedProps: unknown;
  memoizedState: unknown;

  // available in React dev build
  _debugOwner?: Fiber | null;
  _debugSource?: {
    fileName: string;
    lineNumber: number;
    columnNumber: number;
  } | null;
}

// We typed ReactInstance with suport for "_reactInternals" and "_reactInternalFiber"
// As this will allow us to reuse this for other environments once extracted
export type ReactInstance =
  | {
      _reactInternals: Fiber;
    }
  | {
      _reactInternalFiber: Fiber;
    };

export type BusyElementType = "controller" | "request";

export type ControllerContextValue = {
  controllerName?: string;
  controllerSessionId?: string;
  controllerDepth: number;
  depthAfterPageContext?: number;
  ancestorControllerNames: string[];
  // map of controller name to sessionId
  ancestorControllersSessionMap: Record<string, string>;
  addBusyElement: (id: string, type: BusyElementType) => void;
  removeBusyElement: (id: string, type: BusyElementType) => void;
  getIsUsingSuspense: () => boolean;
  setSuspenseUsageToTrueForController: () => void;
};

export type UseControllerContextReturn = Pick<
  ControllerContextValue,
  | "controllerName"
  | "controllerSessionId"
  | "depthAfterPageContext"
  | "ancestorControllerNames"
  | "ancestorControllersSessionMap"
  | "addBusyElement"
  | "removeBusyElement"
  | "getIsUsingSuspense"
  | "setSuspenseUsageToTrueForController"
> & {
  isUsingJotai?: boolean;
  viewName?: string;
};

export type AccessingReactFiberProps = {
  setContextControllerName: (controllerName: string) => void;
  controllerName: string;
  elementProps: any;
  ChildrenElement: React.ComponentType<any>;
};

export type ControllerEventsMap = {
  "APP.controller.mounted": JsonObject;
  "APP.controller.session": JsonObject;
  "APP.controller.unmounted": JsonObject;
  "APP.controller.ready": JsonObject;
  "APP.controller.criticalData.loaded": JsonObject;
};
