import { FinancialAsset } from "@brexhq/currency-ts";
import Big from "big.js";
import { BudgetLimitVisibilityType } from "@/__generated__/globalTypes";
import type { BudgetsTableQuery_globalBudget_Budget_children_BudgetConnection_edges_BudgetEdge_node_Budget as BudgetsTableQuery_budgets_edges_node } from "@/domains/Primitives/features/BudgetsTable/data/__generated__/queries.generated";
import type {
  BudgetAmountsFragment,
  BudgetAmountsLiteFragment,
} from "@/features/Budgets/data/__generated__/fragments.generated";

const isDefined = <T>(v: T | undefined): v is T => typeof v !== "undefined";

type GqlFinancialAsset = Parameters<typeof FinancialAsset.fromGraphQL>[0];

export const createMockBudgetAmountGQL = (
  instrumentCode?: string,
  value?: number,
): GqlFinancialAsset => {
  const instrumentCodeString = instrumentCode ?? "USD";
  return {
    quantity: isDefined(value) ? new Big(value).toString() : "200",
    instrumentCodeString,
  };
};

/**
 * Shared baseLimit value
 * (original budget / spent limit amount w/o any limit increases or rollover values)
 */

export const budgetBaseLimitAmountFromGQL = (
  fragment: Pick<
    BudgetsTableQuery_budgets_edges_node,
    "limitVisibility" | "limitVisibleForUser" | "baseLimit"
  >,
): FinancialAsset | undefined => {
  // Undefined if limit isn't visible
  const { baseLimit, limitVisibility, limitVisibleForUser } = fragment;
  const limitShown =
    limitVisibility === BudgetLimitVisibilityType.SHARED || limitVisibleForUser;
  return limitShown && baseLimit
    ? FinancialAsset.fromGraphQL(baseLimit)
    : undefined;
};

// LITE VERSIONS
// -------------

/**
 * Most components need to know:
 * 1) amount spent
 * 2) limit (if not visible, why it isn't visible)
 */
export interface BudgetAmountsLite {
  currentPeriodSpent: FinancialAsset;
  limitVisibleForUser: boolean;
  limitVisibility: BudgetLimitVisibilityType;
  // Full spendable limit of the current period, including rollover but NOT buffer
  spendableLimit: FinancialAsset | undefined; // Undefined if limit isn't visible
}

const emptyBudgetAmountsLite: BudgetAmountsLite = {
  currentPeriodSpent: new FinancialAsset("0", "USD"),
  limitVisibleForUser: true,
  limitVisibility: BudgetLimitVisibilityType.SHARED,
  spendableLimit: undefined,
};

/**
 * For use in selectors: convert GQL budget amounts into commonly used fields
 */
export const budgetAmountsLiteFromGQL = (
  fragment: Omit<BudgetAmountsLiteFragment, "id"> | null | undefined,
): BudgetAmountsLite => {
  if (!fragment) {
    return emptyBudgetAmountsLite;
  }
  const {
    limitVisibility,
    limitVisibleForUser,
    spendableLimit,
    currentPeriodBalance,
  } = fragment;
  const limitShown =
    limitVisibility === BudgetLimitVisibilityType.SHARED || limitVisibleForUser;
  return {
    // Return currentPeriodSpent as 0 if unavailable
    currentPeriodSpent:
      currentPeriodBalance &&
      // TODO: weird bug where instrumentCodeString is empty sometimes
      // https://brex.slack.com/archives/C04NR5WGBDY/p1686766300908129
      currentPeriodBalance.spent.instrumentCodeString !== ""
        ? FinancialAsset.fromGraphQL(currentPeriodBalance.spent)
        : new FinancialAsset(
            "0",
            spendableLimit?.instrumentCodeString || "USD",
          ),
    limitVisibility,
    limitVisibleForUser,
    spendableLimit:
      limitShown && spendableLimit
        ? FinancialAsset.fromGraphQL(spendableLimit)
        : undefined,
  };
};

export const createMockBudgetAmountsLiteGQL = (
  instrumentCode?: string,
  spent?: number,
  limit?: number,
): Omit<BudgetAmountsLiteFragment, "id"> => {
  const instrumentCodeString = instrumentCode ?? "USD";
  return {
    currentPeriodBalance: {
      spent: createMockBudgetAmountGQL(instrumentCodeString, spent ?? 35),
    },
    spendableLimit: createMockBudgetAmountGQL(
      instrumentCodeString,
      limit ?? 250,
    ),
    limitVisibility: BudgetLimitVisibilityType.SHARED,
    limitVisibleForUser: true,
  };
};

// FULL (NON-LITE) VERSIONS
// ------------------------

/**
 * For components that need the full set of fields
 */
export interface BudgetAmounts extends BudgetAmountsLite {
  enforceLimit: boolean;
  enforceLimitBufferPercentage: number | undefined;
  limit: FinancialAsset | undefined; // Undefined if limit isn't visible
  limitWithBuffer: FinancialAsset | undefined; // Undefined if limit isn't visible
}

const emptyBudgetAmountsRemaining: BudgetAmounts = {
  ...emptyBudgetAmountsLite,
  enforceLimit: false,
  enforceLimitBufferPercentage: 0,
  limit: undefined,
  limitWithBuffer: undefined,
};

/**
 * For use in selectors: convert GQL budget amounts into commonly used fields
 */
export const budgetAmountsFromGQL = (
  fragment: Omit<BudgetAmountsFragment, "id"> | undefined | null,
): BudgetAmounts => {
  if (!fragment) {
    return emptyBudgetAmountsRemaining;
  }
  const {
    limitVisibility,
    limitVisibleForUser,
    limit,
    limitWithBuffer,
    enforceLimit,
    enforceLimitBufferPercentage,
  } = fragment;
  const limitShown =
    limitVisibility === BudgetLimitVisibilityType.SHARED || limitVisibleForUser;
  return {
    ...budgetAmountsLiteFromGQL(fragment),
    enforceLimit: !!enforceLimit,
    enforceLimitBufferPercentage: enforceLimitBufferPercentage ?? undefined,
    limit: limitShown && limit ? FinancialAsset.fromGraphQL(limit) : undefined,
    limitWithBuffer:
      limitShown && limitWithBuffer
        ? FinancialAsset.fromGraphQL(limitWithBuffer)
        : undefined,
  };
};

export const createMockBudgetAmountsGQL = (
  instrumentCode?: string,
  spent?: number,
  limit?: number,
): Omit<BudgetAmountsFragment, "id"> => {
  const instrumentCodeString = instrumentCode ?? "USD";
  return {
    ...createMockBudgetAmountsLiteGQL(instrumentCode, spent, limit),
    limit: createMockBudgetAmountGQL(instrumentCodeString, limit ?? 200),
    limitWithBuffer: null,
    enforceLimit: true,
    enforceLimitBufferPercentage: 10,
    transactionLimit: null,
  };
};

export const sanitizeSpendableLimit = (
  spendableLimit: FinancialAsset | undefined,
): FinancialAsset | undefined => {
  if (spendableLimit && Number(spendableLimit.quantity) < 0) {
    return new FinancialAsset("0", spendableLimit.instrumentCode);
  }
  return spendableLimit;
};
