import {
  SecondaryButton,
  StatusMessage,
  StatusMessageDirection,
} from "@brexhq/component-library";
import styled from "@emotion/styled";
import React from "react";
import ErrorIllustration from "@assets/illustrations/empower/spot/back-end-crash.png";
import { AnalyticsEvents } from "@/domains/App/analytics";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import { useTrackError } from "@/helpers/errorTracking";

const Wrapper = styled.div({
  display: "flex",
  justifyContent: "center",
});

export type ErrorMessageProps = {
  description?: string;
  errorMessage?: string;
  errorOrigin?: string;
  onClickRetry?: () => void;
  title?: string;
  isMappedUserError?: boolean;
};

export type DefaultErrorProps = {
  direction?: StatusMessageDirection;
  retryText?: string;
} & ErrorMessageProps;

export function useTrackErrorMessage({
  description,
  errorMessage,
  errorOrigin,
  title,
  isMappedUserError,
  onClickRetry,
}: ErrorMessageProps) {
  const trackEvent = useTrackEvent();
  const { trackError } = useTrackError();

  const metadata = React.useMemo(
    () => ({
      description,
      errorMessage,
      errorOrigin,
      title,
      isMappedUserError,
    }),
    [description, errorMessage, errorOrigin, title, isMappedUserError],
  );

  React.useEffect(() => {
    // try to derive a useful error message from passed props
    const errorMsg =
      errorMessage || description || title || "DefaultError shown";

    trackEvent(AnalyticsEvents.ErrorMounted, metadata);
    if (!isMappedUserError) {
      trackError(errorMsg, new Error(errorMsg), metadata);
    }
  }, [
    trackEvent,
    trackError,
    metadata,
    errorMessage,
    description,
    title,
    isMappedUserError,
  ]);

  const handleClickRetry = React.useCallback(() => {
    trackEvent(AnalyticsEvents.DefaultErrorButtonClicked, metadata);
    onClickRetry?.();
  }, [metadata, onClickRetry, trackEvent]);

  return onClickRetry && handleClickRetry;
}

export const InternalDefaultError: React.FC<DefaultErrorProps> = ({
  description,
  direction = StatusMessageDirection.VERTICAL,
  errorMessage,
  errorOrigin,
  onClickRetry,
  retryText = "Try again",
  title,
  isMappedUserError = false,
}) => {
  const handleClickRetry = useTrackErrorMessage({
    description,
    errorMessage,
    errorOrigin,
    title,
    isMappedUserError,
    onClickRetry,
  });

  const actions = handleClickRetry
    ? {
        id: "retry",
        action: (
          <SecondaryButton onClick={handleClickRetry}>
            {retryText}
          </SecondaryButton>
        ),
      }
    : undefined;

  return (
    <Wrapper data-testid="default-error">
      <StatusMessage
        direction={direction}
        graphic={ErrorIllustration}
        title={title || "Something went wrong"}
        description={description || "Please try again or contact support."}
        actions={actions}
      />
    </Wrapper>
  );
};
