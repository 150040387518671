import styled from "@emotion/styled";
import { margin, padding, size } from "polished";

export const StyledLayout = styled.div<{ primaryLayout?: boolean }>(
  ({ theme, primaryLayout }) => ({
    position: "relative",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: primaryLayout
      ? theme.colors.background.primary
      : theme.colors.background.secondary,
  }),
);

export const StyledHeader = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexShrink: 0,
  ...padding(theme.spacing[64], theme.spacing[28], theme.spacing[40], 75),
  [theme.breakpoints.phone]: {
    height: 64,
    backgroundColor: theme.colors.background.primary,
    ...theme.borders.defaultBorder.bottom,
    ...padding(0, theme.spacing[28]),
  },
}));

export const StyledLogo = styled.div(({ theme }) => ({
  marginRight: theme.spacing[20],
  flexShrink: 0,
  width: 126,
  [theme.breakpoints.phone]: {
    ...size(24, 90),
  },
}));

export const StyledHeaderAction = styled.div({
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
});

export const StyledHeaderActionMessage = styled.div(({ theme }) => ({
  marginRight: theme.spacing[20],
  [theme.breakpoints.phone]: {
    display: "none",
  },
}));

export const StyledContent = styled.div(({ theme }) => ({
  flexGrow: 1,
  ...margin(0, "auto"),
  position: "relative",
  paddingTop: "10vh",
  width: 462,
  overflow: "auto",
  [theme.breakpoints.phone]: {
    ...padding(theme.spacing[48], theme.spacing[28], 0, theme.spacing[28]),
    width: "auto",
    margin: 0,
  },
}));

export const StyledPreFooter = styled.div(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  ...margin(0, "auto"),
  ...padding(56, 0, theme.spacing[12]),
  ...theme.typography.BodySmallR,
  [theme.breakpoints.phone]: {
    ...padding(theme.spacing[12], theme.spacing[28]),
    width: "auto",
    margin: 0,
  },
}));

export const StyledFooter = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 600,
  flexShrink: 0,
  color: theme.colors.content.primary,
  ...margin(0, "auto"),
  ...padding(56, 0, theme.spacing[32]),
  ...theme.typography.DetailLargeR,
  [theme.breakpoints.phone]: {
    ...padding(theme.spacing[12], theme.spacing[28]),
    width: "auto",
    margin: 0,
  },
}));

export const StyledFooterSeparator = styled.div({
  width: 32,
});
