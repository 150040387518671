import { COPILOT_STORAGE_KEY } from "@/features/Copilot/sharedTypes";

export const getLocalStorageDelegatorId = () =>
  window.localStorage.getItem(COPILOT_STORAGE_KEY) ?? undefined;

export const setLocalStorageDelegatorId = (userId: string) =>
  window.localStorage.setItem(COPILOT_STORAGE_KEY, userId);

export const removeLocalStorageDelegatorId = () =>
  window.localStorage.removeItem(COPILOT_STORAGE_KEY);
