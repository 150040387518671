import type { PaletteColor } from "@brexhq/metal-foundation";
import type { Theme } from "@emotion/react";
import { darken, lighten, transparentize } from "polished";

export type BrandColors = Theme["colors"]["accent"]["primary"]["background"];

export const generateBrandColors = (primary: string): BrandColors => ({
  default: primary as PaletteColor,
  hover: darken(0.05, primary) as PaletteColor,
  pressed: darken(0.135, primary) as PaletteColor,
  focused: primary as PaletteColor,
  disabled: transparentize(0.9, primary) as PaletteColor,
  muted: transparentize(0.9, primary) as PaletteColor,
});

export const generateDarkBrandColors = (primary: string): BrandColors => ({
  default: primary as PaletteColor,
  hover: lighten(0.05, primary) as PaletteColor,
  pressed: lighten(0.135, primary) as PaletteColor,
  focused: primary as PaletteColor,
  disabled: transparentize(0.9, primary) as PaletteColor,
  muted: transparentize(0.9, primary) as PaletteColor,
});
