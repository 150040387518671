import * as React from "react";
import { AdaContext } from "@/features/Ada/contexts/AdaContext";
import { createMockableHook } from "@/helpers/createMockableHook";

const _useAda = () => {
  const { initializeProvider, ...rest } = React.useContext(AdaContext);

  React.useEffect(() => {
    // whenever the "useAda" hook is called we start the initalization of
    // the provider. We are using the default values for the provider while it's
    // not loaded. so this will dynamically import the real ada provider,
    // load it into memory, and replace the old provider values once it's ready
    initializeProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return rest;
};

export const [useAda, MockAdaProvider] = createMockableHook(_useAda);
