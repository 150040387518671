import { useMemo } from "react";
import type { GetBudgetConfigurationFlags } from "@/data/__generated__/queries.generated";
import { getBudgetConfigurationFlags } from "@/data/queries";
import { useQuery } from "@/features/Apollo";
import { createMockableHook } from "@/helpers/createMockableHook";
import { inSnapshotRenderer } from "@/helpers/storybook/inSnapshotRenderer";
import { selectBudgetConfigurationFlags } from "@/selectors/selectBudgetConfigurationFlags";

// Since this hook is used high up in the React tree, it affects faker mock data
// for a lot of stories. Skip the query in snapshot renderer to avoid extra faker call,
// and force all flags to be false.
const skipQuery = inSnapshotRenderer();

const useBudgetConfigurationFlagsQuery = () =>
  useQuery<GetBudgetConfigurationFlags>(getBudgetConfigurationFlags, {
    skip: skipQuery,
    fetchPolicy: "cache-first",
  });

const useBudgetConfigurationFlags = () => {
  const { data } = useBudgetConfigurationFlagsQuery();
  return useMemo(() => selectBudgetConfigurationFlags(data), [data]);
};

export const [
  useMockableBudgetConfigurationFlags,
  MockBudgetConfigurationFlagsProvider,
] = createMockableHook(useBudgetConfigurationFlags);
