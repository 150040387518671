import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { To } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { UnsavedChangesModalController } from "@/domains/App/components/UnsavedChangesModalController";
import type {
  ConfigureFn,
  ModalConfig,
  ShouldBlockFn,
} from "@/domains/App/contexts/UnsavedChangesContext";
import { UnsavedChangesContext } from "@/domains/App/contexts/UnsavedChangesContext";

const DEFAULT_MODAL_MESSAGE =
  "You have started editing this page. Are you sure you want to leave?";
const DEFAULT_BLOCK_FN = () => false;

const useBeforeUnload = (
  shouldBlock: React.MutableRefObject<ShouldBlockFn>,
) => {
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      const mustBlock = shouldBlock.current({ isBeforeUnloadEvent: true });
      if (mustBlock) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes! Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useResetConfiguration = (
  installPathRef: React.MutableRefObject<string>,
  configure: ConfigureFn,
  shouldBlock: React.MutableRefObject<ShouldBlockFn>,
) => {
  const location = useLocation();
  useEffect(() => {
    const mustReset = !location.pathname.startsWith(installPathRef.current);
    if (mustReset) {
      configure({
        modalText: DEFAULT_MODAL_MESSAGE,
        installPath: "",
      });
      shouldBlock.current = DEFAULT_BLOCK_FN;
    }
  }, [location, configure, installPathRef, shouldBlock]);
};

export const UnsavedChangesProvider: React.FC = React.memo(({ children }) => {
  const modalText = useRef(DEFAULT_MODAL_MESSAGE);
  const shouldBlock = useRef<ShouldBlockFn>(DEFAULT_BLOCK_FN);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const installPathRef = useRef("");
  const blockedLocation = useRef<To>("");

  const configure = useCallback((config: ModalConfig) => {
    modalText.current = config.modalText;
    installPathRef.current = config.installPath;
    setIsModalOpen(false);
    blockedLocation.current = "";
  }, []);

  useResetConfiguration(installPathRef, configure, shouldBlock);

  const setShouldBlockFn = useCallback((fn: ShouldBlockFn) => {
    shouldBlock.current = fn;
  }, []);

  const shouldOpenModal = useCallback((location: To) => {
    const mustBlock = shouldBlock.current({
      location,
      isBeforeUnloadEvent: false,
    });

    return mustBlock;
  }, []);

  const openModal = useCallback(
    (location: To) => {
      blockedLocation.current = location;
      setIsModalOpen(true);
    },
    [setIsModalOpen],
  );

  useBeforeUnload(shouldBlock);

  return (
    <UnsavedChangesContext.Provider
      value={useMemo(
        () => ({
          configure,
          setShouldBlockFn,
          shouldOpenModal,
          openModal,
        }),
        [configure, setShouldBlockFn, shouldOpenModal, openModal],
      )}
    >
      {children}
      {isModalOpen && (
        <UnsavedChangesModalController
          bodyText={modalText.current}
          setIsModalOpen={setIsModalOpen}
          blockedLocation={blockedLocation.current}
        />
      )}
    </UnsavedChangesContext.Provider>
  );
});
