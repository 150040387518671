import qs from "qs";
import { useCallback } from "react";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import { trackPerformance } from "@/features/Analytics/helpers/performance";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { getTokenSub } from "@/features/Authentication/helpers/getTokenExpirationTime";
import { setToken } from "@/features/Authentication/helpers/tokenStorage";
import { useRestoreOriginalLocation } from "@/features/Authentication/hooks/useRestoreOriginalLocation";
import type { AuthResult } from "@/features/Authentication/sharedTypes";
import { useComputeAndUpdateServerTime } from "@/features/ServerTime/contexts/ServerTime";
import { urls } from "@/helpers/urls";
import { useNavigate } from "@/hooks/useNavigate";

export const useFinishLogin = () => {
  const authenticationContext = useAuthenticationContext();
  const restoreOriginalLocation = useRestoreOriginalLocation();
  const computeAndUpdateServerTime = useComputeAndUpdateServerTime();
  const track = useTrackEvent();
  const navigate = useNavigate();
  return useCallback(
    async (result: AuthResult) => {
      switch (result.result) {
        case "delegate":
          track(AllAnalyticsEvents.FinishLoginDelegate, {
            id: result.id,
            state: result.state,
          });
          const delegateUriParameters = qs.stringify(
            result.delegateUriParameters,
          );
          navigate(`${result.delegateUri}?${delegateUriParameters}`, {
            replace: true,
          });
          break;

        case "ok": {
          const tokenSub = getTokenSub(result.accessToken);
          track(AllAnalyticsEvents.FinishLoginOk, {
            id: result.id,
            state: result.state,
            tokenSub,
          });
          authenticationContext.setAuthenticationContext({
            accessToken: result.accessToken,
            idToken: result.idToken,
            expiresIn: result.expiresIn,
          });
          setToken(result);

          // Compute server-client time sync offset
          computeAndUpdateServerTime(result, result.requestInitiationTime);

          track(AllAnalyticsEvents.LoginSuccess);
          restoreOriginalLocation(result?.state?.originalLocation);
          break;
        }

        case "redirect": {
          trackPerformance(
            "auth.authentication_controller.redirecting_for_authentication",
          );
          track(AllAnalyticsEvents.FinishLoginRedirect, {
            id: result.id,
            redirectUri: result.redirectUri,
            state: result.state,
          });
          setTimeout(
            () => window.location.replace(result.redirectUri),
            // Without this timeout, on logout (and sometimes login as well),
            // we'll get a huge amount of warnings spammed in the console with
            // messages like these in dev, causing devtools to freeze for up to
            // minutes:
            //
            // DevTools failed to load SourceMap: Could not load content for
            // data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndlYnBhY2s6Ly8vLi9zcmMvZG9tYWlucy9DYXJkL2ZlYXR1cmVzL01hbmFnZUNhcmRzL2ZlYXR1cmVzL1JlcXVlc3RVc2VyTGltaXRJbmNyZWFzZS9lbnVtcy50cz82ODZjIl0sIm5hbWVzIjpbIlVzZXJMaW1pdEVuZERhdGUiLCJGb3JtVmFsdWVzIl0sIm1hcHBpbmdzIjoiOzs7Ozs7Ozs7O0FBQU8sSUFBS0EsZ0JBQVo7O1dBQVlBLGdCO0FBQUFBLGtCO0FBQUFBLGtCO0FBQUFBLGtCO0FBQUFBLGtCO0FBQUFBLGtCO0dBQUFBLGdCLEtBQUFBLGdCOztBQVFMLElBQUtDLFVBQVo7O1dBQVlBLFU7QUFBQUEsWTtBQUFBQSxZO0dBQUFBLFUsS0FBQUEsVSIsImZpbGUiOiIuL3NyYy9kb21haW5zL0NhcmQvZmVhdHVyZXMvTWFuYWdlQ2FyZHMvZmVhdHVyZXMvUmVxdWVzdFVzZXJMaW1pdEluY3JlYXNlL2VudW1zLnRzLmpzIiwic291cmNlc0NvbnRlbnQiOlsiZXhwb3J0IGVudW0gVXNlckxpbWl0RW5kRGF0ZSB7XG4gIE1PTlRIID0gXCJNT05USFwiLFxuICBORVhUX01PTlRIID0gXCJORVhUX01PTlRIXCIsXG4gIFFVQVJURVIgPSBcIlFVQVJURVJcIixcbiAgWUVBUiA9IFwiWUVBUlwiLFxuICBTRUxFQ1RFRF9NT05USF9ZRUFSID0gXCJTRUxFQ1RFRF9NT05USF9ZRUFSXCIsXG59XG5cbmV4cG9ydCBlbnVtIEZvcm1WYWx1ZXMge1xuICBBTU9VTlQgPSBcIkFNT1VOVFwiLFxuICBSRUFTT04gPSBcIlJFQVNPTlwiLFxufVxuIl0sInNvdXJjZVJvb3QiOiIifQ==:
            // Load canceled due to reload of inspected page
            //
            // The timeout is a workaround for this to allow time for sourcemaps
            // to fully load before redirecting away. 10s extra waiting time on
            // login/logout feels like a worthwhile tradeoff vs having devtools
            // freeze on us for minutes.
            //
            // Longer term solution I'd like to pursue is getting rid of the login
            // redirect altogether, which also has significant customer facing
            // perf benefits.
            window.location.origin === "http://localhost:3000" ? 10000 : 0,
          );
          break;
        }

        case "error": {
          // if it is invalid state, try auto-reload the page to correctly set state and exchange token
          if (
            result.type == "invalid_state" &&
            window.location.href.endsWith("login/finish")
          ) {
            navigate(urls.root(), { replace: true });
          }

          // throw known auth errors here so it may be caught downstream,
          // processed accordingly, and logged by sentry/logrocket

          throw new Error(`${result.type} error: ${result.error}`);
        }
        // no default
      }

      return result.result;
    },
    [
      authenticationContext,
      computeAndUpdateServerTime,
      navigate,
      restoreOriginalLocation,
      track,
    ],
  );
};
