// json-bigint-patch is a patch for JSON.parse and JSON.stringify
// to allow processing of BigInt values
import "json-bigint-patch";
// Array.prototype.at polyfill added to support <= Safari 15.3
import "core-js/features/array/at";
// Browsers added support for this very recently and it's used by pdfjs
import "core-js/features/promise/with-resolvers";
import { datadogRum } from "@datadog/browser-rum";
import React from "react";
import ReactDOM from "react-dom";
import AppWithAnalytics from "@/domains/App/components/AppWithAnalytics";

// Namespace for all global variables we create ourselves
// @see `custom.d.ts` for more details
window.brexGlobal = {};

datadogRum.addAction("APP.skeleton.started");

if (process.env.APP_ENV !== "prod") {
  const { bootstrapAuth } = require("@/features/Authentication/helpers/oauth");
  bootstrapAuth();
}

ReactDOM.render(
  <React.StrictMode>
    <AppWithAnalytics />
  </React.StrictMode>,
  document.getElementById("root"),
);
