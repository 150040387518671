import { resourceOperationsQueryAtoms } from "@/domains/App/features/Permissions/atoms/resourceOperationsQueryAtoms";
import { resourceOperationsQuery } from "@/domains/App/features/Permissions/data/queries";
import { selectQueryAtom, useAwaitQueryWithAtoms } from "@/features/Jotai";

export const useResourceOperationsQueryWithAtom = () =>
  useAwaitQueryWithAtoms(resourceOperationsQuery, {
    atomsWithQuery: resourceOperationsQueryAtoms,
    fetchPolicy: "network-only",
  });

export const refetchResourceOperationsQueryAtom = selectQueryAtom(
  resourceOperationsQueryAtoms,
  ({ refetch }) => refetch,
);
