type ErrorType =
  | "no_data"
  | "no_error"
  | "no_data_no_error"
  | "invalid_status"
  | "different_account";

export enum ArfiAnalyticsEvents {
  ArfiMounted = "arfi.mounted",
  ArfiUnmounted = "arfi.unmounted",

  /** Request for information step */
  ArfiRequestForInformationStepView = "arfi.request_for_information_step.view",
  ArfiRequestForInformationStepNoInformationCheckboxToggled = "arfi.request_for_information_step.no_information_checkbox.toggled",
  ArfiRequestForInformationStepNoInformationInputBlur = "arfi.request_for_information_step.no_information_input.blur",
  ArfiRequestForInformationStepGetStartedButtonClicked = "arfi.request_for_information_step.get_started_button.clicked",
  ArfiRequestForInformationStepTakeOverCloseButtonClicked = "arfi.request_for_information_step.takeover_close_button.clicked",
  ArfiRequestForInformationStepUnmounted = "arfi_request_for_information_step.unmounted",

  /** Add information step */
  ArfiAddInformationStepView = "arfi.add_information_step.view",
  ArfiAddInformationStepPartialInformationCheckboxToggled = "arfi.add_information_step.partial_information_checkbox.toggled",
  ArfiAddInformationStepRequestInputBlur = "arfi.add_information_step.response_input.blur",
  ArfiAddInformationStepUploadClicked = "arfi.add_information_step.upload.clicked",
  ArfiAddInformationStepUploadStarted = "arfi.add_information_step.upload.started",
  ArfiAddInformationStepUploadCompleted = "arfi.add_information_step.upload.completed",
  ArfiAddInformationStepUploadFailed = "arfi.add_information_step.upload.failed",
  ArfiAddInformationStepAssetDeleted = "arfi.add_information_step.asset.deleted",
  ArfiAddInformationStepBackButtonClicked = "arfi.add_information_step.back_button.clicked",
  ArfiAddInformationStepContinueButtonClicked = "arfi.add_information_step.continue_button.clicked",
  ArfiAddInformationStepTakeOverCloseButtonClicked = "arfi.add_information_step.takeover_close_button.clicked",
  ArfiAddInformationStepUnmounted = "arfi.add_information_step.unmounted",

  /** Review step */
  ArfiReviewStepView = "arfi.review_step.view",
  ArfiReviewStepEditButtonClicked = "arfi.review_step.edit_button.clicked",
  ArfiReviewStepBackButtonClicked = "arfi.review_step.back_button.clicked",
  ArfiReviewStepSubmitButtonClicked = "arfi.review_step.submit_button.clicked",
  ArfiReviewStepContactUsButtonClicked = "arfi.review_step.contact_us_button.clicked",
  ArfiReviewStepSubmitSuccess = "arfi.review_step.submit.success",
  ArfiReviewStepSubmitFailed = "arfi.review_step.submit.failed",
  ArfiReviewStepTakeOverCloseButtonClicked = "arfi.review_step.takeover_close_button.clicked",
  ArfiReviewStepUnmounted = "arfi.review_step.unmounted",

  /** Thank you step */
  ArfiThankYouStepView = "arfi.thank_you_step.view",
  ArfiThankYouStepCompleteButtonClicked = "arfi.thank_you_step.complete_button.clicked",

  /** completed state */
  ArfiCompletedView = "arfi.completed.view",
  ArfiCompletedContactUsButtonClicked = "arfi.completed.contact_us_button.clicked",
  ArfiCompletedCloseButtonClicked = "arfi.completed.close_button.clicked",

  /** Error states/views */
  ArfiLinkExpiredView = "arfi.link_expired.view",
  ArfiLinkExpiredContactUsButtonClicked = "arfi.link_expired.contact_us_button.clicked",

  ArfiGenericErrorView = "arfi.generic_error.view",
  ArfiGenericErrorContactUsButtonClicked = "arfi.generic_error.contact_us_button.clicked",

  ArfiAccessDeniedView = "arfi.access_denied.view",
  ArfiAccessDeniedContactUsButtonClicked = "arfi.access_denied.contact_us_button.clicked",
}

type EmptyPayload = {};

type RfiInfoPayload = {
  id: string;
  arfiCaseId?: string;
  arfiCaseNumber?: string;
  status?: string;
};

type ErrorPayload = {
  error: ErrorType;
  status?: string;
  id?: string;
  arfiCaseId?: string;
  arfiCaseNumber?: string;
};

export type ArfiEventSchemaMap = {
  /** TakeOver mounted / unmounted */
  [ArfiAnalyticsEvents.ArfiMounted]: EmptyPayload;
  [ArfiAnalyticsEvents.ArfiUnmounted]: EmptyPayload;

  /** Arfi steps */
  /** Request for information step */
  [ArfiAnalyticsEvents.ArfiRequestForInformationStepView]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiRequestForInformationStepUnmounted]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepPartialInformationCheckboxToggled]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepRequestInputBlur]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiRequestForInformationStepGetStartedButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiRequestForInformationStepTakeOverCloseButtonClicked]: RfiInfoPayload;

  /** Add information step */
  [ArfiAnalyticsEvents.ArfiAddInformationStepView]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepUnmounted]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepPartialInformationCheckboxToggled]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepRequestInputBlur]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepUploadClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepUploadStarted]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepUploadCompleted]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepAssetDeleted]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepUploadFailed]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepContinueButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepTakeOverCloseButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiAddInformationStepBackButtonClicked]: RfiInfoPayload;

  /** Review step */
  [ArfiAnalyticsEvents.ArfiReviewStepView]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiReviewStepUnmounted]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiReviewStepEditButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiReviewStepContactUsButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiReviewStepBackButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiReviewStepSubmitButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiReviewStepTakeOverCloseButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiReviewStepSubmitSuccess]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiReviewStepSubmitFailed]: RfiInfoPayload;

  /** Thank you step */
  [ArfiAnalyticsEvents.ArfiThankYouStepView]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiThankYouStepCompleteButtonClicked]: RfiInfoPayload;

  /** Completed state */
  [ArfiAnalyticsEvents.ArfiCompletedView]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiCompletedContactUsButtonClicked]: RfiInfoPayload;
  [ArfiAnalyticsEvents.ArfiCompletedCloseButtonClicked]: RfiInfoPayload;

  /** Error states/views */
  [ArfiAnalyticsEvents.ArfiLinkExpiredView]: ErrorPayload;
  [ArfiAnalyticsEvents.ArfiLinkExpiredContactUsButtonClicked]: RfiInfoPayload;

  [ArfiAnalyticsEvents.ArfiAccessDeniedView]: ErrorPayload;
  [ArfiAnalyticsEvents.ArfiGenericErrorContactUsButtonClicked]: RfiInfoPayload;

  [ArfiAnalyticsEvents.ArfiGenericErrorView]: ErrorPayload;
  [ArfiAnalyticsEvents.ArfiAccessDeniedContactUsButtonClicked]: RfiInfoPayload;
};
