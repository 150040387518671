import * as React from "react";
import { AdaContext } from "@/features/Ada/contexts/AdaContext";
import { HelpshiftContext } from "@/features/Helpshift/contexts/HelpshiftContext";

// If the Ada FF is enabled, it overrides using Helpshift. This is a cleaner
// solution than updating every use of useHelpshift across the app.
export const useHelpshift = () => {
  const { initializeProvider: initializeHelpshift } =
    React.useContext(HelpshiftContext);
  const { initializeProvider: initializeAda, ...adaRest } =
    React.useContext(AdaContext);
  React.useEffect(() => {
    // whenever the "useHelpshift" hook is called we start the initalization of
    // the provider. We are using the default values for the provider while it's
    // not loaded. so this will dynamically import the real helpshift provider,
    // load it into memory, and replace the old provider values once it's ready
    initializeAda();
    // eslint-disabgle-next-line react-hooks/exhaustive-deps
  }, [initializeAda, initializeHelpshift]);
  return adaRest;
};
