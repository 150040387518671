/* eslint-disable @typescript-eslint/no-restricted-imports */
import { ApolloClient } from "@apollo/client";

// apollo client type exports

export type {
  ApolloQueryResult,
  BackgroundQueryHookOptions,
  BaseMutationOptions,
  DocumentNode,
  ErrorPolicy,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  FetchPolicy,
  FetchResult,
  InternalRefetchQueriesInclude,
  LazyQueryExecFunction,
  LazyQueryHookOptions,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  MutationTuple,
  NextLink,
  NoInfer,
  ObservableQuery,
  ObservableQueryFields,
  Operation,
  OperationVariables,
  PureQueryOptions,
  QueryFunctionOptions,
  QueryHookOptions,
  QueryLazyOptions,
  QueryOptions,
  QueryResult,
  RefetchQueriesFunction,
  RequestHandler,
  ServerError,
  ServerParseError,
  SkipToken,
  SuspenseQueryHookOptions,
  UriFunction,
  UseBackgroundQueryResult,
  UseSuspenseQueryResult,
  WatchQueryFetchPolicy,
} from "@apollo/client";

export type { ErrorResponse } from "@apollo/client/link/error";

export type { RetryFunction } from "@apollo/client/link/retry/retryFunction";

export type { MockedProviderProps } from "@apollo/client/testing";

export type { ExecutionResult } from "graphql";

// apollo client value exports

export {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NetworkStatus,
  Observable,
  useApolloClient,
  useBackgroundQuery,
  useLazyQuery,
  useMutation,
  useQuery,
  useReadQuery,
  useSuspenseQuery,
  skipToken,
} from "@apollo/client";

export { setContext } from "@apollo/client/link/context";

export { onError } from "@apollo/client/link/error";

export { createHttpLink } from "@apollo/client/link/http";

export { RetryLink } from "@apollo/client/link/retry";

export { SchemaLink } from "@apollo/client/link/schema";

export { Query, Mutation } from "@apollo/client/react/components";

export { MockedProvider } from "@apollo/client/testing";

export { getOperationName } from "@apollo/client/utilities";

export enum FetchPolicyValue {
  CacheFirst = "cache-first",
  NetworkOnly = "network-only",
  CacheOnly = "cache-only",
  NoCache = "no-cache",
  StandBy = "standby",
}

export default ApolloClient;
