import * as React from "react";

export enum LazyComponentPrefetchType {
  Hook = "hook",
  Loader = "loader",
}

type LazyComponentContextType = {
  lazy: boolean;
  prefetch: LazyComponentPrefetchType | null;
  prefetchError: string | null;
};

export const LazyComponentContext =
  React.createContext<LazyComponentContextType>({
    lazy: false,
    prefetch: null,
    prefetchError: null,
  });
