import * as React from "react";
import { AnalyticsEvents } from "@/domains/App";
import {
  useResetAnalytics,
  useTrackEvent,
} from "@/features/Analytics/contexts/Analytics";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import { useApolloClient } from "@/features/Apollo";
import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { endOpenIdSession } from "@/features/Authentication/helpers/openIdSession";
import { revokeRefreshToken } from "@/features/Authentication/helpers/tokenManagerApi";
import { deleteToken } from "@/features/Authentication/helpers/tokenStorage";
import { forceCrossTabLogout } from "@/features/Authentication/hooks/useLeadershipElection";
import { removeSupportSessionCookie } from "@/features/Authentication/hooks/useSupportSessionCookie";
import type { EndSessionOptions } from "@/features/Authentication/sharedTypes";
import { travelSupportClient } from "@/features/TravelSupport/TravelSupportController";
import { promiseWithTimeout } from "@/helpers/async";
import { urls } from "@/helpers/urls";
import { useNavigate } from "@/hooks/useNavigate";

export const useLogout = () => {
  const reset = useResetAnalytics();
  const navigate = useNavigate();
  const client = useApolloClient();
  const track = useTrackEvent();
  const { resetAuthenticationContext, getIdToken } = useAuthenticationContext();

  const executeBrexAuthLogout = React.useCallback(
    async ({ postLogoutRedirectUri, state }: EndSessionOptions = {}) => {
      await revokeRefreshToken();
      try {
        // Wait up to 10 seconds for the Travel support user to be cleared before proceeding with logout.
        await promiseWithTimeout(travelSupportClient.clear(), 10_000);
      } catch {
        track(AnalyticsEvents.TravelSupportChatLogoutError);
      }

      forceCrossTabLogout();
      resetAuthenticationContext();
      removeSupportSessionCookie();
      return endOpenIdSession({
        idToken: getIdToken(),
        postLogoutRedirectUri,
        state,
      });
    },
    [getIdToken, resetAuthenticationContext, track],
  );

  return React.useCallback(
    async (
      options?: EndSessionOptions,
      onBrexLogoutSuccess?: () => Promise<void>,
    ) => {
      track(AllAnalyticsEvents.Logout);
      reset();
      deleteToken();
      await client.clearStore();
      window.adaEmbed?.reset();
      const result = await executeBrexAuthLogout({
        ...options,
      });

      await onBrexLogoutSuccess?.();

      switch (result.result) {
        case "ok":
          navigate(urls.root());
          break;
        case "redirect":
          window.location.replace(result.redirectUri);
          break;
        case "error":
          // Logout failed, send to target URL anyway
          window.location.replace(urls.root());
        // no default
      }
    },
    [client, executeBrexAuthLogout, navigate, reset, track],
  );
};
