import gql from "graphql-tag";

const internationalWireConfigInputFieldFragment = gql`
  fragment InternationalWireConfig_InputFieldFragment on InternationalWireInputField {
    displayName
    format
    forRecipientTypes
    maxLength
    minLength
    name
    tooltip
    type
    optional
    requiredPerTransaction
    dropdownOptions {
      displayName
      value
      displaySubtext
    }
  }
`;

export const internationalWireConfigFragment = gql`
  fragment InternationalWireConfigFragment on InternationalWireConfig {
    countryConfigs {
      addressRequiresZipCode
      countryCodeAlpha2
      countryName
      iban
      inputFields {
        ...InternationalWireConfig_InputFieldFragment
      }
      isHighRisk
      recipientTypes
    }
    highRiskInputFields {
      ...InternationalWireConfig_InputFieldFragment
    }
  }

  ${internationalWireConfigInputFieldFragment}
`;
