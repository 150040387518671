import { useColorMode } from "@brexhq/metal-theming";
import * as React from "react";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { useTravelResourceOperations } from "@/domains/App/features/Permissions/hooks/useTravelResourceOperations";
import { useGenerateSpotnanaOauthToken } from "@/domains/Travel/hooks/useGenerateSpotnanaOauthToken";
import { setChatBubble } from "@/features/ChatBubble/hooks/useChatBubble";
import { TwilioChatEvent } from "@/features/TravelSupport/sharedTypes";
import { getEnvironment } from "@/helpers";

const SPOTNANA_TWILIO_FLEX_SCRIPT_SRC =
  "https://app.spotnana.com/static/js/twilio-chat-app/twilio_chat_app_bundle.js";

const SPOTNANA_TWILIO_FLEX_STYLESHEET_SRC =
  "https://app.spotnana.com/static/js/twilio-chat-app/index-twilio-chat-app.css";

let isSpotnanaTwilioChatInjected = false;

/**
 * Injects the Spotnana Twilio Flex script and stylesheet
 * elements into the DOM. Should only be called once.
 */
const loadSpotnanaTwilioChat = () =>
  new Promise<boolean>((resolve, reject) => {
    // Skip injecting DOM elements if already injected
    if (isSpotnanaTwilioChatInjected) {
      return resolve(true);
    }

    try {
      const script = document.createElement("script");
      script.src = SPOTNANA_TWILIO_FLEX_SCRIPT_SRC;
      script.async = true;
      script.onload = () => {
        resolve(true);
      };

      const stylesheet = document.createElement("link");
      stylesheet.rel = "stylesheet";
      stylesheet.href = SPOTNANA_TWILIO_FLEX_STYLESHEET_SRC;

      // Inject the Spotnana Twilio Flex stylesheet and script tag into the DOM
      document.head.appendChild(script);
      document.head.appendChild(stylesheet);
      isSpotnanaTwilioChatInjected = true;
    } catch (error) {
      reject(error);
    }
  });

/**
 * @private - Do not use directly. Access via `travelSupportClient` exported by `TravelSupportController`
 */
export const useSpotnanaTwilioChat = () => {
  const [generateSpotnanaOauthToken] = useGenerateSpotnanaOauthToken({});
  const { canManageTrips } = useTravelResourceOperations();
  const [isInitialized, setIsInitialized] = React.useState(false);
  const colorMode = useColorMode();
  const isDarkModeEnabled = colorMode === "dark";
  const unsubscribeRefs = React.useRef<(() => void)[]>([]);
  const trackEvent = useTrackEvent();

  const handleShowChatBubble = React.useCallback((unreadCount?: number) => {
    setChatBubble({
      isVisible: true,
      ...(unreadCount !== undefined ? { unreadCount } : {}),
    });
  }, []);

  const handleUnreadMessageEvent = React.useCallback(
    (ev: CustomEvent<number>) => {
      handleShowChatBubble(ev.detail);
    },
    [handleShowChatBubble],
  );

  const handleMinimizeEvent = React.useCallback(() => {
    trackEvent(AnalyticsEvents.TravelSupportChatMinimized, {
      provider: "twilio",
    });
    handleShowChatBubble();
  }, [handleShowChatBubble, trackEvent]);

  // On mount, initialize the Spotnana Twilio Flex widget
  const handleInitialize = React.useCallback(async () => {
    const [isScriptLoaded, { data }] = await Promise.all([
      loadSpotnanaTwilioChat(),
      generateSpotnanaOauthToken(),
    ]);

    const token = data?.generateSpotnanaOauthToken?.accessToken;
    if (isScriptLoaded && token && window.Twilio) {
      // Initialize the Spotnana Twilio Flex widget
      await window.Twilio.initWebchat({
        token,
        webUrl: getEnvironment("SPOTNANA_TWILIO_CHAT_WEB_URL"),
        serverUrl: getEnvironment("SPOTNANA_TWILIO_CHAT_SERVER_URL"),
        shouldShowMinimizeBtn: true,
        shouldHideOtherTravelerReservation: canManageTrips,
        theme: {
          isLight: !isDarkModeEnabled,
        },
      });

      // Subscribe to Spotnana Twilio Flex events
      const unsubscribeMinimize = window.Twilio.on(
        TwilioChatEvent.Minimize,
        handleMinimizeEvent,
      );

      const unsubscribeUnreadUpdate = window.Twilio.on(
        TwilioChatEvent.UnReadMsgUpdate,
        handleUnreadMessageEvent,
      );

      unsubscribeRefs.current.push(
        unsubscribeMinimize,
        unsubscribeUnreadUpdate,
      );

      setIsInitialized(true);
    } else {
      console.error(
        "[Spotnana Twilio Flex] failed to resolve dependencies to initWebchat:",
        {
          isScriptMounted: isScriptLoaded,
          token: !!token,
          twilio: !!window.Twilio,
        },
      );
    }
  }, [
    canManageTrips,
    generateSpotnanaOauthToken,
    handleMinimizeEvent,
    handleUnreadMessageEvent,
    isDarkModeEnabled,
  ]);

  const handleOpenChat = React.useCallback(() => {
    window.Twilio.show();
  }, []);

  const handleCloseChat = React.useCallback(() => {
    window.Twilio.hide();
  }, []);

  const handleClear = React.useCallback(() => {
    setIsInitialized(false);
    unsubscribeRefs.current.forEach((unsubscribe) => unsubscribe());
    window.Twilio.clear();
    window.Twilio.hide();
  }, []);

  return {
    isInitialized,
    handleInitialize,
    handleOpenChat,
    handleCloseChat,
    handleClear,
  };
};
