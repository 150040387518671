import * as React from "react";
import { useLocation } from "react-router-dom";
import type { JsonObject } from "type-fest";
// We are disabling this to only import it from TrackedErrorBoundary in order to test.
// Everyone else should import TrackedErrorBoundary directly
// eslint-disable-next-line import/no-restricted-paths
import { ErrorBoundary } from "./ErrorBoundary";
import type {
  ErrorBoundaryErrorRendererArgs,
  ErrorBoundaryProps,
} from "./sharedTypes";
import type { DefaultErrorProps } from "@/components/Error/DefaultError";
import { FullScreenErrorPage } from "@/components/FullScreenErrorPage";
import { useSetSkeletonError } from "@/domains/App/features/Skeleton";
import { useTrackRenderingError } from "@/helpers/errorTracking";

type ErrorPageWithResetProps = Pick<
  ErrorBoundaryErrorRendererArgs,
  "resetError"
> &
  DefaultErrorProps;

export const ErrorPageWithReset: React.VFC<ErrorPageWithResetProps> = ({
  resetError,
  ...errorProps
}) => {
  const location = useLocation();
  const mountedRef = React.useRef(false);

  // When location changes, we should reset the previous page error so we can
  // attempt to load the new page.
  React.useEffect(() => {
    // don't reset error on initial mount, otherwise we'll go into an infinite
    // loop of clearing and throwing
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }
    resetError();
  }, [resetError, location]);

  return <FullScreenErrorPage {...errorProps} onClickRetry={resetError} />;
};

export type TrackedErrorBoundaryProps = Pick<
  ErrorBoundaryProps,
  "onError" | "renderError" | "__DROP_TRACK_ORIGIN"
> & {
  extras?: JsonObject;
};

const DEFAULT_EXTRAS = {};

export const TrackedErrorBoundary: React.FC<TrackedErrorBoundaryProps> = ({
  children,
  onError,
  renderError,
  __DROP_TRACK_ORIGIN = "TrackedErrorBoundary",
  extras = DEFAULT_EXTRAS,
}) => {
  const trackRenderingError = useTrackRenderingError();
  const setError = useSetSkeletonError();
  const onErrorCallback = React.useCallback<
    Required<ErrorBoundaryProps>["onError"]
  >(
    (error, info) => {
      trackRenderingError(error, info, {
        ...extras,
        trackOrigin: __DROP_TRACK_ORIGIN,
      });
      onError?.(error, info);
      setError({ error, info, trackOrigin: __DROP_TRACK_ORIGIN, ...extras });
    },
    [extras, onError, __DROP_TRACK_ORIGIN, trackRenderingError, setError],
  );
  return (
    <ErrorBoundary
      onError={onErrorCallback}
      renderError={renderError}
      __DROP_TRACK_ORIGIN={__DROP_TRACK_ORIGIN}
    >
      {children}
    </ErrorBoundary>
  );
};
