import type { IconProps } from "@brexhq/component-library";
import { StatusType } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import type { IconName } from "@brexhq/metal-icon";
import { Icon } from "@brexhq/metal-icon";
import * as React from "react";
import {
  ExpensePaymentStatusType,
  ExpenseStatus as ExpenseStatusType,
  ExpenseType,
} from "@/__generated__/globalTypes";
import { getIsReimbursementDelayed } from "@/domains/Primitives/shared/helpers/getIsReimbursementDelayed";
import { ExpenseApprovalStatusLabels } from "@/domains/Primitives/shared/sharedTypes";
import type { AsRelayEnum } from "@/helpers/typeHelpers";

const selectCardStatusIcon = (
  expenseStatus: AsRelayEnum<ExpenseStatusType>,
): IconName | undefined => {
  switch (expenseStatus) {
    case ExpenseStatusType.APPROVED:
    case ExpenseStatusType.SETTLED:
      return "cardexpenseApproved";
    case ExpenseStatusType.SUBMITTED:
      return "cardexpenseSubmitted";
    case ExpenseStatusType.OUT_OF_POLICY:
      return "cardexpenseDenied";
    default:
      return undefined;
  }
};

const selectReimbursementStatusIcon = (
  expenseStatus: AsRelayEnum<ExpenseStatusType>,
): IconName | undefined => {
  switch (expenseStatus) {
    case ExpenseStatusType.CANCELED:
      return "reimbursementCanceled";
    case ExpenseStatusType.DRAFT:
      return undefined;
    default:
      return "reimbursements";
  }
};

const selectBillStatusIcon = (
  expenseStatus: AsRelayEnum<ExpenseStatusType>,
): IconName | undefined => {
  switch (expenseStatus) {
    case ExpenseStatusType.APPROVED:
    case ExpenseStatusType.SETTLED:
      return "billPaid";
    case ExpenseStatusType.SUBMITTED:
      return "billSubmitted";
    case ExpenseStatusType.OUT_OF_POLICY:
      return "billDenied";
    default:
      return undefined;
  }
};

const getStatusIcon = ({
  type,
  expenseStatus,
}: {
  type: AsRelayEnum<ExpenseType>;
  expenseStatus: AsRelayEnum<ExpenseStatusType>;
}) => {
  let iconName: IconName | undefined = undefined;
  if (type === ExpenseType.REIMBURSEMENT) {
    iconName = selectReimbursementStatusIcon(expenseStatus);
  } else if (type === ExpenseType.CARD) {
    iconName = selectCardStatusIcon(expenseStatus);
  } else if (type === ExpenseType.CLAWBACK) {
    iconName = "reimbursements";
  } else if (type === ExpenseType.BILLPAY) {
    iconName = selectBillStatusIcon(expenseStatus);
  }

  return iconName ? <Icon size={12} name={iconName} /> : undefined;
};

export type UseSelectExpenseStatusProps = {
  completionExpectedAt: GraphqlTime | null;
  expenseStatus: AsRelayEnum<ExpenseStatusType>;
  canSubmitRepayment: boolean;
  isExpenseImprovementsForReimbursementsEnabled?: boolean;
  type: AsRelayEnum<ExpenseType>;
  expensePaymentStatus?: AsRelayEnum<ExpensePaymentStatusType> | null;
  isRepaymentMoneyMovementEnabled?: boolean;
};

type ExpenseStatusData = {
  status: StatusType;
  label: string;
  icon?: React.FC<IconProps>;
  tooltipText?: string;
};

export const useSelectExpenseStatus = () => {
  const { t } = useTranslation();

  return React.useCallback(
    ({
      completionExpectedAt,
      expenseStatus,
      type,
      expensePaymentStatus,
      isExpenseImprovementsForReimbursementsEnabled,
      isRepaymentMoneyMovementEnabled,
    }: UseSelectExpenseStatusProps): ExpenseStatusData => {
      // Special case for declined card - we want these to be more apparent to users
      if (
        type === ExpenseType.CARD &&
        expensePaymentStatus === ExpensePaymentStatusType.DECLINED
      ) {
        return {
          label: t("expense-status-label-declined"),
          status: StatusType.DANGER,
          icon: () => <Icon size={12} name="cardexpenseDenied" />,
        };
      }

      if (
        type === ExpenseType.REIMBURSEMENT &&
        (expenseStatus === ExpenseStatusType.APPROVED ||
          expenseStatus === ExpenseStatusType.SETTLED)
      ) {
        if (expensePaymentStatus === ExpensePaymentStatusType.DECLINED) {
          if (isExpenseImprovementsForReimbursementsEnabled) {
            return {
              tooltipText: t("expense-status-tooltip-check-tasks"),
              label: t("expense-status-label-failed"),
              status: StatusType.DANGER,
              icon: () => <Icon size={12} name="reimbursementDenied" />,
            };
          }

          return {
            label: t("expense-status-label-failed"),
            status: StatusType.DANGER,
            icon: () => <Icon size={12} name="reimbursementFailed" />,
          };
        } else if (expensePaymentStatus === ExpensePaymentStatusType.CLEARED) {
          return {
            label: t("expense-status-label-paid"),
            status: StatusType.SUCCESS,
            icon: () => <Icon size={12} name="reimbursementPaid" />,
          };
        } else if (
          // if is false, then will render the current approved status from ExpenseStatus
          isExpenseImprovementsForReimbursementsEnabled &&
          expensePaymentStatus === ExpensePaymentStatusType.PROCESSING
        ) {
          if (completionExpectedAt) {
            const isReimbursementDelayed = getIsReimbursementDelayed({
              completionExpectedAt,
            });

            if (isReimbursementDelayed) {
              return {
                tooltipText: t("expense-status-tooltip-reimbursement-delays"),
                label: t("expense-status-label-delayed"),
                status: StatusType.WARNING,
                icon: () => <Icon size={12} name="reimbursementScheduled" />,
              };
            }

            return {
              tooltipText: t("expense-status-tooltip-eta"),
              label: t("expense-status-label-eta", {
                eta: new Date(completionExpectedAt),
              }),
              status: StatusType.SUCCESS,
              icon: () => <Icon size={12} name="reimbursementScheduled" />,
            };
          }
        }
      }

      const iconName = getStatusIcon({
        type,
        expenseStatus,
      });

      const icon = iconName ? () => iconName : undefined;

      if (type === ExpenseType.CLAWBACK) {
        switch (expensePaymentStatus) {
          case ExpensePaymentStatusType.AWAITING_PAYMENT:
            return {
              label: ExpenseApprovalStatusLabels.SUBMITTED_REPAYMENT,
              status: StatusType.ACCEPTED,
              icon,
            };
          case ExpensePaymentStatusType.PROCESSING:
            return {
              label: ExpenseApprovalStatusLabels.PROCESSING_REPAYMENT,
              status: StatusType.ACCEPTED,
              icon,
            };
          case ExpensePaymentStatusType.CLEARED:
            return {
              label: ExpenseApprovalStatusLabels.PAID,
              status: StatusType.SUCCESS,
              icon,
            };
          case ExpensePaymentStatusType.DECLINED:
            // The backend currently updates the paymentStatusType to Declined, even when money movement is disabled for a repayment
            // In this case we need to check if the expense status is Approved as that means the repayment has been marked as paid
            // However, this should be handled in the backend soon, so for DataGrid for now we will just show Failed
            return isRepaymentMoneyMovementEnabled === false &&
              expenseStatus === ExpenseStatusType.APPROVED
              ? {
                  label: ExpenseApprovalStatusLabels.PAID,
                  status: StatusType.SUCCESS,
                  icon,
                }
              : {
                  label: ExpenseApprovalStatusLabels.FAILED,
                  status: StatusType.DANGER,
                  icon,
                };
          default: {
            return {
              label: "",
              status: StatusType.NEUTRAL,
              icon,
            };
          }
        }
      }

      switch (expenseStatus) {
        case ExpenseStatusType.SETTLED:
        case ExpenseStatusType.APPROVED:
          return {
            label: ExpenseApprovalStatusLabels.APPROVED,
            status: StatusType.SUCCESS,
            icon,
          };
        case ExpenseStatusType.CANCELED:
          return {
            label: ExpenseApprovalStatusLabels.CANCELED,
            status: StatusType.INCOMPLETE,
            icon,
          };
        case ExpenseStatusType.OUT_OF_POLICY:
          return {
            label: ExpenseApprovalStatusLabels.OUT_OF_POLICY,
            status: StatusType.DANGER,
            icon,
          };
        case ExpenseStatusType.DRAFT:
          return {
            label: ExpenseApprovalStatusLabels.DRAFT,
            status: StatusType.INCOMPLETE,
            icon,
          };
        case ExpenseStatusType.SUBMITTED:
          return {
            tooltipText:
              isExpenseImprovementsForReimbursementsEnabled &&
              type === ExpenseType.REIMBURSEMENT
                ? t("expense-status-tooltip-pending-admin-approval")
                : "",
            label: ExpenseApprovalStatusLabels.SUBMITTED,
            status: StatusType.ACCEPTED,
            icon,
          };
      }

      return {
        label: "",
        status: StatusType.NEUTRAL,
        icon,
      };
    },
    [t],
  );
};
