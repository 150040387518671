import gql from "graphql-tag";

export const permissionsSummaryQuery = gql`
  query PermissionsSummaryQuery {
    permissionsSummary {
      operations {
        key
        resourceType
        action
      }
    }
  }
`;
