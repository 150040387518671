import gql from "graphql-tag";

export const fundingSourceFragment = gql`
  fragment FundingSourceFragment on FundingSource {
    id
    priority
    type
    data {
      accountName
      accountNumberLastFour
      routingNumber
      accountType
    }
    isBrexCash
    provenance
    provenanceId
  }
`;

export const paymentCounterpartyFieldsFragment = gql`
  fragment paymentCounterpartyFields on Counterparty {
    id
    accountNumber
    address {
      address1
      address2
      city
      country
      state
      zip
    }
    beneficiaryBankAddress {
      address1
      address2
      city
      country
      state
      zip
    }
    class
    location
    name
    routingNumber
    type
    beneficiaryBankInfo
    email
    chequeRecipientName
    achCompanyId
    positivePayStatus
    realAccountNumberEndingChars
  }
`;

export const payrollProviderFragment = gql`
  fragment PayrollProviderFragment on PayrollProviderV2 {
    type
    fundingSource {
      accountType
      accountId
    }
    integration {
      id
      type
      status
      gustoInfo {
        accountClaimUrl
      }
    }
    lastVerifiedAt
  }
`;

export const informationRequest = gql`
  fragment InformationRequest on OnboardingInformationRequest {
    id
    status
    data
    type
    description
    canResubmit
    application {
      id
      status
      blueprint {
        id
        name
        type
      }
      state {
        ... on OnboardingApplicationStateDisclosure {
          disclosureState
        }
      }
    }
    beforePages {
      __typename
      ... on OnboardingPagePhotoIdIntro {
        _
      }
    }
    content {
      __typename
      ... on OnboardingFormEmpty {
        _
      }
      ... on OnboardingFormJsonSchema {
        jsonSchema
        uiSchema
        extraData
      }
      ... on OnboardingFormPersona {
        templateId
        individual
        riskLevel
      }
      ... on OnboardingFormPeriodicReview {
        business
        individuals
      }
    }
    contentPage {
      __typename
      ... on OnboardingPageForm {
        _
      }
      ... on OnboardingPageDSuitability {
        _
      }
      ... on OnboardingPageMfa {
        _
      }
      ... on OnboardingPageDTerms {
        _
      }
      ... on OnboardingPageTerms {
        cash
        card
      }
      ... on OnboardingPageUwBankConnection {
        canSkipStatements
        shouldPromptStatements
        financialProductType
        requestReason
        amountNeededConnectedBank
        amountNeededStatements
      }
      ... on OnboardingPageUwQuarterlyBalanceSheets {
        assets {
          fileName
        }
      }
      ... on OnboardingPageUwQuarterlyIncomeStatements {
        assets {
          fileName
        }
      }
      ... on OnboardingPageUwQuarterlyStatementsOfCashFlow {
        assets {
          fileName
        }
      }
    }
  }
`;

export const informationRequestV2 = gql`
  fragment InformationRequestV2 on OnboardingInformationRequest {
    id
    status
    data
    type
    description
    canResubmit
    application {
      id
      status
      blueprint {
        id
        name
        type
      }
      state {
        ... on OnboardingApplicationStateDisclosure {
          disclosureState
        }
      }
    }
    beforePages {
      __typename
      ... on OnboardingPagePhotoIdIntro {
        _
      }
    }
    content {
      __typename
      ... on OnboardingFormEmpty {
        _
      }
      ... on OnboardingFormJsonSchema {
        jsonSchema
        uiSchema
        extraData
      }
      ... on OnboardingFormPersona {
        templateId
        individual
        riskLevel
      }
      ... on OnboardingFormPeriodicReview {
        business
        individuals
      }
    }
    contentPage {
      __typename
      ... on OnboardingPageForm {
        _
      }
      ... on OnboardingPageDSuitability {
        _
      }
      ... on OnboardingPageMfa {
        _
      }
      ... on OnboardingPageDTerms {
        _
      }
      ... on OnboardingPageTerms {
        cash
        card
      }
      ... on OnboardingPageUwBankConnection {
        canSkipStatements
        shouldPromptStatements
        financialProductType
        requestReason
        amountNeededConnectedBank
        amountNeededStatements
      }
      ... on OnboardingPageUwQuarterlyBalanceSheets {
        assets {
          fileName
        }
      }
      ... on OnboardingPageUwQuarterlyIncomeStatements {
        assets {
          fileName
        }
      }
      ... on OnboardingPageUwQuarterlyStatementsOfCashFlow {
        assets {
          fileName
        }
      }
    }
  }
`;

export const onboardingApplicationState = gql`
  fragment OnboardingApplicationStateFragment on OnboardingApplication {
    id
    state {
      ... on OnboardingApplicationStateProductV1 {
        card
        cash
      }
      ... on OnboardingApplicationStateRiskEvaluation {
        compliance
        fraud
        offboardDueDate
        suspendDueDate
      }
      ... on OnboardingApplicationStateEmpty {
        _
      }
    }
  }
`;

export const onboardingApplication = gql`
  fragment OnboardingApplication on OnboardingApplication {
    id
    isSubmitted
    submittedAt
    blueprint {
      id
      name
      type
    }
    createdByUser {
      id
      role
      depositsRole
      firstName
      lastName
    }
    status
    ...OnboardingApplicationStateFragment
    informationRequests(first: 50) {
      edges {
        node {
          id
          ...InformationRequest
        }
      }
    }
  }
  ${informationRequest}
  ${onboardingApplicationState}
`;

export const onboardingApplicationV2 = gql`
  fragment OnboardingApplicationV2 on OnboardingApplication {
    id
    isSubmitted
    submittedAt
    blueprint {
      id
      name
      type
    }
    createdByUser {
      id
      role
      depositsRole
      firstName
      lastName
    }
    status
    ...OnboardingApplicationStateFragment
    informationRequests(first: 50) {
      edges {
        node {
          id
          ...InformationRequestV2
        }
      }
    }
  }
  ${informationRequestV2}
  ${onboardingApplicationState}
`;

export const onboardingApplicationStatus = gql`
  fragment OnboardingApplicationStatusFragment on OnboardingApplication {
    id
    status
  }
`;

export const fundingSourceWithFinancialAccountFragment = gql`
  fragment FundingSourceFragmentWithFinancialAccount on FundingSource {
    id
    ...FundingSourceFragment
    financialAccount {
      id
      source {
        id
        institution {
          id
          name
          vendorsMapping {
            logoBase64
          }
        }
      }
    }
  }
  ${fundingSourceFragment}
`;

export const merchantFragment = gql`
  fragment MerchantFragment on Merchant {
    id
    name
    merchantCategory {
      id
      name
    }
  }
`;

export const addressFragment = gql`
  fragment AddressFragment on Address {
    address1
    address2
    city
    country
    state
    zip
  }
`;

export const counterpartyAddressFragment = gql`
  fragment CounterpartyAddressFragment on CounterpartyAddress {
    address1
    address2
    city
    country
    firstName
    lastName
    state
    zip
  }
`;

export const productConfigFeaturesFragment = gql`
  fragment ProductConfigFeaturesFragment on ProductConfig {
    features {
      multiEntity {
        enabled
      }
      advancedExpenseManagement {
        enabled
      }
    }
  }
`;

export const productConfigFeaturesFragmentV2 = gql`
  fragment ProductConfigFeaturesFragmentV2 on ProductConfig {
    featuresV2 {
      billPay {
        payableBillAccess {
          enabled
          entitled
        }
        payablePaymentEbaCheck {
          enabled
        }
        payablePaymentEbaDomesticAch {
          enabled
        }
        payablePaymentEbaInternationalAch {
          enabled
        }
        payablePaymentEbaDomesticWire {
          enabled
        }
        payablePaymentEbaInternationalWire {
          enabled
        }
        payableProcurementPolicy {
          enabled
        }
      }
      procurement {
        purchaseOrders {
          enabled
        }
      }
      reimbursements {
        basicReimbursements {
          enabled
          entitled
        }
        customReimbursementsApprovalChains {
          enabled
        }
        internationalReimbursements {
          enabled
        }
      }
      expenseManagement {
        basicExpenseManagement {
          enabled
        }
        accountingCustomFields {
          enabled
        }
        accountingFieldValueVisibility {
          enabled
        }
        accountingCustomRules {
          enabled
        }
        advancedAccountingIntegrations {
          enabled
        }
        bulkBudgetManagement {
          enabled
        }
        hrisIntegration {
          enabled
        }
        memoSuggestions {
          enabled
        }
        budgetPrograms {
          enabled
        }
        advancedBudgetTemplates {
          enabled
        }
        customBudgetTemplates {
          enabled
        }
        customCardExpensesApprovalChains {
          enabled
        }
        budgetNesting {
          enabled
        }
        delegatedBudgetManagement {
          enabled
        }
        budgetRequests {
          enabled
        }
        advancedPolicyCustomization {
          enabled
        }
        deadlines {
          enabled
        }
        vatTracking {
          enabled
        }
        enterpriseErpIntegrations {
          enabled
        }
        complianceReporting {
          enabled
        }
        advancedBudgetRestrictions {
          enabled
        }
        advancedBudgetControls {
          enabled
        }
        entitySpendLimitOverrideControls {
          enabled
        }
        aiAssistedReview {
          enabled
        }
        brexAssistant {
          enabled
        }
        complianceRating {
          enabled
        }
        bulkInvite {
          enabled
        }
        aiSmartFiltering {
          enabled
        }
        multipleCurrencyPolicy {
          enabled
        }
        accountingSuggestions {
          enabled
        }
        accountingAccrualDate {
          enabled
        }
        autoInviteSettings {
          enabled
        }
        spendLimitsAdvancedTargeting {
          enabled
        }
        userSpendLimit {
          enabled
        }
        advancedApprovalChains {
          enabled
        }
        walletAccess {
          enabled
        }
        metricsAccess {
          enabled
        }
        cardsAndLimitsAccess {
          enabled
        }
        reportsAccess {
          enabled
        }
        accountingAccess {
          enabled
        }
      }
      support {
        premiumSupport {
          enabled
        }
      }
      platform {
        advancedAndCustomRolesPermissions {
          enabled
        }
        globalSearch {
          enabled
        }
        copilot {
          enabled
        }
        accountSetupTasks {
          enabled
        }
      }
      travel {
        travelAccessManagement {
          enabled
        }
        travelTrips {
          entitled
        }
      }
      multiEntity {
        addEntitiesUnlimited {
          enabled
        }
        addEntitiesRestricted {
          enabled
        }
        locallyBilledCountriesUnlimited {
          enabled
        }
        locallyBilledCountriesLimited {
          enabled
        }
        locallyBilledCountriesRestricted {
          enabled
        }
        usdBilling {
          enabled
        }
      }
      audit {
        auditLogs {
          enabled
        }
      }
    }
  }
`;

export const billPayOnEmpowerMigratedFragment = gql`
  fragment BillPayOnEmpowerMigratedFragment on MigratedAccount {
    billPayOnEmpowerEnabled
  }
`;

export const deadlineSettingFragment = gql`
  fragment ComplianceDeadlineSettingsFragment on RequestDeadlineSettings {
    __typename
    id
    enabled
    gracePeriodDays
  }
`;

export const billingGroupCycleDetailsFragment = gql`
  fragment BillingGroupCycleDetailsFragment on BillingPolicy {
    cycleDetails {
      __typename
      ... on BiWeeklyDetails {
        dayOfWeek
      }
      ... on EveryNDaysDetails {
        numOfDays
      }
      ... on MonthlyDetails {
        dayOfMonth
      }
      ... on SemiMonthlyDetails {
        firstDay
        secondDay
      }
      ... on SemiWeeklyDetails {
        firstDay
        secondDay
      }
      ... on WeeklyDetails {
        dayOfWeek
      }
    }
  }
`;

export const billingPolicyBillFloatFragment = gql`
  fragment BillingPolicyBillFloatFragment on BillingPolicy {
    billFloat {
      type
      details {
        __typename
        ... on ConstantBillFloatDetails {
          days
        }
        ... on ConstantBeforeNextPeriodEndBillFloatDetails {
          days
        }
      }
    }
  }
`;

export const inboxTaskStateFragment = gql`
  fragment InboxTaskStateFragment on InboxTask {
    id
    taskState
  }
`;

export const inboxSurfaceTaskStateFragment = gql`
  fragment InboxSurfaceTaskStateFragment on SurfaceTask {
    id
    taskState
  }
`;

export const extendedFieldValueFragment = gql`
  fragment ExtendedFieldValueFragment on ExtendedFieldValue {
    key
    value {
      ... on ExtendedFieldSingleValue {
        value {
          __typename
          ... on StringValue {
            __typename
            stringValue: value
          }
          ... on BoolValue {
            __typename
            boolValue: value
          }
          ... on DecimalValue {
            __typename
            decimalValue: value
          }
          ... on EnumValue {
            __typename
            enumValue: value
          }
          ... on IdentifierToStringValue {
            __typename
            identifier
            displayName
          }
          ... on TimestampValue {
            __typename
            timestampValue: value
          }
        }
      }
    }
  }
`;
