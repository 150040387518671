import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "spend.approval_rules.read": false,
  "spend.approval_rules.reimbursement.write": false,
  "spend.approval_rules.vendor_card.write": false,
  "spend.cards.all.view": false,
  "spend.cards.manage": false,
  "spend.cards.request": false,
  "spend.cards.yours.view": false,
  "spend.compliance_rating.read": false,
  "spend.compliance_rating_settings.create": false,
  "spend.compliance_rating_settings.read": false,
  "spend.compliance_rating_settings.update": false,
  "spend.hrSync.manage": false,
  "spend.premiumUpsell.setup": false,
  "spend.reimbursements.approve": false,
  "spend.reimbursements.create": false,
  "spend.reports.read": false,
  "spend.requests.company.review": false,
  "spend.requests.setup": false,
  "spend.requests.team.review": false,
  "spend.requests.yours.view": false,
  "spend.requestsSettings.edit": false,
  "spend.review.read": false,
  "spend.review.setup": false,
  "spend.reviewTeam.manage": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {
    "spend.cards.request": true,
    "spend.cards.yours.view": true,
    "spend.compliance_rating.read": true,
    "spend.reimbursements.create": true,
    "spend.requests.yours.view": true,
  } as PartialPermissions,
  [UserRole.BOOKKEEPER]: {
    "spend.cards.all.view": true,
    "spend.compliance_rating.read": true,
    "spend.reports.read": true,
    "spend.requests.yours.view": true,
    "spend.review.read": true,
  } as PartialPermissions,
  [UserRole.ADMIN]: {
    "spend.approval_rules.read": true,
    "spend.approval_rules.reimbursement.write": true,
    "spend.approval_rules.vendor_card.write": true,
    "spend.cards.all.view": true,
    "spend.cards.manage": true,
    "spend.cards.yours.view": true,
    "spend.compliance_rating.read": true,
    "spend.compliance_rating_settings.create": true,
    "spend.compliance_rating_settings.read": true,
    "spend.compliance_rating_settings.update": true,
    "spend.hrSync.manage": true,
    "spend.premiumUpsell.setup": true,
    "spend.reimbursements.create": true,
    "spend.reports.read": true,
    "spend.requests.company.review": true,
    "spend.requests.setup": true,
    "spend.requests.yours.view": true,
    "spend.requestsSettings.edit": true,
    "spend.review.read": true,
    "spend.review.setup": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {
    "spend.reimbursements.create": true,
  } as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {} as PartialPermissions,
  [DepositsRole.ADMIN]: {
    "spend.approval_rules.read": true,
    "spend.approval_rules.reimbursement.write": true,
    "spend.hrSync.manage": true,
    "spend.reimbursements.approve": true,
    "spend.reimbursements.create": true,
    "spend.requests.setup": true,
  } as PartialPermissions,
  null: {} as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {} as PartialPermissions,
  true: {
    "spend.requests.team.review": true,
    "spend.reviewTeam.manage": true,
  } as PartialPermissions,
};

// permissionsEligibleForCopilot determines what permissions are eligible for use in Copilot mode.
// Please use caution around what should be set to true and see go/td-wiki for details.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "spend.approval_rules.read": false,
  "spend.approval_rules.reimbursement.write": false,
  "spend.approval_rules.vendor_card.write": false,
  "spend.cards.all.view": false,
  "spend.cards.manage": false,
  "spend.cards.request": false,
  "spend.cards.yours.view": false,
  "spend.compliance_rating.read": true,
  "spend.compliance_rating_settings.create": false,
  "spend.compliance_rating_settings.read": false,
  "spend.compliance_rating_settings.update": false,
  "spend.hrSync.manage": false,
  "spend.premiumUpsell.setup": false,
  "spend.reimbursements.approve": false,
  "spend.reimbursements.create": false,
  "spend.reports.read": false,
  "spend.requests.company.review": false,
  "spend.requests.setup": false,
  "spend.requests.team.review": false,
  "spend.requests.yours.view": false,
  "spend.requestsSettings.edit": false,
  "spend.review.read": false,
  "spend.review.setup": false,
  "spend.reviewTeam.manage": false,
};
