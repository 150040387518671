import type {
  FreshchatInitValue,
  UserProperties,
  WidgetEvent,
} from "@/features/Freshchat/sharedTypes";

// This class is intended as a type-safe method for interacting with the Freshchat widget API
export class Freshchat {
  static openWidget() {
    window.fcWidget?.show();
    window.fcWidget?.open();
  }

  static closeAndHideWidget() {
    window.fcWidget?.close();
    Freshchat.hideWidget();
  }

  static init(payload: FreshchatInitValue) {
    window.fcWidget?.init(payload);
  }

  static hideWidget() {
    window.fcWidget?.hide();
  }

  static destroyWidget() {
    window.fcWidget?.destroy();
  }

  static clearUser() {
    return window.fcWidget?.user.clear();
  }

  /**
   * For supported events and their arguments
   * @see https://developers.freshchat.com/web-sdk/#messenger-events
   */
  static onEvent(eventName: WidgetEvent, callback: (...args: any) => void) {
    window.fcWidget?.on(eventName, callback);
  }

  static setUserProperties = (userProperties: UserProperties) => {
    window.fcWidget?.user.setProperties(userProperties);
  };

  static isWidgetAvailable() {
    return Boolean(window.fcWidget);
  }
}
