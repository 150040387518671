import { useAtomValue } from "jotai";
import * as React from "react";
import {
  BillPayResourceAttribute,
  ResourceAction,
} from "@/__generated__/globalTypes";
import { resourceOperationMappingAtom } from "@/domains/App/features/Permissions/atoms/resourceOperationsQueryAtoms";

export const useBillPayResourceOperations = () => {
  const { billPay } = useAtomValue(resourceOperationMappingAtom);

  const resourceOperationFlags = React.useMemo(() => {
    const canCreateBasicBillPolicy = billPay.hasBillPayResourceOperation(
      ResourceAction.CREATE,
      BillPayResourceAttribute.BASIC_POLICY,
    );

    const canCreateProcurementPolicy = billPay.hasBillPayResourceOperation(
      ResourceAction.CREATE,
      BillPayResourceAttribute.PROCUREMENT_POLICY,
    );

    const canSeeBills = billPay.hasBillPayResourceOperation(
      ResourceAction.READ,
      BillPayResourceAttribute.BILL,
    );

    const canCreateBills = billPay.hasBillPayResourceOperation(
      ResourceAction.CREATE,
      BillPayResourceAttribute.BILL,
    );

    const canUpdateBills = billPay.hasBillPayResourceOperation(
      ResourceAction.UPDATE,
      BillPayResourceAttribute.BILL,
    );

    const canDeleteBills = billPay.hasBillPayResourceOperation(
      ResourceAction.UPDATE,
      BillPayResourceAttribute.BILL,
    );

    const canSendCashPayments = billPay.hasBillPayResourceOperation(
      ResourceAction.CREATE,
      BillPayResourceAttribute.CASH_PAYMENT,
    );

    /**
     * This is actually EBA_DOMESTIC_ACH_PAYMENT. To be renamed later.
     *
     * Context: https://docs.google.com/document/d/1aSI0y43B4atBDQC0CqGzkAIjKExcrGdFiDBMiY4GdPs/edit?disco=AAAAxiQtLIo
     */
    const canSendEbaAchPayments = billPay.hasBillPayResourceOperation(
      ResourceAction.CREATE,
      BillPayResourceAttribute.EBA_PAYMENT,
    );

    const canSendEbaCheckPayments = billPay.hasBillPayResourceOperation(
      ResourceAction.CREATE,
      BillPayResourceAttribute.EBA_CHECK_PAYMENT,
    );

    const canSendEbaDomesticWirePayments = billPay.hasBillPayResourceOperation(
      ResourceAction.CREATE,
      BillPayResourceAttribute.EBA_DOMESTIC_WIRE_PAYMENT,
    );

    const canSendEbaInternationalWirePayments =
      billPay.hasBillPayResourceOperation(
        ResourceAction.CREATE,
        BillPayResourceAttribute.EBA_INTERNATIONAL_WIRE_PAYMENT,
      );

    const canSendEbaPayments =
      canSendEbaAchPayments ||
      canSendEbaCheckPayments ||
      canSendEbaDomesticWirePayments ||
      canSendEbaInternationalWirePayments;

    return {
      canCreateBasicBillPolicy,
      canCreateBills,
      canCreateProcurementPolicy,
      canDeleteBills,
      canSeeBills,
      canSendCashPayments,
      canSendEbaAchPayments,
      canSendEbaCheckPayments,
      canSendEbaDomesticWirePayments,
      canSendEbaPayments,
      canUpdateBills,
      canSendEbaInternationalWirePayments,
    };
  }, [billPay]);

  return resourceOperationFlags;
};
