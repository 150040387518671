import * as Types from '../../../../__generated__/globalTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SandboxQuery_account_Account = {
  __typename?: 'Account',
  id: string,
  isSandbox: boolean | null,
  isDemo: boolean | null
};

export type SandboxQuery_Query = {
  __typename?: 'Query',
  hasAccessToSandbox: boolean,
  account: SandboxQuery_account_Account | null
};


export type SandboxQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SandboxQuery = SandboxQuery_Query;

export type SandboxLatestImportStatusQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge_node_SandboxAccountAction_actor_SandboxAccountActionActor = {
  __typename?: 'SandboxAccountActionActor',
  id: string,
  displayName: string,
  source: Types.SandboxAccountActionSource
};

export type SandboxLatestImportStatusQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge_node_SandboxAccountAction = {
  __typename?: 'SandboxAccountAction',
  id: string,
  type: Types.SandboxAccountActionType,
  insertedAt: GraphqlTime,
  status: Types.SandboxAccountActionStatus,
  actor: SandboxLatestImportStatusQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge_node_SandboxAccountAction_actor_SandboxAccountActionActor
};

export type SandboxLatestImportStatusQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge = {
  __typename?: 'ListSandboxAccountActionsEdge',
  node: SandboxLatestImportStatusQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge_node_SandboxAccountAction
};

export type SandboxLatestImportStatusQuery_listSandboxAccountActions_ListSandboxAccountActionsResult = {
  __typename?: 'ListSandboxAccountActionsResult',
  edges: Array<SandboxLatestImportStatusQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge>
};

export type SandboxLatestImportStatusQuery_Query = {
  __typename?: 'Query',
  listSandboxAccountActions: SandboxLatestImportStatusQuery_listSandboxAccountActions_ListSandboxAccountActionsResult | null
};


export type SandboxLatestImportStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SandboxLatestImportStatusQuery = SandboxLatestImportStatusQuery_Query;

export type SandboxLatestSuccessfulImportDateQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge_node_SandboxAccountAction = {
  __typename?: 'SandboxAccountAction',
  id: string,
  type: Types.SandboxAccountActionType,
  insertedAt: GraphqlTime,
  status: Types.SandboxAccountActionStatus
};

export type SandboxLatestSuccessfulImportDateQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge = {
  __typename?: 'ListSandboxAccountActionsEdge',
  node: SandboxLatestSuccessfulImportDateQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge_node_SandboxAccountAction
};

export type SandboxLatestSuccessfulImportDateQuery_listSandboxAccountActions_ListSandboxAccountActionsResult = {
  __typename?: 'ListSandboxAccountActionsResult',
  edges: Array<SandboxLatestSuccessfulImportDateQuery_listSandboxAccountActions_ListSandboxAccountActionsResult_edges_ListSandboxAccountActionsEdge>
};

export type SandboxLatestSuccessfulImportDateQuery_Query = {
  __typename?: 'Query',
  listSandboxAccountActions: SandboxLatestSuccessfulImportDateQuery_listSandboxAccountActions_ListSandboxAccountActionsResult | null
};


export type SandboxLatestSuccessfulImportDateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SandboxLatestSuccessfulImportDateQuery = SandboxLatestSuccessfulImportDateQuery_Query;


export const SandboxQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SandboxQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isSandbox"}},{"kind":"Field","name":{"kind":"Name","value":"isDemo"}}]}},{"kind":"Field","name":{"kind":"Name","value":"hasAccessToSandbox"}}]}}]} as unknown as DocumentNode<SandboxQuery, SandboxQueryVariables>;
export const SandboxLatestImportStatusQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SandboxLatestImportStatusQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listSandboxAccountActions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"page"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"1"}}]}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"types"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"IMPORT_ACCOUNTING_DATA"},{"kind":"EnumValue","value":"PROVISION_ACCOUNT"},{"kind":"EnumValue","value":"GENERAL_DATA_IMPORT"}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"actor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"source"}}]}},{"kind":"Field","name":{"kind":"Name","value":"insertedAt"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode<SandboxLatestImportStatusQuery, SandboxLatestImportStatusQueryVariables>;
export const SandboxLatestSuccessfulImportDateQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SandboxLatestSuccessfulImportDateQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listSandboxAccountActions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"page"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"1"}}]}},{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"types"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"IMPORT_ACCOUNTING_DATA"},{"kind":"EnumValue","value":"PROVISION_ACCOUNT"},{"kind":"EnumValue","value":"GENERAL_DATA_IMPORT"}]}},{"kind":"ObjectField","name":{"kind":"Name","value":"statuses"},"value":{"kind":"EnumValue","value":"COMPLETED"}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"insertedAt"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode<SandboxLatestSuccessfulImportDateQuery, SandboxLatestSuccessfulImportDateQueryVariables>;