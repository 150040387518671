import { useAccountId } from "@/features/Authentication/contexts/UserProperties";
import { useAwaitQueryWithAtoms } from "@/features/Jotai";
import { fspConfigQueryAtom } from "@/features/ProAccess/atoms/fspConfigQueryAtoms";
import { fspConfigQuery } from "@/features/ProAccess/data/queries";
import { toRawEntityId } from "@/helpers";

export const useFspConfigQueryWithAtom = () => {
  const accountId = useAccountId();
  const rawAccountId = toRawEntityId(accountId);

  const response = useAwaitQueryWithAtoms(fspConfigQuery, {
    atomsWithQuery: fspConfigQueryAtom,
    variables: { accountId: rawAccountId ?? "" },
    fetchPolicy: "network-only",
  });

  return response;
};
