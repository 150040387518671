import React from "react";
import type { DefaultErrorProps } from "./InternalDefaultError";
import { InternalDefaultError } from "./InternalDefaultError";
import { inSnapshotRenderer } from "@/helpers/storybook/inSnapshotRenderer";

export type { DefaultErrorProps };

export const DefaultError: React.FC<DefaultErrorProps> = (props) => {
  if (inSnapshotRenderer()) {
    throw new Error(
      "Rendering DefaultError in stories that are snapshotted by chromatic is not allowed. Instead, please write unit tests for cases that your component should render DefaultError.",
    );
  }

  return <InternalDefaultError {...props} />;
};
