import type { DocumentNode } from "graphql";
import { useControllerPerformanceContext } from "@/domains/App/components/Controller/ControllerContext";
import type {
  OperationVariables,
  MutationHookOptions,
  MutationTuple,
} from "@/features/Apollo/helpers/client";
import {
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  useMutation as useApolloMutation,
  getOperationName,
} from "@/features/Apollo/helpers/client";

export function useMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
  shouldUseQueryOperationName = true,
): MutationTuple<TData, TVariables> {
  // we don't use `getOperationName` if a user explicitly passes `false` for the `shouldUseQueryOperationName` flag,
  // or if the query options already have variables defined.
  const refetchQueries = Array.isArray(options?.refetchQueries)
    ? options?.refetchQueries.map((x) =>
        shouldUseQueryOperationName &&
        typeof x !== "string" &&
        "query" in x &&
        !x.variables
          ? (getOperationName(x.query) as string)
          : x,
      )
    : options?.refetchQueries;

  const controllerContext = useControllerPerformanceContext();
  return useApolloMutation<TData, TVariables>(mutation, {
    ...options,
    refetchQueries,
    context: {
      ...options?.context,
      controllerContext,
    },
  });
}
