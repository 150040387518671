export enum AdaAnalyticsEvents {
  AdaSupportChatOpened = "ada.support_chat.opened",
  AdaSupportChatClosed = "ada.support_chat.closed",
  AdaSupportFrameTimeout = "ada.support_frame.timeout",
}

type EmptyPayload = {};

export type AdaEventSchemaMap = {
  /** Ada chat opened / closed */
  [AdaAnalyticsEvents.AdaSupportChatOpened]: EmptyPayload;
  [AdaAnalyticsEvents.AdaSupportChatClosed]: EmptyPayload;
  [AdaAnalyticsEvents.AdaSupportFrameTimeout]: EmptyPayload;
};
