import type { CacheKey, JsonSerializable } from "./types";

type GlobalState<T extends JsonSerializable> = {
  [key in CacheKey]: {
    callbacks: Array<React.Dispatch<T>>;
    value: T;
  };
};

// cached global state
const globalState = {};

// return a properly typed cached global state
const getGlobalState = <T extends JsonSerializable>() =>
  globalState as GlobalState<T>;

export type CreateGlobalState<T extends JsonSerializable> = {
  unsubscribe: VoidFunction;
  publish(value: T): void;
};

/**
 * Create a global state manager for the passed cache key.
 *
 * This works in conjunction with the `usePersistedState` hook and allows for
 * multiple hooks to access the realtime values of the cache without the need
 * for a context and potentially expensive re-renders.
 */
export const createGlobalState = <T extends JsonSerializable>(
  key: CacheKey,
  thisCallback: React.Dispatch<T>,
  initialValue: T,
): CreateGlobalState<T> => {
  const state = getGlobalState<T>();
  if (!state[key]) {
    state[key] = { callbacks: [], value: initialValue };
  }
  state[key].callbacks.push(thisCallback);
  return {
    /**
     * This runs when components that use the `usePersistedState` hook unmount
     * so that we don't run a callback associated with a non-existent component
     */
    unsubscribe() {
      const callbacks = state[key].callbacks;
      const index = callbacks.indexOf(thisCallback);
      if (index > -1) {
        callbacks.splice(index, 1);
      }
    },
    /**
     * This informs all other active instances of the `usePersistedState` hook
     * with the same key of a new value
     */
    publish(value: T) {
      if (state[key]?.value !== value) {
        state[key].value = value;
        state[key]?.callbacks.forEach((callback: any) => {
          if (thisCallback !== callback) {
            callback(value);
          }
        });
      }
    },
  };
};
