export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const waitIdle = (options?: IdleRequestOptions) =>
  new Promise((resolve) => {
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(resolve, options);
    } else {
      setTimeout(resolve, 0);
    }
  });

export const waitNextFrame = () =>
  new Promise((resolve) => requestAnimationFrame(resolve));

/**
 * This is a helper function that wraps a promise with a timeout.
 * If the promise takes longer than the specified time, it will throw an error.
 *
 * @param promise The function to be executed.
 * @param ms The time in milliseconds to wait before timing out.
 * @param errorMessage The message included with the error if the promise times out.
 */
export const promiseWithTimeout = <T>(
  promise: Promise<T>,
  ms: number,
  errorMessage = "Promise timed out",
): Promise<T> => {
  // Create a promise that will reject after the specified time.
  const timeout = new Promise<never>((_, reject) => {
    setTimeout(() => {
      reject(new Error(errorMessage));
    }, ms);
  });

  // Combine the promise and the timeout, and return the result of the first one to finish.
  return Promise.race<T>([promise, timeout]);
};
