import * as React from "react";
import { FreshchatContext } from "@/features/Freshchat/contexts/FreshchatContext";

export const useFreshchat = () => {
  const { initializeProvider, ...rest } = React.useContext(FreshchatContext);

  React.useEffect(() => {
    initializeProvider();
  }, [initializeProvider]);

  return rest;
};
