import * as React from "react";
import { useUserId } from "@/features/Authentication/contexts/UserProperties";
import { createMockableHook } from "@/helpers/createMockableHook";
import { useLocalPersistedState } from "@/hooks/useLocalPersistedState";
import { LocalPersistedStateKeys } from "@/hooks/useLocalPersistedState/types";

const NO_OVERRIDES = {};

/*
 ************************************************************************************************
 *
 *  NOTE: This hook is only meant to support the non-production Dev Panel experience
 *  (@/features/DevPanel). Overrides should not be created anywhere other than from that feature.
 *
 ************************************************************************************************
 */
const useProductConfigDevOverrides = (
  defaultUserId?: string,
): {
  overrides?: { [key: string]: boolean };
  setOverride?: (key: string, value: boolean) => void;
  removeOverride?: (key: string) => void;
  removeAllOverrides?: () => void;
} => {
  const [overrides, setOverrides] = useLocalPersistedState<{
    [namespacedKey: string]: { [key: string]: boolean };
  }>(LocalPersistedStateKeys.PRODUCT_CONFIG_DEV_OVERRIDES, NO_OVERRIDES);

  // Not all invocations of this hook are made within the authenticated-user-only
  // UserPropertiesContext, so userId will not always be available from context.
  // This hook takes a defaultUserId parameter and puts the onus on the consumer
  // for getting the userId by some method other than context if it wishes.
  const userIdFromContext = useUserId();
  const userId = userIdFromContext || defaultUserId;

  const namespacedKey = userId || "";
  const namespacedOverrides = React.useMemo(
    () => ({
      ...overrides[namespacedKey],
    }),
    [namespacedKey, overrides],
  );

  const setOverride = React.useCallback(
    (key: string, value: boolean) => {
      const updatedOverrides = {
        ...overrides,
        [namespacedKey]: { ...namespacedOverrides, [key]: value },
      };
      setOverrides(updatedOverrides);
    },
    [namespacedKey, namespacedOverrides, overrides, setOverrides],
  );

  const removeOverride = React.useCallback(
    (key: string) => {
      const { [key]: _, ...rest } = namespacedOverrides;
      const updatedOverrides = {
        ...overrides,
        [namespacedKey]: rest,
      };
      setOverrides(updatedOverrides);
    },
    [namespacedKey, namespacedOverrides, overrides, setOverrides],
  );

  const removeAllOverrides = React.useCallback(() => {
    const { [namespacedKey]: _, ...rest } = overrides;
    setOverrides(rest);
  }, [namespacedKey, overrides, setOverrides]);

  // Overrides should never return for prod or undefined environments,
  // nor should the ability to manipulate overrides.
  const env = process.env.APP_ENV;
  if (!env || env === "prod") {
    return NO_OVERRIDES;
  }

  return {
    overrides: namespacedOverrides,
    setOverride,
    removeOverride,
    removeAllOverrides,
  };
};

export const [
  useMockableProductConfigDevOverrides,
  MockProductConfigDevOverridesProvider,
] = createMockableHook(useProductConfigDevOverrides);
