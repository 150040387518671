import { useCallback, useRef } from "react";
import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { getEnvironmentConfig } from "@/features/Authentication/helpers/config";
import { refreshAccessToken } from "@/features/Authentication/helpers/tokenManagerApi";
import { setToken } from "@/features/Authentication/helpers/tokenStorage";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { loadScript } from "@/helpers/environment";

const initOktaAuthClient = async () => {
  if (!window.OktaAuth) {
    await loadScript(
      "https://global.oktacdn.com/okta-auth-js/7.3.0/okta-auth-js.min.js",
    );
  }

  const config = getEnvironmentConfig();
  return new window.OktaAuth({
    issuer: config.endpoint,
    clientId: config.clientId,
    redirectUri: config.invisibleRedirectUri,
    scopes: config.scope.split(" "),
  });
};

export const useOktaReauth = () => {
  const authenticationContext = useAuthenticationContext();
  // This reference is necessary to prevent potential race conditions, where multiple calls to useOktaReauth could
  // overlap and cause unexpected behavior. By using this flag, you ensure that useOktaReauth cannot be run
  // concurrently, adding a safeguard against issues that could be caused by overlapping reauthentication attempts.
  const isInitialized = useRef(false);
  const { stepUpMfaOie } = useFeatureFlags();
  return useCallback(
    async (
      acrValues?: string | null,
      maxAge?: number | null,
    ): Promise<boolean> => {
      if (isInitialized.current) {
        return false;
      }
      isInitialized.current = true;
      try {
        const authClient = await initOktaAuthClient();
        const res = await authClient.token.getWithPopup({
          acrValues: acrValues || "urn:okta:loa:2fa:any",
          maxAge: stepUpMfaOie ? maxAge || 300 : maxAge,
        });
        // refresh token
        // As of 9/2023, token is refreshed by calling web auth token manager
        // And is a cookie set by web auth token manager, with cookie expiration = access token expiration
        // So we should extend the cookie by calling refresh token
        try {
          await refreshAccessToken();
        } catch {
          console.error("Failed to refresh access token");
        }
        const oktaTokens = res.tokens;
        const tokenResponse = {
          accessToken: oktaTokens.accessToken.accessToken,
          idToken: oktaTokens.idToken.idToken,
          expiresIn:
            (oktaTokens.accessToken.expiresAt * 1000 - new Date().getTime()) /
            1000,
        };
        authenticationContext.setAuthenticationContext(tokenResponse);
        setToken(tokenResponse);

        isInitialized.current = false;
        return true;
      } catch (err) {
        isInitialized.current = false;
        throw err;
      }
    },
    [authenticationContext, stepUpMfaOie],
  );
};
