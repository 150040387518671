import * as React from "react";
import type { FreshchatContextValue } from "@/features/Freshchat/sharedTypes";

export const defaultData: FreshchatContextValue = {
  isFreshchatInitialized: false,
  initializeProvider: () => {},
  openSupport: () => {},
  clearUser: async () => {},
};

export const FreshchatContext = React.createContext(defaultData);
