import type { OnboardingTaskStatus } from "@/data/sharedTypes";
import type { OffboardingTaskId } from "@/domains/Risk/sharedTypes";

type OffboardingTaskPayload = {
  taskId: OffboardingTaskId | "LOADING";
  status?: OnboardingTaskStatus;
};

type OffboardingBannerViewedPayload = {
  taskId?: OffboardingTaskId | string;
  type?: OffboardingTaskId | string;
};

export enum RiskAnalyticsEvents {
  // Offboarding - legacy used to live in onboarding
  OffboardingBannerViewed = "onboarding.getting-started.banner.viewed",
  OffboardingTaskClicked = "onboarding.getting-started.task.clicked",
  OffboardingTaskViewed = "onboarding.getting-started.task.viewed",
}

export type RiskEventSchemaMap = {
  [RiskAnalyticsEvents.OffboardingBannerViewed]: OffboardingBannerViewedPayload;
  [RiskAnalyticsEvents.OffboardingTaskClicked]: OffboardingTaskPayload;
  [RiskAnalyticsEvents.OffboardingTaskViewed]: OffboardingTaskPayload;
};
