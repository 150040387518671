import { ButtonSize, LinkButton } from "@brexhq/component-library";
import * as React from "react";
import {
  StyledContent,
  StyledFooter,
  StyledFooterSeparator,
  StyledHeader,
  StyledHeaderAction,
  StyledHeaderActionMessage,
  StyledLayout,
  StyledLogo,
  StyledPreFooter,
} from "./UnauthorizedLayout.elements";
import { TrackedErrorBoundary } from "@/components/Error";
import { Logo } from "@/components/Logo";
import { UnauthorizedLayoutLanguageSwitcher } from "@/domains/App/features/Localization/components/UnauthorizedLayoutLanguageSwitcher";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { urls } from "@/helpers/urls";

export type UnauthorizedLayoutProps = {
  secondaryAction?: React.ReactNode;
  secondaryActionMessage?: string;
  primaryLayout?: boolean;
  showLanguageSwitcher?: boolean;
  preFooter?: React.ReactNode;
};

export const UnauthorizedLayout: React.FC<UnauthorizedLayoutProps> = ({
  secondaryAction,
  secondaryActionMessage,
  primaryLayout,
  children,
  showLanguageSwitcher,
  preFooter,
}) => {
  const { languageSwitcher } = useFeatureFlags();

  return (
    <StyledLayout
      data-testid={`unauthorized-layout`}
      primaryLayout={primaryLayout}
    >
      <StyledHeader>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        {(secondaryAction || secondaryActionMessage) && (
          <StyledHeaderAction>
            {secondaryActionMessage && (
              <StyledHeaderActionMessage>
                {secondaryActionMessage}
              </StyledHeaderActionMessage>
            )}
            {secondaryAction}
          </StyledHeaderAction>
        )}
      </StyledHeader>
      <StyledContent>
        <TrackedErrorBoundary>{children}</TrackedErrorBoundary>
      </StyledContent>
      {preFooter && <StyledPreFooter>{preFooter}</StyledPreFooter>}
      <StyledFooter>
        <LinkButton
          type="link"
          size={ButtonSize.SMALL}
          href={urls.legal.main()}
          target="_blank"
          rel="noreferrer noopener"
        >
          Brex Platform Agreement
        </LinkButton>
        <StyledFooterSeparator />
        <LinkButton
          type="link"
          size={ButtonSize.SMALL}
          href={urls.website.privacy()}
          target="_blank"
          rel="noreferrer noopener"
        >
          Privacy Policy
        </LinkButton>
        {showLanguageSwitcher && languageSwitcher && (
          <>
            <StyledFooterSeparator />
            <UnauthorizedLayoutLanguageSwitcher />
          </>
        )}
      </StyledFooter>
    </StyledLayout>
  );
};
