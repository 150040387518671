import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";

// eslint-disable-next-line @emotion/syntax-preference
export const getLegacyGlobalStylesheet = (theme: Theme) => css`
  /*
  * NOTE: this MUST be kept in sync with the component-library
  * or there will be unexpected downstream changes that we may not have story
  * coverage for.
  *
  * https://github.com/brexhq/metal-design-system/blob/31de6ee1dffac57aa9583eb066082d31b55ae04b/packages/component-library/.storybook/stylesheets/legacyGlobal.css
  *
  */

  :root {
    /* Primary Accent */
    --accentPrimary: ${theme.colors.accent.primary.background.default};
    --accentPrimaryHover: ${theme.colors.accent.primary.background.hover};
    --accentPrimaryPressed: ${theme.colors.accent.primary.background.pressed};
    --accentPrimaryFocused: ${theme.colors.accent.primary.background.focused};
    --accentPrimaryDisabled: ${theme.colors.accent.primary.background.disabled};
    --accentPrimaryMuted: ${theme.colors.accent.primary.background.muted};
    font-feature-settings: "ss01" on, "ss03" on, "cv05" on, "cv06" on, "cv10" on;

    /* Text */
    --contentPrimary: ${theme.colors.content.primary};
    --contentSecondary: ${theme.colors.content.secondary};
    --contentDisabled: ${theme.colors.content.disabled};

    /* Background */
    --background: ${theme.colors.background.primary};

    /* Status */
    --success: ${theme.colors.positive.default};
    --successHover: ${theme.colors.positive.hover};
    --successPressed: ${theme.colors.positive.pressed};
    --successMuted: ${theme.colors.positive.muted};
    --error: ${theme.colors.negative.default};
    --errorHover: ${theme.colors.negative.hover};
    --errorPressed: ${theme.colors.negative.pressed};
    --errorMuted: ${theme.colors.negative.muted};

    /* Borders */
    --borderDefault: ${theme.colors.border.default};
  }

  * {
    /* Make taps faster on mobile, and prevent double tap zooms */
    touch-action: manipulation;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: "Noto Color Emoji Country Flags", "Inter", -apple-system,
      BlinkMacSystemFont, system-ui, Helvetica, sans-serif, emoji,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    color: var(--contentPrimary);
    background: var(--background);
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    font-size: 15px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  /**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.5; /* 1 */
    margin: 0; /* 2 */
  }

  /**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

  button,
  input {
    /* 1 */
    overflow: visible;
  }

  /**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

  button,
  select {
    /* 1 */
    text-transform: none;
  }

  [role="button"] {
    cursor: pointer;
  }

  /**
 * Remove the inner border and padding in Firefox.
 */

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /**
  * Restore the focus styles unset by the previous rule.
  */

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  /**
  * Correct the padding in Firefox.
  */

  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  /**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from fieldset elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    fieldset elements in all browsers.
 */

  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }

  /**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

  progress {
    vertical-align: baseline;
  }

  /**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  /**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */

  [type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }

  /**
  * Remove the inner padding in Chrome and Safari on macOS.
  */

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  /**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to inherit in Safari.
  */

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  /*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

  details {
    display: block;
  }

  /*
 * Add the correct display in all browsers.
 */

  summary {
    display: list-item;
  }

  a {
    color: var(--contentPrimary);
  }

  a:hover {
    color: var(--contentSecondary);
  }

  a:active {
    color: var(--contentPrimary);
  }

  a:disabled {
    color: var(--contentDisabled);
  }

  p {
    margin: 0 0 11px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }

  h1,
  h2,
  h3 {
    margin-top: 22px;
    margin-bottom: 11px;
  }

  h1 {
    font-size: 39px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 19px;
  }

  h4,
  h5,
  h6 {
    margin-top: 11px;
    margin-bottom: 11px;
  }

  small {
    font-size: 86%;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin: 4px 0 0;
    line-height: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 11px;
  }

  img {
    vertical-align: middle;
    border: 0;
  }

  hr {
    margin-top: 22px;
    margin-bottom: 22px;
    border: 0;
    border-top: 1px solid var(--borderDefault);
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }

  #root {
    height: 100%;
  }

  .app {
    height: 100%;
  }
`;
