import type { ProductConfigQuery } from "@/data/__generated__/queries.generated";
import type { UserPropertiesQuery } from "@/features/Authentication/data/__generated__/queries.generated";

// We want to set defaults in case we do not receive data from the ProductConfigQuery.
// Without this, a failed query could mean someone on Empower Full would be missing features.
export const selectProductConfig = (
  data?: ProductConfigQuery | UserPropertiesQuery,
) => ({
  multiEntityEnabled: data?.productConfig.features.multiEntity.enabled ?? true,
  advancedExpenseManagementEnabled:
    data?.productConfig.features.advancedExpenseManagement.enabled ?? true,
  basicReimbursementsEnabled:
    data?.productConfig.featuresV2.reimbursements.basicReimbursements.enabled ??
    true,
  customReimbursementsApprovalChainsEnabled:
    data?.productConfig.featuresV2.reimbursements
      .customReimbursementsApprovalChains.enabled ?? true,
  internationalReimbursementsEnabled:
    data?.productConfig.featuresV2.reimbursements.internationalReimbursements
      .enabled ?? true,
  basicExpenseManagementEnabled:
    data?.productConfig.featuresV2.expenseManagement.basicExpenseManagement
      .enabled ?? false,
  accountingCustomFieldsEnabled:
    data?.productConfig.featuresV2.expenseManagement.accountingCustomFields
      .enabled ?? true,
  accountingFieldValueVisibilityEnabled:
    data?.productConfig.featuresV2.expenseManagement
      .accountingFieldValueVisibility?.enabled ?? true,
  accountingCustomRulesEnabled:
    data?.productConfig.featuresV2.expenseManagement.accountingCustomRules
      .enabled ?? true,
  advancedAccountingIntegrationsEnabled:
    data?.productConfig.featuresV2.expenseManagement
      .advancedAccountingIntegrations.enabled ?? true,
  hrisIntegrationEnabled:
    data?.productConfig.featuresV2.expenseManagement.hrisIntegration.enabled ??
    true,
  memoSuggestionsEnabled:
    data?.productConfig.featuresV2.expenseManagement?.memoSuggestions.enabled ??
    true,
  budgetProgramsEnabled:
    data?.productConfig.featuresV2.expenseManagement.budgetPrograms.enabled ??
    true,
  advancedBudgetTemplatesEnabled:
    data?.productConfig.featuresV2.expenseManagement?.advancedBudgetTemplates
      .enabled ?? true,
  customBudgetTemplatesEnabled:
    data?.productConfig.featuresV2.expenseManagement?.customBudgetTemplates
      .enabled ?? true,
  budgetNestingEnabled:
    data?.productConfig.featuresV2.expenseManagement?.budgetNesting.enabled ??
    true,
  delegatedBudgetManagementEnabled:
    data?.productConfig.featuresV2.expenseManagement?.delegatedBudgetManagement
      .enabled ?? true,
  budgetRequestsEnabled:
    data?.productConfig.featuresV2.expenseManagement?.budgetRequests.enabled ??
    true,
  advancedPolicyCustomizationEnabled:
    data?.productConfig.featuresV2.expenseManagement
      ?.advancedPolicyCustomization.enabled ?? true,
  deadlinesEnabled:
    data?.productConfig.featuresV2.expenseManagement?.deadlines.enabled ?? true,
  vatTrackingEnabled:
    data?.productConfig.featuresV2.expenseManagement?.vatTracking.enabled ??
    true,
  enterpriseErpIntegrationsEnabled:
    data?.productConfig.featuresV2.expenseManagement?.enterpriseErpIntegrations
      .enabled ?? true,
  complianceReportingEnabled:
    data?.productConfig.featuresV2.expenseManagement?.complianceReporting
      .enabled ?? true,
  advancedAndCustomRolesPermissionsEnabled:
    data?.productConfig.featuresV2.platform?.advancedAndCustomRolesPermissions
      .enabled ?? true,
  advancedBudgetRestrictionsEnabled:
    data?.productConfig.featuresV2.expenseManagement?.advancedBudgetRestrictions
      .enabled ?? true,
  advancedBudgetControlsEnabled:
    data?.productConfig.featuresV2.expenseManagement?.advancedBudgetControls
      .enabled ?? true,
  payableBillAccess:
    data?.productConfig.featuresV2.billPay?.payableBillAccess.enabled ?? true,
  payablePaymentEbaCheck:
    data?.productConfig.featuresV2.billPay?.payablePaymentEbaCheck.enabled ??
    true,
  payablePaymentEbaDomesticAch:
    data?.productConfig.featuresV2.billPay?.payablePaymentEbaDomesticAch
      .enabled ?? true,
  payablePaymentEbaInternationalAch:
    data?.productConfig.featuresV2.billPay?.payablePaymentEbaInternationalAch
      .enabled ?? true,
  payablePaymentEbaDomesticWire:
    data?.productConfig.featuresV2.billPay?.payablePaymentEbaDomesticWire
      .enabled ?? true,
  payablePaymentEbaInternationalWire:
    data?.productConfig.featuresV2.billPay?.payablePaymentEbaInternationalWire
      .enabled ?? true,
  procurementPurchaseOrdersEnabled:
    data?.productConfig.featuresV2.procurement.purchaseOrders.enabled ?? false,
  aiAssistedReviewEnabled:
    data?.productConfig.featuresV2.expenseManagement.aiAssistedReview.enabled ??
    true,
  brexAssistantEnabled:
    data?.productConfig.featuresV2.expenseManagement.brexAssistant.enabled ??
    true,
  complianceRatingEnabled:
    data?.productConfig.featuresV2.expenseManagement.complianceRating.enabled ??
    true,
  travelAccessManagementEnabled:
    data?.productConfig.featuresV2.travel?.travelAccessManagement.enabled ??
    false,
  premiumSupportEnabled:
    data?.productConfig.featuresV2.support.premiumSupport.enabled ?? true,
  customCardExpensesApprovalChainsEnabled:
    data?.productConfig.featuresV2.expenseManagement
      .customCardExpensesApprovalChains.enabled ?? true,
  bulkBudgetManagementEnabled:
    data?.productConfig.featuresV2.expenseManagement.bulkBudgetManagement
      .enabled ?? true,
  bulkInviteEnabled:
    data?.productConfig.featuresV2.expenseManagement.bulkInvite.enabled ?? true,
  autoInviteSettingsEnabled:
    data?.productConfig.featuresV2.expenseManagement.autoInviteSettings
      .enabled ?? true,
  aiSmartFilteringEnabled:
    data?.productConfig.featuresV2.expenseManagement.aiSmartFiltering.enabled ??
    false,
  multipleCurrencyPolicyEnabled:
    data?.productConfig.featuresV2.expenseManagement.multipleCurrencyPolicy
      .enabled ?? false,
  addEntitiesUnlimitedEnabled:
    data?.productConfig.featuresV2.multiEntity.addEntitiesUnlimited.enabled ??
    false,
  addEntitiesRestrictedEnabled:
    data?.productConfig.featuresV2.multiEntity.addEntitiesRestricted.enabled ??
    false,
  locallyBilledCountriesUnlimitedEnabled:
    data?.productConfig.featuresV2.multiEntity.locallyBilledCountriesUnlimited
      .enabled ?? false,
  locallyBilledCountriesLimitedEnabled:
    data?.productConfig.featuresV2.multiEntity.locallyBilledCountriesLimited
      .enabled ?? false,
  locallyBilledCountriesRestrictedEnabled:
    data?.productConfig.featuresV2.multiEntity.locallyBilledCountriesRestricted
      .enabled ?? false,
  usdBillingEnabled:
    data?.productConfig.featuresV2.multiEntity.usdBilling.enabled ?? false,
  accountingSuggestionsEnabled:
    data?.productConfig.featuresV2.expenseManagement.accountingSuggestions
      .enabled ?? false,
  accountingAccrualDateEnabled:
    data?.productConfig.featuresV2.expenseManagement.accountingAccrualDate
      .enabled ?? false,
  entitySpendLimitOverrideControlsEnabled:
    data?.productConfig.featuresV2.expenseManagement
      .entitySpendLimitOverrideControls.enabled ?? false,
  auditLogsEnabled:
    data?.productConfig.featuresV2.audit.auditLogs.enabled ?? false,
  spendLimitsAdvancedTargetingEnabled:
    data?.productConfig.featuresV2.expenseManagement
      .spendLimitsAdvancedTargeting.enabled ?? false,
  userLimitEnabled:
    data?.productConfig.featuresV2.expenseManagement.userSpendLimit.enabled ??
    false,
  advancedApprovalChainsEnabled:
    data?.productConfig.featuresV2.expenseManagement.advancedApprovalChains
      .enabled ?? false,
  walletAccessEnabled:
    data?.productConfig.featuresV2.expenseManagement.walletAccess.enabled ??
    false,
  metricsAccessEnabled:
    data?.productConfig.featuresV2.expenseManagement.metricsAccess.enabled ??
    false,
  cardsAndLimitsAccessEnabled:
    data?.productConfig.featuresV2.expenseManagement.cardsAndLimitsAccess
      .enabled ?? false,
  reportsAccessEnabled:
    data?.productConfig.featuresV2.expenseManagement.reportsAccess.enabled ??
    false,
  accountingAccessEnabled:
    data?.productConfig.featuresV2.expenseManagement.accountingAccess.enabled ??
    false,
  globalSearchEnabled:
    data?.productConfig.featuresV2.platform.globalSearch.enabled ?? false,
  copilotEnabled:
    data?.productConfig.featuresV2.platform.copilot.enabled ?? false,
  accountSetupTasksEnabled:
    data?.productConfig.featuresV2.platform.accountSetupTasks.enabled ?? false,
});
