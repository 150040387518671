import { atom, useAtom, useAtomValue } from "jotai";
import * as React from "react";
import { useLocation } from "react-router-dom";

const isFirstLoadAtom = atom<Boolean>(true);

export const useSetFirstLoadAtom = () => {
  const [isFirstLoad, setIsFirstLoad] = useAtom(isFirstLoadAtom);
  const location = useLocation();

  React.useEffect(() => {
    // State determines if an actual navigation has happened (or if it's a non-useNavigate navigation)
    if (location.state !== null) {
      // Set a datadog global context for logging purposes
      setIsFirstLoad(false);
    }
  }, [location, isFirstLoad, setIsFirstLoad]);
};

export const useIsFirstLoad = () => useAtomValue(isFirstLoadAtom);
