import { useAtomValue } from "jotai";
import * as React from "react";
import {
  TravelResourceAttribute,
  ResourceAction,
} from "@/__generated__/globalTypes";
import { resourceOperationMappingAtom } from "@/domains/App/features/Permissions/atoms/resourceOperationsQueryAtoms";

export const useTravelResourceOperations = () => {
  const { travel } = useAtomValue(resourceOperationMappingAtom);

  const resourceOperationFlags = React.useMemo(() => {
    const canReadDutyOfCare = travel.hasTravelResourceOperation(
      ResourceAction.READ,
      TravelResourceAttribute.DUTY_OF_CARE,
    );

    const canCreateGroupEvents = travel.hasTravelResourceOperation(
      ResourceAction.CREATE,
      TravelResourceAttribute.GROUP_EVENTS,
    );

    const canReadGroupEvents = travel.hasTravelResourceOperation(
      ResourceAction.READ,
      TravelResourceAttribute.GROUP_EVENTS,
    );

    const canUpdateGroupEvents = travel.hasTravelResourceOperation(
      ResourceAction.UPDATE,
      TravelResourceAttribute.GROUP_EVENTS,
    );

    const canCreatePersonalCardBookings = travel.hasTravelResourceOperation(
      ResourceAction.CREATE,
      TravelResourceAttribute.PERSONAL_CARD_BOOKINGS,
    );

    const canReadReporting = travel.hasTravelResourceOperation(
      ResourceAction.READ,
      TravelResourceAttribute.REPORTING,
    );

    const canReadTrips = travel.hasTravelResourceOperation(
      ResourceAction.READ,
      TravelResourceAttribute.TRIPS,
    );

    const canListTrips = travel.hasTravelResourceOperation(
      ResourceAction.LIST,
      TravelResourceAttribute.TRIPS,
    );

    const canCreateTrips = travel.hasTravelResourceOperation(
      ResourceAction.CREATE,
      TravelResourceAttribute.TRIPS,
    );

    const canUpdateTrips = travel.hasTravelResourceOperation(
      ResourceAction.UPDATE,
      TravelResourceAttribute.TRIPS,
    );

    const canManageTrips = travel.hasTravelResourceOperation(
      ResourceAction.READ,
      TravelResourceAttribute.TRIPS_MANAGE,
    );

    return {
      canReadDutyOfCare,
      canCreateGroupEvents,
      canReadGroupEvents,
      canUpdateGroupEvents,
      canCreatePersonalCardBookings,
      canReadReporting,
      canReadTrips,
      canListTrips,
      canCreateTrips,
      canUpdateTrips,
      canManageTrips,
    };
  }, [travel]);

  return resourceOperationFlags;
};
