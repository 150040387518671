import { useCallback, useState } from "react";
import { createMockableHook } from "@/helpers/createMockableHook";
import useQueryParams from "@/hooks/useQueryParams";

const useToggle = (initial = false) => {
  const [on, setOn] = useState(initial);
  const turnOn = useCallback(() => setOn(true), []);
  const turnOff = useCallback(() => setOn(false), []);
  const reset = useCallback(() => setOn(initial), [initial]);
  const toggle = useCallback(() => setOn((prev) => !prev), []);

  return {
    on,
    turnOn,
    turnOff,
    reset,
    toggle,
  };
};

const [useMockableToggle, MockProvider] = createMockableHook(useToggle);

export default useMockableToggle;
export const MockToggleProvider = MockProvider;

type UseToggleQueryOptions = {
  removeOnTurnOff?: boolean;
};

export const useToggleQuery = (
  param: string,
  options?: UseToggleQueryOptions,
) => {
  const { pushQueryParam, queryParams, pushRemoveQueryParam } =
    useQueryParams();

  const turnOn = useCallback(() => {
    pushQueryParam(param, true);
  }, [pushQueryParam, param]);

  const turnOff = useCallback(() => {
    pushQueryParam(param, false);
    if (options?.removeOnTurnOff) {
      pushRemoveQueryParam(param);
    }
  }, [pushQueryParam, param, options, pushRemoveQueryParam]);

  const on = queryParams[param] === "true";

  return {
    on,
    turnOn,
    turnOff,
  };
};
