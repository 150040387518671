import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "cash.account.close": false,
  "cash.account.create": false,
  "cash.account.details": false,
  "cash.accountClosedBanner.read": false,
  "cash.accountHoldings.read": false,
  "cash.accountSuspendedBanner.read": false,
  "cash.cashUpsell.read": false,
  "cash.connectPayroll.write": false,
  "cash.depositsBookTransfer.edit": false,
  "cash.funds.add": false,
  "cash.invoices.read": false,
  "cash.lowCashBalanceNotification.write": false,
  "cash.navigation.read": false,
  "cash.offboarding.read": false,
  "cash.otherUsersPayments.edit": false,
  "cash.otherUsersTransactions.edit": false,
  "cash.payments.approve": false,
  "cash.payments.managePolicy": false,
  "cash.payments.send": false,
  "cash.payroll.read": false,
  "cash.positivePaySettings.edit": false,
  "cash.reverseWire.read": false,
  "cash.send.approvalRequired": false,
  "cash.statements.read": false,
  "cash.transactionStatusNotification.write": false,
  "cash.transactions.read": false,
  "cash.upsellAndOnboarding.read": true,
  "cash.vendors.edit": false,
  "cash.vendors.read": false,
  "cash.verificationLetter.download": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {} as PartialPermissions,
  [UserRole.BOOKKEEPER]: {
    "cash.vendors.read": true,
  } as PartialPermissions,
  [UserRole.ADMIN]: {
    "cash.cashUpsell.read": true,
    "cash.vendors.edit": true,
    "cash.vendors.read": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {
    "cash.account.details": true,
    "cash.invoices.read": true,
    "cash.navigation.read": true,
    "cash.payments.send": true,
    "cash.positivePaySettings.edit": true,
    "cash.send.approvalRequired": true,
    "cash.statements.read": true,
    "cash.transactions.read": true,
    "cash.vendors.edit": true,
    "cash.vendors.read": true,
  } as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {
    "cash.account.details": true,
    "cash.invoices.read": true,
    "cash.navigation.read": true,
    "cash.referralsTopNav.read": true,
    "cash.statements.read": true,
    "cash.transactions.read": true,
    "cash.vendors.read": true,
  } as PartialPermissions,
  [DepositsRole.ADMIN]: {
    "cash.account.close": true,
    "cash.account.create": true,
    "cash.account.details": true,
    "cash.accountClosedBanner.read": true,
    "cash.accountHoldings.read": true,
    "cash.accountSuspendedBanner.read": true,
    "cash.connectPayroll.write": true,
    "cash.depositsBookTransfer.edit": true,
    "cash.funds.add": true,
    "cash.invoices.read": true,
    "cash.lowCashBalanceNotification.write": true,
    "cash.navigation.read": true,
    "cash.offboarding.read": true,
    "cash.otherUsersPayments.edit": true,
    "cash.otherUsersTransactions.edit": true,
    "cash.payments.approve": true,
    "cash.payments.managePolicy": true,
    "cash.payments.send": true,
    "cash.payroll.read": true,
    "cash.positivePaySettings.edit": true,
    "cash.reverseWire.read": true,
    "cash.rewardsNavSection.read": true,
    "cash.statements.read": true,
    "cash.transactionStatusNotification.write": true,
    "cash.transactions.read": true,
    "cash.vendors.edit": true,
    "cash.vendors.read": true,
    "cash.verificationLetter.download": true,
  } as PartialPermissions,
  null: {} as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {} as PartialPermissions,
  true: {} as PartialPermissions,
};

// permissionsEligibleForCopilot determines what permissions are eligible for use in Copilot mode.
// Please use caution around what should be set to true and see go/td-wiki for details.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "cash.account.close": false,
  "cash.account.create": false,
  "cash.account.details": false,
  "cash.accountClosedBanner.read": false,
  "cash.accountHoldings.read": false,
  "cash.accountSuspendedBanner.read": false,
  "cash.cashUpsell.read": false,
  "cash.connectPayroll.write": false,
  "cash.depositsBookTransfer.edit": false,
  "cash.funds.add": false,
  "cash.invoices.read": false,
  "cash.lowCashBalanceNotification.write": false,
  "cash.navigation.read": false,
  "cash.offboarding.read": false,
  "cash.otherUsersPayments.edit": false,
  "cash.otherUsersTransactions.edit": false,
  "cash.payments.approve": false,
  "cash.payments.managePolicy": false,
  "cash.payments.send": false,
  "cash.payroll.read": false,
  "cash.positivePaySettings.edit": false,
  "cash.reverseWire.read": false,
  "cash.send.approvalRequired": false,
  "cash.statements.read": false,
  "cash.transactionStatusNotification.write": false,
  "cash.transactions.read": false,
  "cash.upsellAndOnboarding.read": false,
  "cash.vendors.edit": false,
  "cash.vendors.read": false,
  "cash.verificationLetter.download": false,
};
