import i18nIsoCountries from "i18n-iso-countries";
import type { Address } from "@/components/AddressForm/sharedTypes";
/**
 * This regex matches any input that includes any variations of post + office + box.
 * It prohibits such input regardless of letter case, with an optional space between po and box, and optional periods following p and o.
 * This does NOT include the preceding or trailing numbers because users were splitting this between the two address lines to evade the regex.
 */

export const poBoxRegex =
  /(^|\s+)(((p|post)[-.\s]*(o|0|off|office)[-.\s]*(b|box|bin)[-.\s]*)|((p|post)[-.\s]*(o|0|off|office)[-.\s]*)$|((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?(\s+|$)/i;
export const nonPoBoxRegex =
  /^(?!(^|\s+)(((p|post)[-.\s]*(o|0|off|office)[-.\s]*(b|box|bin)[-.\s]*)|((p|post)[-.\s]*(o|0|off|office)[-.\s]*)|((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?(\s+|$)).*/i;
export const zipCodeRegex = /^[\d]{5}$/;

export const asciiRegex = /^[\x00-\xFF]*$/;

/**
 * RegEx check to see if the email is valid or not
 */
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Regex for extracting emails from long texts. This is a simple regex that captures all the strings that look like emails.
 * It is a good idea the validate each extracted email using the more complex emailRegex above.
 */
export const emailExtractionRegex =
  /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

export const formatAddress = (
  address: {
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zip?: string | null;
  } | null,
) => {
  if (!address || !address.address1) {
    return "";
  }
  const { address1, address2, city, state, zip } = address;
  const formattedAddress2 = address2 ? `, ${address2}` : "";
  const formattedCity = city ? `, ${city}` : "";
  const formattedState = state ? `, ${state}` : "";
  const formattedZip = zip ? ` ${zip}` : "";
  return `${address1}${formattedAddress2}${formattedCity}${formattedState}${formattedZip}`;
};

// Set initial country to US if not set
// also handles 'USA', since some of our partners will prefill it incorrectly
// Ideally, this validation and fixup should happen in the Partner API, not on form initialization
function getInitialCountry(prefilledCountry?: string) {
  if (
    prefilledCountry === undefined ||
    prefilledCountry === null ||
    prefilledCountry === "" ||
    prefilledCountry === "USA"
  ) {
    return "US";
  }
  return prefilledCountry;
}
type Nullable<T> = { [K in keyof T]: T[K] | null };
/**
 * Normalizes a prefilled address
 */
export function parseInitialAddress(address?: Nullable<Address> | null) {
  return {
    ...address,
    address1: address?.address1 || "",
    address2: address?.address2 || "",
    city: address?.city || "",
    state: address?.state || "",
    zip: address?.zip || "",
    country: getInitialCountry(address?.country || ""),
  };
}

export function getCountryDisplayName(countryCode: string) {
  const lookupMap = i18nIsoCountries.getNames("EN");
  return lookupMap[countryCode] || countryCode;
}
