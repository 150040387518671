import { atom } from "jotai";

/**
 * This helps us determine if the async survey popover container should be shown on the UI
 * We use this flag to prevent us from creating a new Qualtrics iframe instance
 * until the popover is closed.
 */
export const isAsyncQualtricsSurveysIframeLoadedAtom = atom<boolean>(false);

/**
 * On load we are immediately start loading the Qualtrics script to check if the user has any
 * eligible surveys. This flag is set back to true on location change
 */
export const isAsyncQualtricsSurveysIframeLoadingAtom = atom<boolean>(true);

/**
 * The Qualtrics iframe code should always run on load, but then only re-run under certain
 * conditions. This flag controls reload when those conditions are met.
 */
export const shouldRunQualtricsIframeAtom = atom<boolean>(true);
