import {
  StatusMessage,
  StatusMessageDirection,
} from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import styled from "@emotion/styled";
import * as React from "react";
import InsufficientPermissionsImage from "@assets/illustrations/empower/spot/insufficient-permissions.png";

const Wrapper = styled.div({
  display: "flex",
  justifyContent: "center",
});

export type PermissionErrorProps = {
  direction?: StatusMessageDirection;
};

export const PermissionError: React.FC<PermissionErrorProps> = ({
  direction = StatusMessageDirection.VERTICAL,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StatusMessage
        direction={direction}
        graphic={InsufficientPermissionsImage}
        title={t("you-dont-have-permissions-to-view-this-page")}
        description={t("contact-your-brex-admin-to-get-access")}
      />
    </Wrapper>
  );
};
