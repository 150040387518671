import React from "react";
import { UserRole } from "@/__generated__/globalTypes";
import { useUserRole } from "@/features/Authentication/contexts/UserProperties";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";

export const useIsLocalizationEnabledForUser = () => {
  const { languageSwitcher } = useFeatureFlags();

  const userRole = useUserRole();
  const isEmployee = React.useMemo(
    () =>
      !!userRole &&
      [UserRole.EMPLOYEE, UserRole.EMPLOYEE_WITHOUT_CORP_CARDS].includes(
        userRole,
      ),
    [userRole],
  );

  return { isLocalizationEnabledForUser: languageSwitcher && isEmployee };
};
