import type { IntegrationVendors } from "./sharedTypes";
import type {
  BillPayIntegrationModalPayload,
  BillPayIntegrationPayload,
  BillPayResyncPayload,
} from "@/domains/Cash/features/AccountsPayable/sharedTypes";
import type { PayrollModalFlows } from "@/domains/Cash/features/PayrollModal/sharedTypes";
import type { IntegrationStatusQuery_integration_Integration as Integration } from "@/domains/Integrations/data/__generated__/queries.generated";
import type { RefreshIntegration_refreshIntegration_RefreshIntegrationPayload_integration_Integration as RefreshIntegration } from "@/domains/Integrations/features/IntegrationDetails/data/__generated__/mutations.generated";

export enum AnalyticsEvents {
  BrowserExtIntegrationClicked = "integrations.browser_ext_integration.clicked",
  GustoIntegrationClicked = "payroll.gusto_integration.clicked",
  IntegrationsAnnouncementClick = "integrations.announcement.clicked_learn_more",
  IntegrationsConnectIntegrationStart = "integrations.connect_integration.start",
  IntegrationsConnectIntegrationSuccess = "integrations.connect_integration.success",
  IntegrationsConnectIntegrationFailure = "integrations.connect_integration.failure",
  IntegrationsConnectNetsuiteMounted = "integrations.connect_netsuite.mounted",
  IntegrationsConnectNetsuiteSetupStarted = "integrations.connect_netsuite.setup.started",
  IntegrationsERPConnectButtonClicked = "integrations.erp.connect.button.clicked",
  IntegrationsSetupStart = "integrations.setup.start",
  IntegrationsSetupFailure = "integrations.setup.failure",
  IntegrationsSetupSuccess = "integrations.setup.success",
  IntegrationsDetailsBillSyncDisableClick = "integrations.details.disable_bill_sync_clicked", // deprecate
  IntegrationsDetailsBillSyncEnableClick = "integrations.details.enable_bill_sync_clicked", // deprecate
  IntegrationsDetailsDisableAttemptClick = "integrations.details.disable_attempt_clicked",
  IntegrationsDetailsDisableAttemptSuccess = "integrations.details.disable_attempt_success",
  IntegrationsDetailsGlSelectionClick = "integrations.details.gl_selection.clicked",
  IntegrationsDetailsDisableAttemptFailure = "integrations.details.disable_attempt_failure",
  IntegrationsDetailsRefreshAttemptClick = "integrations.details.refresh_attempt_clicked",
  IntegrationsDetailsRefreshAttemptSuccess = "integrations.details.refresh_attempt_success",
  IntegrationsDetailsRefreshAttemptFailure = "integrations.details.refresh_attempt_failure",
  IntegrationsDeveloperAccessPortalAcceptanceError = "integrations.developer_access_portal.acceptance_error",
  IntegrationsDeveloperAccessPortalAcceptanceSuccess = "integrations.developer_access_portal.acceptance_success",
  IntegrationsFinishSetupModalClicked = "integrations.finish_setup_modal.clicked",
  IntegrationsFinishSetupModalDismissed = "integrations.finish_setup_modal.dismissed",
  IntegrationsFinishSetupModalMounted = "integrations.finish_setup_modal.mounted",
  IntegrationsFinishSetupModalSubscriptionIssueClicked = "integrations.finish_setup_modal_subscription_issue.clicked",
  IntegrationsFinishSetupModalSubscriptionIssueMounted = "integrations.finish_setup_modal_subscription_issue.mounted",
  IntegrationsMappingCategoriesClosed = "integrations.mapping.categories.closed",
  IntegrationsMappingCategoriesOpened = "integrations.mapping.categories.opened",
  IntegrationsMappingCategoriesToErpAccountChanged = "integrations.mapping.categories.to_erp_account.changed",
  IntegrationsMappingSubsidiariesClosed = "integrations.mapping.subsidiaries.closed",
  IntegrationsMappingSubsidiariesOpened = "integrations.mapping.subsidiaries.opened",
  IntegrationsMappingSubsidiariesToErpSubsidiaryChanged = "integrations.mapping.subsidiaries.to_erp_subsidiary.changed",
  IntegrationsMappingSubsidiariesAddRuleClicked = "integrations.mapping.subsidiaries.add_rule.clicked",
  IntegrationsMappingSubsidiariesDeleteRuleClicked = "integrations.mapping.subsidiaries.delete_rule.clicked",
  IntegrationsMappingCustomRulesAddRuleClicked = "integrations.mapping.custom_rules.add_rule.clicked",
  IntegrationsMappingCustomRulesClosed = "integrations.mapping.custom_rules.closed",
  IntegrationsMappingCustomRulesDeleteRuleCanceled = "integrations.mapping.custom_rules.delete_rule.canceled",
  IntegrationsMappingCustomRulesDeleteRuleClicked = "integrations.mapping.custom_rules.delete_rule.clicked",
  IntegrationsMappingCustomRulesDeleteRuleConfirmed = "integrations.mapping.custom_rules.delete_rule.confirmed",
  IntegrationsMappingCustomRulesEditRuleClicked = "integrations.mapping.custom_rules.edit_rule.clicked",
  IntegrationsMappingCustomRulesOpened = "integrations.mapping.custom_rules.opened",
  IntegrationsMappingDepartmentsClosed = "integrations.mapping.departments.closed",
  IntegrationsMappingDepartmentsOpened = "integrations.mapping.departments.opened",
  IntegrationsMappingDepartmentsToErpDepartmentChanged = "integrations.mapping.departments.to_erp_department.changed",
  IntegrationsMappingLocationsClosed = "integrations.mapping.locations.closed",
  IntegrationsMappingLocationsOpened = "integrations.mapping.locations.opened",
  IntegrationsMappingLocationsToErpLocationChanged = "integrations.mapping.locations.to_erp_location.changed",
  IntegrationsMappingMerchantsAddRuleClicked = "integrations.mapping.merchants.add_rule.clicked",
  IntegrationsMappingMerchantsClosed = "integrations.mapping.merchants.closed",
  IntegrationsMappingMerchantsDeleteRuleClicked = "integrations.mapping.merchants.delete_rule.clicked",
  IntegrationsMappingMerchantsFromMerchantChanged = "integrations.mapping.merchants.from_merchant.changed",
  IntegrationsMappingMerchantsOpened = "integrations.mapping.merchants.opened",
  IntegrationsMappingMerchantsToErpAccountChanged = "integrations.mapping.merchants.to_erp_account.changed",
  IntegrationsMappingMounted = "integrations.mapping.mounted",
  IntegrationsMappingVendorsAddRuleClicked = "integrations.mapping.vendors.add_rule.clicked",
  IntegrationsMappingVendorsClosed = "integrations.mapping.vendors.closed",
  IntegrationsMappingVendorsDeleteRuleClicked = "integrations.mapping.vendors.delete_rule.clicked",
  IntegrationsMappingVendorsFromMerchantChanged = "integrations.mapping.vendors.from_merchant.changed",
  IntegrationsMappingVendorsOpened = "integrations.mapping.vendors.opened",
  IntegrationsMappingVendorsToErpVendorChanged = "integrations.mapping.vendors.to_erp_vendor.changed",
  IntegrationsModalStepErrorMounted = "integrations.modal_step_error.mounted",
  IntegrationsModalStepSyncBillsAndExpensesMounted = "integrations.modal_step_sync_bills_and_expenses.mounted",
  IntegrationsQboCredentialIssueModalClosed = "integrations.qbo_credential_issue_modal.closed",
  IntegrationsQboCredentialIssueModalDisableBillSyncFailure = "integrations.qbo_credential_issue_modal_disable_bill_sync.failure",
  IntegrationsQboCredentialIssueModalDisableBillSyncSuccess = "integrations.qbo_credential_issue_modal_disable_bill_sync.success",
  IntegrationsQboCredentialIssueModalErrorMounted = "integrations.qbo_credential_issue_modal_error.mounted",
  IntegrationsQboCredentialIssueModalGoToQboClicked = "integrations.qbo_credential_issue_modal_go_to_qbo.clicked",
  IntegrationsQboCredentialIssueModalMounted = "integrations.qbo_credential_issue_modal.mounted",
  IntegrationsQboCredentialIssueModalResyncFailure = "integrations.qbo_credential_issue_modal_resync.failure",
  IntegrationsQboCredentialIssueModalResyncSuccess = "integrations.qbo_credential_issue_modal_resync.success",
  IntegrationsQboMigrationModalMounted = "integrations.quickbooks_migration_modal.mounted",
  IntegrationsQboSetupModalBankFeedNextClicked = "integrations.qbo_setup_modal_bank_feed_next.clicked",
  IntegrationsQboSetupModalBankFeedSetupClicked = "integrations.qbo_setup_modal_bank_feed_setup.clicked",
  IntegrationsQboSetupModalBillPayIntroClicked = "integrations.qbo_setup_modal_bill_pay_intro.clicked",
  IntegrationsQboSetupModalDismissed = "integrations.qbo_setup_modal.dismissed",
  IntegrationsQboSetupModalExpensesAndBillsClicked = "integrations.qbo_setup_modal_expenses_and_bills.clicked",
  IntegrationsQboSetupModalExpensesClicked = "integrations.qbo_setup_modal_expenses.clicked",
  IntegrationsQboSetupModalMounted = "integrations.qbo_setup_modal.mounted",
  IntegrationsRedirect = "integrations.redirected-legacy",
  IntegrationsReviewAndExportCollectionsAndRewardsExportMounted = "integrations.review_and_export.collections_and_rewards.export.mounted",
  IntegrationsStartExportClicked = "integrations.start.export.clicked",
  IntegrationsExportSuccess = "integrations.export.success",
  IntegrationsExportFailure = "integrations.export.failure",
  IntegrationsReviewAndExportCollectionsAndRewardsMounted = "integrations.review_and_export.collections_and_rewards.mounted",
  IntegrationsReviewAndExportExpensesAndReimbursementsCreateNewRuleClicked = "integrations.review_and_export.expenses_and_reimbursements.create_new_rule.clicked",
  IntegrationsReviewAndExportExpensesAndReimbursementsExportMounted = "integrations.review_and_export.expenses_and_reimbursements.export.mounted",
  IntegrationsReviewAndExportExpensesAndReimbursementsMounted = "integrations.review_and_export.expenses_and_reimbursements.mounted",
  IntegrationsSubscriptionIssueModalDisableBillSyncFailure = "integrations.subscription_issue_modal_disable_sync.failure",
  IntegrationsSubscriptionIssueModalDisableBillSyncSuccess = "integrations.subscription_issue_modal_disable_sync.success",
  IntegrationsSubscriptionIssueModalDismissed = "integrations.subscription_issue_modal.dismissed",
  IntegrationsSubscriptionIssueModalErrorMounted = "integrations.subscription_issue_modal_error.mounted",
  IntegrationsSubscriptionIssueModalMounted = "integrations.subscription_issue_modal.mounted",
  IntegrationsSubscriptionIssueModalResyncFailure = "integrations.subscription_issue_modal_resync.failure",
  IntegrationsSubscriptionIssueModalResyncSuccess = "integrations.subscription_issue_modal_resync.success",
  IntegrationsSubscriptionIssueModalUpgradeClicked = "integrations.subscription_issue_modal.upgrade_clicked",
  IntegrationsUniversalCsvManageClicked = "integrations.universal_csv.manage.clicked",
  IntegrationsXeroRefreshScopeErrorBannerMounted = "integrations.xero_refresh_scope_error_banner.mounted",
  IntegrationsXeroSetupModalExpensesAndBillsClicked = "integrations.xero_setup_modal_expenses_and_bills.clicked",
}

type EmptyPayload = {};

type IntegrationPayload = {
  integrationId?: string;
  integrationVendor: string;
};

export type IntegrationExportPayload = IntegrationPayload & {
  exportType: string;
  isRewardCollectionView: boolean;
};

type IntegrationRulePayload = IntegrationPayload & {
  ruleId: string;
};

type IntegrationMappingPayload = IntegrationRulePayload & {
  mapFromId: string;
  mapToId: string;
};

type GustoIntegrationClickedPayload = {
  type: PayrollModalFlows;
};

type IntegrationsQboSetupModalBankFeedNextClickedPayload = {
  connectionEstablished: boolean;
};

type IntegrationMutationErrorPayload = {
  integrationId?: string;
  message?: string;
  origin?: string;
  vendor?: IntegrationVendors;
};

type IntegrationsGlSelectionPayload = {
  type: string;
};

type RefreshPayload = {
  integrationId?: string;
  startTimestamp?: number;
  completeTimestamp?: number;
  errorMessage?: string;
  errorDetails?:
    | RefreshIntegration["errorDetails"]
    | Integration["errorDetails"];
};

type DisablePayload = RefreshPayload;

type BrowserPayload = {
  browser: "chrome"; // extend with more browsers as needed
};

export type EventSchemaMap = {
  [AnalyticsEvents.BrowserExtIntegrationClicked]: BrowserPayload;
  [AnalyticsEvents.GustoIntegrationClicked]: GustoIntegrationClickedPayload;
  [AnalyticsEvents.IntegrationsAnnouncementClick]: EmptyPayload;
  [AnalyticsEvents.IntegrationsConnectIntegrationStart]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsConnectIntegrationSuccess]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsConnectIntegrationFailure]: IntegrationMutationErrorPayload;
  [AnalyticsEvents.IntegrationsConnectNetsuiteMounted]: EmptyPayload;
  [AnalyticsEvents.IntegrationsConnectNetsuiteSetupStarted]: EmptyPayload;
  [AnalyticsEvents.IntegrationsERPConnectButtonClicked]: EmptyPayload;
  [AnalyticsEvents.IntegrationsSetupStart]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsSetupFailure]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsSetupSuccess]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsDetailsBillSyncDisableClick]: BillPayIntegrationPayload; // deprecate
  [AnalyticsEvents.IntegrationsDetailsBillSyncEnableClick]: BillPayIntegrationPayload; // deprecate
  [AnalyticsEvents.IntegrationsDetailsDisableAttemptClick]: DisablePayload;
  [AnalyticsEvents.IntegrationsDetailsDisableAttemptSuccess]: DisablePayload;
  [AnalyticsEvents.IntegrationsDetailsGlSelectionClick]: IntegrationsGlSelectionPayload;
  [AnalyticsEvents.IntegrationsDetailsDisableAttemptFailure]: DisablePayload;
  [AnalyticsEvents.IntegrationsDetailsRefreshAttemptClick]: RefreshPayload;
  [AnalyticsEvents.IntegrationsDetailsRefreshAttemptSuccess]: RefreshPayload;
  [AnalyticsEvents.IntegrationsDetailsRefreshAttemptFailure]: RefreshPayload;
  [AnalyticsEvents.IntegrationsDeveloperAccessPortalAcceptanceError]: EmptyPayload;
  [AnalyticsEvents.IntegrationsDeveloperAccessPortalAcceptanceSuccess]: EmptyPayload;
  [AnalyticsEvents.IntegrationsFinishSetupModalClicked]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsFinishSetupModalDismissed]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsFinishSetupModalMounted]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsFinishSetupModalSubscriptionIssueClicked]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsFinishSetupModalSubscriptionIssueMounted]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingCategoriesClosed]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingCategoriesOpened]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingCategoriesToErpAccountChanged]: IntegrationMappingPayload;
  [AnalyticsEvents.IntegrationsMappingSubsidiariesClosed]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingSubsidiariesOpened]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingSubsidiariesToErpSubsidiaryChanged]: IntegrationMappingPayload;
  [AnalyticsEvents.IntegrationsMappingSubsidiariesAddRuleClicked]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingSubsidiariesDeleteRuleClicked]: IntegrationRulePayload;
  [AnalyticsEvents.IntegrationsMappingCustomRulesAddRuleClicked]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingCustomRulesClosed]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingCustomRulesDeleteRuleCanceled]: IntegrationRulePayload;
  [AnalyticsEvents.IntegrationsMappingCustomRulesDeleteRuleClicked]: IntegrationRulePayload;
  [AnalyticsEvents.IntegrationsMappingCustomRulesDeleteRuleConfirmed]: IntegrationRulePayload;
  [AnalyticsEvents.IntegrationsMappingCustomRulesEditRuleClicked]: IntegrationRulePayload;
  [AnalyticsEvents.IntegrationsMappingCustomRulesOpened]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingDepartmentsClosed]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingDepartmentsOpened]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingDepartmentsToErpDepartmentChanged]: IntegrationMappingPayload;
  [AnalyticsEvents.IntegrationsMappingLocationsClosed]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingLocationsOpened]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingLocationsToErpLocationChanged]: IntegrationMappingPayload;
  [AnalyticsEvents.IntegrationsMappingMerchantsAddRuleClicked]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingMerchantsClosed]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingMerchantsDeleteRuleClicked]: IntegrationRulePayload;
  [AnalyticsEvents.IntegrationsMappingMerchantsFromMerchantChanged]: IntegrationMappingPayload;
  [AnalyticsEvents.IntegrationsMappingMerchantsOpened]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingMerchantsToErpAccountChanged]: IntegrationMappingPayload;
  [AnalyticsEvents.IntegrationsMappingMounted]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingVendorsAddRuleClicked]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingVendorsClosed]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingVendorsDeleteRuleClicked]: IntegrationRulePayload;
  [AnalyticsEvents.IntegrationsMappingVendorsFromMerchantChanged]: IntegrationMappingPayload;
  [AnalyticsEvents.IntegrationsMappingVendorsOpened]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsMappingVendorsToErpVendorChanged]: IntegrationMappingPayload;
  [AnalyticsEvents.IntegrationsModalStepErrorMounted]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsModalStepSyncBillsAndExpensesMounted]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsQboCredentialIssueModalClosed]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsQboCredentialIssueModalDisableBillSyncFailure]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsQboCredentialIssueModalDisableBillSyncSuccess]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsQboCredentialIssueModalErrorMounted]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsQboCredentialIssueModalGoToQboClicked]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsQboCredentialIssueModalMounted]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsQboCredentialIssueModalResyncFailure]: BillPayResyncPayload;
  [AnalyticsEvents.IntegrationsQboCredentialIssueModalResyncSuccess]: BillPayResyncPayload;
  [AnalyticsEvents.IntegrationsQboMigrationModalMounted]: EmptyPayload;
  [AnalyticsEvents.IntegrationsQboMigrationModalMounted]: EmptyPayload;
  [AnalyticsEvents.IntegrationsQboSetupModalBankFeedNextClicked]: IntegrationsQboSetupModalBankFeedNextClickedPayload;
  [AnalyticsEvents.IntegrationsQboSetupModalBankFeedSetupClicked]: EmptyPayload;
  [AnalyticsEvents.IntegrationsQboSetupModalBillPayIntroClicked]: EmptyPayload;
  [AnalyticsEvents.IntegrationsQboSetupModalDismissed]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsQboSetupModalExpensesAndBillsClicked]: EmptyPayload;
  [AnalyticsEvents.IntegrationsQboSetupModalExpensesClicked]: EmptyPayload;
  [AnalyticsEvents.IntegrationsQboSetupModalMounted]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsRedirect]: EmptyPayload;
  [AnalyticsEvents.IntegrationsReviewAndExportCollectionsAndRewardsExportMounted]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsStartExportClicked]: IntegrationExportPayload;
  [AnalyticsEvents.IntegrationsExportSuccess]: IntegrationExportPayload;
  [AnalyticsEvents.IntegrationsExportFailure]: IntegrationExportPayload;
  [AnalyticsEvents.IntegrationsReviewAndExportCollectionsAndRewardsMounted]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsReviewAndExportExpensesAndReimbursementsCreateNewRuleClicked]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsReviewAndExportExpensesAndReimbursementsExportMounted]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsReviewAndExportExpensesAndReimbursementsMounted]: IntegrationPayload;
  [AnalyticsEvents.IntegrationsSubscriptionIssueModalDisableBillSyncFailure]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsSubscriptionIssueModalDisableBillSyncSuccess]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsSubscriptionIssueModalDismissed]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsSubscriptionIssueModalErrorMounted]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsSubscriptionIssueModalMounted]: BillPayIntegrationModalPayload;
  [AnalyticsEvents.IntegrationsSubscriptionIssueModalResyncFailure]: BillPayResyncPayload;
  [AnalyticsEvents.IntegrationsSubscriptionIssueModalResyncSuccess]: BillPayResyncPayload;
  [AnalyticsEvents.IntegrationsSubscriptionIssueModalUpgradeClicked]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsUniversalCsvManageClicked]: EmptyPayload;
  [AnalyticsEvents.IntegrationsXeroRefreshScopeErrorBannerMounted]: BillPayIntegrationPayload;
  [AnalyticsEvents.IntegrationsXeroSetupModalExpensesAndBillsClicked]: EmptyPayload;
};
