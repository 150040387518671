export const createMerchantIconUrl = ({
  format = "png",
  size = 26,
  url,
}: {
  format?: string;
  size?: number;
  url?: string | null;
}) => {
  return url ? `${url}?format=${format}&size=${size}` : url;
};
