import type {
  OwnershipVerificationStatus,
  SelfAttestAccountTypePair,
  SelfAttestationAccountType,
  VerificationStatusType,
  CreditPolicyWarningModalType,
} from "@/__generated__/globalTypes";
import type { MoneyMovementFlowTypes } from "@/features/SelectAccountForTransfer/sharedTypes";
import type { UtilizationPrepayUpsellOrigin } from "@/features/UtilizationPrepayUpsell/sharedTypes";

export enum LimitExperienceAnalyticsEvents {
  AccountsExternalAccountsRowItemClicked = "accounts.external_accounts.clicked",

  SetupUtilizationPrepayFlowState = "limit_experience.setup_utilization_prepay_flow.state",

  CardOverviewNotificationTileScheduledPrepayClicked = "card.card_overview.notification_tile.scheduled_prepay.clicked",
  CardOverviewNotificationTileScheduledPrepayMounted = "card.card_overview.notification_tile.scheduled_prepay.mounted",

  CreditLimitEducationDetailPaneMounted = "credit_limit_education.detail_pane.mounted",

  CreditLimitRequestRfiClicked = "credit_limit_request.rfi.clicked",

  CreditLimitRequestMounted = "credit_limit_request.mounted",
  CreditLimitRequestCanceled = "credit_limit_request.canceled",
  CreditLimitRequestFailed = "credit_limit_request.failed",
  CreditLimitRequestSubmitted = "credit_limit_request.submit",

  CreditTermsLearnMoreClicked = "credit_terms.learn_more.clicked",
  CreditTermsViewDocumentsClicked = "credit_terms.view_documents.clicked",
  CreditTermsExternalAccountsClicked = "credit_terms.external_accounts.clicked",

  POAOMultiAccountSelfAttestationNotShown = "poao.self_attestation.multi.not_shown",
  POAOMultiAccountSelfAttestationViewed = "poao.self_attestation.multi.viewed",
  POAOMultiAccountSelfAttestationComplete = "poao.self_attestation.multi.complete",
  POAOSingleAccountSelfAttestationViewed = "poao.self_attestation.single.viewed",
  POAOSingleAccountSelfAttestationComplete = "poao.self_attestation.single.complete",
  POAOSingleAccountSelfAttestationClosed = "poao.self_attestation.single.closed",
  POAOPollingInitiated = "poao.polling.initiated",
  POAOPollingFinished = "poao.polling.finished",
  POAOPollingTimedOut = "poao.polling.timed_out",

  PendingVerificationAccountAdded = "limit_experience.pending_verification_account.added",
  PendingVerificationAccountSelected = "limit_experience.pending_verification_account.selected",
  PendingVerificationAccountSelectBlocked = "limit_experience.pending_verification_account.select.blocked",

  UtilizationPrepayUpsellMounted = "limit_experience.utilization_prepay_upsell.mounted",
  UtilizationPrepayUpsellSubmitted = "limit_experience.utilization_prepay_upsell.submitted",

  FinancialStatementCollectionUploadButtonClicked = "limit_experience.financial_statement_collection.upload_button.clicked",
  FinancialStatementCollectionMounted = "limit_experience.financial_statement_collection.mounted",
  FinancialStatementCollectionClosed = "limit_experience.financial_statement_collection.closed",
  FinancialStatementCollectionAddDocumentSuccess = "limit_experience.financial_statement_collection.add_document.success",
  FinancialStatementCollectionAddDocumentSkipped = "limit_experience.financial_statement_collection.add_document.skipped",
  FinancialStatementCollectionAddDocumentError = "limit_experience.financial_statement_collection.add_document.error",
  FinancialStatementCollectionSubmitted = "limit_experience.financial_statement_collection.submitted",
  FinancialStatementCollectionExampleFileClicked = "limit_experience.financial_statement_collection.example.clicked",

  ExternalAccountEducationModalShown = "limit_experience.external_account_education_modal.shown",
  ExternalAccountEducationModalClosed = "limit_experience.external_account_education_modal.closed",
  ExternalAccountConnectUnderwritingAccountSelected = "limit_experience.external_account_education_modal.underwriting.selected",
  ExternalAccountConnectMoneyMovementAccountSelected = "limit_experience.external_account_education_modal.money_movement.selected",
  MDPostConnectShown = "limit_experience.micro_deposits.post_connect.shown",
  MDPostConnectClosed = "limit_experience.micro_deposits.post_connect.closed",
  MDPostConnectSkipped = "limit_experience.micro_deposits.post_connect.skipped",
  MDPostConnectAddStatements = "limit_experience.micro_deposits.post_connect.add_statements",
  SettingsExternalAccountsRedirect = "limit_experience.settings.external_accounts_redirect",

  CreditPolicyWarningSeen = "limit_experience.credit_policy_warning.seen",
  CreditPolicyWarningDismissed = "limit_experience.credit_policy_warning.dismissed",
  CreditPolicyWarningAddFundsToBrexCashAccountClicked = "limit_experience.credit_policy_warning.add_funds_to_brex_cash_account.clicked",
  CreditPolicyWarningViewConnectedAccountsClicked = "limit_experience.credit_policy_warning.view_connected_accounts.clicked",
  CreditPolicyWarningStatementUploadClicked = "limit_experience.credit_policy_warning.statement_upload.clicked",
  CreditPolicyWarningStatementUploadSuccess = "limit_experience.credit_policy_warning.statement_upload.success",
  CreditPolicyWarningConnectBankClicked = "limit_experience.credit_policy_warning.connect_bank.clicked",
  CreditPolicyWarningConnectBankSuccess = "limit_experience.credit_policy_warning.connect_bank.success",
}

type EmptyPayload = {};

type NotificationTileScheduledPrepayClicked = {
  callSite: "skip" | "manage";
};

type SetupUtilizationPrepayFlowStatePayload = {
  step: string;
};

type POAOMultiAccountSelfAttestationNotShownPayload = {
  numberOfDepositoryAccounts?: number;
  flowType?: MoneyMovementFlowTypes;
  verificationStatus?: VerificationStatusType;
  financialSourceId: string;
};

type POAOSelfAttestationPayload = {
  selfAttestAccountTypePairs: SelfAttestAccountTypePair[];
};

type POAOSelfAttestationSingleAccountPayload = {
  selfAttestationAccountType?: SelfAttestationAccountType;
  financialAccountId: string;
};

type POAOPollingPayload = {
  financialAccountId: string;
  verificationStatus: OwnershipVerificationStatus | null;
};

type PendingVerificationAccountSelectedPayload = {
  financialAccountId: string;
  isSelfAttestionComplete: boolean;
  isVerificationInProgress: boolean;
  isUnverifiedMicroDepositAccount: boolean;
};

type PendingVerificationAccountAddedPayload = {
  flowType?: MoneyMovementFlowTypes;
  financialAccountId: string;
  verificationStatus: VerificationStatusType;
};

type PendingVerificationAccountSelectBlockedPayload = {
  flowType?: MoneyMovementFlowTypes;
  isOneTimeFlow: boolean;
  isVerificationInProgressOrRejected: boolean;
};

type UtilizationPrepayUpsellMountedPayload = {
  origin: UtilizationPrepayUpsellOrigin;
};

type UtilizationPrepayUpsellSubmittedPayload = {
  origin: UtilizationPrepayUpsellOrigin;
  hasOptedIn: boolean;
};

type FinancialStatementCollectionStepSkippedPayload = {
  skippedStep: string;
};

type FinancialStatementCollectionMountedPayload = {
  source: string;
};

type CreditPolicyWarningPayload = {
  modalType?: CreditPolicyWarningModalType;
};

export type LimitExperienceEventSchemaMap = {
  [LimitExperienceAnalyticsEvents.AccountsExternalAccountsRowItemClicked]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.SetupUtilizationPrepayFlowState]: SetupUtilizationPrepayFlowStatePayload;
  [LimitExperienceAnalyticsEvents.CardOverviewNotificationTileScheduledPrepayClicked]: NotificationTileScheduledPrepayClicked;
  [LimitExperienceAnalyticsEvents.CardOverviewNotificationTileScheduledPrepayMounted]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditLimitEducationDetailPaneMounted]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditLimitRequestRfiClicked]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditLimitRequestMounted]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditLimitRequestCanceled]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditLimitRequestFailed]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditLimitRequestSubmitted]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.POAOMultiAccountSelfAttestationNotShown]: POAOMultiAccountSelfAttestationNotShownPayload;
  [LimitExperienceAnalyticsEvents.POAOMultiAccountSelfAttestationViewed]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.POAOMultiAccountSelfAttestationComplete]: POAOSelfAttestationPayload;
  [LimitExperienceAnalyticsEvents.POAOSingleAccountSelfAttestationViewed]: POAOSelfAttestationSingleAccountPayload;
  [LimitExperienceAnalyticsEvents.POAOSingleAccountSelfAttestationComplete]: POAOSelfAttestationSingleAccountPayload;
  [LimitExperienceAnalyticsEvents.POAOSingleAccountSelfAttestationClosed]: POAOSelfAttestationSingleAccountPayload;
  [LimitExperienceAnalyticsEvents.POAOPollingInitiated]: POAOPollingPayload;
  [LimitExperienceAnalyticsEvents.POAOPollingFinished]: POAOPollingPayload;
  [LimitExperienceAnalyticsEvents.POAOPollingTimedOut]: POAOPollingPayload;
  [LimitExperienceAnalyticsEvents.PendingVerificationAccountAdded]: PendingVerificationAccountAddedPayload;
  [LimitExperienceAnalyticsEvents.PendingVerificationAccountSelected]: PendingVerificationAccountSelectedPayload;
  [LimitExperienceAnalyticsEvents.PendingVerificationAccountSelectBlocked]: PendingVerificationAccountSelectBlockedPayload;
  [LimitExperienceAnalyticsEvents.UtilizationPrepayUpsellMounted]: UtilizationPrepayUpsellMountedPayload;
  [LimitExperienceAnalyticsEvents.UtilizationPrepayUpsellSubmitted]: UtilizationPrepayUpsellSubmittedPayload;
  [LimitExperienceAnalyticsEvents.FinancialStatementCollectionUploadButtonClicked]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.FinancialStatementCollectionMounted]: FinancialStatementCollectionMountedPayload;
  [LimitExperienceAnalyticsEvents.FinancialStatementCollectionClosed]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.FinancialStatementCollectionAddDocumentSuccess]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.FinancialStatementCollectionAddDocumentSkipped]: FinancialStatementCollectionStepSkippedPayload;
  [LimitExperienceAnalyticsEvents.FinancialStatementCollectionAddDocumentError]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.FinancialStatementCollectionSubmitted]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.FinancialStatementCollectionExampleFileClicked]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditTermsLearnMoreClicked]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditTermsViewDocumentsClicked]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditTermsExternalAccountsClicked]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.ExternalAccountEducationModalShown]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.ExternalAccountEducationModalClosed]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.ExternalAccountConnectUnderwritingAccountSelected]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.ExternalAccountConnectMoneyMovementAccountSelected]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.MDPostConnectShown]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.MDPostConnectClosed]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.MDPostConnectSkipped]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.MDPostConnectAddStatements]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.SettingsExternalAccountsRedirect]: EmptyPayload;
  [LimitExperienceAnalyticsEvents.CreditPolicyWarningSeen]: CreditPolicyWarningPayload;
  [LimitExperienceAnalyticsEvents.CreditPolicyWarningDismissed]: CreditPolicyWarningPayload;
  [LimitExperienceAnalyticsEvents.CreditPolicyWarningStatementUploadClicked]: CreditPolicyWarningPayload;
  [LimitExperienceAnalyticsEvents.CreditPolicyWarningAddFundsToBrexCashAccountClicked]: CreditPolicyWarningPayload;
  [LimitExperienceAnalyticsEvents.CreditPolicyWarningStatementUploadSuccess]: CreditPolicyWarningPayload;
  [LimitExperienceAnalyticsEvents.CreditPolicyWarningConnectBankClicked]: CreditPolicyWarningPayload;
  [LimitExperienceAnalyticsEvents.CreditPolicyWarningConnectBankSuccess]: CreditPolicyWarningPayload;
  [LimitExperienceAnalyticsEvents.CreditPolicyWarningViewConnectedAccountsClicked]: CreditPolicyWarningPayload;
};
