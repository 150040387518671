import { OptionInput } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { Text } from "@brexhq/metal-text";
import styled from "@emotion/styled";
import * as React from "react";
import { SUPPORTED_LOCALES } from "@/domains/App/features/Localization/helpers/constants";
import { useChangeLanguage } from "@/domains/App/features/Localization/hooks/useChangeLanguage";

export const Container = styled.div(({ theme }) => ({
  marginleft: theme.spacing[20],
}));

export const UnauthorizedLayoutLanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = useChangeLanguage();

  const languageOptions = React.useMemo(
    () =>
      SUPPORTED_LOCALES.map((languageTag) => ({
        value: languageTag,
        inputLabel: t(`language_${languageTag}`),
        label: <Text variant="BodySmallR">{t(`language_${languageTag}`)}</Text>,
      })),
    [t],
  );

  const handleChangeLanguage = React.useCallback(
    ({ value }) => {
      changeLanguage({ languagePreference: value });
    },
    [changeLanguage],
  );

  return (
    <Container>
      <OptionInput
        name="language-selector"
        options={languageOptions}
        value={i18n.language}
        onChange={handleChangeLanguage}
        size="small"
        aria-label={t("language")}
      />
    </Container>
  );
};
