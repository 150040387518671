import { parse, stringify } from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "@/hooks/useNavigate";
import { useRefCallback } from "@/hooks/useRefCallback";

export type QueryParamDictionary = {
  [x: string]: string | undefined;
};

function mapToUndefined(keys: readonly string[]) {
  const baseObj: QueryParamDictionary = {};
  keys.forEach((key: string) => {
    baseObj[key] = undefined;
  });
  return baseObj;
}

/**
 * Return a set of functions for using and manipulating the browser's query parameters.
 *
 * Each function has a 'push' and a 'replace' version.
 *
 * - Use 'Replace' when working with filters, so that users can still push the back
 *     button to go to the previous page (instead of undoing filter changes)
 *
 * - Use 'Push' when opening/closing take-overs, so that pressing the back button
 *     will close the take-over.
 */
export default () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = useMemo(
    () => parse(search, { ignoreQueryPrefix: true, arrayLimit: 100 }),
    [search],
  ) as { [key: string]: string };

  /**
   * `useRefCallback` is used here to avoid ending up updating query params incorrectly due to stale
   * closures
   *
   * @example
   * const onClick = async () => {
   *   pushQueryParams({ a: 1 });
   *   await something();
   *   pushQueryParams({ b: 2 });
   * };
   *
   * // With `useCallback`, the second call to `pushQueryParams` would have the value of `a` removed,
   * // while with `useRefCallback`, the second call to `pushQueryParams` would work as expected.
   */
  const updatedParams = useRefCallback((values: QueryParamDictionary) => ({
    search: stringify({ ...queryParams, ...values }),
  }));

  const pushQueryParams = useRefCallback((values: QueryParamDictionary) => {
    navigate(updatedParams(values));
  });

  const replaceQueryParams = useRefCallback((values: QueryParamDictionary) => {
    navigate(updatedParams(values), { replace: true });
  });

  const pushQueryParam = useRefCallback((key: string, value: any) => {
    navigate(updatedParams({ [key]: value }));
  });

  const replaceQueryParam = useRefCallback((key: string, value: any) => {
    navigate(updatedParams({ [key]: value }), { replace: true });
  });

  const pushRemoveQueryParam = useRefCallback((key: string) => {
    pushQueryParam(key, undefined);
  });

  const replaceRemoveQueryParam = useRefCallback((key: string) => {
    replaceQueryParam(key, undefined);
  });

  const pushRemoveQueryParams = useRefCallback((keys: readonly string[]) => {
    navigate(updatedParams(mapToUndefined(keys)));
  });

  const replaceRemoveQueryParams = useRefCallback((keys: readonly string[]) => {
    navigate(updatedParams(mapToUndefined(keys)), { replace: true });
  });

  return useMemo(
    () => ({
      /** A dictionary containing the current query parameters */
      queryParams,
      /** Push a URL to the browser history with updated query parameters */
      pushQueryParams,
      /** Replace the current URL with new query parameters */
      replaceQueryParams,
      /** Push a URL to the browser history with an updated query parameter */
      pushQueryParam,
      /** Replace the current URL with an updated query parameter */
      replaceQueryParam,
      /** Push a URL to the browser history with the given query parameter removed */
      pushRemoveQueryParam,
      /** Replace the current URL with one where the given query parameter is removed */
      replaceRemoveQueryParam,
      /** Push a URL to the browser history with the given query parameters removed */
      pushRemoveQueryParams,
      /** Replace the current URL with one where the given query parameters are removed */
      replaceRemoveQueryParams,
      /** @deprecated: use push or replace instead */
      setQueryParam: pushQueryParam,
      /** @deprecated: use push or replace instead */
      setQueryParams: pushQueryParams,
      /** @deprecated: use push or replace instead */
      removeQueryParam: pushRemoveQueryParam,
      /** @deprecated: use push or replace instead */
      removeQueryParams: pushRemoveQueryParams,
    }),
    [
      pushQueryParam,
      pushQueryParams,
      pushRemoveQueryParam,
      pushRemoveQueryParams,
      queryParams,
      replaceQueryParam,
      replaceQueryParams,
      replaceRemoveQueryParam,
      replaceRemoveQueryParams,
    ],
  );
};
