import type {
  CardPaymentFailureTypeV3,
  CountryAlpha2,
  ShippingMethod,
  ShippingStatus,
  CardBudgetOwnershipType,
} from "@/__generated__/globalTypes";
import type {
  PhysicalCardShippingAddressWithName,
  UserIntent,
} from "@/data/sharedTypes";
import type { CardType } from "@/domains/Card/features/ManageCards/sharedTypes";
import type {
  SelectedAddressKey as CreatePhysicalCardV2SelectedAddressKey,
  ExpeditedShippingReason,
  FlowOrigin,
} from "@/features/CreatePhysicalCardV2/sharedTypes";
import type { PaymentType } from "@/features/MakePayment/sharedTypes";
import type { SensitiveDataLegacyResultComparison } from "@/features/SensitiveCardData/sharedTypes";
import type { ClickEventType } from "@/features/TrackCardShipment/sharedTypes";

export enum CardAnalyticsEvents {
  AllCardsSeeMore = "all_cards.see_more",
  AutopayErrorNoticeClicked = "card.autopay_error_notice_clicked",
  AutopayErrorNoticeMounted = "card.autopay_error_notice_mounted",
  CardActionActivate = "card.action.activate",
  CardActionAdminLockComplete = "card.action.admin_lock.complete",
  CardActionLockComplete = "card.action.lock.complete",
  CardActionOpenAdminLock = "card.action.admin_lock",
  CardActionContactSupport = "card.action.contact_support",
  CardActionOpenLock = "card.action.lock",
  CardActionOpenModify = "card.action.modify",
  CardActionOpenRename = "card.action.rename",
  CardActionOpenReplace = "card.action.replace",
  CardActionOpenReplaceWithPhysical = "card.action.replace_with_physical",
  CardActionOpenShippingDetails = "card.action.view_shipping_details",
  CardActionOpenTerminate = "card.action.terminate",
  CardActionOpenUnlock = "card.action.unlock",
  CardActionSetPrimary = "card.action.set_primary",
  CardActionRenameComplete = "card.action.rename.complete",
  CardActionSelectCard = "card.action.select_card",
  CardActionTerminateComplete = "card.action.terminate.complete",
  CardActionUnlockComplete = "card.action.unlock.complete",
  CardActionViewTransactions = "card.action.view_transactions",
  CardAllCardsDetailsMounted = "card.all_cards.details.mounted",
  CardAllCardsFilter = "card.all_cards.filter",
  CardAllCardsPageMounted = "card.all_cards.mounted",
  CardAllCardsSort = "card.all_cards.sort",
  CardChangeTabPageMounted = "card.tab.changed",
  CardCopyCvv = "card.copy.cvv",
  CardCopyExpirationDate = "card.copy.expiration_date",
  CardCopyFailed = "card.copy.failed",
  CardCopyPan = "card.copy.pan",
  CardCreateCorporatePhysicalCard = "card.create.corporate.physical",
  CardCreateCorporatePhysicalCardComplete = "card.create.corporate.physical.complete",
  CardCreateCorporateVirtualCard = "card.create.corporate.virtual",
  CardCreateCorporateVirtualCardComplete = "card.create.corporate.virtual.complete",
  CardCreatePhysicalCardV2 = "card.create_physical_card_v2.state",
  CardCreatePhysicalCardV2AddressFormMounted = "card.create_physical_card_v2.address_form.mounted",
  CardCreatePhysicalCardV2AddressFormCountryClicked = "card.create_physical_card_v2.address_form.country_clicked",
  CardCreatePhysicalCardV2AddressFormAutocompleteClicked = "card.create_physical_card_v2.address_form.autocomplete_clicked",
  CardCreatePhysicalCardV2AddressFormSubmitClicked = "card.create_physical_card_v2.address_form.submit_clicked",
  CardCreatePhysicalCardV2CardHolderNameMounted = "card.create_physical_card_v2.address_form.card_holder_name_mounted",
  CardCreatePhysicalCardV2ConfirmAddressOptionClicked = "card.create_physical_card_v2.confirm_address.option_clicked",
  CardCreatePhysicalCardV2MapMounted = "card.create_physical_card_v2.map.mounted",
  CardCreatePhysicalCardV2NormalizedAddressClicked = "card.create_physical_card_v2.address_form.normalized_address_clicked",
  CardCreatePhysicalCardV2Skipped = "card.create_physical_card_v2.skipped",
  CardCreatePhysicalCardV2Submit = "card.create_physical_card_v2.submit",
  CardCreateVirtualCardV2Submit = "card.create_virtual_card_v2.submit",
  CardDetailsShippedDetailsActivateClicked = "card_details.shipped_details.activate_clicked",
  CardDetailsShippedDetailsIssueCardClicked = "card_details.shipped_details.issue_card_clicked",
  CardDetailsShippedDetailsReplaceClicked = "card_details.shipped_details.replace_clicked",
  CardDetailsShippedDetailsViewed = "card_details.shipped_details.viewed",
  CardMakePaymentFundingSourceChanged = "card.make_payment.funding_source.changed",
  CardMakePaymentState = "card.make_payment.state",
  CardOrdered = "card.ordered",
  CardOverviewNotificationTileInsufficientFundsMounted = "card.card_overview.notification_tile.insufficient_funds.mounted",
  CardOverviewNotificationTileInsufficientFundsClicked = "card.card_overview.notification_tile.insufficient_funds.clicked",
  CardOverviewNotificationTileLimitUtilizationClicked = "card.card_overview.notification_tile.limit_utilization.clicked",
  CardOverviewNotificationTileLimitUtilizationMounted = "card.card_overview.notification_tile.limit_utilization.mounted",
  CardOverviewTransactionsClicked = "card.card_overview.transactions.clicked",
  CardOverviewTransactionsMounted = "card.card_overview.transactions.mounted",
  CardSensitiveCardDataQueryShadowModeResult = "card.sensitive_card_data.query.shadow_mode_result",
  CardSpendSuspendedBannerClicked = "card.spend_suspended_banner.clicked",
  CardTransactionIntegrationCategoryUpdated = "card.transaction.integration_category.updated",
  CardTransactionMemoUpdated = "card.transaction.memo.updated",
  CardTransactionReceiptDownloaded = "card.transaction.receipt.downloaded",
  CardTransactionReceiptUploaded = "card.transaction.receipt.uploaded",
  CardTransactionsManagerOrAddCards = "card.transactions.manage_or_add_cards",
  CardYourCardsPageMounted = "card.your_cards.mounted",
  CreateDisputeState = "create_dispute.state",
  CreateDisputeV2 = "create_dispute.v2.state",
  CreateVendorCardComplete = "create.vendor_card.complete",
  CreateVendorCardForm = "create.vendor_card.form",
  CreateVendorCardReview = "create.vendor_card.review",
  FraudDisputeState = "fraud_disputes.state",
  FraudAlertsState = "fraud_alerts.state",
  FraudAlertsDismissSuccess = "fraud_alerts.dismiss_success",
  FraudAlertsDismissError = "fraud_alerts.dismiss_error",
  InternationalShippingContactSupportClicked = "card.international_shipping.contact_support.clicked",
  ManageBankAccountsState = "card.manage_bank_accounts.state",
  ManageCardsActionMenuMounted = "manage_cards.actions_menu.mounted",
  ManageCardsCreateClicked = "manage_cards.create.clicked",
  ManageCardsHideDetailsOnLeave = "manage_cards.card_details.hide",
  ManageCardsHideDetailsOnToggleClick = "manage_cards.card_details_toggle.hide",
  ManageCardsPaginationNextClicked = "manage_cards.pagination.next",
  ManageCardsPaginationPreviousClicked = "manage_cards.pagination.previous",
  ManageCardsShowDetailsOnHover = "manage_cards.card_details.show",
  ManageCardsShowDetailsOnToggleClick = "manage_cards.card_details_toggle.show",
  ManageCardState = "card.manage_card.state",
  MerchantDisputeState = "merchant_disputes.state",
  ReplaceCardDelayCancellation = "card.replace_card.delay_card_cancellation.toggle",
  ReplaceCardSubmit = "card.replace_card.submit",
  ReplaceCardState = "card.replace_card.state",
  ManageCardsVendorCardsSectionEmptyMounted = "manage_cards.vendor_cards_section.empty",
  ManageCardsVendorCardsSectionErrorMounted = "manage_cards.vendor_cards_section.error",
  PaymentFailureAuthorizeBankClick = "card.payment_failure_reason.authorize_bank_click",
  PaymentFailureChangeAutopayClick = "card.payment_failure_reason.change_autopay_click",
  PaymentFailureMakePaymentClick = "card.payment_failure_reason.make_payment_click",
  RequestUserLimitIncreaseClicked = "request.user_limit_increase.clicked",
  RequestUserLimitIncreaseComplete = "request.user_limit_increase.complete",
  RequestUserLimitIncreaseForm = "request.user_limit_increase.form",
  RequestUserLimitIncreaseReview = "request.user_limit_increase.review",
  RequestVendorCardComplete = "request.vendor_card.complete",
  RequestVendorCardForm = "request.vendor_card.form",
  RequestVendorCardReview = "request.vendor_card.review",
  SetPhysicalCardPinStates = "card.set_physical_card_pin.state",
  SetPhysicalCardPinClicked = "card.set_physical_card_pin.clicked",
  StatementsExportCustomReportClicked = "statements.export_custom_report_clicked",
  StatementsStatementExportClicked = "statements.statement_export_clicked",
  TrackCardShipmentBannerMounted = "card.track_card_shipment_banner.mounted",
  TrackCardShipmentBannerClicked = "card.track_card_shipment_banner.clicked",
  TrackCardShipmentDetailsMounted = "card.track_card_shipment_details.mounted",
  TrackCardShipmentDetailsClicked = "card.track_card_shipment_details.clicked",
  UsersViewCardsClicked = "users.cards.view.clicked",
  VendorCardModifyComplete = "vendor_card.modify.complete",
  VendorCardModifyForm = "vendor_card.modify.form",
  VendorCardCustomLockDateClicked = "vendor_card.custom_lock_date.clicked",
  VerifyAllowListLearnMoreClick = "card.verify_allow_list.learn_more_click",
  YourCardsCorporateCardsCtaActivate = "your_cards.corporate_cards.cta.activate",
  YourCardsCorporateCardsCtaChangeAddress = "your_cards.corporate_cards.cta.change_address",
  YourCardsCorporateCardsCtaIssueNewCard = "your_cards.corporate_cards.cta.issue_new_card",
  YourCardsCorporateCardsCtaUnlock = "your_cards.corporate_cards.cta.unlock",
  YourCardsCorporateCardsCtaViewNewCard = "your_cards.corporate_cards.cta.view_new_card",
  YourCardsCorporateCardsFilter = "your_cards.corporate_cards.filter",
  YourCardsRefetchCorporateCards = "your_cards.corporate_cards.refetch",
  YourCardsRefetchVendorCards = "your_cards.vendor_cards.refetch",
  YourCardsVendorCardsCtaUnlock = "your_cards.vendor_cards.cta.unlock",
  YourCardsVendorCardsCtaViewNewCard = "your_cards.vendor_cards.cta.view_new_card",
  YourCardsVendorCardsFilter = "your_cards.vendor_cards.filter",
}

type EmptyPayload = {};

type UserIdPayload = {
  userId: string;
};

type StatementsStatementExportClickedSchema = {
  statementId: string;
};

type CardsFilterPayload = {
  by: string;
  searchValue?: string;
};

type AllCardsSortPayload = {
  sortBy?: string;
  direction?: string;
};

type CardsPageTabs = {
  tab: string;
};

type CardPayload = {
  cardId?: string | null;
};

type VendorCardCustomLockDatePayload = {
  todaysDate?: Date;
  customLockDate?: Date;
};

type HideCardDetailsPayload = {
  cardId?: string | null;
  isDesktopWeb: boolean;
};

type CardActionPayload = {
  cardId: string;
  pathname?: string;
  cardType?: "primary" | "non-primary" | "budget";
};

type CardCreateCorporateCardPayload = {
  origin: string;
};

type CardCreatePhysicalCardV2CommonPayload = {
  origin: string;
};

type CardCreatePhysicalCardV2Payload = {
  step: string;
  error?: string;
} & CardCreatePhysicalCardV2CommonPayload;

type CardCreatePhysicalCardV2NormalizedAddressPayload = {
  field: string;
} & CardCreatePhysicalCardV2CommonPayload;

type CardCreatePhysicalCardV2CardHolderNameErrorPayload = {
  origin: string;
  hasError?: boolean;
  isCardHolderNameNormalized?: boolean;
};

type CardCreatePhysicalCardV2MapMountedPayload = {
  step: string;
} & CardCreatePhysicalCardV2CommonPayload;

type CardCreatePhysicalCardV2AddressFormCountryClickedPayload = {
  country: CountryAlpha2;
} & CardCreatePhysicalCardV2CommonPayload;

type CardCreatePhysicalCardV2AddressFormSubmitClickedPayload = {
  errors?: Partial<PhysicalCardShippingAddressWithName>;
} & CardCreatePhysicalCardV2CommonPayload;

type CardCreatePhysicalCardV2ConfirmAddressOptionClickedPayload = {
  option: CreatePhysicalCardV2SelectedAddressKey;
} & CardCreatePhysicalCardV2CommonPayload;

type CardCreatePhysicalCardV2SkippedPayload = {
  origin: string;
};

type CardCreateV2SubmitPayload = {
  customerUserId: string;
  isAdminOrderingOnBehalfOfUser?: boolean;
  origin?: FlowOrigin;
  shippingMethod?: ShippingMethod;
};

type CardSpendSuspendedBannerClickedPayload = {
  pendingCollectionStatusName?: string;
};

type CardOverviewNotificationTileLimitUtilizationClickedPayLoad = {
  limitUtilizationStatus: string;
};

type RequestIdPayload = {
  requestId: string;
};

type RequestUserLimitIncreasePayload = {
  origin?: string;
};

type CreateDisputeState = {
  step: string;
  isFraudAlert: boolean;
  isPhysicalCard: boolean;
  isAdminDispute: boolean;
};

type CreateDisputeV2State = {
  step: string;
  isItemizedExpense: boolean;
  numberOfItemizedExpenses: number;
  situationType: string;
  isAdminDispute: boolean;
};

type MerchantDisputeState = {
  step: string;
  isAdminDispute: boolean;
};

type FraudDisputeState = {
  step: string;
  isAdminDispute: boolean;
  cardType: string;
};

type FraudAlertsState = {
  step: string;
  expenseIds: string[];
  cardId: string;
};

type CardMakePaymentState = {
  step: string;
  hasEarlyCreditEnabled: boolean;
  hasSelectedEarlyCredit: boolean;
  isPrimaryFundingSource: boolean;
  isBrexCash: boolean;
  paymentType: PaymentType;
};

type CardOrderedPayload = {
  cardId: string;
  origin: FlowOrigin;
  application: "empower" | "classic";
  shippingMethod?: ShippingMethod;
  expeditedShippingReason?: ExpeditedShippingReason;
  expeditedShippingCustomReasonDescription?: string;
};

type CardMakePaymentFundingSourceChanged = {
  isPrimaryFundingSource: boolean;
  isBrexCash: boolean;
};

type CardTypePayload = {
  cardType: CardType;
};

type CardDetailsShippedDetailsPayload = {
  daysSinceIssuance?: number;
};

type CardSensitiveCardDataQueryShadowModeResultPayload = {
  result: "fail" | "pass";
  cardIds: string[];
  comparisons: SensitiveDataLegacyResultComparison[];
};

type CardTransactionMemoUpdated = {
  transactionId: string;
  memo: string;
};

type CardTransactionReceiptUploaded = {
  transactionId: string;
  assetId: string;
};

type CardTransactionIntegrationCategoryUpdated = {
  transactionId: string;
  categoryName: string;
};

type CardTransactionReceiptDownloaded = {
  pathname: string;
  pagePathname?: string;
  transactionId: string;
  receiptId?: string;
};

type ManageBankAccountsState = {
  step: string;
  userIntent: UserIntent;
};

type ManageCardState = {
  step: string;
};

type SetPhysicalCardPinStates = {
  step: string;
};

type SetPhysicalCardPinPayload = {
  cardId: string;
  pathname: string;
  cardType?: "primary" | "non-primary" | "budget";
};

export type TrackCardShipmentPayload = {
  origin: string;
  cardId: string;
  cardStatus?: string;
  shippingStatus?: ShippingStatus;
  clickEvent?: ClickEventType;
};

type TrackCardShipmentBannerPayload = {
  origin?: string;
  cardId: string;
  cardStatus?: string | null;
  shippingStatus?: ShippingStatus;
  clickEvent?:
    | "activate_card"
    | "replace_card"
    | "edd_card_received_confirmation"
    | "kyc_india";
};

type ReplaceCardState = {
  origin: string;
  step: string;
};

type ReplaceCardDelayCancellation = {
  cardId?: string;
  enabled: boolean;
  isAdminOrderingOnBehalfOfUser?: boolean;
};

type ReplaceCardSubmit = {
  cardId?: string;
  terminateCard?: boolean | null;
  isAdminOrderingOnBehalfOfUser?: boolean;
  cardBudgetOwnershipType?: CardBudgetOwnershipType;
  instrumentType?: string;
  shippingMethod?: ShippingMethod;
};

type InternationalShippingContactSupportClickedPayLoad = {
  origin:
    | "createPhysicalCard"
    | "replacePhysicalCard"
    | "userSetupIssuePhysicalCard"
    | "fraudDisputeReplacePhysicalCard";
};

type CardOverviewNotificationTileInsufficientFundsClickedPayload = {
  daysToDueDate: number;
  isBrexCash: boolean;
};

export type CardEventSchemaMap = {
  [CardAnalyticsEvents.AllCardsSeeMore]: EmptyPayload;
  [CardAnalyticsEvents.AutopayErrorNoticeClicked]: {
    failureType: CardPaymentFailureTypeV3;
  };
  [CardAnalyticsEvents.AutopayErrorNoticeMounted]: {
    failureType: CardPaymentFailureTypeV3;
  };
  [CardAnalyticsEvents.CardActionActivate]: CardActionPayload;
  [CardAnalyticsEvents.CardActionContactSupport]: CardActionPayload;
  [CardAnalyticsEvents.CardActionAdminLockComplete]: CardPayload;
  [CardAnalyticsEvents.CardActionLockComplete]: CardPayload;
  [CardAnalyticsEvents.CardActionOpenAdminLock]: CardActionPayload;
  [CardAnalyticsEvents.CardActionOpenLock]: CardActionPayload;
  [CardAnalyticsEvents.CardActionOpenModify]: CardActionPayload;
  [CardAnalyticsEvents.CardActionOpenRename]: CardActionPayload;
  [CardAnalyticsEvents.CardActionOpenReplace]: CardActionPayload;
  [CardAnalyticsEvents.CardActionOpenReplaceWithPhysical]: CardActionPayload;
  [CardAnalyticsEvents.CardActionOpenShippingDetails]: CardActionPayload;
  [CardAnalyticsEvents.CardActionOpenTerminate]: CardActionPayload;
  [CardAnalyticsEvents.CardActionOpenUnlock]: CardActionPayload;
  [CardAnalyticsEvents.CardActionSetPrimary]: CardActionPayload;
  [CardAnalyticsEvents.CardActionRenameComplete]:
    | CardPayload
    | { origin?: string };
  [CardAnalyticsEvents.CardActionSelectCard]: CardActionPayload;
  [CardAnalyticsEvents.CardActionTerminateComplete]: CardPayload;
  [CardAnalyticsEvents.CardActionUnlockComplete]: CardPayload;
  [CardAnalyticsEvents.CardActionViewTransactions]: CardActionPayload;
  [CardAnalyticsEvents.CardAllCardsDetailsMounted]: EmptyPayload;
  [CardAnalyticsEvents.CardAllCardsFilter]: CardsFilterPayload;
  [CardAnalyticsEvents.CardAllCardsPageMounted]: EmptyPayload;
  [CardAnalyticsEvents.CardAllCardsSort]: AllCardsSortPayload;
  [CardAnalyticsEvents.CardChangeTabPageMounted]: CardsPageTabs;
  [CardAnalyticsEvents.CardCopyCvv]: CardPayload;
  [CardAnalyticsEvents.CardCopyExpirationDate]: CardPayload;
  [CardAnalyticsEvents.CardCopyFailed]: CardPayload;
  [CardAnalyticsEvents.CardCopyPan]: CardPayload;
  [CardAnalyticsEvents.CardCreateCorporatePhysicalCard]: CardCreateCorporateCardPayload;
  [CardAnalyticsEvents.CardCreateCorporatePhysicalCardComplete]: EmptyPayload;
  [CardAnalyticsEvents.CardCreateCorporateVirtualCard]: CardCreateCorporateCardPayload;
  [CardAnalyticsEvents.CardCreateCorporateVirtualCardComplete]: EmptyPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2]: CardCreatePhysicalCardV2Payload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2MapMounted]: CardCreatePhysicalCardV2MapMountedPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2CardHolderNameMounted]: CardCreatePhysicalCardV2CardHolderNameErrorPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2AddressFormMounted]: CardCreatePhysicalCardV2CommonPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2AddressFormCountryClicked]: CardCreatePhysicalCardV2AddressFormCountryClickedPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2AddressFormAutocompleteClicked]: CardCreatePhysicalCardV2CommonPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2AddressFormSubmitClicked]: CardCreatePhysicalCardV2AddressFormSubmitClickedPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2ConfirmAddressOptionClicked]: CardCreatePhysicalCardV2ConfirmAddressOptionClickedPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2NormalizedAddressClicked]: CardCreatePhysicalCardV2NormalizedAddressPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2Skipped]: CardCreatePhysicalCardV2SkippedPayload;
  [CardAnalyticsEvents.CardCreatePhysicalCardV2Submit]: CardCreateV2SubmitPayload;
  [CardAnalyticsEvents.CardCreateVirtualCardV2Submit]: CardCreateV2SubmitPayload;
  [CardAnalyticsEvents.CardDetailsShippedDetailsActivateClicked]: CardDetailsShippedDetailsPayload;
  [CardAnalyticsEvents.CardDetailsShippedDetailsIssueCardClicked]: CardDetailsShippedDetailsPayload;
  [CardAnalyticsEvents.CardDetailsShippedDetailsReplaceClicked]: CardDetailsShippedDetailsPayload;
  [CardAnalyticsEvents.CardDetailsShippedDetailsViewed]: CardDetailsShippedDetailsPayload;

  /* Used for experiments in the card domain */
  [CardAnalyticsEvents.CardMakePaymentFundingSourceChanged]: CardMakePaymentFundingSourceChanged;
  [CardAnalyticsEvents.CardMakePaymentState]: CardMakePaymentState;

  [CardAnalyticsEvents.CardOverviewNotificationTileInsufficientFundsMounted]: EmptyPayload;
  [CardAnalyticsEvents.CardOverviewNotificationTileInsufficientFundsClicked]: CardOverviewNotificationTileInsufficientFundsClickedPayload;
  [CardAnalyticsEvents.CardOverviewNotificationTileLimitUtilizationClicked]: CardOverviewNotificationTileLimitUtilizationClickedPayLoad;
  [CardAnalyticsEvents.CardOverviewNotificationTileLimitUtilizationMounted]: EmptyPayload;
  [CardAnalyticsEvents.CardOverviewTransactionsClicked]: EmptyPayload;
  [CardAnalyticsEvents.CardOverviewTransactionsMounted]: EmptyPayload;
  [CardAnalyticsEvents.CardOrdered]: CardOrderedPayload;
  [CardAnalyticsEvents.CardSensitiveCardDataQueryShadowModeResult]: CardSensitiveCardDataQueryShadowModeResultPayload;
  [CardAnalyticsEvents.CardSpendSuspendedBannerClicked]: CardSpendSuspendedBannerClickedPayload;
  [CardAnalyticsEvents.CardTransactionIntegrationCategoryUpdated]: CardTransactionIntegrationCategoryUpdated;
  [CardAnalyticsEvents.CardTransactionMemoUpdated]: CardTransactionMemoUpdated;
  [CardAnalyticsEvents.CardTransactionReceiptDownloaded]: CardTransactionReceiptDownloaded;
  [CardAnalyticsEvents.CardTransactionReceiptUploaded]: CardTransactionReceiptUploaded;
  [CardAnalyticsEvents.CardTransactionsManagerOrAddCards]: {};
  [CardAnalyticsEvents.CardYourCardsPageMounted]: EmptyPayload;
  [CardAnalyticsEvents.CreateDisputeState]: CreateDisputeState;
  [CardAnalyticsEvents.CreateDisputeV2]: CreateDisputeV2State;
  [CardAnalyticsEvents.CreateVendorCardComplete]: EmptyPayload;
  [CardAnalyticsEvents.CreateVendorCardForm]: EmptyPayload;
  [CardAnalyticsEvents.CreateVendorCardReview]: EmptyPayload;
  [CardAnalyticsEvents.FraudAlertsState]: FraudAlertsState;
  [CardAnalyticsEvents.FraudAlertsDismissSuccess]: EmptyPayload;
  [CardAnalyticsEvents.FraudAlertsDismissError]: EmptyPayload;
  [CardAnalyticsEvents.FraudDisputeState]: FraudDisputeState;
  [CardAnalyticsEvents.InternationalShippingContactSupportClicked]: InternationalShippingContactSupportClickedPayLoad;
  [CardAnalyticsEvents.ManageBankAccountsState]: ManageBankAccountsState;
  [CardAnalyticsEvents.ManageCardsActionMenuMounted]: CardTypePayload;
  [CardAnalyticsEvents.ManageCardsCreateClicked]: EmptyPayload;
  [CardAnalyticsEvents.ManageCardsHideDetailsOnLeave]: CardPayload;
  [CardAnalyticsEvents.ManageCardsHideDetailsOnToggleClick]: HideCardDetailsPayload;
  [CardAnalyticsEvents.ManageCardsPaginationNextClicked]: CardTypePayload;
  [CardAnalyticsEvents.ManageCardsPaginationPreviousClicked]: CardTypePayload;
  [CardAnalyticsEvents.ManageCardsShowDetailsOnHover]: CardPayload;
  [CardAnalyticsEvents.ManageCardsShowDetailsOnToggleClick]: CardPayload;
  [CardAnalyticsEvents.ManageCardState]: ManageCardState;
  [CardAnalyticsEvents.MerchantDisputeState]: MerchantDisputeState;
  [CardAnalyticsEvents.ReplaceCardState]: ReplaceCardState;
  [CardAnalyticsEvents.ReplaceCardDelayCancellation]: ReplaceCardDelayCancellation;
  [CardAnalyticsEvents.ReplaceCardSubmit]: ReplaceCardSubmit;
  [CardAnalyticsEvents.ManageCardsVendorCardsSectionEmptyMounted]: EmptyPayload;
  [CardAnalyticsEvents.ManageCardsVendorCardsSectionErrorMounted]: EmptyPayload;
  [CardAnalyticsEvents.PaymentFailureAuthorizeBankClick]: EmptyPayload;
  [CardAnalyticsEvents.PaymentFailureChangeAutopayClick]: EmptyPayload;
  [CardAnalyticsEvents.PaymentFailureMakePaymentClick]: EmptyPayload;
  [CardAnalyticsEvents.RequestUserLimitIncreaseClicked]: RequestUserLimitIncreasePayload;
  [CardAnalyticsEvents.RequestUserLimitIncreaseComplete]: RequestIdPayload;
  [CardAnalyticsEvents.RequestUserLimitIncreaseForm]: EmptyPayload;
  [CardAnalyticsEvents.RequestUserLimitIncreaseReview]: EmptyPayload;
  [CardAnalyticsEvents.RequestVendorCardComplete]: RequestIdPayload;
  [CardAnalyticsEvents.RequestVendorCardForm]: EmptyPayload;
  [CardAnalyticsEvents.RequestVendorCardReview]: EmptyPayload;
  [CardAnalyticsEvents.SetPhysicalCardPinStates]: SetPhysicalCardPinStates;
  [CardAnalyticsEvents.SetPhysicalCardPinClicked]: SetPhysicalCardPinPayload;
  [CardAnalyticsEvents.StatementsExportCustomReportClicked]: EmptyPayload;
  [CardAnalyticsEvents.StatementsStatementExportClicked]: StatementsStatementExportClickedSchema;
  [CardAnalyticsEvents.TrackCardShipmentBannerMounted]: TrackCardShipmentPayload;
  [CardAnalyticsEvents.TrackCardShipmentBannerClicked]: TrackCardShipmentBannerPayload;
  [CardAnalyticsEvents.TrackCardShipmentDetailsMounted]: TrackCardShipmentPayload;
  [CardAnalyticsEvents.TrackCardShipmentDetailsClicked]: TrackCardShipmentPayload;
  [CardAnalyticsEvents.UsersViewCardsClicked]: UserIdPayload;
  [CardAnalyticsEvents.VendorCardModifyComplete]: CardPayload;
  [CardAnalyticsEvents.VendorCardModifyForm]: CardPayload;
  [CardAnalyticsEvents.VendorCardCustomLockDateClicked]: VendorCardCustomLockDatePayload;
  [CardAnalyticsEvents.VerifyAllowListLearnMoreClick]: EmptyPayload;
  [CardAnalyticsEvents.YourCardsCorporateCardsCtaActivate]: CardPayload;
  [CardAnalyticsEvents.YourCardsCorporateCardsCtaChangeAddress]: CardPayload;
  [CardAnalyticsEvents.YourCardsCorporateCardsCtaIssueNewCard]: CardPayload;
  [CardAnalyticsEvents.YourCardsCorporateCardsCtaUnlock]: CardPayload;
  [CardAnalyticsEvents.YourCardsCorporateCardsCtaViewNewCard]: CardPayload;
  [CardAnalyticsEvents.YourCardsCorporateCardsFilter]: CardsFilterPayload;
  [CardAnalyticsEvents.YourCardsRefetchCorporateCards]: EmptyPayload;
  [CardAnalyticsEvents.YourCardsRefetchVendorCards]: EmptyPayload;
  [CardAnalyticsEvents.YourCardsVendorCardsCtaUnlock]: CardPayload;
  [CardAnalyticsEvents.YourCardsVendorCardsCtaViewNewCard]: CardPayload;
  [CardAnalyticsEvents.YourCardsVendorCardsFilter]: CardsFilterPayload;
};
