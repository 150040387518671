import type { JsonObject, Simplify } from "type-fest";

export enum AnalyticsEvents {
  UserJourney = "DROP.userjourney",
  UserJourneyStarted = "userjourney.started",
  UserJourneyStopped = "userjourney.stopped",
  UserJourneyCompleted = "userjourney.completed",
}

type SharedMetadata = {
  userJourneyName: string;
};

type UserJourneyEndedPayload = {
  userJourneyAction: "stopped";
  metadata: JsonObject | undefined;
};

type UserJourneyStartedPayload = {
  userJourneyAction: "started";
  metadata: JsonObject | undefined;
};

type UserJourneyCompletedPayload = {
  userJourneyAction: "completed";
  duration?: number;
  startEventMetadata: JsonObject | undefined;
  stopEventMetadata: JsonObject | undefined;
};

export type EventSchemaMap = {
  [AnalyticsEvents.UserJourney]: Simplify<
    (
      | UserJourneyStartedPayload
      | UserJourneyCompletedPayload
      | UserJourneyEndedPayload
    ) &
      SharedMetadata
  >;
  [AnalyticsEvents.UserJourneyStarted]: UserJourneyStartedPayload &
    SharedMetadata;
  [AnalyticsEvents.UserJourneyStopped]: UserJourneyEndedPayload &
    SharedMetadata;
  [AnalyticsEvents.UserJourneyCompleted]: UserJourneyCompletedPayload &
    SharedMetadata;
};
