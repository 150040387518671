// analytics.ts file should be able to import types from other features
// disabling rule for this file

/* eslint-disable import/no-restricted-paths */

/* eslint-disable @typescript-eslint/no-restricted-imports */
import type { DataGridDensity } from "@brexhq/metal-table";
import type {
  AccountingIntegrationSettingsNetsuiteNameFieldTarget,
  AccountingJournalEntryReviewStatus,
  AccountingJournalEntryType,
  AccountingJournalReportFormatType,
  AccountingRecordType,
  AssistantMode,
  AutomationSuggestionActionType,
  BillingGroupProductType,
  BillingTransactionType,
  BudgetPeriodType,
  BudgetStatus,
  BudgetSuggestionsHelperType,
  BudgetTemplateAvailabilityType,
  BudgetType,
  CardBudgetOwnershipType,
  ComplianceRatingCohort,
  CreateAutoInviteSettingInput,
  CreateGroupInput,
  DepositsAccountType,
  EntityFilterInput,
  EntityType,
  ErrorCode,
  ExpenseFilterInput,
  ExpensePaymentStatusType,
  ExpenseStatus,
  ExpenseType,
  ExportJobStatus,
  ExportMethod,
  ExportNotificationType,
  ExportStatus,
  ExportType,
  ExtendedFieldGroup,
  ExtendedFieldOptionStatus,
  ExtendedFieldStatus,
  FinancialInstrumentCode,
  InboxTaskSortFilter,
  InboxTaskType,
  InboxTaskWebFilterType,
  IntentType,
  LimitGroupOverrides,
  OrderByDirection,
  PaymentFailureType,
  PositivePayStatus,
  PrimitiveType,
  ReceiptProvider,
  ReimbursementPaymentFailureReason,
  ReportFormatType,
  ReportOption,
  RequestActionStatusInput,
  RequestActionType,
  SeenFlagKey,
  StockReportType,
  SurfaceGroup,
  SurfaceTaskSortFilter,
  TargetType,
  TransactionExportType,
  UpdateAutoInviteSettingInput,
  UserProductAccess,
  UserRole,
  UserRoleTypeInput,
  VerificationStatus,
} from "@/__generated__/globalTypes";
import type {
  SelectedBillingCycle,
  UserRole as PrimitiveUserRole,
} from "@/data/sharedTypes";
import type { UpsellType } from "@/domains/App/features/Settings/features/DeadlineSettings/sharedTypes";
import type { AccountingSuggestionCardType } from "@/domains/Primitives/features/AccountingAutomationHub/helpers/constants";
import type {
  AccountingAutomationHubVariants,
  AccountingAutomationSuggestionDisplayedLocations,
  AccountingRuleSuggestion,
  SegmentedControlledRuleTypes,
  SuggestionTakeoverActionType,
} from "@/domains/Primitives/features/AccountingAutomationHub/sharedTypes";
import type {
  OptionInputTypes,
  RuleBodyClause,
  RuleType,
} from "@/domains/Primitives/features/AccountingCustomRulesDetailsPane/sharedTypes";
import type {
  OptionInputTypes as OptionInputTypesV2,
  RuleBodyClause as WorkflowsRuleBodyClause,
} from "@/domains/Primitives/features/AccountingCustomRulesDetailsPaneV2/sharedTypes";
import type { ConnectSteps } from "@/domains/Primitives/features/AccountingIntegrationConnections/helpers/constants";
import type {
  AccountingSetupFlow,
  AccountingSetupGlAccountsEntryMethod,
  UserType,
} from "@/domains/Primitives/features/AccountingSetup/sharedTypes";
import type { AccountingWorkflowRuleImpactedTransactionsLocations } from "@/domains/Primitives/features/AccountingWorkflowRuleImpactedTransactions/sharedTypes";
import type { VendorFormEventSchemaMap } from "@/domains/Primitives/features/AddVendorForm/helpers/analytics";
import type { AuditLogFilterType } from "@/domains/Primitives/features/AuditTable/sharedTypes";
import type { ExpenseTaskType } from "@/domains/Primitives/features/BrexAssistantActivities/sharedTypes";
import type {
  BudgetAttributesStates,
  BudgetEntityType,
  FormFlowType,
  FormTemplateType,
} from "@/domains/Primitives/features/BudgetAttributesForm/sharedTypes";
import type { BudgetFormStep } from "@/domains/Primitives/features/BudgetForm/sharedTypes";
import type { BudgetTemplatePolicyType } from "@/domains/Primitives/features/BudgetOffboardingV2/sharedTypes";
import type { BudgetRequestFormStep } from "@/domains/Primitives/features/BudgetRequestFormV3/sharedTypes";
import type { BudgetRequestNestingCondition } from "@/domains/Primitives/features/BudgetRequestOrganization/sharedTypes";
import type { SuggestedBudgetFormType } from "@/domains/Primitives/features/BudgetSetupSuggestions/sharedTypes";
import type {
  CardEligibilityType,
  CardOwnershipTransferOrigin,
} from "@/domains/Primitives/features/CardOwnershipTransfer/sharedTypes";
import type { CashAccountBannerTypes } from "@/domains/Primitives/features/CashAccountBanners/sharedTypes";
import type { CashTransactionsPageTables } from "@/domains/Primitives/features/CashTransactions/sharedTypes";
import type { Rating } from "@/domains/Primitives/features/ComplianceRatingBanner/sharedTypes";
import type { DashboardSearchEntryPoint } from "@/domains/Primitives/features/DashboardSearch/sharedTypes";
import type { RepaymentActionTypes } from "@/domains/Primitives/features/EmployeeRepayments/sharedTypes";
import type {
  EmployeesFilterKeys,
  EmployeesFilterValues,
} from "@/domains/Primitives/features/EmployeesTable/sharedTypes";
import type { BudgetAuthorizationErrorType } from "@/domains/Primitives/features/ExpenseBudgetSelection/sharedTypes";
import type { ScopedSearchResultTypes } from "@/domains/Primitives/features/ExpenseFilters/subFeatures/LocalSearch/shared/sharedTypes";
import type {
  ActionType,
  ExpensesTableOrigin,
} from "@/domains/Primitives/features/ExpensesTable/sharedTypes";
import type { SelectedIntentTabType } from "@/domains/Primitives/features/GlobalSearch/sharedTypes";
import type { PrimitivesRefreshERPIntegrationMutation_refreshIntegration_RefreshIntegrationPayload_integration_Integration as Integration } from "@/domains/Primitives/features/IntegrationsList/data/__generated__/mutations.generated";
import type { EntitySetupTask } from "@/domains/Primitives/features/LegalEntityDetailsPane/sharedTypes";
import type {
  AirlineForm,
  CarRentalForm,
  DocumentationType,
  ExpenseType as PolicyExpenseType,
  LodgingForm,
  RailForm,
} from "@/domains/Primitives/features/PolicyCreatorV1/sharedTypes";
import type {
  BaseDashboardNav,
  Card,
  ShareAction,
  WidgetEditorForm,
  WidgetForm,
} from "@/domains/Primitives/features/ReportingCompliance/sharedTypes";
import type {
  ActionPermissions,
  DetailsActionsType as SpendLimitDetailsActionsType,
} from "@/domains/Primitives/features/SpendLimitDetails/sharedTypes";
import type { SpendLimitFormStep } from "@/domains/Primitives/features/SpendLimitForm/sharedTypes";
import type { SpendMigrationEventSchemaMap } from "@/domains/Primitives/features/SpendMigration/helpers/analytics";
import type {
  BudgetRequestTable,
  TaskCtaType,
  TaskInboxSectionType,
} from "@/domains/Primitives/features/TaskInbox/sharedTypes";
import type { SubmittedEntityTypes } from "@/domains/Primitives/features/TeamAttributeMapping/sharedTypes";
import type { VendorPortalEventSchemaMap } from "@/domains/Primitives/features/VendorPortal/helpers/analytics";
import type { Origin as CardDetailsPaneOrigin } from "@/domains/Primitives/features/WalletCardBudgetSelector/sharedTypes";
import type { AccountingWorkflowRuleType } from "@/domains/Primitives/shared/helpers/constants";
import type {
  AccountsTimeSeriesPeriodInfo,
  BudgetProgramsTableRowActions,
  BudgetsTableOrigin,
  BulkStatementsDownloadPeriod,
  ConfigureBillingFlowOrigin,
  EmployeeOptionTypes,
  ERPExportAnalyticsParams,
  IntegrationVendors,
  ProductOffered,
} from "@/domains/Primitives/shared/sharedTypes";
import type {
  FilterParams,
  GroupByFieldKey,
  SortingDirection,
} from "@/domains/Primitives/shared/viewModels/sharedTypes";
import type { ApolloError } from "@/features/Apollo";
import type { CardTableAction } from "@/features/CardsAndLimits/sharedTypes";
import type {
  CompanyPolicyAgreementError,
  CompanyPolicyAgreementOrigin,
} from "@/features/CompanyPolicyAgreement/sharedTypes";
import type { CountryOnChangePayload } from "@/features/CountryOptions/sharedTypes";
import type { CreditCardFieldType } from "@/features/CreditCard/sharedTypes";
import type { PolicyDetailsDocumentPayload } from "@/features/PolicyState/analytics";
import type { SelectAccountForTransferValueType } from "@/features/SelectAccountForTransfer/sharedTypes";
import type { ManageInvoicePaymentsTakeoverStep } from "@/modules/Banking/modules/PrincipalProtection/helpers/constants";
import type { BankingTableViews } from "@/modules/Banking/modules/Table/helpers/constants";
import type {
  StatementsFilterType,
  TransactionsV2FilterType as BankingAccountTransactionsFilterType,
} from "@/routes/Accounts/Banking/modules/Filters/shared/sharedTypes";
import type { BankingMigrationDetailsPaneTypes } from "@/routes/Accounts/Banking/modules/MigrationDetailsPane/helpers/constants";
import type { MigrationWizardStep } from "@/routes/Accounts/Banking/modules/MigrationWizard/helpers/constants";
import type { InvoicesOrigin } from "@/routes/Invoices/sharedTypes";
import type {
  OnboardingExperienceSubStep,
  OnboardingExperienceV2Step,
  OnboardingMachineContext,
} from "@/routes/Onboarding/OnboardingExperience/sharedTypes";

// INFO: Copy, paste, and uncomment to add new section
// //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
// // NEW SECTION
// // Start of NEW SECTION related instrumentation
// TODO: Add instrumentation here
// // End of NEW SECTION related instrumentation
// // NEW SECTION
// //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\

export enum PrimitivesAnalyticsEvents {
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // BILL PAY
  // Start of BILL_PAY related instrumentation
  // Bill Invoice Processing Events
  BillPayApproverOnboardingStarted = "bill_pay.approver_onboarding.started",
  BillPayApproverOnboardingContactSupport = "bill_pay.approver_onboarding.contact_support",
  BillPaySetupStarted = "bill_pay.setup.started",
  // End of BILL_PAY related instrumentation
  // BILL PAY
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // ACCOUNTS
  // Start of ACCOUNTS related instrumentation
  // Accounts View Events
  AccountsNewAccountsSurfaceViewed = "accounts.accounts_surface.viewed",
  AccountsAddExternalAccountClicked = "accounts.add.external_account.clicked",
  AccountsAddCashAccountsClicked = "accounts.add.cash_account.clicked",
  AccountsCreditRowItemClicked = "accounts.credit_account.clicked",
  AccountsCashRowItemClicked = "accounts.cash_account.clicked",
  AccountsReimbursementsRowItemClicked = "accounts.reimbursements_account.clicked",
  AccountsExternalAccountSettingsViewed = "accounts.external_account_settings.viewed",
  AccountsExternalAccountPageViewed = "accounts.external_account_page.viewed",
  AccountsExternalAccountTableRowClicked = "accounts.external_account_table_row.clicked",
  AccountsInsightsL2Viewed = "accounts.insights_l2.viewed",
  AccountsCreditL2Viewed = "accounts.credit_l2.viewed",
  AccountsCashL2Viewed = "accounts.cash_l2.viewed",
  AccountsMoveMoneyMenuClicked = "accounts.move_money.menu.clicked",
  AccountsMoveMoneySendMoneyClicked = "accounts.move_money.send_money.clicked",
  AccountsMoveMoneyPayAVendorClicked = "accounts.move_money.pay_a_vendor.clicked",
  AccountsMoveMoneyDepositMoneyClicked = "accounts.move_money.deposit_money.clicked",
  AccountsMoveMoneyTransferFundsClicked = "accounts.move_money.transfer_funds.clicked",
  AccountsMoveMoneyPayCreditBillClicked = "accounts.move_money.pay_credit_bill.clicked",
  AccountsMoveMoneyLinkPayrollProviderClicked = "accounts.move_money.link_payroll_provider.clicked",
  AccountsMoveMoneyReverseWireClicked = "accounts.move_money.reverse_wire.clicked",
  AccountsTimezoneBannerDismissClicked = "accounts.time_zone.banner.dismiss_clicked",
  AccountsTimezoneBannerEditClicked = "accounts.time_zone.banner.edit_clicked",
  AccountsTimezoneModalOpen = "accounts.time_zone.modal.open",
  AccountsTimezoneUpdated = "accounts.time_zone.updated",
  AccountsCashCardMounted = "accounts.cash_card.mounted",
  AccountsCashCardClicked = "accounts.cash_card.clicked",
  AccountsManageAccountsAddCashAccountClicked = "accounts.manage_accounts.add_cash_account.clicked",
  AccountsManageAccountsConnectExternalAccountClicked = "accounts.manage_accounts.connect_external_account.clicked",
  AccountsManageAccountsRemoveBankConnectionClicked = "accounts.manage_accounts.remove_bank_connection.clicked",
  AccountsManageAccountsRemoveBankAccountClicked = "accounts.manage_accounts.remove_bank_account.clicked",
  AccountsManageAccountsFixBankConnectionClicked = "accounts.manage_accounts.fix_bank_connection.clicked",
  AccountsManageAccountsManageAutoPayClicked = "accounts.manage_accounts.manage_autopay.clicked",
  AccountsManageAccountsAddBankAccountsClicked = "accounts.manage_accounts.add_bank_accounts.clicked",
  AccountsManageAccountsAddUnderwritingAccountClicked = "accounts.manage_accounts.add_underwriting_bank_account.clicked",
  AccountsManageAccountsAddManualAccountClicked = "accounts.manage_accounts.add_manual_account.clicked",
  AccountsManageAccountsSetUpReimbursementsClicked = "accounts.manage_accounts.manage_reimbursement.clicked",
  AccountsManageAccountsVerifyProofOfOwnershipClicked = "accounts.manage_accounts.verify_proof_of_ownership.clicked",
  AccountsManageAccountsLocalAutopayVerifyPennyPullClicked = "accounts.manage_accounts.local_autopay.verify_penny_pull.clicked",
  AccountsManageAccountsLocalAutopayVerifyMicroDepositsClicked = "accounts.manage_accounts.local_autopay.verify_micro_deposits.clicked",
  AccountsManageAccountsLocalAutopayVerifyPoaoClicked = "accounts.manage_accounts.local_autopay.verify_poao.clicked",
  AccountsManageAccountsLocalAutopayNewAccountClicked = "accounts.manage_accounts.local_autopay.new_account.clicked",
  AccountsManageAccountsLocalAutopayEnablementBannerMounted = "accounts.manage_accounts.local_autopay.enablement_banner.mounted",
  AccountsManageAccountsLocalAutopayEnablementBannerLearnMoreClicked = "accounts.manage_accounts.local_autopay.enablement_banner.learn_more.clicked",
  AccountsManageAccountsLocalAutopayEnablementBannerDismissed = "accounts.manage_accounts.local_autopay.enablement_banner.dismissed",
  // AccountsV2 View Events
  AccountsV2ModuleViewed = "accounts.v2.module.viewed",
  AccountsV2CreditModuleClicked = "accounts.v2.credit_module.clicked",
  AccountsV2ReimbursementsModuleClicked = "accounts.v2.reimbursements_module.clicked",
  AccountsV2BusinessAccountsModuleClicked = "accounts.v2.business_accounts_module.clicked",
  AccountsV2BusinessAccountsModuleDepositFundsButtonClicked = "accounts.v2.business_accounts_module.deposit_funds.button.clicked",
  AccountsV2ExternalAccountsModuleClicked = "accounts.v2.external_accounts_module.clicked",
  AccountsV2ModuleOptionClicked = "accounts.v2.module.option.clicked",
  AccountsV2DepositFundsModuleReceiveWireClicked = "accounts.v2.deposit.funds.module.receive.wire.clicked",
  AccountsV2DepositFundsModuleDepositFundsClicked = "accounts.v2.deposit.funds.module.deposit.funds.clicked",

  // Applications events
  ProductSelectionClicked = "product_selection.clicked",
  ProductSelectionViewed = "product_selection.viewed",
  // End of ACCOUNTS related instrumentation
  // ACCOUNTS
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // ACCOUNTING
  // Start of ACCOUNTING related instrumentation
  // Accounting L1 Overview
  AccountingOverviewMounted = "accounting.overview.mounted",
  AccountingOverviewErrorMounted = "accounting.overview.error.mounted",
  AccountingOverviewProgressBarMonthPickerUpdated = "accounting.overview.progress_bar.month_picker.updated",
  AccountingOverviewManagePrepareClicked = "accounting.overview.manage_prepare.clicked",
  AccountingOverviewManageReviewExportClicked = "accounting.overview.manage_review_export.clicked",
  AccountingOverviewManageExportHistoryMounted = "accounting.overview.manage_export_history.mounted",
  AccountingOverviewManageExportHistoryClicked = "accounting.overview.manage_export_history.clicked",
  AccountingOverviewManageExportHistoryLaunchSpotlightMounted = "accounting.overview.manage_export_history.launch_spotlight.mounted",
  AccountingOverviewManageExportHistoryLaunchSpotlightDismissalClicked = "accounting.overview.manage_export_history.launch_spotlight.dismissal.clicked",
  AccountingOverviewManageAccountingFieldsClicked = "accounting.overview.manage_accounting_fields.clicked",
  AccountingOverviewManageCustomRulesClicked = "accounting.overview.manage_custom_rules.clicked",
  AccountingOverviewManageFundingSourcesClicked = "accounting.overview.manage_funding_sources.clicked",
  AccountingOverviewManageSettingsClicked = "accounting.overview.manage_settings.clicked",
  AccountingOverviewOnboardingBannerClicked = "accounting.overview.onboarding_banner.clicked",
  AccountingOverviewSetupAccountingGetStartedCTAClicked = "accounting.overview.setup_accounting.get_started_cta.clicked",
  AccountingOverviewSetupAccountingContinueCTAClicked = "accounting.overview.setup_accountinga.continue_cta.clicked",
  // Accounting L2
  AccountingFieldsMounted = "accounting.accounting_fields.mounted",
  AccountingFieldsMappingsMounted = "accounting.accounting_fields.mappings.mounted",
  AccountingFieldsCreateMounted = "accounting.accounting_fields.create.mounted",
  AccountingCustomRulesMounted = "accounting.custom_rules.mounted",
  AccountingFundingSourcesMounted = "accounting.funding_sources.mounted",
  AccountingPrepareMounted = "accounting.prepare.mounted",
  AccountingReviewExportMounted = "accounting.review_export.mounted",
  // Accounting L2 Entity Settings
  AccountingEntitySettingsMounted = "accounting.entity_settings.mounted",
  // Accounting L3
  AccountingOnboardingSetupEntryScreenMounted = "accounting.onboarding.setup_entry_screen.mounted",
  AccountingOnboardingSetupEntryScreenContinueClicked = "accounting.onboarding.setup_entry_screen.continue.clicked",
  AccountingOnboardingSetupEntryScreenBackClicked = "accounting.onboarding.setup_entry_screen.back.clicked",
  AccountingOnboardingManualSetupStartStepMounted = "accounting.onboarding.manual_setup.start_step.mounted",
  AccountingOnboardingManualSetupStartStepBackClicked = "accounting.onboarding.manual_setup.start_step.back.clicked",
  AccountingOnboardingManualSetupStartStepNextClicked = "accounting.onboarding.manual_setup.start_step.next.clicked",
  AccountingOnboardingManualSetupGLAccountSetupStepMounted = "accounting.onboarding.manual_setup.gl_account_setup_step.mounted",
  AccountingOnboardingManualSetupGLAccountSetupStepCSVDownloaded = "accounting.onboarding.manual_setup.gl_account_setup_step.csv.downloaded",
  AccountingOnboardingManualSetupGLAccountSetupStepCSVUploaded = "accounting.onboarding.manual_setup.gl_account_setup_step.csv.uploaded",
  AccountingOnboardingManualSetupGLAccountSetupStepOptionToggled = "accounting.onboarding.manual_setup.gl_account_setup_step.option.toggled",
  AccountingOnboardingManualSetupGLAccountSetupStepBackClicked = "accounting.onboarding.manual_setup.gl_account_setup_step.back.clicked",
  AccountingOnboardingManualSetupGLAccountSetupStepNextClicked = "accounting.onboarding.manual_setup.gl_account_setup_step.next.clicked",
  AccountingOnboardingManualSetupGLAccountConfirmStepMounted = "accounting.onboarding.manual_setup.gl_account_confirm_step.mounted",
  AccountingOnboardingManualSetupGLAccountConfirmStepBackClicked = "accounting.onboarding.manual_setup.gl_account_confirm_step.back.clicked",
  AccountingOnboardingManualSetupGLAccountConfirmStepSearched = "accounting.onboarding.manual_setup.gl_account_confirm_step.searched",
  AccountingOnboardingManualSetupGLAccountConfirmStepAddValueClicked = "accounting.onboarding.manual_setup.gl_account_confirm_step.add_value.clicked",
  AccountingOnboardingManualSetupGLAccountConfirmStepEditClicked = "accounting.onboarding.manual_setup.gl_account_confirm_step.edit.clicked",
  AccountingOnboardingManualSetupGLAccountConfirmStepDeleteClicked = "accounting.onboarding.manual_setup.gl_account_confirm_step.delete.clicked",
  AccountingOnboardingManualSetupGLAccountConfirmStepConfirmClicked = "accounting.onboarding.manual_setup.gl_account_confirm_step.confirm.clicked",
  AccountingOnboardingReviewGLDetailsStepMounted = "accounting.onboarding.review_gl_details_step.mounted",
  AccountingOnboardingReviewGLDetailsStepContinueClicked = "accounting.onboarding.review_gl_details_step.continue.clicked",
  AccountingOnboardingReviewGLDetailsStepBackClicked = "accounting.onboarding.manual_setup.review_gl_details_step.back.clicked",
  AccountingOnboardingMultiEntityEducationStepMounted = "accounting.onboarding.multi_entity_education.mounted",
  AccountingOnboardingMultiEntityEducationStepContinueClicked = "accounting.onboarding.multi_entity_education.continue.clicked",
  AccountingOnboardingMultiEntityEducationStepBackClicked = "accounting.onboarding.manual_setup.multi_entity_education.back.clicked",
  AccountingOnboardingMultiEntityEducationExitClicked = "accounting.onboarding.manual_setup.multi_entity_education.exit.clicked",
  AccountingOnboardingGenerateSuggestionsStepMounted = "accounting.onboarding.generate_suggestions.mounted",
  AccountingOnboardingGenerateSuggestionsExitClicked = "accounting.onboarding.manual_setup.generate_suggestions.exit.clicked",
  AccountingOnboardingManualSetupGLAccountCreateSuccessStepMounted = "accounting.onboarding.manual_setup.gl_account_create_success_step.mounted",
  AccountingOnboardingManualSetupGLAccountCreateSuccessStepNextClicked = "accounting.onboarding.manual_setup.gl_account_create_success_step.next.clicked",
  AccountingOnboardingSetupGLMappingsFlowEntryStepMounted = "accounting.onboarding.setup_gl_mappings_flow.entry_step.mounted",
  AccountingOnboardingSetupGLMappingsFlowEntryStepContinueClicked = "accounting.onboarding.setup_gl_mappings_flow.continue.clicked",
  AccountingOnboardingSetupGLMappingsFlowEntryStepBackClicked = "accounting.onboarding.setup_gl_mappings_flow.back.clicked",
  AccountingOnboardingERPSetupReviewExportSettingsStepMounted = "accounting.onboarding.erp_setup.review_export_settings_step.mounted",
  AccountingOnboardingERPSetupReviewReviewExportSettingsStepContinueClicked = "accounting.onboarding.erp_setup.review_export_settings_step.continue.clicked",
  AccountingOnboardingERPSetupReviewReviewExportSettingsStepBackClicked = "accounting.onboarding.erp_setup.review_export_settings_step.back.clicked",
  AccountingOnboardingReviewSuggestedMappingsStepMounted = "accounting.onboarding.review_suggested_mappings_step.mounted",
  AccountingOnboardingReviewSuggestedMappingsStepContinueClicked = "accounting.onboarding.review_suggested_mappings_step.continue.clicked",
  AccountingOnboardingReviewSuggestedMappingsStepBackClicked = "accounting.onboarding.review_suggested_mappings_step.back.clicked",
  AccountingOnboardingReviewSuggestedMappingsStepExitClicked = "accounting.onboarding.review_suggested_mappings_step.exit.clicked",
  AccountingOnboardingReviewSuggestedMappingsStepIntegrationRuleUpdatesSaved = "accounting.onboarding.review_suggested_mappings_step.integration_rule_updates.saved",
  AccountingOnboardingReviewSuggestedMappingsStepSuggestionsSaved = "accounting.onboarding.review_suggested_mappings_step.suggestions.saved",
  AccountingOnboardingChooseUserScreenMounted = "accounting.onboarding.choose_user_screen.mounted",
  AccountingOnboardingChooseUserScreenCancelClicked = "accounting.onboarding.choose_user_screen.cancel.clicked",
  AccountingOnboardingChooseUserScreenContinueClicked = "accounting.onboarding.choose_user_screen.continue.clicked",
  AccountingOnboardingSelectTeamMemberScreenMounted = "accounting.onboarding.select_team_member_screen.mounted",
  AccountingOnboardingSelectTeamMemberScreenNoEligibleTeamMembers = "accounting.onboarding.select_team_member_screen.no_eligible_team_members",
  AccountingOnboardingSelectTeamMemberScreenTeamPageLinkClicked = "accounting.onboarding.select_team_member_screen.team_page_link.clicked",
  AccountingOnboardingSelectTeamMemberScreenBackClicked = "accounting.onboarding.select_team_member_screen.back.clicked",
  AccountingOnboardingSelectTeamMemberScreenNotifyClicked = "accounting.onboarding.select_team_member_screen.notify.clicked",
  AccountingOnboardingSelectAccountingFirmScreenMounted = "accounting.onboarding.selected_accounting_firm.mounted",
  AccountingOnboardingSelectAccountingFirmScreenBackClicked = "accounting.onboarding.selected_accounting_firm.back.clicked",
  AccountingOnboardingSelectAccountingFirmScreenNotifyClicked = "accounting.onboarding.selected_accounting_firm.notify.clicked",
  AccountingOnboardingIntegrationCreated = "accounting.onboarding.integration.created",
  AccountingOnboardingIntegrationCreatingLearnMoreClicked = "accounting.onboarding.integration.creating.learn_more.clicked",
  AccountingOnboardingContinueClicked = "accounting.onboarding.continue.clicked",
  // Custom Rules Events
  AccountingCustomRulesListTableMounted = "accounting.custom_rules_list_table.mounted",
  AccountingCustomRulesPaneMounted = "accounting.custom_rules_pane.mounted",
  AccountingCustomRulesPaneInputMounted = "accounting.custom_rules_pane.input.mounted",
  AccountingCustomRulesPaneCloseClicked = "accounting.custom_rules_pane.close.clicked",
  AccountingCustomRulesPaneDeleteRuleModalMounted = "accounting.custom_rules_pane.delete_rule_modal.mounted",
  AccountingCustomRulesPaneDeleteRuleModalCancel = "accounting.custom_rules_pane.delete_rule_modal.cancel",
  AccountingCustomRulesPaneDeleteRuleModalConfirm = "accounting.custom_rules_pane.delete_rule_modal.confirm",
  AccountingCustomRulesPaneDeleteRuleClicked = "accounting.custom_rules_pane.delete.rule.clicked",
  AccountingCustomRulesPaneCreateRuleClicked = "accounting.custom_rules_pane.create.rule.clicked",
  AccountingCustomRulesPaneEditRuleClicked = "accounting.custom_rules_pane.edit.rule.clicked",
  AccountingCustomRulesPaneSetCustomRuleName = "accounting.custom_rules_pane.set.custom_rule_name",
  AccountingCustomRulesPaneDateRangeFilterSelected = "accounting.custom_rules_pane.date_range_filter.selected",
  AccountingCustomRulesPaneCategoryOptionChange = "accounting.custom_rules_pane.category_option.change",
  AccountingCustomRulesPaneOptionInputChange = "accounting.custom_rules_pane.option_input.change",
  AccountingCustomRulesPaneBudgetOptionInputChange = "accounting.custom_rules_pane.budget_option_input.change",
  AccountingCustomRulesPaneBudgetApplyNestedChange = "accounting.custom_rules_pane.budget_apply_nested.change",
  AccountingCustomRulesPaneSaveRuleClicked = "accounting.custom_rules_pane.save.rule.clicked",
  AccountingCustomRulesAddRuleFieldClicked = "accounting.custom_rules.add_rule_field.clicked",
  AccountingCustomRulesLearnMoreClicked = "accounting.custom_rules.learn_more.clicked",
  AccountingStreamlinedSummaryCardsManageClicked = "accounting.streamlined.summary_cards.manage.clicked",
  AccountingStreamlinedSummaryCardsMetricsMounted = "accounting.streamlined.summary_cards.metrics.mounted",
  AccountingStreamlinedSummaryCardsMetricsClicked = "accounting.streamlined.summary_cards.metrics.clicked",
  AccountingCustomRulesPaneConfirmActionModalMounted = "accounting.custom_rules_pane.confirm_action_modal.mounted",
  AccountingCustomRulesPaneConfirmActionModalCancel = "accounting.custom_rules_pane.confirm_action_modal.cancel",
  AccountingCustomRulesPaneConfirmActionModalConfirm = "accounting.custom_rules_pane.confirm_action_modal.confirm",
  AccountingCustomRulesCreateRuleToastSuccessMounted = "accounting.custom_rules.create_rule.toast.success.mounted",
  AccountingCustomRulesCreateRuleToastFailedMounted = "accounting.custom_rules.create_rule.toast.failed.mounted",
  AccountingCustomRulesUpdateRuleToastSuccessMounted = "accounting.custom_rules.update_rule.toast.success.mounted",
  AccountingCustomRulesUpdateRuleToastFailedMounted = "accounting.custom_rules.update_rule.toast.failed.mounted",
  AccountingCustomRulesDeleteRuleToastSuccessMounted = "accounting.custom_rules.delete_rule.toast.success.mounted",
  AccountingCustomRulesDeleteRuleToastFailedMounted = "accounting.custom_rules.delete_rule.toast.failed.mounted",
  // Accounting Fields Events
  AccountingFieldsMappingsSectionMounted = "accounting.accounting_fields.mappings_section.mounted",
  AccountingFieldsDownloadCSVSuccess = "accounting.accounting_fields.csvdownload.success",
  AccountingFieldsDownloadCSVFailure = "accounting.accounting_fields.csvdownload.failure",
  AccountingFieldsEditVisibilityMounted = "accounting.accounting_fields.editvisibility.mounted",
  AccountingFieldsEditVisibilityByExpenseType = "accounting.accounting_fields.editvisibility.expensetype",
  AccountingFieldsEditVisibilityByRole = "accounting.accounting_fields.editvisibility.role",
  AccountingFieldsEditVisibilityByOptionToggle = "accounting.accounting_fields.editvisibility.option.toggle",
  AccountingFieldsEditVisibilityByOptionFilterIf = "accounting.accounting_fields.editvisibility.option.filterif",
  AccountingFieldsEditVisibilityByOptionAddCondition = "accounting.accounting_fields.editvisibility.option.addcondition",
  AccountingFieldsEditVisibilitySave = "accounting.accounting_fields.editvisibility.save",
  AccountingFieldsEditVisibilityBack = "accounting.accounting_fields.editvisibility.back",
  AccountingFieldsDetailsPaneEditVisibilityClicked = "accounting.accounting_fields.detailspane.editvisibility.clicked",
  AccountingFieldsEditTakeoverMounted = "accounting.accounting_fields.edit_takeover.mounted",
  AccountingFieldsEditTakeoverCSVDownloadSuccess = "accounting.accounting_fields.edit_takeover.csvdownload.success",
  AccountingFieldsEditTakeoverCSVDownloadFailure = "accounting.accounting_fields.edit_takeover.csvdownload.failure",
  AccountingFieldsEditTakeoverCSVUploadSuccess = "accounting.accounting_fields.edit_takeover.csvupload.success",
  AccountingFieldsEditTakeoverSaveSuccess = "accounting.accounting_fields.edit_takeover.save.success",
  AccountingFieldsEditTakeoverSaveFailure = "accounting.accounting_fields.edit_takeover.save.failure",
  AccountingFieldsDetailsPaneValueEditName = "accounting.accounting_fields.detailspane.value.editname",
  AccountingFieldsDetailsPaneValueDelete = "accounting.accounting_fields.detailspane.value.delete",
  // Streamlined Rules Events
  AccountingJournalFiltersSuggestionsButtonMounted = "accounting.journal.filters.suggestions_button.mounted",
  AccountingJournalFiltersSuggestionsButtonClicked = "accounting.journal.filters.suggestions_button.clicked",
  AccountingStreamlinedSuggestionsTourMounted = "accounting.journal.streamlined_suggestions.tour.mounted",
  AccountingStreamlinedSuggestionsTourForwardClicked = "accounting.journal.streamlined_suggestions.tour.forward.clicked",
  AccountingStreamlinedSuggestionsTourCompleteClicked = "accounting.journal.streamlined_suggestions.tour.complete.clicked",
  AccountingStreamlinedSuggestionsTourDismissClicked = "accounting.journal.streamlined_suggestions.tour.dismiss.clicked",
  AccountingStreamlinedSuggestionsDeclineToastFailedMounted = "accounting.streamlined.decline.toast.failed.mounted",
  AccountingStreamlinedSuggestionsPaneMounted = "accounting.streamlined.suggestions_pane.mounted",
  AccountingStreamlinedSuggestionsPaneCloseClicked = "accounting.streamlined.suggestions_pane.close.clicked",
  AccountingStreamlinedSuggestionsPaneMappingAcceptClicked = "accounting.streamlined.suggestions_pane.mapping.accept.clicked",
  AccountingStreamlinedSuggestionsPaneMappingDeclineClicked = "accounting.streamlined.suggestions_pane.mapping.decline.clicked",
  AccountingStreamlinedSuggestionsPaneRuleViewClicked = "accounting.streamlined.suggestions_pane.rule.view.clicked",
  AccountingStreamlinedSuggestionsPaneRuleDeclineClicked = "accounting.streamlined.suggestions_pane.rule.decline.clicked",
  AccountingStreamlinedMappingsAcceptToastSuccessMounted = "accounting.streamlined.mappings.accept.toast.success.mounted",
  AccountingStreamlinedMappingsAcceptToastFailedMounted = "accounting.streamlined.mappings.accept.toast.failed.mounted",
  AccountingStreamlinedRulePaneMounted = "accounting.streamlined.rule_pane.mounted",
  AccountingStreamlinedRulePaneCloseClicked = "accounting.streamlined.rule_pane.close.clicked",
  AccountingStreamlinedRulePaneCreateClicked = "accounting.streamlined.rule_pane.create.clicked",
  AccountingStreamlinedRulesAcceptToastSuccessMounted = "accounting.streamlined.rules.accept.toast.success.mounted",
  AccountingStreamlinedRulesAcceptToastFailedMounted = "accounting.streamlined.rules.accept.toast.failed.mounted",
  AccountingStreamlinedRuleInvalid = "accounting.streamlined.rules.invalid",
  // Accounting Custom Report Accounting Education Banner Events
  AccountingCustomReportAccountingBannerMounted = "accounting.customReportAccountingBanner.mounted",
  AccountingCustomReportAccountingBannerAccountingLinkClicked = "accounting.customReportAccountingBanner.accountingLink.clicked",
  AccountingCustomReportAccountingBannerIsTargetForEducation = "accounting.customReportAccountingBanner.isTargetForEducation",
  AccountingCustomReportAccountingBannerDoNotShowAgainClicked = "accounting.customReportAccountingBanner.doNotShowAgain.clicked",
  AccountingCustomReportAccountingBannerDoNotShowAgainErrorMounted = "accounting.customReportAccountingBanner.doNotShowAgain.error.mounted",
  // AccountingEntityPane - Tax Code Section
  AccountingEntityDetailsPaneTaxCodeSectionToggled = "accounting.entityDetailsPane.taxCodeSection.toggled",
  AccountingEntityDetailsPaneTaxCodeSectionToggledFailed = "accounting.entityDetailsPane.taxCodeSection.toggled.failed",
  AccountingEntityDetailsPaneTaxCodeSectionMounted = "accounting.entityDetailsPane.taxCodeSection.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionOpened = "accounting.entityDetailsPane.taxCodeSection.opened",
  AccountingEntityDetailsPaneTaxCodeSectionLegalEntityFetchErrorMounted = "accounting.entityDetailsPane.taxCodeSection.legalEntityFetchError.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionVatExtendedFieldsFetchErrorMounted = "accounting.entityDetailsPane.taxCodeSection.vatExtendedFieldsFetchError.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionMappingConflictBannerMounted = "accounting.entityDetailsPane.taxCodeSection.mappingConflictBanner.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionSubsidiaryInactiveBannerMounted = "accounting.entityDetailsPane.taxCodeSection.subsidiaryInactiveBanner.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionUnmappedLegalEntityBannerMounted = "accounting.entityDetailsPane.taxCodeSection.unmappedLegalEntityBanner.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionNoVatCodesBannerMounted = "accounting.entityDetailsPane.taxCodeSection.noVatCodesBanner.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionGenericErrorMounted = "accounting.entityDetailsPane.taxCodeSection.genericError.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionOptionsListMounted = "accounting.entityDetailsPane.taxCodeSection.optionsList.mounted",
  AccountingEntityDetailsPaneTaxCodeSectionOptionToggled = "accounting.entityDetailsPane.taxCodeSection.option.toggled",
  AccountingEntityDetailsPaneTaxCodeSectionOptionToggledFailed = "accounting.entityDetailsPane.taxCodeSection.option.toggled.failed",
  AccountingEntityDetailsPaneTaxCodeSectionGLAccountWarningBannerMounted = "accounting.entityDetailsPane.taxCodeSection.glAccountWarningBanner.mounted",
  AccountingEntityDetailsTaxCodeSectionUpdateTaxPurchaseAccountsUpdated = "accounting.entityDetailsPane.taxCodeSection.update.taxPurchaseAccounts.updated",
  AccountingEntityDetailsTaxCodeSectionUpdateTaxPurchaseAccountsFailed = "accounting.entityDetailsPane.taxCodeSection.update.taxPurchaseAccounts.failed",
  // AccountingExpenseAccruedDateField (details pane)
  AccountingExpenseAccruedDateFieldMounted = "accounting.expenseAccruedDateField.mounted",
  // AccountingExpenseVatField
  AccountingExpenseVatFieldMounted = "accounting.expenseVatField.mounted",
  AccountingExpenseVatFieldRateInputFocused = "accounting.expenseVatField.rateInput.focused",
  AccountingExpenseVatFieldRateInputChanged = "accounting.expenseVatField.rateInput.changed",
  AccountingExpenseVatFieldAmountInputFocused = "accounting.expenseVatField.amountInput.focused",
  AccountingExpenseVatFieldAmountInputChanged = "accounting.expenseVatField.amountInput.changed",
  // AccountingExportHistory
  AccountingExportHistoryMounted = "accounting.export.history.mounted",
  // AccountingExportHistory - Tabs
  AccountingExportHistoryTabsLayoutMounted = "accounting.export.history.tabs_layout.mounted",
  AccountingExportHistoryTabClicked = "accounting.export.history.tab.clicked",
  AccountingExportHistoryTabEducationSeen = "accounting.export.history.tab.education.seen",
  AccountingExportHistoryTabEducationCompleted = "accounting.export.history.tab.education.completed",
  // AccountingExportHistory - Table
  AccountingExportHistoryTableLayoutMounted = "accounting.export.history.table_layout.mounted",
  AccountingExportHistoryTableErrorMounted = "accounting.export.history.table.error.mounted",
  AccountingExportHistoryTableErrorRetryClicked = "accounting.export.history.table.error.retry.clicked",
  AccountingExportHistoryTableRowClicked = "accounting.export.history.table.row.clicked",
  AccountingExportHistoryTableSeeMoreClicked = "accounting.export.history.table.see_more.clicked",
  AccountingExportHistoryTableErpDeeplinkClicked = "accounting.export.history.table.erp_deeplink.clicked",
  AccountingExportHistoryTableDownloadCsvClicked = "accounting.export.history.table.download_csv.clicked",
  AccountingExportHistoryTableAmountClicked = "accounting.export.history.table.amount.clicked",
  // AccountingExportHistory - Column Sort
  AccountingExportHistoryTableColumnSortClicked = "accounting.export.history.table.column_sort.clicked",
  // AccountingExportHistory - Filters
  AccountingExportHistoryFiltersDateFromSelected = "accounting.export.history.filters.date_from.selected",
  AccountingExportHistoryFiltersDateToSelected = "accounting.export.history.filters.date_to.selected",
  AccountingExportHistoryFiltersStatusSelected = "accounting.export.history.filters.status.selected",
  AccountingExportHistoryFiltersTypeMounted = "accounting.export.history.filters.type.selected",
  // AccountingExportHistory - Details Pane
  AccountingExportHistoryDetailsPaneMounted = "accounting.export.history.details_pane.mounted",
  AccountingExportHistoryDetailsPaneCloseClicked = "accounting.export.history.details_pane.close.clicked",
  AccountingExportHistoryDetailsPaneStatusBannerMounted = "accounting.export.history.details_pane.status_banner.mounted",
  AccountingExportHistoryDetailsPaneErrorMounted = "accounting.export.history.details_pane.error.mounted",
  AccountingExportHistoryDetailsPaneErrorRetryClicked = "accounting.export.history.details_pane.error.retry.clicked",
  AccountingExportHistoryDetailsPaneSendBackClicked = "accounting.export.history.send_back.clicked",
  AccountingExportHistoryDetailsPaneViewUnexportedClicked = "accounting.export.history.details_pane.view_unexported.clicked",
  AccountingExportHistoryDetailsPaneViewExportedClicked = "accounting.export.history.details_pane.view_exported.clicked",
  AccountingExportHistoryDetailsPaneViewSentBackClicked = "accounting.export.history.details_pane.view_sent_back.clicked",
  // AccountingExportHistory - Routing modal
  AccountingExportHistoryRoutingModalMounted = "accounting.export.history.routing_modal.mounted",
  AccountingExportHistoryRoutingModalCloseClicked = "accounting.export.history.routing_modal.close.clicked",
  AccountingExportHistoryRoutingModalViewReviewClicked = "accounting.export.history.routing_modal.view_review.clicked",
  AccountingExportHistoryRoutingModalViewReadyForExportClicked = "accounting.export.history.routing_modal.view_ready_for_export.clicked",
  // AccountingExportHistory - Send back modal
  AccountingExportHistorySendBackModalMounted = "accounting.export.history.send_back_modal.mounted",
  AccountingExportHistorySendBackModalSendBackClicked = "accounting.export.history.send_back_modal.send_back.clicked",
  AccountingExportHistorySendBackModalCancelClicked = "accounting.export.history.send_back_modal.cancel.clicked",
  AccountingExportHistorySendBackModalToastSuccessMounted = "accounting.export.history.send_back_modal.toast.success.mounted",
  AccountingExportHistorySendBackModalErrorMounted = "accounting.export.history.send_back_modal.error.mounted",
  // AccountingExportHistory - Export Drilldown
  AccountingExportHistoryExportDrilldownMounted = "accounting.export.history.export_drilldown.mounted",
  // AccountingExportHistory - Transactions Table
  AccountingExportHistoryTransactionsTableErpDeeplinkClicked = "accounting.export.history.transactions_table.erp_deeplink.clicked",
  // AccountingExportHistory - Expense Details Pane
  AccountingExportHistoryExpenseDetailsPaneErpDeeplinkClicked = "accounting.export.history.expense_details_pane..erp_deeplink.clicked",
  // AccountingJournal
  AccountingJournalMounted = "accounting.journal.mounted",
  // AccountingJournal - Table Core
  AccountingJournalTableErrorMounted = "accounting.journal.table.error.mounted",
  AccountingJournalTableErrorRetryClicked = "accounting.journal.table.error.retry.clicked",
  AccountingJournalTableRowChecked = "accounting.journal.table.row.checked",
  AccountingJournalTableRowClicked = "accounting.journal.table.row.clicked",
  AccountingJournalTableRowFieldChanged = "accounting.journal.table.row.field.changed",
  AccountingJournalTableRowFieldCleared = "accounting.journal.table.row.field.cleared",
  AccountingJournalTableSeeMoreClicked = "accounting.journal.table.see_more.clicked",
  AccountingJournalTableSelectAllRowsClicked = "accounting.journal.table.selectAllRows.clicked",
  AccountingJournalTableReloadClicked = "accounting.journal.table.reload.clicked",
  // AccountingJournal - Bulk Edit
  AccountingJournalBulkEditModalMounted = "accounting.journal.bulk_edit_modal.mounted",
  AccountingJournalBulkEditModalEditClicked = "accounting.journal.bulk_edit_modal.edit.clicked",
  AccountingJournalBulkEditModalCancelClicked = "accounting.journal.bulk_edit_modal.cancel.clicked",
  AccountingJournalBulkEditModalToastSuccessMounted = "accounting.journal.bulk_edit_modal.toast.success.mounted",
  AccountingJournalBulkEditModalToastErrorMounted = "accounting.journal.bulk_edit_modal.toast.error.mounted",
  // AccountingJournal - Bulk Edit Education
  AccountingJournalBulkEditEducationToastMounted = "accounting.journal.bulk_edit_education.toast.mounted",
  AccountingJournalBulkEditEducationToastDismissedClicked = "accounting.journal.bulk_edit_education.toast.dismissed.clicked",
  // AccountingJournal - Bulk Suggestion
  AccountingJournalBulkSuggestionModalMounted = "accounting.journal.bulk_suggestion_modal.mounted",
  AccountingJournalBulkSuggestionModalCreateNewRuleClicked = "accounting.journal.bulk_suggestion_modal.create_new_rule.clicked",
  AccountingJournalBulkSuggestionModalCancelClicked = "accounting.journal.bulk_suggestion_modal.cancel.clicked",
  // AccountingJournal - Column Settings
  AccountingJournalTableColumnSettingsMounted = "accounting.journal.table.column_settings.mounted",
  AccountingJournalTableColumnSettingsToggleOnClicked = "accounting.journal.table.column_settings.toggle_on.clicked",
  AccountingJournalTableColumnSettingsToggleOffClicked = "accounting.journal.table.column_settings.toggle_off.clicked",
  AccountingJournalTableColumnSettingsColumnDragged = "accounting.journal.table.column_settings.column.dragged",
  AccountingJournalTableColumnSettingsWarningMounted = "accounting.journal.table.column_settings.warning.mounted",
  AccountingJournalTableColumnSettingsWarningClicked = "accounting.journal.table.column_settings.warning.clicked",
  // AccountingJournal - Column Sort
  AccountingJournalTableColumnSortClicked = "accounting.journal.table.column_sort.clicked",
  // AccountingJournal - Filters
  AccountingJournalFiltersSearchBySelected = "accounting.journal.filters.search_by.selected",
  AccountingJournalFiltersDateSelected = "accounting.journal.filters.date.selected",
  AccountingJournalFiltersUserCategorySelected = "accounting.journal.filters.user_category.selected",
  AccountingJournalFiltersTypeSelected = "accounting.journal.filters.type.selected",
  AccountingJournalFiltersAlertSelected = "accounting.journal.filters.alert.selected",
  AccountingJournalFiltersAmountSelected = "accounting.journal.filters.amount.selected",
  AccountingJournalFiltersBudgetSelected = "accounting.journal.filters.budget.selected",
  AccountingJournalFiltersSpendingEntitySelected = "accounting.journal.filters.spendingEntity.selected",
  AccountingJournalFiltersCustomRuleSelected = "accounting.journal.filters.custom_rule.selected",
  // AccountingJournal - Expense Category Inline Edit Alert
  AccountingJournalExpenseCategoryInlineEditAlertMounted = "accounting.journal.expense_category_inline_edit_alert.mounted",
  AccountingJournalExpenseCategoryInlineEditAlertSaveClicked = "accounting.journal.expense_category_inline_edit_alert.save.clicked",
  AccountingJournalExpenseCategoryInlineEditAlertCancelClicked = "accounting.journal.expense_category_inline_edit_alert.cancel.clicked",
  // AccountingJournal - Real Select All
  AccountingJournalSelectAllButtonMounted = "accounting.journal.select_all.button.mounted",
  AccountingJournalSelectAllButtonSelectClicked = "accounting.journal.select_all.button.select.clicked",
  AccountingJournalSelectAllButtonClearClicked = "accounting.journal.select_all.button.clear.clicked",
  AccountingJournalSelectAllDisabled = "accounting.journal.select_all.disabled",
  // AccountingJournal - Status Changing
  AccountingJournalMarkReadyClicked = "accounting.journal.mark_ready.clicked",
  AccountingJournalSendBackToReviewClicked = "accounting.journal.send_back_to_review.clicked",
  AccountingJournalExportClicked = "accounting.journal.export.clicked",
  // AccountingJournal - Status Changing Toasts
  AccountingJournalReviewStatusChangeToastSuccessMounted = "accounting.journal.review_status_change.toast.success.mounted",
  AccountingJournalReviewStatusChangeToastFailedMounted = "accounting.journal.review_status_change.toast.failed.mounted",
  AccountingJournalReviewStatusChangeToastFailedVerificationMounted = "accounting.journal.review_status_change.toast.failed_verification.mounted",
  // Accounting Journal - Reports
  AccountingJournalReportModalTriggerMounted = "accounting.journal.report.modal.trigger.mounted",
  AccountingJournalReportModalTriggerClicked = "accounting.journal.report.modal.trigger.clicked",
  AccountingJournalReportModalMounted = "accounting.journal.report.modal.mounted",
  AccountingJournalReportModalCloseClicked = "accounting.journal.report.modal.close.clicked",
  AccountingJournalReportModalFormatChanged = "accounting.journal.report.modal.format.changed",
  AccountingJournalReportModalDownloadClicked = "accounting.journal.report.modal.download.clicked",
  AccountingJournalReportModalDownloadError = "accounting.journal.report.modal.download.error",
  AccountingJournalReportModalManualDownloadClicked = "accounting.journal.report.modal.manual_download.clicked",
  AccountingJournalReportModalRetryDownloadClicked = "accounting.journal.report.modal.retry_download.clicked",
  // Accounting Journal - Export Total Amounts
  AccountingJournalExportTotalAmountsMounted = "accounting.journal.export_total_amounts.mounted",
  AccountingJournalExportTotalAmountsError = "accounting.journal.export_total_amounts.clicked",
  // AccountingJournalDatagrid - Bulk Edit
  AccountingJournalDatagridBulkEditBannerMounted = "accounting.journal.datagrid.bulk_edit_modal.banner.mounted",
  // AccountingJournalDatagrid - Shell
  AccountingJournalDatagridMounted = "accounting.journal.datagrid.mounted",
  AccountingJournalDatagridShelfClicked = "accounting.journal.datagrid.shelf.clicked",
  AccountingJournalDatagridTabClicked = "accounting.journal.datagrid.tab.clicked",
  AccountingJournalDatagridShelfErpMissingGLsMounted = "accounting.journal.datagrid.shelf.erp.missing_gls.mounted",
  // AccountingJournalDatagrid - Persisted views
  AccountingJournalDatagridApplyPersistedView = "accounting.journal.datagrid.apply.persisted.view",
  // AccountingJournalDatagrid - Inline row updates
  AccountingJournalDatagridTableRowFieldChanged = "accounting.journal.datagrid.table.row.field.changed",
  AccountingJournalDatagridTableRowFieldChangedErrorMounted = "accounting.journal.datagrid.table.row.field.changed.error.mounted",
  // AccountingJournalDatagrid - Review status movement
  AccountingJournalDatagridReviewStatusUpdateButtonClicked = "accounting.journal.datagrid.review_status.update.button.clicked",
  AccountingJournalDatagridReviewStatusErrorToastMounted = "accounting.journal.datagrid.review_status.error.toast.mounted",
  AccountingJournalDatagridReviewStatusSuccessToastMounted = "accounting.journal.datagrid.review_status.success.toast.mounted",
  // AccountingJournalDatagrid - Accruals
  AccountingJournalDatagridFTUXControllerMounted = "accounting.journal.datagrid.ftux_controller.mounted",
  AccountingJournalDatagridUpdateAccrualReversalDateClicked = "accounting.journal.datagrid.update.accrual_reversal_date.clicked",
  AccountingJournalDatagridUpdateAccrualReversalDateSuccess = "accounting.journal.datagrid.update.accrual_reversal_date.success",
  AccountingJournalDatagridUpdateAccrualReversalDateFailure = "accounting.journal.datagrid.update.accrual_reversal_date.failure",
  AccountingJournalDatagridUpdateAccrualStatusClicked = "accounting.journal.datagrid.update.accrual_status.clicked",
  AccountingJournalDatagridUpdateAccrualStatusSuccess = "accounting.journal.datagrid.update.accrual_status.success",
  AccountingJournalDatagridUpdateAccrualStatusFailure = "accounting.journal.datagrid.update.accrual_status.failure",
  AccountingJournalDatagridUpdateAccrualStatusPartialFailure = "accounting.journal.datagrid.update.accrual_status.partial_failure",
  AccountingAccrualsExportPreviewSummary = "accounting.accruals.export.preview.summary",
  // Accounting Automation - Home
  AccountingAutomationHubMounted = "accounting.automation.hub.mounted",
  AccountingAutomationHubViewAllClicked = "accounting.automation.hub.view_all.clicked",
  AccountingAutomationSegmentedControllerMounted = "accounting.automation.segmented_controller.mounted",
  AccountingAutomationSegmentedControllerTabClicked = "accounting.automation.segmented_controller.tab.clicked",
  AccountingAutomationSegmentedControllerMappingCreateClicked = "accounting.automation.segmented_controller.mapping.create.clicked",
  AccountingAutomationInvalidSuggestion = "accounting.automation.invalid.suggestion",
  // Accounting Automation - ML Suggestion
  AccountingAutomationMlSuggestionCustomizeClicked = "accounting.automation.ml_suggestion.customize.clicked",
  // Accounting Automation - Manual Suggestion
  AccountingAutomationManualSuggestionCreateDifferentClicked = "accounting.automation.manual_suggestion.create_different.clicked",
  AccountingAutomationManualSuggestionCreateClicked = "accounting.automation.manual_suggestion.create.clicked",
  AccountingAutomationManualSuggestionOptionValueClicked = "accounting.automation.manual_suggestion.option_value.clicked",
  // Accounting Automation - Suggestion Card
  AccountingAutomationSuggestionCardMounted = "accounting.automation.suggestion_card.mounted",
  AccountingAutomationSuggestionCardReviewRuleClicked = "accounting.automation.suggestion_card.review_rule.clicked",
  AccountingAutomationSuggestionCardSuggestionActionClicked = "accounting.automation.suggestion_card.action.clicked",
  // Accounting Automation - Suggestions Takeover
  AccountingAutomationSuggestionsTakeoverMounted = "accounting.automation.suggestions_takeover.mounted",
  AccountingAutomationSuggestionsTakeoverEmptyBannerMounted = "accounting.automation.suggestions_takeover.empty_banner.mounted",
  AccountingAutomationSuggestionsTakeoverAcceptBannerMounted = "accounting.automation.suggestions_takeover.accept_banner.mounted",
  AccountingAutomationSuggestionsTakeoverAcceptBannerViewClicked = "accounting.automation.suggestions_takeover.accept_banner.view.clicked",
  AccountingAutomationSuggestionsTakeoverAcceptBannerCloseClicked = "accounting.automation.suggestions_takeover.accept_banner.close.clicked",
  AccountingAutomationSuggestionsTakeoverErrorMounted = "accounting.automation.suggestions_takeover.error.mounted",
  AccountingAutomationSuggestionsTakeoverFilterSelected = "accounting.automation.suggestions_takeover.filter.selected",
  AccountingAutomationSuggestionsTakeoverAcceptVisibleClicked = "accounting.automation.suggestions_takeover.accept_visible.clicked",
  AccountingAutomationSuggestionsTakeoverAcceptVisibleConfirmClicked = "accounting.automation.suggestions_takeover.accept_visible.confirm.clicked",
  AccountingAutomationSuggestionsTakeoverAcceptVisibleCancelClicked = "accounting.automation.suggestions_takeover.accept_visible.cancel.clicked",
  AccountingAutomationSuggestionsTakeoverAcceptAllClicked = "accounting.automation.suggestions_takeover.accept_all.clicked",
  AccountingAutomationSuggestionsTakeoverAcceptAllConfirmClicked = "accounting.automation.suggestions_takeover.accept_all.confirm.clicked",
  AccountingAutomationSuggestionsTakeoverAcceptAllCancelClicked = "accounting.automation.suggestions_takeover.accept_all.cancel.clicked",
  AccountingAutomationSuggestionsTakeoverConfirmBulkAcceptMounted = "accounting.automation.suggestions_takeover.confirm_bulk_accept.mounted",
  AccountingAutomationSuggestionsTakeoverSeeMoreClicked = "accounting.automation.suggestions_takeover.see_more.clicked",
  // Accounting Automation - Update Toasts
  AccountingAutomationToastSuccessMounted = "accounting.automation.toast.success.mounted",
  AccountingAutomationToastSuccessManageClicked = "accounting.automation.toast.success.manage.clicked",
  AccountingAutomationToastErrorMounted = "accounting.automation.toast.error.mounted",
  AccountingAutomationToastErrorDeclineMounted = "accounting.automation.toast.error_decline.mounted",
  // Accounting Automation - Accounting Journal Cell Create rule
  AccountingAutomationAccountingJournalCellCreateMlRuleClicked = "accounting.automation.accounting_journal.cell.create_ml_rule.clicked",
  AccountingAutomationAccountingJournalCellCreateManualRuleClicked = "accounting.automation.accounting_journal.cell.create_manual_rule.clicked",
  AccountingAutomationAccountingJournalShelfSuggestionsCountMounted = "accounting.automation.accounting_journal.shelf_suggestions_count.mounted",
  // Accounting Automation - FTUX and Pre AJ Datagrid
  AccountingAutomationPreDatagridSuggestionCardMounted = "accounting.automation.pre_datagrid.suggestion_card.mounted",
  // Accounting Expense Category Automation
  AccountingExpenseCategoryDropdownPrefilledIconMounted = "accounting.expense_category_dropdown.prefilled_icon.mounted",
  AccountingExpenseCategoryDropdownOpened = "accounting.expense_category_dropdown.opened",
  AccountingExpenseCategoryDropdownSelected = "accounting.expense_category_dropdown.selected",
  // Accounting WorkflowRule Impacted Transactions Table
  AccountingWorkflowRuleImpactedTransactionsTableMounted = "accounting.workflow_rule.impacted_transactions_table.mounted",
  AccountingWorkflowRuleImpactedTransactionsClosePreviewClicked = "accounting.workflow_rule.impacted_transactions_close_preview.clicked",
  AccountingWorkflowRuleImpactedTransactionsHidePreviewClicked = "accounting.workflow_rule.impacted_transactions_hide_preview.clicked",
  AccountingWorkflowRuleImpactedTransactionsOpenPreviewClicked = "accounting.workflow_rule.impacted_transactions_open_preview.clicked",
  AccountingWorkflowRuleImpactedTransactionsTogglePreviewClicked = "accounting.workflow_rule.impacted_transactions_toggle_preview.clicked",
  // Accounting Integration Banner
  AccountingIntegrationAlertCtaClicked = "accounting.integration.alert.cta.clicked",
  // Accounting Jobs Menu
  AccountingJobsMenuMounted = "accounting.jobs_menu.mounted",
  AccountingJobsMenuOpened = "accounting.jobs_menu.open",
  AccountingJobsMenuClearAll = "accounting.jobs_menu.clear_all",
  AccountingJobsMenuItemExpanded = "accounting.jobs_menu.item.expanded",
  AccountingJobsMenuItemDismissed = "accounting.jobs_menu.item.dismissed",
  AccountingJobsMenuItemDownloadClicked = "accounting.jobs_menu.item.download.clicked",
  // Audit Trail
  AuditTrailRowClicked = "audit_trail.row.clicked",
  AuditTrailFilterApplied = "audit_trail.filter.applied",
  AuditTrailPersistedViewsApplied = "audit_trail.persisted_views.applied",
  AuditTrailSearchApplied = "audit_trail.search.applied",
  AuditTrailSeeMoreClicked = "audit_trail.see_more.clicked",
  AuditTrailTableMounted = "audit_trail.table.mounted",
  AuditTrailDetailPaneDeepLinkClicked = "audit_trail.detail_pane.deep_link.clicked",
  AuditTrailDownloadClicked = "audit_trail.download.clicked",
  SecurityShelfCtaClicked = "security.shelf.cta.clicked",
  // AsyncExport - Banners
  AsyncExportBannerMounted = "async.export.banner.mounted",
  AsyncExportBannerDismissClicked = "async.export.banner.dismiss.clicked",
  AsyncExportBannerDownloadClicked = "async.export.banner.download.clicked",
  // AsyncExport - Selector
  AsyncExportSelectorMounted = "async.export.selector.mounted",
  AsyncExportSelectorCheckboxClicked = "async.export.selector.checkbox.clicked",
  // Banking
  BankingACHRemainingLimitExceeded = "cash.ach.remaining.limit.exceeded",
  BankingAddAccountEntryClicked = "banking.add_account.entry.clicked",
  BankingAddAccountMounted = "banking.add_account.mounted",
  BankingAccountPickerClicked = "banking.account-picker.clicked",
  BankingAddAccountSubmitted = "banking.add_account.submitted",
  BankingFDICSweepLearnMoreClicked = "banking.fdic_sweep.learn_more.clicked",
  BankingTableViewClicked = "banking.table_view.clicked",
  BankingTableFilterByClicked = "banking.table.filter_by.clicked",
  BankingIntlWireInstructionRequirementsHCArticleClicked = "banking.intl_wire_instruction_requirements.hc_article.clicked",
  FirstTimeExperienceBankingTableAddFundsClicked = "first_time_experience.banking.table.add_funds.clicked",
  FirstTimeExperienceBankingTableTransferFundsClicked = "first_time_experience.banking.table.transfer_funds.clicked",
  FirstTimeExperienceBankingTableLearnMoreClicked = "first_time_experience.banking.table.learn_more.clicked",
  //Banking - Migration Wizard
  BankingMigrationWizardMounted = "banking.migration_wizard.mounted",
  BankingMigrationWizardExitClicked = "banking.migration_wizard.exit.clicked",
  BankingMigrationWizardStartClicked = "banking.migrartion_wizard.start.clicked",
  BankingMigrationWizardPrimaryClicked = "banking.migration_wizard.primary.clicked",
  BankingMigrationWizardSecondaryClicked = "banking.migration_wizard.secondary.clicked",
  BankingMigrationWizardDetailsPaneMounted = "banking.migration_wizard.details_pane.mounted",
  BankingMigrationWizardBannerCtaClicked = "banking.migration_wizard.banner_cta.clicked",
  BankingMigrationWizardExitModalStayHereClicked = "banking.migration_wizard.exit_modal.stay_here.clicked",
  BankingMigrationWizardSuccessToastMounted = "banking.migration_wizard.success_toast.mounted",
  // Banking - Principal Protection Fees
  BankingPProFeesManagePaymentMethodsClicked = "banking.ppro_fees.manage_payment_methods.clicked",
  BankingPProFeesManagePaymentMethodsTakeoverMounted = "banking.ppro_fees.manage_payment_methods.takeover.mounted",
  BankingPProFeesManagePaymentMethodsTakeoverExited = "banking.ppro_fees.manage_payment_methods.takeover.exited",
  BankingPProFeesManagePaymentMethodsTakeoverPrimaryClicked = "banking.ppro_fees.manage_payment_methods.takeover.primary.clicked",
  // Integrations List Events
  IntegrationsListViewMounted = "integrations.list.view.mounted",
  IntegrationsERPListViewMounted = "integrations.erp.list.view.mounted",
  IntegrationsListChangeSearchTerm = "integrations.list.change.search.term",
  IntegrationsListSelectSearchTerm = "integrations.list.select.search.term",
  IntegrationsListSelectSuggestion = "integrations.list.select.suggestion",
  IntegrationsListConnectClicked = "integrations.list.connect.clicked",
  IntegrationsListManageClicked = "integrations.list.manage.clicked",
  IntegrationsListDisconnectClicked = "integrations.list.disconnect.clicked",
  IntegrationsListDisconnectBankfeedClicked = "integrations.list.disconnect.bankfeed.clicked",
  IntegrationsListRefreshAttemptClicked = "integrations.list.refresh_attempt.clicked",
  IntegrationsListRefreshAttemptSuccess = "integrations.list.refresh_attempt.success",
  IntegrationsListRefreshAttemptFailure = "integrations.list.refresh_attempt.failure",
  IntegrationsListUpdateCredentialsClicked = "integrations.list.update.credentials.clicked",
  // Integrations Connect ERP Integration Events
  IntegrationsConnectERPViewMounted = "integrations.connect.erp.view.mounted",
  IntegrationsConnectERPNoVendor = "integrations.connect.erp.no.vendor.error",
  IntegrationsConnectERPNoIntegration = "integrations.connect.erp.no.integration.error",
  IntegrationsSetupERPViewMounted = "integrations.setup.erp.view.mounted",
  IntegrationsReconnectViewMounted = "integrations.reconnect.view.mounted",
  IntegrationsConnectERPCannotFindIntegrationClicked = "integrations.connect.erp.cannot.find.integration.clicked",
  IntegrationsConnectERPMultiEntityNotSupportedBannerCustomAccountingOptionClicked = "integrations.connect.erp.multientity.notsupported.banner.customaccounting.clicked",
  IntegrationsConnectERPStepContinueClicked = "integrations.connect.erp.step.continue.clicked",
  IntegrationsConnectERPStepBackClicked = "integrations.connect.erp.step.back.clicked",
  IntegrationsConnectERPStepExitClicked = "integrations.connect.erp.step.exit.clicked",
  // Integrations Detail
  IntegrationsDetailViewMounted = "integrations.detail.view.mounted",
  IntegrationsGlSelectionClicked = "integrations.gl_selection.clicked",
  IntegrationsHrisMapEntitiesClicked = "integrations.hris.map.entities.clicked",
  IntegrationsUpdateExportCadenceClicked = "integrations.update_export_cadence.clicked",
  IntegrationsUpdateTransactionExportTypeClicked = "integrations.update_transaction_export_type.clicked",
  IntegrationsUpdateNetsuiteNameFieldSettingsClicked = "integrations.update_netsuite_name_field_settings_clicked",
  IntegrationsManagementArticleRowMounted = "integrations.management.article_row.mounted",
  IntegrationsManagementArticleRowCtaClicked = "integrations.management.article_row.cta.clicked",
  IntegrationsManagementGlAccountTypeInvalid = "integrations.management.gl_account.type.invalid",
  IntegrationsManagementGlAccountTypeValidEmpty = "integrations.management.gl_account.type.valid.empty", // Used when there are no valid types.
  // Implementation Panel Events
  ImplementationSetupViewMounted = "implementation.setup.view.mounted",
  ImplementationSetupSeeWhereClicked = "implementation.setup.see_where.clicked",
  // Start of Uber Integration related events
  UberIntegrationDisconnectClicked = "integrations.uber.disconnect.clicked",
  // End of Uber Integration related events
  // End of ACCOUNTING related instrumentation
  // ACCOUNTING
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // BUDGETS
  // Start of Budgets owned instrumentation
  AutoLimitAssignmentFtuxViewed = "auto_limit_assignment.ftux.viewed",
  AutoLimitAssignmentFtuxNotNowClicked = "auto_limit_assignment.ftux.not_now.clicked",
  AutoLimitAssignmentFtuxEnableClicked = "auto_limit_assignment.ftux.enable.clicked",
  AutoLimitAssignmentFtuxLearnMoreClicked = "auto_limit_assignment.ftux.learn_more.clicked",
  AutoLimitAssignmentFtuxDismissed = "auto_limit_assignment.ftux.dismissed",
  AutoLimitAssignmentToggleViewed = "auto_limit_assignment.toggle.viewed",
  AutoLimitAssignmentToggleEnabled = "auto_limit_assignment.toggle.enabled",
  AutoLimitAssignmentToggleDisabled = "auto_limit_assignment.toggle.disabled",
  // Please check go/budgets-events for guidelines
  BifurcatedExperimentViewed = "bifurcated.experiment.viewed",
  // Budget - Form
  BudgetCreateFlowStartClicked = "budget.create_flow.start.clicked",
  BudgetCreateFlowContinueClicked = "budget.create_flow.continue.clicked",
  BudgetCreateFlowReviewClicked = "budget.create_flow.review.clicked",
  BudgetCreateFlowSaveClicked = "budget.create_flow.save.clicked",
  BudgetCreateFlowDoneClicked = "budget.create_flow.done.clicked",
  BudgetCreateFlowViewPolicyClicked = "budget.create_flow.view_policy.clicked",
  BudgetEditFlowStartClicked = "budget.edit_flow.start.clicked",
  BudgetEditFlowContinueClicked = "budget.edit_flow.continue.clicked",
  BudgetEditFlowReviewClicked = "budget.edit_flow.review.clicked",
  BudgetEditFlowSaveClicked = "budget.edit_flow.save.clicked",
  BudgetEditFlowViewPolicyClicked = "budget.edit_flow.view_policy.clicked",
  BudgetFormEditFlowCancelRecurrenceClicked = "budget.form.edit_flow.cancel_recurrence.clicked",
  BudgetFormEditFlowConfirmRecurrenceClicked = "budget.form.edit_flow.confirm_recurrence.clicked",
  BudgetFormEditFlowRecurrenceClicked = "budget.form.edit_flow.recurrence.clicked",
  BudgetsFirstTimeCreationChoice = "budget.create_flow.first_time.start.clicked",
  // Please check go/budgets-events for guidelines
  // Budget and Spend Limit simplified events
  BudgetFormStepMounted = "budget.form.step.mounted",
  BudgetFormOverlayMounted = "budget.form.overlay.mounted",
  BudgetFormCtaClicked = "budget.form.cta.clicked",
  BudgetFormToggleClicked = "budget.form.toggle.clicked",
  BudgetFormQueryFailure = "budget.form.query.failure",
  BudgetFormMutationFailure = "budget.form.budget_distribution_mutation.failure",
  BudgetFormBudgetCreated = "budget.form.budget.created",
  BudgetFormBudgetEdited = "budget.form.budget.edited",
  BudgetFormValidationErrors = "budget.form.validation.errors",
  // Please check go/budgets-events for guidelines
  // Budget - Budget from budget request form
  BudgetFormInsufficientUserPermissionsRedirected = "budget.form.insufficient_user_permissions.redirected",
  // Please check go/budgets-events for guidelines
  // Budget - User Limit Form (Essentials)
  BudgetFormUserLimitMounted = "budget.form.user_limit_form.mounted",
  BudgetFormUserLimitSubmitted = "budget.form.user_limit_form.submitted",
  // Please check go/budgets-events for guidelines
  // Budget - Page
  BudgetDeleteBudgetClicked = "budget.delete.budget.clicked",
  BudgetDeleteBudgetConfirmClicked = "budget.delete.budget_confirm.clicked",
  BudgetTerminateBudgetClicked = "budget.terminate.budget.clicked",
  BudgetTerminateBudgetConfirmClicked = "budget.terminate.budget_confirm.clicked",
  BudgetPageActionsMoveBudgetClicked = "budget.page.actions.move_budget.clicked",
  BudgetPageActionsConfirmMoveBudgetClicked = "budget.page.actions.confirm_move_budget.clicked",
  BudgetPageActionsMoveBudgetParentBudgetChanged = "budget.page.actions.move_budget_parent_budget.changed",
  BudgetPageActionsMoveBudgetConflictMounted = "budget.page.actions.move_budget_conflict.mounted",
  BudgetPageActionsMoveBudgetErrorMounted = "budget.page.actions.move_budget_error.mounted",
  BudgetPageAllocatedCalculationButtonClicked = "budgets.page.allocated_calculation.button.clicked",
  BudgetComponentBulkManagementBulkActionMenuMounted = "budget.component.bulk_management.bulk_action_menu.mounted",
  BudgetComponentBulkManagementBulkActionClicked = "budget.component.bulk_management.bulk_action.clicked",
  BudgetComponentBulkManagementBulkActionSubmitted = "budget.component.bulk_management.bulk_action.submitted",
  BudgetDrilldownTabChanged = "budget.drilldown.tab.changed",
  BudgetDrilldownTransactionDetailsClicked = "budget.drilldown.transaction_details.clicked",
  BudgetDrilldownViewPolicyClicked = "budget.drilldown.view_policy.clicked",
  BudgetDrilldownViewCardClicked = "budget.drilldown.view_card.clicked",
  BudgetDrilldownCreateCardClicked = "budget.drilldown.create_card.clicked",
  BudgetDrilldownCreateCardSuccess = "budget.drilldown.create_card.success",
  BudgetDrilldownCreateCardError = "budget.drilldown.create_card.error",
  BudgetDrilldownNewReimbursementClicked = "budget.drilldown.new_reimbursement.clicked",
  BudgetDrilldownNestedBudgetClicked = "budget.drilldown.nested_budget.clicked",
  BudgetDrilldownBudgetMemberClicked = "budget.drilldown.budget_member.clicked",
  BudgetDrilldownAddBudgetMembersClicked = "budget.drilldown.add_budget_members.clicked",
  BudgetDrilldownBudgetOwnerClicked = "budget.drilldown.budget_owner.clicked",
  BudgetDrilldownManageAlertsClicked = "budget.drilldown.manage_alerts.clicked",
  BudgetDrilldownViewAllMembersClicked = "budget.drilldown.view_all_members.clicked",
  BudgetDrilldownTripDetailsClicked = "budget.drilldown.trip.details.clicked",
  BudgetDrilldownGroupEventDetailsClicked = "budget.drilldown.group_event.details.clicked",
  BudgetUsersSortByClicked = "budget.users.sort_by.clicked",
  BudgetUsersSearchFieldSubmitted = "budget.users.search.submitted",
  BudgetLimitIncreaseRequestInfoBannerViewBudgetInboxClicked = "budget_limit_increase_request.info_banner.view_budget_inbox.clicked",
  BudgetLimitIncreaseRequestInfoBannerViewAllOutstandingRequestsClicked = "budget_limit_increase_request.info_banner.view_all_outstanding_requests.clicked",
  BudgetLimitIncreaseRequestInfoBannerViewPersonalPendingClicked = "budget_limit_increase_request.info_banner.view_personal_pending.clicked",
  BudgetLimitIncreaseRequestInfoBannerViewActiveTemporaryClicked = "budget_limit_increase_request.info_banner.view_active_temporary.clicked",
  BudgetLimitIncreaseRequestInfoBannerViewWalletClicked = "budget_limit_increase_request.info_banner.view_wallet.clicked",
  // Please check go/budgets-events for guidelines
  // Budget - Details pane
  BudgetDetailsPaneMounted = "budget.details_pane.mounted",
  BudgetDetailsPaneDefaultErrorMounted = "budget.details_pane.default_error.mounted",
  BudgetMembersOwnersDetailsPaneMounted = "budget.members_owners_details_pane.mounted",
  BudgetMembersOwnersDetailsPaneEditMounted = "budget.members_owners_details_pane.edit.mounted",
  BudgetMembersOwnersDetailsPaneEditClicked = "budget.members_owners_details_pane.edit.clicked",
  BudgetMembersOwnersDetailsPaneEditCancelClicked = "budget.members_owners_details_pane.edit.cancel",
  BudgetMembersOwnersDetailsPaneEditSaveClicked = "budget.members_owners_details_pane.edit.save.attempt",
  BudgetMembersOwnersDetailsPaneEditSaveError = "budget.members_owners_details_pane.edit.save.error",
  BudgetMembersOwnersDetailsPaneEditSaveSuccess = "budget.members_owners_details_pane.edit.save.success",
  BudgetMembersOwnersDetailsPaneOwnerClicked = "budget.members_owners_details_pane.owner.clicked",
  // Please check go/budgets-events for guidelines
  // Budgets - Page
  BudgetsOverviewTabChanged = "budgets.overview.tab.changed",
  BudgetsOverviewBudgetClicked = "budgets.overview.budget.clicked",
  BudgetsBudgetOffboardingAlertBannerMounted = "budgets.page.budget_offboarding_alert_banner.mounted",
  BudgetsBudgetOffboardingAlertBannerClicked = "budgets.page.budget_offboarding_alert_banner.clicked",
  BudgetsPageMembersPillTooltipViewAllMembersClicked = "budgets.page.members_pill_tooltip.view_all_members.clicked",
  BudgetsPageMembersPillTooltipViewDistributionRulesClicked = "budgets.page.members_pill_tooltip.view_distribution_rules.clicked",
  BudgetsBudgetProgramsBannerMounted = "budgets.budget_programs_banner.mounted",
  BudgetsBudgetProgramsBannerViewAllClicked = "budgets.budget_programs_banner.view_all.clicked",
  BudgetsBudgetProgramsBannerCreateClicked = "budgets.budget_programs_banner.create.clicked",
  BudgetsBudgetTemplatesBannerMounted = "budgets.budget_templates_banner.mounted",
  BudgetsBudgetTemplatesBannerViewAllClicked = "budgets.budget_templates_banner.view_all.clicked",
  BudgetsBudgetTemplatesBannerHotspotMounted = "budgets.budget_templates_banner.hotspot.mounted",
  BudgetsBudgetTemplatesBannerHotspotClicked = "budgets.budget_templates_banner.hotspot.clicked",
  BudgetsEssentialsEmptyStateBannerMounted = "budgets.essentials_empty_state_banner.mounted",
  BudgetsEssentialsEmptyStateBannerCTAClicked = "budgets.essentials_empty_state_banner.cta.clicked",
  BudgetsSearchFieldSubmitted = "budgets.search.field.submitted",
  BudgetsSearchFilterByClicked = "budgets.search.filter_by.clicked",
  BudgetsSearchFilterByStatusClicked = "budgets.search.filter_by_status.clicked",
  BudgetsSearchSortByClicked = "budgets.search.sort_by.clicked",
  BudgetsSearchShowExpiredClicked = "budgets.search.show_expired.clicked",
  BudgetsSearchSeeMoreClicked = "budgets.search.see_more.clicked",
  BudgetsSearchFilterV2Changed = "budgets.search.filter_v2.changed",
  // Budget request (Shared)
  BudgetRequestComponentApproveDenyMutationFailure = "budget_request.component.approve_deny.mutation.failure",

  // Budget request - Form V1
  BudgetRequestCreateMounted = "budget_request.create.mounted",
  BudgetRequestCreateNextClicked = "budget_request.create.next_clicked",
  BudgetRequestCreatePreviousClicked = "budget_request.create.previous_clicked",
  BudgetRequestCreateReviewModalMounted = "budget_request.create.review_modal_mounted",
  BudgetRequestCreateSaveClicked = "budget_request.create.save_clicked",
  BudgetRequestCreateSuccess = "budget_request.create.success",
  BudgetRequestDenyModalConfirmClicked = "budget_request.deny_modal.confirm_clicked",
  BudgetRequestDenyModalMounted = "budget_request.deny_modal.mounted",
  BudgetRequestDenyModalSuccess = "budget_request.deny_modal.success",

  // Please check go/budgets-events for guidelines
  // Budget request- Form V3
  BudgetRequestFormSelectBudgetTemplateStepMounted = "budget_request.form.select_budget_template_step.mounted",
  BudgetRequestFormBudgetRequestDetailsStepMounted = "budget_request.form.budget_request_details_step.mounted",
  BudgetRequestFormReviewStepMounted = "budget_request.form.review_step.mounted",
  BudgetRequestFormRedesignedBasicsStepMounted = "budget_request.form.redesigned_basics_step.mounted",
  BudgetRequestFormRedesignedDetailsStepMounted = "budget_request.form.redesigned_details_step.mounted",
  BudgetRequestFormRedesignedReviewStepMounted = "budget_request.form.redesigned_review_step.mounted",
  BudgetRequestFormInsufficientUserPermissionsRedirected = "budget_request.form.insufficient_user_permissions.redirected",
  BudgetRequestFormGlobalMccModalMounted = "budget_request.form.global_mcc_modal.mounted",
  BudgetRequestFormNavigationBackButtonClicked = "budget_request.form.navigation.back_button.clicked",
  BudgetRequestFormNavigationPrimaryButtonClicked = "budget_request.form.navigation.primary_button.clicked",
  BudgetRequestFormBudgetRequestPermissionsQueryFailure = "budget_request.form.budget_request_permissions_query.failure",
  BudgetRequestFormBudgetRequestDetailsQueryFailure = "budget_request.form.budget_request_details_query.failure",
  BudgetRequestFormBudgetTemplateOptionsQueryFailure = "budget_request.form.budget_template_options_query.failure",
  BudgetRequestFormBudgetTemplateDetailsQueryFailure = "budget_request.form.budget_template_details_query.failure",
  BudgetRequestFormNoTemplatesRedirected = "budget_request.form.no_templates.redirected",
  BudgetRequestFormDefaultErrorMounted = "budget_request.form.default_error.mounted",
  BudgetRequestFormUnsavedChangesMounted = "budget_request.form.unsaved_changes.mounted",
  BudgetRequestFormUnsavedChangesExitClicked = "budget_request.form.unsaved_changes.exit.clicked",
  BudgetRequestFormUnsavedChangesBackClicked = "budget_request.form.unsaved_changes.back.clicked",
  BudgetRequestFormNavigationCloseWithNoChangesClicked = "budget_request.form.navigation.close_with_no_changes.clicked",
  BudgetRequestFormPolicyPaneOpenPolicyClicked = "budget_request.form.policy_pane.open_policy.clicked",
  BudgetRequestFormTravelCostEstimatorValueChanged = "budget_request.form.travel_cost_estimator.value.changed",
  BudgetRequestFormApproveSuccess = "budget_request.form.approve.success",
  BudgetRequestFormApproveFailure = "budget_request.form.approve.failure",
  BudgetRequestFormDenySuccess = "budget_request.form.deny.success",
  BudgetRequestFormDenyFailure = "budget_request.form.deny.failure",
  BudgetRequestFormCreateSuccess = "budget_request.form.create.success",
  BudgetRequestFormCreateFailure = "budget_request.form.create.failure",
  BudgetRequestFormUpdateSuccess = "budget_request.form.update.success",
  BudgetRequestFormUpdateFailure = "budget_request.form.update.failure",
  BudgetRequestFormInvalidStateBeforeSubmissionFailure = "budget_request.form.invalid_state_before_submission.failure",
  BudgetRequestFormValidateBudgetRequestMccControlsConflictSuccess = "budget_request.form.validate_budget_request_mcc_controls_conflict.success",
  BudgetRequestFormValidateBudgetRequestMccControlsConflictFailure = "budget_request.form.validate_budget_request_mcc_controls_conflict.failure",

  // Please check go/budgets-events for guidelines
  // Budget request - Details pane
  BudgetRequestDetailsPaneClicked = "budget_request.details_pane.clicked",
  BudgetRequestDetailsPaneEditAndReviewClicked = "budget_request.details_pane.edit_and_review.clicked",
  BudgetRequestDetailsPaneEditClicked = "budget_request.details_pane.edit.clicked",
  BudgetRequestDetailsPaneMounted = "budget_request.details_pane.mounted",
  BudgetRequestDetailsPaneReviewClicked = "budget_request.details_pane.review.clicked",
  BudgetRequestDetailsPaneWithdrawConfirmClicked = "budget_request.details_pane.withdraw.confirm.clicked",
  BudgetRequestDetailsPaneWithdrawMounted = "budget_request.details_pane.withdraw.mounted",
  BudgetRequestDetailsPaneWithdrawSuccess = "budget_request.details_pane.withdraw.success",
  BudgetRequestDetailsPaneValidateBudgetRequestMccControlsConflictSuccess = "budget_request.details_pane.validate_budget_request_mcc_controls_conflict.success",
  BudgetRequestDetailsPaneValidateBudgetRequestMccControlsConflictFailure = "budget_request.details_pane.validate_budget_request_mcc_controls_conflict.failure",
  BudgetRequestEditMounted = "budget_request.edit.mounted",
  BudgetRequestEditNextClicked = "budget_request.edit.next_clicked",
  BudgetRequestEditPreviousClicked = "budget_request.edit.previous_clicked",
  BudgetRequestEditSaveClicked = "budget_request.edit.save_clicked",
  BudgetRequestEditSuccess = "budget_request.edit.success",
  BudgetRequestReviewApproveClicked = "budget_request.review.approve.clicked",
  BudgetRequestReviewApproveOverrideConfirmClicked = "budget_request.review.approve.override_confirm_clicked",
  BudgetRequestReviewApproveSuccess = "budget_request.review.approve.success",
  BudgetRequestReviewEditClicked = "budget_request.review.edit_clicked",
  BudgetRequestReviewMounted = "budget_request.review.mounted",
  // Please check go/budgets-events for guidelines
  // Budget attributes - Form
  BudgetAttributesFormDefaultErrorMounted = "budget_attributes.form.default_error.mounted",
  BudgetAttributesFormDefaultErrorRetryClicked = "budget_attributes.form.default_error.retry.clicked",
  BudgetAttributesFormUnsavedChangesMounted = "budget_attributes.form.unsaved_changes.mounted",
  BudgetAttributesFormUnsavedChangesExitClicked = "budget_attributes.form.unsaved_changes.exit.clicked",
  BudgetAttributesFormUnsavedChangesBackClicked = "budget_attributes.form.unsaved_changes.back.clicked",
  BudgetAttributesFormCloseWithNoChangesClicked = "budget_attributes.form.close_with_no_changes.clicked",
  BudgetAttributesFormOnSubmitBudgetTemplateQueryFailure = "budget_attributes.form.on_submit.budget_template_query.failure",
  BudgetAttributesFormPolicyDocumentMounted = "budget_attributes.form.policy_document.mounted",
  BudgetAttributesFormSelectBudgetTemplateStepMounted = "budget_attributes.form.select_budget_template_step.mounted",
  BudgetAttributesFormBudgetAttributesInputDetailsStepMounted = "budget_attributes.form.budget_attributes_input_details_step.mounted",
  BudgetAttributesFormBudgetAttributesReviewStepMounted = "budget_attributes.form.budget_attributes_review_step.mounted",
  BudgetAttributesFormPrimaryActionButtonClicked = "budget_attributes.form.primary_action_button.clicked",
  BudgetAttributesFormSecondaryActionButtonClicked = "budget_attributes.form.secondary_action_button.clicked",
  BudgetAttributesFormViewPolicyToggled = "budget_attributes.form.view_policy.toggled",
  BudgetAttributesFormGlobalMccControlsModalOpenClicked = "budget_attributes.form.global_mcc_controls_modal.open.clicked",
  BudgetAttributesFormGlobalMccControlsModalClosedClicked = "budget_attributes.form.global_mcc_controls_modal.closed.clicked",
  // Budgets V1 FTUX
  BifurcatedBudgetsEducationBannerMounted = "budgets_v1_ftux.banner.mounted",
  BifurcatedBudgetsEducationBannerExpanded = "budgets_v1_ftux.banner.expanded",
  BifurcatedBudgetsEducationBannerCollapsed = "budgets_v1_ftux.banner.collapsed",
  BifurcatedBudgetsEducationTutorialMounted = "budgets_v1_ftux.tutorial.mounted",
  BifurcatedBudgetsEducationTutorialExitClicked = "budgets_v1_ftux.tutorial.exit_clicked",
  BifurcatedBudgetsEducationTutorialCompleted = "budgets_v1_ftux.tutorial.completed",
  BifurcatedBudgetsEducationTutorialCancelClicked = "budgets_v1_ftux.tutorial.cancel_clicked",
  BifurcatedBudgetsEducationTutorialStartClicked = "budgets_v1_ftux.tutorial.start_clicked",
  BifurcatedBudgetsEducationTutorialNextTutorialClicked = "budgets_v1_ftux.tutorial.next_tutorial_clicked",
  BifurcatedBudgetsEducationTutorialNextClicked = "budgets_v1_ftux.tutorial.next_clicked",
  BifurcatedBudgetsEducationTutorialDoneClicked = "budgets_v1_ftux.tutorial.done_clicked",
  BifurcatedBudgetsEducationTutorialSkipClicked = "budgets_v1_ftux.tutorial.skip_clicked",
  BifurcatedBudgetsEducationTutorialBackClicked = "budgets_v1_ftux.tutorial.back_clicked",
  // Budgets Prelaunch Bifurcation
  BudgetsBifurcationPrelaunchBannerMounted = "budgets.bifurcation_prelaunch.banner.mounted",
  BudgetsBifurcationPrelaunchBannerLearnMoreClicked = "budgets.bifurcation_prelaunch.banner.learnmore.clicked",
  // Please check go/budgets-events for guidelines
  // Budget audit trail - Details pane
  BudgetDetailsPaneBudgetAuditTrailMounted = "budget.details_pane.budget_audit_trail.mounted",
  BudgetDetailsPaneBudgetAuditTrailSeeMoreClicked = "budget.details_pane.budget_audit_trail.see_more.clicked",
  BudgetDetailsPaneBudgetAuditTrailSectionToggled = "budget.details_pane.budget_audit_trail.section.toggled",
  // Please check go/budgets-events for guidelines
  // Budget manage notifications - Details pane
  BudgetManageNotificationsDetailsPaneMounted = "budget_manage_notifications.details_pane.mounted",
  BudgetManageNotificationsDetailsPaneSaveClicked = "budget_manage_notifications.details_pane.save.clicked",
  BudgetManageNotificationsDetailsPaneCancelClicked = "budget_manage_notifications.details_pane.cancel.clicked",
  // Please check go/budgets-events for guidelines
  // Budget limit increase request - Components
  BudgetLimitIncreaseClicked = "budget_limit_increase.clicked",
  BudgetLimitIncreaseRequestClicked = "budget_limit_increase_request.clicked",
  // Please check go/budgets-events for guidelines
  // Budget limit increase request - Form
  BudgetLimitIncreaseRequestCreateFlowCreateClicked = "budget_limit_increase_request.create_flow.create.clicked",
  BudgetLimitIncreaseRequestCreateFlowCreateSuccess = "budget_limit_increase_request.create_flow.create.success",
  BudgetLimitIncreaseRequestCreateFlowMounted = "budget_limit_increase_request.create_flow.mounted",
  BudgetLimitIncreaseRequestEditFlowMounted = "budget_limit_increase_request.edit_flow.mounted",
  BudgetLimitIncreaseRequestEditFlowSaveAndApproveClicked = "budget_limit_increase_request.edit_flow.save_and_approve.clicked",
  BudgetLimitIncreaseRequestEditFlowSaveAndApproveSuccess = "budget_limit_increase_request.edit_flow.save_and_approve.success",
  BudgetLimitIncreaseRequestEditFlowSaveClicked = "budget_limit_increase_request.edit_flow.save.clicked",
  BudgetLimitIncreaseRequestEditFlowSaveSuccess = "budget_limit_increase_request.edit_flow.save.success",
  // Please check go/budgets-events for guidelines
  // Budget limit increase request - Details pane
  BudgetLimitIncreaseRequestDetailsPaneApproveClicked = "budget_limit_increase_request.details_pane.approve.clicked",
  BudgetLimitIncreaseRequestDetailsPaneApproveOverrideClicked = "budget_limit_increase_request.details_pane.approve.override.clicked",
  BudgetLimitIncreaseRequestDetailsPaneApproveOverrideConfirmClicked = "budget_limit_increase_request.details_pane.approve.override.confirm.clicked",
  BudgetLimitIncreaseRequestDetailsPaneCancelClicked = "budget_limit_increase_request.details_pane.cancel.clicked",
  BudgetLimitIncreaseRequestDetailsPaneEditClicked = "budget_limit_increase_request.details_pane.edit.clicked",
  BudgetLimitIncreaseRequestDetailsPaneMounted = "budget_limit_increase_request.details_pane.mounted",
  BudgetLimitIncreaseRequestDetailsPaneRejectClicked = "budget_limit_increase_request.details_pane.reject.clicked",
  BudgetLimitIncreaseRequestDetailsPaneRejectConfirmClicked = "budget_limit_increase_request.details_pane.reject.confirm.clicked",
  BudgetLimitIncreaseRequestDetailsPaneRejectOverrideClicked = "budget_limit_increase_request.details_pane.reject.override.clicked",
  BudgetLimitIncreaseRequestDetailsPaneRejectOverrideConfirmClicked = "budget_limit_increase_request.details_pane.reject.override.confirm.clicked",
  BudgetLimitIncreaseRequestDetailsPaneRetryFetchClicked = "budget_limit_increase_request.details_pane.retry_fetch.clicked",
  BudgetLimitIncreaseRequestDetailsPaneListMounted = "budget_limit_increase_request.details_pane.list.mounted",
  BudgetLimitIncreaseRequestDetailsPaneListQueryFailure = "budget_limit_increase_request.details_pane.list.query.failure",
  BudgetLimitIncreaseRequestDetailsPaneListQueryRetryClicked = "budget_limit_increase_request.details_pane.list.query_retry.clicked",
  BudgetLimitIncreaseRequestDetailsPaneListSearchChanged = "budget_limit_increase_request.details_pane.list.search.changed",
  BudgetLimitIncreaseRequestDetailsPaneListCardClicked = "budget_limit_increase_request.details_pane.list.card.clicked",
  // Please check go/budgets-events for guidelines
  // Budget offboarding - Form
  BudgetOffboardingFormStartClicked = "budget_offboarding.form.start.clicked",
  BudgetOffboardingFormDoneClicked = "budget_offboarding.form.done.clicked",
  BudgetOffboardingFormExitClicked = "budget_offboarding.form.exit.clicked",
  BudgetOffboardingFormCancelClicked = "budget_offboarding.form.cancel.clicked",
  BudgetOffboardingFormBackClicked = "budget_offboarding.form.back.clicked",
  BudgetOffboardingFormMounted = "budget_offboarding.form.mounted",
  BudgetOffboardingFormDefaultErrorMounted = "budget_offboarding.form.default_error.mounted",
  BudgetOffboardingFormStartStepMounted = "budget_offboarding.form.start_step.mounted",
  BudgetOffboardingFormEditApprovalChainsStepMounted = "budget_offboarding.form.edit_approval_chains_step.mounted",
  BudgetOffboardingFormDoneStepMounted = "budget_offboarding.form.done_step.mounted",
  BudgetOffboardingFormOffboardUserStepMounted = "budget_offboarding.form.offboard_user_step.mounted",
  BudgetOffboardingFormOffboardUserStepContinueClicked = "budget_offboarding.form.offboard_user_step_continue.clicked",
  BudgetOffboardingFormOffboardUserStepContinueErrorClicked = "budget_offboarding.form.offboard_user_step_continue_error.clicked",
  BudgetOffboardingFormActionItemsMounted = "budget_offboarding.form.action_items.mounted",
  BudgetOffboardingFormActionItemReviewApprovalChainsMounted = "budget_offboarding.form.action_item.review_approval_chains.mounted",
  BudgetOffboardingFormActionItemReviewCardOwnershipMounted = "budget_offboarding.form.action_item.review_card_ownership.mounted",
  BudgetOffboardingFormActionItemApprovalChainActionClicked = "budget_offboarding.form.action_item.approval_chain_action.clicked",
  BudgetOffboardingFormActionItemCardActionClicked = "budget_offboarding.form.action_item.card_action.clicked",
  BudgetOffboardingFormReprocessOffboardingActionSuccess = "budget_offboarding.form.reprocess_offboarding_action.success",
  BudgetOffboardingFormReprocessOffboardingActionFailure = "budget_offboarding.form.reprocess_offboarding_action.failure",
  BudgetOffboardingFormLoadMoreOffboardingActionsSuccess = "budget_offboarding.form.load_more_offboarding_actions.success",
  BudgetOffboardingFormLoadMoreOffboardingActionsFailure = "budget_offboarding.form.load_more_offboarding_actions.failure",
  BudgetOffboardingFormViewBudgetsMounted = "budget_offboarding.form.view_budgets.mounted",
  BudgetOffboardingFormViewBudgetsClicked = "budget_offboarding.form.view_budgets.clicked",
  BudgetOffboardingFormTransferCardOwnershipMounted = "budget_offboarding.form.transfer_card_ownership.mounted",
  BudgetOffboardingFormEditApprovalChainMounted = "budget_offboarding.form.edit_approval_chain.mounted",
  // Please check go/budgets-events for guidelines
  // Budget program - Form
  BudgetProgramCreateFlowStartMounted = "budget_program.create_flow.start.mounted",
  BudgetProgramCreateFlowContinueClicked = "budget_program.create_flow.continue.clicked",
  BudgetProgramCreateFlowReviewMounted = "budget_program.create_flow.review.mounted",
  BudgetProgramCreateFlowCreateClicked = "budget_program.create_flow.create.clicked",
  BudgetProgramCreateFlowCreateSuccess = "budget_program.create_flow.create_success",
  BudgetProgramCreateFlowCreateFail = "budget_program.create_flow.create_fail",
  BudgetProgramCreateFlowAddAnotherBudgetClicked = "budget_program.create_flow.add_another_budget.clicked",
  BudgetProgramEditFlowStartMounted = "budget_program.edit_flow.start.mounted",
  BudgetProgramEditFlowContinueClicked = "budget_program.edit_flow.continue.clicked",
  BudgetProgramEditFlowReviewMounted = "budget_program.edit_flow.review.mounted",
  BudgetProgramEditFlowSaveClicked = "budget_program.edit_flow.save.clicked",
  BudgetProgramEditFlowSaveSuccess = "budget_program.edit_flow.save_success",
  BudgetProgramEditFlowSaveFail = "budget_program.edit_flow.save_fail",
  BudgetProgramEditFlowAddAnotherBudgetClicked = "budget_program.edit_flow.add_another_budget.clicked",
  // Please check go/budgets-events for guidelines
  // Budget programs - Page
  BudgetProgramsTableMounted = "budget_programs_table.mounted",
  BudgetProgramsTableRowActionClicked = "budget_programs_table.row_action.clicked",
  BudgetProgramsTableConfirmDeleteClicked = "budget_programs_table.confirm_delete.clicked",
  BudgetProgramsTableSeeMoreClicked = "budget_programs_table.see_more.clicked",
  BudgetProgramsTableSortClicked = "budget_programs_table.sort.clicked",
  BudgetProgramsTableFilterByStatusClicked = "budget_programs_table.filter_by_status.clicked",
  BudgetProgramsTableFilterByInvitationFilterClicked = "budget_programs_table.filter_by_invitation_filter.clicked",
  BudgetProgramsTableSearchFieldSubmitted = "budget_programs_table.search.field.submitted",
  // Please check go/budgets-events for guidelines
  // Budget program - Details pane
  BudgetProgramDetailPaneEditMemberClicked = "budget_program.detail_pane.edit.member.clicked",
  BudgetProgramDetailPaneAddBudgetClicked = "budget_program.detail_pane.add.budget.clicked",
  BudgetProgramDetailPaneEditBudgetClicked = "budget_program.detail_pane.edit.budget.clicked",
  BudgetProgramDetailPaneRemoveBudgetClicked = "budget_program.detail_pane.remove.budget.clicked",
  BudgetProgramPauseClicked = "budget_program.pause.budget_program.clicked",
  BudgetProgramResumeClicked = "budget_program.resume.budget_program.clicked",
  BudgetProgramDeleteClicked = "budget_program.delete.budget_program.clicked",
  BudgetProgramDeleteConfirmClicked = "budget_program.delete.budget_program_confirm.clicked",
  // Please check go/budgets-events for guidelines
  // Budget template - Form
  // TODO: TBD
  // Please check go/budgets-events for guidelines
  // Budget template - Help panel
  BudgetTemplateOnboardingDetailsPaneMounted = "budget_template_onboarding.details_pane.mounted",
  BudgetTemplateOnboardingDetailsPaneCustomerHubClicked = "budget_template_onboarding.details_pane.customer_hub.clicked",
  BudgetTemplateOnboardingDetailsPaneVideoSeenSuccess = "budget_template_onboarding.details_pane.video_seen.success",
  // Budget template - Details pane
  BudgetTemplateDetailsPaneMounted = "budget_template.details_pane.mounted",
  BudgetTemplateDetailsPaneConfirmPauseClicked = "budget_template.details_pane.confirm_pause.clicked",
  BudgetTemplateDetailsPaneResumeClicked = "budget_template.details_pane.resume.clicked",
  BudgetTemplateDetailsPaneDuplicateClicked = "budget_template.details_pane.duplicate.clicked",
  BudgetTemplateDetailsPaneDeleteDraftClicked = "budget_template.details_pane.delete_draft.clicked",
  BudgetTemplateDetailsPaneCreateTemplateClicked = "budget_template.details_pane.create_template.clicked",
  BudgetTemplateDetailsPaneCreateTemplateSuccess = "budget_template.details_pane.create_template.success",
  BudgetTemplateDetailsPaneCreateTemplateFailure = "budget_template.details_pane.create_template.failure",
  BudgetTemplateDetailsPanePublishClicked = "budget_template.details_pane.publish.clicked",
  BudgetTemplateDetailsPaneAutosaveDetailsSuccess = "budget_template.details_pane.autosave_details.success",
  BudgetTemplateDetailsPaneAutosaveDetailsFailure = "budget_template.details_pane.autosave_details.failure",
  BudgetTemplateDetailsPaneAutosaveAdvancedBudgetControlsSuccess = "budget_template.details_pane.save_advanced_budget_controls.success",
  BudgetTemplateDetailsPaneAutosaveAdvancedBudgetControlsFailure = "budget_template.details_pane.save_advanced_budget_controls.failure",
  // Budget templates - Availability
  BudgetTemplatesAvailabilityMounted = "budget_templates.availability.mounted",
  BudgetTemplatesAvailabilitySaved = "budget_templates.availability.saved",
  BudgetTemplatesAvailabilityFailure = "budget_templates.availability.failure",
  // Please check go/budgets-events for guidelines
  // Budget templates - Budget request organization
  BudgetTemplateBudgetRequestOrganizationMounted = "budget_templates.budget_request_organization.mounted",
  BudgetTemplateBudgetRequestOrganizationSaved = "budget_templates.budget_request_organization.saved",
  // Please check go/budgets-events for guidelines
  // Budget templates - Page
  BudgetTemplatesPageMounted = "budget_templates.page.mounted",
  BudgetTemplatesEducationLearnMoreClicked = "budget_templates.education.learn_more.clicked",
  BudgetTemplatesTableRowClicked = "budget_templates.table.row.clicked",
  BudgetTemplatesTableSeeMoreClicked = "budget_templates.table.see_more.clicked",
  BudgetTemplatesPageEducationBannerGetHelpClicked = "budget_templates.page.education_banner.get_help.clicked",
  BudgetTemplatesPageTableUpdateTemplateSuccess = "budget_templates.page.table.update_template.success",
  BudgetTemplatesPageTableUpdateTemplateFailure = "budget_templates.page.table.update_template.failure",
  BudgetTemplatesPageCreateTemplateClicked = "budget_templates_create_template_clicked",
  BudgetTemplatesPageTableReorderingSuccess = "budget_templates.page.table.reordering.success",
  BudgetTemplatesPageTableReorderingFailure = "budget_templates.page.table.reordering.failure",

  // Please check go/budgets-events for guidelines
  // Account policy currencies - Details pane
  AccountPolicyCurrenciesDetailsPaneConfirmRefreshRateAlertMounted = "account_policy_currencies.details_pane.confirm_refresh_rate_alert.mounted",
  AccountPolicyCurrenciesDetailsPaneMounted = "account_policy_currencies.details_pane.mounted",
  AccountPolicyCurrenciesDetailsPaneUpdateEnabledAccountPolicyCurrenciesSuccess = "account_policy_currencies.details_pane.update_enabled_account_policy_currencies.success",
  AccountPolicyCurrenciesDetailsPaneUpdateEnabledAccountPolicyCurrenciesFailure = "account_policy_currencies.details_pane.update_enabled_account_policy_currencies.failure",
  AccountPolicyCurrenciesDetailsPaneRefreshRatesSuccess = "account_policy_currencies.details_pane.refresh_rates.success",
  AccountPolicyCurrenciesDetailsPaneRefreshRatesFailure = "account_policy_currencies.details_pane.refresh_rates.failure",

  // Please check go/budgets-events for guidelines
  // Budget components
  BudgetComponentEmployeesDropdownOptionClicked = "budget.component.employees_dropdown.option.clicked",
  // Please check go/budgets-events for guidelines
  // Budget Table
  BudgetTableRowExpanded = "budget_table.row.expanded",
  BudgetTableRowCollapsed = "budget_table.row.collapsed",
  BudgetTableRowEmployeePillClicked = "budget_table.row.employee_pill.clicked",
  // Please check go/budgets-events for guidelines
  // Budget Self Serve Onboarding
  BudgetSelfServeCreateFlowStartClicked = "budget.self_serve.create_flow.start.clicked",
  BudgetSelfServeCreateFlowContinueClicked = "budget.self_serve.create_flow.continue.clicked",
  BudgetSelfServeCreateFlowErrorMounted = "budget.self_serve.create_flow.error.mounted",
  BudgetSelfServeCreateFlowDoneClicked = "budget.self_serve.create_flow.done.clicked",
  // Please check go/budgets-events for guidelines
  // Budget - Allocation calculation page
  BudgetAllocationCalculationPageBackButtonClicked = "budget.allocation_calculation.page.back_button.clicked",
  BudgetAllocationCalculationPageSeeMoreButtonClicked = "budget.allocation_calculation.page.see_more_button.clicked",
  BudgetAllocationCalculationPageTableMounted = "budget.allocation_calculation.page.table.mounted",
  BudgetAllocationCalculationPageBudgetHierarchyButtonClicked = "budget.allocation_calculation.page.budget_hierarchy_button.clicked",
  BudgetAllocationCalculationPageBudgetHierarchyMounted = "budget.allocation_calculation.page.budget_hierarchy.mounted",

  // Decline protection
  DeclineProtectionToggleMounted = "decline_protection.toggle.mounted",
  DeclineProtectionToggleToggled = "decline_protection.toggle.toggled",

  // Auto transfer virtual card
  AutoTransferVirtualCardToggled = "auto_transfer_virtual_card.toggled",

  // Spend limit group form
  SpendLimitGroupFormLimitOverridesMounted = "spend_limit_group_form.limit_overrides.mounted",
  SpendLimitGroupFormOverridesAdded = "spend_limit_group_form.overrides.added",
  SpendLimitGroupFormOverridesRemoved = "spend_limit_group_form.overrides.removed",

  // End of Budgets related instrumentation
  // BUDGETS
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // POLICY
  // Start of TEAM related instrumentation
  // Please check go/budgets-events for guidelines
  // Policy - Form
  PolicyEditorMounted = "policy.editor.mounted",
  PolicyEditorPolicySaveClicked = "policy.editor.save-policy.clicked",
  PolicyEditorNewPolicySaveSuccess = "policy.editor.create.submit.success",
  PolicyEditorNewPolicySaveFailure = "policy.editor.create.submit.failure",
  PolicyEditorReceiptItemizationConfigurationMounted = "policy.editor.receipt_itemization.configuration.mounted",
  PolicyEditorPolicyUpdateSaveSuccess = "policy.editor.update.submit.success",
  PolicyEditorPolicyUpdateSaveFailure = "policy.editor.update.submit.failure",
  PolicyEditorUnsavedChangesAlertMounted = "policy.editor.unsaved_changes_alert.mounted",
  PolicyEditorUnsavedChangesAlertCloseClicked = "policy.editor.unsaved_changes_alert.close_clicked",
  PolicyEditorUnsavedChangesAlertProceedWithoutSavingClicked = "expense.editor.unsaved_changes_alert.proceed_without_saving.clicked",
  // Please check go/budgets-events for guidelines
  // Policy Form Events (new pattern, equal to policy.editor)
  PolicyFormNoReviewersWarningModalConfirmClicked = "policy.form.no_reviewers_warning_modal.confirm.clicked",
  PolicyFormNoReviewersWarningModalCancelClicked = "policy.form.no_reviewers_warning_modal.cancel.clicked",
  PolicyFormCategoryMerchantStepNextClicked = "policy.form.category_merchant_step.next.clicked",
  PolicyFormCategoryMerchantStepCustomizeClicked = "policy.form.category_merchant_step.customize.clicked",
  PolicyFormCategoryMerchantStepAddClicked = "policy.form.category_merchant_step.add.clicked",
  PolicyFormCategoryMerchantStepDeleteClicked = "policy.form.category_merchant_step.remove.clicked",
  PolicyFormCategoryMerchantConfigStepSaveClicked = "policy.form.category_merchant_config_step.save.clicked",
  PolicyFormCategoryMerchantConfigStepListAttendeesMounted = "policy.form.category_merchant_config_step.list_attendees.mounted",
  PolicyFormTravelAirlineConfigSaved = "policy.form.travel_airline_config.saved",
  PolicyFormTravelLodgingConfigSaved = "policy.form.travel_lodging_config.saved",
  PolicyFormTravelCarRentalConfigSaved = "policy.form.travel_car_rental_config.saved",
  PolicyFormTravelRailConfigSaved = "policy.form.travel_rail_config.saved",
  PolicyFormSpendRequirementsPreviewStepEditClicked = "policy.form.spend_requirements_preview_step.edit.clicked",
  PolicyFormSpendRequirementsConfigStepSaveClicked = "policy.form.spend_requirements_config_step.save.clicked",
  PolicyFormSpendRequirementsConfigStepAddExpectionClicked = "policy.form.spend_requirements_config_step.add_exception.clicked",
  PolicyFormSpendRequirementsConfigStepSaveExpectionClicked = "policy.form.spend_requirements_config_step.save_exception.clicked",
  PolicyFormSpendRequirementsConfigStepDeleteExpectionClicked = "policy.form.spend_requirements_config_step.delete_exception.clicked",
  PolicyFormSpendRequirementsConfigStepAddReviewRuleClicked = "policy.form.spend_requirements_config_step.add_review_rule.clicked",
  PolicyFormSpendRequirementsConfigStepDeleteReviewRuleClicked = "policy.form.spend_requirements_config_step.delete_review_rule.clicked",
  PolicyFormSpendRequirementsConfigStepAddReviewRuleStepClicked = "policy.form.spend_requirements_config_step.add_review_rule_step.clicked",
  PolicyFormSpendRequirementsConfigStepDeleteReviewRuleStepClicked = "policy.form.spend_requirements_config_step.delete_review_rule_step.clicked",
  PolicyFormSpendRequirementsConfigStepAddApproverTooltipDismissClicked = "policy.form.spend_requirements_config_step.add_approver_tooltip.dismiss.clicked",
  PolicyFormSpendRequirementsConfigStepAddApproverTooltipSecondaryClicked = "policy.form.spend_requirements_config_step.add_approver_tooltip.secondary.clicked",
  PolicyFormSpendRequirementsPreviewAutoMemoUpsellBannerMounted = "policy.form.spend_requirements_preview.automemo_banner.mounted",
  PolicyFormSpendRequirementsPreviewAutoMemoUpsellBannerEnableClicked = "policy.form.spend_requirements_preview.automemo_banner.enable.clicked",
  PolicyFormSpendRequirementsPreviewAutoMemoUpsellBannerDismissClicked = "policy.form.spend_requirements_preview.automemo_banner.dismiss.clicked",
  PolicyFormBasicFormMounted = "policy.form.basic_form.mounted",
  PolicyFormDocumentationFormMounted = "policy.form.documentation_form.mounted",
  PolicyFormExpenseTypeSpecificFormMounted = "policy.form.expense_type_specific_form.mounted",
  PolicyFormReceiptPaneMounted = "policy.form.receipt_pane.mounted",
  PolicyFormMemoPaneMounted = "policy.form.memo_pane.mounted",
  PolicyFormApprovalChainFormMounted = "policy.form.approval_chain_form.mounted",
  PolicyFormApprovalChainPaneMounted = "policy.form.approval_chain_pane.mounted",

  // End of POLICY related instrumentation
  // POLICY
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // TEAM
  // Start of TEAM related instrumentation
  // Team events
  TeamUsersTableRowClicked = "team.users_table_row.clicked",
  TeamUsersTabSearchChanged = "team.users_table_search.changed",
  TeamDepartmentRowActionClicked = "team.department_row_action.clicked",
  TeamLocationRowActionClicked = "team.location_row_action.clicked",
  TeamInviteUserSendInviteClicked = "team.invite_user.send_invite.clicked",
  TeamInviteCtaClicked = "team.invite.cta.clicked",
  TeamInviteFiltersApplied = "team.invite.filters.applied",
  TeamInviteAttributeMappingSkipped = "team.invite.attribute.mapping.skipped",
  TeamInviteSubmit = "team.invite.submit",
  TeamInviteSuccessAddMoreEmployeesClicked = "team.invite.success.add_more_employees.clicked",
  TeamInviteSuccessDoneClicked = "team.invite.success.done.clicked",
  TeamInviteEmployeesDropdownOptionClicked = "team.invite.employees_dropdown.option.clicked",
  TeamManageHrisConnectionClicked = "team.manage.hris.connection.clicked",
  TeamBulkDeactivateSubmit = "team.bulk.deactivate.submit",
  TeamBulkReactivateSubmit = "team.bulk.reactivate.submit",
  TeamBulkReinviteSubmit = "team.bulk.reinvite.submit",
  TeamBulkUninviteSubmit = "team.bulk.uninvite.submit",
  TeamBulkRoleChangeSubmit = "team.bulk.role.change.submit",
  TeamBulkTravelChangeSubmit = "team.bulk.travel.change.submit",
  TeamUsersTableFiltersApplied = "team.users_table.filters.applied",
  TeamUsersTableColumnsVisible = "team.users_table.columns.visible",
  TeamUserInviteResent = "team.user.invite.resent",
  TeamUsersTableMissingUserLimitClicked = "team.users_table.missing_user_limit.clicked",
  TeamUserDetailsPaneCreateUserLimitClicked = "team.user.details_pane.create_user_limit.clicked",
  TeamAutoInviteSubmit = "team.auto_invite.submit",
  TeamAutoInviteEditSubmit = "team.auto_invite.edit.submit",
  TeamAutoInviteDeleteClicked = "team.auto_invite.delete.clicked",
  TeamAutoInviteStatusToggled = "team.auto_invite.status_toggled",
  TeamAutoInviteCreateStepMounted = "team.auto_invite.create.step.mounted",
  TeamAutoInvitePreviewStepMounted = "team.auto_invite.preview.step.mounted",
  TeamAutoInviteRoleStepMounted = "team.auto_invite.role.step.mounted",
  TeamAutoInviteReviewStepMounted = "team.auto_invite.review.step.mounted",
  TeamUserCardAccessUpdate = "team.user_card_access.update",
  TeamUserCardAccessConfirm = "team.user_card_access.confirm",
  TeamUserCardAccessCancel = "team.user_card_access.cancel",
  TeamUserGroupCreateStepMounted = "team.user_group.create.step.mounted",
  TeamUserGroupReviewStepMounted = "team.user_group.review.step.mounted",
  TeamResetPasswordSubmit = "team.reset_password.submit",
  TeamMfaResetSubmit = "team.mfa_reset.submit",
  TeamGroupDeleteClicked = "team.group.delete.clicked",
  TeamGroupCreateSubmit = "team.group.create.submit",
  TeamGroupEditSubmit = "team.group.edit.submit",
  // UserAttributesMultiSelectInput
  TeamUserAttributesMultiSelectSearch = "team.user_attributes_multi_select.search",
  TeamUserAttributesMultiSelectFilterApplied = "team.user_attributes_multi_select.filter.applied",
  // GroupBuilderMultiSelectInput
  TeamGroupBuilderMultiSelectSearch = "team.group_builder.search",
  // Shelf
  TeamShelfMounted = "team.shelf.mounted",
  // Entities tab details pane
  EntityDetailsPaneMounted = "entity.details_pane.mounted",
  EntityDetailsPaneDeleteEntityCtaClicked = "entity.details_pane.delete_entity.cta.clicked",
  EntityDetailsPaneConfirmDeleteEntityCtaClicked = "entity.details_pane.confirm_delete_entity.cta.clicked",
  EntityDetailsPaneSetupTaskCtaClicked = "entity.details_pane.setup_task.cta.clicked",
  EntityDetailsPaneSetupTaskDismissed = "entity.details_pane.setup_task.dismissed",
  EntityDetailsPaneManagePaymentsCtaClicked = "entity.details_pane.manage_payments.cta.clicked",
  EntityDetailsPaneViewBillingDetailsCtaClicked = "entity.details_pane.view_billing_details.cta.clicked",
  EntityDetailsPaneViewAccountingCtaClicked = "entity.details_pane.view_accounting.cta.clicked",
  // End of TEAM related instrumentation
  // TEAM
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // CARD AND LIMITS
  // Start of CARD AND LIMITS related instrumentation
  // Card Events
  CardsAndLimitsSurfaceCardsViewed = "card_limits.surface.cards.viewed",
  CardsAndLimitsSurfaceSpendLimitsViewed = "card_limits.surface.spend_limits.viewed",
  CardsAndLimitsTabClicked = "card_limits.tab.clicked",
  CardsAndLimitsCardActionsClicked = "card_limits.card_actions.clicked",
  CardsAndLimitsShelfCtaClicked = "card_limits.shelf_cta.clicked",
  CardsAndLimitsShelfMounted = "card_limits.surface.shelf.mounted",
  CardsAndLimitsCreateCardViewed = "card_limits.create.card.viewed",
  CardsAndLimitsTableFiltersChanged = "card_limits.table.filters.changed",
  CreateCardsAndLimitStepViewed = "create_cards_and_limit.step.viewed",
  CreateCardsAndLimitSuccess = "create_cards_and_limit.success",
  CreateCardsAndLimitCardPollingComplete = "create_cards_and_limit.card_polling.complete",
  // End of CARD AND LIMITS related instrumentation
  // CARD AND LIMITS
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // CASH
  // Start of CASH related instrumentation
  // Cash Events
  CashTabChanged = "cash.tab.changed",
  CashInsightsTabMounted = "cash.insights.tab.mounted",
  // Cash Accounts Overview
  CashAccountsOverviewTableRowClicked = "cash.accounts_overview.table.row.clicked",
  // Cash Statements Events
  CashBulkStatementsDownloadClicked = "cash.bulk_statements.download.clicked",
  CashStatementsAllAccountsTableRowClicked = "cash.statements.all_accounts.table.row.clicked",
  // Cash Transactions Events
  EmpowerCashTransactionsPageMounted = "empower.cash.transactions.page.mounted",
  CashTransactionsTableRowClicked = "cash.transactions.table.row.clicked",
  CashTransactionsTableLoadMoreTransactionsClicked = "cash.transactions.table.load_more_transactions.clicked",
  CashTransactionsEmptyTableDepositMoneyClicked = "cash.transactions.empty.table.deposit_money.clicked",
  CashTransactionsTableFiltered = "cash.transactions.table.filtered",
  CashTransactionsTableV2Filtered = "cash.transactions.tableV2.filtered",
  CashTransactionsDetailsPaneEditTransactionClicked = "cash.transactions.details.pane.edit_transaction.clicked",
  CashTransactionsDetailsPaneCancelTransactionClicked = "cash.transactions.details.pane.cancel_transaction.clicked",
  CashTransactionsDetailsPaneDownloadTransactionClicked = "cash.transactions.details.pane.download_transaction.clicked",
  CashTransactionsDetailsPaneViewProcessedRecurringTransactionsClicked = "cash.transactions.details.pane.view_processed_recurring_transactions.clicked",
  CashTransactionsDetailsPaneManagePositivePayMerchantsModalOpened = "cash.transactions.details.pane.manage_positive_pay_merchants_modal.opened",
  CashTransactionsDetailsPaneAccountDetailsModalOpened = "cash.transactions.details.pane.account_details_modal.opened",
  CashTransactionsBlockCounterpartyConfirm = "cash.transactions.positive_pay_block.confirm",
  CashTransactionsTrustCounterpartyConfirm = "cash.transactions.positive_pay_trust.confirm",
  CashTransactionsClearPositivePayConfirm = "cash.transactions.positive_pay_clear.confirm",
  CashTransactionsFilterExportTransactionsClicked = "cash.transactions.filter.export_transactions.clicked",
  // Cash edit deposits account
  CashEditAccountNameClicked = "cash.account.edit_name.clicked",
  CashEditAccountNameCompleted = "cash.account.edit_name.completed",
  CashCloseAccountClicked = "cash.account.close.clicked",
  CashAccountOverviewMounted = "cash.account.overview.mounted",
  CashAccountTransactionsMounted = "cash.account.transactions.mounted",
  CashAccountStatementsMounted = "cash.account.statements.mounted",
  CashBannerFundAccountCardClicked = "cash.banner.fund_account_card.clicked",
  CashBannerAutopayCardClicked = "cash.banner.autopay_card.clicked",
  CashBannerAutopayMakeDefaultCompleted = "cash.banner.autopay_make_default.completed",
  CashBannerManageAllocationCardClicked = "cash.banner.manage_allocation_card.clicked",
  CashBannerManageAllocationCompleted = "cash.banner.manage_allocation.completed",
  CashBannerMounted = "cash.banner.mounted",
  CashBannerDismissClicked = "cash.banner.dismissed.clicked",
  CashBannerPayrollCompleted = "cash.banner.payroll.completed",
  CashBannerPayrollCardClicked = "cash.banner.payroll_card.clicked",
  // Cash Invoices
  CashInvoicesButtonClicked = "cash.invoices.button.clicked",
  CashInvoicesNavigated = "cash.invoices.navigated",
  CashInvoicesControllerMounted = "cash.invoices.controller.mounted",
  CashInvoicesControllerUnmounted = "cash.invoices.controller.unmounted",
  CashInvoicesTableActionItemClicked = "cash.invoices.table.action_item.clicked",
  CashInvoicesTableShowMoreClicked = "cash.invoices.table.show_more.clicked",
  CashInvoicesTakeoverNextClicked = "cash.invoices.takeover.next.clicked",
  CashInvoicesTakeoverBackClicked = "cash.invoices.takeover.back.clicked",
  CashInvoicesTakeoverExitClicked = "cash.invoices.takeover.exit.clicked",
  CashInvoicesTakeoverSecondaryClicked = "cash.invoices.takeover.secondary.clicked",
  CashInvoicesMutationSuccess = "cash.invoices.mutation.success",
  CashInvoicesMutationError = "cash.invoices.mutation.error",

  // End of CASH related instrumentation
  // CASH
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // CREDIT
  // Start of CREDIT related instrumentation
  // Credit Events
  PaymentFailureAuthorizeBankClick = "credit.payment.failure.authorize_bank_click",
  PaymentFailureChangeAutopayClick = "credit.payment.failure.change_autopay_click",
  PaymentFailureMakePaymentClick = "credit.payment.failure.make_payment_click",
  CreditOverviewCurrentBalanceMounted = "credit.overview.current_balance.mounted",
  CreditOverviewEducationTileTabChanged = "credit.overview.education_tile.tab_changed",
  CreditOverviewEducationTileTotalCashBalanceBankConnectionErrorMounted = "credit.overview.education_tile.total_cash_balance.bank_connection_error.mounted",
  CreditOverviewEducationTileTotalCashBalanceBankConnectionErrorExpanded = "credit.overview.education_tile.total_cash_balance.bank_connection_error.expanded",
  CreditOverviewEducationTileTotalCashBalanceBankConnectionErrorClicked = "credit.overview.education_tile.total_cash_balance.bank_connection_error.clicked",
  CreditOverviewEducationTileTotalCashBalanceStatementUploadDueMounted = "credit.overview.education_tile.total_cash_balance.statement_due.mounted",
  CreditOverviewEducationTileTotalCashBalanceStatementUploadDueExpanded = "credit.overview.education_tile.total_cash_balance.statement_due.expanded",
  CreditOverviewEducationTileTotalCashBalanceStatementUploadDueClicked = "credit.overview.education_tile.total_cash_balance.statement_due.clicked",
  CreditOverviewEducationTileTotalCashBalanceMounted = "credit.overview.education_tile.total_cash_balance.mounted",
  CreditOverviewEducationTileTotalCashBalanceExpanded = "credit.overview.education_tile.total_cash_balance.expanded",
  CreditOverviewEducationTileTotalCashBalanceClicked = "credit.overview.education_tile.total_cash_balance.clicked",
  CreditOverviewEducationTilePaymentHistoryMounted = "credit.overview.education_tile.payment_history.mounted",
  CreditOverviewEducationTilePaymentHistoryExpanded = "credit.overview.education_tile.payment_history.expanded",
  CreditOverviewEducationTilePaymentHistoryClicked = "credit.overview.education_tile.payment_history.clicked",
  CreditOverviewEducationTileFinancialDocumentsMounted = "credit.overview.education_tile.financial_documents.mounted",
  CreditOverviewEducationTileFinancialDocumentsExpanded = "credit.overview.education_tile.financial_documents.expanded",
  CreditOverviewEducationTileFinancialDocumentsClicked = "credit.overview.education_tile.financial_documents.clicked",
  CreditOverviewEducationTileCreditTermsMounted = "credit.overview.education_tile.credit_terms.mounted",
  CreditOverviewEducationTileCreditTermsExpanded = "credit.overview.education_tile.credit_terms.expanded",
  CreditOverviewNotificationTileInsufficientFundsMounted = "credit.overview.notification_tile.insufficient_funds.mounted",
  CreditOverviewNotificationTileInsufficientFundsClicked = "credit.overview.notification_tile.insufficient_funds.clicked",
  CreditOverviewNotificationTileLimitUtilizationClicked = "credit.overview.notification_tile.limit_utilization.clicked",
  CreditOverviewNotificationTileLimitUtilizationMounted = "credit.overview.notification_tile.limit_utilization.mounted",
  CreditOverviewEducationTilePaymentHistorySpendSuspendedMounted = "credit.overview.education_tile.payment_history.spend_suspended.mounted",
  CreditOverviewEducationTilePaymentHistorySpendSuspendedExpanded = "credit.overview.education_tile.payment_history.spend_suspended.expanded",
  CreditOverviewEducationTilePaymentHistorySpendSuspendedClicked = "credit.overview.education_tile.payment_history.spend_suspended.clicked",
  CreditOverviewEducationTilePaymentHistoryAutopayWarningMounted = "credit.overview.education_tile.payment_history.autopay_warning.mounted",
  CreditOverviewEducationTilePaymentHistoryAutopayWarningExpanded = "credit.overview.education_tile.payment_history.autopay_warning.expanded",
  CreditOverviewEducationTilePaymentHistoryAutopayWarningClicked = "credit.overview.education_tile.payment_history.autopay_warning.clicked",
  CreditOverviewPaymentDetailAutopayClicked = "credit.overview.payment_detail.autopay_clicked",
  CreditOverviewPaymentDetailAutopayMounted = "credit.overview.payment_detail.autopay_mounted",
  CreditOverviewPaymentsDueAutopayClicked = "credit.overview.payment_detail.autopay_clicked",
  CreditOverviewPaymentsDueAutopayMounted = "credit.overview.payment_detail.autopay_mounted",
  CreditOverviewPaymentDetailMakePaymentClicked = "credit.overview.payment_detail.make_payment_clicked",
  CreditOverviewPaymentDetailMakePaymentMounted = "credit.overview.payment_detail.make_payment_mounted",
  CreditOverviewTransactionsClicked = "credit.overview.transactions.clicked",
  CreditOverviewTransactionsMounted = "credit.overview.transactions.mounted",
  CreditBillingOverviewMakePaymentClicked = "credit.billling_overview.make_payment_clicked",
  CreditMakePaymentClicked = "credit.make_payment_clicked",
  CreditTabChanged = "credit.tab.changed",
  // End of CREDIT related instrumentation
  // CREDIT
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // EXPENSES
  // Start of EXPENSES related instrumentation
  // Employee Reimbursement Bank Account Form
  EmployeeBankAccountFormMounted = "employee_bank_account_form.mounted",
  EmployeeBankAccountFormClosed = "employee_bank_account_form.closed",
  EmployeeBankAccountTermsAccepted = "employee_bank_account_form.terms_accepted",
  EmployeeBankAccountFormBackClicked = "employee_bank_account_form.back_clicked",
  EmployeeBankAccountFormNextClicked = "employee_bank_account_form.next_clicked",
  EmployeeBankAccountFormConnectClicked = "employee_bank_account_form.connect_clicked",
  EmployeeBankAccountFormConnectErrorMounted = "employee_bank_account_form.connect_error_mounted",
  EmployeeBankAccountFormCountryMounted = "employee_bank_account_form.country.mounted",
  EmployeeBankAccountFormCountryInputFocused = "employee_bank_account_form.country.input_focused",
  EmployeeBankAccountFormPlaidScreenMounted = "employee_bank_account_form.plaid_screen.mounted",
  EmployeeBankAccountFormPlaidModalMounted = "employee_bank_account_form.plaid_modal.mounted",
  EmployeeBankAccountFormACHDetailsMounted = "employee_bank_account_form.ach_details.mounted",
  EmployeeBankAccountFormACHDetailsInputFocused = "employee_bank_account_form.ach_details.input_focused",
  EmployeeBankAccountFormAddressMounted = "employee_bank_account_form.address.mounted",
  EmployeeBankAccountFormAddressSuggestedAddressMounted = "employee_bank_account_form.address.suggested_address.mounted",
  EmployeeBankAccountFormAddressSuggestedAddressUseDifferentAddressClicked = "employee_bank_account_form.address.suggested_address.use_different_address_clicked",
  EmployeeBankAccountFormAddressNewAddressMounted = "employee_bank_account_form.address.new_address.mounted",
  EmployeeBankAccountFormAddressNewAddressInputFocused = "employee_bank_account_form.address.new_address.input_focused",
  EmployeeBankAccountFormCounterPartyDetailsMounted = "employee_bank_account_form.counter_party_details.mounted",
  EmployeeBankAccountFormCounterPartyDetailsInputFocused = "employee_bank_account_form.counter_party_details.input_focused",
  EmployeeBankAccountFormConnectMutationSuccess = "employee_bank_account_form.connect_mutation.success",
  EmployeeBankAccountFormConnectMutationError = "employee_bank_account_form.connect_mutation.error",
  EmployeeBankAccountFormDeleteModalMounted = "employee_bank_account_form.delete_modal.mounted",
  EmployeeBankAccountFormDeleteModalClosed = "employee_bank_account_form.delete_modal.closed",
  // Expenses All Events
  ExpensesAllPageMounted = "expenses.all.page.mounted",
  ExpensesAllTypeFilterSelected = "expenses.all.type_filter.selected",
  ExpensesAllBudgetFilterSelected = "expenses.all.budget_filter.selected",
  ExpensesAllBudgetTemplateFilterSelected = "expenses.all.budget_template_filter.selected",
  ExpensesAllStatusFilterSelected = "expenses.all.status_filter.selected",
  ExpensesAllComplianceFilterSelected = "expenses.all.compliance_filter.selected",
  ExpensesAllComplianceFilterSelectedAutoMemoUpsellMounted = "expenses.all.compliance_filter.selected.automemo.upsell.mounted",
  ExpensesAllComplianceFilterSelectedAutoMemoUpsellDismissed = "expenses.all.compliance_filter.selected.automemo.upsell.dismiss.clicked",
  ExpensesAllComplianceFilterSelectedAutoMemoUpsellPolicySettingsClicked = "expenses.all.compliance_filter.selected.automemo.upsell.settings.clicked",
  ExpensesAllApprovalFilterSelected = "expenses.all.approval_filter.selected",
  ExpensesAllIncurredByFilterSelected = "expenses.all.incurred_by_filter.selected",
  ExpensesAllSearchBySelected = "expenses.all.search_by.selected",
  ExpensesAllDateFilterSelected = "expenses.all.date_filter.selected",
  ExpensesAllAmountFilterSelected = "expenses.all.amount_filter.selected",
  ExpensesAllExportClicked = "expenses.all.export.clicked",
  ExpensesAllBilledEntityFilterSelected = "expenses.all.billed_entity_filter.selected",
  ExpensesAllSpendingEntityFilterSelected = "expenses.all.spending_entity_filter.selected",
  ExpensesAllAttendeesContainGovernmentOfficialFilterSelected = "expenses.all.attendees_contain_government_official_filter.selected",
  ExpensesAllExpenseCategoryFilterSelected = "expenses.all.expense_category_filter.selected",
  ExpensesAllUserStatusFilterSelected = "expenses.all.user_status_filter.selected",
  ExpensesAllGLAccountFilterSelected = "expenses.all.gl_account_filter.selected",
  ExpensesAllCardFilterSelected = "expenses.all.card_filter.selected",
  ExpensesAllDepartmentFilterSelected = "expenses.all.department_filter.selected",
  ExpensesAllMerchantFilterSelected = "expenses.all.merchant_filter.selected",
  ExpensesAllMerchantCategoryFilterSelected = "expenses.all.merchant_category_filter.selected",
  ExpensesAllUserFilterSelected = "expenses.all.user_filter.selected",
  ExpensesAllVendorFilterSelected = "expenses.all.vendor_filter.selected",
  ExpensesAllDisputeStatusFilterSelected = "expenses.all.dispute_status_filter.selected",
  ExpensesAllExpensePolicyFilterSelected = "expenses.all.expense_policy_filter.selected",
  ExpensesAllFiltersCleared = "expenses.all.filters.cleared",
  ExpensesAllFilterCleared = "expenses.all.filter.cleared",
  ExpensesAllAddFilterSelected = "expenses.all.add_filter.selected",
  ExpensesAllApprovalStatusFilterSelected = "expenses.all.approval_status_filter.selected",
  ExpensesAllPaymentStatusFilterSelected = "expenses.all.payment_status_filter.selected",
  ExpensesAllDerivedComplianceStatusFilterSelected = "expenses.all.derived_compliance_status_filter.selected",
  ExpensesAllDocumentationFilterSelected = "expenses.all.documentation_filter.selected",
  ExpensesAllMemoFilterSelected = "expenses.all.memo_filter.selected",
  ExpensesAllReceiptFilterSelected = "expenses.all.receipt_filter.selected",
  ExpensesAllFlaggedExpenseFilterSelected = "expenses.all.flagged_expense_filter.selected",
  ExpensesAllCostCenterFilterSelected = "expenses.all.cost_center_filter.selected",
  ExpensesAllTrackingStatusFilterSelected = "expenses.all.tracking_status_filter.selected",
  ExpensesPolicyViolationReasonsFilterSelected = "expenses.all.policy_violation_reason_filter.selected",
  ExpenseFiltersExpenseTypeRedirected = "expense_filters.expense_type.redirected",
  ExpensesAllDeadlineUpsellTooltipMounted = "expenses.all.deadline_upsell_tooltip.mounted",
  ExpensesAllDeadlineUpsellModalMounted = "expenses.all.deadline_upsell_modal.mounted",
  ExpensesAllDeadlineUpsellDismissed = "expenses.all.deadline_upsell.dismissed",
  ExpensesAllDeadlineUpsellSetupButtonClicked = "expenses.all.deadline_upsell_setup_button.clicked",
  // Expenses Export Events
  ExpensesExportModalMounted = "expenses.export.modal.mounted",
  ExpensesExportModalClosed = "expenses.export.modal.closed",
  ExpensesExportDownloadClicked = "expenses.export.download.clicked",
  ExpensesExportCreateReportSuccessDownloadRetryClicked = "expenses.export.create_report_success.download_retry.clicked",
  ExpensesExportCreateReportFailDownloadRetryClicked = "expenses.export.create_report_fail.download_retry.clicked",
  // Expenses AI Smart Filtering
  ExpensesAISmartFilteringDropdownButtonClick = "ai.filtering.filter_dropdown_button.click",
  ExpensesAISmartFilteringSubmit = "ai.filtering.filter.submit",
  ExpensesAISmartFilteringAppliedFilterKeys = "ai.filtering.filter.applied.keys.collected",
  ExpensesAISmartFilteringLLMParseError = "ai.filtering.llm.parse.error",
  // DataGrid
  DataGridMounted = "datagrid.mounted",
  DataGridViewSelected = "datagrid.view.selected",
  DataGridSortClicked = "datagrid.sort.clicked",
  DataGridLayoutSelected = "datagrid.layout.selected",
  DataGridRowClicked = "datagrid.row.clicked",
  DataGridDetailsPaneRowCyclingClicked = "datagrid.details_pane.row_cycling.clicked",
  DataGridPaginationPreviousClicked = "datagrid.pagination.previous.clicked",
  DataGridPaginationNextClicked = "datagrid.pagination.next.clicked",
  DataGridPaginationSeeMoreClicked = "datagrid.pagination.see_more.clicked",
  DataGridPaginationPageSizeClicked = "datagrid.pagination.page_size.clicked",
  DataGridV1DeepLinkLegacyParamsUsed = "datagrid.v1.deeplink.legacy_params.used",
  DataGridV1DeepLinkUsed = "datagrid.v1.deeplink.used",
  //DataGrid AI Smart Search
  DataGridAISmartSearchLLMParseError = "datagrid.ai.smart.search.llm.parse.error",
  DataGridAISmartSearchAppliedFilterKeys = "datagrid.ai.smart.search.filter.applied.keys.collected",
  // DataGrid selection
  DataGridSelectionRowToggle = "datagrid.selection.row.toggle",
  DataGridSelectionGroupToggle = "datagrid.selection.group.toggle",
  DataGridSelectionGroupAllToggle = "datagrid.selection.group.all.toggle",
  DataGridSelectionHeaderToggle = "datagrid.selection.header.toggle",
  DataGridSelectionTrueSelectAll = "datagrid.selection.true_select_all",
  // DataGrid Bulk actions
  DataGridBulkActionButtonClick = "datagrid.bulk_action.button.click",
  DataGridBulkActionAttempted = "datagrid.bulk_action.attempted",
  DataGridBulkActionClose = "datagrid.bulk_action.close",
  // DataGrid Column settings
  DataGridColumnSettingsVisibilityToggle = "datagrid.column_settings.visibility.toggle",
  DataGridColumnSettingsDrag = "datagrid.column_settings.drag",
  // DataGrid Grouping
  DataGridGroupingGroupByOptionSelected = "datagrid.grouping.group_by_option.selected",
  DataGridGroupingGroupByOptionCleared = "datagrid.grouping.group_by_option.cleared",
  DataGridGroupingViewAllRowsForGroupClicked = "datagrid.grouping.view_all_rows_for_group.clicked",
  // DataGrid Filters
  DataGridFilteringUseRestrictedFiltersAttempted = "datagrid.filtering.use_restricted_filters.attempted",
  DataGridFiltersApplyFilterClicked = "datagrid.filters.apply_filter.clicked",
  DataGridFiltersRemoveFilterClicked = "datagrid.filters.remove_filter.clicked",
  DataGridFiltersClearFilterClicked = "datagrid.filters.clear_filter.clicked",
  DataGridFiltersClearAllFiltersClicked = "datagrid.filters.clear_all_filters.clicked",
  DataGridFiltersFilterValueChanged = "datagrid.filters.filter.value.changed",
  // DataGrid cell actions
  DataGridTextInputCellChanged = "datagrid.text_input_cell.changed",
  DataGridUploadButtonCellClicked = "datagrid.upload_button_cell.clicked",
  // Datagrid row actions
  DatagridRowActionButtonClick = "datagrid.row_action.button.click",
  // Reimbursements Events V2
  ReimbursementsReviewComplete = "reimbursements.review.complete",
  ReimbursementsFormTakeoverMounted = "reimbursements.form.takeover.mounted",
  ReimbursementsBankConnectTakeoverMounted = "reimbursements.bank_connect.takeover.mounted",
  ReimbursementsBankErrorBannerMounted = "reimbursements.bank_error.banner.mounted",
  ReimbursementsFormCloseClicked = "reimbursements.form.close.clicked",
  ReimbursementsFormConfirmationAlertMounted = "reimbursements.form.confirmation_alert.mounted",
  ReimbursementsFormConfirmationAlertActionClicked = "reimbursements.form.confirmation_alert.action.clicked",
  ReimbursementsFormFieldFocused = "reimbursements.form.field.focused",
  ReimbursementsFormSingleMounted = "reimbursements.form.single.mounted",
  ReimbursementsFormValidatoinError = "reimbursements.form.validation.error",
  ReimbursementsFormUpdateClicked = "reimbursements.form.update.clicked",
  ReimbursementsFormDeleteClicked = "reimbursements.form.delete.clicked",
  ReimbursementsFormCancelClicked = "reimbursements.form.cancel.clicked",
  ReimbursementsFormSaveAsDraftClicked = "reimbursements.form.save_as_draft.clicked",
  ReimbursementsFormSubmitClicked = "reimbursements.form.submit.clicked",
  ReimbursementsFormIsValid = "reimbursements.form.is_valid",
  ReimbursementsFormBulkClicked = "reimbursements.form.bulk_clicked",
  ReimbursementsFormBulkMounted = "reimbursements.form.bulk.mounted",
  ReimbursementsFormBulkSharedInfoMounted = "reimbursements.form.bulk_shared_info.mounted",
  ReimbursementsFormBulkDetailsMounted = "reimbursements.form_bulk_details.mounted",
  ReimbursementsFormBulkAddRequestClicked = "reimbursements.form.bulk_add_request.clicked",
  ReimbursementsFormBulkRemoveRequestClicked = "reimbursements.form.bulk_remove_request.clicked",
  ReimbursementsFormOcrCancelClicked = "reimbursements.form.ocr.cancel.clicked",
  ReimbursementsFormOcrError = "reimbursements.form.ocr.error",
  ReimbursementsFormOcrSuccess = "reimbursements.form.ocr.success",
  ReimbursementsFormMutationError = "reimbursements.mutation.error",
  ReimbursementsFormMutationSuccess = "reimbursements.mutation.success",
  ReimbursementsFormListAttendeesMounted = "reimbursements.form.list_attendees.mounted",
  ReimbursementsTabChanged = "reimbursements.tab.changed",
  ReimbursementsSCASendOneTimePassCodeClicked = "reimbursements.sca.send_one_time_passcode.clicked",
  ReimbursementsSCASendOneTimePassCodeMutationSuccess = "reimbursements.sca.send_one_time_passcode.success",
  ReimbursementsSCASendOneTimePassCodeMutationError = "reimbursements.sca.send_one_time_passcode.error",
  ReimbursementsSCAReauthenticate = "reimbursements.sca.reauthenticate",
  ReimbursementsSCAApproveBeneficiariesClicked = "reimbursements.sca.approve_beneficiaries.clicked",
  ReimbursementsSCAApproveBeneficiariesMutationSuccess = "reimbursements.sca.approve_beneficiaries.success",
  ReimbursementsSCAApproveBeneficiariesMutationError = "reimbursements.sca.approve_beneficiaries.error",
  ReimbursementsCardMounted = "reimbursements.card.mounted",
  ReimbursementsCardSetupButtonClicked = "reimbursements.card_setup_button.clicked",
  ReimbursementsCardSetupButtonDismissed = "reimbursements.card_setup_button.dismissed",
  // Reimbursement terms
  ReimbursementTermsModalMounted = "reimbursement_terms_modal.mounted",
  ReimbursementTermsModalCheckboxToggled = "reimbursement_terms_modal.checkbox.toggled",
  ReimbursementTermsModalSubmitClicked = "reimbursement_terms_modal.submit.clicked",
  // Reimbursement V3 events (Bulk OCR)
  ReimbursementsNoReceiptClicked = "reimbursements.no_receipt.clicked",
  ReimbursementsBulkOcrReceiptUploadMounted = "reimbursements.bulk_ocr.receipt_upload.mounted",
  ReimbursementsBulkOcrDetailsMounted = "reimbursements.bulk_ocr.details.mounted",
  ReimbursementsBulkOcrCount = "reimbursements.bulk_ocr.count",
  ReimbursementsBulkOcrContinueClicked = "reimbursements.bulk_ocr.continue.clicked",
  ReimbursementsBulkOcrBackClicked = "reimbursements.bulk_ocr.back.clicked",
  ReimbursementsBulkOcrDeleteClicked = "reimbursements.bulk_ocr.delete.clicked",
  ReimbursementsBulkOcrPaginationNextClicked = "reimbursements.bulk_ocr.pagination.next.clicked",
  ReimbursementsBulkOcrPaginationBackClicked = "reimbursements.bulk_ocr.pagination.back.clicked",
  ReimbursementsBulkOcrCarouselClicked = "reimbursements.bulk_ocr.carousel.clicked",
  ReimbursementsBulkOcrApplyAllBudgetClicked = "reimbursements.bulk_ocr.apply_all_budgets.clicked",
  ReimbursementsBulkOcrApplyAllMemoClicked = "reimbursements.bulk_ocr.apply_all_memos.clicked",
  ReimbursementsBulkOcrMultipleReimbursements = "reimbursements.bulk_ocr.multiple_reimbursements",
  // Mileage V2 Events
  ReimbursementsFormMileageFieldMounted = "reimbursements.form.mileage_field.mounted",
  ReimbursementsFormMileageTripTypeChanged = "reimbursements.form.mileage.trip_type.changed",
  ReimbursementsFormMileageAddStopClicked = "reimbursements.form.mileage.add_stop.clicked",
  ReimbursementsFormMileageRemoveStopClicked = "reimbursements.form.mileage.remove_stop.clicked",
  ReimbursementsFormMileageDisabledMounted = "reimbursements.form.mileage_disabled.mounted",
  ReimbursementsFormMileageDistanceOverridden = "reimbursements.form.mileage.distance_overridden",
  MileageTripMapMounted = "mileage.trip_map.mounted",
  MileageDirectionsError = "mileage.directions.error",
  // Receipts Bulk Upload
  ReceiptsEasyUploadFileUploadStart = "receipts.easy_upload.upload.start",
  ReceiptsEasyUploadFilePickerButtonClicked = "receipts.easy_upload.file_picker.button.clicked",
  ReceiptsEasyUploadFileUploadRejectedUnsupportedFileType = "receipts.easy_upload.upload.reject.unsupported_file_type",
  ReceiptsEasyUploadFileUploadRejectedMaximumFiles = "receipts.easy_upload.upload.reject.maximum_files_limit_exceeded",
  ReceiptsEasyUploadFileUploadError = "receipts.easy_upload.upload.error",
  ReceiptsEasyUploadMatchSuccessToastMounted = "receipts.easy_upload.match.success.toast.mounted",
  ReceiptsEasyUploadMatchFailureToastMounted = "receipts.easy_upload.match.failure.toast.mounted",
  ReceiptsEasyUploadToastViewDetailsCTAClicked = "receipts.easy_upload.failed_matches.view_details.cta.clicked",
  ReceiptsEasyUploadFailedMatchesModalCTAClicked = "receipts.easy_upload.failed_matches.modal.cta.clicked",
  // Expenses Inbox Events
  ExpensesInboxLinkToAllExpensesClicked = "expenses.inbox.link_to_all_expenses.clicked",
  // Expenses Drilldown Events
  ExpensesDrilldownMounted = "expenses.drilldown.mounted",
  // Expenses Table Events
  EmpowerExpensesTableBulkActionAttempted = "empower.expenses.table.bulk_action.attempted",
  EmpowerExpensesTableBulkActionSuccess = "empower.expenses.table.bulk_action.success",
  EmpowerExpensesTableSortClicked = "empower.expenses.table.sort.clicked",
  EmpowerExpensesTableSeeMoreClicked = "empower.expenses.table.see_more.clicked",
  EmpowerExpensesTableLoadMoreExpensesForGroupClicked = "empower.expenses.table.load_more_expenses_for_group.clicked",
  EmpowerExpensesTableRowClicked = "empower.expenses.table.row.clicked",
  EmpowerExpensesTableZeroSearchResult = "empower.expenses.table.zero_search_result",
  EmpowerExpensesTableRowClickedForSearch = "empower.expenses.table.row.clicked_for_search",
  EmpowerExpensesTableRowClickedForSearchTop3 = "empower.expenses.table.row.clicked_for_search_top3",
  EmpowerExpensesTableGroupByClicked = "empower.expenses.table.group_by.clicked",
  EmpowerExpensesTableGroupByCleared = "empower.expenses.table.group_by.cleared",
  EmpowerExpensesTableGroupSelectionClicked = "empower.expenses.table.group_selection.clicked",
  EmpowerExpensesTableClearGroupSelectionClicked = "empower.expenses.table.clear_group_selection.clicked",
  EmpowerExpensesTableCheckboxSelected = "empower.expenses.table.checkbox.selected",
  EmpowerExpensesTableMemoUpdated = "empower.expenses.table.memo_updated",
  EmpowerExpensesTableBudgetUpdated = "empower.expenses.table.budget_updated",
  EmpowerExpensesTableMemoFocused = "empower.expenses.table.memo_focused",
  EmpowerExpensesTableBudgetFocused = "empower.expenses.table.budget_focused",
  EmpowerExpensesTablePaginatedPrevClicked = "empower.expenses.table.paginated.prev.clicked",
  EmpowerExpensesTablePaginatedNextClicked = "empower.expenses.table.paginated.next.clicked",
  EmpowerExpensesTableFlaggedExpenseColumnHovered = "empower.expenses.table.flagged_expense.column.hovered",
  // Expense Category Events
  ExpenseBudgetSelectionSaveRequest = "expense.budget_selection.save_request",
  ExpenseBudgetSelectionErrorModalMounted = "expense.budget_selection.error_modal.mounted",
  ExpenseBudgetSelectionErrorModalCloseClicked = "expense.budget_selection.error_modal.close_clicked",
  ExpenseBudgetSelectionErrorModalCtaClicked = "expense.budget_selection.error_modal.cta_clicked",
  ExpenseBudgetSelectionAddUserToBudgetAlertMounted = "expense.budget_selection.add_user_to_budget_alert.mounted",
  ExpenseBudgetSelectionAddUserToBudgetAlertConfirmClicked = "expense.budget_selection.add_user_to_budget_alert.confirm_clicked",
  ExpenseBudgetSelectionAddUserToBudgetAlertCloseClicked = "expense.budget_selection.add_user_to_budget_alert.close_clicked",
  ExpenseCategoriesMounted = "expense.categories.mounted",
  ExpenseCategoriesCategoryChange = "expense.categories.category_change",
  ExpenseCategoriesAddendumChange = "expense.categories.addendum_change",
  // Expense Details Pane Events
  ExpenseDetailsPaneMounted = "expense.details_pane.mounted",
  ExpenseDetailsPaneLoaded = "expense.details_pane.loaded",
  ExpenseErrorDetailsPaneMounted = "expense.error.details_pane.mounted",
  ExpenseErrorDetailsPaneRetryFetchClicked = "expense.error.details_pane.retry_fetch.clicked",
  ExpenseDetailsPaneEditsSaved = "expense.details_pane.edits.saved",
  ExpenseDetailsPaneEditsSaveRequest = "expense.details_pane.edits.save_request",
  ExpenseDetailsPaneBudgetChangeAlertMounted = "expense.details_pane.budget_change_alert.mounted",
  ExpenseDetailsPaneBudgetChangeAlertCloseClicked = "expense.details_pane.budget_change_alert.close_clicked",
  ExpenseDetailsPaneBudgetChangeAlertSaveClicked = "expense.details_pane.budget_change_alert.save_clicked",
  ExpenseDetailsPaneBudgetSearch = "expense.details_pane.budget_search",
  ExpenseDetailsPaneUnsavedChangesAlertMounted = "expense.details_pane.unsaved_changes_alert.mounted",
  ExpenseDetailsPaneUnsavedChangesAlertCloseClicked = "expense.details_pane.unsaved_changes_alert.close_clicked",
  ExpenseDetailsPaneUnsavedChangesAlertProceedWithoutSavingClicked = "expense.details_pane.unsaved_changes_alert.proceed_without_saving.clicked",
  ExpenseDetailsPaneSaveOnReviewModalMounted = "expense.details_pane.save_on_review_modal.mounted",
  ExpenseDetailsPaneSaveOnReviewModalCloseClicked = "expense.details_pane.save_on_review_modal.close_clicked",
  ExpenseDetailsPaneSaveOnReviewModalConfirmClicked = "expense.details_pane.save_on_review_modal.confirm_clicked",
  ExpenseDetailsPaneCancelReimbursementModalMounted = "expense.details_pane.cancel_reimbursement_modal.mounted",
  ExpenseDetailsPaneCancelReimbursementModalConfirmClicked = "expense.details_pane.cancel_reimbursement_modal.confirm_clicked",
  ExpenseDetailsPaneCancelReimbursementModalCloseClicked = "expense.details_pane.cancel_reimbursement_modal.close_clicked",
  ExpenseDetailsPaneDeleteReimbursementModalMounted = "expense.details_pane.delete_reimbursement_modal.mounted",
  ExpenseDetailsPaneDeleteReimbursementModalConfirmClicked = "expense.details_pane.delete_reimbursement_modal.confirm_clicked",
  ExpenseDetailsPaneDeleteReimbursementModalCloseClicked = "expense.details_pane.delete_reimbursement_modal.close_clicked",
  ExpenseDetailsPaneMarkedCardExpenseAsReviewed = "expense.details_pane.marked_card_expense.reviewed",
  ExpenseDetailsPaneReimbursementRequestActioned = "expense.details_pane.reimbursement_request.actioned",
  ExpenseDetailsPanePaymentFailureReasonBannerClicked = "expense.details_pane.payment_failure_reason_banner.clicked",
  ExpenseDetailsPaneUpdateRequestSuccess = "expense.details_pane.update_request.success",
  ExpenseDetailsPaneDocumentationRequirementTooltipMounted = "expense.details_pane.documentation_requirement_tooltip.mounted",
  ExpenseDetailsPaneShowReceiptPaneButtonClicked = "expense.details_pane.show_receipt_pane_button.clicked",
  ExpenseDetailsPaneExpenseCyclingClicked = "expense.details_pane.expense_cycling.clicked",
  ExpenseDetailsPaneTabClicked = "expense.details_pane.tab.clicked",
  ExpenseDetailsPaneConfirmAutoAssignedLimitClick = "expense.details_pane.confirm_auto_assigned_limit.click",
  ExpenseDetailsPaneConfirmAutoAssignedLimitSuccess = "expense.details_pane.confirm_auto_assigned_limit.success",
  // Expense Details Pane - Billpay
  ExpenseDetailsPaneEditBillClicked = "expense.details_pane.edit_bill.clicked",
  // Expense Details Pane - Audit Trail
  ExpenseDetailsPaneBudgetAuditTrailMounted = "expense.details_pane.budget_audit_trail.mounted",
  ExpenseDetailsPaneBudgetAuditTrailSectionToggled = "expense.details_pane.budget_audit_trail.section.toggled",
  // Expense Details Pane - List Attendees
  ExpenseDetailsPaneListAttendeesMounted = "expense.details_pane.list_attendees.mounted",
  ExpenseDetailsPaneListAttendeesTooltipMounted = "expense.details_pane.list_attendees.tooltip.mounted",
  // Expense Details Pane - Auto Generated Receipt
  ExpenseDetailsPaneAutoGeneratedBasicReceiptTooltipMounted = "expense.details_pane.receipt.basic.tooltip.mounted",
  ExpenseDetailsPaneAutoGeneratedBasicReceiptTooltipEditPolicyClicked = "expense.details_pane.receipt.basic.tooltip.edit-policy.clicked",
  // Expense Details Pane - Receipt Affidavit
  ExpenseDetailsPaneGenerateAffidavitMounted = "expense.details_pane.generate_affidavit.mounted",
  ExpenseDetailsPaneGenerateAffidavitClicked = "expense.details_pane.generate_affidavit.clicked",
  ExpenseDetailsPaneGenerateAffidavitConfirmed = "expense.details_pane.generate_affidavit.confirmed",
  ExpenseDetailsPaneGenerateAffidavitCanceled = "expense.details_pane.generate_affidavit.canceled",
  ExpenseDetailsPaneGenerateAffidavitSuccess = "expense.details_pane.generate_affidavit.success",
  ExpenseDetailsPaneGenerateAffidavitError = "expense.details_pane.generate_affidavit.error",
  // Expense Details Pane - Upload receipt from mobile
  ExpenseDetailsPaneUploadFromMobileMounted = "expense.details_pane.receipt.upload_from_mobile.mounted",
  ExpenseDetailsPaneUploadFromMobileClicked = "expense.details_pane.receipt.upload_from_mobile.clicked",
  ExpenseDetailsPaneUploadFromMobileNotificationTriggered = "expense.details_pane.receipt.upload_from_mobile.notification_triggered",
  ExpenseDetailsPaneUploadFromMobileIntroMounted = "expense.details_pane.receipt.upload_from_mobile.intro.mounted",
  // Expense Details Pane Events Itemize Expense
  ExpenseDetailsPaneItemizeExpenseClicked = "expense.details_pane.itemizeExpense.clicked",
  ExpenseDetailsPaneItemizeExpenseAddClicked = "expense.details_pane.itemizeExpense.add_clicked",
  ExpenseDetailsPaneItemizeExpenseRemoveClicked = "expense.details_pane.itemizeExpense.remove_clicked",
  ExpenseDetailsPaneItemizeExpenseAmountChanged = "expense.details_pane.itemizeExpenseamount_changed",
  ExpenseDetailsPaneItemizeExpenseCategoryChanged = "expense.details_pane.itemizeExpense.category_changed",
  ExpenseDetailsPaneItemizeExpenseSaveClicked = "expense.details_pane.itemizeExpense.save_clicked",
  ExpenseDetailsPaneItemizeExpenseCancelClicked = "expense.details_pane.itemizeExpense.cancel_clicked",
  ExpenseDetailsPaneItemizeExpenseConfirmModalCloseClicked = "expense.details_pane.itemizeExpenseConfirmModal.close_clicked",
  ExpenseDetailsPaneItemizeExpenseConfirmModalConfirmClicked = "expense.details_pane.itemizeExpenseConfirmModal.confirm_clicked",
  // Expense Details Pane Receipt Integration Educational Banner
  ExpenseDetailsPaneReceiptIntegrationBannerMounted = "expense.details_pane.receiptIntegration.banner.mounted",
  ExpenseDetailsPaneReceiptIntegrationBannerCTAClicked = "expense.details_pane.receiptIntegration.banner.clicked",
  ExpenseDetailsPaneReceiptIntegrationConfirmationModalConfirmClicked = "expense.details_pane.receiptIntegration.confirmModal.confirm_clicked",
  // Expense Details Categorization Section
  ExpenseDetailsPaneCategorizationCustomRuleResolutionApplyRuleClicked = "expense.details_pane.categorization.custom_rule_resolution.apply_rule.clicked",
  ExpenseDetailsPaneCategorizationCustomRuleResolutionRemoveAllRulesClicked = "expense.details_pane.categorization.custom_rule_resolution.remove_all_rules.clicked",
  // Expense Details Footer AJ Specific
  ExpenseDetailsPaneMarkReadyClicked = "expense.details_pane.mark_ready.clicked",
  ExpenseDetailsPaneSentBackToReviewClicked = "expense.details_pane.sent_back_to_review.clicked",
  // Expense Details Autosave
  ExpenseDetailsPaneAutosaveCompleted = "expense.details_pane.autosave.save.completed",
  ExpenseDetailsPaneAutosaveUnsavedChangesAlertMounted = "expense.details_pane.autosave.unsaved_changes_alert.mounted",
  ExpenseDetailsPaneAutosaveUnsavedChangesAlertCloseClicked = "expense.details_pane.autosave.unsaved_changes_alert.closed",
  ExpenseDetailsPaneAutosaveUnsavedChangesAlertProceedWithoutSavingClicked = "expense.details_pane.autosave.unsaved_changes_alert.clicked",
  // Expense Details Memo Suggestion
  ExpenseDetailsPaneMemoSuggestionUpsellMounted = "expense.details_pane.memo.suggestion.upsell_tooltip.mounted",
  ExpenseDetailsPaneMemoSuggestionUpsellDismissed = "expense.details_pane.memo.suggestion.upsell_tooltip.dismiss.clicked",
  ExpenseDetailsPaneMemoSuggestionMounted = "expense.details_pane.memo.suggestion.mounted",
  ExpenseDetailsPaneMemoSuggestionClicked = "expense.details_pane.memo.suggestion.clicked",
  ExpenseDetailsPaneMemoSuggestionEditPolicyUpsellClicked = "expense.details_pane.memo.suggestion.edit_policy.clicked",
  // Expense Details Pane Suggestion
  ExpenseDetailsPaneAttendeeSuggestionMounted = "expense.details_pane.attendee.suggestion.mounted",
  ExpenseDetailsPaneAttendeeSuggestionClicked = "expense.details_pane.attendee.suggestion.clicked",
  // Expense Details budget suggestions
  ExpenseDetailsPaneBudgetSuggestionUpsellMounted = "expense.details_pane.budget.suggestion.upsell_tooltip.mounted",
  ExpenseDetailsPaneBudgetSuggestionUpsellDismissed = "expense.details_pane.budget.suggestion.upsell_tooltip.dismiss.clicked",
  ExpenseDetailsPaneBudgetSuggestionMounted = "expense.details_pane.budget.suggestion.mounted",
  ExpenseDetailsPaneBudgetSuggestionClicked = "expense.details_pane.budget.suggestion.clicked",
  ExpenseDetailsPaneBudgetSuggestionDismiss = "expense.details_pane.budget.suggestion.dismiss",
  ExpenseDetailsPaneBudgetSuggestionFailedDryRunBudgets = "expense.details_pane.budget.suggestion.failed_dry_run_budgets",
  // Expense Details Pane Assistant Attribution
  ExpenseDetailsPaneBrexAssistantAttributionLabelMounted = "expense.details_pane.brex_assistant.attribution.label.mounted",
  // Expense Details Attendees
  ExpenseDetailsPaneLastEnteredAttendeesClicked = "expense.details_pane.attendees.last_entered.clicked",
  ExpenseDetailsPaneExternalAttendeeFormChange = "expense.details_pane.external_attendee.form.change",
  // Expense details flagged expense
  ExpenseDetailsPaneFlaggedExpenseBannerMounted = "expense.details_pane.flagged_expense_banner.mounted",
  ExpenseDetailsPaneReviewReasonBannerMounted = "expense.details_pane.review_reason_banner.mounted",
  // Expense details payment status banner
  ExpenseDetailsPanePaymentStatusBannerMounted = "expense.details_pane.payment_status.banner.mounted",
  // Budget and Policy Expense Details
  ExpenseDetailsPaneBudgetAndPolicyExpenseFieldUpdated = "expense.details_pane.budget_and_policy.expense_field",
  // Expense Details Pane - First Spend Guide
  ExpenseDetailsPaneFirstSpendGuideSeen = "expense.details_pane.first_spend_guide.seen",
  ExpenseDetailsPaneFirstSpendGuideCompleted = "expense.details_pane.first_spend_guide.completed",
  // Declined expenses - suggested actions
  ExpenseDeclineSuggestionView = "expense.decline_suggestion.view",
  ExpenseDeclineSuggestionClick = "expense.decline_suggestion.clicked",
  ExpenseDeclineSuggestionOptionsView = "expense.decline_suggestion.multiple_options",
  ExpenseDeclineSuggestionShowOptions = "expense.decline_suggestion.show_options_click",
  // Bulk Edit Details
  BulkEditDetailsExpenseMounted = "expense.bulk_edit_details.mounted",
  BulkEditDetailsEditClicked = "expense.bulk_edit_details.edit.clicked",
  BulkEditDetailsUpdatedSuccess = "expense.bulk_edit_details.updated.success",
  // Employee Repayments
  EmployeeRepaymentModalMounted = "employee_repayment.modal.mounted",
  EmployeeRepaymentModalCloseClicked = "employee_repayment.modal.close_clicked",
  EmployeeRepaymentCreateRepaymentConfirmClicked = "employee_repayment.create_repayment.confirm_clicked",
  EmployeeRepaymentCancelRepaymentConfirmClicked = "employee_repayment.cancel_repayment.confirm_clicked",
  EmployeeRepaymentSubmitRepaymentConfirmClicked = "employee_repayment.submit_repayment.confirm_clicked",
  EmployeeRepaymentError = "employee_repayment.error",
  EmployeeRepaymentSuccess = "employee_repayment.success",
  EmployeeRepaymentCompleteRepaymentClicked = "employee_repayment.complete_repayment.clicked",
  EmployeeRepaymentSelfInitiatedRepaymentClicked = "employee_repayment.complete_repayment.clicked",
  EmployeeRepaymentRetryRepaymentConfirmClicked = "employee_repayment.retry_repayment.confirm_clicked",
  EmployeeRepaymentRequestRepaymentOnReimbursementClicked = "employee_repayment.request_repayment.reimbursement.clicked",
  EmployeeRepaymentSubmitOwnRepaymentOnReimbursementClicked = "employee_repayment.submit_own_repayment.reimbursement.clicked",
  // shared events
  ApproveExpensesModalMounted = "approve_expenses.modal.mounted",
  DenyExpensesModalMounted = "deny_expenses.modal.mounted",
  // End of EXPENSES related instrumentation
  // EXPENSES
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // ONBOARDING
  // Start of ONBOARDING related instrumentation
  // Onboarding Events
  OnboardingCompanyPolicyAgreementViewed = "onboarding.company_policy_agreement.viewed",
  OnboardingCompanyPolicyAgreementErrored = "onboarding.company_policy_agreement.errored",
  OnboardingCompanyPolicyAgreementCompleted = "onboarding.company_policy_agreement.completed",
  // Onboarding Experience Events
  OnboardingExperienceMobileGuardViewed = "onboarding_experience.mobile_guard.viewed",
  OnboardingExperienceSubStepViewed = "onboarding_experience.substep.viewed",
  OnboardingExperienceSubStepBackClicked = "onboarding_experience.substep.back",
  OnboardingExperienceSubStepCompleted = "onboarding_experience.substep.completed",
  OnboardingExperienceDirectReportsLoaded = "onboarding_experience.direct_reports.loaded",
  // Onboarding Experience Machine Events
  OnboardingExperienceMachineStarted = "onboarding_experience.machine.started",
  OnboardingExperienceMachineCompleted = "onboarding_experience.machine.completed",
  OnboardingExperienceMachineState = "onboarding_experience.machine.state",
  OnboardingExperienceMachineStateViewed = "onboarding_experience.machine.state_viewed",
  // End of ONBOARDING related instrumentation
  // ONBOARDING
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // WALLET
  // Start of WALLET related instrumentation
  YourWalletYourCardsEmptyStateSetupCardMounted = "your_wallet.your_cards.empty_state_setup_card.mounted",
  YourWalletYourCardsEmptyStateSetupAutopayMounted = "your_wallet.your_cards.empty_state_setup_autopay.mounted",
  YourWalletYourCardsEmptyStateApplyForCreditMounted = "your_wallet.your_cards.empty_state_apply_for_credit.mounted",
  YourWalletYourCardsEmptyStateAwaitingApprovalMounted = "your_wallet.your_cards.empty_state_awaiting_approval.mounted",
  YourWalletYourCardsEmptyStateApplyForCreditLimitClicked = "your_wallet.your_cards.empty_state_apply_for_credit_limit.clicked",
  YourWalletYourCardsEmptyStateVerifyClicked = "your_wallet.your_cards.empty_state_verify.clicked",
  YourWalletYourCardsEmptyStateApplicationClicked = "your_wallet.your_cards.empty_state_application.clicked",
  // Admin Card Shipping Controller Events
  AdminCardShippingMounted = "admin_card_shipping.mounted",
  AdminCardShippingNextStep = "admin_card_shipping.next_step",
  // Admin Card Shipping Flow Events
  AdminCardShippingFlowMounted = "admin_card_shipping_flow.mounted",
  AdminCardShippingFlowCompleted = "admin_card_shipping_flow.completed",
  AdminCardShippingFlowExited = "admin_card_shipping_flow.exited",
  // Admin Card Setup Education Events
  AdminCardSetupEducationMounted = "admin_card_setup_education.mounted",
  // Your Wallet Header
  YourWalletSelectedBudgetHeaderMounted = "your_wallet.selected_budget_header.mounted",
  YourWalletSelectedBudgetHeaderHovered = "your_wallet.selected_budget_header.hovered",
  // Wallet Revamp Events
  YourWalletManageBudgetClicked = "your_wallet.manage_budget.clicked",
  YourWalletManageBudgetMounted = "your_wallet.manage_budget.mounted",
  YourWalletBudgetReassigned = "your_wallet.budget.reassigned",
  YourWalletBudgetReassignToastShown = "your_wallet.budget.reassign.toast.shown",
  YourWalletBudgetReassignToastCtaClicked = "your_wallet.budget.reassign.toast.cta.clicked",
  YourWalletViewAllBudgetsAndCardsClicked = "your_wallet.view_all_budgets_and_cards.clicked",
  YourWalletViewCardDetailsClicked = "your_wallet.view_card_details.clicked",
  YourWalletViewBudgetDetailsClicked = "your_wallet.view_budget_details.clicked",
  CreditCardDetailsCopied = "credit_card_details.copied",
  CreditCardSensitiveInfoDNSError = "card.sensitive_info.dns_error",
  YourWalletNewVirtualCardClicked = "your_wallet.new_virtual_card.clicked",
  YourWalletNewVirtualCardCreated = "your_wallet.new_virtual_card.created",
  YourWalletNewVendorCardClicked = "your_wallet.new_vendor_card.clicked",
  YourWalletNewVirtualCardDone = "your_wallet.new_virtual_card.done",
  CardDetailsPaneMounted = "card_details_pane.mounted",
  // Budgets and cards pane events
  BudgetsCardsPaneViewSubPaneClicked = "budgets_cards_pane.view_sub_pane.clicked",
  BudgetsCardsPaneSearchEntered = "budgets_cards_pane.search.entered",

  // End of WALLET related instrumentation
  // WALLET
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // TASK INBOX
  // Start of TASK INBOX related instrumentation
  // Request Review Toast Events
  // Task Inbox Events
  TaskInboxView = "task_inbox.view",
  TaskInboxError = "task_inbox.error",
  TaskInboxViewTasks = "task_inbox.view_tasks",
  TaskInboxEmpty = "task_inbox.empty",
  TaskInboxPaginationClick = "task_inbox.pagination_click",
  TaskInboxPaginationClickError = "task_inbox.pagination_click.error",
  TaskInboxViewUnexpectedType = "task_inbox.view.unexpected_type",
  TaskInboxSectionView = "task_inbox.section.view",
  TaskInboxPartiallyMaterializedBannerView = "task_inbox.partially_materialized_banner.view",
  TaskInboxTaskClick = "task_inbox.task.click",
  TaskInboxTaskDismissClick = "task_inbox.task.dismiss_click",
  TaskInboxTaskClose = "task_inbox.task.close",
  TaskInboxDetailsPaneView = "task_inbox.details_pane.view",
  TaskInboxDetailsPaneError = "task_inbox.details_pane.error",
  TaskInboxDetailsPanePrimaryCtaClick = "task_inbox.details_pane.primary_cta.click",
  TaskInboxDetailsPanePrimaryCtaClickSuccess = "task_inbox.details_pane.primary_cta.click.success",
  TaskInboxDetailsPanePrimaryCtaClickError = "task_inbox.details_pane.primary_cta.click.error",
  TaskInboxDetailsPaneContactSupportTeamCtaClick = "task_inbox.details_pane.contact_support_team_cta.click",
  TaskInboxDetailsPaneCloseCtaClick = "task_inbox.details_pane.close_cta.click",
  TaskInboxDetailsPaneViewExpenseCtaClick = "task_inbox.details_pane.view_expense_cta.click",
  TaskInboxDetailsPaneRelevantExpensesSeeMoreClick = "task_inbox.details_pane.relevant_expenses_see_more.click",
  TaskInboxBulkSelectClick = "task_inbox.bulk_select.click",
  TaskInboxBulkResolveTasksClick = "task_inbox.bulk_resolve_tasks.click",
  TaskInboxAdditionalTasksView = "task_inbox.additional_tasks.view",
  TaskInboxAdditionalTasksClick = "task_inbox.additional_tasks.click",
  TaskCompleted = "task.completed",
  TaskClosed = "task.closed",
  // TODO: We can deprecate after confirming that the new instrumentation is working
  // and we can remove this event from any looker/amplitude/hex dashboards
  TaskInboxBudgetRequestsCtaTap = "task_inbox.budget_requests.cta.tap", // This is used for when the status filters is clicked
  TaskInboxBudgetRequestsTableEmpty = "task_inbox.budget_requests.table.empty",
  TaskInboxBudgetRequestsTableView = "task_inbox.budget_requests.table.view",
  TaskInboxBudgetRequestsTableError = "task_inbox.budget_requests.table.error",
  // TODO: We can deprecate after confimrming that the new instrumentation is working
  // and we can remove this event from any looker/amplitude/hex dashboards
  // TaskInboxListError to be replaced by TaskInboxError
  // TaskInboxListViewTasks to be replaced by TaskInboxViewTasks
  // TaskInboxListPaginationClick to be replaced by TaskInboxPaginationClick
  // TaskInboxListPaginationClickError to be replaced by TaskInboxPaginationClickError
  // TaskInboxListTaskClick to be replaced by TaskInboxTaskClick
  // TaskInboxListTaskClose to be replaced by TaskInboxTaskClose
  // TaskInboxDetailsPaneCloseCtaClick to be replaced by TaskInboxTaskClose
  // TaskInboxListExtendedFiltersAddClick to be replaced by TaskInboxExtendedFiltersClick
  TaskInboxListError = "task_inbox.list.error",
  TaskInboxListViewTasks = "task_inbox.list.view_tasks",
  TaskInboxListPaginationClick = "task_inbox.list.pagination_click",
  TaskInboxListPaginationClickError = "task_inbox.list.pagination_click.error",
  TaskInboxListTaskClick = "task_inbox.list.task_click",
  TaskInboxListTaskClose = "task_inbox.list.task_close",
  // These are the filters cta events that should be deprecated (to remove the `list` from the name)
  TaskInboxListExtendedFiltersAddClick = "task_inbox.list.extended_filters_add.click",
  TaskInboxListFiltersClick = "task_inbox.list.filters.click",
  TaskInboxListFiltersClearClick = "task_inbox.list.filters_clear.click",
  TaskInboxListFiltersAddClick = "task_inbox.list.filters_add.click",
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // End of TASK INBOX related instrumentation

  // NOTIFICATION CENTER
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  NotificationCenterSeeMoreClick = "notification_center.see_more.click",
  NotificationCenterOverviewAbandoned = "notification_center_overview.abandoned",
  NotificationCenterOverviewView = "notification_center_overview.view",
  NotificationCenterOverviewBadgeCountError = "notification_center_overview.badge_count_error",
  NotificationCenterViewAlerts = "notification_center.view_alerts",
  NotificationCenterViewError = "notification_center.error",
  NotificationCenterAlertMarkReadTap = "notification_center.alert.mark_read.tap",
  NotificationCenterAlertUpdateStatusError = "notification_center.alert.update_status.error",
  NotificationCenterAlertMarkUnreadTap = "notification_center.alert.mark_unread.tap",
  NotificationCenterAlertCtaTapFailed = "notification_center.alert.cta_failed",
  NotificationCenterAlertCtaTapSuccess = "notification_center.alert.cta_resolved",
  NotificationCenterAlertPrimaryCtaTap = "notification_center.alert.primary_cta.tap",
  NotificationCenterAlertSecondaryCtaTap = "notification_center.alert.secondary_cta.tap",
  NotificationCenterAlertSeeMoreTap = "notification_center.alert.see_more.tap",
  NotificationCenterAlertSubscribeTap = "notification_center.alert.subscribe.tap",
  NotificationCenterAlertUnsubscribeTap = "notification_center.alert.unsubscribe.tap",
  NotificationCenterAlertSeeLessTap = "notification_center.alert.see_less.tap",
  NotificationCenterReadAllTap = "notification_center.read_all.tap",
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // End of NOTIFICATION CENTER related instrumentation

  // SHARED
  // Start of SHARED related instrumentation
  // User timezone events
  UsersTimezoneModalOpen = "users.time_zone.modal.open",
  UsersTimezoneUpdated = "users.time_zone.updated",
  // Request Review Toast Events
  RequestReviewToastMounted = "request_review.toast.mounted",
  RequestReviewToastUndoClicked = "request_review.toast.undo.clicked",
  // End of SHARED related instrumentation
  // SHARED
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // Custom CSV Export
  CustomCsvExportEntryButtonClicked = "custom_csv_export.entry_button.clicked",
  CustomCsvExportCreateTemplateButtonClicked = "custom_csv_export.create_template.clicked",
  CustomCsvExportCustomColumnsSaved = "custom_csv_export.columns_saved",
  CustomCsvExportColumnHeaderUpdated = "custom_csv_export.column_header.updated",
  CustomCsvExportExportAsIsClicked = "custom_csv_export.export_as_is.clicked",
  CustomCsvExportTemplateClick = "custom_csv_export.template.clicked",
  CustomCsvExportCSVExportClicked = "custom_csv_export.csv_export.clicked",
  CustomCsvExportERPExportClicked = "custom_csv_export.erp_export.clicked",
  CustomCsvExportAccountingIntegrationExportClicked = "custom_csv_export.accounting_integration_export.clicked",
  CustomCsvExportIntegrationStatusErrorMounted = "custom_csv_export.integration_status_error.mounted",
  CustomCsvExportSyncStatusErrorMounted = "custom_csv_export.sync_status_error.mounted",
  CustomCsvExportTooManyTransactionsErrorMounted = "custom_csv_export.too_many_transactions_error.mounted",
  CustomCsvExportDetailsPaneMounted = "custom_csv_export.details_pane.mounted",
  CustomCsvExportDetailsPaneCloseClicked = "custom_csv_export.details_pane.close.clicked",
  CustomCsvExportDetailsPaneOptionsMounted = "custom_csv_export.details_pane.options.mounted",
  CustomCsvExportDetailsPaneAccrualOptionsMounted = "custom_csv_export.details_pane.accrual.options.mounted",
  CustomCsvExportDetailsPaneOptionsCancelClicked = "custom_csv_export.details_pane.options.cancel.clicked",
  CustomCsvExportDetailsPaneOptionsBackClicked = "custom_csv_export.details_pane.options.back.clicked",
  CustomCsvExportDetailsPaneOptionsNextClicked = "custom_csv_export.details_pane.options.next.clicked",
  CustomCsvExportDetailsPaneConfirmationMounted = "custom_csv_export.details_pane.confirmation.mounted",
  CustomCsvExportGenericErrorMounted = "custom_csv_export.generic_error.mounted",
  CustomCsvExportAccrualReversalDateClicked = "custom_csv_export.accrual.reversal_date.clicked",
  // End of CSV Export.
  // Request CTA events.
  RequestCtaBudgetsRequestBudgetClick = "request_cta.budgets.request_budget.click",
  RequestCtaBudgetsCreateBudgetClick = "request_cta.budgets.create_budget.click",
  RequestCtaBudgetsCreateBudgetProgramClick = "request_cta.budgets.create_budget_program.click",
  RequestCtaBudgetsCreateNestedBudgetClick = "request_cta.budgets.create_nested_budget.click",
  RequestCtaWalletRequestBudgetClick = "request_cta.wallet.request_budget.click",
  RequestCtaReimbursementsRequestOutOfPocketClick = "request_cta.reimbursements.request_out_of_pocket.click",
  RequestCtaReimbursementsRequestMileageClick = "request_cta.reimbursements.request_mileage.click",
  RequestCtaReimbursementsViewDraftsClick = "request_cta.reimbursements.view_drafts.click",
  // End of Request CTA events.
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // COMMENT
  // Start of COMMENT related instrumentation
  CommentSendCommentAttempt = "comment.send_comment.attempt",
  CommentSendCommentSuccess = "comment.send_comment.success",
  CommentSendCommentError = "comment.send_comment.error",
  CommentBulkDocumentationReminderCtaMounted = "comment.bulk_documentation_reminder.cta.mounted",
  CommentBulkDocumentationReminderModalMounted = "comment.bulk_documentation_reminder.modal.mounted",
  CommentBulkDocumentationReminderSent = "comment.bulk_documentation_reminder.sent",
  CommentBulkDocumentationReminderSentSuccess = "comment.bulk_documentation_reminder.sent.success",
  CommentDocumentationReminderMounted = "comment.documentation_reminder.mounted",
  CommentDocumentationReminderClicked = "comment.documentation_reminder.clicked",
  CommentSuggestionAddToMemoMounted = "comment.suggestion.add_to_memo.mounted",
  CommentSuggestionAddToMemoClicked = "comment.suggestion.add_to_memo.clicked",
  CommentManuallyResolveTaskClicked = "comment.manually_resolve_task.clicked",
  CommentAddTaggedEmployeesModalDismissClicked = "comment.add_tagged_employees_modal.dismiss.clicked",
  CommentAddTaggedEmployeesModalAddClicked = "comment.add_tagged_employees_modal.add.clicked",
  CommentAddTaggedEmployeesModalCloseClicked = "comment.add_tagged_employees_modal.close.clicked",
  CommentRemoveTaggedEmployeesModalDismissClicked = "comment.remove_tagged_employees_modal.dismiss.clicked",
  CommentRemoveTaggedEmployeesModalRemoveClicked = "comment.remove_tagged_employees_modal.add.clicked",
  CommentRemoveTaggedEmployeesModalCloseClicked = "comment.remove_tagged_employees_modal.close.clicked",
  // End of COMMENT related instrumentation
  // COMMENT
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // // REPORTING
  // // Start of REPORTING related instrumentation
  ReportingReportsPageMounted = "reporting.reports_page.mounted",
  ReportingReportMounted = "reporting.report.mounted",
  ReportingReportActionsMenuClicked = "reporting.report.actions_menu.clicked",
  ReportingReportDeleteReportClicked = "reporting.report.delete_report.clicked",
  ReportingReportDeleteReportConfirmed = "reporting.report.delete_report.confirmed",
  ReportingReportEditReportClicked = "reporting.report.edit_report.clicked",
  ReportingReportReportNameEdited = "reporting.report.report_name.edited",
  ReportingReportDiscardChangesClicked = "reporting.report.discard_changes.clicked",
  ReportingReportSaveChangesClicked = "reporting.report.save_changes.clicked",
  ReportingReportSaveCopyClicked = "reporting.report.save_copy.clicked",
  ReportingReportDuplicateReportClicked = "reporting.report.duplicate_report.clicked",
  ReportingReportShareReportModalOpened = "reporting.report.share_report_modal.opened",
  ReportingReportShareReportModalConfirmClicked = "reporting.report.share_report_modal.confirm.clicked",
  ReportingReportShareReportModalConfirmSuccess = "reporting.report.share_report_modal.confirm.success",
  ReportingReportShareReportModalCancelClicked = "reporting.report.share_report_modal.cancel.clicked",
  ReportingReportFiltersAddFilterClicked = "reporting.report.filters.add_filter.clicked",
  ReportingReportFiltersFilterAdded = "reporting.report.filters.filter.added",
  ReportingReportFiltersFilterValueSelected = "reporting.report.filters.filter_value.selected",
  ReportingReportFiltersFilterRemoved = "reporting.report.filters.filter.removed",
  ReportingReportLayoutChanged = "reporting.report.layout.changed",
  ReportingReportAddWidgetClicked = "reporting.report.add_widget.clicked",
  ReportingWidgetEditorVizTypeSelected = "reporting.widget_editor.viz_type.selected",
  ReportingWidgetEditorSaveButtonClicked = "reporting.widget_editor.save_button.clicked",
  ReportingWidgetEditorDiscardChangesButtonClicked = "reporting.widget_editor.discard_changes_button.clicked",
  ReportingWidgetLoaded = "reporting.widget.loaded",
  ReportingWidgetActionsMenuClicked = "reporting.widget.actions_menu.clicked",
  ReportingWidgetCloneWidgetClicked = "reporting.widget.clone_widget.clicked",
  ReportingWidgetDeleteWidgetClicked = "reporting.widget.delete_widget.clicked",
  ReportingWidgetEditWidgetClicked = "reporting.widget.edit_widget.clicked",
  ReportingWidgetViewExpensesClicked = "reporting.widget.view_expenses.clicked",
  ReportingWidgetDownloadExpensesClicked = "reporting.widget.export_expenses.clicked",
  ReportingWidgetBarGroupHovered = "reporting.widget.bar_group.hovered",
  ReportingWidgetBarClicked = "reporting.widget.bar.clicked",
  ReportingWidgetFilterByClicked = "reporting.widget.filter_by.clicked",
  ReportingWidgetTooltipHovered = "reporting.widget.tooltip.hovered",
  ReportingDefaultStockReportSelected = "reporting.default_stock_report.selected",
  // // End of REPORTING related instrumentation
  // // REPORTING
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // STATEMENTS
  // Start of STATEMENTS related instrumentation
  StatementsListPaneView = "statements.view",
  StatementsYearExpand = "statements.year.expand",
  StatementsSeeMoreClick = "statements.see_more.click",
  StatementsDownloadClick = "statements.download.click",
  // End of STATEMENTS related instrumentation
  // STATEMENTS
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // ConsolidatedBilling
  // Start of ConsolidatedBilling related instrumentation
  BillingModalOpen = "billing.modal.open",
  BillingModalClose = "billing.modal.close",
  BillingSpendVisualizationGroupingChange = "billing.spend-visualization-grouping.change",
  BillingPendingActivityCardView = "billing.pending-activity-card.view",
  BillingPendingActivitySectionView = "billing.pending-activity-section.view",
  BillingViewPendingExpensesClick = "billing.view-pending-expenses.click",
  BillingTransactionDetailPaneView = "billing.transaction-detail-pane.view",
  BillingPaymentDetailManageAutopayClick = "billing.payment-detail.manage-autopay.click",
  BillingPaymentDetailMakePaymentClick = "billing.payment-detail.make-payment.click",
  BillingPaymentDetailFailureBannerView = "billing.payment-detail.failure-banner.view",
  BillingPurchaseDetailExpenseDetailClick = "billing.purchase-detail.expense-detail.click",
  BillingPurchaseDetailExpenseDetailClear = "billing.purchase-detail.expense-detail.clear",
  BillingPurchaseDetailItemizeExpenseSuccess = "billing.purchase-detail.itemize-expense.success",
  BillingTransactionsPageSizeChange = "billing.transactions.page-size.change",
  BillingTransactionsPreviousPage = "billing.transactions.previous-page",
  BillingTransactionsNextPage = "billing.transactions.next-page",
  BillingTransactionsGroupingChange = "billing.transactions.grouping.change",
  BillingTransactionsAddFilter = "billing.transactions.add-filter",
  BillingTransactionsClearAllFilters = "billing.transactions.clear-all-filters",
  BillingAlertView = "billing.alert.view",
  BillingAlertClick = "billing.alert.click",
  BillingSeePastPaymentsClick = "billing.see-past-payments.click",
  BillingTransactionsDownloadModalView = "billing.transactions.download-modal.view",
  BillingTransactionsDownloadModalClosed = "billing.transactions.download-modal.closed",
  BillingTransactionsDownloadClick = "billing.transactions.download.click",
  // End of ConsolidatedBilling related instrumentation
  // ConsolidatedBilling
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // REIMBURSEMENT TRACKING
  // Start of Reimbursement Tracking related instrumentation
  ReimbursementTrackingExportExpensesButtonClick = "reimbursement_tracking.export.expenses.button.click",
  ReimbursementTrackingExportHistoryPaneButtonClick = "reimbursement_tracking.export.history.pane.button.click",
  ReimbursementTrackingDownloadHistoryButtonClick = "reimbursement_tracking.download.history.button.click",
  ReimbursementTrackingExpensesExported = "reimbursement_tracking.expenses.exported",
  // End of Reimbursement Tracking related instrumentation
  // REIMBURSEMENT TRACKING
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // Automation Suggestions
  InboxSuggestionsDropdownClick = "inbox.suggestions.dropdown.click",
  InboxSuggestionsDropdownSuggestionCardMounted = "inbox.suggestions.dropdown.card.mounted",
  InboxSuggestionsBulkAcceptClick = "inbox.suggestions.dropdown.suggestions.bulk_accept.click",
  InboxSuggestionsBulkDismissClick = "inbox.suggestions.dropdown.suggestions.bulk_dismiss.click",
  InboxSuggestionsBulkUndoClick = "inbox.suggestions.dropdown.suggestions.bulk_undo.click",
  InboxSuggestionsSingleAcceptClick = "inbox.suggestions.dropdown.suggestions.single_accept.click",
  InboxSuggestionsSingleDismissClick = "inbox.suggestions.dropdown.suggestions.single_dismiss.click",
  InboxSuggestionsSingleUndoClick = "inbox.suggestions.dropdown.suggestions.single_undo.click",
  InboxSuggestionsViewAnotherClick = "inbox.suggestions.dropdown.suggestions.view_another.click",
  // Expense Assistant
  ExpenseAssistantMounted = "expense_assistant.mounted",
  ExpenseAssistantCloseClick = "expense_assistant.close.click",
  // End of Automation events
  // Attribute mapping
  AttributeMappingViewMounted = "attribute.mapping.view.mounted",
  AttributeMappingSubmitted = "attribute.mapping.submitted",
  // End of Attribute mapping events
  // Configure Billed Entities Flow - Credit and Reimbursements
  ConfigureBillingFlowButtonClicked = "configure.billing.flow.button.clicked",
  ConfigureBillingFlowMounted = "configure.billing.flow.mounted",
  ConfigureBillingFlowLegalEntityChanged = "configure.billing.flow.legal.entity.changed",
  ConfigureBillingFlowCountryChanged = "configure.billing.flow.country.changed",
  ConfigureBillingFlowCurrencyChanged = "configure.billing.flow.currency.changed",
  ConfigureBillingFlowPaymentMethodChanged = "configure.billing.flow.payment_method.changed",
  ConfigureBillingFlowFundingSourceChanged = "configure.billing.flow.funding.source.changed",
  ConfigureBillingFlowBillingCycleChanged = "configure.billing.flow.billing.cycle.changed",
  ConfigureBillingFlowAirwalexKycLinkClicked = "configure.billing.flow.airwalex.kyc.link.clicked",
  ConfigureBillingFlowReviewScreenMounted = "configure.billing.flow.review.screen.mounted",
  ConfigureBillingFlowTermsAndConditionsAccepted = "configure.billing.flow.accepts.t&c",
  ConfigureBillingFlowConfirmButtonClicked = "configure.billing.flow.confirm.button.clicked",
  //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // CARD OWNERSHIP TRANSFER
  // Start of CARD OWNERSHIP TRANSFER related events
  CardOwnershipTransferStarted = "card_ownership_transfer.started",
  CardOwnershipTransferStepViewed = "card_ownership_transfer.step.viewed",
  CardOwnershipTransferCompleted = "card_ownership_transfer.completed",
  CardOwnershipTransferExit = "card_ownership_transfer.exit",
  CardOwnershipTransferError = "card_ownership_transfer.error",
  CardOwnershipTransferErrorRetry = "card_ownership_transfer.error_retry",
  CardOwnershipTransferOnlineMerchantPaginationClicked = "card_ownership_transfer.review_online_merchant_pagination.clicked",
  CardOwnershipTransferSelectNewOwnerClicked = "card_ownership_transfer.select_new_owner.clicked",
  CardOwnershipTransferSelectBudgetClicked = "card_ownership_transfer.select_budget.clicked",
  // End of CARD OWNERSHIP TRANSFER related events
  // Dashboard Search
  DashboardSearchResults = "dashboard_search.results",
  DashboardSearchSelectResult = "dashboard_search.select_result",
  DashboardSearchOpened = "dashboard_search.opened",
  DashboardSearchClosed = "dashboard_search.closed",
  // End of Dashboard Search
  // Brex Assistant
  // Start of Brex Assistant Settings related events
  BrexAssistantComplianceSettingsWaitlistJoinClicked = "assistant.settings.waitlist.join.button.clicked",
  BrexAssistantComplianceSettingsWaitlistJoinFromUrl = "assistant.settings.waitlist.join_from_url.clicked",
  BrexAssistantComplianceSettingsWaitlistJoinError = "assistant.settings.waitlist.join.error",
  BrexAssistantGoogleCalendarOAuthError = "assistant.settings.calendar_connect.oauth.error",
  BrexAssistantGoogleCalendarIntegrationCreateSuccess = "assistant.settings.calendar.integration.create.success",
  // END of Brex Assistant Settings related events
  // Start of Brex Assistant Window related events
  BrexAssistantDetailsPaneMounted = "assistant.details_pane.mounted",
  BrexAssistantDetailsPaneError = "assistant.details_pane.error",
  BrexAssistantDetailsPaneCloseClick = "assistant.details_pane.close.clicked",
  BrexAssistantDetailsPaneSettingsClick = "assistant.details_pane.settings.clicked",
  BrexAssistantDetailsPaneFTUXMounted = "assistant.details_pane.ftux.mounted",
  BrexAssistantDetailsPaneFTUXContinueClick = "assistant.details_pane.ftux.continue.clicked",
  BrexAssistantDetailsPaneFTUXConnectGoogleCalendarClick = "assistant.details_pane.ftux.connect_google_calendar.clicked",
  BrexAssistantDetailsPaneFTUXEnableAfterCalendarConnectedClick = "assistant.details_pane.ftux.enable_after_calendar_connected.clicked",
  BrexAssistantDetailsPaneFTUXUpdateSettingsStart = "assistant.details_pane.ftux.update_user_settings.start",
  BrexAssistantDetailsPaneFTUXUpdateSettingsError = "assistant.details_pane.ftux.update_user_settings.error",
  BrexAssistantDetailsPaneFTUXComplete = "assistant.details_pane.ftux.complete",
  BrexAssistantActivitiesUnseenCardMounted = "assistant.activities.unseen.card.mounted",
  BrexAssistantActivitiesSeenCardMounted = "assistant.activities.seen.card.mounted",
  BrexAssistantActivitiesCardClick = "assistant.activities.card.clicked",
  BrexAssistantActivitiesCardTaskFieldClick = "assistant.activities.card.task.clicked",
  // Assistant Details Pane Menu
  BrexAssistantDetailsPaneMenuClick = "assistant.details_pane.menu.click",
  BrexAssistantDetailsPaneMenuItemClick = "assistant.details_pane.menu.item.click",
  // END of Brex Assistant Window related events
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // // VENDORS for BILL PAY AND PROCUREMENT
  // // Start of Vendors
  VendorsClickSendBill = "vendors.click.send_bill",
  VendorsTableMounted = "vendors.table.mounted",
  VendorsFtuxAddInvoicesClicked = "vendors.add_invoices.clicked",
  VendorsFtuxAddVendorClicked = "vendors.add_vendor.clicked",
  VendorsFtuxImportFromCsvClicked = "vendors.import_from_csv.clicked",
  // // End of Vendors
  // // VENDORS for BILL PAY AND PROCUREMENT
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\

  // END Vendor event

  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // // PLANNING SETUP
  // // Start of PLANNING SETUP related instrumentation
  PlanningSetupHelperMounted = "planning_setup.helper.mounted",
  PlanningSetupHelperStepMounted = "planning_setup.helper_step.mounted",
  PlanningSetupConnectErpStepRefreshDataClick = "planning_setup.connect_erp_step.refresh_data.click",
  PlanningSetupConnectErpStepFetchErpData = "planning_setup.connect_erp_step.fetch_erp_data",
  PlanningSetupAccountSelectionStepInitialSuggestion = "planning_setup.account_selection_step.initial_suggestion",
  PlanningSetupBudgetSuggestionsStepInitialSuggestion = "planning_setup.budget_suggestions_step.initial_suggestion",
  PlanningSetupHelperBudgetsCreated = "planning_setup.helper.budgets_created",
  // // End of PLANNING SETUP related instrumentation
  // // PLANNING SETUP
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\

  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // // EXPORT CONTROLLER
  // // Start of EXPORT CONTROLLER related instrumentation
  ExportDownloadClick = "export.download.click",
  // // End of EXPORT CONTROLLER related instrumentation
  // // EXPORT CONTROLLER
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\

  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // // USER LIMIT FORM
  // // Start of USER LIMIT FORM related instrumentation
  UserLimitFormReviewPeriodChangeAlertMounted = "user-limit-form.review-period-change.alert.mounted",
  UserLimitFormAdvancedControlsClicked = "user-limit-form.advanced-controls.clicked",
  UserLimitFormShowPolicyClicked = "user-limit-form.edit-policy.clicked",
  // // End of USER LIMIT FORM related instrumentation
  // // USER LIMIT FORM
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\

  // BEGIN Global keyboard shortcuts
  GlobalKeyboardShortcutPressed = "dashboard.global-keyboard-shortcut-pressed",
  // END Global keyboard shortcuts

  // BEGIN Spend limit details L2
  SpendLimitDetailsL2Mounted = "spend_limit.details_l2.mounted",
  SpendLimitDetailsL2QueryError = "spend_limit.details_l2.query.error",
  SpendLimitDetailsL2QueryRetryClicked = "spend_limit.details_l2.query.retry.clicked",
  SpendLimitDetailsL2FragmentError = "spend_limit.details_l2.fragment.error",

  SpendLimitDetailsL2HeaderMounted = "spend_limit.details_l2.header.mounted",
  SpendLimitDetailsL2HeaderMountedError = "spend_limit.details_l2.header.mounted.error",
  SpendLimitDetailsL2HeaderActionsMounted = "spend_limit.details_l2.header.actions.mounted",
  SpendLimitDetailsL2HeaderActionClicked = "spend_limit.details_l2.header.action.clicked",

  SpendLimitDetailsL2DataGridMounted = "spend_limit.details_l2.datagrid.mounted",
  SpendLimitDetailsL2DataGridMountedError = "spend_limit.details_l2.datagrid.mounted.error",
  SpendLimitDetailsL2DataGridRowSelected = "spend_limit.details_l2.datagrid.row.selected",

  SpendLimitDetailsL2DetailsPaneMounted = "spend_limit.details_l2.details_pane.mounted",
  SpendLimitDetailsL2DetailsPaneMountedError = "spend_limit.details_l2.details_pane.mounted.error",
  SpendLimitDetailsL2DetailsPaneActionsMounted = "spend_limit.details_l2.details_pane.actions.mounted",
  SpendLimitDetailsL2DetailsPaneActionClicked = "spend_limit.details_l2.details_pane.action.clicked",
  // END Spend limit details L2

  // Begin Spend limit action pane
  SpendLimitActionPaneMounted = "spend_limit.action_pane.mounted",
  SpendLimitActionPaneMountedError = "spend_limit.action_pane.mounted.error",
  // END spend limit action pane

  // BEGIN Spend limit request pane
  SpendLimitRequestPaneMounted = "spend_limit.request_pane.mounted",
  SpendLimitRequestPaneLimitIncreaseRequestQueryError = "spend_limit.request_pane.limit_increase_request_query.error",
  SpendLimitRequestPaneRequestTypesQueryError = "spend_limit.request_pane.request_types_query.error",
  SpendLimitRequestPaneLimitIncreaseRequestQueryRetryClicked = "spend_limit.request_pane.limit_increase_request_query.retry.clicked",
  SpendLimitRequestPaneRequestTypesQueryRetryClicked = "spend_limit.request_pane.request_types_query.retry.clicked",
  SpendLimitRequestPaneRequestIncreaseClicked = "spend_limit.request_pane.request_increase.clicked",
  SpendLimitRequestPaneCreateNewLimitClicked = "spend_limit.request_pane.create_new_limit.clicked",
  // END Spend limit request pane

  // BEGIN Request types management pane
  RequestTypesManagementPaneMounted = "request_types_management_pane.mounted",
  RequestTypesManagementPaneTabsChanged = "request_types_management_pane.tabs.clicked",
  RequestTypesManagementPaneRequestTypesQueryError = "request_types_management_pane.request_types_query.error",
  RequestTypesManagementPaneRequestTypesQueryRetryClicked = "request_types_management_pane.request_types_query.retry.clicked",
  RequestTypesManagementPaneRequestTypesReordered = "request_types_management_pane.request_types.reordered",
  RequestTypesManagementPaneCtaClicked = "request_types_management_pane.cta.clicked",
  RequestTypesManagementPaneDialogCtaClicked = "request_types_management_pane.dialog.cta.clicked",
  // END Request types management pane

  // BEGIN Request types form
  RequestTypesFormStepMounted = "request_types_form.step.mounted",
  RequestTypesFormCtaClicked = "request_types_form.cta.clicked",
  RequestTypesFormOverlayMounted = "request_types_form.overlay.mounted",
  RequestTypesFormQueryFailure = "request_types_form.query.failure",
  RequestTypesFormMutationCompleted = "request_types_form.mutation.completed",
  // END Request types form

  // BEGIN Policy management
  PolicyManagementPaneMounted = "policy_management_pane.mounted",
  PolicyManagementPanePoliciesQueryFailure = "policy_management_pane.policies_query.failure",
  PolicyManagementPanePoliciesQueryRetryClicked = "policy_management_pane.policies_query.retry.clicked",
  PolicyManagementPaneTabsChanged = "policy_management_pane.tabs.changed",
  PolicyManagementPanePolicyDetailsClicked = "policy_management_pane.policy_details.clicked",
  PolicyManagementPaneCreateClicked = "policy_management_pane.create.clicked",
  // END Policy management

  // BEGIN Policy selectors
  ExpensePolicySelectorMounted = "expense_policy_selector.mounted",
  ExpensePolicySelectorPoliciesQueryFailure = "expense_policy_selector.policies_query.failure",
  ExpensePolicySelectorPolicyOptionClicked = "expense_policy_selector.policy_option.clicked",
  ExpensePolicySelectorNewPolicyClicked = "expense_policy_selector.new_policy.clicked",
  LimitIncreaseRequestPolicySelectorMounted = "limit_increase_request_policy_selector.mounted",
  LimitIncreaseRequestPolicySelectorPoliciesQueryFailure = "limit_increase_request_policy_selector.policies_query.failure",
  LimitIncreaseRequestPolicySelectorPolicyOptionClicked = "limit_increase_request_policy_selector.policy_option.clicked",
  LimitIncreaseRequestPolicySelectorEditPolicyClicked = "limit_increase_request_policy_selector.edit_policy.clicked",
  LimitIncreaseRequestPolicySelectorNewPolicyClicked = "limit_increase_request_policy_selector.new_policy.clicked",
  // END Policy selectors

  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
  // // SEARCH
  // // Start of SEARCH related instrumentation
  // Scoped Search
  ScopedSearchResultSelected = "scoped_search.result.selected",
  TableRowClickedForScopedSearch = "scoped_search.table_row.clicked",

  // Global Search
  GlobalSearchResultSelected = "global_search.result.selected",
  GlobalSearchIntentTypeSelected = "global_search.intent_type.selected",
  // END SEARCH
  // //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
}

export enum PrimitivesAnalyticsMetadata {
  ReportingDashboard = "reporting.dashboard",
  DataGridMetadata = "datagrid.metadata",
}

type EmptyPayload = {};

type ReportingBasePayload = BaseDashboardNav;

export type DataGridAnalyticsPayload = {
  instantiationId: string | null;
  /**
   * As part of #inc-4295, we discovered we can't pass the `activeRowId` as part
   * of the payload. This value changes too often, causing a lot of unnecessary
   * re-renders in the data grid, decreasing the overall page performance.
   */
  // selectedCount: number;
  // activeRowId: string | null;
  hasFiltersApplied: boolean;
  rowCount: number | null;
  isGrouping: boolean;
  groupBy: GroupByFieldKey | null;
  sorts: Record<string, SortingDirection> | null;
  visibleColumns: string[];
  filters: string[];
  persistedView: {
    id: string | null;
    label: string | null;
  };
  layout: {
    density: DataGridDensity;
  };
  pagination: {
    paginationStyle: string;
    hasMoreEntityRows: boolean | null;
    pageSize: number | null;
    currentPage: number | null;
    totalPages: number | null;
  };
};

/** All the slices of state you can save with deep linking in DataGrid */
export enum DataGridDeepLinkingParamType {
  FILTER = "FILTER",
  GROUP_BY = "GROUP_BY",
  PERSISTED_VIEW = "PERSISTED_VIEW",
  SORT = "SORT",
}

type ReportingReportShareReportPayload = ReportingBasePayload & {
  shareAction: ShareAction | null;
};

type ReportingReportFiltersBasePayload = {
  dashboard: BaseDashboardNav;
  filterKey: keyof ExpenseFilterInput;
};

type ReportingReportFiltersFilterValueSelectedPayload =
  ReportingReportFiltersBasePayload & {
    value: string;
  };

type ReportingReportLayoutChangedPayload = ReportingBasePayload & {
  prevLayout: Card[];
  newLayout: Card[];
};

type ReportingWidgetEditorBasePayload = {
  dashboard: BaseDashboardNav;
  widget?: WidgetForm;
  widgetForm?: WidgetEditorForm;
};

type ReportingWidgetEditorVizTypeSelectedPayload =
  ReportingWidgetEditorBasePayload & {
    newVizType: string;
  };

type ReportingWidgetBasePayload = {
  dashboard: BaseDashboardNav;
  widget: Card;
};

type ReportingWidgetResultPayload = {
  dashboard: BaseDashboardNav;
  widget: Card;
  hasData: boolean;
};

type ReportingDefaultStockReportSelectedPayload = {
  stockReportType: StockReportType;
};

type AccountingCardPayload = {
  cardName?: string;
};

type MetalTablePayload = {
  isMetalTable?: boolean;
};

type Is1BrexRearchitecturePayload = {
  is1BrexRearch?: boolean;
};

type IsDatagridPayload = {
  isDatagrid?: boolean;
};

type AccountsRowItemClickedPayload = {
  accountId: string;
};

type AccountManageClickedPayload = {
  sourceId: string;
};

type TimezoneUpdatedPayload = {
  accountId: string;
  userId: string;
};

type CashTabChangedPayload = {
  tab: string;
};

type CreditOverviewEducationTileTotalCashBalanceStatementDuePayload = {
  daysToDueDate: number;
};

type CreditOverviewNotificationTileInsufficientFundsClickedPayload = {
  daysToDueDate: number;
  isBrexCash: boolean;
};

type CreditOverviewNotificationTileLimitUtilizationClickedPayLoad = {
  limitUtilizationStatus: string;
};

type CreditMakePaymentButtonClickedPayLoad = {
  origin?: string;
};

type CreditTabChangedPayload = {
  tab: string;
};

type ExpensesOriginPayload = {
  filters?: ExpenseFilterInput;
  origin: ExpensesTableOrigin | string | undefined;
  isScopedSearch?: boolean;
};

type ExpensesTableSearchCTAPayload = ExpensesOriginPayload & {
  filters?: ExpenseFilterInput;
};

type ExpensesMetadataPayload = {
  metadata: Record<string, any> | undefined;
};

type FilterPayload = Is1BrexRearchitecturePayload &
  MetalTablePayload & {
    selectedOptions: string[];
  };

type OptionInputPayload = {
  fieldId?: string;
  inputType: string;
  action: string;
  ruleType: RuleType;
};

type BudgetOptionInputType = OptionInputPayload & {
  hasParentApplyNestedSelected: boolean;
};

type CategoryFieldPayload = {
  ruleType: string;
};

type ExpensesAllExportPayload = ExpensesOriginPayload & ExpensesMetadataPayload;

type ExpensesFilterPayload = FilterPayload &
  ExpensesOriginPayload &
  ExpensesMetadataPayload;

type DeadlineUpsellPayload = {
  upsellType: UpsellType | undefined;
};

type ExpensesDateFilterPayload = ExpensesFilterPayload & {
  dateType?: string;
};

type SearchByPayload = Is1BrexRearchitecturePayload &
  MetalTablePayload & {
    searchField: string;
  };

type ExpensesSearchByPayload = SearchByPayload &
  ExpensesOriginPayload &
  ExpensesMetadataPayload;

type SortPayload = {
  sortBy: string;
  direction: "asc" | "desc";
  origin: ExpensesTableOrigin;
  expenseType?: ExpenseType;
};

type GroupingBasePayload = {
  groupBy: string;
};

type LegacyExpenseGroupingPayload = GroupingBasePayload & ExpensesOriginPayload;

type LegacyExpenseGroupingSelectionPayload = {
  expenseCount: number;
  groupCount: number;
} & LegacyExpenseGroupingPayload;

type GroupingTitleActionPayload = {
  groupId: string;
} & GroupingBasePayload;

type ExpensesTableSeeMorePayload = {
  groupBy?: string;
} & ExpensesOriginPayload;

type LoadMoreExpensesForGroupPayload = {
  groupId: string;
} & ExpensesTableSeeMorePayload;

type UseRestrictedFiltersPayload = {
  filterIds?: string[];
  groupByFieldKey?: string;
  persistedViewId?: string;
};

type DataGridFiltersPayload = {
  filterId?: string;
  filterType?: string;
};

type DataGridSelectionPayload = {
  rowId?: string;
  groupId?: string;
  value?: string;
};

type DataGridBulkActionPayload = {
  bulkActionId: string;
};

type DataGridRowActionPayload = {
  rowActionId: string;
};

export type DataGridColumnSettingsPayload = {
  columnKey: string;
  nextState?: boolean;
  oldContainer?: string | null;
  newContainer?: string;
};

type DataGridSortPayload = {
  columnKey: string;
  nextDirection?: SortingDirection;
};

type DataGridPageSizePayload = {
  size: number;
};

type DataGridViewPayload = {
  viewId: string;
};

type DataGridLayoutPayload = {
  density: DataGridDensity;
  showDividers: boolean;
};

type DataGridCellPayload = {
  columnName: string;
};

type DataGridUploadButtonCellPayload = DataGridCellPayload & {
  uploadButtonState: "UPLOAD" | "UPLOADED";
};

type BudgetProgramsSortPayload = {
  sortBy: string;
  direction?: "asc" | "desc";
};

type BudgetProgramDetailPanePayload = {
  budgetProgramId: string;
  budgetBlueprintIndex?: number;
  budgetId?: string;
};

type CheckboxClickedPayload = {
  isChecked: boolean;
};

type OnboardingCompanyPolicyAgreementViewedPayload = {
  origin: CompanyPolicyAgreementOrigin;
};

type OnboardingCompanyPolicyAgreementErroredPayload = {
  reason: CompanyPolicyAgreementError;
};

type OnboardingExperienceSubStepViewedPayload = {
  step: OnboardingExperienceSubStep;
};

type OnboardingExperienceDirectReportsLoadedPayload = {
  count: number;
};

type OnboardingExperienceMachineContextPayload = {
  context: OnboardingMachineContext;
};

type OnboardingExperienceMachineStartedPayload =
  OnboardingExperienceMachineContextPayload;

type OnboardingExperienceMachineCompletedPayload =
  OnboardingExperienceMachineContextPayload;

type OnboardingExperienceMachineStatePayload =
  OnboardingExperienceMachineContextPayload & {
    state: string;
  };

type OnboardingExperienceMachineStateViewedPayload = {
  state: OnboardingExperienceV2Step;
};

type CardOwnershipTransferStartedPayload = {
  cardId: string;
  origin?: CardOwnershipTransferOrigin;
};

type CardOwnershipTransferStepViewedPayload =
  CardOwnershipTransferStartedPayload & {
    step: string;
    eventType?: string;
    activeBudgetCount?: number;
    cardBudgetOwnershipType: CardBudgetOwnershipType;
  };

type CardOwnershipTransferStepCompletedPayload =
  CardOwnershipTransferStartedPayload & {
    oldOwnerId: string;
    newOwnerId: string;
  };

type CardOwnershipTransferErrorPayload = CardOwnershipTransferStartedPayload & {
  errorType:
    | "card_eligibility"
    | "fetching_card_info"
    | "role_permission"
    | "transferring_card";
  cardEligibilityErrorType?: CardEligibilityType;
};

type CardOwnershipTransferOnlineMerchantPaginationPayload = {
  page: number;
  totalMerchants: number;
};

type ProductSelectionEventPayload = {
  location: string;
  product?: ProductOffered;
  blueprint?: string;
  action?: "start" | "dismiss" | "cancel" | "external" | "delete";
};

type ExpensesInboxLinkToAllExpensesClickedPayload = {
  type: "reimbursements" | "card_expenses" | "missing_info" | "bills";
  count: number;
};

type ExpensesTableRowClickedPayload = {
  action: "open" | "close";
  expenseId: string;
  origin: ExpensesTableOrigin;
  isFlaggedExpense: boolean;
};

type ExpensesTableBulkActionAttemptedPayload = {
  action: ActionType;
  expenseType: ExpenseType | null;
  expenseCount: number;
  isOverride: boolean;
  groupCount?: number;
  groupIds?: string[];
} & ExpensesOriginPayload;

type ExpensesTableCheckboxSelectedPayload = {
  hasSelectedAll: boolean;
  hasSelectedExpense: boolean;
  hasSelectedGroup: boolean;
} & ExpensesOriginPayload;

type ExpensesDrilldownPayload = {
  drillBy: string[];
};

export type ExpenseBudgetSelectionPayload = {
  requestStatus: "failure" | "success" | "attempt";
  location: "expense_details" | "expense_table" | "accounting.journal";
  expenseId: string;
  currentBudgetId: string;
  targetBudgetId?: string;
  failureIndicator?: string;
  error?: BudgetAuthorizationErrorType;
  budgetStatus?: BudgetStatus;
  origin?: ExpensesTableOrigin;
};

type ExpenseCategoriesPayload = {
  expenseId: string | null;
  fieldId?: string;
};

type ExpenseDetailsPanePayload = Is1BrexRearchitecturePayload &
  MetalTablePayload & {
    expenseId: string;
    expenseType?: ExpenseType;
    isFromAccountingJournal?: boolean;
    isV2?: boolean;
    secondaryPaneIsOpen?: boolean;
  };

type ExpenseDetailsPaneShowReceiptPaneButtonClickedPayload = {
  expenseId: string;
  toggle: "open" | "close";
  isExpenseOwner: boolean;
  isManagerOfExpenseOwner?: boolean;
  isAdmin?: boolean;
};

type RowCyclingPayload = {
  action: "next" | "previous" | "close";
  isKeyboardShortcut?: boolean;
  origin?: string;
};

type ExpenseDetailsPaneLoaded = {
  expenseId: string;
  isCurrentReviewer?: boolean;
  canOverrideApprove?: boolean;
  isOwnExpense?: boolean;
  expenseType: ExpenseType;
  origin: string;
};

type ExpenseDetailsChangeActionType = "budget";

export type ExpenseDetailsChangeAction = {
  actionType: ExpenseDetailsChangeActionType;
  additionalInformation: Record<string, any>;
};

type ExpenseDetailsPaneEditsSavedPayload = {
  expenseId: string;
  editedFields: string[];
  status?: ExpenseStatus | null;
  changeActions?: ExpenseDetailsChangeAction[];
  isAccountingJournal?: boolean;
  isV2?: boolean;
};

type ExpenseDetailsPaneEditsSaveRequestPayload =
  ExpenseDetailsPaneEditsSavedPayload & {
    type: "failure" | "success" | "attempt";
  };

type ExpenseDetailsPaneChangeAlertPayload = {
  expenseId: string;
  status: ExpenseStatus | null;
};

export type ExpenseBudgetSelectionErrorModalPayload = {
  error:
    | "insufficient limit"
    | "insufficient limit - ancestor"
    | "date conflict"
    | "status"
    | "not yet started"
    | "expired"
    | "over transaction limit"
    | "unknown";
  budgetId?: string;
  expenseId: string;
};

export type ExpenseBudgetSelectionInsufficientLimitErrorModalPayload =
  ExpenseBudgetSelectionErrorModalPayload & {
    ancestorBudgetId?: string;
    periodType: BudgetPeriodType;
    isWithinCurrentPeriod: boolean;
    limitIncreaseRequestEnabled: boolean;
    hasPendingLimitIncreaseRequest: boolean;
  };

export type ExpenseBudgetSelectionTransactionLimitErrorModalPayload =
  ExpenseBudgetSelectionErrorModalPayload & {
    transactionLimitAmount: string;
  };
export type ExpenseBudgetSelectionDateConflictErrorModalPayload =
  ExpenseBudgetSelectionErrorModalPayload & {
    conflictOccurred: "before" | "after" | "unknown";
  };

type ExpenseBudgetSelectionAddUserToBudgetPayload = {
  expenseId: string;
  budgetId: string;
  userId: string;
};

type ReimbursementModalPayload = {
  reimbursementItemId: string | null;
};

type ExpenseDetailsPaneMarkedCardExpenseAsReviewedPayload = {
  expenseId: string;
  isOverride: boolean;
};

type ExpenseDetailsPaneReimbursementRequestActionedPayload = {
  expenseId: string;
  action: "approve" | "deny";
  isOverride: boolean;
};

type ExpenseDetailsRequestActionSuccessPayload = {
  expenseId: string;
  expenseType: ExpenseType;
};

type ExpenseIdPayload = {
  expenseId: string;
};

type ExpenseDetailsDocumentationRequirementTooltipPayload = {
  expenseId: string;
  requestActionType?: RequestActionType;
  extendedFieldId?: string;
};

type ExpenseDetailsPanePaymentFailureReasonBannerClickedPayload = {
  expenseId: string;
};

type ExpenseDetailsPaneItemizeExpensePayload = {
  expenseId: string;
};

type ExpenseDetailsPaneItemizeExpenseClickedPayload = {
  expenseId: string;
  userRole: PrimitiveUserRole | null | undefined;
  isManagerOfExpense: boolean;
};

type ExpenseDetailsPaneItemizeExpenseSaveClickedPayload = {
  expenseId: string;
  itemizedExpenseCount: number;
};

type ExpenseDetailsPaneCategorizationRuleResolutionPayload = {
  expenseId: string;
  appliedRuleId?: string;
};

type DeclinedExpenseAction =
  | "edit_budget"
  | "switch_budget"
  | "unlock_card"
  | "request_budget_limit_increase"
  | "request_budget_limit_increase_auto_budget"
  | "view_pending_limit_increase"
  | "request_budget"
  | "view_pending_budget_request"
  | "view_policy"
  | "reopen_budget";
type DeclinedExpenseSuggestionPayload = {
  action: DeclinedExpenseAction;
};

export type UploadFromMobilePayload = {
  expenseId: string;
  numReceipts: number;
};

type UploadFromMobileIntroPayload = {
  expenseId: string;
  hasSeen: boolean;
};

type UploadFromMobileNotificationPayload = {
  expenseId: string;
  result: "success" | "error";
  error?: string;
};

type EmployeeRepaymentPayload = {
  expenseId: string;
  action: RepaymentActionTypes;
  previousAction?: RepaymentActionTypes;
  error?: string;
  repaymentExpenseId?: string | null;
  repaymentExpenseType: ExpenseType | null;
};

type TeamTableRowClickedPayload = {
  id: string;
};

type TeamTableRowActionClickedPayload = {
  action: string;
  id: string;
};

type TeamInviteUserSendInviteClickedPayload = {
  role: UserRole;
  hasSetLocation: boolean;
  hasSetDepartment: boolean;
  hasSetManager: boolean;
  hasSetCostCenter: boolean;
  hasSetLegalEntity: boolean;
  allowCorporateCard: boolean;
  productAccess?: UserProductAccess[];
};

type TeamInviteFiltersAppliedPayload = {
  filterKey: EmployeesFilterKeys;
};

type TeamUsersFiltersAppliedPayload = {
  filterName: string;
};

type TeamUsersTableColumnsVisiblePayload = {
  visibleColumns: string[];
};

type TeamUsersTableMissingUserLimitClickedPayload = {
  userId: string;
};

type TeamInviteSubmitPayload = {
  role: UserRoleTypeInput;
  isReimbursementOnly: boolean;
  numberInvited: number;
  bulkInviteFromTeamsPage: boolean;
  appliedFilters?: EmployeesFilterValues;
  productAccess?: UserProductAccess[];
};

type TeamBulkActionPayload = {
  numberSelected: number;
};

type TeamAutoInviteDeletePayload = {
  id: string;
};

type TeamAutoInviteStatusPayload = {
  id: string;
  active: boolean;
};

type TeamAutoInviteStepMountedPayload = {
  flow: "create" | "edit";
};

type TeamUserCardAccessUpdatePayload = {
  accessToGrant: UserProductAccess;
  accessToRemove: UserProductAccess;
};

type TeamUserGroupStepMountedPayload = {
  flow: "create" | "edit";
};

type TeamMfaResetSubmittedPayload = {
  targetUserId: string;
};

type TeamGroupDeleteClickedPayload = {
  groupId: string;
};

type GroupField = "displayName" | "memberCondition" | "members";

type TeamGroupUpdatePayload = Record<GroupField, boolean>;

type TeamUserAttributesFilterPayload = {
  filter: PrimitiveType;
};

type TeamBulkRoleChangeSubmitPayload = {
  role: UserRole;
  numberSelected: number;
  productAccess?: UserProductAccess[];
};

type TeamBulkTravelChangeSubmitPayload = {
  numberSelected: number;
};

type TeamUserInviteResentPayload = {
  userId: string;
};

type UserIdPayload = {
  userId: string;
};

type BudgetIdPayload = {
  budgetId?: string;
};

type BudgetsOverviewTabChangedPayload = {
  tab: string;
};

type BudgetsOverviewBudgetClickedPayload = {
  tab: string;
  budgetId: string;
};

type BudgetDrilldownTabChangedPayload = {
  tab: string;
  budgetId: string;
};

type BudgetDrilldownViewPolicyClickedPayload = {
  budgetId: string;
  policyId: string;
};

type BudgetDrilldownTransactionDetailsClickedPayload = {
  budgetId: string;
  expenseId: string;
};

/**
 * Budgets
 * Please check go/budgets-events for guidelines
 */

export type BudgetPolicyPayload = {
  policyId?: string;
  budgetId?: string;
  isParent?: boolean;
};

export type BudgetPayload = {
  budgetId?: string;
  budgetType?: BudgetType;
  budgetTemplateId?: string;
  policyId?: string;
  isAutoTransferVirtualCardEnabled?: boolean;
};

export type BudgetSavePayload = BudgetPayload & {
  includesDistribution?: boolean;
  hasStructureSuggestion?: boolean;
  editedFields?: boolean;
  isLimitGroup?: boolean;
};

export type BudgetFormValidationErrorsPayload = BudgetPayload & {
  errors: Record<string, string>;
};

export type BudgetFormPayload = BudgetPayload & {
  formFlow: "create" | "edit";
  formStep: BudgetFormStep | SpendLimitFormStep;
  isFromWalletCreatingCard?: boolean;
  origin?: string;
};

export type BudgetFormErrorPayload = BudgetFormPayload & {
  error?: ApolloError;
  query?: string;
  mutation?: string;
  hasData?: boolean;
  isLimitGroup?: boolean;
};

export type BudgetFormOverlayPayload = BudgetFormPayload & {
  overlay:
    | "globalMccModal"
    | "unsavedChangesModal"
    | "advancedControlsPane"
    | "reviewPeriodChangeModal";
};

export type BudgetFormClickedPayload = BudgetFormPayload & {
  group?: "navigation" | "unsavedChanges";
  action:
    | "backButton"
    | "primaryButton"
    | "viewPolicy"
    | "advancedControls"
    | "exitWithoutSaving"
    | "exitNoChanges"
    | "goBack";
};

export type BudgetFormToggledPayload = BudgetFormPayload & {
  action: "provideTripDetailsToggled" | "createVirtualCardToggled";
  toggledValue?: boolean;
};

export type BudgetFormUserLimitPayload = {
  formFlow: "create" | "edit";
  isOriginatedFromTeam?: boolean;
  isBifurcated?: boolean;
};

export type BudgetRequestPayload = {
  budgetRequestId?: string;
  budgetTemplateId?: string;
  policyId?: string;
};

export type BudgetRequestFormPayload = BudgetRequestPayload & {
  formFlow?: "create" | "edit" | "approve" | "adminApprove";
  formStep?: BudgetRequestFormStep;
};

export type BudgetRequestFormViewPolicyPayload = PolicyDetailsDocumentPayload &
  BudgetRequestFormPayload;

export type BudgetRequestFormErrorPayload = BudgetRequestFormPayload & {
  error?: ApolloError | string;
  query?: string;
  mutation?: string;
};

export type BudgetRequestDetailsPaneErrorPayload = BudgetRequestPayload & {
  error?: ApolloError | string;
  query?: string;
  mutation?: string;
};

export type BudgetTemplatePayload = {
  budgetTemplateId?: string;
  policyId?: string;
};

export type BudgetAttributesFormPayload = {
  state: BudgetAttributesStates;
  budgetEntityId: string;
  budgetEntityType: BudgetEntityType;
  budgetTemplateType?: FormTemplateType;
  budgetTemplateId?: string;
  formFlowType: FormFlowType;
};

export type BifurcatedBudgetsEducationTutorialPayload = {
  tutorialId?: string;
  step?: number;
};

type BudgetAttributesFormErrorPayload = {
  state: BudgetAttributesStates;
  budgetEntityId: string;
  budgetEntityType: BudgetEntityType;
  formFlowType: FormFlowType;
  budgetTemplateType?: FormTemplateType;
  budgetTemplateId?: string;
  error: "BudgetEntityQuery" | "BudgetTemplateOptionsQuery";
};

type BudgetAttributesFormActionButtonPayload = {
  state: BudgetAttributesStates;
  budgetEntityId: string;
  budgetEntityType: BudgetEntityType;
  formFlowType: FormFlowType;
  budgetTemplateType?: FormTemplateType;
  budgetTemplateId?: string;
  buttonText: string;
};

type BudgetAttributesFormViewPolicyTogglePayload = {
  state: BudgetAttributesStates;
  budgetEntityId: string;
  budgetEntityType: BudgetEntityType;
  formFlowType: FormFlowType;
  budgetTemplateType?: FormTemplateType;
  budgetTemplateId?: string;
  toggled: "on" | "off";
};

type BudgetAuditTrailSectionTogglePayload = {
  budgetId: string;
  toggled: "open" | "close";
};

type BudgetCreateFlowPayload = {
  parentBudgetId?: string;
};

type BudgetCreateFlowDoneClickedPayload = {
  parentBudgetId?: string;
  budgetId?: string;
};

type BudgetsFirstTimeCreationChoicePayload = {
  budgetFlowType: "limits" | "budgets";
};

type BudgetEditFlowPayload = {
  budgetId?: string;
  includesInactive?: boolean;
};

type BudgetCreateFlowViewPolicyPayload = {
  policyId: string;
  parentBudgetId: string;
};

type BudgetEditFlowViewPolicyPayload = {
  policyId: string;
  budgetId?: string;
};

type BudgetParentBudgetPayload = {
  budgetId: string;
  parentBudgetId: string;
};

type BudgetAllocatedCalculationPayload = {
  budgetId: string;
  isAllocationBreakdownTable: boolean;
};

type BudgetAllocatedCalculationPagePayload = {
  budgetId: string;
  error?: any;
};

type BudgetBulkManagementBulkActionMenuPayload = {
  origin: BudgetsTableOrigin;
  selectedRowCount: number;
};

type BudgetBulkManagementBulkActionPayload =
  BudgetBulkManagementBulkActionMenuPayload & {
    action: string;
    impactedRowCount?: number;
  };

type BudgetRequestApproveDenyErrorPayload = {
  error: string;
  type: "expected" | "unexpected" | "unknown";
  mutation: "approve" | "deny";
  origin: "detailsPane" | "inbox" | "form" | "unknown";
  isOverride: true | false | "unknown";
  budgetRequestId: string;
};

type BudgetRecurrencePayload = {
  budgetId?: string;
  recurrence: BudgetPeriodType;
};

type BudgetDrilldownNestedBudgetClickedPayload = {
  budgetId: string;
  parentBudgetId: string;
};

type BudgetDrilldownBudgetMemberClickedPayload = {
  budgetId: string;
  memberId: string;
};

type BudgetDrilldownBudgetOwnerClickedPayload = {
  budgetId: string;
  ownerId: string;
};

type BudgetsSortByPayload = {
  field: string;
  direction: "ASCENDING" | "DESCENDING";
};

type BudgetsSearchFilterV2ChangedPayload = {
  filterKey: string;
  newValue?: string | string[];
};

type ExpensesExportAsyncPayload = {
  isAsyncExport: boolean;
};

type ExpensesExportDownloadClickedPayload = ExpensesExportAsyncPayload & {
  legacyFilters?: EntityFilterInput;
  entityFilters?: ExpenseFilterInput;
  reportType: ReportFormatType;
  numExpenses: number;
};

type ExpensesExportCreateReportSuccessDownloadRetryClickedPayload =
  ExpensesExportAsyncPayload & {
    legacyFilters?: EntityFilterInput;
    entityFilters?: ExpenseFilterInput;
    reportType: ReportFormatType;
    numExpenses: number;
    reportOptions?: ReportOption[];
  };

type ExpensesExportCreateReportFailDownloadRetryClickedPayload =
  ExpensesExportAsyncPayload & {
    legacyFilters?: EntityFilterInput;
    entityFilters?: ExpenseFilterInput;
    reportType: ReportFormatType;
    numExpenses: number;
  };

type SmartFilteringPayload = {
  keys: string[];
};

type ReimbursementsBankErrorPayload = {
  verificationStatus?: VerificationStatus;
  isFxRateError?: boolean;
};

type ReimbursementsOcrCancelPayload = {
  durationMs: number;
  isBulkOcr?: boolean;
};

type ReimbursementsOcrErrorPayload = {
  error?: Error;
  isTimeOut?: boolean;
  durationMs: number;
  receiptId?: string;
  isBulkOcr?: boolean;
  fileContentType?: string | null;
};

type ReimbursementsOcrSuccessPayload = {
  hasDateResult: boolean;
  hasAmountResult: boolean;
  hasMerchantResult: boolean;
  hasMerchantCategoryResult: boolean;
  accountCurrencyCode?: string;
  ocrCurrencyCode?: string;
  durationMs: number;
  expenseIdEncoded?: string;
  receiptId?: string;
  isBulkOcr?: boolean;
  fileContentType?: string | null;
};

type ReimbursementFormIsValidPayload = {
  numRequests: number;
};

export type ReimbursementsMutationRequestPayload = {
  expenseStatus?: ExpenseStatus | undefined;
  numRequests: number;
  numMileage: number;
  hasSpendLimit: boolean;
  isBulkOcr?: boolean;
  shouldGenerateAffidavit?: boolean;
  // Only tracked if single mileage request
  tripType?: string;
  countryCode?: string;
  numStops?: number;
};

type ReimbursementsMutationResponsePayload = {
  mutation: string;
  error?: ApolloError;
  hasMileage?: boolean;
  hasSpendLimit: boolean;
  isReimbursementSpendLimitRequired: boolean;
};

type ReimbursementsConfirmationAlertPayload = {
  title: string;
  action?: string;
};

type ReimbursementsFieldPayload = {
  name: string;
};

type ReimbursementsTabChangedPayload = {
  tab: string;
};

type InputFieldPayload = {
  field: string;
};

type EmployeeBankAccountConnectPayload = {
  country: string;
  currency: string;
  isEditing: boolean;
};

type EmployeeBankAccountFormPayload = {
  isEditing: boolean;
  isOnboarding: boolean;
};

type EmployeeBankAccountBackNextPayload = {
  from: string;
};

type EmployeeBankAccountMutationResponsePayload = {
  mutation: string;
  error?: ApolloError;
};

type BudgetOffboardingBrokenApprovalChainsPayload = {
  deactivatedUsersCount?: number;
  brokenApprovalChainsCount?: number;
  brokenBudgetTemplatesCount?: number;
  brokenCardsCount?: number;
};

type BudgetOffboardingErrorPayload = {
  error?: ApolloError;
  errorType:
    | "budgetOffboardingActions"
    | "expenseCategoryFieldIds"
    | "vatFieldIds";
};

type BudgetOffboardingCurrentStepPayload = {
  currentStep: string;
  currentSubStep?: number;
};

type BudgetOffboardingOffboardUserPayload =
  BudgetOffboardingCurrentStepPayload & {
    deactivatedEmployeeId: string;
  };

type BudgetOffboardingOffboardUserContinuePayload =
  BudgetOffboardingOffboardUserPayload & {
    offboardedUserCount: number;
    hasRequiredActions: boolean;
  };

type BudgetOffboardingReviewPayload = BudgetOffboardingCurrentStepPayload & {
  deactivatedEmployeeId: string;
  actionItemCount: number;
};

type BudgetOffboardingReviewApprovalChainsPayload =
  BudgetOffboardingCurrentStepPayload &
    BudgetOffboardingBrokenApprovalChainsPayload & {
      deactivatedEmployeeId: string;
    };

type BudgetOffboardingCardOwnershipPayload =
  BudgetOffboardingCurrentStepPayload & {
    brokenPCardCount: number;
    deactivatedEmployeeId: string;
  };

type BudgetOffboardingApprovalChainActionPayload = {
  deactivatedEmployeeId: string;
  actionType?:
    | BudgetTemplatePolicyType
    | "BUDGET_REQUESTS"
    | "VIEW_ACTIVE_BUDGETS";
  policyId?: string | null;
  templateId?: string;
};

type BudgetOffboardingCardActionPayload =
  BudgetOffboardingCurrentStepPayload & {
    budgetId: string | undefined;
    budgetType: CardBudgetOwnershipType | null;
    cardId: string;
    deactivatedEmployeeId: string;
    selectedCardEmployeeId?: string;
    actionType: "transfer" | "cancel";
  };

type BudgetOffboardingFormReprocessOffboardingActionPayload = {
  deactivatedEmployeeId: string;
};

type BudgetOffboardingTemplateDetailsPayload = {
  templateId?: string | null;
  policyId?: string | null;
};

type BudgetOffboardingTemplateBudgetCountPayload =
  BudgetOffboardingTemplateDetailsPayload & {
    budgetCount: number;
  };

// Deprecate and replace with BudgetProgramPayload
type BudgetProgramCreateFlowPayload = {
  budgetProgramId?: string;
};

// Deprecate and replace with BudgetProgramPayload
type BudgetProgramEditFlowPayload = {
  budgetProgramId: string;
};

// Deprecate and replace with BudgetProgramPayload
type BudgetProgramIdPayload = { budgetProgramId: string };

// Deprecate and replace with BudgetProgramPayload
type BudgetProgramsTableRowActionPayload = {
  budgetProgramId: string;
  action: BudgetProgramsTableRowActions;
};

type BudgetRequestIdPayload = {
  budgetRequestEntityId?: string;
};

type BudgetRequestApproveDenyModalPayload = {
  budgetRequestEntityId?: string;
  isOverride: boolean;
};

type BudgetManageNotificationsIdPayload = {
  budgetId: string;
};

type BudgetLimitIncreaseRequestClickedPayload = {
  budgetId?: string;
  source:
    | "budget_details_page"
    | "your_wallet_page"
    | "team_page"
    | "budget_explorer";
};

type BudgetLimitIncreaseRequestIdPayload = {
  budgetId?: string;
  limitIncreaseRequestEntityId?: string;
};

type BudgetLimitIncreaseRequestDetailsPaneListMountedPayload = {
  count?: number;
  isSearchResult?: boolean;
};

type EmployeesDropdownOptionClickedPayload = {
  optionType: EmployeeOptionTypes;
  selectionCount: number;
};

type PolicyEditorFormPayload = {
  policyId?: string | null;
  isCreatingNewPolicy: boolean;
  surfaceArea?: string;
};

type PolicyEditorSavedPayload = {
  policyId?: string | null;
  editedForms?: string[];
  autoMemoEnabled?: boolean;
};

type PolicyEditorReceiptItemizationMountedPayload = {
  expenseType: PolicyExpenseType;
};

type PolicyFormMerchantCategoryPayload = {
  type: "MERCHANT" | "CATEGORY" | "MERCHANT_EXCEPTION" | "CATEGORY_EXCEPTION";
};

type PolicyFormMerchantCategoryConfigPayload = {
  type: "MERCHANT" | "CATEGORY" | "MERCHANT_EXCEPTION" | "CATEGORY_EXCEPTION";
  restrictByPrice: boolean;
  hasNotes: boolean;
  specifiedMerchantsCount?: number;
};

type PolicyFormTravelAirlineConfigSavedPayload = AirlineForm;

type PolicyFormTravelLodgingeConfigSavedPayload = LodgingForm;

type PolicyFormTravelCarRentalConfigSavedPayload = CarRentalForm;

type PolicyFormTravelRailConfigSavedPayload = RailForm;

type PolicyFormSpendRequirementPayload = {
  expenseType: PolicyExpenseType;
  documentationType: DocumentationType;
};

type PolicyFormExpenseTypePayload = {
  expenseType: PolicyExpenseType;
};

type PolicyFormEditPayload = {
  expenseType: PolicyExpenseType;
  isUserExceptions: boolean;
};

type CashAccountOverviewTableRowClickedPayload = {
  accountId?: string;
  allAccountsView?: boolean;
};

type CashBulkStatementsDownloadClickedPayload = {
  period: BulkStatementsDownloadPeriod;
  accountId?: string;
};

type CashStatementsAllAccountsTableRowClickedPayload = {
  accountId?: string;
};

export enum TransactionsV2FilterType {
  TYPE = "type",
  STATUS = "status",
  DATE = "date",
  AMOUNT = "amount",
  TEXT = "text",
}

type CashTransactionsTableV2FilteredPayload = {
  filter?: TransactionsV2FilterType;
  addOrRemove: string;
  extendedFilters?: string;
};

type CashTransactionsTableRowClickedPayload = {
  cashTransactionId?: string;
};

type CashTransactionsTableLoadMoreTransactionsClickedPayload = {
  tableType: CashTransactionsPageTables;
};

type CashTransactionsTableFilteredPayload = {
  filteredBy: string;
  startDate?: Date | null;
  endDate?: Date | null;
  value?: string;
};

type CashTransactionsDetailsPaneEditTransactionClickedPayload = {
  cashTransactionId: string;
};

type CashTransactionsDetailsPaneCancelTransactionClickedPayload = {
  cashTransactionId: string;
};

type CashTransactionsDetailsPaneDownloadTransactionClickedPayload = {
  cashTransactionId: string;
};

type CashTransactionsClearPositivePayConfirmPayload = {
  type: PositivePayStatus | null;
};

type ImplementationSetupViewedPayload = {
  view: string;
};

type ReceiptProviderPayload = {
  provider: ReceiptProvider;
};

type GenerateAffidavitPayload = {
  expenseId: string;
  expenseType: ExpenseType;
};

export enum TaskInboxPaginationClickType {
  NEXT = "NEXT",
  PREV = "PREVIOUS",
  PAGESIZE = "PAGESIZE",
}

export enum BulkTaskActionType {
  APPROVE = "APPROVE",
  DENY = "DENY",
  RESOLVE = "RESOLVE",
}

type TaskInboxSectionViewPayload = {
  type: TaskInboxSectionType;
  /**
   * metadata from inboxTasks query
   */
  webFilterType: InboxTaskWebFilterType | SurfaceGroup;
  sortBy?: InboxTaskSortFilter | SurfaceTaskSortFilter;
  count: number;
};

type VisibleTask = {
  id: string;
  type: InboxTaskType;
};

type TaskInboxSectionViewTasksPayLoad = {
  type: TaskInboxSectionType;
  visibleTasks: Array<VisibleTask>;
  webFilterType?: InboxTaskWebFilterType | SurfaceGroup;
  count?: number;
};

type TaskInboxPaginationClickPayload = {
  type: TaskInboxSectionType;
  webFilterType: InboxTaskWebFilterType | SurfaceGroup;
  clickType: TaskInboxPaginationClickType;
};

type TaskInboxPaginationClickErrorPayload = TaskInboxPaginationClickPayload & {
  error: string;
};

type TaskInboxListTaskActionPayload = {
  id: string;
  entityId: string;
  type: InboxTaskType;
};

type TaskInboxListTaskClickPayload = TaskInboxListTaskActionPayload & {
  ctaType: TaskCtaType;
};

type TaskInboxListErrorPayload = {
  error: string;
  filter: InboxTaskWebFilterType | SurfaceGroup;
};

type TaskInboxSectionErrorPayload = {
  error: string;
  webFilterType?: InboxTaskWebFilterType | SurfaceGroup;
  type: TaskInboxSectionType;
};

type TaskInboxBulkPayload = {
  taskType: InboxTaskType;
  id: string;
  action: BulkTaskActionType;
  type: TaskInboxSectionType;
};

type TaskInboxBulkSelectPayload = {
  type: TaskInboxSectionType;
  webFilterType?: InboxTaskWebFilterType;
  hasSelectedAll: boolean;
};

type TaskCompletedPayload = {
  taskType: InboxTaskType;
  id: string | string[];
  action: RequestActionStatusInput;
};

type TaskClosedPayload = {
  taskType: InboxTaskType;
  id: string | string[];
};

type TaskInboxSectionPayload = {
  type: TaskInboxSectionType;
  /**
   * Only used to distinguish between the inboxTasks filters if there are multiple
   * sections in a tab
   */
  webFilterType?: InboxTaskWebFilterType | SurfaceGroup;
  count?: number;
};

type TaskInboxBudgetRequestsCtaPayload = {
  filterType: BudgetRequestTable;
};

type TaskInboxBudgetRequestsTableEmptyPayload = {
  filterType: BudgetRequestTable;
};

type TaskInboxBudgetRequestsTableViewPayload = {
  filterType: BudgetRequestTable;
  count: number;
};

type TaskInboxBudgetRequestsTableErrorPayload = {
  filterType: BudgetRequestTable;
  error: string;
};

type TaskInboxDetailsPaneViewPayload = {
  taskId: string;
  taskEntityId: string | null | undefined;
  taskEntityName: string | null | undefined;
  taskType: InboxTaskType | null | undefined;
};

type TaskInboxAdditionlTask = {
  type: TaskInboxSectionType;
  count: number;
};

export enum TaskInboxFilterType {
  STATE = "state",
  INSIGHT_TYPE = "insight_type",
  DATE = "date",
}

type TaskInboxListFiltersPayload = {
  webFilterType: InboxTaskWebFilterType;
  filter?: TaskInboxFilterType;
  addOrRemove: string;
  extendedFilters?: string;
};

type TaskInboxDetailsPaneViewExpensePayload =
  TaskInboxDetailsPaneViewPayload & {
    expenseId: string;
  };

type TaskInboxRelevantExpensesClickPayload = {
  type?: InboxTaskType | undefined;
  seeMoreType: "MORE" | "ALL";
  count: number;
};

type TaskInboxDetailsPaneErrorPayload = TaskInboxDetailsPaneViewPayload & {
  error: string;
};

type TaskInboxTaskClickPayload = {
  entity_id: string;
  type: TaskInboxSectionType;
  /**
   * id and taskType are optional because some tasks are not part of the task service
   * and are still within the request service.
   */
  id?: string;
  taskType?: InboxTaskType;
};
// TODO: Task Inbox to depreciate:
type TaskInboxListPayload = {
  taskCount?: number;
  filter: InboxTaskWebFilterType | SurfaceGroup;
  sortBy?: InboxTaskSortFilter;
};

type TaskInboxListViewTasksPayLoad = {
  visibleTasks: Array<VisibleTask>;
  webFilterType?: InboxTaskWebFilterType;
};

type TaskInboxListPaginationClickPayload = {
  type: InboxTaskWebFilterType | SurfaceGroup;
  clickType: TaskInboxPaginationClickType;
};

type TaskInboxListPaginationClickErrorPayload =
  TaskInboxListPaginationClickPayload & {
    error: string;
  };

type TaskInboxViewUnexpectedType = {
  type: InboxTaskType;
  webFilterType: InboxTaskWebFilterType | SurfaceGroup;
  taskId: string;
};

type TaskInboxAdditionalTasksClickPayload = TaskInboxAdditionlTask;

type TaskInboxAdditionalTasksViewPayload = {
  additionalTasks: TaskInboxAdditionlTask[];
};

// END Task Inbox payloads

type NotificationCenterAlertSubscribeTapPayload = {
  alertId: string;
  notificationType: string;
  notificationGroupId: string;
  cchno: string;
};

type NotificationCenterAlertUnsubscribeTapPayload = {
  alertId: string;
  notificationType: string;
  notificationGroupId: string;
  cchno: string;
};

type NotificationCenterAlertUpdateStatusErrorPayload = {
  error: string;
};

type NotificationCenterOverviewViewPayload = {
  session_start_time: string;
};

type NotificationCenterOverviewBadgeCountErrorPayload = {
  error: string;
};

type NotificationCenterViewErrorPayload = {
  error: string;
};

type NotificationCenterAlertCtaTapPayload = {
  alertId: string;
  ctaUrl: string;
  notificationType: string;
  cchno: string;
};

type NotificationCenterAlertMarkUnreadTapPayload = {
  alertId: string;
  cchno: string;
};

type NotificationCenterAlertMarkReadTapPayload = {
  alertId: string;
  cchno: string;
};

type NotificationCenterAlertCtaTapSuccessPayload = {
  alertId: string;
  ctaUrl: string;
  notificationType: string;
  cchno: string;
};

type NotificationCenterAlertCtaTapFailedPayload = {
  alertId: string;
  ctaUrl: string;
  notificationType: string;
  cchno: string;
};

type NotificationCenterAlertSeeMoreTapPayload = {
  alert_id: string;
  cchno: string;
};

type NotificationCenterAlertSeeLessTapPayload = {
  alert_id: string;
  cchno: string;
};

type NotificationCenterViewAlertsPayload = {
  visibleAlerts: {
    notificationType: string;
    primaryUrl: string;
    id: string;
    cchno: string;
  }[];
};

type NotificationCenterReadAllTapPayload = {
  alertCount: number;
};

type NotificationCenterOverviewAbandonedPayload = {
  method: "navigated_away" | "closed_tab";
  session_start_time: string;
};

type CreditCardSensitiveInfoDNSErrorPayload = {
  accountId?: string;
};

type CreditCardDetailsCopiedPayload = {
  field: CreditCardFieldType;
  cardType: "primary" | "non-primary" | "budget" | "bill-pay" | "unknown";
  origin?: "wallet_l1" | "card_details_pane";
};

type CardDetailsPanePayload = {
  origin?: CardDetailsPaneOrigin;
};

type YourWalletViewCardDetailsClickedPayload = {
  origin?:
    | "budgets_and_cards_pane"
    | "unassigned_cards_pane"
    | "budget_card_search_pane";
};

type BudgetsCardsPaneSearchEnteredPayload = {
  pane:
    | "budgets_and_cards_pane"
    | "unassigned_cards_pane"
    | "expired_budgets_pane"
    | "budget_card_search_pane"
    | "automatic_limit_selection_cards_pane";
};

type BudgetsCardsPaneViewSubPaneClickedPayload = {
  pane:
    | "unassigned_cards_pane"
    | "expired_budgets_pane"
    | "pending_budgets_pane"
    | "budget_card_search_pane"
    | "automatic_limit_selection_cards_pane"
    | "bill_pay_cards_pane";
};

type AdminCardShippingNextStepPayload = {
  state: string;
  nextStep: string;
};

type YourWalletBudgetReassignedPayload = {
  cardType: "primary" | "non-primary";
  origin: CardDetailsPaneOrigin;
  isCardOwner?: boolean;
};

type YourWalletBudgetToastPayload = {
  complianceRating: Rating | undefined;
};

type YourWalletNewVirtualCardCreatedPayload = {
  cardId: string;
  isAdminOrderingOnBehalfOfUser?: boolean;
};

type YourWalletNewVirtualCardPayload = {
  origin: "wallet_l1" | "card_details_pane";
  isEssentialsCreateCardEnabled?: boolean;
};

type BudgetTableRowExpansionStatePayload = {
  depth: number;
  origin: BudgetsTableOrigin;
};

type BudgetTemplateIdPayload = { budgetTemplateId: string };

type BudgetTemplateErrorPayload = BudgetTemplateIdPayload & { error?: string };

type BudgetTemplateTableUpdateSuccessPayload = {
  budgetTemplateId: string;
  fieldUpdated: string;
};

type BudgetTemplateTableUpdateErrorPayload = {
  budgetTemplateId: string;
  fieldUpdated: string;
  error: ApolloError;
};

type BudgetTemplateDetailsUpdateErrorPayload = {
  budgetTemplateId: string;
  error?: ApolloError;
};

type BudgetTemplateAvailabilityPayload = {
  budgetTemplateId: string;
  availabilityType: BudgetTemplateAvailabilityType;
};

type BudgetTemplateBudgetRequestOrganizationSavedPayload = {
  budgetTemplateId: string;
  budgetRequestNestingCondition: BudgetRequestNestingCondition;
  autoApprovalEnabled: boolean;
};

type CustomRulePayload = Is1BrexRearchitecturePayload & {
  cohortValue?: number;
  ruleId?: string;
  suggestedRule?: boolean;
};

type StreamlinedRuleInvalidPayload = {
  suggestionId: string;
  side: "filters" | "actions";
};

type StreamlinedSuggestionsButtonPayload = {
  suggestionsCount: number;
  page: "prepare" | "export";
};

type StreamlinedSuggestionsTourPayload = {
  stepId: string;
  stepIndex: number;
  stepsCount: number;
  /**
   * From anchor determines whether the event was trigged
   * from the popover ctas or from the popover anchor.
   */
  fromAnchor: boolean;
};

type StreamlinedSuggestionsPanePayload = Omit<
  StreamlinedSuggestionsButtonPayload,
  "page"
> & {
  /**
   * Ids of the suggestions that are currently visible in the pane.
   */
  ids: string[];
};

type StreamlinedSuggestionsBasePayload = {
  suggestionId: string;
};

type SuggestionAcceptancePayload = StreamlinedSuggestionsBasePayload & {
  isEdited: boolean;
};

type CustomRuleInputPayload = CustomRulePayload & {
  inputType: OptionInputTypes | OptionInputTypesV2;
  ruleType: RuleType;
};

type AccountingMappingsSectionPayload = {
  location: "1B" | "1B-summer-release" | "pre-1B";
};

type AccountingFieldPayload = {
  id?: string;
  group?: ExtendedFieldGroup;
};

type AccountingTakeoverPayload = AccountingFieldPayload & {
  step?: string;
};

type AccountingFieldVisibilityPayload = {
  tag?: string;
  value?: string;
};

export type AccountingCustomReportAccountingBannerPayload = {
  origin: string | null;
};

type AccountingCustomReportAccountingBannerIsTargetPayload =
  AccountingCustomReportAccountingBannerPayload & {
    isGroupToShowEducation?: boolean;
  };

type AccountingCustomReportAccountingBannerDoNotShowAgainPayload =
  AccountingCustomReportAccountingBannerPayload & {
    value: boolean;
  };

type AccountingPayload = Is1BrexRearchitecturePayload &
  IsDatagridPayload & {
    vendor?: IntegrationVendors | null;
  };

type AccountingSetupGlAccountsEntryMethodPayload = {
  setupMethod: AccountingSetupGlAccountsEntryMethod;
};

type AccountingIntegrationAlertCtaPayload = {
  vendor?: IntegrationVendors | null;
};

export type AccountingSetupUpdateIntegrationRulesPayload = {
  integrationRulesUpdatedEventsPayload: {
    ruleId: string;
    key: string;
    optionId: string;
  }[];
};

export type AccountingSetupCreateSuggestionsPayload = {
  suggestionsSavedEventsPayload: {
    actionType: AutomationSuggestionActionType;
    suggestionId: string;
    ruleId: string;
  }[];
};

type AccountingOnboardingChooseUserTypePayload = {
  userType: UserType;
};

type AccountingOnboardingSelectTeamMemberPayload = {
  teamMemberRole: UserRole | null;
};

export type AccountingOnboardingSelectTeamMemberTeamPageLinkClicked = {
  origin: "upgrade_role" | "invite_member";
};

type AccountingOnboardingIntegrationCreatedPayload = {
  vendor: IntegrationVendors;
};

type AccountingJournalDatagridAccrrualsFTUXPayload = {
  ftuxNotSeenFlag?: SeenFlagKey;
  vendor: IntegrationVendors;
};

type AccountingSetupGLDetailsStepMountedPayload =
  Is1BrexRearchitecturePayload & {
    flow: AccountingSetupFlow;
  };

type AccountingOverviewPayload = AccountingPayload & {
  hasOnboarded?: boolean;
  hasTransactions?: boolean;
};

type AccountingOnboardingGetStartedPayload = IsDatagridPayload & {
  withExperimentEnabled?: boolean;
};

type AccountingExportHistoryTabsPayload = {
  tab: "export" | "transaction";
};

type AccountingExportHistoryTabEducationCompletedPayload = {
  from: "hotspot" | "tab";
};

type AccountingExportHistoryBasePayload = {
  exportAttemptId?: string;
  exportAttemptStatus?: ExportJobStatus;
  exportMethod?: string;
};

type AccountingExportHistoryFiltersPayload = {
  selectedOptions: string[] | string;
};

type AccountingExportHistoryTableRowPayload =
  Required<AccountingExportHistoryBasePayload>;

export type AccountingExportHistoryTableRowMenuPayload =
  Required<AccountingExportHistoryBasePayload> & {
    target: "MENU" | "MENU_ITEM";
  };

type AccountingExportHistoryTableErpDeeplinkPayload =
  AccountingExportHistoryTableRowMenuPayload & {
    vendor: IntegrationVendors;
  };

type AccountingExportHistoryTableColumnSortPayload = {
  columnId: string;
  columnSortOrder: OrderByDirection;
};

export type AccountingExportHistoryDetailsPanePayload =
  AccountingExportHistoryBasePayload & {
    detailsPaneType: string;
    exportMessagesCount?: number;
  };

type AccountingExportHistoryRoutingModalPayload =
  AccountingExportHistoryBasePayload & {
    type: string;
  };

type AccountingExportHistorySendBackModalPayload =
  AccountingExportHistoryBasePayload & {
    numTransactionsToSendBack: number;
  };

type AccountingJournalCorePayload = Is1BrexRearchitecturePayload &
  MetalTablePayload & {
    reviewStatus: AccountingJournalEntryReviewStatus;
  };

export type AccountingEntityDetailsPaneTaxCodeSectionPayload = {
  accountingEntityId: string;
  is1BEnabled?: boolean;
  legalEntityId?: string;
  subsidiaryIdentifier?: string;
};

export type AccountingEntityDetailsPaneTaxCodeCustomAccountingSectionPayload = {
  legalEntityId: string;
};

type AccountingJournalRowPayload = AccountingJournalCorePayload & {
  id: string;
  entrySourceId: string;
  entryType: AccountingJournalEntryType | AccountingRecordType;
};

type AccountingEntityDetailsPaneTaxCodeSectionFieldPayload =
  AccountingEntityDetailsPaneTaxCodeSectionPayload & {
    fieldId: string;
    employeeEditable?: boolean;
    status: ExtendedFieldStatus;
  };

type AccountingEntityDetailsPaneTaxCodeSectionOptionsPayload =
  AccountingEntityDetailsPaneTaxCodeSectionPayload & {
    fieldId: string;
    employeeEditable?: boolean;
    status: ExtendedFieldOptionStatus;
  };

type AccountingEntityDetailsPaneTaxCodeSectionUpdateTaxPurchaseAccountsPayload =
  AccountingEntityDetailsPaneTaxCodeSectionPayload & {
    updatedName?: string;
    subsidiaryId: string;
  };

export type AccountingExpenseAccruedDateFieldPayload = {
  accountingRecordId: string;
  origin: "EXPENSES_ALL" | "ACCOUNTING_JOURNAL";
};

export type AccountingExpenseVatFieldPayload = {
  addendumFieldId?: string;
  amount?: number;
  identifier?: string;
  legalEntityId?: string;
  origin: "DASHBOARD_EXPENSE_DETAILS_PANE" | "DASHBOARD_REIMBURSEMENT";
  spendingEntityId?: string;
};

type AccountingJournalTableCheckboxPayload = AccountingJournalRowPayload & {
  checked: boolean;
};

type AccountingJournalFieldPayload = AccountingJournalRowPayload &
  Is1BrexRearchitecturePayload & {
    columnName: string;
    hasSeenEducationToast?: boolean;
    numEntries?: number;
  };

type AccountingJournalBulkEditPayload = Is1BrexRearchitecturePayload & {
  action?: { id: RuleBodyClause["left"]; value: RuleBodyClause["right"] }[];
  cohortValue?: number;
  columnName?: string;
  entrySourceIds?: string[];
  filters?: { id: RuleBodyClause["left"]; value: RuleBodyClause["right"] }[];
  hasSeenEducationToast?: boolean;
  numEntries: number;
  suggestedRule?: boolean;
  selectedAllEnabled?: boolean;
  workflowsActions?: {
    id: string;
    value: WorkflowsRuleBodyClause["rightHandSide"];
  }[];
  workflowsFilters?: {
    id: string;
    value: WorkflowsRuleBodyClause["rightHandSide"];
  }[];
} & (MetalTablePayload | IsDatagridPayload);

type AccountingJournalBulkEditEducationPayload = {
  fromBulkUpdateAllClick?: boolean;
};

type AccountingJournalColumnSettingsPayload = Is1BrexRearchitecturePayload &
  MetalTablePayload & {
    columnName?: string;
    reviewStatus: AccountingJournalEntryReviewStatus;
  };

type AccountingJournalTableColumnSortPayload = Is1BrexRearchitecturePayload &
  MetalTablePayload & {
    columnId: string;
    columnSortOrder: OrderByDirection;
    reviewStatus: AccountingJournalEntryReviewStatus;
  };

export type AccountingJournalExportPayload = MetalTablePayload & {
  entryTypes: string[];
  exportType: string;
  numEntries: number;
};

type AccountingJournalStatusChangePayload = Is1BrexRearchitecturePayload &
  MetalTablePayload & {
    numEntries: number;
    selectAllEnabled: boolean;
    timeToStatusChange?: number;
  };

type AccountingJournalStatusChangeToastPayload = Is1BrexRearchitecturePayload &
  MetalTablePayload & {
    targetReviewStatus: AccountingJournalEntryReviewStatus;
  };

type AccountingJournalReportEducationFormatChangedPayload =
  IsDatagridPayload & {
    reportFormatType: AccountingJournalReportFormatType;
  };

type AccountingJournalReportEducationDownloadPayload = IsDatagridPayload & {
  numEntries: number;
  selectAllEnabled: boolean;
  reportFormatType: AccountingJournalReportFormatType;
};

type AccountingJournalDatagridBulkEditBannerPayload = {
  bannerType: "WARNING" | "ERROR";
  columnName: string;
  numEntries: number;
  selectAllEnabled: boolean;
};

type AccountingJournalDatagridMountedPayload = {
  reviewStatus: string;
  vendor: string;
};

type AccountingJournalDatagridApplyPersistedViewPayload = {
  persistedViewId?: string;
};

type AccountingJournalDatagridShelfPayload = {
  numSuggestions?: number;
  missingGlSettingsVisible?: boolean;
  type:
    | "CREATE_RULE"
    | "FIELDS_AND_RULES"
    | "SETTINGS"
    | "EXPORT_HISTORY"
    | "ENTITY_SETTINGS";
};

type AccountingJournalDatagridInlineEditPayload = {
  accountingRecordType: string;
  accountingRecordId: string;
  columnName: string;
};

type AccountingJournalDatagridTabClickPayload = {
  reviewStatus: string;
};

export type AccountingJournalDatagridReviewStatusPayload = {
  isAccrual: boolean;
  numAttemptedTransactions: number;
  selectAllEnabled: boolean;
  targetReviewStatus: string;
};

export type AccountingJournalDatagridAccrualReversalDatePayload = {
  accrualReversalDate: string;
  numAttemptedTransactions: number;
  numVisibleAccountingRecords: number;
  selectAllEnabled: boolean;
  vendor: IntegrationVendors;
};

export type AccountingJournalDatagridAccrualStatusPayload = {
  targetReviewStatus: AccountingJournalEntryReviewStatus;
  numVisibleAccountingRecords: number;
  selectAllEnabled: boolean;
  vendor: IntegrationVendors;
};

export type AccountingJournalDatagridAccrualStatusCountPayload =
  AccountingJournalDatagridAccrualStatusPayload & {
    attemptedCountMarkAccrual: number;
    successfulCountMarkAccrual: number;
  };

export type AccountingAccrualsExportPreviewSummaryPayload = {
  numAccruals: number;
  numEntries: number;
};

type AccountingJournalDatagridReviewStatusErrorToastPayload =
  AccountingJournalDatagridReviewStatusPayload & {
    numFailedTransactions: number;
    validationErrors?: string[];
  };

type AccountingAutomationBasePayload = {
  variant?: AccountingAutomationHubVariants;
};

type AccountingAutomationHomePayload = {
  suggestionsTotalCount: number;
  suggestionIds: string[];
  suggestionGroupIds: string[];
};

type AccountingAutomationSegmentedControllerPayload = {
  actionDatasourceId?: string;
  activeTab: SegmentedControlledRuleTypes;
  filterDatasourceId?: string;
};

type AccountingAutomationInvalidSuggestionPayload = {
  reason:
    | "lhsMissingData"
    | "noActionOrFilterData"
    | "noSuggestionData"
    | "rhsMissingData"
    | "rhsMissingMultipleData";
  side?: "actions" | "filters";
  suggestionGroupId: string;
  suggestionId: string;
};

type AccountingAutomationSuggestionBasePayload = Partial<
  Pick<
    AccountingRuleSuggestion,
    "cardType" | "ruleSuggestionType" | "suggestionId" | "suggestionGroupId"
  >
>;

type AccountingAutomationSuggestionCardPayload =
  AccountingAutomationSuggestionBasePayload & {
    actionType?: AutomationSuggestionActionType;
    displayedLocation: AccountingAutomationSuggestionDisplayedLocations;
  };

type AccountingAutomationManaulSuggestionPayload = {
  actionDatasourceId?: string;
  activeValue?: string;
  fieldName?: string;
  filterDatasourceId?: string;
};

type AccountingAutomationSuggestionsTakeoverPayload = {
  suggestionsTotalCount: number;
  suggestionsVisibleCount: number;
};

type AccountingAutomationSuggestionsTakeoverAcceptBannerPayload = {
  suggestionsUpdatedCount: number;
  suggestionTypeFilterValue: AccountingWorkflowRuleType;
};

type AccountingAutomationSuggestionsTakeoverFiltersPayload = {
  filterFieldName: string;
  filterSelectedValue: string;
};

type AccountingAutomationSuggestionsTakeoverBannerPayload = {
  fieldFilterValue: string;
  suggestionTypeFilterValue: string;
};

type AccountingAutomationSuggestionsTakeoverBulkAcceptPayload = {
  actionDatasourceId?: string;
  filterDatasourceId?: string;
  suggestionGroupIds?: string[];
  suggestionIds?: string[];
  suggestionsTotalCount: number;
  suggestionTakeoverActionType: SuggestionTakeoverActionType | undefined;
};

type AccountingAutomationToastPayload = {
  cardType?: AccountingSuggestionCardType;
  displayedLocation: AccountingAutomationSuggestionDisplayedLocations;
  isMlSuggestion: boolean;
  fieldId?: string;
};

type AccountingAutomationAccountingJournalCellCreateRulePayload = {
  actionDatasourceId?: string;
  fieldName: string;
  suggestionId?: string;
};

type AccountingAutomationAccountingJournalShelfSuggestionsCountCreateRulePayload =
  {
    suggestionsTotalCount: number;
  };

type AccountingAutomationFtuxAndPreDatagrid = {
  cardType: AccountingSuggestionCardType;
  displayedLocation: "ONGOING" | "FTUX";
  suggestionIds?: string[];
};

export type AccountingExpenseCategoryPayload = {
  expenseId?: string;
  expenseCategoryValueId?: string | null;
  isPrefilled?: boolean;
  isMobile: boolean;
  predictionId?: string;
  edpV2?: boolean;
};

type AccountingExpenseCategorySelectionPayload =
  AccountingExpenseCategoryPayload & {
    isSuggestionSelected?: boolean;
    timeToSelection?: string;
  };

type AccountingWorkflowRuleImpactedTransactionsPayload = {
  extendedFieldActionIdsCount: number;
  location: AccountingWorkflowRuleImpactedTransactionsLocations;
  simulatedTransactionTotalCount: number | undefined;
  transactionsTotalCount: number | null | undefined;
};

type AccountingWorkflowRuleImpactedTransactionsClickedPayload = Pick<
  AccountingWorkflowRuleImpactedTransactionsPayload,
  "location"
>;

type AuditTrailRowClickPayload = {
  id: string;
};

type AuditTrailFilterPayload = {
  appliedFilters: FilterParams<AuditLogFilterType>;
};

type AuditTrailDeepLinkPayload = {
  targetType: TargetType;
};

type SecurityShelfCtaClickedPayload = {
  cta: "trust_portal" | "authentication";
};

type AsyncExportBannerPayload = Is1BrexRearchitecturePayload & {
  exportId: string;
  exportStatus: ExportStatus | ExportJobStatus | null;
  vendor: IntegrationVendors;
};

type AsyncExportSelectorPayload = Is1BrexRearchitecturePayload & {
  checked: boolean;
  exportType?: ExportType | ExportMethod;
};

type SearchTermPayload = {
  searchTerm: string;
};

type ScopedSearchSelectedPayload = {
  isRecentSearch?: boolean;
  origin: ExpensesTableOrigin | string | undefined;
  requestId: string | null;
  selectedEntityId: string | null;
  rowIndex?: number;
  resultType: ScopedSearchResultTypes | string;
  trigger: "Enter" | "cmd+Enter" | "click";
};

type ScopedSearchTableRowPayload = {
  filters?: ExpenseFilterInput;
  origin: ExpensesTableOrigin | string | undefined;
  rowIndex: number;
  requestId: string | null;
};

type GlobalSearchSelectedPayload = {
  isRecentSearch?: boolean;
  intentType?: IntentType;
  resultType: string; // Navigation, etc
  rowIndex?: number;
  trigger: "Enter" | "click"; // event trigger
};

type GlobalSearchIntentTypeSelectedPayload = {
  intentType: SelectedIntentTabType;
};

type IntegrationsListPayload = {
  completeTimestamp?: number;
  startTimestamp?: number;
  integrationName: string;
  credentialId?: string;
  bankFeedClientId?: string;
  errorDetails?: Integration["errorDetails"];
  errorMessage?: string;
};

type IntegrationsConnectBannerCustomAccountingOptionClicked = {
  qboFtuxSetupExperimentEnabled?: boolean;
};

type IntegrationsConnectERPStepCTAClickPayload = {
  currentStep: (typeof ConnectSteps)[keyof typeof ConnectSteps];
};

type IntegrationsGlSelectionPayload = {
  type: string;
};

export type IntegrationManagementGlAccountPayload = {
  accountId?: string;
  vendor?: IntegrationVendors;
};

type IntegrationsUpdateExportCadencePayload = {
  type: string;
};

type IntegrationsUpdateTransactionExportTypePayload = {
  transactionType: string;
  type: TransactionExportType;
};

type IntegrationArticleRowPayload = {
  articleId: string;
};

type IntegrationsUpdateNetsuiteNameFieldSettingsClickedPayload = {
  cardExpensesNameFieldTarget?: AccountingIntegrationSettingsNetsuiteNameFieldTarget;
  reimbursementsNameFieldTarget?: AccountingIntegrationSettingsNetsuiteNameFieldTarget;
};

type CustomCsvExportTemplateIdPayload = {
  templateId: string | null;
};

type ExpenseMemoSuggestionPayload = {
  expenseId?: string | null;
  suggestionId?: string | null;
};

type ExpenseBudgetSuggestionPayload = ExpenseMemoSuggestionPayload;

type ExpenseAttendeeSuggestionPayload = ExpenseMemoSuggestionPayload & {
  type?: "internal" | "external";
};

type ExpenseBudgetSuggestionAcceptPayload = ExpenseBudgetSuggestionPayload & {
  budgetId: string;
  location: "expense_details_pane" | "expense_budget_dropdown";
};

type ExpenseDetailsPaneBudgetAndPolicyExpenseFieldPayload = {
  expenseId: string;
  field: "location" | "department";
};

type CustomCsvExportAccrualReversalDateClickedPayload = {
  accrualReversalDate: string;
};

type CustomCsvExportEntryButtonClickedPayload = Is1BrexRearchitecturePayload &
  MetalTablePayload;

export type CustomCsvExportBasePayload = Is1BrexRearchitecturePayload & {
  emailExportNotification: ExportNotificationType | null;
  entryTypes: string[];
  exportType: ExportType;
  isAsyncExport: boolean;
  numEntries: number;
  selectAllEnabled: boolean;
};

export type CustomCsvExportPayload = CustomCsvExportBasePayload &
  CustomCsvExportTemplateIdPayload;

export type CustomCsvERPExportPayload = {
  erpExportAnalyticsParams: ERPExportAnalyticsParams;
  vendor: IntegrationVendors;
} & CustomCsvExportBasePayload;

export type CustomCsvExportAccountingIntegrationPayload = {
  emailExportNotification: ExportNotificationType | null;
  entryTypes: string[];
  exportType: ExportType;
  numAccruals: number;
  // numEntries are total entries attempted to export
  numEntries: number;
  vendor: IntegrationVendors;
  erpExportAnalyticsParams?: ERPExportAnalyticsParams;
  templateId?: string;
};

type CustomCsvExportColumnHeaderRenamedPayload = {
  columnId: string;
  newDisplayedHeader: string;
} & CustomCsvExportTemplateIdPayload;

type CustomCsvExportColumnsSavedPayload = {
  columns: {
    columnId: string;
    displayedHeader: string;
    isVisible: boolean;
  }[];
  isCreate: boolean;
} & CustomCsvExportTemplateIdPayload;

type RequestsCtaPayload = {
  origin?: string;
};

type ReceiptsBulkUploadPayload = {
  pathname?: string;
};

type ReceiptsBulkMatchToastPayload = {
  matchedReceipts: number;
  unmatchedReceipts: number;
};

type MileageTripTypePayload = {
  // TODO: we should be able to import TripType here but doing so will trigger circular dependency loop
  // Need to refactor & clean up how shared types are being exported in a few other domains
  tripType: string;
};

type MileageAddRemoveStopsPayload = {
  updatedStops: number;
};

type MoveMoneyPayload = {
  origin?: string;
  version?: number;
};

type CommentSendCommentPayload = {
  externalEntityId: string;
  externalEntityType: string;
  hasTags: boolean;
  reminderSuggestionStatus: "not_a_suggestion" | "unmodified" | "modified";
  hasPendingCommentTask: boolean;
};

type CommentTaskPayload = {
  id?: string;
  externalEntityId: string;
  externalEntityType: string;
};

type CommentBulkDocumentationReminderPayload = {
  numOfExpenses: number;
};

type CommentDocumentationReminderPayload = {
  origin: "comment_panel" | "compliance_banner";
};

type CommentAddToMemoSuggestionPayload = {
  commentId: string;
};

type ErrorPayload = {
  error: string;
};

type TableInputUpdatedSuccessPayload = {
  expenseId: string;
  origin?: ExpensesTableOrigin;
};

type BulkEditDetailsUpdatedSuccessPayload = {
  numOfExpenses: number;
  groupSelection: boolean;
  origin?: ExpensesTableOrigin;
};

type BulkEditDetailsEditClickedPayload = {
  groupSelection: boolean;
};

type ExpensesTablePaginatedPrevClickedPayload = {
  currentPage: number;
  origin: ExpensesTableOrigin;
};

type ExpensesTablePaginatedNextClickedPayload = {
  currentPage: number;
  origin: ExpensesTableOrigin;
};

type ReimbursementsSCAMutationErrorPayload = {
  error?: ErrorCode | string;
};

type AccountsV2ModuleOptionClickedPayload = {
  moduleTitle: string;
  value: string;
  periodInfo: AccountsTimeSeriesPeriodInfo;
};

type ReimbursementsBulkOcrCountPayload = {
  receiptCount: number;
  ocrSuccessCount: number;
};

type BulkOcrPaginationPayload = {
  receiptCount: number;
  currentIndex: number;
  nextIndex: number;
};

type ReimbursementsBulkOcrPayload = {
  reimbursementCount: number;
  isDraft?: boolean;
  hasSameMemo: boolean;
  hasSameBudget: boolean;
};

type AttributeMappingViewPayload = {
  legalEntitiesCount: number;
};

type AttributeMappingSubmitPayload = {
  submittedEntityTypes: SubmittedEntityTypes;
};

type ConfigureBillingFlowDefaultPayload = {
  legalEntityId: string;
  productType: BillingGroupProductType;
};
type ConfigureBillingFlowButtonClickedPayload = {
  productType: BillingGroupProductType;
  origin: ConfigureBillingFlowOrigin;
};
type ConfigureBillingFlowMountedPayload = ConfigureBillingFlowDefaultPayload;
type ConfigureBillingFlowLegalEntityChangedPayload =
  ConfigureBillingFlowDefaultPayload;
type ConfigureBillingFlowCountryChangedPayload =
  ConfigureBillingFlowDefaultPayload & {
    countryOption: CountryOnChangePayload;
  };
type ConfigureBillingFlowCurrencyChangedPayload =
  ConfigureBillingFlowDefaultPayload & {
    currency: FinancialInstrumentCode;
  };
type ConfigureBillingFlowPaymentMethodChangedPayload =
  ConfigureBillingFlowDefaultPayload & {
    isTracking: boolean;
  };
type ConfigureBillingFlowFundingSourceChangedPayload =
  ConfigureBillingFlowDefaultPayload & {
    source: SelectAccountForTransferValueType;
  };
type ConfigureBillingFlowBillingCycleChangedPayload =
  ConfigureBillingFlowDefaultPayload & SelectedBillingCycle;

type StatementsYearPayload = ConsolidatedBillingPayload & {
  year: number;
};

type StatementsDownloadPayload = ConsolidatedBillingPayload & {
  statementId: string;
};

type DashboardSearchEventPayload = {
  entry?: DashboardSearchEntryPoint;
  numberOfResults?: number;
  accountMenuFlag?: boolean;
};

type DashboardSearchResultsPayload = DashboardSearchEventPayload;
type DashboardSearchSelectResultPayload = DashboardSearchEventPayload & {
  selectedIndex: number;
  selectedRoute: string;
  selectedGroup: string;
};
type DashboardSearchOpenedPayload = DashboardSearchEventPayload;
type DashboardSearchClosedPayload = DashboardSearchEventPayload;

type CashBannerAutopayCardPayload = {
  productType?: BillingGroupProductType;
};

type CashBannerAutopayChangePayload = {
  productType?: BillingGroupProductType;
  bannerType?: CashAccountBannerTypes;
};

type CashBannerDismissPayload = {
  bannerType?: CashAccountBannerTypes;
};

type CashBannerMountedPayload = {
  bannerType?: CashAccountBannerTypes;
};

type BrexAssistantFTUXSettingsPayload = {
  mode: AssistantMode;
};

type BrexAssistantCalendarOAuthPayload = {
  error: string;
};

type BrexAssistantActivityTaskFieldClickPayload = {
  expenseId: string | null;
  taskType: ExpenseTaskType;
};

type BrexAssistantMenuItemClickPayload = {
  tab: string;
};

// Begin Vendors event payloads

type VendorsTableMountedPayload = {
  isUsingNewFtux: boolean;
  vendorsCount: number;
};

// End Vendors event payloads

type ConsolidatedBillingPayload = {
  productType: BillingGroupProductType;
};

type SpendVisualizationGroupingPayload = ConsolidatedBillingPayload & {
  grouping: string;
};

type BillingModalPayload = {
  productType?: BillingGroupProductType;
  modalType: string;
  id?: string;
};

type PendingActivityCardViewPayload = ConsolidatedBillingPayload & {
  pendingExpensesCount: number;
  scheduledPaymentsCount: number;
  initiatedPaymentsCount: number;
};

type PendingActivitySectionViewPayload = ConsolidatedBillingPayload & {
  activityType: string;
  count: number;
};

type TransactionDetailPaneViewPayload = ConsolidatedBillingPayload & {
  transactionType: BillingTransactionType;
};

type PaymentFailureBannerViewPayload = ConsolidatedBillingPayload & {
  failureType?: PaymentFailureType;
};

type TransactionsTablePageSizePayload = ConsolidatedBillingPayload & {
  pageSize: number;
};

type TransactionsGroupingChangePayload = ConsolidatedBillingPayload & {
  grouping: string;
};

type TransactionsAddFilterPayload = ConsolidatedBillingPayload & {
  filterName: string;
};

type BillingAlertPayload = {
  alertName: string;
};

type CashInvoicesButtonClickPayload = {
  type: string;
};

type CashInvoicesControllerPayload = {
  name: string;
};

type CashInvoicesNavigatedPayload = {
  from: InvoicesOrigin | string;
  to: string;
  search?: string;
};

type CashInvoicesTableActionItemPayload = {
  invoiceId?: string;
  action?: string;
};

type CashInvoiceTakeoverPayload = {
  step: string;
  flow: string;
};

type CashInvoiceMutationPayload = {
  mutation: string;
  flow?: string;
};

type FirstSpendPayload = {
  expenseId: string;
  isDeepLink: boolean;
  complianceRatingCohort?: ComplianceRatingCohort;
};

type ReimbursementTrackingExpensesExportedPayload = {
  totalNumberOfRetries: number;
  timeToCompletionMs: number;
  succeeded: boolean;
};

type BillingTransactionsDownloadPayload = ConsolidatedBillingPayload & {
  reportFormat: ReportFormatType;
  filtersUsed: string[];
};

type ExternalAttendeeDetailsViewFieldName =
  | "jobTitle"
  | "companyName"
  | "isGovernmentOfficial"
  | "name";

export type ExpenseDetailsPaneExternalAttendeeFormChangePayload = {
  fieldName: ExternalAttendeeDetailsViewFieldName;
  didOverrideInitialValue: boolean;
  formMetadata: {
    triggeredBy: "brexAssistantSuggestion" | "manual";
  };
};

type ExpenseDetailsPanePaymentStatusBannerMountedPayload = {
  expenseStatus: ExpenseStatus | null;
  expenseType: ExpenseType;
  paymentFailureReason?: ReimbursementPaymentFailureReason | null;
  paymentStatusType: ExpensePaymentStatusType | null;
};

type EntitySetupTaskPayload = {
  taskName: EntitySetupTask;
  ctaAction?: string;
};

type EntityBillingInteractionPayload = {
  productType: BillingGroupProductType;
};

type ExportPayload = {
  reportFormat: string;
  isAsync: boolean;
  hasError: boolean;
  retry: boolean;
  entityType: EntityType;
};

type PlanningSetupHelperStepMountedPayload = {
  step: number;
  stepKey?: string;
  hasComeFromIntegrations?: boolean;
  helperType?: BudgetSuggestionsHelperType;
};

type PlanningSetupConnectErpStepRefreshDataClickPayload = {
  taskUpdatedAt: string;
};

type PlanningSetupAccountSelectionStepInitialSuggestionPayload = {
  monthlyAccounts: string[];
  yearlyAccounts: string[];
  helperType: BudgetSuggestionsHelperType;
};

type PlanningSetupBudgetSuggestionsStepInitialSuggestionPayload = {
  suggestedBudgets: Partial<SuggestedBudgetFormType>[];
  helperType: BudgetSuggestionsHelperType;
};

type PlanningSetupHelperBudgetsCreatedPayload = {
  suggestedBudgets: Partial<SuggestedBudgetFormType>[];
  monthlyAccounts: string[];
  yearlyAccounts: string[];
  helperType: BudgetSuggestionsHelperType;
};

type BudgetsPageMembersPillTooltipPayload = {
  budgetId?: string;
  source?: "budget_details_page" | "budget_explorer";
};

export enum BbaPolishModuleClickType {
  SEE_TRANSACTIONS = "see_transactions",
  SEE_STATEMENTS = "see_statements",
  BALANCE_CHART_TIME_DROPDOWN = "balance_chart_time_dropdown",
  ACCOUNTS_TABLE_MONEY_IN = "accounts_table_money_in",
  ACCOUNTS_TABLE_MONEY_OUT = "accounts_table_money_out",
  ACCOUNTS_TABLE_DOWNLOAD_STATEMENT = "accounts_table_download_statement",
  ACCOUNTS_TABLE_MOVE_MONEY = "accounts_table_move_money",
  ACCOUNTS_TABLE_BALANCE = "accounts_table_balance",
  ACCOUNTS_TABLE_NEXT = "accounts_table_next",
  ACCOUNTS_TABLE_PREV = "accounts_table_prev",
  ACCOUNTS_TABLE_ROW_CHANGE = "accounts_table_row_change",
  ACCOUNTS_TABLE_ADD_ACCOUNT = "accounts_table_add_account",
}

type AccountsV2ModuleViewedPayload = {
  moduleTitle: string;
};

type AccountingJournalSelectAllButtonPayload = Is1BrexRearchitecturePayload & {
  reviewStatus: AccountingJournalEntryReviewStatus;
};

type AccountingJournalSelectAllDisabledPayload = {
  hasUnselectedAll: boolean;
  hasUnselectedRows: boolean;
  hasUnselectedDate: boolean;
};

// Banking
export type BankingAddAccountEntryClickedPayload = {
  origin: string;
};

type BankingAccountPickerPayload = {
  accountId?: string;
  allAccounts?: boolean;
  isPrimary?: boolean;
};

export type BankingTableViewPayload = {
  tableView: BankingTableViews;
};

export type BankingTableFilterByPayload = {
  tableView: BankingTableViews;
  filter?: BankingAccountTransactionsFilterType | StatementsFilterType;
  addOrRemove: string;
  extendedFilters?: string;
};

export type FirstTimeExperienceBankingTablePayload = {
  accountType?: DepositsAccountType;
};

export type BankingMigrationWizardPayload = {
  step?: string;
};

export type BankingMigrationWizardDetailsPanePayload = {
  type?: BankingMigrationDetailsPaneTypes;
  step?: MigrationWizardStep;
};

export type BankingMigrationWizardBannerPayload = {
  type?: MigrationWizardStep;
};

export type ManagePaymentMethodsTakeoverPayload = {
  step?: ManageInvoicePaymentsTakeoverStep;
};

type CardsAndLimitsShelfCtaClickedPayload = {
  cta:
    | "create_card"
    | "create_spend_limit"
    | "manage_templates"
    | "manage_company_policy"
    | "manage_programs"
    | "manage_requests"
    | "manage_policies"
    | "spend_migration";
};

type CardsAndLimitsTabClickedPayload = {
  tab: "cards" | "spend_limits";
};

export type CardsAndLimitsCardActionsClickedPayload = {
  action: CardTableAction;
  cardId: string;
};

type CardsAndLimitsCreateCardViewedPayload =
  | {
      step: "selectCardType";
      cardType?: "virtual" | "employee";
      clicked?: "create_spend_limit";
    }
  | {
      step: "virtualCardDetails";
      limitTemplate?: FormTemplateType;
      clicked?:
        | "view_policy"
        | "collapsed_settings"
        | "additional_settings"
        | "create_card_for_existing_spend_limit";
    }
  | { step: "reviewVirtualCardDetails"; clicked: "view_policy" };

type CardsAndLimitsTableFiltersChangedPayload = {
  filterKey: string;
  newValue?: string | string[];
};

type CreateCardsAndLimitStepViewedPayload = {
  step: string;
};

export type CardsAndLimitShelfMountedPayload = {
  templatesSimplificationEnabled: boolean;
  isEssentials: boolean;
};

export type CreateCardsAndLimitCardPollingCompletePayload = {
  success: boolean;
  pollingCount: number;
};

export type GlobalKeyboardShortcutPressedPayload = {
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
  key: string;
  code: string;
};

export type SpendLimitRequestPaneCreateNewLimitClickedPayload = {
  requestTypeId?: string;
};

export type RequestTypesManagementPanePayload = {
  tab?: string;
};

export type RequestTypesManagementPaneReorderPayload =
  RequestTypesManagementPanePayload & {
    fromIndex?: number;
    toIndex?: number;
  };

export type RequestTypesManagementPaneCtaPayload =
  RequestTypesManagementPanePayload & {
    requestTypeId?: string;
    action?: "create" | "edit" | "activate" | "deactivate";
  };

export type RequestTypesManagementPaneDialogCtaPayload =
  RequestTypesManagementPaneCtaPayload & {
    dialogAction?: "confirm" | "cancel";
  };

export type RequestTypesFormPayload = {
  requestTypeId?: string;
  formFlow?: "create" | "edit";
  formStep?:
    | "basics"
    | "approvalChain"
    | "reusableApprovalChain"
    | "budgetNestingRules"
    | "review";
};

export type RequestTypesFormCtaPayload = RequestTypesFormPayload & {
  action?: string;
};

export type RequestTypesFormOperationPayload = RequestTypesFormPayload & {
  operationName?: string;
  operationStatus?: "success" | "failure";
};

export type PolicyManagementPayload = {
  tab?: "active" | "inactive";
};

export type PolicyManagementPolicyDetailsPayload = PolicyManagementPayload & {
  policyId?: string;
};

export type PolicySelectorPayload = {
  location?: string;
};

export type PolicySelectorOptionPayload = PolicySelectorPayload & {
  policyId?: string;
};

type CreditOverviewEducationTileTabChangedPayload = {
  tabName: string;
};

type DeclineProtectionTogglePayload = {
  cardId: string;
};

type AutoTransferVirtualCardToggledPayload = {
  enabled: boolean;
  origin: "card_details_pane";
};

type DataGridDeepLinkParamTypesPayload = {
  paramTypes: DataGridDeepLinkingParamType[];
};

type SpendLimitGroupBasePayload = {
  spendLimitGroupId?: string;
};

type SpendLimitDetailsPayload = {
  id: string;
  type: "spend-limit" | "limit-group";
};

type SpendLimitDetailsActionsPayload = SpendLimitDetailsPayload & {
  actionPermissions: ActionPermissions;
};

type SpendLimitDetailsActionPayload = SpendLimitDetailsPayload & {
  action: SpendLimitDetailsActionsType;
};

type SpendLimitActionPanePayload = SpendLimitDetailsPayload & {
  action: SpendLimitDetailsActionsType;
};
type SpendLimitDetailsDatagridRowSelectedPayload = SpendLimitDetailsPayload & {
  grid: "expenses" | "cards" | "limits";
  rowId: string;
};
type SpendLimitDetailsFragmentErrorPayload = SpendLimitDetailsPayload & {
  fragment: string;
};

export type SpendLimitGroupFormOverridesPayload = SpendLimitGroupBasePayload & {
  formFlow: "create" | "edit";
  spendLimitGroupId?: string;
  overrideType: Omit<keyof LimitGroupOverrides, "__typename">;
};

export type PrimitivesEventSchemaMap = {
  [PrimitivesAnalyticsEvents.BillPayApproverOnboardingStarted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BillPayApproverOnboardingContactSupport]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BillPaySetupStarted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsAddCashAccountsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsNewAccountsSurfaceViewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsAddExternalAccountClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsCreditRowItemClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsCashRowItemClicked]: AccountsRowItemClickedPayload;
  [PrimitivesAnalyticsEvents.AccountsReimbursementsRowItemClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsExternalAccountSettingsViewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsExternalAccountPageViewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsExternalAccountTableRowClicked]: AccountsRowItemClickedPayload;
  [PrimitivesAnalyticsEvents.AccountsCreditL2Viewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsCashL2Viewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsMoveMoneyMenuClicked]: MoveMoneyPayload;
  [PrimitivesAnalyticsEvents.AccountsMoveMoneySendMoneyClicked]: MoveMoneyPayload;
  [PrimitivesAnalyticsEvents.AccountsMoveMoneyPayAVendorClicked]: MoveMoneyPayload;
  [PrimitivesAnalyticsEvents.AccountsMoveMoneyDepositMoneyClicked]: MoveMoneyPayload;
  [PrimitivesAnalyticsEvents.AccountsMoveMoneyTransferFundsClicked]: MoveMoneyPayload;
  [PrimitivesAnalyticsEvents.AccountsMoveMoneyLinkPayrollProviderClicked]: MoveMoneyPayload;
  [PrimitivesAnalyticsEvents.AccountsMoveMoneyPayCreditBillClicked]: MoveMoneyPayload;
  [PrimitivesAnalyticsEvents.AccountsMoveMoneyReverseWireClicked]: MoveMoneyPayload;
  [PrimitivesAnalyticsEvents.AccountsInsightsL2Viewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsTimezoneBannerDismissClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsTimezoneBannerEditClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsTimezoneModalOpen]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsTimezoneUpdated]: TimezoneUpdatedPayload;
  [PrimitivesAnalyticsEvents.AccountsCashCardMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsCashCardClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsAddCashAccountClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsConnectExternalAccountClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsRemoveBankConnectionClicked]: AccountManageClickedPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsRemoveBankAccountClicked]: AccountManageClickedPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsFixBankConnectionClicked]: AccountManageClickedPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsManageAutoPayClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsAddBankAccountsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsAddUnderwritingAccountClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsAddManualAccountClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsSetUpReimbursementsClicked]: AccountManageClickedPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsVerifyProofOfOwnershipClicked]: AccountManageClickedPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsLocalAutopayVerifyPennyPullClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsLocalAutopayVerifyMicroDepositsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsLocalAutopayVerifyPoaoClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsLocalAutopayNewAccountClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsLocalAutopayEnablementBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsLocalAutopayEnablementBannerDismissed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsManageAccountsLocalAutopayEnablementBannerLearnMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsV2ModuleViewed]: AccountsV2ModuleViewedPayload;
  [PrimitivesAnalyticsEvents.AccountsV2CreditModuleClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsV2ReimbursementsModuleClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsV2BusinessAccountsModuleClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsV2BusinessAccountsModuleDepositFundsButtonClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsV2ExternalAccountsModuleClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsV2DepositFundsModuleReceiveWireClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsV2DepositFundsModuleDepositFundsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountsV2ModuleOptionClicked]: AccountsV2ModuleOptionClickedPayload;

  [PrimitivesAnalyticsEvents.UsersTimezoneModalOpen]: EmptyPayload;
  [PrimitivesAnalyticsEvents.UsersTimezoneUpdated]: TimezoneUpdatedPayload;
  // ACCOUNTING
  // Accounting L1 Overview
  [PrimitivesAnalyticsEvents.AccountingOverviewMounted]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewErrorMounted]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewProgressBarMonthPickerUpdated]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManagePrepareClicked]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageReviewExportClicked]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageExportHistoryMounted]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageExportHistoryClicked]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageExportHistoryLaunchSpotlightMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageExportHistoryLaunchSpotlightDismissalClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageAccountingFieldsClicked]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageCustomRulesClicked]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageFundingSourcesClicked]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewManageSettingsClicked]: AccountingOverviewPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewOnboardingBannerClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewSetupAccountingGetStartedCTAClicked]: AccountingOnboardingGetStartedPayload;
  [PrimitivesAnalyticsEvents.AccountingOverviewSetupAccountingContinueCTAClicked]: IsDatagridPayload;
  [PrimitivesAnalyticsEvents.AccountingJobsMenuMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingJobsMenuOpened]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingJobsMenuClearAll]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingJobsMenuItemExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingJobsMenuItemDismissed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingJobsMenuItemDownloadClicked]: EmptyPayload;
  // Accounting L2
  [PrimitivesAnalyticsEvents.AccountingFieldsMounted]: AccountingPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsMappingsMounted]: AccountingPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsCreateMounted]: AccountingPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesMounted]: AccountingPayload;
  [PrimitivesAnalyticsEvents.AccountingFundingSourcesMounted]: AccountingPayload;
  [PrimitivesAnalyticsEvents.AccountingPrepareMounted]: AccountingPayload;
  [PrimitivesAnalyticsEvents.AccountingReviewExportMounted]: AccountingPayload;
  [PrimitivesAnalyticsEvents.AccountingEntitySettingsMounted]: AccountingPayload;
  [PrimitivesAnalyticsEvents.AccountingIntegrationAlertCtaClicked]: AccountingIntegrationAlertCtaPayload;
  // Accounting L3
  [PrimitivesAnalyticsEvents.AccountingOnboardingSetupEntryScreenMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSetupEntryScreenContinueClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSetupEntryScreenBackClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupStartStepMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupStartStepBackClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupStartStepNextClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountSetupStepMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountSetupStepCSVDownloaded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountSetupStepCSVUploaded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountSetupStepOptionToggled]: AccountingSetupGlAccountsEntryMethodPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountSetupStepBackClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountSetupStepNextClicked]: AccountingSetupGlAccountsEntryMethodPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountConfirmStepMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountConfirmStepBackClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountConfirmStepSearched]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountConfirmStepAddValueClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountConfirmStepEditClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountConfirmStepDeleteClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountConfirmStepConfirmClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewGLDetailsStepMounted]: AccountingSetupGLDetailsStepMountedPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewGLDetailsStepContinueClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewGLDetailsStepBackClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingMultiEntityEducationStepMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingMultiEntityEducationStepContinueClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingMultiEntityEducationStepBackClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingMultiEntityEducationExitClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingGenerateSuggestionsStepMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingGenerateSuggestionsExitClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountCreateSuccessStepMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingManualSetupGLAccountCreateSuccessStepNextClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSetupGLMappingsFlowEntryStepMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSetupGLMappingsFlowEntryStepContinueClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSetupGLMappingsFlowEntryStepBackClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingERPSetupReviewExportSettingsStepMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingERPSetupReviewReviewExportSettingsStepContinueClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingERPSetupReviewReviewExportSettingsStepBackClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewSuggestedMappingsStepMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewSuggestedMappingsStepContinueClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewSuggestedMappingsStepBackClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewSuggestedMappingsStepExitClicked]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewSuggestedMappingsStepIntegrationRuleUpdatesSaved]: AccountingSetupUpdateIntegrationRulesPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingReviewSuggestedMappingsStepSuggestionsSaved]: AccountingSetupCreateSuggestionsPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingChooseUserScreenMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingChooseUserScreenCancelClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingChooseUserScreenContinueClicked]: AccountingOnboardingChooseUserTypePayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSelectTeamMemberScreenMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSelectTeamMemberScreenNoEligibleTeamMembers]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSelectTeamMemberScreenTeamPageLinkClicked]: AccountingOnboardingSelectTeamMemberTeamPageLinkClicked;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSelectTeamMemberScreenBackClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSelectTeamMemberScreenNotifyClicked]: AccountingOnboardingSelectTeamMemberPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSelectAccountingFirmScreenMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSelectAccountingFirmScreenBackClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingSelectAccountingFirmScreenNotifyClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingIntegrationCreated]: AccountingOnboardingIntegrationCreatedPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingIntegrationCreatingLearnMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingOnboardingContinueClicked]: EmptyPayload;
  // Custom Rules Events
  [PrimitivesAnalyticsEvents.AccountingCustomRulesListTableMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneMounted]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneInputMounted]: CustomRuleInputPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneCloseClicked]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneDeleteRuleModalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneDeleteRuleModalCancel]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneDeleteRuleModalConfirm]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneDeleteRuleClicked]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneCreateRuleClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneEditRuleClicked]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneSetCustomRuleName]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneDateRangeFilterSelected]: FilterPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneCategoryOptionChange]: OptionInputPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneOptionInputChange]: OptionInputPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneBudgetOptionInputChange]: BudgetOptionInputType;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneBudgetApplyNestedChange]: BudgetOptionInputType;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneSaveRuleClicked]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesAddRuleFieldClicked]: CategoryFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesLearnMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSummaryCardsManageClicked]: AccountingCardPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSummaryCardsMetricsMounted]: AccountingCardPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSummaryCardsMetricsClicked]: AccountingCardPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneConfirmActionModalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneConfirmActionModalCancel]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesPaneConfirmActionModalConfirm]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesCreateRuleToastSuccessMounted]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesCreateRuleToastFailedMounted]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesUpdateRuleToastSuccessMounted]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesUpdateRuleToastFailedMounted]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesDeleteRuleToastSuccessMounted]: CustomRulePayload;
  [PrimitivesAnalyticsEvents.AccountingCustomRulesDeleteRuleToastFailedMounted]: CustomRulePayload;
  // Accounting Fields Events
  [PrimitivesAnalyticsEvents.AccountingFieldsMappingsSectionMounted]: AccountingMappingsSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsDownloadCSVSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsDownloadCSVFailure]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditVisibilityMounted]: AccountingFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditVisibilityByExpenseType]: AccountingFieldVisibilityPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditVisibilityByRole]: AccountingFieldVisibilityPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditVisibilityByOptionToggle]: AccountingFieldVisibilityPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditVisibilityByOptionFilterIf]: AccountingFieldVisibilityPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditVisibilityByOptionAddCondition]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditVisibilitySave]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditVisibilityBack]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsDetailsPaneEditVisibilityClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditTakeoverMounted]: AccountingTakeoverPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditTakeoverCSVDownloadSuccess]: AccountingFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditTakeoverCSVDownloadFailure]: AccountingFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditTakeoverCSVUploadSuccess]: AccountingFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditTakeoverSaveSuccess]: AccountingFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsEditTakeoverSaveFailure]: AccountingFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsDetailsPaneValueEditName]: AccountingFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingFieldsDetailsPaneValueDelete]: AccountingFieldPayload;
  // Streamlined Rules Events
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsTourMounted]: Pick<
    StreamlinedSuggestionsTourPayload,
    "stepIndex"
  >;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsTourForwardClicked]: StreamlinedSuggestionsTourPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsTourDismissClicked]: Omit<
    StreamlinedSuggestionsTourPayload,
    "fromAnchor"
  >;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsTourCompleteClicked]: Pick<
    StreamlinedSuggestionsTourPayload,
    "fromAnchor"
  >;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersSuggestionsButtonMounted]: StreamlinedSuggestionsButtonPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersSuggestionsButtonClicked]: StreamlinedSuggestionsButtonPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsDeclineToastFailedMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsPaneMounted]: StreamlinedSuggestionsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsPaneCloseClicked]: Omit<
    StreamlinedSuggestionsPanePayload,
    "ids"
  >;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsPaneMappingAcceptClicked]: SuggestionAcceptancePayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsPaneMappingDeclineClicked]: StreamlinedSuggestionsBasePayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsPaneRuleViewClicked]: StreamlinedSuggestionsBasePayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedSuggestionsPaneRuleDeclineClicked]: StreamlinedSuggestionsBasePayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedMappingsAcceptToastSuccessMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedMappingsAcceptToastFailedMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedRulePaneMounted]: StreamlinedSuggestionsBasePayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedRulePaneCloseClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedRulePaneCreateClicked]: SuggestionAcceptancePayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedRulesAcceptToastSuccessMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedRulesAcceptToastFailedMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingStreamlinedRuleInvalid]: StreamlinedRuleInvalidPayload;
  // Accounting Custom Report Accounting Education Banner Events
  [PrimitivesAnalyticsEvents.AccountingCustomReportAccountingBannerMounted]: AccountingCustomReportAccountingBannerPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomReportAccountingBannerAccountingLinkClicked]: AccountingCustomReportAccountingBannerPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomReportAccountingBannerIsTargetForEducation]: AccountingCustomReportAccountingBannerIsTargetPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomReportAccountingBannerDoNotShowAgainClicked]: AccountingCustomReportAccountingBannerDoNotShowAgainPayload;
  [PrimitivesAnalyticsEvents.AccountingCustomReportAccountingBannerDoNotShowAgainErrorMounted]: AccountingCustomReportAccountingBannerDoNotShowAgainPayload;
  // AccountingEntityPane - TasuggestionId section
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionToggled]: AccountingEntityDetailsPaneTaxCodeSectionFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionToggledFailed]: AccountingEntityDetailsPaneTaxCodeSectionFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionOpened]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionLegalEntityFetchErrorMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionVatExtendedFieldsFetchErrorMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionMappingConflictBannerMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionSubsidiaryInactiveBannerMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionUnmappedLegalEntityBannerMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionNoVatCodesBannerMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionGenericErrorMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionOptionsListMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionOptionToggled]: AccountingEntityDetailsPaneTaxCodeSectionOptionsPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionOptionToggledFailed]: AccountingEntityDetailsPaneTaxCodeSectionOptionsPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsPaneTaxCodeSectionGLAccountWarningBannerMounted]: AccountingEntityDetailsPaneTaxCodeSectionPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsTaxCodeSectionUpdateTaxPurchaseAccountsUpdated]: AccountingEntityDetailsPaneTaxCodeSectionUpdateTaxPurchaseAccountsPayload;
  [PrimitivesAnalyticsEvents.AccountingEntityDetailsTaxCodeSectionUpdateTaxPurchaseAccountsFailed]: AccountingEntityDetailsPaneTaxCodeSectionUpdateTaxPurchaseAccountsPayload;
  // AccountingExpenseAccruedDateField (details pane)
  [PrimitivesAnalyticsEvents.AccountingExpenseAccruedDateFieldMounted]: AccountingExpenseAccruedDateFieldPayload;
  // AccountingExpenseVatField
  [PrimitivesAnalyticsEvents.AccountingExpenseVatFieldMounted]: AccountingExpenseVatFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingExpenseVatFieldRateInputFocused]: AccountingExpenseVatFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingExpenseVatFieldRateInputChanged]: AccountingExpenseVatFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingExpenseVatFieldAmountInputFocused]: AccountingExpenseVatFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingExpenseVatFieldAmountInputChanged]: AccountingExpenseVatFieldPayload;
  // AccountingExportHistory
  [PrimitivesAnalyticsEvents.AccountingExportHistoryMounted]: AccountingPayload;
  // AccountingExportHistory - Tabs
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTabsLayoutMounted]: AccountingExportHistoryTabsPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTabClicked]: AccountingExportHistoryTabsPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTabEducationSeen]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTabEducationCompleted]: AccountingExportHistoryTabEducationCompletedPayload;
  // AccountingExportHistory - Table
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableLayoutMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableErrorMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableErrorRetryClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableRowClicked]: AccountingExportHistoryTableRowPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableSeeMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableErpDeeplinkClicked]: AccountingExportHistoryTableErpDeeplinkPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableDownloadCsvClicked]: AccountingExportHistoryTableRowMenuPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableAmountClicked]: AccountingExportHistoryTableRowMenuPayload;
  // AccountingExportHistory - Sorting
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTableColumnSortClicked]: AccountingExportHistoryTableColumnSortPayload;
  // AccountingExportHistory - Filters
  [PrimitivesAnalyticsEvents.AccountingExportHistoryFiltersDateFromSelected]: AccountingExportHistoryFiltersPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryFiltersDateToSelected]: AccountingExportHistoryFiltersPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryFiltersStatusSelected]: AccountingExportHistoryFiltersPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryFiltersTypeMounted]: AccountingExportHistoryFiltersPayload;
  // AccountingExportHistory - Details Pane
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneMounted]: AccountingExportHistoryDetailsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneCloseClicked]: AccountingExportHistoryDetailsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneStatusBannerMounted]: AccountingExportHistoryDetailsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneErrorMounted]: AccountingExportHistoryDetailsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneErrorRetryClicked]: AccountingExportHistoryDetailsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneSendBackClicked]: AccountingExportHistoryDetailsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneViewUnexportedClicked]: AccountingExportHistoryDetailsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneViewExportedClicked]: AccountingExportHistoryDetailsPanePayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryDetailsPaneViewSentBackClicked]: AccountingExportHistoryDetailsPanePayload;
  // AccountingExportHistory - Routing modal
  [PrimitivesAnalyticsEvents.AccountingExportHistoryRoutingModalMounted]: AccountingExportHistoryRoutingModalPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryRoutingModalCloseClicked]: AccountingExportHistoryRoutingModalPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryRoutingModalViewReviewClicked]: AccountingExportHistoryRoutingModalPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistoryRoutingModalViewReadyForExportClicked]: AccountingExportHistoryRoutingModalPayload;
  // AccountingExportHistory - Send back modal
  [PrimitivesAnalyticsEvents.AccountingExportHistorySendBackModalMounted]: AccountingExportHistorySendBackModalPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistorySendBackModalSendBackClicked]: AccountingExportHistorySendBackModalPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistorySendBackModalCancelClicked]: AccountingExportHistorySendBackModalPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistorySendBackModalToastSuccessMounted]: AccountingExportHistorySendBackModalPayload;
  [PrimitivesAnalyticsEvents.AccountingExportHistorySendBackModalErrorMounted]: AccountingExportHistorySendBackModalPayload;
  // AccountingExportHistory - Export Drilldown
  [PrimitivesAnalyticsEvents.AccountingExportHistoryExportDrilldownMounted]: AccountingExportHistoryBasePayload;
  // AccountingExportHistory - Transactions Table
  [PrimitivesAnalyticsEvents.AccountingExportHistoryTransactionsTableErpDeeplinkClicked]: AccountingExportHistoryBasePayload;
  // AccountingExportHistory - Expense Details Pane
  [PrimitivesAnalyticsEvents.AccountingExportHistoryExpenseDetailsPaneErpDeeplinkClicked]: AccountingExportHistoryBasePayload;
  // AccountingJournal
  [PrimitivesAnalyticsEvents.AccountingJournalMounted]: AccountingJournalCorePayload;
  // AccountingJournal - Table Core
  [PrimitivesAnalyticsEvents.AccountingJournalTableErrorMounted]: AccountingJournalCorePayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableErrorRetryClicked]: AccountingJournalCorePayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableRowChecked]: AccountingJournalTableCheckboxPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableRowClicked]: AccountingJournalRowPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableRowFieldChanged]: AccountingJournalFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableRowFieldCleared]: AccountingJournalFieldPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableSeeMoreClicked]: AccountingJournalCorePayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableSelectAllRowsClicked]: Pick<
    AccountingJournalTableCheckboxPayload,
    "checked" | "is1BrexRearch" | "isMetalTable" | "reviewStatus"
  >;
  [PrimitivesAnalyticsEvents.AccountingJournalTableReloadClicked]: AccountingJournalCorePayload;
  // AccountingJournal - Bulk Edit
  [PrimitivesAnalyticsEvents.AccountingJournalBulkEditModalMounted]: AccountingJournalBulkEditPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalBulkEditModalEditClicked]: AccountingJournalBulkEditPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalBulkEditModalCancelClicked]: AccountingJournalBulkEditPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalBulkEditModalToastSuccessMounted]: AccountingJournalBulkEditPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalBulkEditModalToastErrorMounted]: AccountingJournalBulkEditPayload;
  // AccountingJournal - Bulk Edit Education
  [PrimitivesAnalyticsEvents.AccountingJournalBulkEditEducationToastMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalBulkEditEducationToastDismissedClicked]: AccountingJournalBulkEditEducationPayload;
  // AccountingJournal - Bulk Suggestion
  [PrimitivesAnalyticsEvents.AccountingJournalBulkSuggestionModalMounted]: AccountingJournalBulkEditPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalBulkSuggestionModalCreateNewRuleClicked]: AccountingJournalBulkEditPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalBulkSuggestionModalCancelClicked]: AccountingJournalBulkEditPayload;
  // AccountingJournal - Column Settings
  [PrimitivesAnalyticsEvents.AccountingJournalTableColumnSettingsMounted]: AccountingJournalColumnSettingsPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableColumnSettingsToggleOnClicked]: AccountingJournalColumnSettingsPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableColumnSettingsToggleOffClicked]: AccountingJournalColumnSettingsPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableColumnSettingsColumnDragged]: AccountingJournalColumnSettingsPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableColumnSettingsWarningMounted]: AccountingJournalColumnSettingsPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalTableColumnSettingsWarningClicked]: AccountingJournalColumnSettingsPayload;
  // AccountingJournal - Column Sort
  [PrimitivesAnalyticsEvents.AccountingJournalTableColumnSortClicked]: AccountingJournalTableColumnSortPayload;
  // AccountingJournal - Filters
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersSearchBySelected]: SearchByPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersDateSelected]: FilterPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersUserCategorySelected]: FilterPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersTypeSelected]: FilterPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersAlertSelected]: FilterPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersAmountSelected]: FilterPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersBudgetSelected]: FilterPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersSpendingEntitySelected]: FilterPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalFiltersCustomRuleSelected]: FilterPayload;
  // AccountingJournal - Expense Category Inline Edit Alert
  [PrimitivesAnalyticsEvents.AccountingJournalExpenseCategoryInlineEditAlertMounted]: IsDatagridPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalExpenseCategoryInlineEditAlertSaveClicked]: IsDatagridPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalExpenseCategoryInlineEditAlertCancelClicked]: IsDatagridPayload;
  // AccountingJournal - Real Select All
  [PrimitivesAnalyticsEvents.AccountingJournalSelectAllButtonMounted]: AccountingJournalSelectAllButtonPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalSelectAllButtonSelectClicked]: AccountingJournalSelectAllButtonPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalSelectAllButtonClearClicked]: AccountingJournalSelectAllButtonPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalSelectAllDisabled]: AccountingJournalSelectAllDisabledPayload;
  // AccountingJournal - Status Changing
  [PrimitivesAnalyticsEvents.AccountingJournalMarkReadyClicked]: AccountingJournalStatusChangePayload;
  [PrimitivesAnalyticsEvents.AccountingJournalSendBackToReviewClicked]: AccountingJournalStatusChangePayload;
  [PrimitivesAnalyticsEvents.AccountingJournalExportClicked]: AccountingJournalStatusChangePayload;
  // AccountingJournal - Status Changing Toasts
  [PrimitivesAnalyticsEvents.AccountingJournalReviewStatusChangeToastSuccessMounted]: AccountingJournalStatusChangeToastPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReviewStatusChangeToastFailedMounted]: AccountingJournalStatusChangeToastPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReviewStatusChangeToastFailedVerificationMounted]: AccountingJournalStatusChangeToastPayload;
  // Accounting Journal - Quick CSV
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalTriggerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalTriggerClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalMounted]: IsDatagridPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalCloseClicked]: IsDatagridPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalFormatChanged]: AccountingJournalReportEducationFormatChangedPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalDownloadClicked]: AccountingJournalReportEducationDownloadPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalDownloadError]: Partial<AccountingJournalReportEducationDownloadPayload>;
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalManualDownloadClicked]: IsDatagridPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalReportModalRetryDownloadClicked]: IsDatagridPayload;
  // Accounting Journal - Export Total Amounts
  [PrimitivesAnalyticsEvents.AccountingJournalExportTotalAmountsMounted]: AccountingAccrualsExportPreviewSummaryPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalExportTotalAmountsError]: EmptyPayload;
  // AccountingJournalDatagrid - Bulk Edit
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridBulkEditBannerMounted]: AccountingJournalDatagridBulkEditBannerPayload;
  // AccountingJournalDatagrid - Shell
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridMounted]: AccountingJournalDatagridMountedPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridShelfClicked]: AccountingJournalDatagridShelfPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridTabClicked]: AccountingJournalDatagridTabClickPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridShelfErpMissingGLsMounted]: EmptyPayload;
  // AccountingJournalDatagrid - Persisted view updates
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridApplyPersistedView]: AccountingJournalDatagridApplyPersistedViewPayload;
  // AccountingJournalDatagrid - Inline row updates
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridTableRowFieldChanged]: AccountingJournalDatagridInlineEditPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridTableRowFieldChangedErrorMounted]: AccountingJournalDatagridInlineEditPayload;
  // AccountingJournalDatagrid - Review status movement
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridReviewStatusUpdateButtonClicked]: AccountingJournalDatagridReviewStatusPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridReviewStatusErrorToastMounted]: AccountingJournalDatagridReviewStatusErrorToastPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridReviewStatusSuccessToastMounted]: AccountingJournalDatagridReviewStatusPayload;
  // AccountingJournalDatagrid - Accruals
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridFTUXControllerMounted]: AccountingJournalDatagridAccrrualsFTUXPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridUpdateAccrualReversalDateClicked]: AccountingJournalDatagridAccrualReversalDatePayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridUpdateAccrualReversalDateSuccess]: AccountingJournalDatagridAccrualReversalDatePayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridUpdateAccrualReversalDateFailure]: AccountingJournalDatagridAccrualReversalDatePayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridUpdateAccrualStatusClicked]: AccountingJournalDatagridAccrualStatusPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridUpdateAccrualStatusSuccess]: AccountingJournalDatagridAccrualStatusPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridUpdateAccrualStatusFailure]: AccountingJournalDatagridAccrualStatusPayload;
  [PrimitivesAnalyticsEvents.AccountingJournalDatagridUpdateAccrualStatusPartialFailure]: AccountingJournalDatagridAccrualStatusCountPayload;
  [PrimitivesAnalyticsEvents.AccountingAccrualsExportPreviewSummary]: AccountingAccrualsExportPreviewSummaryPayload;
  // Accounting Automation - Home
  [PrimitivesAnalyticsEvents.AccountingAutomationHubMounted]: AccountingAutomationBasePayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationHubViewAllClicked]: AccountingAutomationHomePayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSegmentedControllerMounted]: AccountingAutomationSegmentedControllerPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSegmentedControllerTabClicked]: AccountingAutomationSegmentedControllerPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSegmentedControllerMappingCreateClicked]: AccountingAutomationSegmentedControllerPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationInvalidSuggestion]: AccountingAutomationInvalidSuggestionPayload;
  // Accounting Automation - ML Suggestion
  [PrimitivesAnalyticsEvents.AccountingAutomationMlSuggestionCustomizeClicked]: AccountingAutomationSuggestionBasePayload;
  // Accounting Automation - Manual Suggestion
  [PrimitivesAnalyticsEvents.AccountingAutomationManualSuggestionCreateDifferentClicked]: AccountingAutomationManaulSuggestionPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationManualSuggestionCreateClicked]: AccountingAutomationManaulSuggestionPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationManualSuggestionOptionValueClicked]: AccountingAutomationManaulSuggestionPayload;
  // Accounting Automation - Suggestion Card
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionCardMounted]: AccountingAutomationSuggestionCardPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionCardReviewRuleClicked]: AccountingAutomationSuggestionCardPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionCardSuggestionActionClicked]: AccountingAutomationSuggestionCardPayload;
  // Accounting Automation - Suggestions Takeover
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverEmptyBannerMounted]: AccountingAutomationSuggestionsTakeoverBannerPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptBannerMounted]: AccountingAutomationSuggestionsTakeoverAcceptBannerPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptBannerViewClicked]: AccountingAutomationSuggestionsTakeoverAcceptBannerPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptBannerCloseClicked]: AccountingAutomationSuggestionsTakeoverAcceptBannerPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverErrorMounted]: AccountingAutomationSuggestionsTakeoverBannerPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverFilterSelected]: AccountingAutomationSuggestionsTakeoverFiltersPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptVisibleClicked]: AccountingAutomationSuggestionsTakeoverBulkAcceptPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptVisibleConfirmClicked]: AccountingAutomationSuggestionsTakeoverBulkAcceptPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptVisibleCancelClicked]: AccountingAutomationSuggestionsTakeoverBulkAcceptPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptAllClicked]: AccountingAutomationSuggestionsTakeoverBulkAcceptPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptAllConfirmClicked]: AccountingAutomationSuggestionsTakeoverBulkAcceptPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverAcceptAllCancelClicked]: AccountingAutomationSuggestionsTakeoverBulkAcceptPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverConfirmBulkAcceptMounted]: AccountingAutomationSuggestionsTakeoverBulkAcceptPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationSuggestionsTakeoverSeeMoreClicked]: AccountingAutomationSuggestionsTakeoverPayload;
  // Accounting Automation - Update Toasts
  [PrimitivesAnalyticsEvents.AccountingAutomationToastSuccessMounted]: AccountingAutomationToastPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationToastSuccessManageClicked]: AccountingAutomationToastPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationToastErrorMounted]: AccountingAutomationToastPayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationToastErrorDeclineMounted]: AccountingAutomationToastPayload;
  // Accounting Automation - Accounting Journal Cell Create rule
  [PrimitivesAnalyticsEvents.AccountingAutomationAccountingJournalCellCreateMlRuleClicked]: AccountingAutomationAccountingJournalCellCreateRulePayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationAccountingJournalCellCreateManualRuleClicked]: AccountingAutomationAccountingJournalCellCreateRulePayload;
  [PrimitivesAnalyticsEvents.AccountingAutomationAccountingJournalShelfSuggestionsCountMounted]: AccountingAutomationAccountingJournalShelfSuggestionsCountCreateRulePayload;
  // Accounting Automation - FTUX and Pre AJ Datagrid
  [PrimitivesAnalyticsEvents.AccountingAutomationPreDatagridSuggestionCardMounted]: AccountingAutomationFtuxAndPreDatagrid;
  // Accounting Expense Category Automation
  [PrimitivesAnalyticsEvents.AccountingExpenseCategoryDropdownPrefilledIconMounted]: AccountingExpenseCategoryPayload;
  [PrimitivesAnalyticsEvents.AccountingExpenseCategoryDropdownOpened]: AccountingExpenseCategoryPayload;
  [PrimitivesAnalyticsEvents.AccountingExpenseCategoryDropdownSelected]: AccountingExpenseCategorySelectionPayload;
  // Accounting WorkflowRule Impacted Transactions Table
  [PrimitivesAnalyticsEvents.AccountingWorkflowRuleImpactedTransactionsTableMounted]: AccountingWorkflowRuleImpactedTransactionsPayload;
  [PrimitivesAnalyticsEvents.AccountingWorkflowRuleImpactedTransactionsClosePreviewClicked]: AccountingWorkflowRuleImpactedTransactionsClickedPayload;
  [PrimitivesAnalyticsEvents.AccountingWorkflowRuleImpactedTransactionsHidePreviewClicked]: AccountingWorkflowRuleImpactedTransactionsClickedPayload;
  [PrimitivesAnalyticsEvents.AccountingWorkflowRuleImpactedTransactionsOpenPreviewClicked]: AccountingWorkflowRuleImpactedTransactionsClickedPayload;
  [PrimitivesAnalyticsEvents.AccountingWorkflowRuleImpactedTransactionsTogglePreviewClicked]: AccountingWorkflowRuleImpactedTransactionsClickedPayload;
  // Audit Trail - Security L1
  [PrimitivesAnalyticsEvents.AuditTrailRowClicked]: AuditTrailRowClickPayload;
  [PrimitivesAnalyticsEvents.AuditTrailFilterApplied]: AuditTrailFilterPayload;
  [PrimitivesAnalyticsEvents.AuditTrailPersistedViewsApplied]: AuditTrailFilterPayload;
  [PrimitivesAnalyticsEvents.AuditTrailSearchApplied]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AuditTrailSeeMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AuditTrailTableMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AuditTrailDetailPaneDeepLinkClicked]: AuditTrailDeepLinkPayload;
  [PrimitivesAnalyticsEvents.AuditTrailDownloadClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.SecurityShelfCtaClicked]: SecurityShelfCtaClickedPayload;
  // AsyncExport - Banners
  [PrimitivesAnalyticsEvents.AsyncExportBannerMounted]: AsyncExportBannerPayload;
  [PrimitivesAnalyticsEvents.AsyncExportBannerDismissClicked]: AsyncExportBannerPayload;
  [PrimitivesAnalyticsEvents.AsyncExportBannerDownloadClicked]: AsyncExportBannerPayload;
  // AsyncExport - Selector
  [PrimitivesAnalyticsEvents.AsyncExportSelectorMounted]: AsyncExportSelectorPayload;
  [PrimitivesAnalyticsEvents.AsyncExportSelectorCheckboxClicked]: AsyncExportSelectorPayload;
  // Banking
  [PrimitivesAnalyticsEvents.BankingACHRemainingLimitExceeded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingAddAccountEntryClicked]: BankingAddAccountEntryClickedPayload;
  [PrimitivesAnalyticsEvents.BankingAddAccountMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingAccountPickerClicked]: BankingAccountPickerPayload;
  [PrimitivesAnalyticsEvents.BankingAddAccountSubmitted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingFDICSweepLearnMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingIntlWireInstructionRequirementsHCArticleClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingTableViewClicked]: BankingTableViewPayload;
  [PrimitivesAnalyticsEvents.BankingTableFilterByClicked]: BankingTableFilterByPayload;
  [PrimitivesAnalyticsEvents.FirstTimeExperienceBankingTableAddFundsClicked]: FirstTimeExperienceBankingTablePayload;
  [PrimitivesAnalyticsEvents.FirstTimeExperienceBankingTableTransferFundsClicked]: FirstTimeExperienceBankingTablePayload;
  [PrimitivesAnalyticsEvents.FirstTimeExperienceBankingTableLearnMoreClicked]: FirstTimeExperienceBankingTablePayload;
  // Banking - Migration Wizard
  [PrimitivesAnalyticsEvents.BankingMigrationWizardMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingMigrationWizardExitClicked]: BankingMigrationWizardPayload;
  [PrimitivesAnalyticsEvents.BankingMigrationWizardStartClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingMigrationWizardPrimaryClicked]: BankingMigrationWizardPayload;
  [PrimitivesAnalyticsEvents.BankingMigrationWizardSecondaryClicked]: BankingMigrationWizardPayload;
  [PrimitivesAnalyticsEvents.BankingMigrationWizardDetailsPaneMounted]: BankingMigrationWizardDetailsPanePayload;
  [PrimitivesAnalyticsEvents.BankingMigrationWizardBannerCtaClicked]: BankingMigrationWizardBannerPayload;
  [PrimitivesAnalyticsEvents.BankingMigrationWizardExitModalStayHereClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingMigrationWizardSuccessToastMounted]: BankingMigrationWizardPayload;
  // Banking - Principal Protection Fees
  [PrimitivesAnalyticsEvents.BankingPProFeesManagePaymentMethodsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingPProFeesManagePaymentMethodsTakeoverMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingPProFeesManagePaymentMethodsTakeoverExited]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BankingPProFeesManagePaymentMethodsTakeoverPrimaryClicked]: ManagePaymentMethodsTakeoverPayload;
  // BUDGETS
  // Auto Limit Assignment
  [PrimitivesAnalyticsEvents.AutoLimitAssignmentFtuxViewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AutoLimitAssignmentFtuxNotNowClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AutoLimitAssignmentFtuxEnableClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AutoLimitAssignmentFtuxLearnMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AutoLimitAssignmentFtuxDismissed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AutoLimitAssignmentToggleViewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AutoLimitAssignmentToggleEnabled]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AutoLimitAssignmentToggleDisabled]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BifurcatedExperimentViewed]: EmptyPayload;
  // Budget Details
  [PrimitivesAnalyticsEvents.BudgetDrilldownTripDetailsClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownGroupEventDetailsClicked]: BudgetIdPayload;
  // Budget Details Pane
  [PrimitivesAnalyticsEvents.BudgetDetailsPaneMounted]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDetailsPaneDefaultErrorMounted]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetMembersOwnersDetailsPaneMounted]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetMembersOwnersDetailsPaneEditMounted]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetMembersOwnersDetailsPaneEditClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetMembersOwnersDetailsPaneEditCancelClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetMembersOwnersDetailsPaneEditSaveClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetMembersOwnersDetailsPaneEditSaveError]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetMembersOwnersDetailsPaneEditSaveSuccess]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetMembersOwnersDetailsPaneOwnerClicked]: BudgetIdPayload &
    UserIdPayload;
  // Budget Form V2
  [PrimitivesAnalyticsEvents.BudgetFormInsufficientUserPermissionsRedirected]: BudgetAttributesFormPayload;
  // Budget Requests (v3)
  [PrimitivesAnalyticsEvents.BudgetRequestFormSelectBudgetTemplateStepMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormBudgetRequestDetailsStepMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormReviewStepMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormRedesignedBasicsStepMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormRedesignedDetailsStepMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormRedesignedReviewStepMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormInsufficientUserPermissionsRedirected]:
    | BudgetAttributesFormPayload
    | BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormGlobalMccModalMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormNavigationBackButtonClicked]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormNavigationPrimaryButtonClicked]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormBudgetRequestPermissionsQueryFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormBudgetRequestDetailsQueryFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormBudgetTemplateOptionsQueryFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormBudgetTemplateDetailsQueryFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormNoTemplatesRedirected]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormDefaultErrorMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormUnsavedChangesMounted]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormUnsavedChangesExitClicked]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormUnsavedChangesBackClicked]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormNavigationCloseWithNoChangesClicked]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormPolicyPaneOpenPolicyClicked]: BudgetRequestFormViewPolicyPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormTravelCostEstimatorValueChanged]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormApproveSuccess]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormApproveFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormDenySuccess]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormDenyFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormCreateSuccess]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormCreateFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormUpdateSuccess]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormUpdateFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormInvalidStateBeforeSubmissionFailure]: BudgetRequestFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormValidateBudgetRequestMccControlsConflictSuccess]: BudgetRequestFormPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestFormValidateBudgetRequestMccControlsConflictFailure]: BudgetRequestFormErrorPayload;

  // Budgets page members pill
  [PrimitivesAnalyticsEvents.BudgetsPageMembersPillTooltipViewAllMembersClicked]: BudgetsPageMembersPillTooltipPayload;
  [PrimitivesAnalyticsEvents.BudgetsPageMembersPillTooltipViewDistributionRulesClicked]: BudgetsPageMembersPillTooltipPayload;

  // Budget Attributes
  [PrimitivesAnalyticsEvents.BudgetAttributesFormDefaultErrorMounted]: BudgetAttributesFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormDefaultErrorRetryClicked]: BudgetAttributesFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormUnsavedChangesMounted]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormUnsavedChangesExitClicked]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormUnsavedChangesBackClicked]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormCloseWithNoChangesClicked]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormCloseWithNoChangesClicked]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormOnSubmitBudgetTemplateQueryFailure]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormPolicyDocumentMounted]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormSelectBudgetTemplateStepMounted]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormBudgetAttributesInputDetailsStepMounted]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormBudgetAttributesReviewStepMounted]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormPrimaryActionButtonClicked]: BudgetAttributesFormActionButtonPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormSecondaryActionButtonClicked]: BudgetAttributesFormActionButtonPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormViewPolicyToggled]: BudgetAttributesFormViewPolicyTogglePayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormGlobalMccControlsModalOpenClicked]: BudgetAttributesFormPayload;
  [PrimitivesAnalyticsEvents.BudgetAttributesFormGlobalMccControlsModalClosedClicked]: BudgetAttributesFormPayload;
  // Budgets V1 Ftux
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationBannerExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationBannerCollapsed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialMounted]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialExitClicked]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialCompleted]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialCancelClicked]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialStartClicked]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialNextTutorialClicked]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialNextClicked]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialDoneClicked]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialSkipClicked]: BifurcatedBudgetsEducationTutorialPayload;
  [PrimitivesAnalyticsEvents.BifurcatedBudgetsEducationTutorialBackClicked]: BifurcatedBudgetsEducationTutorialPayload;
  // Budgets Prelaunch Bifurcation
  [PrimitivesAnalyticsEvents.BudgetsBifurcationPrelaunchBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBifurcationPrelaunchBannerLearnMoreClicked]: EmptyPayload;
  // Budget Audit Trail
  [PrimitivesAnalyticsEvents.BudgetDetailsPaneBudgetAuditTrailMounted]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDetailsPaneBudgetAuditTrailSeeMoreClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDetailsPaneBudgetAuditTrailSectionToggled]: BudgetAuditTrailSectionTogglePayload;
  // Budget Create Edit Flow (v1)
  [PrimitivesAnalyticsEvents.BudgetCreateFlowStartClicked]: BudgetCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetCreateFlowContinueClicked]: BudgetCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetCreateFlowReviewClicked]: BudgetCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetCreateFlowSaveClicked]: BudgetCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetCreateFlowDoneClicked]: BudgetCreateFlowDoneClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetsFirstTimeCreationChoice]: BudgetsFirstTimeCreationChoicePayload;
  [PrimitivesAnalyticsEvents.BudgetCreateFlowViewPolicyClicked]: BudgetCreateFlowViewPolicyPayload;
  [PrimitivesAnalyticsEvents.BudgetEditFlowStartClicked]: BudgetEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetEditFlowContinueClicked]: BudgetEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetEditFlowReviewClicked]: BudgetEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetEditFlowSaveClicked]: BudgetEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetEditFlowViewPolicyClicked]: BudgetEditFlowViewPolicyPayload;
  [PrimitivesAnalyticsEvents.BudgetFormEditFlowCancelRecurrenceClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetFormStepMounted]: BudgetFormPayload;
  [PrimitivesAnalyticsEvents.BudgetFormOverlayMounted]: BudgetFormOverlayPayload;
  [PrimitivesAnalyticsEvents.BudgetFormQueryFailure]: BudgetFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetFormMutationFailure]: BudgetFormErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetFormCtaClicked]: BudgetFormClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetFormToggleClicked]: BudgetFormToggledPayload;
  [PrimitivesAnalyticsEvents.BudgetFormEditFlowConfirmRecurrenceClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetFormEditFlowRecurrenceClicked]: BudgetRecurrencePayload;
  [PrimitivesAnalyticsEvents.BudgetFormBudgetCreated]: BudgetSavePayload;
  [PrimitivesAnalyticsEvents.BudgetFormBudgetEdited]: BudgetSavePayload;
  [PrimitivesAnalyticsEvents.BudgetFormValidationErrors]: BudgetFormValidationErrorsPayload;
  [PrimitivesAnalyticsEvents.BudgetFormUserLimitMounted]: BudgetFormUserLimitPayload;
  [PrimitivesAnalyticsEvents.BudgetFormUserLimitSubmitted]: BudgetFormUserLimitPayload;
  [PrimitivesAnalyticsEvents.BudgetDeleteBudgetClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDeleteBudgetConfirmClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTerminateBudgetClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTerminateBudgetConfirmClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetPageActionsMoveBudgetClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetPageActionsConfirmMoveBudgetClicked]: BudgetParentBudgetPayload;
  [PrimitivesAnalyticsEvents.BudgetPageActionsMoveBudgetParentBudgetChanged]: BudgetParentBudgetPayload;
  [PrimitivesAnalyticsEvents.BudgetPageActionsMoveBudgetConflictMounted]: BudgetParentBudgetPayload;
  [PrimitivesAnalyticsEvents.BudgetPageActionsMoveBudgetErrorMounted]: BudgetParentBudgetPayload;
  [PrimitivesAnalyticsEvents.BudgetPageAllocatedCalculationButtonClicked]: BudgetAllocatedCalculationPayload;
  [PrimitivesAnalyticsEvents.BudgetComponentBulkManagementBulkActionMenuMounted]: BudgetBulkManagementBulkActionMenuPayload;
  [PrimitivesAnalyticsEvents.BudgetComponentBulkManagementBulkActionClicked]: BudgetBulkManagementBulkActionPayload;
  [PrimitivesAnalyticsEvents.BudgetComponentBulkManagementBulkActionSubmitted]: BudgetBulkManagementBulkActionPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestComponentApproveDenyMutationFailure]: BudgetRequestApproveDenyErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestCreateMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestCreateNextClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestCreatePreviousClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestCreateReviewModalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestCreateSaveClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestCreateSuccess]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDenyModalConfirmClicked]: BudgetRequestApproveDenyModalPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDenyModalMounted]: BudgetRequestApproveDenyModalPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDenyModalSuccess]: BudgetRequestApproveDenyModalPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneEditAndReviewClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneEditClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneMounted]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneReviewClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneWithdrawConfirmClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneWithdrawMounted]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneWithdrawSuccess]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneValidateBudgetRequestMccControlsConflictSuccess]: BudgetRequestPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestDetailsPaneValidateBudgetRequestMccControlsConflictFailure]: BudgetRequestDetailsPaneErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestEditMounted]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestEditNextClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestEditPreviousClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestEditSaveClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestEditSuccess]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestReviewApproveClicked]: BudgetRequestApproveDenyModalPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestReviewApproveOverrideConfirmClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestReviewApproveSuccess]: BudgetRequestApproveDenyModalPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestReviewEditClicked]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetRequestReviewMounted]: BudgetRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetManageNotificationsDetailsPaneMounted]: BudgetManageNotificationsIdPayload;
  [PrimitivesAnalyticsEvents.BudgetManageNotificationsDetailsPaneSaveClicked]: BudgetManageNotificationsIdPayload;
  [PrimitivesAnalyticsEvents.BudgetManageNotificationsDetailsPaneCancelClicked]: BudgetManageNotificationsIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseClicked]: BudgetLimitIncreaseRequestClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestClicked]: BudgetLimitIncreaseRequestClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestCreateFlowMounted]: Partial<BudgetIdPayload>;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestCreateFlowCreateClicked]: Partial<BudgetIdPayload>;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestCreateFlowCreateSuccess]: Partial<BudgetIdPayload>;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestEditFlowMounted]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestEditFlowSaveClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestEditFlowSaveAndApproveClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestEditFlowSaveSuccess]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestEditFlowSaveAndApproveSuccess]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneMounted]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneRetryFetchClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneListMounted]: BudgetLimitIncreaseRequestDetailsPaneListMountedPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneListQueryFailure]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneListQueryRetryClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneListSearchChanged]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneListCardClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneEditClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneApproveClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneApproveOverrideClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneApproveOverrideConfirmClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneRejectClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneRejectConfirmClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneRejectOverrideClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneRejectOverrideConfirmClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestDetailsPaneCancelClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestInfoBannerViewBudgetInboxClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestInfoBannerViewAllOutstandingRequestsClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestInfoBannerViewActiveTemporaryClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestInfoBannerViewPersonalPendingClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetLimitIncreaseRequestInfoBannerViewWalletClicked]: BudgetLimitIncreaseRequestIdPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormStartClicked]: BudgetOffboardingBrokenApprovalChainsPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormDoneClicked]: BudgetOffboardingBrokenApprovalChainsPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormCancelClicked]: BudgetOffboardingBrokenApprovalChainsPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormDefaultErrorMounted]: BudgetOffboardingErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormStartStepMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormEditApprovalChainsStepMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormDoneStepMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormExitClicked]: BudgetOffboardingCurrentStepPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormBackClicked]: BudgetOffboardingCurrentStepPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormOffboardUserStepMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormOffboardUserStepContinueClicked]: BudgetOffboardingOffboardUserContinuePayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormOffboardUserStepContinueErrorClicked]: BudgetOffboardingOffboardUserContinuePayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormActionItemsMounted]: BudgetOffboardingReviewPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormActionItemReviewApprovalChainsMounted]: BudgetOffboardingReviewApprovalChainsPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormActionItemReviewCardOwnershipMounted]: BudgetOffboardingCardOwnershipPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormActionItemApprovalChainActionClicked]: BudgetOffboardingApprovalChainActionPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormActionItemCardActionClicked]: BudgetOffboardingCardActionPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormReprocessOffboardingActionSuccess]: BudgetOffboardingFormReprocessOffboardingActionPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormReprocessOffboardingActionFailure]: BudgetOffboardingFormReprocessOffboardingActionPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormLoadMoreOffboardingActionsSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormLoadMoreOffboardingActionsFailure]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormViewBudgetsMounted]: BudgetOffboardingTemplateBudgetCountPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormViewBudgetsClicked]: BudgetOffboardingTemplateDetailsPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormTransferCardOwnershipMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetOffboardingFormEditApprovalChainMounted]: BudgetOffboardingTemplateDetailsPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramCreateFlowStartMounted]: BudgetProgramCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramCreateFlowContinueClicked]: BudgetProgramCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramCreateFlowReviewMounted]: BudgetProgramCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramCreateFlowAddAnotherBudgetClicked]: BudgetProgramCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramCreateFlowCreateClicked]: BudgetProgramCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramCreateFlowCreateSuccess]: BudgetProgramCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramCreateFlowCreateFail]: BudgetProgramCreateFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramEditFlowStartMounted]: BudgetProgramEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramEditFlowContinueClicked]: BudgetProgramEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramEditFlowReviewMounted]: BudgetProgramEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramEditFlowSaveClicked]: BudgetProgramEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramEditFlowSaveSuccess]: BudgetProgramEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramEditFlowSaveFail]: BudgetProgramEditFlowPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramEditFlowAddAnotherBudgetClicked]: BudgetProgramIdPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramPauseClicked]: BudgetProgramIdPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramResumeClicked]: BudgetProgramIdPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramDeleteClicked]: BudgetProgramIdPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramDeleteConfirmClicked]: BudgetProgramIdPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramsTableMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramsTableRowActionClicked]: BudgetProgramsTableRowActionPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramsTableConfirmDeleteClicked]: BudgetProgramIdPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramsTableSeeMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramsTableSortClicked]: BudgetProgramsSortPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramsTableFilterByStatusClicked]: FilterPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramsTableFilterByInvitationFilterClicked]: FilterPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramsTableSearchFieldSubmitted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramDetailPaneAddBudgetClicked]: BudgetProgramIdPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramDetailPaneEditMemberClicked]: BudgetProgramIdPayload;
  [PrimitivesAnalyticsEvents.BudgetProgramDetailPaneEditBudgetClicked]: BudgetProgramDetailPanePayload;
  [PrimitivesAnalyticsEvents.BudgetProgramDetailPaneRemoveBudgetClicked]: BudgetProgramDetailPanePayload;
  [PrimitivesAnalyticsEvents.BudgetTableRowCollapsed]: BudgetTableRowExpansionStatePayload;
  [PrimitivesAnalyticsEvents.BudgetTableRowExpanded]: BudgetTableRowExpansionStatePayload;
  [PrimitivesAnalyticsEvents.BudgetTableRowEmployeePillClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetAllocationCalculationPageBackButtonClicked]: BudgetAllocatedCalculationPagePayload;
  [PrimitivesAnalyticsEvents.BudgetAllocationCalculationPageSeeMoreButtonClicked]: BudgetAllocatedCalculationPagePayload;
  [PrimitivesAnalyticsEvents.BudgetAllocationCalculationPageTableMounted]: BudgetAllocatedCalculationPagePayload;
  [PrimitivesAnalyticsEvents.BudgetAllocationCalculationPageBudgetHierarchyButtonClicked]: BudgetAllocatedCalculationPagePayload;
  [PrimitivesAnalyticsEvents.BudgetAllocationCalculationPageBudgetHierarchyMounted]: BudgetAllocatedCalculationPagePayload;
  [PrimitivesAnalyticsEvents.DeclineProtectionToggleMounted]: DeclineProtectionTogglePayload;
  [PrimitivesAnalyticsEvents.DeclineProtectionToggleToggled]: DeclineProtectionTogglePayload & {
    enabled: boolean;
  };
  [PrimitivesAnalyticsEvents.AutoTransferVirtualCardToggled]: AutoTransferVirtualCardToggledPayload;
  [PrimitivesAnalyticsEvents.SpendLimitGroupFormLimitOverridesMounted]: SpendLimitGroupBasePayload;
  [PrimitivesAnalyticsEvents.SpendLimitGroupFormOverridesAdded]: SpendLimitGroupFormOverridesPayload;
  [PrimitivesAnalyticsEvents.SpendLimitGroupFormOverridesRemoved]: SpendLimitGroupFormOverridesPayload;
  [PrimitivesAnalyticsEvents.YourWalletBudgetReassigned]: YourWalletBudgetReassignedPayload;
  [PrimitivesAnalyticsEvents.YourWalletBudgetReassignToastShown]: YourWalletBudgetToastPayload;
  [PrimitivesAnalyticsEvents.YourWalletBudgetReassignToastCtaClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletManageBudgetClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletManageBudgetMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletViewAllBudgetsAndCardsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletNewVirtualCardClicked]: YourWalletNewVirtualCardPayload;
  [PrimitivesAnalyticsEvents.YourWalletNewVirtualCardCreated]: YourWalletNewVirtualCardCreatedPayload;
  [PrimitivesAnalyticsEvents.YourWalletNewVirtualCardDone]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletNewVendorCardClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditCardDetailsCopied]: CreditCardDetailsCopiedPayload;
  [PrimitivesAnalyticsEvents.CreditCardSensitiveInfoDNSError]: CreditCardSensitiveInfoDNSErrorPayload;
  [PrimitivesAnalyticsEvents.CardDetailsPaneMounted]: CardDetailsPanePayload;
  [PrimitivesAnalyticsEvents.YourWalletViewCardDetailsClicked]: YourWalletViewCardDetailsClickedPayload;
  [PrimitivesAnalyticsEvents.YourWalletViewBudgetDetailsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletYourCardsEmptyStateSetupCardMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletYourCardsEmptyStateSetupAutopayMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletYourCardsEmptyStateApplyForCreditMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletYourCardsEmptyStateAwaitingApprovalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletYourCardsEmptyStateApplyForCreditLimitClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletYourCardsEmptyStateVerifyClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletYourCardsEmptyStateApplicationClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletSelectedBudgetHeaderMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.YourWalletSelectedBudgetHeaderHovered]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsCardsPaneViewSubPaneClicked]: BudgetsCardsPaneViewSubPaneClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetsCardsPaneSearchEntered]: BudgetsCardsPaneSearchEnteredPayload;
  [PrimitivesAnalyticsEvents.AdminCardShippingMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AdminCardShippingNextStep]: AdminCardShippingNextStepPayload;
  [PrimitivesAnalyticsEvents.AdminCardShippingFlowMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AdminCardShippingFlowCompleted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AdminCardShippingFlowExited]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AdminCardSetupEducationMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashInsightsTabMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashTabChanged]: CashTabChangedPayload;
  [PrimitivesAnalyticsEvents.PaymentFailureAuthorizeBankClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PaymentFailureChangeAutopayClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PaymentFailureMakePaymentClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewCurrentBalanceMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTabChanged]: CreditOverviewEducationTileTabChangedPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceBankConnectionErrorMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceBankConnectionErrorExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceBankConnectionErrorClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceStatementUploadDueMounted]: CreditOverviewEducationTileTotalCashBalanceStatementDuePayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceStatementUploadDueExpanded]: CreditOverviewEducationTileTotalCashBalanceStatementDuePayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceStatementUploadDueClicked]: CreditOverviewEducationTileTotalCashBalanceStatementDuePayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileTotalCashBalanceClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistoryMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistoryExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistoryClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistorySpendSuspendedMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistorySpendSuspendedExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistorySpendSuspendedClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistoryAutopayWarningMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistoryAutopayWarningExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTilePaymentHistoryAutopayWarningClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileFinancialDocumentsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileFinancialDocumentsExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileCreditTermsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileCreditTermsExpanded]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewEducationTileFinancialDocumentsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewNotificationTileInsufficientFundsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewNotificationTileInsufficientFundsClicked]: CreditOverviewNotificationTileInsufficientFundsClickedPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewNotificationTileLimitUtilizationClicked]: CreditOverviewNotificationTileLimitUtilizationClickedPayLoad;
  [PrimitivesAnalyticsEvents.CreditOverviewNotificationTileLimitUtilizationMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewPaymentDetailAutopayClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewPaymentDetailAutopayMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewPaymentsDueAutopayClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewPaymentsDueAutopayMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewPaymentDetailMakePaymentClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewPaymentDetailMakePaymentMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewTransactionsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditOverviewTransactionsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditBillingOverviewMakePaymentClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreditMakePaymentClicked]: CreditMakePaymentButtonClickedPayLoad;
  [PrimitivesAnalyticsEvents.CreditTabChanged]: CreditTabChangedPayload;
  // Cards and limits
  [PrimitivesAnalyticsEvents.CardsAndLimitsSurfaceCardsViewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CardsAndLimitsSurfaceSpendLimitsViewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CardsAndLimitsTabClicked]: CardsAndLimitsTabClickedPayload;
  [PrimitivesAnalyticsEvents.CardsAndLimitsCardActionsClicked]: CardsAndLimitsCardActionsClickedPayload;
  [PrimitivesAnalyticsEvents.CardsAndLimitsShelfCtaClicked]: CardsAndLimitsShelfCtaClickedPayload;
  [PrimitivesAnalyticsEvents.CardsAndLimitsShelfMounted]: CardsAndLimitShelfMountedPayload;
  [PrimitivesAnalyticsEvents.CardsAndLimitsCreateCardViewed]: CardsAndLimitsCreateCardViewedPayload;
  [PrimitivesAnalyticsEvents.CardsAndLimitsTableFiltersChanged]: CardsAndLimitsTableFiltersChangedPayload;
  [PrimitivesAnalyticsEvents.CreateCardsAndLimitStepViewed]: CreateCardsAndLimitStepViewedPayload;
  [PrimitivesAnalyticsEvents.CreateCardsAndLimitSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CreateCardsAndLimitCardPollingComplete]: CreateCardsAndLimitCardPollingCompletePayload;
  // Budget components
  [PrimitivesAnalyticsEvents.BudgetComponentEmployeesDropdownOptionClicked]: EmployeesDropdownOptionClickedPayload;
  // Employee bank account form
  [PrimitivesAnalyticsEvents.EmployeeBankAccountTermsAccepted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormClosed]: EmployeeBankAccountFormPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormMounted]: EmployeeBankAccountFormPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormBackClicked]: EmployeeBankAccountBackNextPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormNextClicked]: EmployeeBankAccountBackNextPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormConnectErrorMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormConnectClicked]: EmployeeBankAccountConnectPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormCountryMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormCountryInputFocused]: InputFieldPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormPlaidScreenMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormPlaidModalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormACHDetailsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormACHDetailsInputFocused]: InputFieldPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormCounterPartyDetailsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormCounterPartyDetailsInputFocused]: InputFieldPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormAddressMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormAddressSuggestedAddressMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormAddressSuggestedAddressUseDifferentAddressClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormAddressNewAddressMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormAddressNewAddressInputFocused]: InputFieldPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormConnectMutationSuccess]: EmployeeBankAccountMutationResponsePayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormConnectMutationError]: EmployeeBankAccountMutationResponsePayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormDeleteModalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeBankAccountFormDeleteModalClosed]: EmptyPayload;
  // Expenses All
  [PrimitivesAnalyticsEvents.ExpensesAllPageMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllTypeFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllBudgetFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllBudgetTemplateFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllStatusFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllComplianceFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllApprovalFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllIncurredByFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllSearchBySelected]: ExpensesSearchByPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDateFilterSelected]: ExpensesDateFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllAmountFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllBilledEntityFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllSpendingEntityFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllAttendeesContainGovernmentOfficialFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllExpenseCategoryFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllExportClicked]: ExpensesAllExportPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllFiltersCleared]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllFilterCleared]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllUserStatusFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllGLAccountFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllCardFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDepartmentFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllMerchantFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllMerchantCategoryFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllUserFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllVendorFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDisputeStatusFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllExpensePolicyFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllAddFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllComplianceFilterSelectedAutoMemoUpsellMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllComplianceFilterSelectedAutoMemoUpsellDismissed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllComplianceFilterSelectedAutoMemoUpsellPolicySettingsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllApprovalStatusFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllPaymentStatusFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDerivedComplianceStatusFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDocumentationFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllMemoFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllReceiptFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllFlaggedExpenseFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllCostCenterFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllTrackingStatusFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpensesPolicyViolationReasonsFilterSelected]: ExpensesFilterPayload;
  [PrimitivesAnalyticsEvents.ExpenseFiltersExpenseTypeRedirected]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDeadlineUpsellTooltipMounted]: DeadlineUpsellPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDeadlineUpsellModalMounted]: DeadlineUpsellPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDeadlineUpsellDismissed]: DeadlineUpsellPayload;
  [PrimitivesAnalyticsEvents.ExpensesAllDeadlineUpsellSetupButtonClicked]: DeadlineUpsellPayload;
  // Expenses Export
  [PrimitivesAnalyticsEvents.ExpensesExportModalMounted]: ExpensesExportAsyncPayload;
  [PrimitivesAnalyticsEvents.ExpensesExportModalClosed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpensesExportDownloadClicked]: ExpensesExportDownloadClickedPayload;
  [PrimitivesAnalyticsEvents.ExpensesExportCreateReportSuccessDownloadRetryClicked]: ExpensesExportCreateReportSuccessDownloadRetryClickedPayload;
  [PrimitivesAnalyticsEvents.ExpensesExportCreateReportFailDownloadRetryClicked]: ExpensesExportCreateReportFailDownloadRetryClickedPayload;
  // AI Smart Filtering
  [PrimitivesAnalyticsEvents.ExpensesAISmartFilteringDropdownButtonClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpensesAISmartFilteringSubmit]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpensesAISmartFilteringAppliedFilterKeys]: SmartFilteringPayload;
  [PrimitivesAnalyticsEvents.ExpensesAISmartFilteringLLMParseError]: EmptyPayload;
  // Declined expenses - suggested actions
  [PrimitivesAnalyticsEvents.ExpenseDeclineSuggestionView]: DeclinedExpenseSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDeclineSuggestionClick]: DeclinedExpenseSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDeclineSuggestionOptionsView]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDeclineSuggestionShowOptions]: EmptyPayload;

  // DataGrid
  [PrimitivesAnalyticsEvents.DataGridMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.DataGridRowClicked]: DataGridSelectionPayload;
  [PrimitivesAnalyticsEvents.DataGridSortClicked]: DataGridSortPayload;
  [PrimitivesAnalyticsEvents.DataGridViewSelected]: DataGridViewPayload;
  [PrimitivesAnalyticsEvents.DataGridLayoutSelected]: DataGridLayoutPayload;
  [PrimitivesAnalyticsEvents.DataGridDetailsPaneRowCyclingClicked]: RowCyclingPayload;
  [PrimitivesAnalyticsEvents.DataGridPaginationPreviousClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.DataGridPaginationNextClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.DataGridPaginationPageSizeClicked]: DataGridPageSizePayload;
  [PrimitivesAnalyticsEvents.DataGridPaginationSeeMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.DataGridV1DeepLinkLegacyParamsUsed]: DataGridDeepLinkParamTypesPayload;
  [PrimitivesAnalyticsEvents.DataGridV1DeepLinkUsed]: DataGridDeepLinkParamTypesPayload;
  // DataGrid Smart Search
  [PrimitivesAnalyticsEvents.DataGridAISmartSearchLLMParseError]: EmptyPayload;
  [PrimitivesAnalyticsEvents.DataGridAISmartSearchAppliedFilterKeys]: SmartFilteringPayload;
  // DataGrid selection
  [PrimitivesAnalyticsEvents.DataGridSelectionRowToggle]: DataGridSelectionPayload;
  [PrimitivesAnalyticsEvents.DataGridSelectionGroupToggle]: DataGridSelectionPayload;
  [PrimitivesAnalyticsEvents.DataGridSelectionGroupAllToggle]: DataGridSelectionPayload;
  [PrimitivesAnalyticsEvents.DataGridSelectionHeaderToggle]: DataGridSelectionPayload;
  [PrimitivesAnalyticsEvents.DataGridSelectionTrueSelectAll]: DataGridSelectionPayload;
  [PrimitivesAnalyticsEvents.DataGridGroupingGroupByOptionCleared]: GroupingBasePayload;
  [PrimitivesAnalyticsEvents.DataGridGroupingViewAllRowsForGroupClicked]: GroupingTitleActionPayload;
  // DataGrid bulk actions
  [PrimitivesAnalyticsEvents.DataGridBulkActionButtonClick]: DataGridBulkActionPayload;
  [PrimitivesAnalyticsEvents.DataGridBulkActionClose]: DataGridBulkActionPayload;
  [PrimitivesAnalyticsEvents.DataGridBulkActionAttempted]: DataGridBulkActionPayload;
  // DataGrid column settings
  [PrimitivesAnalyticsEvents.DataGridColumnSettingsVisibilityToggle]: DataGridColumnSettingsPayload;
  [PrimitivesAnalyticsEvents.DataGridColumnSettingsDrag]: DataGridColumnSettingsPayload;
  // DataGrid Grouping
  [PrimitivesAnalyticsEvents.DataGridGroupingGroupByOptionSelected]: GroupingBasePayload;
  [PrimitivesAnalyticsEvents.DataGridGroupingGroupByOptionCleared]: GroupingBasePayload;
  [PrimitivesAnalyticsEvents.DataGridGroupingViewAllRowsForGroupClicked]: GroupingTitleActionPayload;
  // DataGrid Filters
  [PrimitivesAnalyticsEvents.DataGridFilteringUseRestrictedFiltersAttempted]: UseRestrictedFiltersPayload;
  [PrimitivesAnalyticsEvents.DataGridFiltersClearAllFiltersClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.DataGridFiltersApplyFilterClicked]: DataGridFiltersPayload;
  [PrimitivesAnalyticsEvents.DataGridFiltersRemoveFilterClicked]: DataGridFiltersPayload;
  [PrimitivesAnalyticsEvents.DataGridFiltersClearFilterClicked]: DataGridFiltersPayload;
  [PrimitivesAnalyticsEvents.DataGridFiltersFilterValueChanged]: DataGridFiltersPayload;
  // DataGrid cell actions
  [PrimitivesAnalyticsEvents.DataGridTextInputCellChanged]: DataGridCellPayload;
  [PrimitivesAnalyticsEvents.DataGridUploadButtonCellClicked]: DataGridUploadButtonCellPayload;
  // Datagrid row actions
  [PrimitivesAnalyticsEvents.DatagridRowActionButtonClick]: DataGridRowActionPayload;
  //Reimbursements V2
  [PrimitivesAnalyticsEvents.ReimbursementsReviewComplete]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormTakeoverMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBankErrorBannerMounted]: ReimbursementsBankErrorPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBankConnectTakeoverMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormCloseClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormConfirmationAlertMounted]: ReimbursementsConfirmationAlertPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormConfirmationAlertActionClicked]: ReimbursementsConfirmationAlertPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormFieldFocused]: ReimbursementsFieldPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormSingleMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormBulkClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormBulkMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormBulkSharedInfoMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormBulkDetailsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormBulkAddRequestClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormBulkRemoveRequestClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormCancelClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormIsValid]: ReimbursementFormIsValidPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormValidatoinError]: {
    errors: string[];
  };
  [PrimitivesAnalyticsEvents.ReimbursementsFormUpdateClicked]: ReimbursementsMutationRequestPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormSubmitClicked]: ReimbursementsMutationRequestPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormDeleteClicked]: ReimbursementsMutationRequestPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormSaveAsDraftClicked]: ReimbursementsMutationRequestPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormOcrCancelClicked]: ReimbursementsOcrCancelPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormOcrError]: ReimbursementsOcrErrorPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormOcrSuccess]: ReimbursementsOcrSuccessPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormMutationError]: ReimbursementsMutationResponsePayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormMutationSuccess]: ReimbursementsMutationResponsePayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormListAttendeesMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsTabChanged]: ReimbursementsTabChangedPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsSCASendOneTimePassCodeClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsSCASendOneTimePassCodeMutationSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsSCASendOneTimePassCodeMutationError]: ReimbursementsSCAMutationErrorPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsSCAReauthenticate]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsSCAApproveBeneficiariesClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsSCAApproveBeneficiariesMutationSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsSCAApproveBeneficiariesMutationError]: ReimbursementsSCAMutationErrorPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsCardMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsCardSetupButtonClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsCardSetupButtonDismissed]: EmptyPayload;
  // Reimbursement terms
  [PrimitivesAnalyticsEvents.ReimbursementTermsModalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementTermsModalCheckboxToggled]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementTermsModalSubmitClicked]: EmptyPayload;
  // Reimbursements V3
  [PrimitivesAnalyticsEvents.ReimbursementsNoReceiptClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrReceiptUploadMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrDetailsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrCount]: ReimbursementsBulkOcrCountPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrContinueClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrBackClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrDeleteClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrPaginationNextClicked]: BulkOcrPaginationPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrPaginationBackClicked]: BulkOcrPaginationPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrCarouselClicked]: BulkOcrPaginationPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrApplyAllMemoClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrApplyAllBudgetClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsBulkOcrMultipleReimbursements]: ReimbursementsBulkOcrPayload;
  // Mileage v2
  [PrimitivesAnalyticsEvents.ReimbursementsFormMileageFieldMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormMileageTripTypeChanged]: MileageTripTypePayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormMileageAddStopClicked]: MileageAddRemoveStopsPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormMileageRemoveStopClicked]: MileageAddRemoveStopsPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormMileageDisabledMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementsFormMileageDistanceOverridden]: EmptyPayload;
  [PrimitivesAnalyticsEvents.MileageTripMapMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.MileageDirectionsError]: EmptyPayload;
  // Onboarding
  [PrimitivesAnalyticsEvents.OnboardingCompanyPolicyAgreementViewed]: OnboardingCompanyPolicyAgreementViewedPayload;
  [PrimitivesAnalyticsEvents.OnboardingCompanyPolicyAgreementErrored]: OnboardingCompanyPolicyAgreementErroredPayload;
  [PrimitivesAnalyticsEvents.OnboardingCompanyPolicyAgreementCompleted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceMobileGuardViewed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceSubStepViewed]: OnboardingExperienceSubStepViewedPayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceSubStepBackClicked]: OnboardingExperienceSubStepViewedPayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceSubStepCompleted]: OnboardingExperienceSubStepViewedPayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceDirectReportsLoaded]: OnboardingExperienceDirectReportsLoadedPayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceMachineStarted]: OnboardingExperienceMachineStartedPayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceMachineCompleted]: OnboardingExperienceMachineCompletedPayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceMachineState]: OnboardingExperienceMachineStatePayload;
  [PrimitivesAnalyticsEvents.OnboardingExperienceMachineStateViewed]: OnboardingExperienceMachineStateViewedPayload;
  [PrimitivesAnalyticsEvents.ProductSelectionClicked]: ProductSelectionEventPayload;
  [PrimitivesAnalyticsEvents.ProductSelectionViewed]: ProductSelectionEventPayload;
  [PrimitivesAnalyticsEvents.ExpensesInboxLinkToAllExpensesClicked]: ExpensesInboxLinkToAllExpensesClickedPayload;
  // Card Ownership Transfer
  [PrimitivesAnalyticsEvents.CardOwnershipTransferStepViewed]: CardOwnershipTransferStepViewedPayload;
  [PrimitivesAnalyticsEvents.CardOwnershipTransferStarted]: CardOwnershipTransferStartedPayload;
  [PrimitivesAnalyticsEvents.CardOwnershipTransferCompleted]: CardOwnershipTransferStepCompletedPayload;
  [PrimitivesAnalyticsEvents.CardOwnershipTransferExit]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CardOwnershipTransferError]: CardOwnershipTransferErrorPayload;
  [PrimitivesAnalyticsEvents.CardOwnershipTransferErrorRetry]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CardOwnershipTransferOnlineMerchantPaginationClicked]: CardOwnershipTransferOnlineMerchantPaginationPayload;
  [PrimitivesAnalyticsEvents.CardOwnershipTransferSelectNewOwnerClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CardOwnershipTransferSelectBudgetClicked]: EmptyPayload;
  // Expenses Table
  [PrimitivesAnalyticsEvents.ExpensesDrilldownMounted]: ExpensesDrilldownPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableBulkActionAttempted]: ExpensesTableBulkActionAttemptedPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableBulkActionSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableSortClicked]: SortPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableSeeMoreClicked]: ExpensesTableSeeMorePayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableLoadMoreExpensesForGroupClicked]: LoadMoreExpensesForGroupPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableRowClicked]: ExpensesTableRowClickedPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableZeroSearchResult]: ExpensesOriginPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableRowClickedForSearch]: ExpensesTableSearchCTAPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableRowClickedForSearchTop3]: ExpensesOriginPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableGroupByClicked]: LegacyExpenseGroupingPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableGroupByCleared]: ExpensesOriginPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableGroupSelectionClicked]: LegacyExpenseGroupingSelectionPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableClearGroupSelectionClicked]: LegacyExpenseGroupingSelectionPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableCheckboxSelected]: ExpensesTableCheckboxSelectedPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTablePaginatedPrevClicked]: ExpensesTablePaginatedPrevClickedPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTablePaginatedNextClicked]: ExpensesTablePaginatedNextClickedPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableFlaggedExpenseColumnHovered]: ExpensesOriginPayload;
  [PrimitivesAnalyticsEvents.ApproveExpensesModalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.DenyExpensesModalMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseBudgetSelectionSaveRequest]: ExpenseBudgetSelectionPayload;
  [PrimitivesAnalyticsEvents.ExpenseCategoriesMounted]: ExpenseCategoriesPayload;
  [PrimitivesAnalyticsEvents.ExpenseCategoriesCategoryChange]: ExpenseCategoriesPayload;
  [PrimitivesAnalyticsEvents.ExpenseCategoriesAddendumChange]: ExpenseCategoriesPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneMounted]: ExpenseDetailsPanePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneLoaded]: ExpenseDetailsPaneLoaded;
  [PrimitivesAnalyticsEvents.ExpenseErrorDetailsPaneMounted]: ExpenseDetailsPanePayload;
  [PrimitivesAnalyticsEvents.ExpenseErrorDetailsPaneRetryFetchClicked]: ExpenseDetailsPanePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneEditsSaved]: ExpenseDetailsPaneEditsSavedPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneEditsSaveRequest]: ExpenseDetailsPaneEditsSaveRequestPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetChangeAlertMounted]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetChangeAlertCloseClicked]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetChangeAlertSaveClicked]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetSearch]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseBudgetSelectionErrorModalMounted]:
    | ExpenseBudgetSelectionErrorModalPayload
    | ExpenseBudgetSelectionInsufficientLimitErrorModalPayload
    | ExpenseBudgetSelectionDateConflictErrorModalPayload
    | ExpenseBudgetSelectionTransactionLimitErrorModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseBudgetSelectionErrorModalCloseClicked]:
    | ExpenseBudgetSelectionErrorModalPayload
    | ExpenseBudgetSelectionInsufficientLimitErrorModalPayload
    | ExpenseBudgetSelectionDateConflictErrorModalPayload
    | ExpenseBudgetSelectionTransactionLimitErrorModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseBudgetSelectionErrorModalCtaClicked]:
    | ExpenseBudgetSelectionErrorModalPayload
    | ExpenseBudgetSelectionInsufficientLimitErrorModalPayload
    | ExpenseBudgetSelectionDateConflictErrorModalPayload
    | ExpenseBudgetSelectionTransactionLimitErrorModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseBudgetSelectionAddUserToBudgetAlertMounted]: ExpenseBudgetSelectionAddUserToBudgetPayload;
  [PrimitivesAnalyticsEvents.ExpenseBudgetSelectionAddUserToBudgetAlertConfirmClicked]: ExpenseBudgetSelectionAddUserToBudgetPayload;
  [PrimitivesAnalyticsEvents.ExpenseBudgetSelectionAddUserToBudgetAlertCloseClicked]: ExpenseBudgetSelectionAddUserToBudgetPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneUnsavedChangesAlertMounted]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneUnsavedChangesAlertCloseClicked]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneUnsavedChangesAlertProceedWithoutSavingClicked]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneSaveOnReviewModalMounted]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneSaveOnReviewModalCloseClicked]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneSaveOnReviewModalConfirmClicked]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneCancelReimbursementModalMounted]: ReimbursementModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneCancelReimbursementModalConfirmClicked]: ReimbursementModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneCancelReimbursementModalCloseClicked]: ReimbursementModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneDeleteReimbursementModalMounted]: ReimbursementModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneDeleteReimbursementModalConfirmClicked]: ReimbursementModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneDeleteReimbursementModalCloseClicked]: ReimbursementModalPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneMarkedCardExpenseAsReviewed]: ExpenseDetailsPaneMarkedCardExpenseAsReviewedPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneReimbursementRequestActioned]: ExpenseDetailsPaneReimbursementRequestActionedPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneUpdateRequestSuccess]: ExpenseDetailsRequestActionSuccessPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneConfirmAutoAssignedLimitClick]: ExpenseIdPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneConfirmAutoAssignedLimitSuccess]: ExpenseIdPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneDocumentationRequirementTooltipMounted]: ExpenseDetailsDocumentationRequirementTooltipPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneEditBillClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPanePaymentFailureReasonBannerClicked]: ExpenseDetailsPanePaymentFailureReasonBannerClickedPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetAuditTrailMounted]: BudgetAuditTrailSectionTogglePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetAuditTrailSectionToggled]: BudgetAuditTrailSectionTogglePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneListAttendeesMounted]: ExpenseDetailsPanePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneListAttendeesTooltipMounted]: ExpenseDetailsPanePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneAutoGeneratedBasicReceiptTooltipMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneAutoGeneratedBasicReceiptTooltipEditPolicyClicked]: ReceiptProviderPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneGenerateAffidavitMounted]: GenerateAffidavitPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneGenerateAffidavitClicked]: GenerateAffidavitPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneGenerateAffidavitConfirmed]: GenerateAffidavitPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneGenerateAffidavitCanceled]: GenerateAffidavitPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneGenerateAffidavitSuccess]: GenerateAffidavitPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneGenerateAffidavitError]: GenerateAffidavitPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneUploadFromMobileMounted]: UploadFromMobilePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneUploadFromMobileClicked]: UploadFromMobilePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneUploadFromMobileIntroMounted]: UploadFromMobileIntroPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneUploadFromMobileNotificationTriggered]: UploadFromMobileNotificationPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseClicked]: ExpenseDetailsPaneItemizeExpenseClickedPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseAddClicked]: ExpenseDetailsPaneItemizeExpensePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseRemoveClicked]: ExpenseDetailsPaneItemizeExpensePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseAmountChanged]: ExpenseDetailsPaneItemizeExpensePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseCategoryChanged]: ExpenseDetailsPaneItemizeExpensePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseSaveClicked]: ExpenseDetailsPaneItemizeExpenseSaveClickedPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseCancelClicked]: ExpenseDetailsPaneItemizeExpensePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseConfirmModalCloseClicked]: ExpenseDetailsPaneItemizeExpensePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneItemizeExpenseConfirmModalConfirmClicked]: ExpenseDetailsPaneItemizeExpensePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneReceiptIntegrationBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneReceiptIntegrationBannerCTAClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneReceiptIntegrationConfirmationModalConfirmClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneCategorizationCustomRuleResolutionApplyRuleClicked]: ExpenseDetailsPaneCategorizationRuleResolutionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneCategorizationCustomRuleResolutionRemoveAllRulesClicked]: ExpenseDetailsPaneCategorizationRuleResolutionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneMarkReadyClicked]: ExpenseDetailsPanePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneSentBackToReviewClicked]: ExpenseDetailsPanePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneAutosaveCompleted]: ExpenseDetailsPanePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneAutosaveUnsavedChangesAlertMounted]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneAutosaveUnsavedChangesAlertCloseClicked]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneAutosaveUnsavedChangesAlertProceedWithoutSavingClicked]: ExpenseDetailsPaneChangeAlertPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneMemoSuggestionUpsellMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneMemoSuggestionUpsellDismissed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneMemoSuggestionMounted]: ExpenseMemoSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneMemoSuggestionClicked]: ExpenseMemoSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneAttendeeSuggestionMounted]: ExpenseAttendeeSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneAttendeeSuggestionClicked]: ExpenseAttendeeSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneMemoSuggestionEditPolicyUpsellClicked]: ExpenseMemoSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetSuggestionUpsellMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetSuggestionUpsellDismissed]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetSuggestionMounted]: ExpenseBudgetSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetSuggestionClicked]: ExpenseBudgetSuggestionAcceptPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetSuggestionDismiss]: ExpenseBudgetSuggestionPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetSuggestionFailedDryRunBudgets]: {
    unsuccessfulBudgetIds: string[];
  };
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBrexAssistantAttributionLabelMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneLastEnteredAttendeesClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneExternalAttendeeFormChange]: ExpenseDetailsPaneExternalAttendeeFormChangePayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneReviewReasonBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneFlaggedExpenseBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPanePaymentStatusBannerMounted]: ExpenseDetailsPanePaymentStatusBannerMountedPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneBudgetAndPolicyExpenseFieldUpdated]: ExpenseDetailsPaneBudgetAndPolicyExpenseFieldPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneShowReceiptPaneButtonClicked]: ExpenseDetailsPaneShowReceiptPaneButtonClickedPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneExpenseCyclingClicked]: RowCyclingPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneTabClicked]: {
    tabId: string;
    expenseId: string;
  };
  //First Spend Guide
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneFirstSpendGuideSeen]: FirstSpendPayload;
  [PrimitivesAnalyticsEvents.ExpenseDetailsPaneFirstSpendGuideCompleted]: FirstSpendPayload;
  //Bulk Edit Expenses
  [PrimitivesAnalyticsEvents.BulkEditDetailsExpenseMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableMemoUpdated]: TableInputUpdatedSuccessPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableBudgetUpdated]: TableInputUpdatedSuccessPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableMemoFocused]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmpowerExpensesTableBudgetFocused]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BulkEditDetailsEditClicked]: BulkEditDetailsEditClickedPayload;
  [PrimitivesAnalyticsEvents.BulkEditDetailsUpdatedSuccess]: BulkEditDetailsUpdatedSuccessPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentModalMounted]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentModalCloseClicked]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentCreateRepaymentConfirmClicked]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentCancelRepaymentConfirmClicked]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentSubmitRepaymentConfirmClicked]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentError]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentSuccess]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentSelfInitiatedRepaymentClicked]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentCompleteRepaymentClicked]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentRetryRepaymentConfirmClicked]: EmployeeRepaymentPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentRequestRepaymentOnReimbursementClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EmployeeRepaymentSubmitOwnRepaymentOnReimbursementClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamUsersTableRowClicked]: TeamTableRowClickedPayload;
  [PrimitivesAnalyticsEvents.TeamUsersTabSearchChanged]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamUserInviteResent]: TeamUserInviteResentPayload;
  [PrimitivesAnalyticsEvents.TeamDepartmentRowActionClicked]: TeamTableRowActionClickedPayload;
  [PrimitivesAnalyticsEvents.TeamLocationRowActionClicked]: TeamTableRowActionClickedPayload;
  [PrimitivesAnalyticsEvents.TeamInviteUserSendInviteClicked]: TeamInviteUserSendInviteClickedPayload;
  [PrimitivesAnalyticsEvents.TeamInviteCtaClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamInviteFiltersApplied]: TeamInviteFiltersAppliedPayload;
  [PrimitivesAnalyticsEvents.TeamUsersTableFiltersApplied]: TeamUsersFiltersAppliedPayload;
  [PrimitivesAnalyticsEvents.TeamUsersTableColumnsVisible]: TeamUsersTableColumnsVisiblePayload;
  [PrimitivesAnalyticsEvents.TeamUsersTableMissingUserLimitClicked]: TeamUsersTableMissingUserLimitClickedPayload;
  [PrimitivesAnalyticsEvents.TeamInviteSubmit]: TeamInviteSubmitPayload;
  [PrimitivesAnalyticsEvents.TeamInviteAttributeMappingSkipped]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamInviteSuccessAddMoreEmployeesClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamInviteSuccessDoneClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamInviteEmployeesDropdownOptionClicked]: EmployeesDropdownOptionClickedPayload;
  [PrimitivesAnalyticsEvents.TeamManageHrisConnectionClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamBulkDeactivateSubmit]: TeamBulkActionPayload;
  [PrimitivesAnalyticsEvents.TeamBulkReactivateSubmit]: TeamBulkActionPayload;
  [PrimitivesAnalyticsEvents.TeamBulkReinviteSubmit]: TeamBulkActionPayload;
  [PrimitivesAnalyticsEvents.TeamBulkUninviteSubmit]: TeamBulkActionPayload;
  [PrimitivesAnalyticsEvents.TeamUserDetailsPaneCreateUserLimitClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamAutoInviteSubmit]: CreateAutoInviteSettingInput;
  [PrimitivesAnalyticsEvents.TeamAutoInviteEditSubmit]: UpdateAutoInviteSettingInput;
  [PrimitivesAnalyticsEvents.TeamAutoInviteDeleteClicked]: TeamAutoInviteDeletePayload;
  [PrimitivesAnalyticsEvents.TeamAutoInviteStatusToggled]: TeamAutoInviteStatusPayload;
  [PrimitivesAnalyticsEvents.TeamAutoInviteCreateStepMounted]: TeamAutoInviteStepMountedPayload;
  [PrimitivesAnalyticsEvents.TeamUserGroupCreateStepMounted]: TeamUserGroupStepMountedPayload;
  [PrimitivesAnalyticsEvents.TeamUserGroupReviewStepMounted]: TeamUserGroupStepMountedPayload;
  [PrimitivesAnalyticsEvents.TeamAutoInvitePreviewStepMounted]: TeamAutoInviteStepMountedPayload;
  [PrimitivesAnalyticsEvents.TeamAutoInviteRoleStepMounted]: TeamAutoInviteStepMountedPayload;
  [PrimitivesAnalyticsEvents.TeamAutoInviteReviewStepMounted]: TeamAutoInviteStepMountedPayload;
  [PrimitivesAnalyticsEvents.TeamUserCardAccessUpdate]: TeamUserCardAccessUpdatePayload;
  [PrimitivesAnalyticsEvents.TeamUserCardAccessConfirm]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamUserCardAccessCancel]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamResetPasswordSubmit]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamMfaResetSubmit]: TeamMfaResetSubmittedPayload;
  [PrimitivesAnalyticsEvents.TeamGroupDeleteClicked]: TeamGroupDeleteClickedPayload;
  [PrimitivesAnalyticsEvents.TeamGroupCreateSubmit]: CreateGroupInput;
  [PrimitivesAnalyticsEvents.TeamGroupEditSubmit]: TeamGroupUpdatePayload;
  [PrimitivesAnalyticsEvents.TeamUserAttributesMultiSelectSearch]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamUserAttributesMultiSelectFilterApplied]: TeamUserAttributesFilterPayload;
  [PrimitivesAnalyticsEvents.TeamGroupBuilderMultiSelectSearch]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamShelfMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.TeamBulkRoleChangeSubmit]: TeamBulkRoleChangeSubmitPayload;
  [PrimitivesAnalyticsEvents.TeamBulkTravelChangeSubmit]: TeamBulkTravelChangeSubmitPayload;
  [PrimitivesAnalyticsEvents.BudgetsOverviewTabChanged]: BudgetsOverviewTabChangedPayload;
  [PrimitivesAnalyticsEvents.BudgetsOverviewBudgetClicked]: BudgetsOverviewBudgetClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetOffboardingAlertBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetOffboardingAlertBannerClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetProgramsBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetProgramsBannerViewAllClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetProgramsBannerCreateClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetTemplatesBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetTemplatesBannerViewAllClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetTemplatesBannerHotspotMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsBudgetTemplatesBannerHotspotClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownTabChanged]: BudgetDrilldownTabChangedPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownViewCardClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownViewPolicyClicked]: BudgetDrilldownViewPolicyClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownTransactionDetailsClicked]: BudgetDrilldownTransactionDetailsClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownCreateCardClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownCreateCardSuccess]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownCreateCardError]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownNewReimbursementClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownNestedBudgetClicked]: BudgetDrilldownNestedBudgetClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownBudgetMemberClicked]: BudgetDrilldownBudgetMemberClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownAddBudgetMembersClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownBudgetOwnerClicked]: BudgetDrilldownBudgetOwnerClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownManageAlertsClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetDrilldownViewAllMembersClicked]: BudgetIdPayload;
  [PrimitivesAnalyticsEvents.BudgetsEssentialsEmptyStateBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsEssentialsEmptyStateBannerCTAClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsSearchFieldSubmitted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsSearchFilterByClicked]: FilterPayload;
  [PrimitivesAnalyticsEvents.BudgetsSearchFilterByStatusClicked]: FilterPayload;
  [PrimitivesAnalyticsEvents.BudgetsSearchSortByClicked]: BudgetsSortByPayload;
  [PrimitivesAnalyticsEvents.BudgetsSearchShowExpiredClicked]: CheckboxClickedPayload;
  [PrimitivesAnalyticsEvents.BudgetsSearchSeeMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetsSearchFilterV2Changed]: BudgetsSearchFilterV2ChangedPayload;
  [PrimitivesAnalyticsEvents.BudgetUsersSearchFieldSubmitted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetUsersSortByClicked]: BudgetsSortByPayload;
  [PrimitivesAnalyticsEvents.BudgetSelfServeCreateFlowStartClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetSelfServeCreateFlowContinueClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetSelfServeCreateFlowDoneClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetSelfServeCreateFlowErrorMounted]: ErrorPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorMounted]: PolicyEditorFormPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorPolicySaveClicked]: PolicyEditorFormPayload;
  [PrimitivesAnalyticsEvents.PolicyFormNoReviewersWarningModalConfirmClicked]: PolicyEditorFormPayload;
  [PrimitivesAnalyticsEvents.PolicyFormNoReviewersWarningModalCancelClicked]: PolicyEditorFormPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorNewPolicySaveSuccess]: PolicyEditorSavedPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorNewPolicySaveFailure]: PolicyEditorSavedPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorPolicyUpdateSaveSuccess]: PolicyEditorSavedPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorPolicyUpdateSaveFailure]: PolicyEditorSavedPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorReceiptItemizationConfigurationMounted]: PolicyEditorReceiptItemizationMountedPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorUnsavedChangesAlertMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorUnsavedChangesAlertCloseClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyEditorUnsavedChangesAlertProceedWithoutSavingClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormCategoryMerchantStepNextClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormCategoryMerchantStepCustomizeClicked]: PolicyFormMerchantCategoryPayload;
  [PrimitivesAnalyticsEvents.PolicyFormCategoryMerchantStepAddClicked]: PolicyFormMerchantCategoryPayload;
  [PrimitivesAnalyticsEvents.PolicyFormCategoryMerchantStepDeleteClicked]: PolicyFormMerchantCategoryPayload;
  [PrimitivesAnalyticsEvents.PolicyFormCategoryMerchantConfigStepSaveClicked]: PolicyFormMerchantCategoryConfigPayload;
  [PrimitivesAnalyticsEvents.PolicyFormTravelAirlineConfigSaved]: PolicyFormTravelAirlineConfigSavedPayload;
  [PrimitivesAnalyticsEvents.PolicyFormTravelLodgingConfigSaved]: PolicyFormTravelLodgingeConfigSavedPayload;
  [PrimitivesAnalyticsEvents.PolicyFormTravelCarRentalConfigSaved]: PolicyFormTravelCarRentalConfigSavedPayload;
  [PrimitivesAnalyticsEvents.PolicyFormTravelRailConfigSaved]: PolicyFormTravelRailConfigSavedPayload;
  [PrimitivesAnalyticsEvents.PolicyFormCategoryMerchantConfigStepListAttendeesMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsPreviewStepEditClicked]: PolicyFormExpenseTypePayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepAddExpectionClicked]: PolicyFormSpendRequirementPayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepSaveExpectionClicked]: PolicyFormSpendRequirementPayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepDeleteExpectionClicked]: PolicyFormSpendRequirementPayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepAddReviewRuleClicked]: PolicyFormExpenseTypePayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepDeleteReviewRuleClicked]: PolicyFormExpenseTypePayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepAddReviewRuleStepClicked]: PolicyFormExpenseTypePayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepDeleteReviewRuleStepClicked]: PolicyFormExpenseTypePayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepAddApproverTooltipDismissClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepAddApproverTooltipSecondaryClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsConfigStepSaveClicked]: PolicyFormExpenseTypePayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsPreviewAutoMemoUpsellBannerMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsPreviewAutoMemoUpsellBannerEnableClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormSpendRequirementsPreviewAutoMemoUpsellBannerDismissClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormBasicFormMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormDocumentationFormMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormExpenseTypeSpecificFormMounted]: PolicyFormExpenseTypePayload;
  [PrimitivesAnalyticsEvents.PolicyFormReceiptPaneMounted]: PolicyFormEditPayload;
  [PrimitivesAnalyticsEvents.PolicyFormMemoPaneMounted]: PolicyFormEditPayload;
  [PrimitivesAnalyticsEvents.PolicyFormApprovalChainFormMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyFormApprovalChainPaneMounted]: PolicyFormEditPayload;

  // Scoped Search
  [PrimitivesAnalyticsEvents.ScopedSearchResultSelected]: ScopedSearchSelectedPayload;
  [PrimitivesAnalyticsEvents.TableRowClickedForScopedSearch]: ScopedSearchTableRowPayload;

  // Global Search
  [PrimitivesAnalyticsEvents.GlobalSearchResultSelected]: GlobalSearchSelectedPayload;
  [PrimitivesAnalyticsEvents.GlobalSearchIntentTypeSelected]: GlobalSearchIntentTypeSelectedPayload;

  // Integrations - List
  [PrimitivesAnalyticsEvents.IntegrationsListViewMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsERPListViewMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListSelectSuggestion]: SearchTermPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListSelectSearchTerm]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListChangeSearchTerm]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListConnectClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListManageClicked]: IntegrationsListPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListDisconnectClicked]: IntegrationsListPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListDisconnectBankfeedClicked]: IntegrationsListPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListRefreshAttemptClicked]: IntegrationsListPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListRefreshAttemptSuccess]: IntegrationsListPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListRefreshAttemptFailure]: IntegrationsListPayload;
  [PrimitivesAnalyticsEvents.IntegrationsListUpdateCredentialsClicked]: IntegrationsListPayload;
  // Integrations - Connection Flow
  [PrimitivesAnalyticsEvents.IntegrationsConnectERPViewMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsConnectERPNoVendor]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsConnectERPNoIntegration]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsSetupERPViewMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsReconnectViewMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsConnectERPCannotFindIntegrationClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.IntegrationsConnectERPMultiEntityNotSupportedBannerCustomAccountingOptionClicked]: IntegrationsConnectBannerCustomAccountingOptionClicked;
  [PrimitivesAnalyticsEvents.IntegrationsConnectERPStepContinueClicked]: IntegrationsConnectERPStepCTAClickPayload;
  [PrimitivesAnalyticsEvents.IntegrationsConnectERPStepBackClicked]: IntegrationsConnectERPStepCTAClickPayload;
  [PrimitivesAnalyticsEvents.IntegrationsConnectERPStepExitClicked]: IntegrationsConnectERPStepCTAClickPayload;
  // Integrations - Detail
  [PrimitivesAnalyticsEvents.IntegrationsDetailViewMounted]: EmptyPayload;
  // Integrations - GL Selection
  [PrimitivesAnalyticsEvents.IntegrationsGlSelectionClicked]: IntegrationsGlSelectionPayload;
  [PrimitivesAnalyticsEvents.IntegrationsManagementGlAccountTypeInvalid]: IntegrationManagementGlAccountPayload;
  [PrimitivesAnalyticsEvents.IntegrationsManagementGlAccountTypeValidEmpty]: IntegrationManagementGlAccountPayload;
  // Integrations - Update accounting integration settings
  [PrimitivesAnalyticsEvents.IntegrationsUpdateExportCadenceClicked]: IntegrationsUpdateExportCadencePayload;
  [PrimitivesAnalyticsEvents.IntegrationsUpdateTransactionExportTypeClicked]: IntegrationsUpdateTransactionExportTypePayload;
  [PrimitivesAnalyticsEvents.IntegrationsUpdateNetsuiteNameFieldSettingsClicked]: IntegrationsUpdateNetsuiteNameFieldSettingsClickedPayload;
  // Integrations - Details - Management
  [PrimitivesAnalyticsEvents.IntegrationsManagementArticleRowMounted]: IntegrationArticleRowPayload;
  [PrimitivesAnalyticsEvents.IntegrationsManagementArticleRowCtaClicked]: IntegrationArticleRowPayload;
  // Integrations - HRIS Detail
  [PrimitivesAnalyticsEvents.IntegrationsHrisMapEntitiesClicked]: EmptyPayload;
  // Uber for Business Integration
  [PrimitivesAnalyticsEvents.UberIntegrationDisconnectClicked]: EmptyPayload;
  // Implementation Panel
  [PrimitivesAnalyticsEvents.ImplementationSetupViewMounted]: ImplementationSetupViewedPayload;
  [PrimitivesAnalyticsEvents.ImplementationSetupSeeWhereClicked]: ImplementationSetupViewedPayload;
  // Cash Account Overview
  [PrimitivesAnalyticsEvents.CashAccountsOverviewTableRowClicked]: CashAccountOverviewTableRowClickedPayload;
  // Cash Statements
  [PrimitivesAnalyticsEvents.CashBulkStatementsDownloadClicked]: CashBulkStatementsDownloadClickedPayload;
  [PrimitivesAnalyticsEvents.CashStatementsAllAccountsTableRowClicked]: CashStatementsAllAccountsTableRowClickedPayload;
  // Cash Transactions
  [PrimitivesAnalyticsEvents.EmpowerCashTransactionsPageMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsTableRowClicked]: CashTransactionsTableRowClickedPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsTableLoadMoreTransactionsClicked]: CashTransactionsTableLoadMoreTransactionsClickedPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsEmptyTableDepositMoneyClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsTableV2Filtered]: CashTransactionsTableV2FilteredPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsTableFiltered]: CashTransactionsTableFilteredPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsDetailsPaneEditTransactionClicked]: CashTransactionsDetailsPaneEditTransactionClickedPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsDetailsPaneCancelTransactionClicked]: CashTransactionsDetailsPaneCancelTransactionClickedPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsDetailsPaneDownloadTransactionClicked]: CashTransactionsDetailsPaneDownloadTransactionClickedPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsDetailsPaneViewProcessedRecurringTransactionsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsDetailsPaneManagePositivePayMerchantsModalOpened]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsDetailsPaneAccountDetailsModalOpened]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsBlockCounterpartyConfirm]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsTrustCounterpartyConfirm]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsClearPositivePayConfirm]: CashTransactionsClearPositivePayConfirmPayload;
  [PrimitivesAnalyticsEvents.CashTransactionsFilterExportTransactionsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashEditAccountNameClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashEditAccountNameCompleted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashCloseAccountClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashAccountOverviewMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashAccountTransactionsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashAccountStatementsMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashBannerFundAccountCardClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashBannerAutopayCardClicked]: CashBannerAutopayCardPayload;
  [PrimitivesAnalyticsEvents.CashBannerAutopayMakeDefaultCompleted]: CashBannerAutopayChangePayload;
  [PrimitivesAnalyticsEvents.CashBannerManageAllocationCardClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashBannerManageAllocationCompleted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashBannerMounted]: CashBannerMountedPayload;
  [PrimitivesAnalyticsEvents.CashBannerDismissClicked]: CashBannerDismissPayload;
  [PrimitivesAnalyticsEvents.CashBannerPayrollCompleted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashBannerPayrollCardClicked]: EmptyPayload;
  // Cash Invoices
  [PrimitivesAnalyticsEvents.CashInvoicesButtonClicked]: CashInvoicesButtonClickPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesControllerMounted]: CashInvoicesControllerPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesControllerUnmounted]: CashInvoicesControllerPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesNavigated]: CashInvoicesNavigatedPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesTableActionItemClicked]: CashInvoicesTableActionItemPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesTableShowMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesTakeoverNextClicked]: CashInvoiceTakeoverPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesTakeoverBackClicked]: CashInvoiceTakeoverPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesTakeoverExitClicked]: CashInvoiceTakeoverPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesTakeoverSecondaryClicked]: CashInvoiceTakeoverPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesMutationSuccess]: CashInvoiceMutationPayload;
  [PrimitivesAnalyticsEvents.CashInvoicesMutationError]: CashInvoiceMutationPayload;
  // Task Inbox
  [PrimitivesAnalyticsEvents.TaskInboxView]: TaskInboxSectionPayload;
  [PrimitivesAnalyticsEvents.TaskInboxError]: TaskInboxSectionErrorPayload;
  [PrimitivesAnalyticsEvents.TaskInboxEmpty]: TaskInboxSectionPayload;
  [PrimitivesAnalyticsEvents.TaskInboxSectionView]: TaskInboxSectionViewPayload;
  [PrimitivesAnalyticsEvents.TaskInboxViewTasks]: TaskInboxSectionViewTasksPayLoad;
  [PrimitivesAnalyticsEvents.TaskInboxPaginationClick]: TaskInboxPaginationClickPayload;
  [PrimitivesAnalyticsEvents.TaskInboxPaginationClickError]: TaskInboxPaginationClickErrorPayload;
  [PrimitivesAnalyticsEvents.TaskInboxPartiallyMaterializedBannerView]: TaskInboxListPayload;
  [PrimitivesAnalyticsEvents.TaskInboxTaskClick]: TaskInboxTaskClickPayload;
  [PrimitivesAnalyticsEvents.TaskInboxTaskDismissClick]: TaskInboxTaskClickPayload;
  [PrimitivesAnalyticsEvents.TaskInboxTaskClose]: TaskInboxTaskClickPayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPaneView]: TaskInboxDetailsPaneViewPayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPaneError]: TaskInboxDetailsPaneErrorPayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPanePrimaryCtaClick]: TaskInboxDetailsPaneViewPayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPanePrimaryCtaClickSuccess]: TaskInboxDetailsPaneViewPayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPanePrimaryCtaClickError]: TaskInboxDetailsPaneErrorPayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPaneContactSupportTeamCtaClick]: TaskInboxDetailsPaneViewPayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPaneCloseCtaClick]: TaskInboxDetailsPaneViewPayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPaneViewExpenseCtaClick]: TaskInboxDetailsPaneViewExpensePayload;
  [PrimitivesAnalyticsEvents.TaskInboxDetailsPaneRelevantExpensesSeeMoreClick]: TaskInboxRelevantExpensesClickPayload;
  [PrimitivesAnalyticsEvents.TaskInboxBulkSelectClick]: TaskInboxBulkSelectPayload;
  [PrimitivesAnalyticsEvents.TaskInboxBulkResolveTasksClick]: TaskInboxBulkPayload;
  [PrimitivesAnalyticsEvents.TaskCompleted]: TaskCompletedPayload;
  [PrimitivesAnalyticsEvents.TaskClosed]: TaskClosedPayload;
  [PrimitivesAnalyticsEvents.TaskInboxViewUnexpectedType]: TaskInboxViewUnexpectedType;
  [PrimitivesAnalyticsEvents.TaskInboxAdditionalTasksView]: TaskInboxAdditionalTasksViewPayload;
  [PrimitivesAnalyticsEvents.TaskInboxAdditionalTasksClick]: TaskInboxAdditionalTasksClickPayload;

  // TODO: Update task inbox budget analytics to use the default task inbox ones.
  [PrimitivesAnalyticsEvents.TaskInboxBudgetRequestsCtaTap]: TaskInboxBudgetRequestsCtaPayload;
  [PrimitivesAnalyticsEvents.TaskInboxBudgetRequestsTableEmpty]: TaskInboxBudgetRequestsTableEmptyPayload;
  [PrimitivesAnalyticsEvents.TaskInboxBudgetRequestsTableView]: TaskInboxBudgetRequestsTableViewPayload;
  [PrimitivesAnalyticsEvents.TaskInboxBudgetRequestsTableError]: TaskInboxBudgetRequestsTableErrorPayload;
  // TODO: Task Inbox events to deprecate:
  [PrimitivesAnalyticsEvents.TaskInboxListError]: TaskInboxListErrorPayload;
  [PrimitivesAnalyticsEvents.TaskInboxListViewTasks]: TaskInboxListViewTasksPayLoad;
  [PrimitivesAnalyticsEvents.TaskInboxListPaginationClick]: TaskInboxListPaginationClickPayload;
  [PrimitivesAnalyticsEvents.TaskInboxListPaginationClickError]: TaskInboxListPaginationClickErrorPayload;
  [PrimitivesAnalyticsEvents.TaskInboxListTaskClick]: TaskInboxListTaskClickPayload;
  [PrimitivesAnalyticsEvents.TaskInboxListTaskClose]: TaskInboxListTaskActionPayload;
  // TODO: These can be deprecated last. Need to create proper events for our filters
  [PrimitivesAnalyticsEvents.TaskInboxListExtendedFiltersAddClick]: TaskInboxListFiltersPayload;
  [PrimitivesAnalyticsEvents.TaskInboxListFiltersClick]: TaskInboxListFiltersPayload;
  [PrimitivesAnalyticsEvents.TaskInboxListFiltersClearClick]: TaskInboxListFiltersPayload;
  [PrimitivesAnalyticsEvents.TaskInboxListFiltersAddClick]: TaskInboxListFiltersPayload;

  // Notification Center
  [PrimitivesAnalyticsEvents.NotificationCenterAlertSubscribeTap]: NotificationCenterAlertSubscribeTapPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertUnsubscribeTap]: NotificationCenterAlertUnsubscribeTapPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterSeeMoreClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertUpdateStatusError]: NotificationCenterAlertUpdateStatusErrorPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertCtaTapSuccess]: NotificationCenterAlertCtaTapSuccessPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertCtaTapFailed]: NotificationCenterAlertCtaTapFailedPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertSeeMoreTap]: NotificationCenterAlertSeeMoreTapPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertSeeLessTap]: NotificationCenterAlertSeeLessTapPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertPrimaryCtaTap]: NotificationCenterAlertCtaTapPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertSecondaryCtaTap]: NotificationCenterAlertCtaTapPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertMarkReadTap]: NotificationCenterAlertMarkReadTapPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterAlertMarkUnreadTap]: NotificationCenterAlertMarkUnreadTapPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterOverviewView]: NotificationCenterOverviewViewPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterViewError]: NotificationCenterViewErrorPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterOverviewBadgeCountError]: NotificationCenterOverviewBadgeCountErrorPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterViewAlerts]: NotificationCenterViewAlertsPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterOverviewAbandoned]: NotificationCenterOverviewAbandonedPayload;
  [PrimitivesAnalyticsEvents.NotificationCenterReadAllTap]: NotificationCenterReadAllTapPayload;

  // Receipts Bulk Easy Upload
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadFileUploadStart]: ReceiptsBulkUploadPayload;
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadFilePickerButtonClicked]: ReceiptsBulkUploadPayload;
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadFileUploadRejectedUnsupportedFileType]: ReceiptsBulkUploadPayload;
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadFileUploadRejectedMaximumFiles]: ReceiptsBulkUploadPayload;
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadFileUploadError]: ReceiptsBulkUploadPayload;
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadToastViewDetailsCTAClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadMatchSuccessToastMounted]: ReceiptsBulkMatchToastPayload;
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadMatchFailureToastMounted]: ReceiptsBulkMatchToastPayload;
  [PrimitivesAnalyticsEvents.ReceiptsEasyUploadFailedMatchesModalCTAClicked]: EmptyPayload;
  // Request Review Toast
  [PrimitivesAnalyticsEvents.RequestReviewToastMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.RequestReviewToastUndoClicked]: EmptyPayload;
  // Budget templates
  [PrimitivesAnalyticsEvents.BudgetTemplatesPageMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesEducationLearnMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesTableRowClicked]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesTableSeeMoreClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesPageEducationBannerGetHelpClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesPageTableUpdateTemplateSuccess]: BudgetTemplateTableUpdateSuccessPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesPageTableUpdateTemplateFailure]: BudgetTemplateTableUpdateErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesPageCreateTemplateClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesPageTableReorderingSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesPageTableReorderingFailure]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountPolicyCurrenciesDetailsPaneConfirmRefreshRateAlertMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountPolicyCurrenciesDetailsPaneMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountPolicyCurrenciesDetailsPaneUpdateEnabledAccountPolicyCurrenciesSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountPolicyCurrenciesDetailsPaneUpdateEnabledAccountPolicyCurrenciesFailure]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountPolicyCurrenciesDetailsPaneRefreshRatesSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.AccountPolicyCurrenciesDetailsPaneRefreshRatesFailure]: EmptyPayload;
  // Budget template details pane
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneMounted]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneConfirmPauseClicked]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneResumeClicked]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneDuplicateClicked]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneDeleteDraftClicked]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneCreateTemplateClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneCreateTemplateSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneCreateTemplateFailure]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPanePublishClicked]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneAutosaveDetailsSuccess]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneAutosaveDetailsFailure]: BudgetTemplateDetailsUpdateErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneAutosaveAdvancedBudgetControlsSuccess]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateDetailsPaneAutosaveAdvancedBudgetControlsFailure]: BudgetTemplateErrorPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateOnboardingDetailsPaneMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateOnboardingDetailsPaneCustomerHubClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateOnboardingDetailsPaneVideoSeenSuccess]: EmptyPayload;
  // Budget template - availability
  [PrimitivesAnalyticsEvents.BudgetTemplatesAvailabilityMounted]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesAvailabilitySaved]: BudgetTemplateAvailabilityPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplatesAvailabilityFailure]: BudgetTemplateAvailabilityPayload;
  // Budget template budget request organization
  [PrimitivesAnalyticsEvents.BudgetTemplateBudgetRequestOrganizationMounted]: BudgetTemplateIdPayload;
  [PrimitivesAnalyticsEvents.BudgetTemplateBudgetRequestOrganizationSaved]: BudgetTemplateBudgetRequestOrganizationSavedPayload;
  // CustomCsvExport
  [PrimitivesAnalyticsEvents.CustomCsvExportEntryButtonClicked]: CustomCsvExportEntryButtonClickedPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportDetailsPaneMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportDetailsPaneOptionsMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportDetailsPaneAccrualOptionsMounted]: Is1BrexRearchitecturePayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportDetailsPaneOptionsCancelClicked]: CustomCsvExportBasePayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportDetailsPaneOptionsBackClicked]: CustomCsvExportBasePayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportDetailsPaneOptionsNextClicked]: CustomCsvExportBasePayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportDetailsPaneCloseClicked]: CustomCsvExportBasePayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportDetailsPaneConfirmationMounted]: CustomCsvExportBasePayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportGenericErrorMounted]: Partial<
    CustomCsvExportPayload & CustomCsvERPExportPayload
  >;
  [PrimitivesAnalyticsEvents.CustomCsvExportAccrualReversalDateClicked]: CustomCsvExportAccrualReversalDateClickedPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportSyncStatusErrorMounted]: Partial<
    CustomCsvExportPayload & CustomCsvERPExportPayload
  > & {
    syncStatus?: string;
  };
  [PrimitivesAnalyticsEvents.CustomCsvExportIntegrationStatusErrorMounted]: Partial<
    CustomCsvExportPayload & CustomCsvERPExportPayload
  > & {
    integrationStatus?: string;
  };
  [PrimitivesAnalyticsEvents.CustomCsvExportTooManyTransactionsErrorMounted]: Partial<
    CustomCsvExportPayload & CustomCsvERPExportPayload
  >;
  [PrimitivesAnalyticsEvents.CustomCsvExportCreateTemplateButtonClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportCustomColumnsSaved]: CustomCsvExportColumnsSavedPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportColumnHeaderUpdated]: CustomCsvExportColumnHeaderRenamedPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportExportAsIsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportTemplateClick]: CustomCsvExportTemplateIdPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportCSVExportClicked]: CustomCsvExportPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportERPExportClicked]: CustomCsvERPExportPayload;
  [PrimitivesAnalyticsEvents.CustomCsvExportAccountingIntegrationExportClicked]: CustomCsvExportAccountingIntegrationPayload;
  // Request CTA
  [PrimitivesAnalyticsEvents.RequestCtaBudgetsRequestBudgetClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.RequestCtaBudgetsCreateBudgetClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.RequestCtaBudgetsCreateBudgetProgramClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.RequestCtaBudgetsCreateNestedBudgetClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.RequestCtaWalletRequestBudgetClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.RequestCtaReimbursementsRequestOutOfPocketClick]: RequestsCtaPayload;
  [PrimitivesAnalyticsEvents.RequestCtaReimbursementsRequestMileageClick]: RequestsCtaPayload;
  [PrimitivesAnalyticsEvents.RequestCtaReimbursementsViewDraftsClick]: RequestsCtaPayload;
  // Comment
  [PrimitivesAnalyticsEvents.CommentSendCommentAttempt]: CommentSendCommentPayload;
  [PrimitivesAnalyticsEvents.CommentSendCommentSuccess]: CommentSendCommentPayload;
  [PrimitivesAnalyticsEvents.CommentSendCommentError]: CommentSendCommentPayload;
  [PrimitivesAnalyticsEvents.CommentBulkDocumentationReminderCtaMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.CommentBulkDocumentationReminderModalMounted]: CommentBulkDocumentationReminderPayload;
  [PrimitivesAnalyticsEvents.CommentBulkDocumentationReminderSent]: CommentBulkDocumentationReminderPayload;
  [PrimitivesAnalyticsEvents.CommentBulkDocumentationReminderSentSuccess]: CommentBulkDocumentationReminderPayload;
  [PrimitivesAnalyticsEvents.CommentDocumentationReminderMounted]: CommentDocumentationReminderPayload;
  [PrimitivesAnalyticsEvents.CommentDocumentationReminderClicked]: CommentDocumentationReminderPayload;
  [PrimitivesAnalyticsEvents.CommentSuggestionAddToMemoMounted]: CommentAddToMemoSuggestionPayload;
  [PrimitivesAnalyticsEvents.CommentSuggestionAddToMemoClicked]: CommentAddToMemoSuggestionPayload;
  [PrimitivesAnalyticsEvents.CommentManuallyResolveTaskClicked]: CommentTaskPayload;
  [PrimitivesAnalyticsEvents.CommentAddTaggedEmployeesModalAddClicked]: CommentTaskPayload;
  [PrimitivesAnalyticsEvents.CommentAddTaggedEmployeesModalDismissClicked]: CommentTaskPayload;
  [PrimitivesAnalyticsEvents.CommentAddTaggedEmployeesModalCloseClicked]: CommentTaskPayload;
  [PrimitivesAnalyticsEvents.CommentRemoveTaggedEmployeesModalRemoveClicked]: CommentTaskPayload;
  [PrimitivesAnalyticsEvents.CommentRemoveTaggedEmployeesModalDismissClicked]: CommentTaskPayload;
  [PrimitivesAnalyticsEvents.CommentRemoveTaggedEmployeesModalCloseClicked]: CommentTaskPayload;
  // Automation Suggestions
  [PrimitivesAnalyticsEvents.InboxSuggestionsDropdownClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.InboxSuggestionsDropdownSuggestionCardMounted]: ExpenseMemoSuggestionPayload;
  [PrimitivesAnalyticsEvents.InboxSuggestionsBulkAcceptClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.InboxSuggestionsBulkDismissClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.InboxSuggestionsBulkUndoClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.InboxSuggestionsSingleAcceptClick]: ExpenseMemoSuggestionPayload;
  [PrimitivesAnalyticsEvents.InboxSuggestionsSingleDismissClick]: ExpenseMemoSuggestionPayload;
  [PrimitivesAnalyticsEvents.InboxSuggestionsSingleUndoClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.InboxSuggestionsViewAnotherClick]: ExpenseMemoSuggestionPayload;
  // Automations - Expense Assistant
  [PrimitivesAnalyticsEvents.ExpenseAssistantMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ExpenseAssistantCloseClick]: EmptyPayload;
  // Reporting
  [PrimitivesAnalyticsEvents.ReportingReportsPageMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReportingReportMounted]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportActionsMenuClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportDeleteReportClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportDeleteReportConfirmed]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportEditReportClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportReportNameEdited]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportDiscardChangesClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportSaveChangesClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportSaveCopyClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportDuplicateReportClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportShareReportModalOpened]: ReportingReportShareReportPayload;
  [PrimitivesAnalyticsEvents.ReportingReportShareReportModalConfirmClicked]: ReportingReportShareReportPayload;
  [PrimitivesAnalyticsEvents.ReportingReportShareReportModalConfirmSuccess]: ReportingReportShareReportPayload;
  [PrimitivesAnalyticsEvents.ReportingReportShareReportModalCancelClicked]: ReportingReportShareReportPayload;
  [PrimitivesAnalyticsEvents.ReportingReportAddWidgetClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetEditorVizTypeSelected]: ReportingWidgetEditorVizTypeSelectedPayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetEditorSaveButtonClicked]: ReportingWidgetEditorBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetEditorDiscardChangesButtonClicked]: ReportingWidgetEditorBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportFiltersAddFilterClicked]: ReportingBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportFiltersFilterAdded]: ReportingReportFiltersBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportFiltersFilterValueSelected]: ReportingReportFiltersFilterValueSelectedPayload;
  [PrimitivesAnalyticsEvents.ReportingReportFiltersFilterRemoved]: ReportingReportFiltersBasePayload;
  [PrimitivesAnalyticsEvents.ReportingReportLayoutChanged]: ReportingReportLayoutChangedPayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetLoaded]: ReportingWidgetResultPayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetActionsMenuClicked]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetCloneWidgetClicked]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetDeleteWidgetClicked]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetEditWidgetClicked]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetViewExpensesClicked]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetDownloadExpensesClicked]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetBarGroupHovered]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetBarClicked]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetFilterByClicked]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingWidgetTooltipHovered]: ReportingWidgetBasePayload;
  [PrimitivesAnalyticsEvents.ReportingDefaultStockReportSelected]: ReportingDefaultStockReportSelectedPayload;
  // Attribute mapping
  [PrimitivesAnalyticsEvents.AttributeMappingViewMounted]: AttributeMappingViewPayload;
  [PrimitivesAnalyticsEvents.AttributeMappingSubmitted]: AttributeMappingSubmitPayload;
  // Configure Billed Entities Flow - Credit and Reimbursements
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowButtonClicked]: ConfigureBillingFlowButtonClickedPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowMounted]: ConfigureBillingFlowMountedPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowLegalEntityChanged]: ConfigureBillingFlowLegalEntityChangedPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowCountryChanged]: ConfigureBillingFlowCountryChangedPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowCurrencyChanged]: ConfigureBillingFlowCurrencyChangedPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowPaymentMethodChanged]: ConfigureBillingFlowPaymentMethodChangedPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowFundingSourceChanged]: ConfigureBillingFlowFundingSourceChangedPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowBillingCycleChanged]: ConfigureBillingFlowBillingCycleChangedPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowAirwalexKycLinkClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowReviewScreenMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowTermsAndConditionsAccepted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ConfigureBillingFlowConfirmButtonClicked]: EmptyPayload;
  // Statements
  [PrimitivesAnalyticsEvents.StatementsListPaneView]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.StatementsYearExpand]: StatementsYearPayload;
  [PrimitivesAnalyticsEvents.StatementsSeeMoreClick]: StatementsYearPayload;
  [PrimitivesAnalyticsEvents.StatementsDownloadClick]: StatementsDownloadPayload;
  // Billing
  [PrimitivesAnalyticsEvents.BillingModalOpen]: BillingModalPayload;
  [PrimitivesAnalyticsEvents.BillingModalClose]: BillingModalPayload;
  [PrimitivesAnalyticsEvents.BillingSpendVisualizationGroupingChange]: SpendVisualizationGroupingPayload;
  [PrimitivesAnalyticsEvents.BillingPendingActivityCardView]: PendingActivityCardViewPayload;
  [PrimitivesAnalyticsEvents.BillingPendingActivitySectionView]: PendingActivitySectionViewPayload;
  [PrimitivesAnalyticsEvents.BillingViewPendingExpensesClick]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingTransactionDetailPaneView]: TransactionDetailPaneViewPayload;
  [PrimitivesAnalyticsEvents.BillingPaymentDetailMakePaymentClick]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingPaymentDetailManageAutopayClick]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingPaymentDetailFailureBannerView]: PaymentFailureBannerViewPayload;
  [PrimitivesAnalyticsEvents.BillingPurchaseDetailExpenseDetailClick]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingPurchaseDetailExpenseDetailClear]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingPurchaseDetailItemizeExpenseSuccess]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsPageSizeChange]: TransactionsTablePageSizePayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsPreviousPage]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsNextPage]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsGroupingChange]: TransactionsGroupingChangePayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsAddFilter]: TransactionsAddFilterPayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsClearAllFilters]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingAlertView]: BillingAlertPayload;
  [PrimitivesAnalyticsEvents.BillingAlertClick]: BillingAlertPayload;
  [PrimitivesAnalyticsEvents.BillingSeePastPaymentsClick]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsDownloadModalView]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsDownloadModalClosed]: ConsolidatedBillingPayload;
  [PrimitivesAnalyticsEvents.BillingTransactionsDownloadClick]: BillingTransactionsDownloadPayload;
  // Reimbursement Tracking
  [PrimitivesAnalyticsEvents.ReimbursementTrackingExportExpensesButtonClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementTrackingExportHistoryPaneButtonClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementTrackingDownloadHistoryButtonClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.ReimbursementTrackingExpensesExported]: ReimbursementTrackingExpensesExportedPayload;
  // Dashboard Search
  [PrimitivesAnalyticsEvents.DashboardSearchResults]: DashboardSearchResultsPayload;
  [PrimitivesAnalyticsEvents.DashboardSearchSelectResult]: DashboardSearchSelectResultPayload;
  [PrimitivesAnalyticsEvents.DashboardSearchOpened]: DashboardSearchOpenedPayload;
  [PrimitivesAnalyticsEvents.DashboardSearchClosed]: DashboardSearchClosedPayload;
  // Brex Assistant
  [PrimitivesAnalyticsEvents.BrexAssistantComplianceSettingsWaitlistJoinClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantComplianceSettingsWaitlistJoinFromUrl]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantComplianceSettingsWaitlistJoinError]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantGoogleCalendarOAuthError]: BrexAssistantCalendarOAuthPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantGoogleCalendarIntegrationCreateSuccess]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneError]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneCloseClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneSettingsClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneFTUXMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneFTUXContinueClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneFTUXConnectGoogleCalendarClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneFTUXEnableAfterCalendarConnectedClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneFTUXUpdateSettingsStart]: BrexAssistantFTUXSettingsPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneFTUXUpdateSettingsError]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneFTUXComplete]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantActivitiesUnseenCardMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantActivitiesSeenCardMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantActivitiesCardClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantActivitiesCardTaskFieldClick]: BrexAssistantActivityTaskFieldClickPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneMenuClick]: EmptyPayload;
  [PrimitivesAnalyticsEvents.BrexAssistantDetailsPaneMenuItemClick]: BrexAssistantMenuItemClickPayload;
  // Begin Vendors (payables)
  [PrimitivesAnalyticsEvents.VendorsClickSendBill]: EmptyPayload;
  [PrimitivesAnalyticsEvents.VendorsTableMounted]: VendorsTableMountedPayload;
  [PrimitivesAnalyticsEvents.VendorsFtuxAddInvoicesClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.VendorsFtuxAddVendorClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.VendorsFtuxImportFromCsvClicked]: EmptyPayload;
  // End Vendors (payables)

  // Legal entities
  [PrimitivesAnalyticsEvents.EntityDetailsPaneMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EntityDetailsPaneDeleteEntityCtaClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EntityDetailsPaneConfirmDeleteEntityCtaClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.EntityDetailsPaneSetupTaskCtaClicked]: EntitySetupTaskPayload;
  [PrimitivesAnalyticsEvents.EntityDetailsPaneSetupTaskDismissed]: EntitySetupTaskPayload;
  [PrimitivesAnalyticsEvents.EntityDetailsPaneManagePaymentsCtaClicked]: EntityBillingInteractionPayload;
  [PrimitivesAnalyticsEvents.EntityDetailsPaneViewBillingDetailsCtaClicked]: EntityBillingInteractionPayload;
  [PrimitivesAnalyticsEvents.EntityDetailsPaneViewAccountingCtaClicked]: EmptyPayload;

  // Planing setup helpers
  [PrimitivesAnalyticsEvents.PlanningSetupHelperMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PlanningSetupHelperStepMounted]: PlanningSetupHelperStepMountedPayload;
  [PrimitivesAnalyticsEvents.PlanningSetupConnectErpStepFetchErpData]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PlanningSetupConnectErpStepRefreshDataClick]: PlanningSetupConnectErpStepRefreshDataClickPayload;
  [PrimitivesAnalyticsEvents.PlanningSetupAccountSelectionStepInitialSuggestion]: PlanningSetupAccountSelectionStepInitialSuggestionPayload;
  [PrimitivesAnalyticsEvents.PlanningSetupBudgetSuggestionsStepInitialSuggestion]: PlanningSetupBudgetSuggestionsStepInitialSuggestionPayload;
  [PrimitivesAnalyticsEvents.PlanningSetupHelperBudgetsCreated]: PlanningSetupHelperBudgetsCreatedPayload;

  // Export Controller
  [PrimitivesAnalyticsEvents.ExportDownloadClick]: ExportPayload;

  // User Limit Form
  [PrimitivesAnalyticsEvents.UserLimitFormReviewPeriodChangeAlertMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.UserLimitFormAdvancedControlsClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.UserLimitFormShowPolicyClicked]: EmptyPayload;

  // Global keyboard shortcut tracking
  [PrimitivesAnalyticsEvents.GlobalKeyboardShortcutPressed]: GlobalKeyboardShortcutPressedPayload;

  // Spend limit l2
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2Mounted]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2QueryError]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2QueryRetryClicked]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2FragmentError]: SpendLimitDetailsFragmentErrorPayload;

  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2HeaderMounted]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2HeaderActionsMounted]: SpendLimitDetailsActionsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2HeaderMountedError]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2HeaderActionClicked]: SpendLimitDetailsActionPayload;

  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2DataGridMounted]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2DataGridMountedError]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2DataGridRowSelected]: SpendLimitDetailsDatagridRowSelectedPayload;

  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2DetailsPaneMounted]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2DetailsPaneMountedError]: SpendLimitDetailsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2DetailsPaneActionsMounted]: SpendLimitDetailsActionsPayload;
  [PrimitivesAnalyticsEvents.SpendLimitDetailsL2DetailsPaneActionClicked]: SpendLimitDetailsActionPayload;

  [PrimitivesAnalyticsEvents.SpendLimitActionPaneMounted]: SpendLimitActionPanePayload;
  [PrimitivesAnalyticsEvents.SpendLimitActionPaneMountedError]: SpendLimitActionPanePayload;

  // Spend limit request pane
  [PrimitivesAnalyticsEvents.SpendLimitRequestPaneMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.SpendLimitRequestPaneLimitIncreaseRequestQueryError]: EmptyPayload;
  [PrimitivesAnalyticsEvents.SpendLimitRequestPaneRequestTypesQueryError]: EmptyPayload;
  [PrimitivesAnalyticsEvents.SpendLimitRequestPaneLimitIncreaseRequestQueryRetryClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.SpendLimitRequestPaneRequestTypesQueryRetryClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.SpendLimitRequestPaneRequestIncreaseClicked]: EmptyPayload;
  [PrimitivesAnalyticsEvents.SpendLimitRequestPaneCreateNewLimitClicked]: SpendLimitRequestPaneCreateNewLimitClickedPayload;

  // Request types management pane
  [PrimitivesAnalyticsEvents.RequestTypesManagementPaneMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.RequestTypesManagementPaneTabsChanged]: RequestTypesManagementPanePayload;
  [PrimitivesAnalyticsEvents.RequestTypesManagementPaneRequestTypesQueryError]: RequestTypesManagementPanePayload;
  [PrimitivesAnalyticsEvents.RequestTypesManagementPaneRequestTypesQueryRetryClicked]: RequestTypesManagementPanePayload;
  [PrimitivesAnalyticsEvents.RequestTypesManagementPaneRequestTypesReordered]: RequestTypesManagementPaneReorderPayload;
  [PrimitivesAnalyticsEvents.RequestTypesManagementPaneCtaClicked]: RequestTypesManagementPaneCtaPayload;
  [PrimitivesAnalyticsEvents.RequestTypesManagementPaneDialogCtaClicked]: RequestTypesManagementPaneDialogCtaPayload;

  // Request types form
  [PrimitivesAnalyticsEvents.RequestTypesFormStepMounted]: RequestTypesFormPayload;
  [PrimitivesAnalyticsEvents.RequestTypesFormCtaClicked]: RequestTypesFormCtaPayload;
  [PrimitivesAnalyticsEvents.RequestTypesFormOverlayMounted]: RequestTypesFormPayload;
  [PrimitivesAnalyticsEvents.RequestTypesFormQueryFailure]: RequestTypesFormOperationPayload;
  [PrimitivesAnalyticsEvents.RequestTypesFormMutationCompleted]: RequestTypesFormOperationPayload;

  // Policy management
  [PrimitivesAnalyticsEvents.PolicyManagementPaneMounted]: EmptyPayload;
  [PrimitivesAnalyticsEvents.PolicyManagementPanePoliciesQueryFailure]: PolicyManagementPayload;
  [PrimitivesAnalyticsEvents.PolicyManagementPanePoliciesQueryRetryClicked]: PolicyManagementPayload;
  [PrimitivesAnalyticsEvents.PolicyManagementPaneTabsChanged]: PolicyManagementPayload;
  [PrimitivesAnalyticsEvents.PolicyManagementPanePolicyDetailsClicked]: PolicyManagementPolicyDetailsPayload;
  [PrimitivesAnalyticsEvents.PolicyManagementPaneCreateClicked]: PolicyManagementPayload;

  // Policy selectors
  [PrimitivesAnalyticsEvents.ExpensePolicySelectorMounted]: PolicySelectorPayload;
  [PrimitivesAnalyticsEvents.ExpensePolicySelectorPoliciesQueryFailure]: PolicySelectorPayload;
  [PrimitivesAnalyticsEvents.ExpensePolicySelectorPolicyOptionClicked]: PolicySelectorOptionPayload;
  [PrimitivesAnalyticsEvents.ExpensePolicySelectorNewPolicyClicked]: PolicySelectorPayload;
  [PrimitivesAnalyticsEvents.LimitIncreaseRequestPolicySelectorMounted]: PolicySelectorPayload;
  [PrimitivesAnalyticsEvents.LimitIncreaseRequestPolicySelectorPoliciesQueryFailure]: PolicySelectorPayload;
  [PrimitivesAnalyticsEvents.LimitIncreaseRequestPolicySelectorPolicyOptionClicked]: PolicySelectorOptionPayload;
  [PrimitivesAnalyticsEvents.LimitIncreaseRequestPolicySelectorEditPolicyClicked]: PolicySelectorOptionPayload;
  [PrimitivesAnalyticsEvents.LimitIncreaseRequestPolicySelectorNewPolicyClicked]: PolicySelectorPayload;
} & VendorFormEventSchemaMap &
  VendorPortalEventSchemaMap &
  SpendMigrationEventSchemaMap;

export type PrimitivesMetadataSchemaMap = {
  [PrimitivesAnalyticsMetadata.ReportingDashboard]: ReportingBasePayload;
  [PrimitivesAnalyticsMetadata.DataGridMetadata]: DataGridAnalyticsPayload;
};
