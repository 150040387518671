import { TokenError } from "@openid/appauth";
import { getAccessToken, refreshAccessToken } from "./tokenManagerApi";
import { trackPerformance } from "@/features/Analytics/helpers/performance";
import { trackEvent } from "@/features/Analytics/helpers/trackEvent";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import {
  destroyState,
  loadState,
  saveState,
} from "@/features/Authentication/helpers/state";
import type {
  FinishAuthResult,
  OAuthOkResponse,
  TokenExchangeRequest,
} from "@/features/Authentication/sharedTypes";

/**
 * Finish the auth process by fetching a JWT token from the token
 * manager service.
 *
 * This function can also delegate the token generation to another
 * platform (eg. the mobile app).
 *
 * This function relies on certain auth parameters existing in
 * localStorage, which are part of the OAuth configuration and set when
 * starting the auth process.
 */
export const finishAuth = async (
  result: OAuthOkResponse,
): Promise<FinishAuthResult> => {
  // retrieve important authorization state from localStorage
  // result.state corresponds to the OAuth config id and is the random string
  // we generated when initially retreiving the OAuth configuration
  const authState = loadState(result.state);

  // the state key from the OAuth service doesn't match the one we generated
  // and stored in `localStorage`, which may be a result of an expired state,
  // or an attempt to hijack a session
  if (authState === null) {
    trackEvent(AllAnalyticsEvents.AuthConfigError, {
      stateId: result.state,
    });
    return {
      result: "error",
      error: `Invalid state received`,
      id: result.state,
      type: "invalid_state",
    };
  }

  // this shouldn't happen, but just in case, let's plan accordingly as we need
  // this code to request a JWT token
  if (!result.code) {
    // remove invalid OAuth state from `localStorage`
    destroyState(authState.id);
    return {
      result: "error",
      error: "The code parameter is not present in the OAuth result",
      id: authState.id,
      state: authState.state,
      type: "no_code_param",
    };
  }

  const jwtRequestParams: TokenExchangeRequest = {
    code: result.code,
    redirectUri: authState.redirectUri,
    pkceVerifier: authState.pkceVerifier,
  };

  if (
    authState.state?.shouldDelegateAccessTokenGeneration &&
    authState.state?.delegateUri
  ) {
    return {
      result: "delegate",
      id: authState.id,
      state: authState.state,
      delegateUri: authState.state.delegateUri,
      delegateUriParameters: jwtRequestParams,
    };
  }

  if (authState.validated) {
    const requestInitiationTime = Date.now();
    const jwtResult = await refreshAccessToken();
    return {
      result: "ok",
      accessToken: jwtResult.accessToken,
      expiresIn: jwtResult.expiresIn,
      id: authState.id,
      idToken: jwtResult.idToken,
      state: authState.state,
      requestInitiationTime,
    };
  }

  try {
    trackPerformance("auth.token.fetch");
    const requestInitiationTime = Date.now();
    // retrieve a JWT token from the token manager service
    const jwtResult = await getAccessToken(jwtRequestParams);
    trackPerformance("auth.token.response", "auth.token.fetch");

    saveState({ ...authState, validated: true });

    // success, return necessary information to pass along to API services
    return {
      result: "ok",
      accessToken: jwtResult.accessToken,
      expiresIn: jwtResult.expiresIn,
      id: authState.id,
      idToken: jwtResult.idToken,
      state: authState.state,
      requestInitiationTime,
    };
  } catch (e) {
    trackPerformance("auth.token.error", "auth.token.fetch");
    const error = e instanceof TokenError ? e.error : (e as Error).message;
    return {
      result: "error",
      error,
      id: authState.id,
      state: authState.state,
      type: "JWT",
    };
  }
};
