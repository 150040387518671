export enum StepUpMfaAnalyticsEvents {
  StepUpMfaSkipped = "step.up.mfa.skipped",
  StepUpMfaRequired = "step.up.mfa.required",
  StepUpMfaPrompted = "step.up.mfa.prompted",
  StepUpMfaVerified = "step.up.mfa.verified",
  StepUpMfaFailed = "step.up.mfa.failed",
}

type StepUpMfaEventPayload = {};

type StepUpMfaSkippedPayload = StepUpMfaEventPayload & {
  mfaEnabled: boolean;
  stepUpEnabled: boolean;
  recentlyAuthed: boolean;
  shouldReauthenticate: boolean;
  sessionStatus: string | undefined;
};

export type StepUpMfaEventSchemaMap = {
  // other event metadata payloads defined above
  [StepUpMfaAnalyticsEvents.StepUpMfaSkipped]: StepUpMfaSkippedPayload;
  [StepUpMfaAnalyticsEvents.StepUpMfaRequired]: StepUpMfaEventPayload;
  [StepUpMfaAnalyticsEvents.StepUpMfaPrompted]: StepUpMfaEventPayload;
  [StepUpMfaAnalyticsEvents.StepUpMfaVerified]: StepUpMfaEventPayload;
  [StepUpMfaAnalyticsEvents.StepUpMfaFailed]: StepUpMfaEventPayload;
};
