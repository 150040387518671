import Ajv from "ajv";
import * as React from "react";
import { AnalyticsEvents } from "@/domains/Travel/analytics";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import { IFrameHandler } from "@/features/Authentication/helpers/iframeHandler";
import { getEnvironment } from "@/helpers";
import { SpotnanaVisitedLocalStorageKey } from "@/helpers/constants";
import { useTrackError } from "@/helpers/errorTracking";

const SPOTNANA_HOSTNAME = getEnvironment("SPOTNANA_EMBED_ENDPOINT")!;
const SPOTNANA_SIGNOUT_URL = `${SPOTNANA_HOSTNAME}/signout`;

// returned after successful sign out
interface SpotnanaSignoutMessagePayload {
  from: "spotnana-embed";
  type: "TOKEN_EXCHANGE_SIGNOUT";
}

// returned if login state is already signed out
interface SpotnanaLoginStatusMessagePayload {
  from: "spotnana-embed";
  type: "LOGIN_STATUS";
  payload: {
    state: "LOGGED_IN" | "LOGGED_OUT";
  };
}

const tokenExchangeSignoutSchema = {
  type: "object",
  properties: {
    type: { const: "TOKEN_EXCHANGE_SIGNOUT" },
    from: { const: "spotnana-embed" },
  },
  required: ["type", "from"],
};

const loginStatusSchema = {
  type: "object",
  properties: {
    type: { const: "LOGIN_STATUS" },
    from: { const: "spotnana-embed" },
    payload: {
      type: "object",
      properties: {
        state: { type: "string", enum: ["LOGGED_IN", "LOGGED_OUT"] },
      },
      required: ["state"],
    },
  },
  required: ["type", "from", "payload"],
};

const ajv = new Ajv();
const tokenExchangeRequestValidator =
  ajv.compile<SpotnanaSignoutMessagePayload>(tokenExchangeSignoutSchema);
const loginStatusValidator =
  ajv.compile<SpotnanaLoginStatusMessagePayload>(loginStatusSchema);

type IFrameMessagePayload =
  | SpotnanaSignoutMessagePayload
  | SpotnanaLoginStatusMessagePayload;

/**
 * We integrate with our travel partner, Spotnana, through iframes. As part of
 * logging out of Brex, we also need to log out of the corresponding Spotnana session.
 * We do this by mounting a new iframe and loading Spotnana's signout route.
 */
export const useLogoutSpotnana = () => {
  const trackEvent = useTrackEvent();
  const { trackError } = useTrackError();

  return React.useCallback(() => {
    const hasOpenedSpotnana = window.localStorage.getItem(
      SpotnanaVisitedLocalStorageKey,
    );

    if (!hasOpenedSpotnana) {
      return Promise.resolve();
    }

    window.localStorage.removeItem(SpotnanaVisitedLocalStorageKey);
    return new Promise<void>((resolve) => {
      const handler = new IFrameHandler<IFrameMessagePayload>({
        url: SPOTNANA_SIGNOUT_URL,
        callback: (data, origin) => {
          if (
            origin?.startsWith(SPOTNANA_HOSTNAME) &&
            (data.type === "TOKEN_EXCHANGE_SIGNOUT" ||
              (data.type === "LOGIN_STATUS" &&
                data.payload.state === "LOGGED_OUT"))
          ) {
            trackEvent(AnalyticsEvents.TravelSpotnanaSignoutSuccess);
            resolve();
            return true;
          }
          trackEvent(AnalyticsEvents.TravelSpotnanaSignoutFailure);
          return false;
        },
        timeout: 30 * 1000,
        timeoutCallback: () => {
          console.error("Spotnana signout timed out");
          trackEvent(AnalyticsEvents.TravelSpotnanaSignoutTimeout);
          trackError(
            "Spotnana signout timed out",
            new Error("Spotnana signout timed out"),
          );
          resolve();
        },
        errorHandler: (e) => {
          console.error("Error during Spotnana signout", e);
          trackEvent(AnalyticsEvents.TravelSpotnanaSignoutError);
          trackError(
            "Error during Spotnana signout",
            e instanceof Error ? e : new Error(),
          );
          resolve();
        },
        validator: (data) =>
          tokenExchangeRequestValidator(data) || loginStatusValidator(data),
      });
      handler.init();
    });
  }, [trackError, trackEvent]);
};
