import type { OperationDefinitionNode } from "graphql";
import type {
  Operation,
  RequestHandler,
  RetryFunction,
} from "@/features/Apollo";
import { RetryLink, ApolloLink } from "@/features/Apollo";

type Query = Operation["query"];

export enum RetryStrategyType {
  Count = "retry-count",
  Function = "retry-function",
}

type RetryStrategy =
  | {
      type: RetryStrategyType.Count;
      value: number;
    }
  | {
      type: RetryStrategyType.Function;
      value: RetryFunction;
    };

type RetryConfigOptions = {
  query: Query;
  retryStrategy: RetryStrategy;
};

type RetryStrategyImplementation = {
  query: Query;
  shouldRetry: RetryFunction;
};

const DEFAULT_RETRY_STRATEGY: RetryStrategy = {
  type: RetryStrategyType.Count,
  value: 3,
};

const INITIAL_RETRY_TIMEOUT_MS = 300;

const retryOperations = new Map<string, RetryStrategyImplementation>();

/**
 * In order to catch errors included in GraphQL requests (ie. the request is successful and the
 * body contains an errors attribute) we must throw an error so that the apollo-retry-link can
 * catch and determine if a retry should happen.
 */
const handleGqlErrors: RequestHandler = (operation, forward) => {
  const shouldRetryOp = retryOperations.has(operation.operationName);

  if (shouldRetryOp) {
    // When the onError link catches GraphQL Errors we are able to retry
    // at most once, see: https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
    // This snippet allows us to forward GQL errors onto the RetryLink.
    // See: https://github.com/apollographql/apollo-link/issues/541
    return forward(operation).map((data) => {
      const { data: gqlData, errors } = data ?? {};

      if (
        (gqlData?.errors !== undefined && gqlData.errors.length > 0) ||
        (errors !== undefined && errors.length > 0)
      ) {
        throw new Error("GraphQL Operation Error. Forwarding to RetryLink.");
      }

      return data;
    });
  }

  return forward(operation);
};

/**
 * Handle all failures from GQL functions will endup in this specific ApolloLink. By default this
 * handler will catch network errors and internal thrown errors (See:
 * https://www.apollographql.com/docs/react/data/error-handling/#retrying-operations). Registered
 * queries will be evaluated to determine if they should be retried.
 */
const handleRetryAttempt: RetryFunction = (count, operation, error) => {
  const retryOps = retryOperations.get(operation.operationName);

  if (retryOps !== undefined) {
    const { shouldRetry } = retryOps;

    return shouldRetry(count, operation, error);
  }

  return false;
};

/**
 * Register the retry handler with an initial retry timeout.
 */
export const apolloRetryNetworkErrorsLink = new RetryLink({
  attempts: handleRetryAttempt,
  delay: {
    initial: INITIAL_RETRY_TIMEOUT_MS,
    max: Infinity,
    jitter: true,
  },
});

/**
 * The apollo-retry-link only deals with internal thrown errors or network
 * response codes in the 4xx - 5xx range. This ApolloLink is registered to allow
 * retrying based on GQL errors.
 */
export const apolloRetryGqlErrorsLink = new ApolloLink(handleGqlErrors);

const makeRetryStrategyEvaluator = (strategy: RetryStrategy): RetryFunction => {
  const retryFn =
    strategy.type === RetryStrategyType.Function
      ? strategy.value
      : (count: number) => count < strategy.value;

  return (count, operation, errors) => {
    return retryFn(count, operation, errors);
  };
};

/**
 * Register a particular query for a retry. By default the query will be retried 3 times.
 *
 * @param query A gql`` query to retry
 * @param config [Optional] An options object to indicate the strategy to be used when
 * attempting a retry of the given query.
 */
export const registerQueryForRetry = (
  query: Query,
  config?: Omit<RetryConfigOptions, "query">,
) => {
  // N.B In order to get the operationName, which we will use to match
  // when evaluating retry, we must go through the query definitions and find all operations.
  query.definitions
    .filter(
      (def): def is OperationDefinitionNode =>
        (def as OperationDefinitionNode).operation != null &&
        (def as OperationDefinitionNode).name != null &&
        ["query", "mutation"].includes(
          (def as OperationDefinitionNode).operation,
        ),
    )
    .forEach((def) =>
      retryOperations.set(def.name!.value, {
        query,
        shouldRetry: makeRetryStrategyEvaluator(
          config?.retryStrategy ?? DEFAULT_RETRY_STRATEGY,
        ),
      }),
    );
};

/**
 * Really only to be used when testing, if called will not attempt to re-register
 * queries for retry.
 */
export const resetQueriesToRetry = () => retryOperations.clear();
