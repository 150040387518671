import type {
  SetDelegatorUserIdValue,
  GetDelegatorUserIdValue,
  GetDelegateUserIdValue,
  SetDelegateUserIdValue,
  GetIsInCopilotModeValue,
} from "@/features/Copilot/sharedTypes";
import { actionAtom } from "@/features/Jotai";

export const getDelegatorUserIdAtom = actionAtom<GetDelegatorUserIdValue>(
  () => undefined,
);

export const setDelegatorUserIdAtom = actionAtom<SetDelegatorUserIdValue>(
  () => undefined,
);

export const getDelegateUserIdAtom = actionAtom<GetDelegateUserIdValue>(
  () => undefined,
);

export const setDelegateUserIdAtom = actionAtom<SetDelegateUserIdValue>(
  () => undefined,
);

export const getIsInCopilotModeAtom = actionAtom<GetIsInCopilotModeValue>(
  () => undefined,
);
