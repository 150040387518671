import type { Subscription } from "zen-observable-ts";
import type { Operation, NextLink, FetchResult } from "@/features/Apollo";
import { Observable } from "@/features/Apollo";

export interface SubscriberInterface {
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}

export interface QueuedRequest {
  operation: Operation;
  forward?: NextLink;
  subscriber?: SubscriberInterface;

  // A promise is created when the query fetch request is added to the queue and
  // is resolved once the result is back from the server.
  observable?: Observable<FetchResult>;
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}

// There's no official support for refreshing token on Apollo V2 (Since it's
// deprecated) For now we are getting the idea for an observable queue from this
// (Also deprecated) old version of the "apollo-link-token-refresh" package,
// that handles token refresh for Apollo V2.
// E.g.
// https://github.com/newsiberian/apollo-link-token-refresh/blob/v0.2/src/tokenRefreshLink.ts
export class ApolloQueuing {
  public queuedRequests: QueuedRequest[] = [];

  private subscriptions: { [key: string]: Subscription } = {};

  constructor() {
    this.queuedRequests = [];
  }

  public enqueueRequest(request: QueuedRequest): Observable<FetchResult> {
    // Is this the best way to copy a request?
    const requestCopy = { ...request };

    // Whenever we want to queue up an apollo request, we check if it has an Observable.
    // If it does, we use it. If not, we manually create one.
    // We do this bc....
    requestCopy.observable =
      requestCopy.observable ??
      new Observable<FetchResult>((observer) => {
        this.queuedRequests.push(requestCopy);

        if (typeof requestCopy.subscriber === "undefined") {
          requestCopy.subscriber = {};
        }

        requestCopy.subscriber.next =
          requestCopy.next || observer.next.bind(observer);
        requestCopy.subscriber.error =
          requestCopy.error || observer.error.bind(observer);
        requestCopy.subscriber.complete =
          requestCopy.complete || observer.complete.bind(observer);
      });

    return requestCopy.observable;
  }

  public consumeQueue(): void {
    this.queuedRequests.forEach((request) => {
      const { query, variables, operationName } = request.operation;
      // https://github.com/apollographql/apollo-client/commit/07463bcb0acd21d59f08c8213facf49e10f0b3e4#diff-59d38a11f324ca5a3e9096ebe17733039aacd046b81657de078472587dd775d3L3
      const key = JSON.stringify([operationName, query, variables]);

      // If ther request doesn't have a subscriber or forward method, we don't
      // want to consume it
      if (!request.subscriber || !request.forward) {
        return () => {};
      }
      this.subscriptions[key] = request
        .forward(request.operation)
        .subscribe(request.subscriber);

      return () => {
        this.subscriptions[key].unsubscribe();
      };
    });

    this.clearQueue();
  }

  public clearQueue(): void {
    this.queuedRequests = [];
  }
}
