import * as React from "react";

type AnnouncementsContextValue = {
  setRegisteredContentId: (contentId: string) => void;
  registeredContentId: string;
};

export const AnnouncementsContext =
  React.createContext<AnnouncementsContextValue>({
    setRegisteredContentId: () => {},
    registeredContentId: "",
  });

export const AnnouncementsProvider: React.FC = React.memo(({ children }) => {
  const [registeredContentId, setRegisteredContentId] =
    React.useState<string>("");
  const value = React.useMemo(
    () => ({
      setRegisteredContentId,
      registeredContentId,
    }),
    [registeredContentId],
  );
  return (
    <AnnouncementsContext.Provider value={value}>
      {children}
    </AnnouncementsContext.Provider>
  );
});
