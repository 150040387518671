// This key is intended to be shared between Brex frontend / backend systems and applications
// so that we can sign and verify using HMAC.
// Generated by Ben Morris via command `head -c 512  /dev/urandom |base64 | fold -w 64| head -n 4` on Feb 26 2021
export const BREX_HMAC_KEY =
  "XUzg02xNVzQfHqD5uXJaa5Ywe0E5SNq6rFq2Wr4OR8/3H+kY41Lf4uAwlxQPpv4k";

/**
 * @see https://stackoverflow.com/a/9458996
 */
const _arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

/**
 *
 * The equivalent command in python are:
 *
 * ```
 * >>> import hmac
 * >>> import base64
 * >>> import hashlib
 * >>> base64.b64encode(hmac.new("XUzg02xNVzQfHqD5uXJaa5Ywe0E5SNq6rFq2Wr4OR8/3H+kY41Lf4uAwlxQPpv4k", 'cuacc_ckllong70000201o4lm5ai2ut'.encode('utf-8'), hashlib.sha512).digest())
 * ```
 *
 * Which should return:
 * 'Y3nQxU9E7aHwFawiydFP4mJr6l7QH2m/0+OdZymffchzWJzPjX5YTavOrM+JkZv/hhenio7txDVstMmVqyhCuA=='
 *
 * @param value any string value
 */
export const getSecureHash = async (value: string): Promise<string> => {
  const cryptoSubtle = window.crypto.subtle;
  const encoder = new TextEncoder();

  // convert string into binary
  const valueBinary = encoder.encode(value);
  const brexHmacKeyBinary = encoder.encode(BREX_HMAC_KEY);

  /**
   * Hash and sign
   */
  const cryptoKey = await cryptoSubtle.importKey(
    "raw",
    brexHmacKeyBinary,
    { name: "HMAC", hash: "SHA-512" },
    false,
    ["sign", "verify"],
  );
  const hashedBinary = await cryptoSubtle.sign("HMAC", cryptoKey, valueBinary);

  return _arrayBufferToBase64(hashedBinary);
};
