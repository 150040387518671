import { useTranslation } from "@brexhq/i18n";
import Cookies from "js-cookie";
import * as React from "react";
import {
  DEFAULT_LOCALE,
  LOCALE_PREFERENCE_COOKIE_NAME,
} from "@/domains/App/features/Localization/helpers/constants";
import { useChangeLanguage } from "@/domains/App/features/Localization/hooks/useChangeLanguage";
import { useIsLocalizationEnabledForUser } from "@/domains/App/features/Localization/hooks/useIsLocalizationEnabledForUser";
import { useUserLanguagePreference } from "@/domains/App/features/Localization/hooks/useUserLanguagePreference";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";

type UseSetInitialLanguageProps = {
  languagePreference?: string;
  setCookie?: boolean;
};

const useSetInitialLanguage = ({
  languagePreference,
  setCookie,
}: UseSetInitialLanguageProps) => {
  const { languageSwitcher } = useFeatureFlags();
  const { i18n } = useTranslation();
  const changeLanguage = useChangeLanguage();
  const [initialLanguageLoaded, setIntialLanguageLoaded] =
    React.useState(false);

  React.useEffect(() => {
    if (!initialLanguageLoaded && i18n.isInitialized) {
      changeLanguage({
        languagePreference: languageSwitcher
          ? (languagePreference ?? DEFAULT_LOCALE)
          : DEFAULT_LOCALE,
        setCookie,
        callback: () => setIntialLanguageLoaded(true),
      });
    }
  }, [
    languagePreference,
    initialLanguageLoaded,
    i18n,
    changeLanguage,
    languageSwitcher,
    setCookie,
  ]);

  return { initialLanguageLoaded };
};

export const useSetInitialLanguageUnauthenticated = () => {
  const languagePreference = Cookies.get(LOCALE_PREFERENCE_COOKIE_NAME);
  return useSetInitialLanguage({ languagePreference, setCookie: false });
};

export const useSetInitialLanguageAuthenticated = () => {
  const { isLocalizationEnabledForUser } = useIsLocalizationEnabledForUser();
  const languagePreference = useUserLanguagePreference();

  return useSetInitialLanguage({
    languagePreference: isLocalizationEnabledForUser
      ? languagePreference
      : undefined,
    setCookie: isLocalizationEnabledForUser && !!languagePreference,
  });
};
