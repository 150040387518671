import * as Sentry from "@sentry/browser";
import type {
  ExecutableDefinitionNode,
  FragmentDefinitionNode,
  OperationDefinitionNode,
  OperationTypeNode,
  TypeSystemDefinitionNode,
  TypeSystemExtensionNode,
} from "graphql";
import type { NextLink, Operation } from "@/features/Apollo";
import { ApolloLink, onError } from "@/features/Apollo";
import type { GqlClientName } from "@/features/Apollo/sharedTypes";

function isOperationDefinition(
  defn:
    | ExecutableDefinitionNode
    | TypeSystemDefinitionNode
    | TypeSystemExtensionNode,
): defn is OperationDefinitionNode {
  return defn.kind === "OperationDefinition";
}

function isFragmentDefinition(
  defn:
    | ExecutableDefinitionNode
    | TypeSystemDefinitionNode
    | TypeSystemExtensionNode,
): defn is FragmentDefinitionNode {
  return defn.kind === "FragmentDefinition";
}

export type SentryBreadcumbOperationInfo = {
  type: `${OperationTypeNode}` | undefined;
  name: string;
  fragments: string | undefined;
  gqlClient: GqlClientName;
};

const operationInfo = (operation: Operation): SentryBreadcumbOperationInfo => ({
  type: operation.query.definitions
    .filter(isOperationDefinition)
    .find((defn) => defn?.operation)?.operation,
  name: operation.operationName,
  fragments: operation.query.definitions
    .filter(isFragmentDefinition)
    .map((defn) => defn.name.value)
    .join(", "),
  gqlClient: "apollo",
});

export const loggingLink = new ApolloLink(
  (operation: Operation, forward?: NextLink) => {
    Sentry.addBreadcrumb({
      category: "graphql",
      level: "info",
      message: "request sent",
      data: operationInfo(operation),
    });

    if (!forward) {
      return null;
    }

    return forward(operation);
  },
);

export const errorLoggingLink = onError(
  ({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((err) =>
        Sentry.addBreadcrumb({
          category: "graphql",
          level: "warning",
          message: `graphqlError: "${err.message}"`,
          data: operationInfo(operation),
        }),
      );
    } else if (networkError) {
      Sentry.addBreadcrumb({
        category: "graphql",
        level: "warning",
        message: `networkError: "${networkError.message}"`,
        data: operationInfo(operation),
      });
    }
  },
);
