import gql from "graphql-tag";

export const customBrandingQuery = gql`
  query CustomBrandingQuery {
    user {
      id
      customBranding {
        id
        primaryColor
        darkModePrimaryColor
        mainLogo {
          id
          presignedDownloadUrl
        }
        darkModeMainLogo {
          id
          presignedDownloadUrl
        }
        squareAvatarLogo {
          id
          presignedDownloadUrl
        }
        darkModeSquareAvatarLogo {
          id
          presignedDownloadUrl
        }
      }
    }
  }
`;
