import type {
  BalanceSufficiencyLevel,
  OnboardingBlueprintType,
  OnboardingInformationRequestDescription,
  VerificationRequestType,
} from "@/__generated__/globalTypes";
import type { AutocompleteService } from "@/components/AddressInput/sharedTypes";
import type {
  OnboardingStep,
  ProductType,
} from "@/domains/Onboarding/sharedTypes";
import type { ProductApplicationStates } from "@/routes/Onboarding/modules/OnboardingVerifications/sharedTypes";

type OnboardingStepUnion = `${OnboardingStep}`;
type InformationRequestDesc =
  | `${OnboardingInformationRequestDescription}`
  | `${VerificationRequestType}`
  | "INTRO"
  | "PRODUCT_SELECTION"
  | "EXTRA";

type AllOnboardingScreenTypes =
  | OnboardingStep
  | OnboardingStepUnion
  | ProductApplicationStates;

export enum OnboardingAnalyticsEvents {
  OnboardingApplicationViewed = "onboarding.application.viewed",
  OnboardingStatementUploaded = "onboarding.statement_upload.uploaded",
  OnboardingStatementCollectionComplete = "onboarding.statement_upload.collection_complete",
  OnboardingStatementSkipped = "onboarding.statement_upload.skipped",
  PersonaEvent = "identity.onboarding_flow.persona",
  OnboardingProductSelectionClick = "onboarding.product.selection.click",
  SignupRedirectSeen = "signup.redirect.seen",
  OnboardingInformationRequestViewed = "onboarding.information_request.viewed",
  OnboardingInformationRequestSubmitted = "onboarding.information_request.submitted",
  OnboardingBusinessLeadershipBoOtherYesNoButtonClicked = "onboarding.business_leadership.bo.other.yesno.button.clicked",
  OnboardingBusinessLeadershipBoSelfYesNoButtonClicked = "onboarding.business_leadership.bo.self.yesno.button.clicked",
  OnboardingBusinessLeadershipBoListNoButtonClicked = "onboarding.business_leadership.bo.list.no.button.clicked",
  OnboardingBusinessLeadershipBoSelfFormViewed = "onboarding.business_leadership.bo.self.form.viewed",
  OnboardingBusinessLeadershipBoOtherFormViewed = "onboarding.business_leadership.bo.other.form.viewed",
  OnboardingBusinessLeadershipApplicantFormViewed = "onboarding.business_leadership.applicant.form.viewed",
  OnboardingBusinessLeadershipCoFormViewed = "onboarding.business_leadership.co.form.viewed",
  OnboardingBusinessLeadershipBoSelfContinueButtonClicked = "onboarding.business_leadership.bo.self.continue.button.clicked",
  OnboardingBusinessLeadershipCoContinueButtonClicked = "onboarding.business_leadership.co.continue.button.clicked",
  OnboardingBusinessLeadershipBoSelfBackButtonClicked = "onboarding.business_leadership.bo.self.back.button.clicked",
  OnboardingBusinessLeadershipCoBackButtonClicked = "onboarding.business_leadership.bo.co.back.button.clicked",
  OnboardingBusinessLeadershipApplicantContinueButtonClicked = "onboarding.business_leadership.applicant.continue.button.clicked",
  OnboardingBusinessLeadershipApplicantBackButtonClicked = "onboarding.business_leadership.applicant.back.button.clicked",
  OnboardingVerifyEmailScreenViewed = "onboarding.verify_email_screen.viewed",
  OnboardingPolicyClicked = "onboarding.policy.clicked",
  OnboardingManualUploadChanged = "onboarding.manual-upload.changed",
  OnboardingManualUploadFailure = "onboarding.manual-upload.failure",
  OnboardingManualUploadSuccess = "onboarding.manual-upload.success",
  OnboardingOcrUploadDocumentViewed = "onboarding.ocr.upload-document.viewed",
  OnboardingOcrUploadDocumentUploadStarted = "onboarding.ocr.upload-document.upload.started",
  OnboardingOcrUploadDocumentUploadComplete = "onboarding.ocr.upload-document.upload.complete",
  OnboardingOcrUploadDocumentSubmitted = "onboarding.ocr.upload-document.submitted",
  OnboardingOcrDocumentSubmitted = "onboarding.ocr.document.submitted",
  OnboardingOcrConfirmDetailsViewed = "onboarding.ocr.confirm-details.viewed",
  OnboardingOcrConfirmDetailsSubmitted = "onboarding.ocr.confirm-details.submitted",
  OnboardingOcrContactSupportViewed = "onboarding.ocr.contact-support.viewed",
  OnboardingOcrContactSupportContactClicked = "onboarding.ocr.contact-support.contact.clicked",
  OnboardingOcrContactSupportTryAgainClicked = "onboarding.ocr.contact-support.try-again.clicked",
  OnboardingOcrIncorrectDocumentViewed = "onboarding.ocr.incorrect-document.viewed",
  OnboardingOcrIncorrectDocumentTryAgainClicked = "onboarding.ocr.incorrect-document.try-again.clicked",
  OnboardingOcrSuccessViewed = "onboarding.ocr.success.viewed",
  OnboardingOcrSuccessDoneClicked = "onboarding.ocr.success.done.clicked",
  OnboardingFieldFocus = "onboarding.field.focus",
  OnboardingFieldBlur = "onboarding.field.blur",
  OnboardingFieldChecked = "onboarding.field.checked",
  OnboardingFieldOptionSelected = "onboarding.field.option.selected",
  OnboardingButtonClicked = "onboarding.button.clicked",
  OnboardingExperimentViewed = "onboarding.experiment.viewed",
  OnboardingAddressValidating = "onboarding.address.validating",
  OnboardingAddressValidated = "onboarding.address.validated",
  OnboardingAddressSuggested = "onboarding.address.suggested",
  OnboardingAddressV2Invalid = "onboarding.address.v2.invalid.viewed",
  OnboardingAddressV2Suggested = "onboarding.address.v2.suggested.viewed",
  OnboardingAddressV2BypassInvalid = "onboarding.address.v2.invalid.selected",
  OnboardingAddressV2BypassSuggested = "onboarding.address.v2.original.selected",
  OnboardingAddressV2SelectSuggested = "onboarding.address.v2.suggested.selected",
  OnboardingStepViewed = "onboarding.step.viewed",
  OnboardingStepSubmitted = "onboarding.step.submitted",
  OnboardingHelpClicked = "onboarding.help.clicked",
  OnboardingUserSignupActivated = "onboarding.user.signup.activated",
  OnboardingUserInvitedActivated = "onboarding.user.invited.activated",
  OnboardingBiometricsLoaded = "onboarding.biometric.loaded",
  OnboardingDocumentSummaryLength = "onboarding.document.summary.length",
  OnboardingLinkedinVerificationSkipClicked = "onboarding.linkedin.skip.clicked",
  OnboardingLinkedinVerificationConnectClicked = "onboarding.linkedin.connect.clicked",
  // Segmentation Events
  OnboardingSegmentationIndustryFormSubmitted = "Industry Form Submitted",
  OnboardingSegmentationIndustrySearchCompleted = "Industry Search Completed",
  OnboardingSegmentationIndustrySearchSelected = "Industry Search Selected",
  OnboardingSegmentationIndustrySearchSent = "Industry Search Sent",
  // Autopay
  OnboardingAutopayError = "onboarding.autopay.error",
  OnboardingSVBResponse = "onboarding.svb.response",
}

type SVBResponsePayload = {
  type: "view" | "click";
  context: "modal" | "application_pending" | "done_modal";
  product: "EmergencyBridgeLoan" | "ApplyForCash" | "ApplyForCard";
  cta?: "primary" | "secondary";
  ctaText?: string;
};

type EmptyPayload = {};

type OnboardingApplicationPayload = {
  blueprintType?: OnboardingBlueprintType;
  blueprintName?: string;
};

type OnboardingErrorPayload = {
  message?: string;
  type: string;
};

type SignupRedirectSeenSchema = {
  referrer: string;
  url: string;
};

type OnboardingStatementUploadedSchema = {
  statementsUploaded: number;
  requiredStatementsCount: number;
};

type OnboardingStatementCollectionCompleteSchema = {
  requiredStatementsCount: number;
};

type OnboardingBusinessLeadershipBoOtherYesnoPayload = {
  option: "yes" | "no";
};
type OnboardingBusinessLeadershipBoSelfYesNoPayload = {
  option: "yes" | "no";
};
type OnboardingBusinessLeadershipBoListNoButtonClickedPayload = {
  option: "yes" | "no";
};

type OnboardingInformationRequestViewedPayload = {
  informationRequest?: OnboardingInformationRequestDescription;
  type?: OnboardingInformationRequestDescription;
  blueprintType?: OnboardingBlueprintType;
  blueprintName?: string;
  enforceAddressValidation: boolean;
  brexHomeEnabled?: boolean;
};

type OnboardingInformationRequestSubmittedPayload = {
  killYellowTier?: boolean | null;
  informationRequest?: OnboardingInformationRequestDescription;
  type?: OnboardingInformationRequestDescription;
  brexHomeEnabled?: boolean;
};

type OnboardingVerifyEmailScreenViewedPayload = {
  variation: string;
};

type OnboardingProductSelectionClickPayload = {
  product: ProductType;
};

type OnboardingManualUploadPayload = {
  type: string;
};

type OnboardingFieldFocusPayload = {
  fieldName: string;
};

type OnboardingFieldBlurPayload = {
  fieldName: string;
  hasValue?: boolean;
};

// Used for tracking the inputs to PII-safe checkboxes
type OnboardingFieldCheckedPayload = {
  fieldName: string;
  value: boolean;
};

type OnboardingFieldOptionSelectedPayload = {
  fieldName: string;
};

type OnboardingButtonClickPayload = {
  id: string; // could be anything to highlight the button's origin
  label: string;
  step?: AllOnboardingScreenTypes;
  blueprintName?: string;
  informationRequestName?: InformationRequestDesc;
};

type MinimumBalancePayload = {
  experiment: "minimumBalancePolicy";
  variant: true;
  policyMinRequirement?: number;
  totalUWBalance?: number;
  balanceSufficiencyLevel?: BalanceSufficiencyLevel | null;
};

type OnboardingExperimentViewedPayload =
  | {
      experiment: string;
      variant: string | boolean;
    }
  | MinimumBalancePayload;

type OnboardingAddressValidatedPayload = {
  isDeliverable?: boolean;
  isPoBox?: boolean;
  isValid: boolean;
  autocompleteService: AutocompleteService;
};

type OnboardingOcrUploadDocumentPayload = {
  nonPdfUploadEnabled: boolean;
};

type OnboardingOcrDocumentSubmittedPayload = {
  enabledBypass: boolean;
};

type OnboardingStepViewedPayload = {
  step: AllOnboardingScreenTypes;
  informationRequestName?: InformationRequestDesc;
  blueprintType?: OnboardingBlueprintType;
  blueprintName?: string;
};

type OnboardingHelpClickedPayload = {
  slug?: string;
  step: AllOnboardingScreenTypes;
  informationRequestName?: InformationRequestDesc;
  blueprintType?: OnboardingBlueprintType;
  blueprintName?: string;
};

type OnboardingStepSubmittedPayload = {
  step: AllOnboardingScreenTypes;
  informationRequestName?: InformationRequestDesc;
  blueprintType?: OnboardingBlueprintType;
  blueprintName?: string;
  financialServices?: string;
  isReimbursementSelected?: boolean;
  isBillPaySelected?: boolean;
  isTravelSelected?: boolean;
};

type IndustrySubmittedPayload = {
  industryName: string;
};

type IndustrySearchSentPayload = {
  searchTerm: string;
};

type IndustrySearchCompletedPayload = {
  numResults: number;
  searchTerm: string;
};

type OnboardingBiometricsLoadedPayload = {
  loaded: boolean;
};

type OnboardingDocumentUploadLength = {
  length: number;
};

export type OnboardingEventSchemaMap = {
  [OnboardingAnalyticsEvents.OnboardingApplicationViewed]: OnboardingApplicationPayload;
  [OnboardingAnalyticsEvents.PersonaEvent]: any;
  [OnboardingAnalyticsEvents.OnboardingStatementUploaded]: OnboardingStatementUploadedSchema;
  [OnboardingAnalyticsEvents.OnboardingStatementCollectionComplete]: OnboardingStatementCollectionCompleteSchema;
  [OnboardingAnalyticsEvents.OnboardingStatementSkipped]: EmptyPayload;
  [OnboardingAnalyticsEvents.SignupRedirectSeen]: SignupRedirectSeenSchema;
  [OnboardingAnalyticsEvents.OnboardingInformationRequestViewed]: OnboardingInformationRequestViewedPayload;
  [OnboardingAnalyticsEvents.OnboardingInformationRequestSubmitted]: OnboardingInformationRequestSubmittedPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipBoOtherYesNoButtonClicked]: OnboardingBusinessLeadershipBoOtherYesnoPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipBoSelfYesNoButtonClicked]: OnboardingBusinessLeadershipBoSelfYesNoPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipBoSelfFormViewed]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipBoOtherFormViewed]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipApplicantFormViewed]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipCoFormViewed]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipBoSelfContinueButtonClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipBoSelfBackButtonClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipCoContinueButtonClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipCoBackButtonClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipApplicantContinueButtonClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipApplicantBackButtonClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBusinessLeadershipBoListNoButtonClicked]: OnboardingBusinessLeadershipBoListNoButtonClickedPayload;
  [OnboardingAnalyticsEvents.OnboardingVerifyEmailScreenViewed]: OnboardingVerifyEmailScreenViewedPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrUploadDocumentViewed]: OnboardingOcrUploadDocumentPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrUploadDocumentUploadStarted]: OnboardingOcrUploadDocumentPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrUploadDocumentUploadComplete]: OnboardingOcrUploadDocumentPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrUploadDocumentSubmitted]: OnboardingOcrUploadDocumentPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrDocumentSubmitted]: OnboardingOcrDocumentSubmittedPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrConfirmDetailsViewed]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrConfirmDetailsSubmitted]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrContactSupportViewed]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrContactSupportContactClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrContactSupportTryAgainClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrIncorrectDocumentViewed]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrIncorrectDocumentTryAgainClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrSuccessViewed]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingOcrSuccessDoneClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingPolicyClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingProductSelectionClick]: OnboardingProductSelectionClickPayload;
  [OnboardingAnalyticsEvents.OnboardingManualUploadChanged]: OnboardingManualUploadPayload;
  [OnboardingAnalyticsEvents.OnboardingManualUploadFailure]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingManualUploadSuccess]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingFieldFocus]: OnboardingFieldFocusPayload;
  [OnboardingAnalyticsEvents.OnboardingFieldBlur]: OnboardingFieldBlurPayload;
  [OnboardingAnalyticsEvents.OnboardingFieldChecked]: OnboardingFieldCheckedPayload;
  [OnboardingAnalyticsEvents.OnboardingFieldOptionSelected]: OnboardingFieldOptionSelectedPayload;
  [OnboardingAnalyticsEvents.OnboardingButtonClicked]: OnboardingButtonClickPayload;
  [OnboardingAnalyticsEvents.OnboardingExperimentViewed]: OnboardingExperimentViewedPayload;
  [OnboardingAnalyticsEvents.OnboardingAddressValidating]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingAddressValidated]: OnboardingAddressValidatedPayload;
  [OnboardingAnalyticsEvents.OnboardingAddressSuggested]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingStepViewed]: OnboardingStepViewedPayload;
  [OnboardingAnalyticsEvents.OnboardingStepSubmitted]: OnboardingStepSubmittedPayload;
  [OnboardingAnalyticsEvents.OnboardingHelpClicked]: OnboardingHelpClickedPayload;
  [OnboardingAnalyticsEvents.OnboardingUserSignupActivated]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingUserInvitedActivated]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingBiometricsLoaded]: OnboardingBiometricsLoadedPayload;
  [OnboardingAnalyticsEvents.OnboardingAddressV2Invalid]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingAddressV2Suggested]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingAddressV2BypassInvalid]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingAddressV2BypassSuggested]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingAddressV2SelectSuggested]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingDocumentSummaryLength]: OnboardingDocumentUploadLength;
  [OnboardingAnalyticsEvents.OnboardingLinkedinVerificationSkipClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingLinkedinVerificationConnectClicked]: EmptyPayload;
  [OnboardingAnalyticsEvents.OnboardingSegmentationIndustryFormSubmitted]: IndustrySubmittedPayload;
  [OnboardingAnalyticsEvents.OnboardingSegmentationIndustrySearchSelected]: IndustrySubmittedPayload;
  [OnboardingAnalyticsEvents.OnboardingSegmentationIndustrySearchSent]: IndustrySearchSentPayload;
  [OnboardingAnalyticsEvents.OnboardingSegmentationIndustrySearchCompleted]: IndustrySearchCompletedPayload;
  [OnboardingAnalyticsEvents.OnboardingAutopayError]: OnboardingErrorPayload;
  [OnboardingAnalyticsEvents.OnboardingSVBResponse]: SVBResponsePayload;
};
