import {
  controllerMountPerformanceMark,
  hasPerformanceMark,
} from "@/domains/App/components/Controller/helpers/performanceMarkHelpers";
import type { UseControllerContextReturn } from "@/domains/App/components/Controller/sharedTypes";

export const getLoadingControllerNames = (
  controllerContext: UseControllerContextReturn | undefined,
) => {
  const loadingControllerNames: string[] = [];
  if (controllerContext) {
    let controllers = controllerContext.ancestorControllersSessionMap;
    if (
      controllerContext.controllerName &&
      controllerContext.controllerSessionId
    ) {
      controllers = {
        ...controllers,
        [controllerContext.controllerSessionId]:
          controllerContext.controllerName,
      };
    }

    Object.entries(controllers).forEach(
      ([controllerSessionId, controllerName]) => {
        // if a controller has a mount performance mark, it means it's not ready. We remove this mark once a controller is ready
        const performanceMark =
          controllerMountPerformanceMark(controllerSessionId);
        if (hasPerformanceMark(performanceMark)) {
          loadingControllerNames.push(controllerName);
        }
      },
    );
  }
  return loadingControllerNames;
};

export const getIsControllerReady = (
  controllerContext: UseControllerContextReturn | undefined,
) => {
  if (controllerContext) {
    // if a controller doesn't have a mount performance mark, it means it's already marked as ready. We remove this mark once a controller is ready
    const performanceMark = controllerMountPerformanceMark(
      controllerContext.controllerSessionId,
    );
    return !hasPerformanceMark(performanceMark);
  }
  return false;
};

const getDurationFromControllerMount = (controllerSessionId: string) => {
  const performanceMark = controllerMountPerformanceMark(controllerSessionId);

  if (hasPerformanceMark(performanceMark)) {
    return (
      performance.now() -
      window.performance.getEntriesByName(performanceMark)[0].startTime
    );
  }
};

export const getRequestTimeDifferencesFromControllerMount = (
  controllerContext: UseControllerContextReturn | undefined,
) => {
  const requestTimeDifferencesFromControllerMount: Record<string, number> = {};

  if (controllerContext) {
    let controllers = controllerContext.ancestorControllersSessionMap;
    if (
      controllerContext.controllerName &&
      controllerContext.controllerSessionId
    ) {
      controllers = {
        ...controllers,
        [controllerContext.controllerSessionId]:
          controllerContext.controllerName,
      };
    }

    Object.entries(controllers).forEach(
      ([controllerSessionId, controllerName]) => {
        const duration = getDurationFromControllerMount(controllerSessionId);
        if (duration) {
          requestTimeDifferencesFromControllerMount[controllerName] = duration;
        }
      },
    );
  }
  return requestTimeDifferencesFromControllerMount;
};

const getControllerSessionIdFromName = (
  ancestorControllersSessionMap: Record<string, string>,
  controllerName: string,
) => {
  return Object.entries(ancestorControllersSessionMap).find(
    ([_controllerSessionId, name]) => name === controllerName,
  )?.[0];
};

const getViewControllerSessionId = (
  controllerContext: UseControllerContextReturn,
) => {
  const currentControllerDepth = controllerContext.depthAfterPageContext;

  if (currentControllerDepth) {
    const viewControllerIndex =
      controllerContext.ancestorControllerNames.length -
      currentControllerDepth +
      1;
    const viewControllerName =
      controllerContext.ancestorControllerNames[viewControllerIndex];
    const viewControllerSessionId = getControllerSessionIdFromName(
      controllerContext.ancestorControllersSessionMap,
      viewControllerName,
    );

    const viewConotrollerPerformanceMark = controllerMountPerformanceMark(
      viewControllerSessionId,
    );

    // If the ViewController has a performance mark, then it means this is a page load
    // If not, then a controller is mounted after the page loaded (e.g. expense/budget details pane).
    // In the latter case, we should grab the top-most controller that's in a loading state (i.e. has a mount performance mark)
    if (hasPerformanceMark(viewConotrollerPerformanceMark)) {
      return viewControllerSessionId;
    } else if (
      controllerContext.controllerName &&
      controllerContext.controllerSessionId
    ) {
      const controllerNames = [
        ...controllerContext.ancestorControllerNames,
        controllerContext.controllerName,
      ];
      const controllerSessionIdsMap = {
        ...controllerContext.ancestorControllersSessionMap,
        [controllerContext.controllerSessionId]:
          controllerContext.controllerName,
      };
      for (let i = viewControllerIndex; i < controllerNames.length; i++) {
        const controllerName = controllerNames[i];
        const controllerSessionId = getControllerSessionIdFromName(
          controllerSessionIdsMap,
          controllerName,
        );
        const performanceMark =
          controllerMountPerformanceMark(controllerSessionId);
        if (hasPerformanceMark(performanceMark)) {
          return controllerSessionId;
        }
      }
    }
  }
  return undefined;
};

export const getRequestTimeDifferenceFromViewControllerMount = (
  controllerContext: UseControllerContextReturn | undefined,
) => {
  if (controllerContext) {
    const viewControllerSessionId =
      getViewControllerSessionId(controllerContext);
    if (viewControllerSessionId) {
      const duration = getDurationFromControllerMount(viewControllerSessionId);
      if (duration) {
        return duration;
      }
    }
  }
  return undefined;
};
