import gql from "graphql-tag";
import { useSetAtom } from "jotai";
import * as React from "react";
import { useQuery } from "@/features/Apollo";
import {
  refetchSeenFlagsAtom,
  seenFlagsDataAtom,
  seenFlagsNetworkStatusAtom,
} from "@/features/SeenFlag/atoms/seenFlagsAtoms";
import { allSeenFlags } from "@/features/SeenFlag/helpers";

export type { SeenFlagsQuery } from "./__generated__/queries.generated";

export const seenFlagsQuery = gql`
  query SeenFlagsQuery($keys: [SeenFlagKey]!) {
    user {
      id
      seenFlags(keys: $keys) {
        id
        key
        value
        lastSeenAt
      }
    }
  }
`;

export const useSeenFlagsQueryWithAtoms = () => {
  const { data, refetch, networkStatus } = useQuery(seenFlagsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      keys: allSeenFlags,
    },
  });

  const setRefetch = useSetAtom(refetchSeenFlagsAtom);
  const setData = useSetAtom(seenFlagsDataAtom);
  const setNetworkStatus = useSetAtom(seenFlagsNetworkStatusAtom);

  React.useEffect(() => {
    setRefetch({ refetch });
  }, [refetch, setRefetch]);

  React.useEffect(() => {
    setData(data);
  }, [data, setData]);

  React.useEffect(() => {
    setNetworkStatus(networkStatus);
  }, [networkStatus, setNetworkStatus]);

  return null;
};
