import { stringify } from "qs";
import type { JsonObject } from "type-fest";
import { trackEvent } from "@/features/Analytics/helpers/trackEvent";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import {
  getEnvironmentConfig,
  getOAuthServiceConfig,
} from "@/features/Authentication/helpers/config";
import { finishAuth } from "@/features/Authentication/helpers/finishAuth";
import { getTokenIssuer } from "@/features/Authentication/helpers/getTokenExpirationTime";
import { isOAuthErrorResponse } from "@/features/Authentication/helpers/oauth";
import { startAuth } from "@/features/Authentication/helpers/startAuth";
import {
  destroyState,
  purgeExpiredStates,
} from "@/features/Authentication/helpers/state";
import type {
  AuthOptions,
  AuthResult,
  EndSessionOptions,
  EndSessionResult,
  OAuthErrorResponse,
} from "@/features/Authentication/sharedTypes";
import { getEnvironment } from "@/helpers/environment";
import { internalTrackError } from "@/helpers/errorTracking";

/**
 * Begin an OpenID session.
 * This should only be called once when a user first accesses the Dashboard.
 */
export const beginOpenIdSession = async (
  options?: AuthOptions,
): Promise<AuthResult> => {
  trackEvent(AllAnalyticsEvents.BeginOpenIDSession, {
    ...options,
    extras: null,
  });

  // remove any expired auth states in localStorage
  purgeExpiredStates();

  // trigger reauthenticaton when prompt login is provided
  if (options?.prompt === "login") {
    return startAuth({
      interactive: true,
      ...options,
    });
  }

  // start the auth process
  const startAuthResult = await startAuth({
    ...options,
  });

  // interactive auth, redirect to our OAuth provider
  if (startAuthResult.result === "redirect") {
    return startAuthResult;
  }

  if (startAuthResult.result === "response") {
    // non error OAuth response
    if (!isOAuthErrorResponse(startAuthResult.response)) {
      // finish the auth process by attempting to fetch a JWT token
      const finishAuthResult = await finishAuth(startAuthResult.response);
      if (finishAuthResult.result === "error") {
        // if we were unable to get auth state from localStorage
        if (finishAuthResult.type === "invalid_state" && !options?.isRetry) {
          // retry session once more
          return beginOpenIdSession({ ...options, isRetry: true });
        }
      }
      return finishAuthResult;
    } else {
      const oAuthResponse = startAuthResult.response as OAuthErrorResponse;
      // attempt to remove state associated with this OAuth session
      destroyState(oAuthResponse.state);
      // since we're redirecting to the OAuth provider, track the response error
      // immediately as it won't be caught otherwise
      trackEvent(AllAnalyticsEvents.AuthError, {
        message: `OAuth: ${oAuthResponse.error}`,
        origin: "beginOpenIdSession.startAuth",
        state: oAuthResponse.state,
      });
    }
  }

  // something unexpected happened, redirect to our OAuth provider to login
  return startAuth({
    interactive: true,
    ...options,
  });
};

/**
 * End the current OpenID session.
 */
const oktaApiEndpoint = getEnvironment("OKTA_API_ENDPOINT");
const sessionEndpoint = `${oktaApiEndpoint}/sessions/me`;

export const endOpenIdSession = async (
  options?: EndSessionOptions,
): Promise<EndSessionResult> => {
  trackEvent(AllAnalyticsEvents.EndOpenIdSession, {
    hasIdToken: !!options?.idToken,
    hasSession: !!options?.state?.session,
    hasToken: !!options?.state?.token,
    originalLocation: options?.state?.originalLocation,
    selectedProduct: options?.state?.selectedProduct,
    postLogoutRedirectUri: options?.postLogoutRedirectUri,
  });
  // the idToken from the token manager service should always be present, but
  // if not, exit as it's required to properly log out of the OAuth provider
  if (!options?.idToken) {
    try {
      await fetch(sessionEndpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
    } catch (e) {}
    return {
      result: "ok",
    };
  }

  // logout based on issuer
  const issuer = getTokenIssuer(options.idToken!);

  // without an issuer, we can't log out properly. exit instead.
  if (!issuer) {
    return {
      result: "ok",
    };
  }

  try {
    const { endSessionEndpoint } = await getOAuthServiceConfig();
    let endSessionUrl = endSessionEndpoint;

    // check for invalid end session url
    if (!endSessionUrl?.startsWith(issuer)) {
      // refetch url with issuer
      const { endSessionEndpoint: updatedEndSessionEndpoint } =
        await getOAuthServiceConfig({ endpointOverride: issuer });
      endSessionUrl = updatedEndSessionEndpoint;
    }

    const environmentConfig = getEnvironmentConfig();

    const postLogoutRedirectUri =
      options.postLogoutRedirectUri || environmentConfig.postLogoutRedirectUri;

    // this should never happen, but may be a result of our OAuth service being
    // down or having issues
    if (!endSessionUrl) {
      trackEvent(AllAnalyticsEvents.AuthError, {
        message: "Missing endSessionEndpoint",
        origin: "endOpenIdSession",
      });
      return {
        result: "error",
        error: "Missing endSessionEndpoint",
      };
    }

    const params: JsonObject = {
      id_token_hint: options.idToken,
      post_logout_redirect_uri: postLogoutRedirectUri,
    };

    if (options.state) {
      params.state = JSON.stringify(options.state);
    }

    const search = stringify(params);

    return {
      result: "redirect",
      redirectUri: `${endSessionUrl}?${search}`,
    };
  } catch (e) {
    internalTrackError({
      error: e as Error,
      context: {
        origin: "endOpenIdSession",
      },
    });
    return {
      result: "error",
      error: (e as Error).message,
    };
  }
};
