import memoize from "memoize-one";
import { v4 as uuid } from "uuid";
import type { ErrorCode } from "@/__generated__/globalTypes";
import { notEmpty } from "@/helpers/typeHelpers";

type ConnectionCursor = string;

type DefaultNode = { id?: string };

type Edge<Node> = {
  node: Node | null;
  cursor?: ConnectionCursor | null;
} | null;

type PageInfo = {
  startCursor?: ConnectionCursor | null;
  endCursor?: ConnectionCursor | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
};

export type Connection<Node> =
  | {
      edges: readonly Edge<Node>[] | null;
      pageInfo?: PageInfo;
    }
  | null
  | undefined;

export type NodeMap<Node> = {
  [id: string]: Node;
};

export const listToNodes = memoize(
  <Node>(list: (Node | null)[] | null | undefined) =>
    list ? list.filter(notEmpty) : [],
);

export const connectionToNodes = memoize(
  <Node>(connection: Connection<Node>) =>
    connection && connection.edges
      ? connection.edges.map((x) => x?.node).filter(notEmpty)
      : [],
);

export const nodesToMap = memoize(
  <Node extends DefaultNode>(nodes: (Node | undefined)[]) =>
    nodes.reduce<NodeMap<Node>>((map, node) => {
      const id = node?.id;

      // Specifically check for undefined to allow nodes with an empty string as
      // an ID to signify an empty / default selection
      if (id !== undefined && node) {
        map[id] = node;
      }

      return map;
    }, {}),
);

export const connectionToNodesMap = memoize(
  <Node extends DefaultNode>(connection: Connection<Node>) =>
    nodesToMap(connectionToNodes(connection)),
);

// This needs to be used to omit the clientMutationId field from all schema genereated
// input types because they will have clientMutationId as a non-nullable property, due
// to the way our schema is structured at the moment. We can probably deprecate this
// once we remove clientMutationId on the API.
export type WithoutClientMutationId<Input> = Omit<Input, "clientMutationId">;

type EntityId = { typename: string; rawId: string };

// These functions help pave over the fact that our search API expects the raw entity ID
// while all of our other graphql APIs expect the base64 encoded version
export const decodeEntityId = memoize(
  (encodedId?: string): undefined | EntityId => {
    if (!encodedId) {
      return undefined;
    }
    try {
      const [typename, rawId] = atob(encodedId).split(":");
      return { typename, rawId };
    } catch (e) {
      console.error("Error in decoding entity:", encodedId);
    }
    return { typename: "", rawId: encodedId };
  },
);

export const isEncoded = memoize((encodedId?: string): boolean => {
  if (!encodedId) {
    return false;
  }
  try {
    const [, rawId] = atob(encodedId).split(":");
    return !!rawId;
  } catch (e) {
    return false;
  }
});

export const toRawEntityId = memoize(
  (encodedId?: string): string | undefined => {
    if (!encodedId) {
      return undefined;
    }
    return decodeEntityId(encodedId)?.rawId;
  },
);

/*
Small wrapper so we can avoid repeating:

mutate({
  variables: {
    input: {
      whatever: 'input',
      that_we_need: 'toprovide',
      clientMutaionId: ''
    },
  },
}),
and do

mutate(mutationInput({
  whatever: 'input',
  thatweneed: 'toprovide
}))
*/

type ExtendMutationInput<T> = T & {
  clientMutationId: string;
};

export const mutationInput = memoize(
  <T>(
    input: T = {} as T,
  ): { variables: { input: ExtendMutationInput<T> } } => ({
    variables: { input: { ...input, clientMutationId: "" } },
  }),
);

/**
 * Returns mutation variables plus a generated clientMutationId
 */
export const clientMutationInput = memoize(
  <T>(
    input: T = {} as T,
  ): { variables: { input: ExtendMutationInput<T> } } => ({
    variables: { input: { clientMutationId: uuid(), ...input } },
  }),
);

export const encodeEntityId = memoize(
  ({ rawId, typename }: EntityId): null | string =>
    rawId && typename ? btoa(`${typename}:${rawId}`) : null,
);

export type StandardClientError =
  | {
      /**
       * Error code representing an enum of known user error values used to map
       * to UI. Should be a fairly coarse characterization of an error that
       * should be sufficient for client side branching logic. Expect this to
       * remain relatively static. Domains can implement this error and use
       * errorType for further domain-specific branching logic
       */
      code: ErrorCode;

      /**
       * An optional internal message used for debugging purposes
       */
      message: string | null;

      /**
       * The path to the input field(s) that caused the error.
       */
      path?: string[];
    }
  | null
  | undefined;
