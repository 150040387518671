import * as React from "react";
import { trackPerformance } from "@/features/Analytics/helpers/performance";
import { locationBeforeSessionTimeoutStorageKey } from "@/features/SessionTimeoutModal/hooks/useSessionExpiredTimeoutEffect";
import { urls } from "@/helpers/urls";
import { useNavigate } from "@/hooks/useNavigate";

const tryParseUrl = (originalLocation?: string) => {
  if (!originalLocation) {
    return null;
  }
  try {
    return new URL(window.location.origin + originalLocation);
  } catch (e) {
    return null;
  }
};

const shouldRestoreLocation = (originalLocation?: string): boolean => {
  const parsedUrl = tryParseUrl(originalLocation);
  if (parsedUrl === null) {
    return false;
  }
  return (
    !parsedUrl.pathname.startsWith(urls.auth.logout()) &&
    !parsedUrl.pathname.startsWith(urls.auth.forgot()) &&
    !parsedUrl.pathname.startsWith(urls.auth.login()) &&
    !parsedUrl.pathname.startsWith(urls.auth.loginEmailRoot()) &&
    !parsedUrl.pathname.startsWith(urls.auth.loginFinish()) &&
    !parsedUrl.pathname.startsWith(urls.onboarding.rejected()) &&
    !parsedUrl.pathname.startsWith(urls.users.passwordReset()) &&
    !parsedUrl.pathname.startsWith(urls.users.activate()) &&
    !parsedUrl.pathname.startsWith(urls.integrations.qboDisconnected()) &&
    // This is the location where users land after getting logged out, after this
    // they get redirected to login. This ends up as the originalLocation after
    // every logout, so must not be restored if we want to have a chance at
    // redirecting to locationBeforeSessionTimeout.
    //
    // Luckily there's no reason to ever restore this since it simply redirects to
    // the default route.
    !(parsedUrl.pathname === "/" && parsedUrl.search === "?state=")
  );
};

const getLocationBeforeSessionTimeout = () => {
  try {
    const jsonValue =
      window.sessionStorage.getItem(locationBeforeSessionTimeoutStorageKey) ??
      "null";
    window.sessionStorage.removeItem(locationBeforeSessionTimeoutStorageKey);
    return JSON.parse(jsonValue);
  } catch {
    return null;
  }
};

export const useRestoreOriginalLocation = () => {
  const navigate = useNavigate();
  return React.useCallback(
    (originalLocation?: string) => {
      const locationBeforeSessionTimeout = getLocationBeforeSessionTimeout();
      // First try to restore originalLocation, which is location before direct navigation to a url.
      // i.e. someone entering dashboard.brex.com/rewards and then getting redirected to login page.
      if (originalLocation && shouldRestoreLocation(originalLocation)) {
        navigate(originalLocation, { replace: true });
      } else if (
        // Then try to restore location before an auto session timeout.
        // originalLocation should take precedence since the intent to navigate is more recent.
        locationBeforeSessionTimeout &&
        shouldRestoreLocation(locationBeforeSessionTimeout)
      ) {
        navigate(locationBeforeSessionTimeout, { replace: true });
      } else {
        // Finally, default to home
        navigate(urls.root(), { replace: true });
      }
      trackPerformance("auth.login_finish_redirect.finished");
    },
    [navigate],
  );
};
