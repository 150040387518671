import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "account-management.view": false,
  "expense.dispute.account.create": false,
  "fsp.client.connections.update": false,
  "fsp.connections.update": false,
  "fsp.team.update": false,
  "legal-entities.billing-configurations.create": false,
  "legal-entities.billing-configurations.delete": false,
  "legal-entities.billing-configurations.read": false,
  "legal-entities.billing-configurations.update": false,
  "legal-entities.legal-entities.create": false,
  "legal-entities.legal-entities.delete": false,
  "legal-entities.legal-entities.read": false,
  "legal-entities.legal-entities.update": false,
  "primitives.accounting.create": false,
  "primitives.accounting.view": false,
  "primitives.accounts.edit": false,
  "primitives.accounts.view": false,
  "primitives.billed_entity.view": false,
  "primitives.budgets.all-budgets.view": false,
  "primitives.budgets.any.edit": false,
  "primitives.budgets.any.terminate": false,
  "primitives.budgets.bookkeeper.view": false,
  "primitives.budgets.breadcrumbs.view": false,
  "primitives.budgets.budgets-audit-trail.view": false,
  "primitives.budgets.budgets-offboarding.view": false,
  "primitives.budgets.budgets-programs.view": false,
  "primitives.budgets.budgets-templates.view": false,
  "primitives.budgets.budgets-you-manage.view": false,
  "primitives.budgets.cards.view": false,
  "primitives.budgets.create.any.budget": false,
  "primitives.budgets.limit-increase.override": false,
  "primitives.budgets.nested-budgets.view": false,
  "primitives.budgets.parent-budget.reassign": false,
  "primitives.budgets.policy.edit": false,
  "primitives.budgets.request": false,
  "primitives.budgets.request-limit-increase": false,
  "primitives.budgets.terminated-budgets.view": false,
  "primitives.budgets.this-budget.view": false,
  "primitives.cards_and_limits.edit": false,
  "primitives.cards_and_limits.edit_cards": false,
  "primitives.cards_and_limits.view": false,
  "primitives.cash.view": false,
  "primitives.credit.makePayment.view": false,
  "primitives.credit.overview.view": false,
  "primitives.credit.view": false,
  "primitives.expenses.auditTrail.view": false,
  "primitives.expenses.billing-amount.view": false,
  "primitives.expenses.categorization.edit": false,
  "primitives.expenses.company.delete": false,
  "primitives.expenses.company.edit": false,
  "primitives.expenses.company.review": false,
  "primitives.expenses.receipt-validation.view": false,
  "primitives.expenses.team.review": false,
  "primitives.hris.read": false,
  "primitives.implementation_panel.view": false,
  "primitives.integrations.view": false,
  "primitives.navigation.accountName.view": false,
  "primitives.policies.edit": false,
  "primitives.policies.review-chain.view": false,
  "primitives.policies.view": false,
  "primitives.referrals.view": false,
  "primitives.reimbursements.can.accept.tc": false,
  "primitives.reimbursements.final.approve.cash": false,
  "primitives.repayments.edit": false,
  "primitives.reports.company.update": false,
  "primitives.reports.employee.view": false,
  "primitives.reports.manager.view": false,
  "primitives.reports.view": false,
  "primitives.rewards.view": false,
  "primitives.spend_insights.inbox.view": false,
  "primitives.spend_insights.settings.view": false,
  "primitives.spend_migration.table.view": false,
  "primitives.team.company.view": false,
  "primitives.team.inviteUser.view": false,
  "primitives.team.reports.view": false,
  "primitives.team.userCard.edit": false,
  "primitives.team.view": false,
  "primitives.your-wallet.cards.last4.view": false,
  "primitives.your-wallet.cards.pan.view": false,
  "primitives.your-wallet.cards.view": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {
    "primitives.budgets.cards.view": true,
    "primitives.budgets.request": true,
    "primitives.budgets.request-limit-increase": true,
    "primitives.referrals.view": true,
    "primitives.repayments.edit": true,
    "primitives.reports.employee.view": true,
    "primitives.your-wallet.cards.last4.view": true,
    "primitives.your-wallet.cards.pan.view": true,
    "primitives.your-wallet.cards.view": true,
  } as PartialPermissions,
  [UserRole.BOOKKEEPER]: {
    "legal-entities.billing-configurations.read": true,
    "legal-entities.legal-entities.read": true,
    "primitives.accounting.create": true,
    "primitives.accounting.view": true,
    "primitives.accounts.view": true,
    "primitives.billed_entity.view": true,
    "primitives.budgets.all-budgets.view": true,
    "primitives.budgets.bookkeeper.view": true,
    "primitives.budgets.cards.view": true,
    "primitives.budgets.nested-budgets.view": true,
    "primitives.budgets.request": true,
    "primitives.budgets.request-limit-increase": true,
    "primitives.budgets.terminated-budgets.view": true,
    "primitives.budgets.this-budget.view": true,
    "primitives.cards_and_limits.view": true,
    "primitives.credit.view": true,
    "primitives.expenses.auditTrail.view": true,
    "primitives.expenses.billing-amount.view": true,
    "primitives.expenses.categorization.edit": true,
    "primitives.expenses.company.edit": true,
    "primitives.implementation_panel.view": true,
    "primitives.integrations.view": true,
    "primitives.navigation.accountName.view": true,
    "primitives.referrals.view": true,
    "primitives.repayments.edit": true,
    "primitives.reports.view": true,
    "primitives.team.company.view": true,
    "primitives.team.view": true,
    "primitives.your-wallet.cards.last4.view": true,
    "primitives.your-wallet.cards.pan.view": true,
    "primitives.your-wallet.cards.view": true,
  } as PartialPermissions,
  [UserRole.ADMIN]: {
    "account-management.view": true,
    "expense.dispute.account.create": true,
    "fsp.client.connections.update": true,
    "fsp.connections.update": true,
    "fsp.team.update": true,
    "legal-entities.billing-configurations.create": true,
    "legal-entities.billing-configurations.delete": true,
    "legal-entities.billing-configurations.read": true,
    "legal-entities.billing-configurations.update": true,
    "legal-entities.legal-entities.create": true,
    "legal-entities.legal-entities.delete": true,
    "legal-entities.legal-entities.read": true,
    "legal-entities.legal-entities.update": true,
    "primitives.accounting.create": true,
    "primitives.accounting.view": true,
    "primitives.accounts.edit": true,
    "primitives.accounts.view": true,
    "primitives.billed_entity.view": true,
    "primitives.budgets.all-budgets.view": true,
    "primitives.budgets.any.edit": true,
    "primitives.budgets.any.terminate": true,
    "primitives.budgets.breadcrumbs.view": true,
    "primitives.budgets.budgets-audit-trail.view": true,
    "primitives.budgets.budgets-offboarding.view": true,
    "primitives.budgets.budgets-programs.view": true,
    "primitives.budgets.budgets-templates.view": true,
    "primitives.budgets.budgets-you-manage.view": true,
    "primitives.budgets.cards.view": true,
    "primitives.budgets.create.any.budget": true,
    "primitives.budgets.limit-increase.override": true,
    "primitives.budgets.nested-budgets.view": true,
    "primitives.budgets.parent-budget.reassign": true,
    "primitives.budgets.policy.edit": true,
    "primitives.budgets.request": true,
    "primitives.budgets.request-limit-increase": true,
    "primitives.budgets.terminated-budgets.view": true,
    "primitives.budgets.this-budget.view": true,
    "primitives.cards_and_limits.edit": true,
    "primitives.cards_and_limits.edit_cards": true,
    "primitives.cards_and_limits.view": true,
    "primitives.credit.makePayment.view": true,
    "primitives.credit.overview.view": true,
    "primitives.credit.view": true,
    "primitives.expenses.auditTrail.view": true,
    "primitives.expenses.billing-amount.view": true,
    "primitives.expenses.categorization.edit": true,
    "primitives.expenses.company.delete": true,
    "primitives.expenses.company.edit": true,
    "primitives.expenses.company.review": true,
    "primitives.expenses.receipt-validation.view": true,
    "primitives.hris.read": true,
    "primitives.implementation_panel.view": true,
    "primitives.integrations.view": true,
    "primitives.navigation.accountName.view": true,
    "primitives.policies.edit": true,
    "primitives.policies.review-chain.view": true,
    "primitives.policies.view": true,
    "primitives.referrals.view": true,
    "primitives.reimbursements.can.accept.tc": true,
    "primitives.repayments.edit": true,
    "primitives.reports.company.update": true,
    "primitives.reports.view": true,
    "primitives.rewards.view": true,
    "primitives.spend_insights.inbox.view": true,
    "primitives.spend_insights.settings.view": true,
    "primitives.spend_migration.table.view": true,
    "primitives.team.company.view": true,
    "primitives.team.inviteUser.view": true,
    "primitives.team.userCard.edit": true,
    "primitives.team.view": true,
    "primitives.your-wallet.cards.last4.view": true,
    "primitives.your-wallet.cards.pan.view": true,
    "primitives.your-wallet.cards.view": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {
    "primitives.accounts.view": true,
    "primitives.budgets.request": true,
    "primitives.cash.view": true,
  } as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {
    "primitives.cash.view": true,
    "primitives.expenses.billing-amount.view": true,
    "primitives.implementation_panel.view": true,
  } as PartialPermissions,
  [DepositsRole.ADMIN]: {
    "fsp.client.connections.update": true,
    "primitives.billed_entity.view": true,
    "primitives.budgets.any.edit": true,
    "primitives.budgets.budgets-you-manage.view": true,
    "primitives.budgets.policy.edit": true,
    "primitives.cash.view": true,
    "primitives.credit.makePayment.view": true,
    "primitives.credit.overview.view": true,
    "primitives.credit.view": true,
    "primitives.expenses.billing-amount.view": true,
    "primitives.expenses.inbox.view": true,
    "primitives.implementation_panel.view": true,
    "primitives.integrations.view": true,
    "primitives.navigation.accountName.view": true,
    "primitives.reimbursements.final.approve.cash": true,
    "primitives.team.company.view": true,
    "primitives.team.view": true,
  } as PartialPermissions,
  null: {} as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {} as PartialPermissions,
  true: {
    "primitives.budgets.budgets-you-manage.view": true,
    "primitives.expenses.team.review": true,
    "primitives.reports.manager.view": true,
    "primitives.team.reports.view": true,
    "primitives.team.view": true,
  } as PartialPermissions,
};

// permissionsEligibleForCopilot determines what permissions are eligible for use in Copilot mode.
// Please use caution around what should be set to true and see go/td-wiki for details.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "account-management.view": false,
  "expense.dispute.account.create": true,
  "fsp.client.connections.update": false,
  "fsp.connections.update": false,
  "fsp.team.update": false,
  "legal-entities.billing-configurations.create": false,
  "legal-entities.billing-configurations.delete": false,
  "legal-entities.billing-configurations.read": false,
  "legal-entities.billing-configurations.update": false,
  "legal-entities.legal-entities.create": false,
  "legal-entities.legal-entities.delete": false,
  "legal-entities.legal-entities.read": false,
  "legal-entities.legal-entities.update": false,
  "primitives.accounting.create": false,
  "primitives.accounting.view": false,
  "primitives.accounts.edit": false,
  "primitives.accounts.view": false,
  "primitives.billed_entity.view": false,
  "primitives.budgets.all-budgets.view": true,
  "primitives.budgets.any.edit": true,
  "primitives.budgets.any.terminate": true,
  "primitives.budgets.bookkeeper.view": true,
  "primitives.budgets.breadcrumbs.view": true,
  "primitives.budgets.budgets-audit-trail.view": true,
  "primitives.budgets.budgets-offboarding.view": false,
  "primitives.budgets.budgets-programs.view": false,
  "primitives.budgets.budgets-templates.view": false,
  "primitives.budgets.budgets-you-manage.view": true,
  "primitives.budgets.cards.view": false,
  "primitives.budgets.create.any.budget": true,
  "primitives.budgets.limit-increase.override": false,
  "primitives.budgets.nested-budgets.view": true,
  "primitives.budgets.parent-budget.reassign": true,
  "primitives.budgets.policy.edit": true,
  "primitives.budgets.request": true,
  "primitives.budgets.request-limit-increase": true,
  "primitives.budgets.terminated-budgets.view": true,
  "primitives.budgets.this-budget.view": true,
  "primitives.cards_and_limits.edit": true,
  "primitives.cards_and_limits.edit_cards": false,
  "primitives.cards_and_limits.view": true,
  "primitives.cash.view": false,
  "primitives.credit.makePayment.view": false,
  "primitives.credit.overview.view": false,
  "primitives.credit.view": false,
  "primitives.expenses.auditTrail.view": true,
  "primitives.expenses.billing-amount.view": true,
  "primitives.expenses.categorization.edit": true,
  "primitives.expenses.company.delete": false,
  "primitives.expenses.company.edit": true,
  "primitives.expenses.company.review": true,
  "primitives.expenses.receipt-validation.view": true,
  "primitives.expenses.team.review": true,
  "primitives.hris.read": false,
  "primitives.implementation_panel.view": false,
  "primitives.integrations.view": false,
  "primitives.navigation.accountName.view": true,
  "primitives.policies.edit": false,
  "primitives.policies.review-chain.view": false,
  "primitives.policies.view": false,
  "primitives.referrals.view": false,
  "primitives.reimbursements.can.accept.tc": true,
  "primitives.reimbursements.final.approve.cash": true,
  "primitives.repayments.edit": false,
  "primitives.reports.company.update": false,
  "primitives.reports.employee.view": false,
  "primitives.reports.manager.view": false,
  "primitives.reports.view": false,
  "primitives.rewards.view": false,
  "primitives.spend_insights.inbox.view": true,
  "primitives.spend_insights.settings.view": false,
  "primitives.spend_migration.table.view": false,
  "primitives.team.company.view": false,
  "primitives.team.inviteUser.view": false,
  "primitives.team.reports.view": false,
  "primitives.team.userCard.edit": false,
  "primitives.team.view": false,
  "primitives.your-wallet.cards.last4.view": true,
  "primitives.your-wallet.cards.pan.view": false,
  "primitives.your-wallet.cards.view": false,
};
