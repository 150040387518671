import type { BackendModule } from "i18next";
import {
  COMPONENT_LIBRARY,
  transformToMetalFolderName,
  WEB_PACKAGE_NAMESPACE_AFFIX,
} from "@/domains/App/features/Localization/helpers/transformMetalPackageName";

const combinedResourcesCache = new Map<string, any>();

export const createLocalizationBackend = (): BackendModule => {
  return {
    type: "backend",
    init: () => {},
    read: (requestedLocale, requestedNamespace, callback) => {
      const locale = requestedLocale === "debug" ? "en-US" : requestedLocale;

      let namespace: string;
      if (
        requestedNamespace.includes(WEB_PACKAGE_NAMESPACE_AFFIX) ||
        requestedNamespace.includes(COMPONENT_LIBRARY)
      ) {
        namespace = transformToMetalFolderName(requestedNamespace);
      } else {
        namespace = requestedNamespace;
      }

      if (typeof locale !== "string" || !locale) {
        callback(new Error("Language not specified"), null);
      }

      // Load the combined resources synchronously if they are already cached
      if (combinedResourcesCache.has(locale)) {
        const cachedNamespaceResource = combinedResourcesCache.get(locale)[
          namespace
        ] || { body: [] };
        callback(null, cachedNamespaceResource);
        return;
      }

      import(
        /* webpackChunkName: "i18n/combined-[request]" */
        `@/locales/${locale}.json`
      )
        .then((resourceModule) => {
          const resource = resourceModule.default;
          const namespaceResource = resource[namespace] || { body: [] };

          combinedResourcesCache.set(locale, resource);

          callback(null, namespaceResource);
        })
        .catch(() => {
          callback(
            new Error("Cannot load FTL for namespace: " + namespace),
            null,
          );
        });
    },
  };
};
