/* eslint-disable @emotion/syntax-preference */
import { css } from "@emotion/react";
import * as ramda from "ramda";
import interNormalItalic from "@assets/fonts/Inter/Inter-Italic.woff2";
import interMedium from "@assets/fonts/Inter/Inter-Medium.woff2";
import interMediumItalic from "@assets/fonts/Inter/Inter-MediumItalic.woff2";
import interNormal from "@assets/fonts/Inter/Inter-Regular.woff2";
import interSemiBold from "@assets/fonts/Inter/Inter-SemiBold.woff2";
import interSemiBoldItalic from "@assets/fonts/Inter/Inter-SemiBoldItalic.woff2";
import interDisplayBold from "@assets/fonts/Inter/InterDisplay-Bold.woff2";
import interDisplayMedium from "@assets/fonts/Inter/InterDisplay-Medium.woff2";
import interDisplayRegular from "@assets/fonts/Inter/InterDisplay-Regular.woff2";
import interDisplaySemiBold from "@assets/fonts/Inter/InterDisplay-SemiBold.woff2";
import monoNumbersOnly from "@assets/fonts/Inter/Mono-NumbersOnly.woff2";
/**
 * To generate this font subset, run the following commands:
 * ```sh
 * pip install fonttools lxml brotli
 * cd assets/fonts
 * pyftsubset NotoColorEmoji-Regular.ttf \
 *  --output-file=NotoColorEmoji-Regular-country-flags.woff2 \
 *  --flavor=woff2 \
 *  --unicodes=U+1F1E6-1F1FF,U+1F3F4,U+E0062-E0063,U+E0065,U+E0067,U+E006C,U+E006E,U+E0073-E0074,U+E0077,U+E007F
 * ```
 */
import notoColorEmojiCountryFlags from "@assets/fonts/NotoColorEmoji-Regular-country-flags.woff2";
import robotoMonoLight from "@assets/fonts/RobotoMono-Light-300.woff2";
import spaceMonoRegular from "@assets/fonts/SpaceMono-Regular.woff2";

type FontWeights = { [key: number]: string };

const fontStylesHelper = (
  fontFamily: string,
  fontStyle: string,
  weights: FontWeights,
  unicodeRange?: string,
  fontDisplay?: string,
) =>
  ramda.pipe<FontWeights, [string, string][], string[], string>(
    ramda.toPairs,
    ramda.map(
      ([fontWeight, fontFilePath]) =>
        `
        @font-face {
          font-family: ${fontFamily};
          font-style: ${fontStyle};
          font-weight: ${fontWeight};
          src: url(${fontFilePath});
          ${unicodeRange && `unicode-range: ${unicodeRange};`}
          ${fontDisplay && `font-display: ${fontDisplay};`}
        }
      `,
    ),
    ramda.reduce(ramda.concat, ""),
  )(weights);

export const fonts = {
  inter: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSizes: { 400: interNormal, 500: interMedium, 600: interSemiBold },
  },
  interItalic: {
    fontFamily: "Inter",
    fontStyle: "italic",
    fontSizes: {
      400: interNormalItalic,
      500: interMediumItalic,
      600: interSemiBoldItalic,
    },
  },
  interDisplay: {
    fontFamily: "Inter Display",
    fontStyle: "normal",
    fontSizes: {
      400: interDisplayRegular,
      500: interDisplayMedium,
      600: interDisplaySemiBold,
      700: interDisplayBold,
    },
  },
  monoNumbersOnly: {
    fontFamily: "Mono Numbers Only",
    fontStyle: "normal",
    fontSizes: { 500: monoNumbersOnly },
  },
  robotoMonoLight: {
    fontFamily: "Roboto Mono Light",
    fontStyle: "normal",
    fontSizes: { 300: robotoMonoLight },
  },
  notoColorEmojiCountryFlags: {
    fontFamily: "Noto Color Emoji Country Flags",
    fontStyle: "normal",
    fontSizes: { 500: notoColorEmojiCountryFlags },
    // Use this font only for the specific country flag unicode characters
    unicodeRange:
      "U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067, U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F",
    // Makes sure that loading font does not block rendering characters, as it will be the first font in the font-family list.
    // This ensures that we do not show transparent characters for all characters while this font loads.
    fontDisplay: "swap",
  },
  spaceMonoRegular: {
    fontFamily: "Space Mono",
    fontStyle: "normal",
    fontSizes: { 400: spaceMonoRegular },
  },
};

export const normalFontStyles = css`
  ${fontStylesHelper(
    fonts.inter.fontFamily,
    fonts.inter.fontStyle,
    fonts.inter.fontSizes,
  )}
`;

export const italicFontStyles = css`
  ${fontStylesHelper(
    fonts.interItalic.fontFamily,
    fonts.interItalic.fontStyle,
    fonts.interItalic.fontSizes,
  )}
`;

export const displayFontStyles = css`
  ${fontStylesHelper(
    fonts.interDisplay.fontFamily,
    fonts.interDisplay.fontStyle,
    fonts.interDisplay.fontSizes,
  )}
`;

export const monoNumbersOnlyFontStyles = css`
  ${fontStylesHelper(
    fonts.monoNumbersOnly.fontFamily,
    fonts.monoNumbersOnly.fontStyle,
    fonts.monoNumbersOnly.fontSizes,
  )}
`;

export const robotoMonoLightFontStyles = css`
  ${fontStylesHelper(
    fonts.robotoMonoLight.fontFamily,
    fonts.robotoMonoLight.fontStyle,
    fonts.robotoMonoLight.fontSizes,
  )}
`;

export const notoColorEmojiCountryFlagsFontStyles = css`
  ${fontStylesHelper(
    fonts.notoColorEmojiCountryFlags.fontFamily,
    fonts.notoColorEmojiCountryFlags.fontStyle,
    fonts.notoColorEmojiCountryFlags.fontSizes,
    fonts.notoColorEmojiCountryFlags.unicodeRange,
    fonts.notoColorEmojiCountryFlags.fontDisplay,
  )}
`;

export const spaceMonoRegularFontStyles = css`
  ${fontStylesHelper(
    fonts.spaceMonoRegular.fontFamily,
    fonts.spaceMonoRegular.fontStyle,
    fonts.spaceMonoRegular.fontSizes,
  )}
`;
