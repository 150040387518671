import { atomsWithQuery, selectQueryAtom } from "@/features/Jotai";
import type { SandboxQuery } from "@/modules/Sandbox/data/__generated__/queries.generated";

export const sandboxQueryAtom = atomsWithQuery<SandboxQuery>();

export const selectSandboxQueryAtom = selectQueryAtom(
  sandboxQueryAtom,
  ({ data }) => ({
    isSandbox: data?.account?.isSandbox ?? false,
    isDemo: data?.account?.isDemo ?? false,
    hasAccessToSandbox: data?.hasAccessToSandbox ?? false,
  }),
);
