import { useAtomValue, useSetAtom } from "jotai";
import * as React from "react";
import { useAnalytics } from "@/features/Analytics/contexts/Analytics";
import { getSegmentDestinationsFromCookies } from "@/features/Analytics/helpers/cookieManagement";
import { getReferralProperties } from "@/features/Analytics/helpers/referrals";
import type { UserProperties } from "@/features/Analytics/sharedTypes";
import {
  analyticsIdentifiedAtom,
  userPropertiesAtom,
} from "@/features/Authentication/atoms/userIdentityAtoms";
import {
  useInitializeUserPropertiesQueries,
  useUserIntegrationsQuery,
} from "@/features/Authentication/data/queries";
import { selectUserIntegrationsProperties } from "@/features/Authentication/selectors/selectUserIntegrationsProperties";
import { useCopilot } from "@/features/Copilot/hooks/useCopilot";

export const useUserIdentity = (userPropertyOverrides?: UserProperties) => {
  const isCanceled = React.useRef(false);
  const { identify } = useAnalytics();
  const { getDelegatorUserId } = useCopilot();
  const setAnalyticsIdentified = useSetAtom(analyticsIdentifiedAtom);
  const [isIdentified, setIsIdentified] = React.useState(false);

  const { data: integrationsData, loading: integrationsLoading } =
    useUserIntegrationsQuery();

  useInitializeUserPropertiesQueries();

  const userProperties = useAtomValue(userPropertiesAtom);
  const identifyUser = React.useCallback(
    async (userProps?: UserProperties) => {
      // only identify the user when user properties are available
      // prevent identifying the user more than once
      // don't identify if this hook has been unmounted
      if (
        userProps !== undefined &&
        !isIdentified &&
        !isCanceled.current &&
        !integrationsLoading
      ) {
        const segmentDestinations = getSegmentDestinationsFromCookies();

        await identify(
          userProps.id,
          {
            ...userProps,
            ...getReferralProperties(),
            delegatorId: getDelegatorUserId(),
            ...userPropertyOverrides,
            ...selectUserIntegrationsProperties(integrationsData),
          },
          { integrations: segmentDestinations },
        );
        setIsIdentified(true);
        setAnalyticsIdentified(Promise.resolve(true));
      }
    },
    [
      isIdentified,
      integrationsLoading,
      identify,
      getDelegatorUserId,
      userPropertyOverrides,
      integrationsData,
      setAnalyticsIdentified,
    ],
  );

  React.useEffect(() => {
    identifyUser(userProperties);
  }, [identifyUser, userProperties]);

  // if this hook is unmounted before the user is identified, flag it so that
  // the process can be aborted.
  React.useEffect(() => {
    // we set it to false on mount because the same component instance can be remounted by Suspense & StrictMode
    isCanceled.current = false;
    return () => {
      isCanceled.current = true;
    };
  }, []);

  return {
    identifying: !isIdentified,
    userProperties,
  };
};
