import {
  useIsMobileNavExpanded,
  useIsMobileOverlayOpen,
  useToggleMobileNavExpanded,
} from "@brexhq/metal-navigation";
import { useBreakpoint } from "@brexhq/metal-utils";
import { useTheme } from "@emotion/react";
import * as React from "react";
import useToggle from "@/hooks/useToggle";

type MainLayoutContextValue = {
  isAccountMenuOpen: boolean;
  toggleAccountMenu: () => void;
  hideAccountMenu: () => void;
  supportHubVisible: boolean;
  toggleSupportHub: () => void;
  showSupportHub: () => void;
  hideSupportHub: () => void;
  sidePanePush: boolean;
  toggleSidePanePush: () => void;
};

const MainLayoutContext = React.createContext<MainLayoutContextValue>({
  isAccountMenuOpen: false,
  toggleAccountMenu: () => undefined,
  hideAccountMenu: () => undefined,
  supportHubVisible: false,
  toggleSupportHub: () => undefined,
  showSupportHub: () => undefined,
  hideSupportHub: () => undefined,
  sidePanePush: false,
  toggleSidePanePush: () => undefined,
});

export const MainLayoutProvider: React.FC = ({ children }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.tabletPortrait);
  const isMobileNavExpanded = useIsMobileNavExpanded();
  const toggleMobileNavExpanded = useToggleMobileNavExpanded();
  const { setIsMobileOverlayOpen } = useIsMobileOverlayOpen();

  const {
    on: isAccountMenuOpen,
    toggle: toggleAccountMenuVisible,
    turnOff: hideAccountMenu,
  } = useToggle();
  const {
    on: supportHubVisible,
    toggle: toggleSupportHubVisible,
    turnOn: showSupportHub,
    turnOff: hideSupportHub,
  } = useToggle(false);

  const { on: sidePanePush, toggle: toggleSidePanePush } = useToggle(false);

  const toggleAccountMenu = React.useCallback(() => {
    if (supportHubVisible && isMobile) {
      hideSupportHub();
    }
    if (isMobileNavExpanded) {
      toggleMobileNavExpanded();
    }
    toggleAccountMenuVisible();
  }, [
    supportHubVisible,
    isMobile,
    isMobileNavExpanded,
    toggleAccountMenuVisible,
    hideSupportHub,
    toggleMobileNavExpanded,
  ]);

  const toggleSupportHub = React.useCallback(() => {
    if (isAccountMenuOpen) {
      toggleAccountMenuVisible();
    }
    if (isMobileNavExpanded) {
      toggleMobileNavExpanded();
    }
    toggleSupportHubVisible();
  }, [
    isAccountMenuOpen,
    isMobileNavExpanded,
    toggleSupportHubVisible,
    toggleAccountMenuVisible,
    toggleMobileNavExpanded,
  ]);

  React.useEffect(() => {
    // Hide support hub when viewport size changes to mobile - this is required as support hub & account menu can be visible simultaneously on desktop, but only one at a time on mobile.
    if (isMobile) {
      hideSupportHub();
    }

    // Hide account menu when viewport size changes from mobile to desktop - required as desktop account menu uses PopupMenu
    if (!isMobile) {
      hideAccountMenu();
    }
  }, [isMobile, hideSupportHub, hideAccountMenu]);

  React.useEffect(() => {
    if (isMobileNavExpanded) {
      hideAccountMenu();
      hideSupportHub();
    }
  }, [hideAccountMenu, hideSupportHub, isMobileNavExpanded]);

  React.useEffect(() => {
    if (isMobile) {
      setIsMobileOverlayOpen(isAccountMenuOpen || supportHubVisible);
    }
  }, [isAccountMenuOpen, isMobile, setIsMobileOverlayOpen, supportHubVisible]);

  const value = React.useMemo(
    () => ({
      isAccountMenuOpen,
      toggleAccountMenu,
      hideAccountMenu,
      supportHubVisible,
      toggleSupportHub,
      showSupportHub,
      hideSupportHub,
      sidePanePush,
      toggleSidePanePush,
    }),
    [
      hideAccountMenu,
      hideSupportHub,
      isAccountMenuOpen,
      showSupportHub,
      sidePanePush,
      supportHubVisible,
      toggleAccountMenu,
      toggleSidePanePush,
      toggleSupportHub,
    ],
  );

  return (
    <MainLayoutContext.Provider value={value}>
      {children}
    </MainLayoutContext.Provider>
  );
};

export const useMainLayout = () => React.useContext(MainLayoutContext);
