import * as React from "react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- This file is the wrapper around this restricted import.
import { useLogout as useLogoutBrex } from "@/features/Authentication/hooks/useLogout";
import type { EndSessionOptions } from "@/features/Authentication/sharedTypes";
import { useLogoutSpotnana } from "@/features/Travel/hooks/useLogoutSpotnana";

/**
 * Handle logout for both Brex and Spotnana.
 */
export const useLogout = () => {
  const logoutBrex = useLogoutBrex();
  const logoutSpotnana = useLogoutSpotnana();

  return React.useCallback(
    (options?: EndSessionOptions) => {
      return logoutBrex(options, logoutSpotnana);
    },
    [logoutBrex, logoutSpotnana],
  );
};
