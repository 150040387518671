export enum HelpshiftAnalyticsEvents {
  HelpshiftSupportChatOpened = "helpshift.support_chat.opened",
  HelpshiftSupportChatClosed = "helpshift.support_chat.closed",
}

type EmptyPayload = {};

export type HelpshiftEventSchemaMap = {
  /** Helpshift chat opened / closed */
  [HelpshiftAnalyticsEvents.HelpshiftSupportChatOpened]: EmptyPayload;
  [HelpshiftAnalyticsEvents.HelpshiftSupportChatClosed]: EmptyPayload;
};
