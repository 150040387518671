import {
  COOKIE_BASED_AUTH_KEY,
  isCookieBasedAuth,
} from "@/features/Authentication/helpers/oauth";
import { getEnvironment } from "@/helpers";

// This is added to improve debugging and backend observability for our API service. Do NOT remove
// See https://www.apollographql.com/docs/link/links/http/#dynamic-uri for details
const getUri = ({
  operationName,
  extraSearchParams,
}: { operationName: string; extraSearchParams?: URLSearchParams }) => {
  const searchParams = extraSearchParams || new URLSearchParams();
  // operationName isn't needed by GraphQL convention, but improves the DX in tools (Networking, RUM, etc)
  searchParams.set("operationName", operationName);
  if (isCookieBasedAuth()) {
    searchParams.set(COOKIE_BASED_AUTH_KEY, "true");
  }

  const API_HOST = getEnvironment("API_HOST");
  return `${API_HOST}/v1/graphql?${searchParams.toString()}`;
};

export default getUri;
