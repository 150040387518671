export enum AnalyticsEvents {
  SandboxPageViewed = "sandbox.page.viewed",
  SandboxModeEntryClicked = "sandbox.mode_entry.clicked",
  SandboxShelfItemImportDataClicked = "sandbox.shelf_item.import_data.clicked",
  SandboxShelfItemConnectERPClicked = "sandbox.shelf_item.connect_erp.clicked",
  SandboxImportDataModalCtaClicked = "sandbox.import_data_modal.cta.clicked",
  SandboxImportDataModalClose = "sandbox.import_data_modal.close",
  SandboxImportDataModalEmailSupportClicked = "sandbox.import_data_modal.email_support.clicked",
  SandboxImportStatusAlertInProgressMounted = "sandbox.import_status_alert.in_progress.mounted",
  SandboxImportStatusAlertCompletedMounted = "sandbox.import_status_alert.completed.mounted",
  SandboxImportStatusAlertCompletedCtaClicked = "sandbox.import_status_alert.completed.cta.clicked",
  SandboxImportStatusAlertFailedMounted = "sandbox.import_status_alert.failed.mounted",
  SandboxImportStatusAlertFailedCtaClicked = "sandbox.import_status_alert.failed.cta.clicked",
  SandboxImportStatusAlertFailedClose = "sandbox.import_status_alert.failed.close",
}

type EmptyPayload = {};

type SandboxModeEntryClickedPayload = {
  isEnteringSandbox: boolean;
  origin: "top_nav" | "account_menu";
};

export type EventSchemaMap = {
  [AnalyticsEvents.SandboxPageViewed]: EmptyPayload;
  [AnalyticsEvents.SandboxModeEntryClicked]: SandboxModeEntryClickedPayload;
  [AnalyticsEvents.SandboxShelfItemImportDataClicked]: EmptyPayload;
  [AnalyticsEvents.SandboxShelfItemConnectERPClicked]: EmptyPayload;
  [AnalyticsEvents.SandboxImportDataModalCtaClicked]: EmptyPayload;
  [AnalyticsEvents.SandboxImportDataModalClose]: EmptyPayload;
  [AnalyticsEvents.SandboxImportDataModalEmailSupportClicked]: EmptyPayload;
  [AnalyticsEvents.SandboxImportStatusAlertInProgressMounted]: EmptyPayload;
  [AnalyticsEvents.SandboxImportStatusAlertCompletedMounted]: EmptyPayload;
  [AnalyticsEvents.SandboxImportStatusAlertCompletedCtaClicked]: EmptyPayload;
  [AnalyticsEvents.SandboxImportStatusAlertFailedMounted]: EmptyPayload;
  [AnalyticsEvents.SandboxImportStatusAlertFailedCtaClicked]: EmptyPayload;
  [AnalyticsEvents.SandboxImportStatusAlertFailedClose]: EmptyPayload;
};
