import type { CampaignStage } from "@/__generated__/globalTypes";
import type {
  ConnectBankFlowType,
  PlaidConnectionType,
} from "@/features/ConnectBank/sharedTypes";

export enum AddFinancialDocumentsStates {
  addDocuments = "addDocuments",
  completed = "completed",
  canceled = "canceled",
  fetchingAccounts = "fetchingAccounts",
  fetchingAccountsError = "fetchingAccountsError",
  selectAccount = "selectAccount",
  submitted = "submitted",
  uploadError = "uploadError",
}

export enum AddFinancialDocumentsEvents {
  addDocument = "addDocument",
  cancel = "cancel",
  nextStep = "nextStep",
  prevStep = "prevStep",
  retryFetchingAccounts = "retryFetchingAccounts",
  selectAccount = "selectAccount",
  submit = "submit",
}

export enum TellerEvents {
  EXIT = "EXIT",
  FAILED = "FAILED",
  INITIALIZED = "INITIALIZED",
  OPEN = "OPEN",
  SUCCESSFUL_CONNECTION = "SUCCESSFUL_CONNECTION",
  SUCCESSFUL_FIX_CONNECTION = "SUCCESSFUL_FIX_CONNECTION",
}

// CodatStatus
export const CodatStatusAnalyticsEvents = {
  FinancialDocumentsCodatLinkClicked: "financial_documents.codat.link.clicked",
  FinancialDocumentsCodatLinkConnectionSuccessful:
    "financial_documents.codat.link.connection.successful",
  FinancialDocumentsCodatLinkConnectionCancelled:
    "financial_documents.codat.link.connection.cancelled",
  FinancialDocumentsCodatLinkConnectionUnsupported:
    "financial_documents.codat.link.connection.unsupported",
  FinancialDocumentsCodatLinkConnection500:
    "financial_documents.codat.link.connection.500",
  FinancialDocumentsCodatLinkConnectionUncategorized:
    "financial_documents.codat.link.connection.uncategorized",
} as const;

// AddFinancialDocumentsModal
export const AddFinancialDocumentsModalAnalyticsEvents = {
  AddFinancialDocumentsModalMounted: "add_financial_documents_modal.mounted",
  AddFinancialDocumentsState: "add_financial_documents.state",
  UnderwritingSettingsPageStatementUploadMounted:
    "underwriting.settings_page_statement_upload.mounted",
  UnderwritingSettingsPageStatementUploadRedirect:
    "underwriting.settings_page_statement_upload.redirected",
} as const;

// AddFinancialDocumentsPageButtonModal
export const AddFinancialDocumentsPageButtonModalAnalyticsEvents = {
  AddFinancialDocumentsPageButtonModalMounted:
    "add_financial_documents_page_button_modal.mounted",
  AddFinancialDocumentsPageButtonModalState:
    "add_financial_documents_page_button_modal.state",
} as const;

export const FinicityConnectAnalyticsEvents = {
  FinicityConnectSuccess: "financials.finicity_connect.success",
  FinicityConnectCancel: "financials.finicity_connect.cancel",
  FinicityConnectError: "financials.finicity_connect.error",
  FinicityConnectLoaded: "financials.finicity_connect.loaded",
  FinicityConnectRoute: "financials.finicity_connect.route",

  FinicityConnectUserActionRequiredMounted:
    "financials.finicity_connect.user_action_required.mounted",
  FinicityConnectFixMounted: "financials.finicity_connect.fix.mounted",
  FinicityConnectErrorMounted: "financials.finicity_connect.error.mounted",
} as const;

export const PlaidAnalyticsEvents = {
  PlaidEvent: "financials.connect_bank.plaid_event", // Generic event
  PlaidConnectionStarted: "plaid.connect_bank.started",
  PlaidConnectionCompleted: "plaid.connect_bank.completed",
  PlaidConnectionCanceled: "plaid.connect_bank.canceled",
  PlaidConnectionFailed: "plaid.connect_bank.failed",
  PlaidSameDayMDVerificationStarted:
    "plaid.verify_same_day_micro_deposits.started",
  PlaidSameDayMDVerificationCompleted:
    "plaid.verify_same_day_micro_deposits.completed",
  PlaidSameDayMDVerificationCanceled:
    "plaid.verify_same_day_micro_deposits.canceled",
  PlaidSameDayMDVerificationFailed:
    "plaid.verify_same_day_micro_deposits.failed",
  MigrateFinicityToPlaid: "financials.connect_bank.migrate_finicity_to_plaid",
} as const;

export const TellerAnalyticsEvents = {
  TellerEvent: "financials.connect_bank.teller_event",
} as const;

export const UnderwritingBannersAnalyticsEvents = {
  RequestedFinancialDocumentsBannerViewed:
    "requested_financial_documents_banner.viewed",
  RequestedFinancialDocumentsBannerClicked:
    "requested_financial_documents_banner.clicked",
  RequestedFinancialDocumentsPageViewed:
    "requested_financial_documents_page.viewed",
  SelfServeConnectBankNoticeMounted: "self_serve_connect_bank.mounted",
  SelfServeConnectBankNoticeClicked: "self_serve_connect_bank.clicked",
  MigrateToOAuthNoticeMounted: "migrate_finicity_oauth_notice.mounted",
  MigrateToOAuthNoticeClicked: "migrate_finicity_oauth_notice.clicked",
  LowerLimitBannerMounted: "lower_limit_banner.mounted",
  LowerLimitBannerClicked: "lower_limit_banner.clicked",
} as const;

export const ConnectBankAnalyticsEvents = {
  ConnectBankForceVendorQuery: "financials.connect_bank.force_vendor_query",
  ConnectBankChangeVendorStart: "financials.connect_bank.change_vendor.start",
  ConnectBankChangeVendorConnected:
    "financials.connect_bank.change_vendor.connected",
  ConnectBankChangeVendorComplete:
    "financials.connect_bank.change_vendor.complete",
} as const;

export const StaleFinancialsActionAnalyticsEvents = {
  StaleFinancialsModalSeen: "stale_financials.modal.seen",
  StaleFinancialsModalDismissed: "stale_financials.modal.dismissed",
  StaleFinancialsBannerSeen: "stale_financials.banner.seen",
  StaleFinancialsEmailOpenedDashboard:
    "stale_financials.email_opened_dashboard",
  StaleFinancialsActionSuccessful: "stale_financials.action.success",
} as const;

export const FixBankConnectionAnalyticsEvents = {
  ConfirmFixBankConnectionModalShown:
    "financials.fix_bank_connection.confirm_fix_modal_shown",
  FixBankConnectionFlowStart: "financials.fix_bank_connection.start",
  FixBankConnectionFlowCancelled: "financials.fix_bank_connection.cancelled",
  FixBankConnectionFlowFinicityOpened:
    "financials.fix_bank_connection.finicity_opened",
  FixBankConnectionFlowFinicityClosed:
    "financials.fix_bank_connection.finicity_closed",
  FixBankConnectionFlowFinicitySuccess:
    "financials.fix_bank_connection.finicity_success",
  FixBankConnectionFlowPlaidOpened:
    "financials.fix_bank_connection.plaid_opened",
  FixBankConnectionFlowPlaidClosed:
    "financials.fix_bank_connection.plaid_closed",
  FixBankConnectionFlowPlaidSuccess:
    "financials.fix_bank_connection.plaid_success",
  FixBankConnectionFlowTellerOpened:
    "financials.fix_bank_connection.teller_opened",
} as const;

export const AnalyticsEvents = {
  ...AddFinancialDocumentsModalAnalyticsEvents,
  ...AddFinancialDocumentsPageButtonModalAnalyticsEvents,
  ...CodatStatusAnalyticsEvents,
  ...FinicityConnectAnalyticsEvents,
  ...PlaidAnalyticsEvents,
  ...TellerAnalyticsEvents,
  ...UnderwritingBannersAnalyticsEvents,
  ...ConnectBankAnalyticsEvents,
  ...StaleFinancialsActionAnalyticsEvents,
  ...FixBankConnectionAnalyticsEvents,
  ConnectBankBackdoorActivated:
    "settings.company.connect_bank_backdoor.activated",
} as const;

type EmptyPayload = {};

type AddFinancialDocumentsState = {
  noAccountSelection: boolean;
  step: AddFinancialDocumentsStates;
};

type ConnectBankNoticePayload = {
  reason: "add" | "fix";
  institutionId?: string;
};

type ConnectBankBackdoorActivatedPayload = {
  referrer: string;
};

type FinicityErrorPayload = {
  finicityError: any;
};

type FinicityEventPayload = {
  finicityEvent: any;
  flow: ConnectBankFlowType;
};

type PlaidEvent = {
  eventName: string;
  metadata: any;
  flowType: ConnectBankFlowType;
};

type PlaidConnectionEvent = {
  flowType: ConnectBankFlowType;
  connectionType?: PlaidConnectionType;
};

type TellerEvent = {
  eventName: string;
  metadata: any;
};

type FinancialDocumentsCodatLinkConnectionUncategorizedPayload = {
  statusText?: string;
};

type UnderwritingSettingsPageStatementUploadMountedPayload = {
  reason: string;
};

type UnderwritingSettingsPageStatementUploadRedirectPayload = {
  redirect: string;
};

type BankConnectionChangeVendorPayload = {
  initialInstitutionId: string;
  isAutopay: boolean;
};

type BankConnectionForceVendorPayload = {
  defaultToPlaid: boolean;
  defaultToTeller: boolean;
  defaultToFinicity: boolean;
};

type FixBankConnectionPayload = {
  initialFinancialSourceId?: string;
  institutionName?: string;
};

type StaleFinancialsActionPayload = {
  campaignStage?: CampaignStage;
  // Indicates if a stale financials event originates from an email action
  isEmailInitiated: boolean;
};

export type EventSchemaMap = {
  [AnalyticsEvents.AddFinancialDocumentsModalMounted]: EmptyPayload;
  [AnalyticsEvents.AddFinancialDocumentsPageButtonModalMounted]: EmptyPayload;
  [AnalyticsEvents.AddFinancialDocumentsState]: AddFinancialDocumentsState;
  [AnalyticsEvents.AddFinancialDocumentsPageButtonModalState]: AddFinancialDocumentsState;
  [AnalyticsEvents.ConnectBankBackdoorActivated]: ConnectBankBackdoorActivatedPayload;
  [AnalyticsEvents.FinancialDocumentsCodatLinkConnectionSuccessful]: EmptyPayload;
  [AnalyticsEvents.FinancialDocumentsCodatLinkConnectionCancelled]: EmptyPayload;
  [AnalyticsEvents.FinancialDocumentsCodatLinkConnection500]: EmptyPayload;
  [AnalyticsEvents.FinancialDocumentsCodatLinkConnectionUnsupported]: EmptyPayload;
  [AnalyticsEvents.FinancialDocumentsCodatLinkConnectionUncategorized]: FinancialDocumentsCodatLinkConnectionUncategorizedPayload;
  [AnalyticsEvents.FinicityConnectSuccess]: FinicityEventPayload;
  [AnalyticsEvents.FinicityConnectCancel]: EmptyPayload;
  [AnalyticsEvents.FinicityConnectError]: FinicityErrorPayload;
  [AnalyticsEvents.FinicityConnectLoaded]: EmptyPayload;
  [AnalyticsEvents.FinicityConnectRoute]: FinicityEventPayload;
  [AnalyticsEvents.FinicityConnectUserActionRequiredMounted]: EmptyPayload;
  [AnalyticsEvents.FinicityConnectFixMounted]: EmptyPayload;
  [AnalyticsEvents.FinicityConnectErrorMounted]: EmptyPayload;
  [AnalyticsEvents.PlaidEvent]: PlaidEvent;
  [AnalyticsEvents.PlaidConnectionStarted]: PlaidConnectionEvent;
  [AnalyticsEvents.PlaidConnectionCompleted]: PlaidConnectionEvent;
  [AnalyticsEvents.PlaidConnectionCanceled]: PlaidConnectionEvent;
  [AnalyticsEvents.PlaidConnectionFailed]: PlaidConnectionEvent;
  [AnalyticsEvents.PlaidSameDayMDVerificationStarted]: EmptyPayload;
  [AnalyticsEvents.PlaidSameDayMDVerificationCompleted]: EmptyPayload;
  [AnalyticsEvents.PlaidSameDayMDVerificationCanceled]: EmptyPayload;
  [AnalyticsEvents.PlaidSameDayMDVerificationFailed]: EmptyPayload;
  [AnalyticsEvents.TellerEvent]: TellerEvent;
  [AnalyticsEvents.MigrateFinicityToPlaid]: EmptyPayload;
  [AnalyticsEvents.RequestedFinancialDocumentsPageViewed]: EmptyPayload;
  [AnalyticsEvents.RequestedFinancialDocumentsBannerClicked]: EmptyPayload;
  [AnalyticsEvents.RequestedFinancialDocumentsBannerViewed]: EmptyPayload;
  [AnalyticsEvents.SelfServeConnectBankNoticeMounted]: ConnectBankNoticePayload;
  [AnalyticsEvents.SelfServeConnectBankNoticeClicked]: ConnectBankNoticePayload;
  [AnalyticsEvents.LowerLimitBannerMounted]: EmptyPayload;
  [AnalyticsEvents.LowerLimitBannerClicked]: EmptyPayload;
  [AnalyticsEvents.MigrateToOAuthNoticeMounted]: ConnectBankNoticePayload;
  [AnalyticsEvents.MigrateToOAuthNoticeClicked]: ConnectBankNoticePayload;
  [AnalyticsEvents.StaleFinancialsModalSeen]: StaleFinancialsActionPayload;
  [AnalyticsEvents.StaleFinancialsModalDismissed]: StaleFinancialsActionPayload;
  [AnalyticsEvents.StaleFinancialsBannerSeen]: StaleFinancialsActionPayload;
  [AnalyticsEvents.StaleFinancialsActionSuccessful]: StaleFinancialsActionPayload;
  [AnalyticsEvents.StaleFinancialsEmailOpenedDashboard]: StaleFinancialsActionPayload;
  [AnalyticsEvents.UnderwritingSettingsPageStatementUploadMounted]: UnderwritingSettingsPageStatementUploadMountedPayload;
  [AnalyticsEvents.UnderwritingSettingsPageStatementUploadRedirect]: UnderwritingSettingsPageStatementUploadRedirectPayload;
  [AnalyticsEvents.ConnectBankForceVendorQuery]: BankConnectionForceVendorPayload;
  [AnalyticsEvents.ConnectBankChangeVendorStart]: BankConnectionChangeVendorPayload;
  [AnalyticsEvents.ConnectBankChangeVendorConnected]: BankConnectionChangeVendorPayload;
  [AnalyticsEvents.ConnectBankChangeVendorComplete]: BankConnectionChangeVendorPayload;
  [AnalyticsEvents.ConfirmFixBankConnectionModalShown]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowStart]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowCancelled]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowFinicityOpened]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowFinicityClosed]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowFinicitySuccess]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowPlaidOpened]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowPlaidClosed]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowPlaidSuccess]: FixBankConnectionPayload;
  [AnalyticsEvents.FixBankConnectionFlowTellerOpened]: FixBankConnectionPayload;
};
