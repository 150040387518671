export function getKeysExtendType<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export type EntriesOf<T extends object> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function getEntriesExtendType<T extends object>(obj: T): EntriesOf<T> {
  return Object.entries(obj) as EntriesOf<T>;
}

/**
 * Breaks distributiveness of a union of type objects
 *
 * Read more here: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
 */
// Internal helper type, do not export
type FlattenUnion<T> = T extends any ? T : never;

/** Strongly-typed `Object.assign` alias that enforces an initial empty object */
export const assign = Object.assign as <T extends Record<string, unknown>>(
  // ensure that first arg is an empty object
  src: Record<string, never>,
  // infer return type from the rest parameter
  ...args: T[]
) => {
  [K in keyof FlattenUnion<T>]: T[K];
};

export function mapNullToUndefined<T>(v: T | null): T | undefined {
  return v ?? undefined;
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value != null;
}

export const isUndefined = <T>(v: T | undefined): v is undefined =>
  typeof v === "undefined";

export const isDefined = <T>(v: T | undefined): v is T => !isUndefined(v);

export const isDefinedAndNotNull = <T>(v: T | undefined | null): v is T =>
  !isUndefined(v) && v !== null;

/**
 * This is meant to mimick the behavior of TS's new `satisfies` syntax,
 * verifying that a value is assignable to a type without changing its type
 *
 * @example
 * // if we type the variable, we check that it's value is assignable to the type, but we lose additional information (like record keys)
 * const categories1: Record<string, { name: string }> = { urgent: { name: "Urgent" }, normal: { name: "Normal" } };
 * type C1 = keyof typeof categories1; // string
 *
 * // with the satisfies syntax, we can check that the value is assignable to the type without losing type information
 * const categories2 = { urgent: { name: "Urgent" }, normal: { name: "Normal" } } satisfies Record<string, { name: string }>;
 * type C2 = keyof typeof categories2; // "urgent" | "normal"
 *
 * // because our tooling doesn't support the satisfies syntax yet, we can use this helper to achieve the same result
 * const categories3 = satisfies<Record<string, { name: string }>>()(
 *  { urgent: { name: "Urgent" }, normal: { name: "Normal" } }
 * );
 * type C3 = keyof typeof categories3; // "urgent" | "normal"
 */
export const satisfies =
  <T>() =>
  <U extends T>(value: U) =>
    value;

/** A version of `Omit` that distributes over unions */
export type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

/** Returns the keys of an object that do not accept `undefined` as their value */
export type NotUndefinedKeysOf<T> = {
  [K in keyof T]-?: undefined extends T[K] ? never : K;
}[keyof T];

/**
 * Makes some properties optional. `T extends any` is used to distribute over unions
 *
 * @example
 * // those are equivalent:
 * type A = PickPartial<{ name: string, id: string }, "name">;
 * type B = { name?: string, id: string };
 **/
export type PickPartial<T, K extends keyof T> = T extends any
  ? Partial<Pick<T, K>> & Omit<T, K>
  : never;

/**
 * Makes some properties required. `T extends any` is used to distribute over unions
 *
 * @example
 * // those are equivalent:
 * type A = PickRequired<{ name?: string, id?: string }, "id">;
 * type B = { name?: string, id: string };
 **/
export type PickRequired<T, K extends keyof T> = T extends any
  ? Required<Pick<T, K>> & Omit<T, K>
  : never;

export type Unknown =
  | boolean
  | string
  | number
  | symbol
  | bigint
  | null
  | undefined
  | object
  | Function;

/**
 * We can't do `type Extends<T> = T extends null | undefined ? true : false` since, because of
 * TS's distributiveness over unions, `Extends<string | undefined>` would be `false | true`
 * instead of `true`.
 *
 * This helper returns `true | never`, and then `Extends` maps `true` to `true`
 * and `never` to `false`
 */
type ExtendsHelper<T, U> = T extends U ? true : never;

/**
 * Returns `true` if `T` extends `U` and `false` otherwise. Allows to check for compatibility without
 * distributing over unions.
 *
 * @example
 * // let's say we want to create a component that accepts a `defaultValue` prop, which can be optional if the value type is nullable
 * type Props1<T> = T extends undefined ?
 *  { defaultValue?: T; onChange: (v: T) => void }
 *  : { defaultValue: T; onChange: (v: T) => void };
 *
 * // TS will distribute over the union, giving us another union
 * type P1 = Props1<string | undefined>; // { defaultValue?: undefined; onChange: (v: undefined) => void } | { defaultValue: string; onChange: (v: string) => void }
 *
 * type Props2<T> = Extends<T, undefined> extends true ?
 *  { defaultValue?: T; onChange: (v: T) => void }
 *  : { defaultValue: T; onChange: (v: T) => void };
 *
 * // TS will not distribute over the union, giving us a single type, as intended
 * type P2 = Props2<string | undefined>; // { defaultValue?: string | undefined; onChange: (v: string | undefined) => void }
 */
export type Extends<T, U> = true extends ExtendsHelper<T, U> ? true : false;

export function isKeyOf<Obj extends object>(
  obj: Obj,
  key: keyof any,
): key is keyof Obj {
  return key in obj;
}

export function arrayIncludesTypeGuard<T>(
  arr: readonly T[],
  value: unknown,
): value is T {
  return arr.includes(value as T);
}

export function assertUnreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

type AssertedType<
  AssertFn extends (value: unknown) => asserts value is unknown,
> = AssertFn extends ((value: unknown) => asserts value is infer T) ? T : never;

export function assertIsArrayOf<
  AssertFn extends (value: unknown) => asserts value is unknown,
>(
  assertFn: AssertFn,
  value: unknown,
): asserts value is AssertedType<AssertFn>[] {
  if (!Array.isArray(value)) {
    throw new Error(`Expected ${String(value)} to be an array`);
  }
  for (const element of value) {
    assertFn(element);
  }
}

export type StrictExtract<T, U extends T> = Extract<T, U>;

/**
 * Forces a type to be `T` or string (string literal or string)
 */
export type StringLiteralOrString<T> = T | (string & {});

/**
 * Utility type to create a union of the provided object and an empty
 * version of the object with all values set to `never`.
 */
export type OptionalObject<T> = T | { [K in keyof T]?: never };

class ExhaustiveCheckError extends Error {}
export const exhaustiveCheck = (_value: never, message: string): never => {
  throw new ExhaustiveCheckError(message);
};

/**
 * A variation of {@linkcode exhaustiveCheck} that doesn't throw. It's meant to be used in places
 * where we want to verify we've covered all known cases, but we want to handle unknown ones
 * gracefully.
 */
export const safeExhaustiveCheck = (_value: never): void => {};

export function assert(
  value: unknown,
  message?: string | Error,
): asserts value {
  if (!value) {
    const error =
      message instanceof Error
        ? message
        : new Error(message ?? "Assertion failed");
    throw error;
  }
}

export type RelayEnumFallback = "%future added value";
export type AsRelayEnum<E extends string> = `${E}` | RelayEnumFallback;
