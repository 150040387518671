import { useTranslation } from "@brexhq/i18n";
import type { ColorModeSetting } from "@brexhq/metal-theming";
import { MetalProvider } from "@brexhq/metal-theming";
import { MotionProvider } from "@brexhq/metal-utils";
import { m } from "framer-motion";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import * as React from "react";
import { useCustomBranding } from "@/features/CustomThemeBranding/hooks/useCustomBranding";
import { LazyMotionMinimal } from "@/helpers/motion";

export const colorModeSettingAtom = atomWithStorage<ColorModeSetting>(
  "__BREX__ACTIVE_METAL_THEME",
  "light",
);

export const ThemeProvider: React.FC = React.memo(({ children }) => {
  const branding = useCustomBranding();
  const [colorMode, setColorMode] = useAtom(colorModeSettingAtom);
  const themeOverrides = React.useMemo(
    () => (branding.hasCustomBranding ? branding.themeOverride : undefined),
    [branding],
  );

  const { i18n } = useTranslation();
  const locale = i18n?.language || "en-US";

  return (
    <MotionProvider motion={m} wrapper={LazyMotionMinimal}>
      <MetalProvider
        colorMode={colorMode}
        onChangeColorMode={setColorMode}
        themeOverrides={themeOverrides}
        locale={locale}
      >
        {children}
      </MetalProvider>
    </MotionProvider>
  );
});
