import { datadogRum } from "@datadog/browser-rum";
import { v4 as uuid } from "uuid";
import { getTokenExpirationTime } from "@/features/Authentication/helpers/getTokenExpirationTime";
import type {
  TokenExchangeRequest,
  TokenExchangeResponse,
} from "@/features/Authentication/sharedTypes";
import { getEnvironment } from "@/helpers";
import { internalTrackError } from "@/helpers/errorTracking";

const tokenManagerEndpoint = getEnvironment("WEB_TOKEN_MANAGER_ENDPOINT");
const exchangeApiEndpoints = {
  exchangeUrl: `${tokenManagerEndpoint}/exchange`,
  refreshUrl: `${tokenManagerEndpoint}/refresh`,
  revokeRefreshToken: `${tokenManagerEndpoint}/revoke-refresh-token`,
  toggleUrl: `${tokenManagerEndpoint}/toggle`,
} as const;

const oktaApiEndpoint = getEnvironment("OKTA_API_ENDPOINT");
const sessionRefreshEndpoint = `${oktaApiEndpoint}/sessions/me/lifecycle/refresh`;

type AuthTokenManagerResponse = {
  access_token: string;
  id_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
};

export type TokenRevokeResponse = boolean;

const fetchWrapper = (input: RequestInfo, init: RequestInit = {}) =>
  fetch(input, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-brex-request-id": uuid(),
    },
    credentials: "include",
    ...init,
  });

/**
 * As the final part in our authentication flow, we need to grab the "code" we
 * get via the consent flow, and exchange it for the respective tokens.
 *
 * These exchange is done by hitting Hydra's /exchange API endpoint, and passing
 * it the code, plus certain information that was used to create this code.
 * (This so we can ensure "code" validity).
 *
 * We are not hitting hydra directly, we do so through our "WEB_TOKEN_MANAGER"
 * service, which will recieve information from the fetch request, augment it
 * with extra info, and do the code exchange for us.
 *
 * Callint the /exchange endpoint on the WEB_TOKEN_MANAGER Service then will return the "accessToken", "idToken" in
 * the response that we will use for authentication purposes.
 *
 * It will also return a token expiration date (expiresIn) in the response, as
 * well as a "refreshToken" inside a secure cookie that is not accessible by the
 * dashboard or client-side code. (We handle this via a cookie this to minimize
 * attack vectors, reduce potential attack surface and increase our security).
 * We use these 2 in conjuction to securely keep the user's dashboard access
 * alive throughout their session duration.
 */
export const getAccessToken = async ({
  code,
  redirectUri,
  pkceVerifier,
}: TokenExchangeRequest): Promise<TokenExchangeResponse> => {
  try {
    const rawResponse = await fetchWrapper(exchangeApiEndpoints.exchangeUrl, {
      body: JSON.stringify({
        clientId: "dashboard",
        code,
        redirectUri,
        codeVerifier: pkceVerifier,
      }),
    });

    if (rawResponse.status === 401) {
      throw new Error("Unauthorized access token request");
    }

    const response = (await rawResponse.json()) as AuthTokenManagerResponse;

    return {
      accessToken: response.access_token,
      idToken: response.id_token,
      expiresIn: response.expires_in,
    };
  } catch (e) {
    internalTrackError({
      error: e as Error,
      errorName: "Access token exchange error",
    });
    throw e;
  }
};

/**
 * Part of keeping the securely keep the user's dashboard access alive
 * throughout their session duration, we use the 'refesh token keep-alive
 * authentication flow, we need to send our WEB_TOKEN_MANAGER Service a request
 * for a new token.
 *
 * For this we don't need to re-authenticate the user, so we are using the
 * previously mentioned "refresh_token" that we store in a secure cookie.
 *
 * Again, we are not hitting hydra directly, so we this through our
 * "WEB_TOKEN_MANAGER" service, which will recieve the cookie being sent from
 * the fetch request, augment it with extra info, and do the token refesh for
 * use.
 *
 * calling the /refresh endpoint in our WEB_TOKEN_MANAGER Service has the same
 * response body shape as "getAccessToken" function. It will return the
 * "accessToken", "idToken" in the response body that we will then use to "keep
 * alive" our user's session.
 *
 * Same with the getAccessToken function,refreshAccessToken will also return a
 * token expiration date (expiresIn) in the response body, as well as a
 * "refreshToken" inside a secure cookie that is not accessible by the dashboard
 * or client-side code. We use these 2 in conjuction to securely keep the user's
 * dashboard access alive throughout their session duration.
 */
export const refreshAccessToken = async (): Promise<TokenExchangeResponse> => {
  try {
    const rawResponse = await fetchWrapper(exchangeApiEndpoints.refreshUrl);

    if (rawResponse.status === 401) {
      throw new Error("Unauthorized refresh request");
    }

    // refresh Okta session
    try {
      await fetchWrapper(sessionRefreshEndpoint, {});
    } catch {
      console.error("Failed to refresh session");
    }

    const response = (await rawResponse.json()) as AuthTokenManagerResponse;

    let duration = response.expires_in;
    try {
      const expiryTime = getTokenExpirationTime(response.access_token);
      if (expiryTime) {
        duration = Math.floor((expiryTime - Date.now()) / 1000);
      }
    } catch {}

    datadogRum.addAction("refreshAccessToken", { duration });

    return {
      accessToken: response.access_token,
      idToken: response.id_token,
      expiresIn: response.expires_in,
    };
  } catch (e) {
    internalTrackError({
      error: e as Error,
      errorName: "Refresh access token error",
    });
    throw e;
  }
};

export const toggleSession = async (
  sessionMode: string,
): Promise<TokenExchangeResponse> => {
  try {
    const rawResponse = await fetchWrapper(exchangeApiEndpoints.toggleUrl, {
      body: JSON.stringify({
        sessionMode,
      }),
    });

    if (rawResponse.status === 401) {
      throw new Error("Unauthorized toggle request");
    }

    // refresh Okta session
    try {
      await fetchWrapper(sessionRefreshEndpoint, {});
    } catch {
      console.error("Failed to refresh session");
    }

    const response = (await rawResponse.json()) as AuthTokenManagerResponse;

    let duration = response.expires_in;
    try {
      const expiryTime = getTokenExpirationTime(response.access_token);
      if (expiryTime) {
        duration = Math.floor((expiryTime - Date.now()) / 1000);
      }
    } catch {}

    datadogRum.addAction("toggleSession", { duration });

    return {
      accessToken: response.access_token,
      idToken: response.id_token,
      expiresIn: response.expires_in,
    };
  } catch (e) {
    internalTrackError({
      error: e as Error,
      errorName: "Toggle session error",
    });
    throw e;
  }
};

/**
 * Part of keeping the securely keep the user's dashboard access alive
 * throughout their session duration, since 'refesh-token' stays in a http-only cookie, we use this endpoint
 * to revoke the refresh token
 *
 * Again, as with other methods in this helper module, we are not hitting hydra directly, so we do this through our
 * "WEB_TOKEN_MANAGER" service, which will recieve the cookie being sent from
 * the fetch request, and revoke it against hydra.
 */
export const revokeRefreshToken =
  async (): Promise<TokenRevokeResponse | null> => {
    const response = await fetchWrapper(
      exchangeApiEndpoints.revokeRefreshToken,
    );
    return response.status === 200;
  };
