import {
  VerificationRequestType,
  VerificationRequestStatus,
  VerificationAnalysisStatusV2,
  VerificationAnalysisResult,
} from "@/__generated__/globalTypes";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import type { GetVerificationAnalysisQuery_verificationAnalysis_VerificationAnalysis as VerificationAnalysis } from "@/routes/Onboarding/data/__generated__/queries.generated";
import { useMockableListVerificationAnalyses } from "@/routes/Onboarding/hooks/useListVerificationAnalyses";

const INTERNAL_REQUEST_TYPES = [
  VerificationRequestType.SCREENING,
  VerificationRequestType.MIDDESK,
  VerificationRequestType.INTERNAL,
];

const SUBMITTED_RESULTS = [
  VerificationAnalysisResult.PENDING_REVIEW,
  VerificationAnalysisResult.APPROVED,
  VerificationAnalysisResult.REJECTED,
];

const selectVerificationAnalysis = (
  analysis: VerificationAnalysis | null | undefined,
) => {
  if (!analysis || analysis.status === VerificationAnalysisStatusV2.CANCELLED) {
    return undefined;
  }

  const requests = analysis.requests
    .filter(({ requestType }) => !INTERNAL_REQUEST_TYPES.includes(requestType))
    .filter(({ status }) => status !== VerificationRequestStatus.CANCELED);

  const hasPendingRequests = requests.some(({ status }) =>
    [
      VerificationRequestStatus.IN_PROGRESS,
      VerificationRequestStatus.NEW,
      VerificationRequestStatus.READY_TO_SUBMIT,
    ].includes(status),
  );

  const hadPendingRequests = requests.length > 0;

  const isProcessing =
    analysis.result === VerificationAnalysisResult.PROCESSING;

  const isSubmitted =
    SUBMITTED_RESULTS.includes(analysis.result) && !hasPendingRequests;

  return {
    id: analysis.id,
    status: analysis.status,
    result: analysis.result,
    rawRequests: requests,
    hasPendingRequests,
    hadPendingRequests,
    applicantCustomerUserId: analysis.applicantCustomerUserId,
    isProcessing,
    isSubmitted,
    // Note: This field may not be set on all analysis types.
    submittedAt: analysis.submittedAt,
    requirementName: analysis.requirement?.name,
  };
};

export const useGetLendingDisclosureAnalysis = (shouldSkip = false) => {
  const { enableLendingDisclosure } = useFeatureFlags();

  const { data, loading, error } = useMockableListVerificationAnalyses({
    filter: {
      requirements: [
        "lending_disclosure_ca",
        "lending_disclosure_fl",
        "lending_disclosure_ga",
        "lending_disclosure_ny",
      ].map((requirementName) => ({
        name: requirementName,
      })),
    },
    skip: shouldSkip || !enableLendingDisclosure,
  });

  const lendingDisclosureAnalysis = data?.verificationAnalyses?.edges
    .map((edge) => selectVerificationAnalysis(edge?.node))
    .find(
      (selectedAnalysis) =>
        selectedAnalysis?.hasPendingRequests &&
        selectedAnalysis.status === VerificationAnalysisStatusV2.PENDING,
    );

  return { data, loading, lendingDisclosureAnalysis, error };
};
