import Cookies from "js-cookie";
import { SeenFlagKey } from "@/__generated__/globalTypes";
import { AccountStatus } from "@/data/sharedTypes";
import { useAccountStatus } from "@/domains/Primitives/shared/hooks/useAccountStatus";
import { useMockableFspConfig } from "@/features/ProAccess";
import { useSeenFlag } from "@/features/SeenFlag";
import { useSandbox } from "@/modules/Sandbox/hooks/useSandbox";
import { ADMIN_ONBOARDING_COOKIE_LABEL } from "@/routes/Onboarding/OnboardingExperience/helpers/constants";
import { parseBooleanFromString } from "@/routes/Onboarding/OnboardingExperience/helpers/helpers";

export const useOnboardingExperiencePermissions = () => {
  const { fspDetails } = useMockableFspConfig();
  const { isInSandboxMode } = useSandbox();
  const {
    accountStatus,
    isLoading: isLoadingAccountStatus,
    hasError: accountStatusHasError,
  } = useAccountStatus();
  const shouldSeeOnboarding =
    ![
      AccountStatus.SUSPENDED,
      AccountStatus.CLOSED,
      AccountStatus.REJECTED,
      AccountStatus.TERMINATED,
    ].includes(accountStatus as AccountStatus) &&
    !isLoadingAccountStatus &&
    !fspDetails &&
    !isInSandboxMode;

  const showAdminOnboardingFlowForSynthetic =
    parseBooleanFromString(Cookies.get(ADMIN_ONBOARDING_COOKIE_LABEL) ?? "") ??
    false;

  const primitivesOnboardingSeenFlagKey =
    SeenFlagKey.PRIMITIVES_ONBOARDING_COMPLETED;

  const {
    value: hasCompletedOnboarding,
    isLoading: isLoadingOnboardingSeenFlag,
    hasError: completedOnboardingHasError,
  } = useSeenFlag({ key: primitivesOnboardingSeenFlagKey });

  const hasNotCompletedPrimitivesOnboarding =
    !isLoadingOnboardingSeenFlag && !hasCompletedOnboarding;

  return {
    shouldSeeOnboardingExperience:
      (hasNotCompletedPrimitivesOnboarding ||
        showAdminOnboardingFlowForSynthetic) &&
      shouldSeeOnboarding,
    isLoading: isLoadingOnboardingSeenFlag || isLoadingAccountStatus,
    hasError: completedOnboardingHasError || accountStatusHasError,
  };
};
