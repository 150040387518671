import { datadogRum } from "@datadog/browser-rum";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { mountedAtom } from "@/domains/App/features/Skeleton/atoms/mounted";

export function useSkeletonMounted() {
  const [mounted, setMounted] = useAtom(mountedAtom);
  useEffect(() => {
    if (!mounted) {
      setMounted(true);

      const actionName = "APP.skeleton.mounted";
      const measure = window.performance.measure(actionName);

      datadogRum.setGlobalContextProperty("appSkeletonMounted", true);
      datadogRum.addAction(actionName, {
        measure: measure?.duration,
        duration: measure?.duration,
      });
    }
  }, [mounted, setMounted]);
}
