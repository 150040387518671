import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import React from "react";
import type { JsonObject } from "type-fest";
import { useControllerPerformanceContext } from "@/domains/App/components/Controller/ControllerContext";

interface CustomError extends Error {
  __trackOrigin__: string;
}

type TrackErrorOptions = {
  context?: JsonObject;
  error: unknown;
  errorName?: string;
};
/**
 * Track an error in third party services
 *
 * Use this function directly if tracking an error within a function that's not
 * exposed externally to controllers, components, or hooks.
 *
 * @param errorName Custom error name
 * @param error The error to track
 * @param context Optional error context
 */
export const internalTrackError = (options: TrackErrorOptions) => {
  // error should always be present, but guard against it missing
  const error = (
    options.error instanceof Error
      ? options.error
      : new Error(options.error ? String(options.error) : "Undefined error")
  ) as CustomError;

  // add error name to context
  const context: JsonObject = {
    ...options.context,
    errorName: options.errorName || (error as unknown as Error).message,
  };

  // Prevent tracking of already tracked errors to eliminate duplicates.
  // Since there's automatic tracking of errors in our performance tooling, it's
  // possible that errors are tracked in one context, re-thrown, and bubble up
  // to another context that also attempts to track it. This makes it so they
  // are only tracked once as part of withControllerPerformanceMetrics
  if (
    error?.__trackOrigin__ === "PerformanceControllerErrorBoundary" &&
    context?.trackOrigin === "PerformanceControllerErrorBoundary"
  ) {
    return;
  }

  datadogRum.addError(error, context);

  datadogLogs.logger.error(options.errorName || "error occur", context);

  if (process.env.NODE_ENV === "production") {
    LogRocket.captureException(error as unknown as Error, {
      tags: context as any,
    });
    Sentry.withScope((scope) => {
      scope.setContext("dashboard_error_info", context ?? null);
      Sentry.captureException(error);
    });
  }

  if (context?.trackOrigin && !error?.__trackOrigin__) {
    error.__trackOrigin__ = context?.trackOrigin as string;
  }
};

/**
 * Hook for tracking an error in third party services
 *
 * This hook automatically adds controller context to the error.
 * Use this for tracking an error within controllers and components.
 *
 * @param errorName Custom error name
 * @param error The error to track
 * @param context Optional error context
 */
export const useTrackError = () => {
  const { ancestorControllerNames, controllerName, controllerSessionId } =
    useControllerPerformanceContext();
  const trackError = React.useCallback(
    (errorName: string | undefined, error: Error, context?: JsonObject) => {
      internalTrackError({
        errorName,
        error,
        context: {
          ...context,
          ancestorControllerNames,
          controllerName,
          controllerSessionId,
        },
      });
    },
    [ancestorControllerNames, controllerName, controllerSessionId],
  );
  return React.useMemo(() => ({ trackError }), [trackError]);
};

/**
 * Hook for tracking React render errors in third party services
 *
 * This hook is only intended to be used within an TrackedErrorBoundary.
 *
 * @param error The error to track
 * @param info Information about the React error
 * @param context Optional error context
 */
export const useTrackRenderingError = () => {
  const { trackError } = useTrackError();
  return React.useCallback(
    (error: Error, info: React.ErrorInfo, context?: JsonObject) =>
      trackError(error.message || "No message defined", error, {
        reactComponentStack: info.componentStack,
        ...context,
      }),
    [trackError],
  );
};
