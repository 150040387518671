import { useAtom } from "jotai";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { shouldRunQualtricsIframeAtom } from "@/features/CustomerFeedback/subFeatures/Qualtrics/atoms/qualtrics";
import { qualtricsReloadPaths } from "@/features/CustomerFeedback/subFeatures/Qualtrics/helpers/qualtrics";

export const useQualtricsIframeReload = () => {
  const location = useLocation();
  const [, setShouldRunQualtricsIframe] = useAtom(shouldRunQualtricsIframeAtom);

  React.useEffect(() => {
    if (qualtricsReloadPaths.has(location.pathname)) {
      setShouldRunQualtricsIframe(true);
    }
  }, [location.pathname, setShouldRunQualtricsIframe]);
};
