import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { DocumentNode } from "graphql";
import { useControllerPerformanceContext } from "@/domains/App/components/Controller/ControllerContext";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useQuery as useApolloQuery } from "@/features/Apollo/helpers/client";
import type {
  OperationVariables,
  QueryHookOptions,
} from "@/features/Apollo/helpers/client";
import { createLoadingInfoFromQueryResult } from "@/features/Apollo/helpers/createLoadingInfoFromQueryResult";
import type { ExtendedQueryResult } from "@/features/Apollo/sharedTypes";

export function useQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): ExtendedQueryResult<TData, TVariables> {
  const controllerContext = useControllerPerformanceContext();
  const result = useApolloQuery<TData, TVariables>(query, {
    ...options,
    context: {
      ...options?.context,
      controllerContext,
    },
  });
  return {
    ...result,
    ...createLoadingInfoFromQueryResult(result),
  };
}
