import { addMinutes, isAfter } from "date-fns";
import type { AuthState } from "@/features/Authentication/sharedTypes";

type State = AuthState & {
  expires: Date;
};

const AUTHORIZATION_STATE_KEY = "auth_flow_";
// Time to live (in minutes) for store authorization state
const AUTHORIZATION_STATE_TTL_MINUTES = 30;

export const getStateStorageKey = (id: string) => {
  return `${AUTHORIZATION_STATE_KEY}${id}`;
};

/**
 * Remove any stored states that are older than the configured TTL value.
 */
export const purgeExpiredStates = (currentTime: Date = new Date()) => {
  const keysToRemove: string[] = [];
  for (let i = 0, total = window.localStorage.length; i < total; i++) {
    const key = window.localStorage.key(i);
    if (key?.startsWith(AUTHORIZATION_STATE_KEY)) {
      const authState = window.localStorage.getItem(key);
      if (authState !== null) {
        const parsedState = JSON.parse(authState) as State;
        if (
          !parsedState.expires ||
          isAfter(currentTime, new Date(parsedState.expires))
        ) {
          keysToRemove.push(key);
        }
      } else {
        keysToRemove.push(key);
      }
    }
  }
  keysToRemove.forEach((key) => {
    window.localStorage.removeItem(key);
  });
};

/**
 * Save authorization state to `localStorage` with an expiration date/time
 * according to the configured TTL.
 */
export const saveState = (
  state: AuthState,
  currentTime: Date = new Date(),
  ttlMinutes: number = AUTHORIZATION_STATE_TTL_MINUTES,
) => {
  window.localStorage.setItem(
    getStateStorageKey(state.id),
    JSON.stringify({
      ...state,
      expires: addMinutes(currentTime, ttlMinutes),
    }),
  );
};

/**
 * Load authorization state by ID from `localStorage`.
 */
export const loadState = (id: string): State | null => {
  const rawAuthorizationState = window.localStorage.getItem(
    getStateStorageKey(id),
  );
  return rawAuthorizationState !== null
    ? (JSON.parse(rawAuthorizationState) as State)
    : null;
};

/**
 * Remove authorization state by ID from `localStorage`.
 */
export const destroyState = (id?: string) => {
  if (id) {
    window.localStorage.removeItem(getStateStorageKey(id));
  }
};
