import { atom } from "jotai";
import { selectAtom } from "jotai/utils";
import type { QueryResult } from "@/features/Apollo";
import { NetworkStatus } from "@/features/Apollo";
import { atomsWithQuery } from "@/features/Jotai";
import type {
  SeenFlagsQuery,
  SeenFlagsQueryVariables,
} from "@/features/SeenFlag/data/__generated__/queries.generated";
import {
  selectSeenFlags,
  getSeenFlagsMap,
} from "@/features/SeenFlag/selectors";

export const seenFlagsQueryAtoms = atomsWithQuery<
  SeenFlagsQuery,
  SeenFlagsQueryVariables
>({
  suspensePolicy:
    window.localStorage.getItem("SEEN_FLAGS_SUSPENSE_POLICY") === "unset"
      ? undefined
      : "initial",
});

type SeenFlagsQueryResult = QueryResult<SeenFlagsQuery>;

export const refetchSeenFlagsAtom = atom<{
  refetch: SeenFlagsQueryResult["refetch"];
}>({
  refetch: async () => {
    return null as any;
  },
});

export const seenFlagsDataAtom = atom<SeenFlagsQueryResult["data"]>(undefined);

export const seenFlagsNetworkStatusAtom = atom(NetworkStatus.loading);

export const seenFlagsAtom = selectAtom(seenFlagsDataAtom, (data) =>
  selectSeenFlags(data),
);

export const seenFlagsMapAtom = selectAtom(seenFlagsDataAtom, (data) =>
  getSeenFlagsMap(data),
);

export const seenFlagsMapAtomWithoutSuspense = selectAtom(
  seenFlagsDataAtom,
  (data) => getSeenFlagsMap(data),
);

export const seenFlagsLoadingAtom = selectAtom(
  seenFlagsNetworkStatusAtom,
  (networkStatus) => networkStatus === NetworkStatus.loading,
);

export const seenFlagsHasErrorAtom = selectAtom(
  seenFlagsNetworkStatusAtom,
  (networkStatus) => networkStatus === NetworkStatus.error,
);
