import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import { AnalyticsEvents, useTrackEvent } from "@/domains/App";
import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { useUserId } from "@/features/Authentication/contexts/UserProperties";
import { getTokenExpirationTime } from "@/features/Authentication/helpers/getTokenExpirationTime";
import { getEnvironment } from "@/helpers/environment";

const brexSupportSessionKey = "brex_support_session";

export interface SupportSessionCookie {
  userId?: string;
  sessionId?: string;
  browsedHelpCenterAnonymously?: boolean;
}

const cookieDomain = (): string => {
  const env = getEnvironment("APP_ENV");
  if (env === "localhost") {
    return "localhost";
  }
  if (env === "stg") {
    return ".brexapps.com";
  }
  if (env === "dev") {
    return ".brexhq.dev";
  }
  return ".brex.com";
};

const getSupportSessionCookie = () => {
  const cookie = Cookies.get(brexSupportSessionKey);
  if (cookie) {
    return JSON.parse(cookie) as SupportSessionCookie;
  }
};

const getOrGenerateSessionId = (
  userId: string,
  existingCookie?: SupportSessionCookie,
) => {
  if (existingCookie?.userId == userId) {
    return existingCookie.sessionId;
  }
  return uuid();
};

export const useGenerateSupportSessionCookie = () => {
  const track = useTrackEvent();
  const { getAccessToken } = useAuthenticationContext();
  const accessToken = getAccessToken();
  const userId = useUserId();

  if (accessToken && userId) {
    const existingCookieValue = getSupportSessionCookie();
    const sessionId = getOrGenerateSessionId(userId, existingCookieValue);

    const cookieValue: SupportSessionCookie = {
      userId,
      sessionId,
    };
    const accessTokenExpiration = getTokenExpirationTime(accessToken);

    if (accessTokenExpiration) {
      // Convert from unix timestamp to javascript Date, required for Cookies module
      const cookieExpiration = new Date(accessTokenExpiration);

      Cookies.set(brexSupportSessionKey, cookieValue, {
        expires: cookieExpiration,
        domain: cookieDomain(),
        sameSite: "strict",
        secure: true,
        path: "/",
      });
    }
    if (existingCookieValue?.browsedHelpCenterAnonymously) {
      // Used to link previously unauthenticated HC traffic to later-authenticated dashboard user
      track(AnalyticsEvents.HelpCenterAnonymousLogin);
    }
  }
};

export const removeSupportSessionCookie = () => {
  Cookies.remove(brexSupportSessionKey, { path: "/", domain: cookieDomain() });
};
