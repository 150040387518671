type IFrameHandlerOptions<T> = {
  url: string;
  callback: (data: T, origin?: string) => boolean;
  timeout?: number;
  timeoutCallback?: () => void;
  errorHandler?: (error?: Error) => void;

  /**
   * Although the generic type is specified for the event data, because this data
   * is coming from an external source, it's a good idea to validate the data
   * before assuming type-safe operations in the callback.
   */
  validator?: (data: T) => boolean;
};

/**
 * This implementation is heavily inspired by auth0.js and converted to TypeScript
 * @see https://github.com/auth0/auth0.js/blob/master/src/helper/iframe-handler.js
 */
export class IFrameHandler<T> {
  private options: IFrameHandlerOptions<T>;

  private iframe: HTMLIFrameElement | null = null;

  private timeoutHandler = 0;

  constructor(options: IFrameHandlerOptions<T>) {
    this.options = options;
    this.handleError = this.handleError.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
  }

  private handleError(
    event: Event | string,
    source?: string,
    lineno?: number,
    colno?: number,
    error?: Error,
  ) {
    this.destroy();
    this.options.errorHandler?.(error);
  }

  private handleMessage(event: MessageEvent) {
    if (this.options.validator && !this.options.validator(event.data)) {
      return;
    }
    if (this.options.callback(event.data, event.origin)) {
      this.destroy();
    }
  }

  private handleTimeout() {
    this.destroy();
    this.options.timeoutCallback?.();
  }

  public init() {
    this.iframe = window.document.createElement("iframe");
    this.iframe.style.display = "none";
    this.iframe.onerror = this.handleError;
    window.addEventListener("message", this.handleMessage, false);
    window.document.body.appendChild(this.iframe);
    this.iframe.src = this.options.url;
    this.timeoutHandler = window.setTimeout(
      this.handleTimeout,
      this.options.timeout ?? 60 * 1000,
    );
  }

  public destroy() {
    clearTimeout(this.timeoutHandler);
    setTimeout(() => {
      window.removeEventListener("message", this.handleMessage, false);
      this.iframe?.parentNode?.removeChild(this.iframe);
    }, 0);
  }
}
