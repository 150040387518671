import { useAtomValue } from "jotai";
import { createMockableHook } from "@/helpers/createMockableHook";
import { selectSandboxQueryAtom } from "@/modules/Sandbox/atoms/sandboxQueryAtoms";

// Fetches if user is in sandbox environment or is a demo account
const _useSandbox = () => {
  const { isSandbox, isDemo, hasAccessToSandbox } = useAtomValue(
    selectSandboxQueryAtom,
  );
  return {
    isInSandboxMode: isSandbox,
    isDemoAccount: isDemo,
    hasAccessToSandbox,
  };
};

export const [useSandbox, MockSandboxProvider] =
  createMockableHook(_useSandbox);
