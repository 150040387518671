import type { GraphQLErrors } from "@apollo/client/errors";
import * as React from "react";
import { Navigate } from "react-router-dom";
import { Suspend } from "@/components/Suspend";
import { ErrorType } from "@/data/sharedTypes";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { ApolloError } from "@/features/Apollo";
import { LoginError } from "@/features/Authentication/components/LoginError";
import IdentificationController from "@/features/Authentication/controllers/IdentificationController";
import { isCookieBasedAuth } from "@/features/Authentication/helpers/oauth";
import { transformUnauthenticatedErrorType } from "@/features/Authentication/helpers/transformUnauthenticatedErrorType";
import { useAuthenticate } from "@/features/Authentication/hooks/useAuthenticate";
import { QueryBoundary } from "@/features/Jotai";
import { urls } from "@/helpers/urls";

function renderError({ error: queryError }: { error: unknown }) {
  let errorType = ErrorType.FETCH_USER_FAILURE;
  let errorMessage: string | undefined;

  if (queryError instanceof ApolloError) {
    // Workaround to access errors https://github.com/apollographql/apollo-client/issues/2810
    const errors: GraphQLErrors =
      queryError.graphQLErrors.length > 0
        ? queryError.graphQLErrors
        : (queryError.networkError as any)?.result?.errors || [];

    const type = (
      errors.find(({ extensions }) => extensions?.code === "UNAUTHENTICATED")
        ?.extensions as any
    )?.type;

    const receivedErrorType = transformUnauthenticatedErrorType(type);
    if (receivedErrorType === ErrorType.LOGIN_ACCOUNT_REJECTED) {
      // Account rejected
      return <Navigate replace to={urls.onboarding.rejected()} />;
    }

    if (receivedErrorType) {
      errorType = receivedErrorType;
    }
  }

  if (queryError instanceof Error) {
    errorMessage = queryError.message;
  }

  return <LoginError errorType={errorType} errorMessage={errorMessage} />;
}

function TokenAuthenticationController({
  children,
}: React.PropsWithChildren<{}>) {
  const { authenticating, error } = useAuthenticate();

  if (error) {
    return <LoginError errorType={error[0]} errorMessage={error[1]} />;
  }

  if (authenticating) {
    return <Suspend />;
  }

  return (
    <QueryBoundary
      // eslint-disable-next-line @brexhq/react-memo/require-memo-hooks
      renderLoading={() => <Suspend />}
      renderError={renderError}
    >
      <IdentificationController>{children}</IdentificationController>
    </QueryBoundary>
  );
}

/**
 * This is a single purpose controller and is only responsible for obtaining
 * a valid user session.
 *
 * DO NOT ADD ANY ADDITIONAL LOGIC!!!
 *
 * If you need to run code post-authentication, but before rendering the
 * Dashboard, add it to the AuthenticatedRouter component in the App domain.
 */
const AuthenticationController: React.FC = ({ children }) => {
  if (!isCookieBasedAuth()) {
    return (
      <TokenAuthenticationController>{children}</TokenAuthenticationController>
    );
  }

  return (
    <QueryBoundary
      // eslint-disable-next-line @brexhq/react-memo/require-memo-hooks
      renderLoading={() => <Suspend />}
      renderError={renderError}
    >
      <IdentificationController>{children}</IdentificationController>
    </QueryBoundary>
  );
};

export default withControllerPerformanceMetrics(AuthenticationController);
