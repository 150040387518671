import { getTokenExpirationTime } from "@/features/Authentication/helpers/getTokenExpirationTime";

// This defines our access token should be refreshed when we are at at least 15
// mins of its invalidation time
const REFRESH_TOKEN_LEGROOM = 15 * 60 * 1000;

export function getTokenState(token?: string | null): {
  expired: boolean;
  needRefresh: boolean;
} {
  if (!token) {
    return { expired: false, needRefresh: false };
  }
  const tokenExpirationTime = getTokenExpirationTime(token);
  if (!tokenExpirationTime) {
    return { expired: false, needRefresh: true };
  }

  // If the current time is between the expiry date, and our delta/legroom time,
  // we want to refresh the token
  if (
    Date.now() > tokenExpirationTime - REFRESH_TOKEN_LEGROOM &&
    Date.now() <= tokenExpirationTime
  ) {
    return { expired: false, needRefresh: true };
  }
  // If the current time is past the expiry date, we have to short circuit and logout the user
  if (Date.now() > tokenExpirationTime) {
    return { expired: true, needRefresh: false };
  }
  return { expired: false, needRefresh: false };
}
