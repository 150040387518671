import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { getTokenExpirationTime } from "@/features/Authentication/helpers/getTokenExpirationTime";
import { createMockableHook } from "@/helpers/createMockableHook";

// This checks if the token is expired, unparseable, unexistent or invalid.
const isTokenValid = (accessToken?: string | null) => {
  if (!accessToken) {
    return false;
  }
  try {
    const expirationTime = getTokenExpirationTime(accessToken);
    if (!expirationTime) {
      return false;
    }

    return Date.now() < expirationTime;
  } catch (e) {
    return false;
  }
};

const useIsAuthenticatedInternal = () => {
  const { getAccessToken } = useAuthenticationContext();
  const accessToken = getAccessToken();
  return isTokenValid(accessToken);
};

export const [useIsAuthenticated, MockedUseIsAuthenticated] =
  createMockableHook(useIsAuthenticatedInternal);
