/* eslint-disable @typescript-eslint/no-restricted-imports */
import * as React from "react";
import type { PrefetchedQueryCache } from "@/features/Prefetch/helpers/cache";

export type PrefetchableViewContextValue = {
  queries: PrefetchedQueryCache;
};

export const PrefetchableViewContext = React.createContext<
  PrefetchableViewContextValue | undefined
>(undefined);
