type Context = {
  controllerName?: string;
  productSurfaceSuffix?: string;
};

export function getProductSurface(context?: Context): string {
  if (typeof context !== "object") {
    return "";
  }
  const controllerName = context?.controllerName;
  const suffix = context?.productSurfaceSuffix;
  return [controllerName, suffix].filter(Boolean).join("__");
}
