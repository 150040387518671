import ApolloClient from "@/features/Apollo/helpers/client";

export { useLazyQuery } from "./hooks/useLazyQuery";
export { useMutation } from "./hooks/useMutation";
export { useQuery } from "./hooks/useQuery";
export { useSuspenseQuery } from "./hooks/useSuspenseQuery";
export { useBackgroundQuery } from "./hooks/useBackgroundQuery";

// apollo client types
export type {
  ApolloQueryResult,
  BackgroundQueryHookOptions,
  BaseMutationOptions,
  DocumentNode,
  ErrorPolicy,
  ErrorResponse,
  ExecutionResult,
  FetchMoreOptions,
  FetchPolicy,
  FetchResult,
  InternalRefetchQueriesInclude,
  LazyQueryHookOptions,
  MockedProviderProps,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  MutationTuple,
  NextLink,
  ObservableQuery,
  ObservableQueryFields,
  Operation,
  OperationVariables,
  PureQueryOptions,
  QueryFunctionOptions,
  QueryHookOptions,
  QueryLazyOptions,
  QueryOptions,
  QueryResult,
  RefetchQueriesFunction,
  RequestHandler,
  RetryFunction,
  ServerError,
  ServerParseError,
  SuspenseQueryHookOptions,
  UriFunction,
  WatchQueryFetchPolicy,
} from "@/features/Apollo/helpers/client";

// apollo client values
export {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  FetchPolicyValue,
  getOperationName,
  InMemoryCache,
  MockedProvider,
  Mutation,
  NetworkStatus,
  Observable,
  onError,
  Query,
  RetryLink,
  SchemaLink,
  setContext,
  skipToken,
  useApolloClient,
  useReadQuery,
} from "@/features/Apollo/helpers/client";

export default ApolloClient;
