import gql from "graphql-tag";
import { fundingSourceWithFinancialAccountFragment } from "@/data/fragments";

export const cashAccountStateQuery = gql`
  query CashAccountStateQuery {
    user {
      id
      role
      depositsRole
    }
    account {
      id
      status
      statusFromSuspendedToActive
      cardAccountType
      depositsAccounts(first: 1) {
        edges {
          cursor
          node {
            id
            status
          }
        }
      }
    }
    accountClosureStatus
  }
`;

export const countryListQuery = gql`
  query Countries($filters: CountriesListFilters) {
    countries(filters: $filters) {
      alpha2
      displayName
      defaultCurrency {
        instrumentCodeString
        name
        tickerSymbol
      }
      restrictions {
        isSanctionedForCardShipping
      }
      supportedLocalCardCollectionCurrencies {
        instrumentCodeString
        name
      }
      supportedLocalReimbursementsCollectionCurrencies {
        instrumentCodeString
        name
      }
    }
  }
`;

export const customerBillingGroups = gql`
  query CustomerBillingGroups($filters: BillingGroupFilterInput) {
    billingGroups(filters: $filters) {
      edges {
        node {
          id
          billedEntity {
            id
            displayName
            isDefault
          }
          totalBalance {
            quantity
          }
        }
      }
      totalCount
    }
  }
`;

export const customerMultiEntityUsage = gql`
  query CustomerMultiEntityUsage {
    legalEntities {
      edges {
        node {
          id
        }
      }
    }
    billingGroups(
      filters: {
        productTypes: [CARD, REIMBURSEMENTS]
        billableStates: [ENABLED, PENDING]
      }
    ) {
      edges {
        node {
          id
          billingFinancialInstrument {
            instrumentCodeString
          }
          billedEntity {
            id
            displayName
            country {
              alpha2
            }
          }
        }
      }
    }
  }
`;

export const billingGroupReimbursementFundingSource = gql`
  query BillingGroupReimbursementFundingSource {
    billingGroups(
      filters: { productTypes: [REIMBURSEMENTS, EMPLOYEE_TRACKING] }
    ) {
      edges {
        node {
          id
          productType
          billedEntity {
            id
            isDefault
          }
          fundingSource {
            id
            ...FundingSourceFragmentWithFinancialAccount
          }
        }
      }
    }
  }
  ${fundingSourceWithFinancialAccountFragment}
`;

export const legalEntityOptionById = gql`
  query LegalEntityOptionById($id: ID!) {
    legalEntity(identifier: { id: $id, type: ID }) {
      id
      displayName
    }
  }
`;
