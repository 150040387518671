import { useCallback } from "react";
import { useMockableBudgetConfigurationFlags } from "@/hooks/useBudgetConfigurationFlags";

/**
 * Hook to determine if the current account uses Bifurcated Budgets (Budgets V1 / Budgets V2)
 *
 * ERD: https://docs.google.com/document/d/1-vFR0AZA-gJDIH-ikmUJrPzzzPLaNRC-NYCLSMRwp1I
 */

export const useBifurcatedBudgetFeatureFlags = () => {
  const { BIFURCATION } = useMockableBudgetConfigurationFlags();
  return BIFURCATION ?? false;
};

/**
 * This hook will facilitate i18n of text in the bifurcated world.
 * All ids within *.ftl files that switch between bifurcated text will be suffixed with "-budgets-v1"
 *
 * Budgets V1 is the official name of the Bifurcated Budgets project, the reason being that our
 * current implementation is Budgets V0
 *
 * Example usage with i18n:
 *    t("id")
 *
 * Example usage with i18n supporting bifurcation:
 *    t(bt("id"))
 */

export const useBifurcatedBudgetTranslation = () => {
  const isBifurcated = useBifurcatedBudgetFeatureFlags();

  const getBifurcatedTranslation = useCallback(
    (id: string) => (isBifurcated ? `${id}-budgets-v1` : id),
    [isBifurcated],
  );

  return { bt: getBifurcatedTranslation };
};
