import type { TaskStatus, TaskType } from "@/__generated__/globalTypes";
import type {
  CellError,
  CsvFileError,
} from "@/features/CsvUploader/sharedTypes";

export enum BulkUserMgmtSteps {
  Upload = "Upload",
  ReviewEntities = "ReviewEntities",
  ReviewData = "ReviewData",
  Success = "Success",
}

export type ErrorType = {
  message: string;
  location: {
    column: string | null;
    rowNumber: number;
  };
};

export type UserUploadTask = {
  id: string;
  type: TaskType;
  status: TaskStatus;
  insertedAt: string;
  assetId: string;
  numUsersCreated: number;
  numUsersUpdated: number;
  spreadsheetErrors: ErrorType[];
  errorMessage: string;
  uniqueErrorRowCount: number;
};

export enum StepStatusType {
  ACTIVE = "active",
  COMPLETED = "completed",
  INACTIVE = "inactive",
  ERROR = "error",
}

export enum AnalyticsEvents {
  BulkUserMgmtOboardingSelected = "bulk_user_mgmt.onboarding.selected",
  BulkUserMgmtTeamDropdownClicked = "bulk_user_mgmt.team.dropdown.clicked",
  BulkUserMgmtUploadComplete = "bulk_user_mgmt.upload.complete",
  BulkUserMgmtUnrecognizedEntitiesMounted = "bulk_user_mgmt.unrecognized_entities.mounted",
  BulkUserMgmtFileFormatError = "bulk_user_mgmt.file_format.error",
  BulkUserMgmtFileSubmitSuccess = "bulk_user_mgmt.file.submit.success",
  BulkUserMgmtPendingBannerView = "bulk_user_mgmt.pending.banner.view",
  BulkUserMgmtSuccessBannerView = "bulk_user_mgmt.success.banner.view",
  BulkUserMgmtPartialErrorBannerView = "bulk_user_mgmt.partial_error.banner.view",
  BulkUserMgmtPartialErrorBannerClick = "bulk_user_mgmt.partial_error.banner.click",
  BulkUserMgmtFullErrorBannerView = "bulk_user_mgmt.full_error.banner.view",
}

type EmptyPayload = {};

type ErrorRowCount = {
  uniqueErrorRows: number;
};

export type EventSchemaMap = {
  [AnalyticsEvents.BulkUserMgmtOboardingSelected]: EmptyPayload;
  [AnalyticsEvents.BulkUserMgmtTeamDropdownClicked]: EmptyPayload;
  [AnalyticsEvents.BulkUserMgmtUploadComplete]: EmptyPayload;
  [AnalyticsEvents.BulkUserMgmtUnrecognizedEntitiesMounted]: EmptyPayload;
  [AnalyticsEvents.BulkUserMgmtFileFormatError]: CsvFileError;
  [AnalyticsEvents.BulkUserMgmtFileSubmitSuccess]: EmptyPayload;
  [AnalyticsEvents.BulkUserMgmtPendingBannerView]: EmptyPayload;
  [AnalyticsEvents.BulkUserMgmtSuccessBannerView]: EmptyPayload;
  [AnalyticsEvents.BulkUserMgmtPartialErrorBannerView]: ErrorRowCount;
  [AnalyticsEvents.BulkUserMgmtPartialErrorBannerClick]: EmptyPayload;
  [AnalyticsEvents.BulkUserMgmtFullErrorBannerView]: EmptyPayload;
};

export type UnrecognizedEntityData = {
  columnName: EntityColumnName;
  pluralDisplayName: string;
  unrecognizedEntities: string[];
}[];

export type EntityColumnName =
  | "costCenter"
  | "legalEntity"
  | "location"
  | "department"
  | "title";

export type SelectedEntities = { [K in EntityColumnName]: string[] };

export type BulkUserManagementTableRow = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  managerEmail?: string;
  monthlyLimit?: string;
  costCenter?: string;
  department?: string;
  legalEntity?: string;
  location?: string;
  title?: string;
};

export type BulkUserManagementTableColumnIds = keyof BulkUserManagementTableRow;

export type BulkUserManagementTableColumn = {
  id: BulkUserManagementTableColumnIds;
  header: string;
  optional: boolean;
};

export type InvalidLegalEntityRemappingError = {
  row: number;
  unverifiedLegalEntityName: string;
};

export type BulkUserManagementCsvValidationError =
  | CellError
  | InvalidLegalEntityRemappingError;

export type FormattedErrors = {
  message: string;
  rowNumber: number;
  column: string | null;
  id: string;
}[];
