import { useTranslation } from "@brexhq/i18n";
import Cookies from "js-cookie";
import * as React from "react";
import { LOCALE_PREFERENCE_COOKIE_NAME } from "@/domains/App/features/Localization/helpers/constants";
import { getEnvironment } from "@/helpers/environment";

const cookieDomain = (): string => {
  const env = getEnvironment("APP_ENV");
  if (env === "localhost") {
    return "localhost";
  }
  if (env === "stg") {
    return ".brexapps.com";
  }
  if (env === "dev") {
    return ".brexhq.dev";
  }
  return ".brex.com";
};

type UseChangeLanguageProps = {
  callback?: () => void;
  languagePreference: string;
  setCookie?: boolean;
};

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();

  return React.useCallback(
    ({
      callback,
      languagePreference,
      setCookie = true,
    }: UseChangeLanguageProps) => {
      return i18n.changeLanguage(languagePreference, (err) => {
        if (err) {
          throw new Error(err);
        } else {
          callback?.();

          if (setCookie) {
            Cookies.set(LOCALE_PREFERENCE_COOKIE_NAME, languagePreference, {
              sameSite: "strict",
              secure: true,
              domain: cookieDomain(),
            });
          }
        }
      });
    },
    [i18n],
  );
};
