import * as Sentry from "@sentry/browser";
import pRetry, { AbortError } from "p-retry";
import type {
  GraphQLSingularResponse,
  RequestParameters,
  Variables,
} from "relay-runtime";
import type { SentryBreadcumbOperationInfo } from "@/features/Apollo/helpers/analytics";
import { isOperationExemptFromAuthentication } from "@/features/Apollo/helpers/authExemptRequests";
import type { useGetCustomHeaders } from "@/features/Apollo/helpers/getCustomHeaders";
import getUri from "@/features/Apollo/helpers/getUri";
import type { GqlContext } from "@/features/Apollo/sharedTypes";
import { getTokenState } from "@/features/Authentication/helpers/getTokenState";
import type { useBeginOpenIDAuthorization } from "@/features/Authentication/hooks/useBeginOpenIDAuthorization";
import type { useTrackError } from "@/helpers/errorTracking";
import {
  addDatadogRUMGraphqlRequestMetadata,
  type GqlErrorHandler,
} from "@/helpers/graphqlRequests";

// FIXME: we likely want the lock to be shared between Apollo and Relay
let tokenRefreshLock: Promise<
  { success: true } | { success: false; error: unknown }
> | null = null;

export type FetchFnAdditionalParams = {
  getCustomHeaders: ReturnType<typeof useGetCustomHeaders>;
  getAccessToken: () => string | null;
  refreshToken: () => Promise<void>;
  beginOpenIDAuthorization: ReturnType<typeof useBeginOpenIDAuthorization>;
  trackError: ReturnType<typeof useTrackError>["trackError"];
  handleError: GqlErrorHandler;
};

const operationInfo = (
  params: RequestParameters,
  metadata: GqlContext | undefined,
): SentryBreadcumbOperationInfo => ({
  type: params.operationKind as SentryBreadcumbOperationInfo["type"],
  name: params.name,
  gqlClient: "relay",
  fragments: metadata?.metadata?.fragmentNames.join(","),
});

class GqlFetchError extends Error {
  response: Response | undefined;

  constructor(
    message: string,
    options: ErrorOptions & { response: Response | undefined },
  ) {
    super(message, options);
    this.response = options.response;
  }

  static fromError(error: unknown, response: Response | undefined) {
    return new GqlFetchError(
      error instanceof Error ? error.message : "Unknown error",
      { cause: error, response: response },
    );
  }
}
class GqlParseError extends GqlFetchError {}

type FetchParams = {
  params: RequestParameters;
  variables: Variables;
  metadata: GqlContext | undefined;
} & FetchFnAdditionalParams;

const baseFetch = async ({
  params,
  variables,
  metadata,
  getCustomHeaders,
  getAccessToken,
  refreshToken,
  beginOpenIDAuthorization,
  trackError,
}: FetchParams) => {
  let response: Response | undefined = undefined;

  try {
    Sentry.addBreadcrumb({
      category: "graphql",
      level: "info",
      message: "request sent",
      data: operationInfo(params, metadata),
    });

    if (!isOperationExemptFromAuthentication({ operationName: params.name })) {
      const { expired, needRefresh } = getTokenState(getAccessToken());
      if (expired) {
        void beginOpenIDAuthorization({ interactive: true });

        throw new AbortError("Access token expired");
      } else if (needRefresh) {
        // `??=` guarantees we only trigger one token refresh at a time
        tokenRefreshLock ??= refreshToken()
          .then(() => ({ success: true }) as const)
          .catch((error) => {
            // We could consider loging out the user here, but for now, it's better if
            // we let our downstream apollo-link to handle the subsequent 401 responses,
            // and proceed to logout the user there
            trackError("Error refreshing access token", error);
            void beginOpenIDAuthorization({ interactive: true });

            return { success: false, error } as const;
          });
        const tokenRefreshResult = await tokenRefreshLock;

        if (!tokenRefreshResult.success) {
          throw new AbortError(
            new Error("Failed to refresh access token", {
              cause: tokenRefreshResult.error,
            }),
          );
        }
      }
    }

    response = await fetch(
      getUri({
        operationName: params.name,
        extraSearchParams: new URLSearchParams({ client: "relay" }),
      }),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getCustomHeaders({
            graphqlClient: "relay",
            isMutation: params.operationKind === "mutation",
            productSurfaceSuffix: metadata?.productSurfaceSuffix,
            controllerName: metadata?.controllerContext?.controllerName,
            operationName: params.name,
            requestId: metadata?.metadata?.requestId,
          }),
        } satisfies Partial<HeadersInit> as HeadersInit,
        credentials: "same-origin",
        body: JSON.stringify({
          query: params.text,
          variables,
        }),
      },
    );

    try {
      return {
        data: (await response.json()) as GraphQLSingularResponse,
        response,
      };
    } catch (cause) {
      // we're currently retrying the fetch after parse errors — is that the right thing to do?
      throw new GqlParseError("Failed to parse response", {
        cause,
        response,
      });
    }
  } catch (error) {
    if (error instanceof AbortError) {
      if (error.cause && !(error.cause instanceof GqlFetchError)) {
        error.cause = GqlFetchError.fromError(error.cause, response);
      }
      throw error;
    }

    if (!(error instanceof GqlFetchError)) {
      throw GqlFetchError.fromError(error, response);
    }

    throw error;
  }
};

export const fetchWithRetry = async (params: FetchParams) => {
  const addDatadogRUMGraphqlResponseMetadata =
    addDatadogRUMGraphqlRequestMetadata(params.metadata);

  const handleNetworkError = (
    handlerParams: Omit<Parameters<GqlErrorHandler>[0], "retry"> & {
      onNotRetry: () => Promise<GraphQLSingularResponse>;
    },
  ) => {
    let resolve!: (
      value: GraphQLSingularResponse | Promise<GraphQLSingularResponse>,
    ) => void;
    const promise = new Promise<GraphQLSingularResponse>((res) => {
      resolve = res;
    });

    const retried = params.handleError({
      ...handlerParams,
      retry: () => resolve(fetchWithRetry(params)),
    });

    if (!retried) {
      resolve(handlerParams.onNotRetry());
    }

    return promise;
  };

  const result = await pRetry(() => baseFetch(params), {
    retries: 5,
    minTimeout: 300,
    maxTimeout: Infinity,
    randomize: true,
    onFailedAttempt: (error) => {
      Sentry.addBreadcrumb({
        category: "graphql",
        level: "warning",
        message: `networkError: "${error.message}"`,
        data: operationInfo(params.params, params.metadata),
      });
    },
  })
    .then((data) => ({ data }))
    .catch((error: unknown) => ({ error }));

  if ("error" in result) {
    const error =
      result.error && result.error instanceof AbortError
        ? result.error.cause
        : result.error;
    const response =
      error && error instanceof GqlFetchError ? error.response : undefined;

    return handleNetworkError({
      // what statusCode to use if we didn't even get a response?
      statusCode: response?.status ?? -1,
      headers: response?.headers,
      errors: [],
      failedToParse: error ? error instanceof GqlParseError : false,
      isApolloServerError: false,
      rumMetadata: params.metadata?.metadata,
      onNotRetry: () => Promise.reject(error),
    });
  }

  const { response, data } = result.data;

  addDatadogRUMGraphqlResponseMetadata({
    response: data,
    headers: response.headers,
    fetchTimingInfo: undefined,
  });

  if (!response.ok) {
    return handleNetworkError({
      statusCode: response.status,
      headers: response.headers,
      errors: data && "errors" in data ? data.errors : undefined,
      failedToParse: false,
      isApolloServerError: false,
      rumMetadata: params.metadata?.metadata,
      onNotRetry: () => Promise.resolve(data),
    });
  }

  if ("errors" in data && data.errors && data.errors.length > 0) {
    for (const error of data.errors) {
      Sentry.addBreadcrumb({
        category: "graphql",
        level: "warning",
        message: `graphqlError: "${error.message}"`,
        data: operationInfo(params.params, params.metadata),
      });
    }
  }

  return data;
};
