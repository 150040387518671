import type { StatusProps } from "@brexhq/component-library";
import { StatusType } from "@brexhq/component-library";
import type { IconName, IconProps } from "@brexhq/metal-icon";
import type {
  CreateWorkflowRuleInput,
  ExpenseFilterInput,
} from "@/__generated__/globalTypes";
import {
  ExpenseFieldKeyType,
  BudgetPeriodType,
  CashTransactionStatus,
  CashTransactionCancellationReason,
  BudgetTemplateType,
  StatusView,
  BillingGroupProductType,
  ExpenseType,
  UserStatus,
  AccountingJournalEntryAlert as EntryAlert,
  BankingTransactionStatus,
  BankingTransactionType,
  BankingTransactionCancellationReason,
  RuleApplicationConditionAccountingJournalEntryState,
} from "@/__generated__/globalTypes";
import { BudgetStatusFilterValues } from "@/domains/Primitives/shared/helpers/budgetFiltersTypes";
import {
  DateTypeFilterInput,
  AccountingJournalEntryAttributeAlert as EntryAttributeAlert,
  FilterKeys,
} from "@/domains/Primitives/shared/sharedTypes";
import type { AccountingAlert } from "@/domains/Primitives/shared/sharedTypes";
import type { BudgetIconColor } from "@/features/Budgets/sharedTypes";
import type { AsRelayEnum } from "@/helpers/typeHelpers";

export const BUDGET_RECURRENCE_OPTIONS = [
  {
    value: BudgetPeriodType.ONE_TIME,
    label: "Never",
  },
  {
    value: BudgetPeriodType.PER_WEEK,
    label: "Every week",
  },
  {
    value: BudgetPeriodType.PER_MONTH,
    label: "Every month",
  },
  {
    value: BudgetPeriodType.PER_QUARTER,
    label: "Every quarter",
  },
  {
    value: BudgetPeriodType.PER_YEAR,
    label: "Every year",
  },
];

const baseRecurrenceText = "This budget will reset at the beginning of each ";
export const BUDGET_RECURRENCE_ASSISTIVE_TEXT = {
  [BudgetPeriodType.INVALID]: "",
  [BudgetPeriodType.ONE_TIME]: "",
  [BudgetPeriodType.PER_WEEK]: baseRecurrenceText + "week",
  [BudgetPeriodType.PER_MONTH]: baseRecurrenceText + "month",
  [BudgetPeriodType.PER_QUARTER]: baseRecurrenceText + "quarter",
  [BudgetPeriodType.PER_YEAR]: baseRecurrenceText + "year",
};

export const BUDGET_RECURRENCE_OPTIONS_NO_ONE_TIME =
  BUDGET_RECURRENCE_OPTIONS.filter(
    (option) => option.value !== BudgetPeriodType.ONE_TIME,
  );

export const BUDGET_RECURRENCE_DROPDOWN_OPTIONS = [
  {
    value: BudgetPeriodType.PER_WEEK,
    label: "Weekly",
    period: "week",
  },
  {
    value: BudgetPeriodType.PER_MONTH,
    label: "Monthly",
    period: "month",
  },
  {
    value: BudgetPeriodType.PER_QUARTER,
    label: "Quarterly",
    period: "quarter",
  },
  {
    value: BudgetPeriodType.PER_YEAR,
    label: "Yearly",
    period: "year",
  },
];
export const DEFAULT_BUDGET_RECURRENCE = BUDGET_RECURRENCE_OPTIONS[0];

export const DEFAULT_BUDGET_STATUS_FILTER_VALUE =
  BudgetStatusFilterValues.ACTIVE;

export const MethodByTransactionDetailsTypeMap = {
  CashTransactionAchDetails: "ACH",
  CashTransactionAchReturnDetails: "ACH",
  CashTransactionAdminBalanceAdjustmentDetails: "Adjustment",
  CashTransactionBookTransferDetails: "Transfer",
  CashTransactionBrexOperationalTransferDetails: "Transfer",
  CashTransactionCardCollectionDetails: "Card payment",
  CashTransactionDepositedChequeDetails: "Check",
  CashTransactionDepositedChequeReturnDetails: "Check",
  CashTransactionDividendDetails: "Dividend",
  CashTransactionDomesticWireDetails: "Domestic wire",
  CashTransactionDomesticWireReturnDetails: "Domestic wire",
  CashTransactionFboTransferDetails: "FBO transfer",
  CashTransactionInterestDetails: "Interest",
  CashTransactionInternationalWireDetails: "International wire",
  CashTransactionInternationalWireReturnDetails: "International wire",
  CashTransactionIntraCuaccBookTransferDetails: "Transfer",
  CashTransactionOutgoingChequeDetails: "Check",
  CashTransactionOutgoingChequeReturnDetails: "Check",
  CashTransactionPayrollEstimateDetails: "ACH",
  CashTransactionRewardsRedemptionDetails: "Rewards redeemed",
  // delete ReceivablesOffers references when they are removed from the BE generated type
  CashTransactionReceivablesOffersAdvanceDetails: "Deposit",
  CashTransactionReceivablesOffersCollectionDetails: "Collection",
  CashTransactionReceivablesOffersRepaymentDetails: "Collection",
};

export type BBAStatusFilterOptions =
  | BankingTransactionStatus.TRANSACTION_STATUS_APPROVAL_REQUIRED
  | BankingTransactionStatus.TRANSACTION_STATUS_PROCESSED
  | BankingTransactionStatus.TRANSACTION_STATUS_PROCESSING
  | BankingTransactionStatus.TRANSACTION_STATUS_SCHEDULED
  | BankingTransactionStatus.TRANSACTION_STATUS_FAILED;

export const BBA_STATUS_LABEL_MAP: Record<
  BBAStatusFilterOptions,
  { label: string; groupHeading?: string }
> = {
  [BankingTransactionStatus.TRANSACTION_STATUS_PROCESSED]: {
    label: "processed-status-label",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_APPROVAL_REQUIRED]: {
    label: "pending-status-label",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_PROCESSING]: {
    label: "processing-status-label",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_FAILED]: {
    label: "failed-status-label",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_SCHEDULED]: {
    label: "scheduled-status-label",
  },
};
export type BBAMethodFilterV2Options =
  | BankingTransactionType.TRANSACTION_TYPE_ACH
  | BankingTransactionType.TRANSACTION_TYPE_WIRE
  | BankingTransactionType.TRANSACTION_TYPE_CHEQUE
  | BankingTransactionType.TRANSACTION_TYPE_INTRA_CUSTOMER_ACCOUNT_BOOK_TRANSFER;

export const BBA_METHOD_V2_LABEL_MAP: Record<
  BBAMethodFilterV2Options,
  { label: string; groupHeading?: string }
> = {
  [BankingTransactionType.TRANSACTION_TYPE_ACH]: {
    label: "ach-method-label",
  },
  [BankingTransactionType.TRANSACTION_TYPE_CHEQUE]: {
    label: "check-method-label",
  },
  [BankingTransactionType.TRANSACTION_TYPE_WIRE]: {
    label: "wire-method-label",
  },
  [BankingTransactionType.TRANSACTION_TYPE_INTRA_CUSTOMER_ACCOUNT_BOOK_TRANSFER]:
    {
      label: "transfers-method-label",
    },
};

export const BankingTransactionMethodPropsMap: Record<
  string | symbol,
  { label: string; icon?: IconProps["name"] }
> = {
  [BankingTransactionType.TRANSACTION_TYPE_REWARDS_REDEMPTION]: {
    label: "Rewards redemption",
  },
  [BankingTransactionType.TRANSACTION_TYPE_CHEQUE_RETURN]: {
    label: "Check return",
  },
  [BankingTransactionType.TRANSACTION_TYPE_INTEREST]: {
    label: "Interest",
  },
  [BankingTransactionType.TRANSACTION_TYPE_DIVIDEND]: {
    label: "Dividend",
  },
  [BankingTransactionType.TRANSACTION_TYPE_PAYROLL_ESTIMATE]: {
    label: "ACH",
  },
  [BankingTransactionType.TRANSACTION_TYPE_WIRE_RETURN]: {
    label: "Wire return",
  },
  [BankingTransactionType.TRANSACTION_TYPE_ACH]: {
    label: "ACH",
  },
  [BankingTransactionType.TRANSACTION_TYPE_ACH_RETURN]: {
    label: "ACH return",
  },
  [BankingTransactionType.TRANSACTION_TYPE_ADMIN_BALANCE_ADJUSTMENT]: {
    label: "Adjustment",
  },
  [BankingTransactionType.TRANSACTION_TYPE_CHEQUE]: {
    label: "Check",
  },
  [BankingTransactionType.TRANSACTION_TYPE_WIRE]: {
    label: "Wire",
  },
  [BankingTransactionType.TRANSACTION_TYPE_CARD_COLLECTION]: {
    label: "Card payment",
  },
  [BankingTransactionType.TRANSACTION_TYPE_FBO_TRANSFER]: {
    label: "FBO transfer",
    icon: "cashtoCash",
  },
  [BankingTransactionType.TRANSACTION_TYPE_BOOK_TRANSFER]: {
    label: "Transfer",
    icon: "cashtoCash",
  },
  [BankingTransactionType.TRANSACTION_TYPE_INTRA_CUSTOMER_ACCOUNT_BOOK_TRANSFER]:
    {
      label: "Transfer",
      icon: "cashtoCash",
    },
  [BankingTransactionType.TRANSACTION_TYPE_RECEIVABLES_OFFERS_ADVANCE]: {
    label: "Deposit",
  },
  [BankingTransactionType.TRANSACTION_TYPE_RECEIVABLES_OFFERS_COLLECTION]: {
    label: "Collection",
  },
  [BankingTransactionType.TRANSACTION_TYPE_RECEIVABLES_OFFERS_REPAYMENT]: {
    label: "Collection",
  },
};
export const BankingTransactionStatusPropsMap: Record<
  string | symbol,
  { label: string; status: StatusType; icon?: StatusProps["icon"] }
> = {
  [BankingTransactionStatus.TRANSACTION_STATUS_APPROVAL_REQUIRED]: {
    label: "Requires approval",
    status: StatusType.WARNING,
    icon: "userLockFilled",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_DELETED]: {
    label: "Deleted",
    status: StatusType.DANGER,
    icon: "errorFilled",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_DRAFT]: {
    label: "Draft",
    status: StatusType.WARNING,
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_FAILED]: {
    label: "Failed",
    status: StatusType.DANGER,
    icon: "errorFilled",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_PROCESSED]: {
    label: "Complete",
    status: StatusType.SUCCESS,
    icon: "checkmarkinCircleFilled",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_PROCESSING]: {
    label: "Processing",
    status: StatusType.INCOMPLETE,
    icon: "sync",
  },
  [BankingTransactionStatus.TRANSACTION_STATUS_SCHEDULED]: {
    label: "Scheduled",
    status: StatusType.NEUTRAL,
    icon: "calendarRange",
  },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_ACCOUNT_CLOSED]:
    {
      label: "Account closed",
      status: StatusType.DANGER,
      icon: "reimbursementFailed",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_APPROVAL_DENIED]:
    {
      label: "Approval denied",
      status: StatusType.DANGER,
      icon: "userTerminateFilled",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_BLOCKED_BY_POSITIVE_PAY]:
    {
      label: "Transaction blocked",
      status: StatusType.DANGER,
      icon: "errorFilled",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_INSUFFICIENT_FUNDS]:
    {
      label: "Insufficient funds",
      status: StatusType.DANGER,
      icon: "errorFilled",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_MISSED_SEND_ON]:
    {
      label: "Insufficient funds",
      status: StatusType.DANGER,
      icon: "errorFilled",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_ACCOUNT_NOT_FOUND]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_ACH_DEBIT_CAPABILITY_REVOKED]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_CUSTOMER_EDITED]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_FAILED_VERIFICATION]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_USER_CANCELLED]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_SYSTEM_CANCELLED]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_UNAUTHORIZED_VENDOR_DRAWDOWN_REQUEST]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_EXCEEDS_AUTHORIZED_VENDOR_DRAWDOWN_AMOUNT]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [BankingTransactionCancellationReason.TRANSACTION_CANCELLATION_REASON_SACA_BALANCE_COMMITMENT_VIOLATION]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
};

export const CashTransactionStatusPropsMap: Record<
  string | symbol,
  { label: string; status: StatusType; icon?: StatusProps["icon"] }
> = {
  [CashTransactionStatus.APPROVAL_REQUIRED]: {
    label: "Requires approval",
    status: StatusType.WARNING,
    icon: "userLockFilled",
  },
  [CashTransactionStatus.DELETED]: {
    label: "Deleted",
    status: StatusType.DANGER,
    icon: "errorFilled",
  },
  [CashTransactionStatus.DRAFT]: {
    label: "Draft",
    status: StatusType.WARNING,
  },
  [CashTransactionStatus.FAILED]: {
    label: "Failed",
    status: StatusType.DANGER,
    icon: "errorFilled",
  },
  [CashTransactionStatus.PROCESSED]: {
    label: "Complete",
    status: StatusType.SUCCESS,
    icon: "checkmarkinCircleFilled",
  },
  [CashTransactionStatus.PROCESSING]: {
    label: "Processing",
    status: StatusType.INCOMPLETE,
    icon: "sync",
  },
  [CashTransactionStatus.SCHEDULED]: {
    label: "Scheduled",
    status: StatusType.NEUTRAL,
    icon: "calendarRange",
  },
  PAYROLL_ESTIMATE: {
    label: "Payroll estimate",
    status: StatusType.NEUTRAL,
    icon: "usersPayroll",
  },
  [CashTransactionCancellationReason.ACCOUNT_CLOSED]: {
    label: "Account closed",
    status: StatusType.DANGER,
    icon: "reimbursementFailed",
  },
  [CashTransactionCancellationReason.APPROVAL_DENIED]: {
    label: "Approval denied",
    status: StatusType.DANGER,
    icon: "userTerminateFilled",
  },
  [CashTransactionCancellationReason.BLOCKED_BY_POSITIVE_PAY]: {
    label: "Transaction blocked",
    status: StatusType.DANGER,
    icon: "errorFilled",
  },
  [CashTransactionCancellationReason.INSUFFICIENT_FUNDS]: {
    label: "Insufficient funds",
    status: StatusType.DANGER,
    icon: "errorFilled",
  },
  [CashTransactionCancellationReason.MISSED_SEND_ON]: {
    label: "Insufficient funds",
    status: StatusType.DANGER,
    icon: "errorFilled",
  },
  [CashTransactionCancellationReason.ACCOUNT_NOT_FOUND]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
  [CashTransactionCancellationReason.ACH_DEBIT_CAPABILITY_REVOKED]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
  [CashTransactionCancellationReason.CUSTOMER_EDITED]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
  [CashTransactionCancellationReason.FAILED_VERIFICATION]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
  [CashTransactionCancellationReason.INVALID]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
  [CashTransactionCancellationReason.USER_CANCELLED]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
  [CashTransactionCancellationReason.SYSTEM_CANCELLED]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
  [CashTransactionCancellationReason.UNAUTHORIZED_VENDOR_DRAWDOWN_REQUEST]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
  [CashTransactionCancellationReason.EXCEEDS_AUTHORIZED_VENDOR_DRAWDOWN_AMOUNT]:
    {
      label: "Canceled",
      status: StatusType.NEUTRAL,
      icon: "terminated",
    },
  [CashTransactionCancellationReason.SACA_BALANCE_COMMITMENT_VIOLATION]: {
    label: "Canceled",
    status: StatusType.NEUTRAL,
    icon: "terminated",
  },
};

export const CashTransactionCanceledStatuses = [
  CashTransactionCancellationReason.INVALID,
  CashTransactionCancellationReason.MISSED_SEND_ON,
  CashTransactionCancellationReason.USER_CANCELLED,
  CashTransactionCancellationReason.SYSTEM_CANCELLED,
  CashTransactionCancellationReason.CUSTOMER_EDITED,
  CashTransactionCancellationReason.ACCOUNT_NOT_FOUND,
  CashTransactionCancellationReason.ACH_DEBIT_CAPABILITY_REVOKED,
  CashTransactionCancellationReason.UNAUTHORIZED_VENDOR_DRAWDOWN_REQUEST,
  CashTransactionCancellationReason.EXCEEDS_AUTHORIZED_VENDOR_DRAWDOWN_AMOUNT,
  CashTransactionCancellationReason.SACA_BALANCE_COMMITMENT_VIOLATION,
];

/** @note Any receipt with origin that starts with "auto_addendum_receipt" will be autogenerated. */
export const AUTO_GENERATED_RECEIPT_ORIGIN_PREFIX = "auto_addendum_receipt";

// count limit for expense badges and table titles
export const EXPENSE_COUNT_LIMIT = 10000;
export const EXPENSE_COUNT_LIMIT_COPY = "10k+";

export const disabledRequestLimitIncreaseCtaText =
  "You have a pending limit increase request on this budget. To submit a new limit increase request, withdraw your existing request.";

export const ExtendedFieldBoolTypeLabel = "Checkbox" as const;
export const ExtendedFieldIdentifierTypeLabel = "List" as const;
export const ExtendedFieldStringTypeLabel = "Freeform" as const;

// NOTE: these are the encoded identifiers for vat field options
// with static identifiers
export enum VatExtendedFieldIdentifiers {
  // Identifier:custom
  Custom = "SWRlbnRpZmllcjpjdXN0b20=",
  // Identifier:not_eligible
  NotEligible = "SWRlbnRpZmllcjpub3RfZWxpZ2libGU=",
  // Identifier:vat_eligible
  VatEligible = "SWRlbnRpZmllcjp2YXRfZWxpZ2libGU=",
  // Identifier:tax_exempt
  TaxExempt = "SWRlbnRpZmllcjp0YXhfZXhlbXB0",
}

export const VatExtendedFieldIdentifiersToTaxCodes: {
  [key in VatExtendedFieldIdentifiers]: {
    taxCodeName?: string;
    taxCodeRate?: number;
  };
} = {
  [VatExtendedFieldIdentifiers.Custom]: {
    taxCodeName: "Custom rate",
    taxCodeRate: undefined,
  },
  [VatExtendedFieldIdentifiers.NotEligible]: {
    taxCodeName: "Not eligible",
    taxCodeRate: undefined,
  },
  [VatExtendedFieldIdentifiers.VatEligible]: {
    taxCodeName: "VAT eligible",
    taxCodeRate: undefined,
  },
  [VatExtendedFieldIdentifiers.TaxExempt]: {
    taxCodeName: "Tax exempt",
    taxCodeRate: undefined,
  },
};

/** Mileage constants */
export const MILEAGE_RATE_PRECISION = 6;

/** Expense grouping and drilling constants */
export const drillByParamKey = "drillBy";
export const groupByParamKey = "groupBy";
export const groupBySource = "groupBySource";

/** Query params */
export const makePaymentQueryParam = "makePayment";
export const legalEntityIdQueryParam = "legalEntityId";
export const billedEntitiesDetailsPaneQueryParam = "manageBilledEntities";

export const mostRecentTransactionNumRows = 5;

export const getBudgetTemplateTypes = (
  type: AsRelayEnum<BudgetTemplateType>,
  isUserLimitTemplateType: boolean,
): { title: string; icon: IconName; color?: BudgetIconColor } => {
  switch (type) {
    case BudgetTemplateType.OFFSITE:
      return {
        title: "Offsite",
        icon: "moodScale5",
        color: "purple",
      };
    case BudgetTemplateType.OTHER: {
      if (isUserLimitTemplateType) {
        return {
          title: "User limit",
          icon: "usersPayroll",
          color: "pink",
        };
      }
      return {
        title: "General",
        icon: "other",
        color: "yellow",
      };
    }
    case BudgetTemplateType.PROCUREMENT:
      return {
        title: "Procurement",
        icon: "shipping",
        color: "blue",
      };
    case BudgetTemplateType.STIPEND:
      return {
        title: "Stipend",
        icon: "bankNote",
        color: "green",
      };
    case BudgetTemplateType.TRAVEL:
      return {
        title: "Travel",
        icon: "travel",
        color: "primary",
      };
    default:
      return {
        title: "General",
        icon: "other",
        color: "yellow",
      };
  }
};

export const DEFAULT_DATE_TYPE_FILTER_VALUE = DateTypeFilterInput.PURCHASE_DATE;

export const MAX_ACCOUNTING_AUTOMATION_SUGGESTIONS_TO_FETCH = 30;

export const CardStatusViewLabels = {
  [StatusView.ACTIVE]: "Active",
  [StatusView.EXPIRED]: "Expired",
  [StatusView.LOCKED]: "Locked",
  [StatusView.TERMINATED]: "Canceled",
  [StatusView.WAITING_ACTIVATION]: "Shipped",
};

// ============  locally funded reimbursement constants  ============

// We do not have a BillingGroupProductType for repayments, but repayments use the
// BillingGroupProductType of the original card expense
// TODO: Support ExpenseType.BILLPAY if possible
export const MAP_EXPENSE_TYPE_TO_BILLING_GROUP_PRODUCT_TYPE: {
  [billingGroup: string]: BillingGroupProductType;
} = {
  [ExpenseType.CARD]: BillingGroupProductType.CARD,
  [ExpenseType.CLAWBACK]: BillingGroupProductType.CARD,
  [ExpenseType.REIMBURSEMENT]: BillingGroupProductType.REIMBURSEMENTS,
};

export const EXPENSE_TYPE_LABELS: {
  [expenseType: string]: { capitalized: string; uncapitalized: string };
} = {
  [ExpenseType.CARD]: {
    uncapitalized: "card expense",
    capitalized: "Card expense",
  },
  [ExpenseType.REIMBURSEMENT]: {
    uncapitalized: "reimbursement",
    capitalized: "Reimbursement",
  },
  [ExpenseType.CLAWBACK]: {
    uncapitalized: "repayment",
    capitalized: "Repayment",
  },
  [ExpenseType.BILLPAY]: { uncapitalized: "bill", capitalized: "Bill" },
};

/** Workflow Rule constants */

export enum WorkflowRuleTagKey {
  ACCOUNTING_INTEGRATION_ID = "accounting_integration_id",
  ACCOUNTING_RULE_TYPE = "accounting_rule_type",
  BUDGET_ID = "budget_id",
  DATA_SOURCE = "data_source",
  RULE_ACTION_LHS = "rule_action_lhs",
  RULE_FILTER_LHS = "rule_filter_lhs",
}

export enum WorkflowRuleTagValue {
  TMC = "TMC",
}

export enum AccountingWorkflowRuleType {
  CUSTOM = "custom",
  MAPPING = "mapping",
}

export enum WorkflowRuleCoreFieldAddress {
  AMOUNT = "expense.customer_invariant_amount",
  APPROVED = "expense.expense_status",
  BUDGET = "expense.budgets_budget_id",
  CARD = "expense.card_id",
  CATEGORY = "expense.categories_category_id",
  COST_CENTER = "expense.cost_center_id",
  DATE = "expense.payments_payment_posted_at",
  DEPARTMENT = "expense.customer_department_id",
  HAS_RECEIPTS = "expense.receipt_count",
  LEGAL_ENTITY = "expense.legal_entity_id",
  LOCATION = "expense.customer_location_id",
  MEMO = "expense.memo",
  MERCHANT = "expense.vendors_vendor_id",
  MERCHANT_CATEGORY = "expense.merchants_category_id",
  USER = "expense.customer_user_id",
  // core field addresses to be added so may change
  VAT = "expense.vat",
}

export const CreateWorkflowRuleAccountingJournalEntryConditions: CreateWorkflowRuleInput["conditions"] =
  [
    {
      accountingJournalEntryCondition: {
        allowedStates: [
          RuleApplicationConditionAccountingJournalEntryState.NOT_CREATED,
          RuleApplicationConditionAccountingJournalEntryState.IN_REVIEW,
        ],
      },
    },
  ];

/** Automation Suggestions constants */

export enum AutomationSuggestionTagKey {
  ACCOUNTING_RULE_SUGGESTION_TYPE = "accounting_rule_suggestion_type",
  NUM_HISTORICAL_EXPENSES = "num_historical_expenses",
}

export enum AutomationSuggestionTagValue {
  DEFAULT_MAPPING_SUGGESTION = "default_mapping_suggestion",
  CUSTOM_RULE_SUGGESTION = "custom_rule_suggestion",
}

/** Extended Field Tag constants */

export enum ExtendedFieldTagKey {
  ACCOUNTING_INTEGRATION_ID = "accounting_integration_id",
  ACCOUNTING_JOURNAL_DISPLAY = "accounting_journal.display",
  DATA_SOURCE = "data_source",
  EXPENSE_BILL_PAY_WORKFLOW_ACCESSIBLE = "expense_bill_pay_workflow_accessible",
  EXPENSE_CARD_WORKFLOW_ACCESSIBLE = "expense_card_workflow_accessible",
  EXPENSE_DETAILS_DISPLAY = "expense_details.display",
  EXPENSE_REIMBURSEMENT_WORKFLOW_ACCESSIBLE = "expense_reimbursement_workflow_accessible",
  GROUP = "group",
  INTEGRATION_FIELD_TYPE = "integration_field_type",
  INTEGRATION_ID = "integration_id",
  VENDOR_INTERNAL_ID = "vendor_internal_id",
}

export enum ExtendedFieldTagDataSourceValue {
  TMC = "TMC",
}

// https://github.com/brexhq/credit_card/blob/main/spend/extensible_fields_library/src/main/kotlin/brex/extensiblefields/library/model/extensions/IntegrationFieldExtension.kt#L14
export enum IntegrationExtendedFieldType {
  BILLABLE_STATUS = "BILLABLE_STATUS",
  CLASS = "CLASS",
  CREDIT_CARD = "CREDIT_CARD",
  CUSTOM = "CUSTOM",
  CUSTOMER = "CUSTOMER",
  DEPARTMENT = "DEPARTMENT",
  EMPLOYEE = "EMPLOYEE",
  // NOTE: This is an anomalous VX field. It is GROUP = ACCOUNTING, but
  // uses INT tags.
  FUNDING_SOURCE = "FUNDING_SOURCE",
  LOCATION = "LOCATION",
  PROJECT = "PROJECT",
  SEGMENT = "SEGMENT",
  SUBSIDIARY = "SUBSIDIARY",
  TAX_CODE = "TAX_CODE",
  USER_CATEGORY = "USER_CATEGORY",
  VENDOR = "VENDOR",
}

export enum ExtendedFieldTagVisibilityType {
  HIDDEN = "hidden",
}

// this constant filters out users that would break attendees
export const EXPENSE_ATTENDEES_VALID_USER_STATUSES: UserStatus[] = [
  UserStatus.ACTIVE,
  UserStatus.DISABLED,
  UserStatus.PENDING_ACTIVATION,
  UserStatus.INVITED,
];

// TODO: Nuke this once enum can be pulled from gql globalTypes
export const accountingIntegrationBillableStatus = {
  BILLABLE_TYPE_BILLABLE: "BILLABLE_TYPE_BILLABLE",
  BILLABLE_TYPE_NON_BILLABLE: "BILLABLE_TYPE_NON_BILLABLE",
  BILLABLE_TYPE_NOT_SET: "BILLABLE_TYPE_NOT_SET",
} as const;

export const ComplianceAJAlerts: AccountingAlert[] = [
  EntryAlert.MISSING_MEMO,
  EntryAlert.MISSING_RECEIPT,
  EntryAlert.OTHER_REASON,
];

export const HighPriorityAccountingAJAlerts: AccountingAlert[] = [
  EntryAlert.CONFLICTING_RULES,
  EntryAlert.MISSING_GL_ACCOUNT,
  EntryAlert.MISSING_REQUIRED_FIELDS,
  EntryAttributeAlert.RESUBMITTED,
  EntryAttributeAlert.REVERTED,
  EntryAttributeAlert.SENT_BACK_FOR_REVIEW,
];

/**
 * This enum stores the param identifiers that
 * open up details panes on different
 * ExpensesTableController uses.
 */
export enum RowIdQueryParam {
  EXPENSE_ID = "expenseId",
  ID = "id",
  SOURCE_BUDGET_ID = "srcBudgetId",
  ENTITY_ID = "entityId",
  DETAILS_PANE_ID = "detailsPaneId",
  EID = "eid",
  MODAL_ID = "modalId",
}

export enum VendorsViewQueryParams {
  VENDOR_ID = "vendorId",
  SEARCH = "search",
}

// Subset of ExpenseFilterInput that can be grouped by, note these are currently assumed to be string arrays
type GroupableExpenseFilterInput = Pick<
  ExpenseFilterInput,
  | "budgetIds"
  | "merchantIds"
  | "merchantCategoryIds"
  | "customerUserIds"
  | "status"
  | "complianceStatuses"
  | "tripIds"
  | "derivedComplianceStatuses"
>;

export const MAP_FIELD_KEY_TO_DRILL_BY: { [key: string]: FilterKeys } = {
  [ExpenseFieldKeyType.EXPENSE_MERCHANTS_MERCHANT_ID]: FilterKeys.merchant,
  [ExpenseFieldKeyType.EXPENSE_MERCHANTS_CATEGORY_ID]:
    FilterKeys.merchantCategory,
  [ExpenseFieldKeyType.EXPENSE_BUDGETS_BUDGET_ID]: FilterKeys.budget,
  [ExpenseFieldKeyType.EXPENSE_CUSTOMER_USER_ID]: FilterKeys.user,
  [ExpenseFieldKeyType.EXPENSE_EXPENSE_STATUS]: FilterKeys.approvalStatus,
  [ExpenseFieldKeyType.REQUEST_COMPLIANCE_STATUS]:
    FilterKeys.documentationDeadline,
  [ExpenseFieldKeyType.TRAVEL_EXPENSE_EXTENSION_TRIPS_TRIP_ID]: FilterKeys.trip,
  [ExpenseFieldKeyType.REQUEST_DERIVED_COMPLIANCE_STATUS]:
    FilterKeys.derivedComplianceStatus,
};

export const MAP_GROUPING_FIELD_KEY_TO_EXPENSE_FILTER: {
  [key: string]: keyof GroupableExpenseFilterInput;
} = {
  [FilterKeys.budget]: "budgetIds",
  [FilterKeys.merchant]: "merchantIds",
  [FilterKeys.merchantCategory]: "merchantCategoryIds",
  [FilterKeys.user]: "customerUserIds",
  [FilterKeys.approvalStatus]: "status",
  [FilterKeys.documentationDeadline]: "complianceStatuses",
  [FilterKeys.trip]: "tripIds",
  [FilterKeys.derivedComplianceStatus]: "derivedComplianceStatuses",
};
