import { stringify } from "qs";
import * as React from "react";
import { Navigate, useLocation, useMatch } from "react-router-dom";
import { useAllowedPath } from "@/domains/App/features/Authorization/hooks/useAllowedPath";
import { usePermissions } from "@/domains/App/features/Permissions";
import { TrackEventOnMount } from "@/features/Analytics/components/TrackEventOnMount";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import { addBankConnectionParam } from "@/features/ManageBankAccounts/helpers/bankParams";
import { urls } from "@/helpers/urls";

type RedirectsProps = {
  canSeeDashboard: boolean;
  canSeeCard: boolean;
  shouldSeeCashApplication: boolean;
  shouldSeeAddBankConnectionModal: boolean;
  mustSeeEmailVerificationScreen: boolean;
  shouldSeeOnboardingExperience: boolean;
  shouldSeeLendingDisclosure: boolean;
};

export const Redirects: React.FC<RedirectsProps> = ({
  children,
  canSeeDashboard,
  canSeeCard,
  shouldSeeCashApplication,
  shouldSeeAddBankConnectionModal,
  mustSeeEmailVerificationScreen,
  shouldSeeOnboardingExperience,
  shouldSeeLendingDisclosure,
}) => {
  const { pathname, search } = useLocation();
  const isAllowed = useAllowedPath(pathname, canSeeCard);
  const isValidationScreen = useMatch(urls.onboarding.activateAccount());
  const permissions = usePermissions();

  const fallbackUrl = permissions["card.yourTransactions.read"]
    ? urls.card.yourTransactions()
    : urls.card.companyTransactions();

  // don't redirect for plaid oauth, it is needed to connect bank accounts during signup (and most of these redirects are for the onboarding application)
  // also don't redirect for sales platform connection, as it is used to make an application decision
  if (pathname === urls.oauth.plaid()) {
    return <>{children}</>;
  }

  if (mustSeeEmailVerificationScreen) {
    if (!isValidationScreen) {
      // TODO we're going to run into issues with this and the selectedProduct query param.
      // The email they open won't have the query param
      return (
        <Navigate
          replace
          to={{ pathname: urls.onboarding.activateAccount(), search }}
        />
      );
    } else {
      return <>{children}</>;
    }
  }

  if (!canSeeDashboard) {
    return <Navigate replace to={urls.auth.logout()} />;
  }

  // if user is trying to access any other cash URL with
  // cash application, send them back
  if (
    shouldSeeCashApplication &&
    pathname.startsWith(urls.cash.root()) &&
    pathname !== urls.onboarding.primitives.cashUpsell()
  ) {
    return <Navigate replace to={urls.onboarding.primitives.cashUpsell()} />;
  }

  if (
    shouldSeeOnboardingExperience &&
    !pathname.startsWith(urls.onboarding.main())
  ) {
    return <Navigate replace to={{ pathname: urls.onboarding.main() }} />;
  }

  // TODO: Felipe.T.
  // Move all other redirections to use `useLegacyPermissions`
  if (!isAllowed) {
    return <Navigate replace to={fallbackUrl} />;
  }

  if (shouldSeeAddBankConnectionModal) {
    return (
      <TrackEventOnMount
        eventName={AllAnalyticsEvents.ConnectBankBackdoorActivated}
        eventProps={{ referrer: window.document.referrer }}
      >
        <Navigate
          replace
          to={{
            pathname: urls.primitives.accounts.externalAccounts(),
            search: stringify({
              [addBankConnectionParam]: true,
              useTeller: search.includes("useTeller") ? true : undefined,
              useFinicity: search.includes("useFinicity") ? true : undefined,
            }),
          }}
        />
      </TrackEventOnMount>
    );
  }

  if (pathname.startsWith(urls.legacy.budgetsInbox())) {
    return (
      <Navigate
        replace
        to={{ pathname: urls.primitives.inbox.budgetRequests(), search }}
      />
    );
  }

  if (
    shouldSeeLendingDisclosure &&
    !pathname.startsWith(urls.onboarding.lendingDisclosure())
  ) {
    return (
      <Navigate
        replace
        to={{ pathname: urls.onboarding.lendingDisclosure() }}
      />
    );
  }

  // This is a workaround for React.FC expecting a ReactElement as a return.
  return <>{children}</>;
};
