import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";

// NOTA-BENE: please contact Product Security before changing these to sync Medusa!

// Enforce alphabetical ordering to help avoid duplicates
/* eslint sort-keys: ["error", "asc"] */
export const defaultPermissions = {
  "team.bulkInviteReimbursementOnlyUsers.send": false,
  "team.clients.read": false,
  "team.company.read": false,
  "team.invite.send": false,
  "team.manager.read": false,
  "team.nestedNav.read": false,
  "team.partners.read": false,
};

type PermissionsKeys = keyof typeof defaultPermissions;
type AllPermissions = { [key in PermissionsKeys]: boolean };
type PartialPermissions = Partial<{ [key in PermissionsKeys]: true }>;

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByRole = {
  [UserRole.REGULAR]: {} as PartialPermissions,
  [UserRole.BOOKKEEPER]: {
    "team.company.read": true,
    "team.nestedNav.read": true,
  } as PartialPermissions,
  [UserRole.ADMIN]: {
    "team.bulkInviteReimbursementOnlyUsers.send": true,
    "team.clients.read": true,
    "team.company.read": true,
    "team.invite.send": true,
    "team.nestedNav.read": true,
    "team.partners.read": true,
  } as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByDepositsRole = {
  [DepositsRole.USER]: {} as PartialPermissions,
  [DepositsRole.BOOKKEEPER]: {} as PartialPermissions,
  [DepositsRole.ADMIN]: {
    "team.bulkInviteReimbursementOnlyUsers.send": true,
    "team.nestedNav.read": true,
  } as PartialPermissions,
  null: {} as PartialPermissions,
};

/* eslint sort-keys: ["error", "asc"] */
export const permissionsByIsManager = {
  false: {} as PartialPermissions,
  true: {
    "team.manager.read": true,
  } as PartialPermissions,
};

// permissionsEligibleForCopilot determines what permissions are eligible for use in Copilot mode.
// Please use caution around what should be set to true and see go/td-wiki for details.
/* eslint sort-keys: ["error", "asc"] */
export const permissionsEligibleForCopilot: AllPermissions = {
  "team.bulkInviteReimbursementOnlyUsers.send": false,
  "team.clients.read": false,
  "team.company.read": false,
  "team.invite.send": false,
  "team.manager.read": false,
  "team.nestedNav.read": false,
  "team.partners.read": false,
};
