export enum SpendLimitToggleRequirementEvents {
  SpendLimitRequirementToggleReimbursementClicked = "spend_limit_requirement_toggle.reimbursement.clicked",
  SpendLimitRequirementToggleBillPayClicked = "spend_limit_requirement_toggle.billPay.clicked",
}

export type SpendLimitToggleRequirementSchemaMap = {
  [SpendLimitToggleRequirementEvents.SpendLimitRequirementToggleReimbursementClicked]: {
    value: boolean;
  };
  [SpendLimitToggleRequirementEvents.SpendLimitRequirementToggleBillPayClicked]: {
    value: boolean;
  };
};
