import type { IconName } from "@brexhq/metal-icon";
import type { StepStatus } from "@brexhq/metal-stepper";
import type { ReactNode } from "react";
import type {
  OnboardingCardDetailsQuery_card_Card as OnboardingCardDetailsQuery_card,
  OnboardingBudgetsQuery_budgets_BudgetConnection_edges_BudgetEdge_node_Budget as OnboardingBudgetsQuery_budgets_edges_node,
  OnboardingBudgetsQuery_ownedBudgets_BudgetConnection_edges_BudgetEdge_node_Budget as OnboardingBudgetsQuery_ownedBudgets_edges_node,
} from "./data/__generated__/queries.generated";
import type { Card } from "@/domains/Primitives/features/WalletEntities/sharedTypes";
import type { BudgetOverviewCardData } from "@/features/Budgets/sharedTypes";

export enum OnboardingExperienceStep {
  SELECT_LANGUAGE = "SELECT_LANGUAGE",
  WELCOME_MFA_FIRST_ADMIN = "WELCOME_MFA_FIRST_ADMIN",
  WELCOME_MFA = "WELCOME_MFA",
  MFA = "MFA",
  USER_KYC = "USER_KYC",
  ORDER_CARD = "ORDER_CARD",
  SPEND_HANDBOOK_INTRO = "SPEND_HANDBOOK_INTRO",
  BUDGET_INTRODUCTION = "BUDGET_INTRODUCTION",
  POLICY_INTRODUCTION = "POLICY_INTRODUCTION",
  GET_STARTED_CARD = "GET_STARTED_CARD",
  USE_CARD_WITH_BUDGET = "USE_CARD_WITH_BUDGET",
  REVIEW_BUDGET_POLICY = "REVIEW_BUDGET_POLICY",
  MANAGE_TEAM_EXPENSES = "MANAGE_TEAM_EXPENSES",
  MANAGE_OWNED_BUDGETS = "MANAGE_OWNED_BUDGETS",
  COMPANY_POLICY_AGREEMENT = "COMPANY_POLICY_AGREEMENT",
  MOBILE_DOWNLOAD = "MOBILE_DOWNLOAD",
  CONNECT_BANK_FOR_REIMBURSEMENT = "CONNECT_BANK_FOR_REIMBURSEMENT",
  NEW_REIMBURSEMENT_REQUEST_DEMO = "NEW_REIMBURSEMENT_REQUEST_DEMO",
  VIDEO_DEMO = "VIDEO_DEMO",
}

export enum OnboardingExperienceSubStep {
  // substeps of CONNECT_BANK_FOR_REIMBURSEMENT
  CONNECT_BANK_FOR_REIMBURSEMENT_INTRO = "CONNECT_BANK_FOR_REIMBURSEMENT_INTRO",
  CONNECT_BANK_FOR_REIMBURSEMENT_SETUP = "CONNECT_BANK_FOR_REIMBURSEMENT_SETUP",
  CONNECT_BANK_FOR_REIMBURSEMENT_SUCCESS = "CONNECT_BANK_FOR_REIMBURSEMENT_SUCCESS",
}

export type OnboardingPersonalization = {
  /**
   * A map of different logos that may be used
   * during the onbnoarding process. ie. image only, vs image + company name.
   */
  logos: Record<string, string | ReactNode>;
  /**
   * A map of colors to be used during onboarding that match the companies
   * brand.
   */
  colors: {
    brandPrimary: string;
    brandGradientStopColor: string;
    handbookJointColor: string;
  };
  /**
   * Visual illustrations displayed on some steps of the Onboarding experience
   */
  illustrations: Partial<
    Record<
      OnboardingExperienceStep | OnboardingExperienceSubStep | string,
      string
    >
  >;
  /**
   * Encoded file id used to determine if company has a policy agreement
   */
  companyPolicyAgreementFileId: string | undefined;
};

/**
 * all onboarding v2 steps (used by state machine)
 */
export const OnboardingExperienceV2Steps = {
  SELECT_LANGUAGE: "SELECT_LANGUAGE",
  WELCOME: "WELCOME",
  REGISTER_PHONE_NUMBER: "REGISTER_PHONE_NUMBER",
  MFA: "MFA",
  WELCOME_FIRST_ADMIN: "WELCOME_FIRST_ADMIN",
  USER_KYC: "USER_KYC",
  COMPANY_POLICY_AGREEMENT: "COMPANY_POLICY_AGREEMENT",
  ORDER_CARD: "ORDER_CARD",
  EDUCATION: "EDUCATION",
  SET_BUDGET_AND_REVIEW_POLICY: "SET_BUDGET_AND_REVIEW_POLICY",
  AUTO_ASSIGN: "AUTO_ASSIGN",
  CONNECT_BANK_FOR_REIMBURSEMENT: "CONNECT_BANK_FOR_REIMBURSEMENT",
  MOBILE_DOWNLOAD: "MOBILE_DOWNLOAD",
  SUCCESS: "SUCCESS",
  PRODUCT_SELECTION: "PRODUCT_SELECTION",
} as const;
export type OnboardingExperienceV2StepValues<T> = T[keyof T];
export type OnboardingExperienceV2Step = OnboardingExperienceV2StepValues<
  typeof OnboardingExperienceV2Steps
>;

/**
 * types for `useOnboardingComponents` hook - each of these types are used to
 * define specific properties sent to each step controller
 */
export type OnboardingCommonStepProps = {
  OnboardingStepComponent: React.FC<any> | null;
  hasError?: boolean;
  onBack?: () => void | null;
  onContinue?: () => void;
  onSkip?: () => void;
  Stepper: React.ReactNode;
  stepperSteps: StepperStepProps[];
  stateContext: OnboardingMachineContext;
};
export type OnboardingCommonStepPropsWithoutComponent<
  OnboardingSpecificStepProps,
> = Omit<OnboardingCommonStepProps, "OnboardingStepComponent"> &
  OnboardingSpecificStepProps;
export type OnboardingWelcomeStepProps = {};
export type OnboardingRegisterPhoneNumberStepProps = {};
export type OnboardingMFAStepProps = {};
export type OnboardingCompanyPolicyAgreementStepProps = {};
export type OnboardingCardShippingStepProps = {
  setCardId: React.Dispatch<React.SetStateAction<string>>;
};
export type OnboardingEducationStepProps = {};
export type OnboardingCardTutorialStepProps = {
  budgets: OnboardingBudgetsQuery_budgets_edges_node[];
  card: OnboardingCardDetailsQuery_card | undefined;
  selectedBudgetId: string;
  onSelectBudget: ({
    budgetId,
    card,
  }: {
    budgetId: string;
    card?: Card | OnboardingCardDetailsQuery_card | undefined;
  }) => Promise<void>;
};
export type OnboardingConnectBankAccountStepProps = {};
export type OnboardingMobileUpsellStepProps = {
  disableBack?: boolean;
};
export type OnboardingSuccessStepProps = {
  isCompletingOnboarding: boolean;
};
export type OnboardingStepComponentProps = OnboardingCommonStepProps &
  (
    | OnboardingRegisterPhoneNumberStepProps
    | OnboardingMFAStepProps
    | OnboardingCompanyPolicyAgreementStepProps
    | OnboardingCardShippingStepProps
    | OnboardingEducationStepProps
    | OnboardingCardTutorialStepProps
    | OnboardingConnectBankAccountStepProps
    | OnboardingMobileUpsellStepProps
    | OnboardingSuccessStepProps
  );

/**
 * for stepper component
 */
export type StepperStepProps = {
  status: StepStatus;
  title: string;
  iconName: IconName;
};

/**
 * state machine types
 */
export type OnboardingMachinePermissions = {
  shouldSeeSelectLanguageStep: boolean;
  shouldSeeWelcomeMFAFirstAdminStep: boolean;
  shouldSeeRegisterPhoneNumber: boolean;
  shouldSeeMfaSetup: boolean;
  shouldSeeUserKyc: boolean;
  shouldSeeCompanyPolicyAgreement: boolean;
  shouldSeeCardShipping: boolean;
  shouldSeeBudgetsAndPoliciesIntro: boolean;
  shouldSeeBudgetOwnerOnboarding: boolean;
  shouldSeeReviewTeamExpenses: boolean;
  shouldSeeEducationStep: boolean;
  shouldSeeCardTutorial: boolean;
  shouldSeeVirtualCardTutorial: boolean;
  shouldSeeAutoAssignTutorial: boolean;
  shouldSeeConnectBankAccount: boolean;
  shouldSeeMobileUpsell: boolean;
  shouldSeeProductSelection: boolean;
};
export type OnboardingMachinePermissionsHasCompleted = {
  hasCompletedRegisterPhoneNumber: boolean;
  hasCompletedMfaForOnboarding: boolean;
  hasCompletedCompanyPolicyAgreement: boolean;
  hasCompletedCardShipping: boolean;
  hasCompletedConnectBankAccount: boolean;
  hasCompletedUserKyc: boolean;
};
export type BudgetData = OnboardingBudgetsQuery_budgets_edges_node &
  Pick<BudgetOverviewCardData, "currentBudgetPeriodSpentByUser">;
export type OnboardingStepData = {
  accountDbaName: string;
  budgets: BudgetData[];
  card: OnboardingCardDetailsQuery_card | undefined;
  cardId: string;
  companyPolicyAgreementFileId: string | undefined;
  employeeId: string | undefined;
  hasOnboarded: boolean;
  isAccountActive: boolean;
  isInitialApplicant: boolean;
  isAdmin: boolean;
  isCompanyPolicyAgreementEnabled: boolean;
  isManager: boolean;
  isReimbursementsOnly: boolean;
  isSetupAdmin: boolean;
  isSsoEnabled: boolean;
  ownedBudgets: OnboardingBudgetsQuery_ownedBudgets_edges_node[];
  totalBudgets: number;
  userId: string;
};
export type OnboardingMachineContext = {
  completed: string[];
  lastCompletedStep: string;
  permissions: OnboardingMachinePermissions;
  permissionsHasCompleted: OnboardingMachinePermissionsHasCompleted;
  steps: OnboardingExperienceV2Step[];
  data: OnboardingStepData;
};

export type OnboardingMachineEvents =
  | {
      type: "CONTINUE";
      permissions: OnboardingMachinePermissions;
    }
  | {
      type: "BACK";
    }
  | {
      type: "FETCH_INIT_DATA_SUCCESS";
      permissions: OnboardingMachinePermissions;
      cardId: string | undefined;
      budgets: OnboardingBudgetsQuery_budgets_edges_node[];
      hasOnboarded: boolean;
      isReimbursementsOnly: boolean;
      totalBudgets: number;
      data: OnboardingStepData;
    };

export type OnboardingMachineSchema = {
  states: {
    fetchInitData: {};
    selectLanguage: {};
    welcome: {};
    determineStep: {};
    determineStepBack: {};
    registerPhoneNumber: {};
    mfa: {};
    userKyc: {};
    policyAgreement: {};
    cardShipping: {};
    education: {
      states: {
        intro: {};
        cardTutorial: {};
        autoAssignTutorial: {};
      };
    };
    connectBankAccount: {};
    mobileUpsell: {};
    productSelection: {};
    welcomeFirstAdmin: {};
    complete: {};
  };
};
