import React from "react";
import type {
  ControllerContextValue,
  UseControllerContextReturn,
} from "@/domains/App/components/Controller/sharedTypes";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ViewNameContext } from "@/features/Prefetch/contexts/ViewNameContext";
import noOp from "@/helpers/noOp";

export const defaultControllerPerformanceContext = {
  controllerDepth: 0,
  depthAfterPageContext: undefined,
  ancestorControllerNames: [],
  ancestorControllersSessionMap: {},
  addBusyElement: noOp,
  removeBusyElement: noOp,
  getIsUsingSuspense: () => false,
  setSuspenseUsageToTrueForController: noOp,
};

export const ControllerPerformanceContext =
  React.createContext<ControllerContextValue>(
    defaultControllerPerformanceContext,
  );

export const useSetSuspenseStatusOnCurrentController = () => {
  const { setSuspenseUsageToTrueForController } = React.useContext(
    ControllerPerformanceContext,
  );
  return React.useMemo(
    () => ({ setSuspenseUsageToTrueForController }),
    [setSuspenseUsageToTrueForController],
  );
};
export const useControllerPerformanceContext =
  (): UseControllerContextReturn => {
    const {
      ancestorControllerNames,
      ancestorControllersSessionMap,
      controllerName,
      controllerSessionId,
      addBusyElement,
      removeBusyElement,
      depthAfterPageContext,
      getIsUsingSuspense,
      setSuspenseUsageToTrueForController,
    } = React.useContext(ControllerPerformanceContext);
    const viewNameContext = React.useContext(ViewNameContext);

    return React.useMemo(
      () => ({
        ancestorControllerNames,
        ancestorControllersSessionMap,
        controllerName,
        controllerSessionId,
        addBusyElement,
        depthAfterPageContext,
        removeBusyElement,
        getIsUsingSuspense,
        setSuspenseUsageToTrueForController,
        viewName: viewNameContext?.name,
      }),
      // these should never change since they are REFs, we can safely add them here and not trigger re-renders
      [
        controllerName,
        ancestorControllerNames,
        ancestorControllersSessionMap,
        controllerSessionId,
        depthAfterPageContext,
        addBusyElement,
        removeBusyElement,
        getIsUsingSuspense,
        setSuspenseUsageToTrueForController,
        viewNameContext?.name,
      ],
    );
  };
