import { addDays, isAfter } from "date-fns";

export const getIsReimbursementDelayed = ({
  completionExpectedAt,
}: {
  completionExpectedAt: string | null | undefined;
}) => {
  if (!completionExpectedAt) {
    return;
  }
  const currentDate = new Date();
  // add 2 extra days after ETA to reduce any unnecessary outreach
  // about show the "delayed" badge
  const completionExpectedFormatted = addDays(
    new Date(completionExpectedAt),
    2,
  );
  return isAfter(currentDate, completionExpectedFormatted);
};
