import { useResourceOperationsQueryWithAtom } from "@/domains/App/features/Permissions/hooks/useResourceOperationsQueryWithAtom";

// Dummy component used to trigger resourceOperations query.
// We want to conditionally trigger this query for now until
// we're confident in the new resolver.
export const LoadResourceOperations = () => {
  useResourceOperationsQueryWithAtom();

  return null;
};
