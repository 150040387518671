import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import * as React from "react";

const cache = createCache({
  key: "dashboard",
  stylisPlugins: [
    (element) => {
      if (element.type === "rule" && Array.isArray(element.props)) {
        element.props = element.props.map((prop) => {
          return prop.startsWith(".") ? `body ${prop}` : prop;
        });
      }
    },
  ],
});

/**
 * This provider leverages the emotion cache to improve
 * emotion style specificity. It serves as a guard against third
 * party styles overriding our styles.
 */
const EmotionCacheProvider: React.FC = React.memo(({ children }) => {
  return <CacheProvider value={cache}>{children}</CacheProvider>;
});

export default EmotionCacheProvider;
