import gql from "graphql-tag";

export const fspConfigQuery = gql`
  query FspConfig($accountId: String!) {
    isFsp(accountId: $accountId)
    getFSPDetails {
      fspCustomerAccountId
      name
      description
      logoLink
      detailsLink
    }
  }
`;
