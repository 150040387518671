import type { JwtPayload } from "jwt-decode";
import jwtDecode from "jwt-decode";
import memoize from "memoize-one";

export const getTokenExpirationTime = memoize((token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.exp ? decoded.exp * 1000 : null;
});

export const getAuthOccurredAt = memoize((token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.iat ? decoded.iat * 1000 : null;
});

export const getTokenIssuer = memoize((token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.iss ? decoded.iss : null;
});

export const getTokenSub = memoize((token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.sub ? decoded.sub : null;
});

export const getTokenAcr = memoize((token: string) => {
  const decoded = jwtDecode<JwtPayload & { acr?: string }>(token);
  return decoded?.acr ? decoded.acr : null;
});

export const getFactorVerifiedAt = memoize((token: string) => {
  const decoded = jwtDecode<JwtPayload & { fa_time?: number }>(token);
  return decoded?.fa_time ? decoded.fa_time * 1000 : null;
});
