import { OnboardingExperienceStep } from "@/routes/Onboarding/OnboardingExperience/sharedTypes";

const sharedBudgetPolicyOnboardingSteps = [
  OnboardingExperienceStep.SPEND_HANDBOOK_INTRO,
  OnboardingExperienceStep.BUDGET_INTRODUCTION,
  OnboardingExperienceStep.POLICY_INTRODUCTION,
];

const reimbursementOnlySteps = [
  OnboardingExperienceStep.NEW_REIMBURSEMENT_REQUEST_DEMO,
  OnboardingExperienceStep.CONNECT_BANK_FOR_REIMBURSEMENT,
];

const managerOnboardingSteps = [OnboardingExperienceStep.MANAGE_TEAM_EXPENSES];

const budgetOwnerOnboardingSteps = [
  OnboardingExperienceStep.MANAGE_OWNED_BUDGETS,
];

const cardOnboardingSteps = [
  OnboardingExperienceStep.GET_STARTED_CARD,
  OnboardingExperienceStep.USE_CARD_WITH_BUDGET,
  OnboardingExperienceStep.REVIEW_BUDGET_POLICY,
  OnboardingExperienceStep.VIDEO_DEMO,
];

type OnboardingStepOptions = {
  shouldSeeWelcomeMfa?: boolean;
  shouldSeeBudgetsAndPoliciesIntro?: boolean;
  shouldSeeVirtualCardTutorial?: boolean;
  shouldSeeCardOnboarding?: boolean;
  shouldSeeReimbursementOnboarding?: boolean;
  shouldSeeMfa?: boolean;
  shouldSeeUserKyc?: boolean;
  shouldSeeOrderCard?: boolean;
  shouldSeeBudgetOwnerOnboarding?: boolean;
  shouldSeeManagerOnboarding?: boolean;
  shouldSeeCompanyPolicyAgreement?: boolean;
  shouldSeeMobileDownload?: boolean;
};

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export const getOnboardingSteps = ({
  shouldSeeWelcomeMfa,
  shouldSeeBudgetsAndPoliciesIntro,
  shouldSeeCardOnboarding,
  shouldSeeReimbursementOnboarding,
  shouldSeeMfa,
  shouldSeeUserKyc,
  shouldSeeOrderCard,
  shouldSeeBudgetOwnerOnboarding,
  shouldSeeManagerOnboarding,
  shouldSeeCompanyPolicyAgreement,
  shouldSeeMobileDownload,
}: RequireAtLeastOne<OnboardingStepOptions>) => {
  const requiredSteps: OnboardingExperienceStep[] = [];
  const optionalSteps: OnboardingExperienceStep[] = [];

  if (shouldSeeWelcomeMfa) {
    requiredSteps.push(OnboardingExperienceStep.WELCOME_MFA);
  }

  if (shouldSeeMfa) {
    requiredSteps.push(OnboardingExperienceStep.MFA);
  }

  if (shouldSeeUserKyc) {
    requiredSteps.push(OnboardingExperienceStep.USER_KYC);
  }

  if (shouldSeeCompanyPolicyAgreement) {
    requiredSteps.push(OnboardingExperienceStep.COMPANY_POLICY_AGREEMENT);
  }

  if (shouldSeeOrderCard) {
    requiredSteps.push(OnboardingExperienceStep.ORDER_CARD);
  }

  if (shouldSeeBudgetsAndPoliciesIntro) {
    requiredSteps.push(...sharedBudgetPolicyOnboardingSteps);
  }

  if (shouldSeeCardOnboarding) {
    requiredSteps.push(...cardOnboardingSteps);
  }

  if (shouldSeeReimbursementOnboarding) {
    requiredSteps.push(...reimbursementOnlySteps);
  }

  if (shouldSeeManagerOnboarding) {
    requiredSteps.push(...managerOnboardingSteps);
  }

  if (shouldSeeBudgetOwnerOnboarding) {
    requiredSteps.push(...budgetOwnerOnboardingSteps);
  }

  if (shouldSeeMobileDownload) {
    optionalSteps.push(OnboardingExperienceStep.MOBILE_DOWNLOAD);
  }

  const canBeCompleteAtStepIndex = requiredSteps.length - 1;
  const steps = [...requiredSteps, ...optionalSteps];

  return { steps, canBeCompleteAtStepIndex };
};

export const BUDGET_CARD_WIDTH = 330;
export const BUDGET_CARD_MARGIN = 16;

/**
 *
 * @param str must be a string with value of "true" or "false"
 *            anything not "true" or "false" will return null
 * @returns the boolean equivalent: "true" -> true ; "false" -> false
 */

export const parseBooleanFromString = (input: boolean | string) => {
  if (typeof input === "boolean") {
    return input;
  }
  if (String(input).toLowerCase() === "true") {
    return true;
  }
  if (String(input).toLowerCase() === "false") {
    return false;
  }
  return null;
};

export const getRandomLast4 = () => Math.floor(1000 + Math.random() * 9000);
