import { useEffect, useState } from "react";
import { useTrackError } from "@/helpers/errorTracking";

type ScriptProps = Pick<HTMLScriptElement, "src" | "type" | "async"> & {
  checkForExisting?: boolean;
};

export type ErrorState = ErrorEvent | null;

const isBrowser =
  typeof window !== "undefined" && typeof window.document !== "undefined";

export default function useScript({
  checkForExisting = false,
  src,
  type,
  async,
}: ScriptProps): [boolean, ErrorState] {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorState>(null);
  const { trackError } = useTrackError();

  useEffect(() => {
    if (!isBrowser) {
      return undefined;
    }

    if (checkForExisting) {
      const existing = document.querySelectorAll(`script[src="${src}"]`);
      if (existing.length > 0) {
        setLoading(false);
        return undefined;
      }
    }

    const scriptEl = document.createElement("script");
    scriptEl.setAttribute("src", src);

    if (type) {
      scriptEl.setAttribute("type", type);
    }

    if (async) {
      scriptEl.async = true;
    }

    const handleLoad = () => {
      setLoading(false);
    };

    const handleError = (err: ErrorEvent) => {
      trackError(
        "Error loading script",
        new Error(`Failed to load script: ${src}`),
        {
          src,
          type,
          async,
        },
      );
      setError(err);
    };

    scriptEl.addEventListener("load", handleLoad);
    scriptEl.addEventListener("error", handleError);

    document.body.appendChild(scriptEl);

    return () => {
      scriptEl.removeEventListener("load", handleLoad);
      scriptEl.removeEventListener("error", handleError);
    };
    // we need to ignore the attributes as they're a new object per call, so we'd never skip an effect call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return [loading, error];
}
