import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { createMockableHook } from "@/helpers/createMockableHook";
import { useSandbox } from "@/modules/Sandbox/hooks/useSandbox";

const _useBankingDemo = () => {
  const { isDemoAccount } = useSandbox();
  const { bankingDemo } = useFeatureFlags();
  return {
    enabled: isDemoAccount && bankingDemo,
  };
};

export const [useBankingDemo, MockBankingDemoProvider] =
  createMockableHook(_useBankingDemo);
