import * as React from "react";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { getCleanToken } from "@/features/Authentication/helpers/tokenStorage";
import { useServerDateFactory } from "@/features/ServerTime/contexts/ServerTime";
import { getTiming } from "@/features/SessionTimeoutModal/helpers/getTiming";
import { useLogger } from "@/helpers/logger";
import { useLogout } from "@/hooks/useLogout";

export const locationBeforeSessionTimeoutStorageKey =
  "__BREX__locationBeforeSessionTimeout";

export const useSessionExpiresEffect = (
  updateCountdownValue: (value: number | undefined) => void,
) => {
  const logger = useLogger();
  const sessionExpiredTimeoutRef = React.useRef<number>();
  const location = useLocation();
  const locationRef = React.useRef(location);
  const logout = useLogout();
  const { getAccessToken } = useAuthenticationContext();
  const getCurrentDate = useServerDateFactory();
  const token = getAccessToken();

  // We need the latest location to know what to restore after login
  // but we don't want to reset the interval, so storing in a ref.
  React.useEffect(() => {
    locationRef.current = location;
  }, [location]);

  // This callback saves the current location state in local storage then calls logout
  const sessionExpiredCallback = useCallback(() => {
    const timing = getTiming(getAccessToken, getCurrentDate);
    if (!timing) {
      return;
    }
    const {
      now,
      accessToken,
      sessionExpiresAt,
      sessionExpiresAtWithThreshold,
    } = timing;
    // not expired yet
    if (now < sessionExpiresAtWithThreshold) {
      return;
    }
    updateCountdownValue(undefined);
    // Save location before timeout so we can restore it after logging back in.
    // Using sessionStorage so each tab can restore to its own latest location.
    window.sessionStorage.setItem(
      locationBeforeSessionTimeoutStorageKey,
      JSON.stringify(locationRef.current),
    );

    logger.info("session timeout logout user", {
      hasTokenExpired: now > sessionExpiresAt,
      sessionExpiresAt,
      now,
      browserNow: Date.now(),
      accessToken: getCleanToken(accessToken),
    });
    logout();
  }, [getAccessToken, getCurrentDate, logger, logout, updateCountdownValue]);

  useEffect(() => {
    const timing = getTiming(getAccessToken, getCurrentDate);
    if (!timing) {
      return;
    }
    const { now, sessionExpiresAtWithThreshold } = timing;

    // clear old timeout before setting another
    if (sessionExpiredTimeoutRef.current) {
      clearTimeout(sessionExpiredTimeoutRef.current);
    }
    sessionExpiredTimeoutRef.current = window.setTimeout(
      sessionExpiredCallback,
      Math.max(0, sessionExpiresAtWithThreshold - now),
    );

    return () => {
      clearTimeout(sessionExpiredTimeoutRef.current);
    };
  }, [
    getAccessToken,
    getCurrentDate,
    sessionExpiredCallback,
    // ensure the side effect happens when there is a new token from context
    token,
  ]);
};
