import { IFrameHandler } from "./iframeHandler";
import { trackPerformance } from "@/features/Analytics/helpers/performance";
import { trackEvent } from "@/features/Analytics/helpers/trackEvent";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import { getOAuthConfig } from "@/features/Authentication/helpers/config";
import { parseOAuthResponseUrl } from "@/features/Authentication/helpers/oauth";
import { saveState } from "@/features/Authentication/helpers/state";
import type {
  AuthOptions,
  OAuthResponse,
  StartAuthResult,
} from "@/features/Authentication/sharedTypes";

interface OAuthResponsePayload {
  type: string;
  response: string;
}

/**
 * Start the auth process by fetching the OAuth configuration and
 * contacting our OAuth server to check for auth status.
 *
 * During this process, we render a hidden <iframe> that renders a page from
 * our OAuth service, which then communicates via the `postMessage` API to give
 * us auth status.
 */
export const startAuth = async (
  options?: AuthOptions,
): Promise<StartAuthResult> => {
  trackEvent(AllAnalyticsEvents.StartAuth, {
    ...options,
    extras: null,
  });

  // get the OAuth config from the configuration service
  const oAuthConfig = await getOAuthConfig(options);

  trackEvent(AllAnalyticsEvents.AuthConfig, {
    stateId: oAuthConfig.id,
  });

  // save important aspects of the OAuth config to localStorage as well as
  // other state (i.e. location for post login redirect) to be used when the
  // user returns to the Dashboard after logging in
  try {
    // this may fail if localStorage is full, but it's not a blocker
    // for continuing the process
    saveState({
      id: oAuthConfig.id,
      pkceVerifier: oAuthConfig.pkceVerifier,
      redirectUri: oAuthConfig.redirectUri,
      state: options?.state,
    });
  } catch {
    trackEvent(AllAnalyticsEvents.AuthError, {
      id: oAuthConfig.id,
      message: "Failed to save OAuth state to localStorage",
      origin: "startAuth",
    });
  }

  // if this is an interactive auth, force a redirect to our identity
  // service so the user can enter their credentials
  if (options?.interactive) {
    return {
      result: "redirect",
      id: oAuthConfig.id,
      redirectUri: oAuthConfig.authorizationUrl,
      state: options?.state,
    };
  }

  // mount a hidden <iframe> for communicating with our OAuth service
  trackPerformance("auth.iframe.init");
  const response = await new Promise<OAuthResponse>((resolve) => {
    const handler = new IFrameHandler<OAuthResponsePayload>({
      url: oAuthConfig?.authorizationUrl as string,
      callback: (data) => {
        // there's a lot of noise on the window `message` event channel, and
        // we're only interested in the `auth_response` event
        if (data.type !== "authorization_response") {
          return false;
        }
        resolve(parseOAuthResponseUrl(data.response));
        return true;
      },
      // in the case OAuth service is down, handle a timeout
      timeoutCallback: () => {
        resolve({
          type: "error",
          error: "timeout",
          state: oAuthConfig.id,
        });
      },
      // handle the unexpected
      errorHandler: () => {
        resolve({
          type: "error",
          error: "internal_error",
          state: oAuthConfig.id,
        });
      },
    });
    handler.init();
  });
  trackPerformance("auth.iframe.response", "auth.iframe.init");

  return {
    result: "response",
    response,
  };
};
