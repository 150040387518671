import type { ProductConfigQuery } from "@/data/__generated__/queries.generated";
import type { UserPropertiesQuery } from "@/features/Authentication/data/__generated__/queries.generated";

export const selectProductConfigEntitlements = (
  data?: ProductConfigQuery | UserPropertiesQuery,
) => ({
  travelTripsEntitled:
    data?.productConfig.featuresV2.travel?.travelTrips.entitled ?? false,
  payableBillAccessEntitled:
    data?.productConfig.featuresV2.billPay?.payableBillAccess.entitled ?? false,
  basicReimbursementsEntitled:
    data?.productConfig.featuresV2.reimbursements.basicReimbursements
      .entitled ?? true,
});
