import * as React from "react";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";

{
  /*
    ⚠️ This here is an experimental and non-production controller. ⚠️ 

    It's used to implement tools/flows and helpers that can improve
    developer experience while working on the dashboard.

    If you want to load anything into it, load it inside the PanelController, in an async way.
*/
}

const PanelController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PanelController" */
      "@/features/DevPanel/controllers/PanelController"
    ),
);

export const DevPanelController = () => {
  const { devPanelAccess } = useFeatureFlags();

  // Our staging environment supports anonymous signups, and is currently used by
  // partners and bug bounty participants. For security purposes, we don't even want
  // to load the javascript bundle containing the dev panel unless the user has a Brex
  // email. That limitation is managed through this feature flag.
  if (!devPanelAccess) {
    return null;
  }
  return (
    <React.Suspense fallback={null}>
      <PanelController />
    </React.Suspense>
  );
};
