export const AnalyticsEvents = {
  VendorDetailsPaneViewed: "vendor.details-pane.viewed",
  AddVendorFormExitDiscardPromptOpen: "vendor.add-form.exit-discard-prompt",
  AddVendorFormExitDiscardDelete: "vendor.add-form.exit-discard-delete",
  AddVendorFormExit: "vendor.add-form.exit",
  AddVendorFormBackClicked: "vendor.add-form.back-clicked",
  AddVendorFormNextClicked: "vendor.add-form.next-clicked",
} as const;

type VendorIdPayload = {
  vendorId: string | undefined;
};

type CurrentStepPayload = {
  currentStep: string;
};

export type EventSchemaMap = {
  [AnalyticsEvents.VendorDetailsPaneViewed]: VendorIdPayload;
  [AnalyticsEvents.AddVendorFormExitDiscardPromptOpen]: VendorIdPayload;
  [AnalyticsEvents.AddVendorFormExitDiscardDelete]: VendorIdPayload;
  [AnalyticsEvents.AddVendorFormExit]: VendorIdPayload;
  [AnalyticsEvents.AddVendorFormBackClicked]: CurrentStepPayload;
  [AnalyticsEvents.AddVendorFormNextClicked]: CurrentStepPayload;
};
