export type GetDelegatorUserIdValue = () => string | undefined;
export type SetDelegatorUserIdValue = (
  userId: string | undefined,
  reload?: boolean,
) => void;
export type GetDelegateUserIdValue = () => string | undefined;
export type SetDelegateUserIdValue = (userId: string | undefined) => void;
export type GetIsInCopilotModeValue = () => boolean | undefined;

export const COPILOT_QUERY_PARAM = "copilot";
export const COPILOT_STORAGE_KEY = "delegator_customer_user_id";

export enum ToastType {
  addition = "addition",
  removal = "removal",
}
