export enum AnalyticsEvents {
  ReferralsCopyReferrerLinkClicked = "referrals.ReferralsPage.ReferralsHeader.copyReferrerLinkClicked",
  ReferralsReferralsPageHeaderReferEmailClicked = "referrals.ReferralsPage.ReferralsHeader.referClicked",
  ReferralsReferralsPageHeaderReferEmailSuccess = "referrals.ReferralsPage.ReferralsHeader.referSuccess",
  ReferralsReferralsPageHeaderReferEmailFailure = "referrals.ReferralsPage.ReferralsHeader.referFail",
  ReferralsReferralsPageHeaderReferEmailSubmitButtonClicked = "referrals.ReferralsPage.ReferralsHeader.ReferEmailSubmitButtonClicked",
  ReferralsPromotionCompleted = "referrals.promotion.completed",
}

type EmptyPayload = {};

export type EventSchemaMap = {
  [AnalyticsEvents.ReferralsCopyReferrerLinkClicked]: EmptyPayload;
  [AnalyticsEvents.ReferralsReferralsPageHeaderReferEmailClicked]: EmptyPayload;
  [AnalyticsEvents.ReferralsReferralsPageHeaderReferEmailSuccess]: EmptyPayload;
  [AnalyticsEvents.ReferralsReferralsPageHeaderReferEmailFailure]: EmptyPayload;
  [AnalyticsEvents.ReferralsReferralsPageHeaderReferEmailSubmitButtonClicked]: EmptyPayload;
  [AnalyticsEvents.ReferralsPromotionCompleted]: EmptyPayload;
};
