import { datadogRum } from "@datadog/browser-rum";
import memoize from "memoize-one";
import * as React from "react";
import { v4 as uuid } from "uuid";
import {
  ControllerPerformanceContext,
  useControllerPerformanceContext,
} from "./ControllerContext";
import {
  getReactComponentInternals,
  PerformanceInternalReactRefComponent,
} from "./helpers";
import type {
  AccessingReactFiberProps,
  BusyElementType,
  ControllerContextValue,
} from "./sharedTypes";
import { VIEW_CONTROLLER_DISPLAY_NAME } from "./sharedTypes";
import {
  controllerMountPerformanceMark,
  hasPerformanceMark,
} from "@/domains/App/components/Controller/helpers/performanceMarkHelpers";
import { PerformanceControllerErrorBoundary } from "@/domains/App/components/Controller/PerformanceControllerErrorBoundary";
import type { RenderStats } from "@/domains/App/components/Controller/PerformanceProfiler";
import {
  ControllerProfiler,
  aggregateRenderStats,
} from "@/domains/App/components/Controller/PerformanceProfiler";
import { useIsFirstLoad } from "@/domains/App/contexts/FirstLoadStatus";
import { useAnalytics } from "@/features/Analytics/contexts/Analytics";
import { LazyComponentContext } from "@/features/LazyComponents";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PrefetchableViewContext } from "@/features/Prefetch/contexts/PrefetchableViewContext";
import { useDeepMemo } from "@/hooks/useDeepMemo";

const useAncestor = () => {
  const possibleParentContext = useControllerPerformanceContext();
  const parentControllerName = possibleParentContext.controllerName;
  const {
    ancestorControllerNames,
    ancestorControllersSessionMap,
    controllerSessionId,
  } = possibleParentContext;

  const updatedAncestorControllers = React.useMemo(
    () =>
      controllerSessionId && parentControllerName
        ? {
            ...ancestorControllersSessionMap,
            [controllerSessionId]: parentControllerName,
          }
        : ancestorControllersSessionMap,
    [ancestorControllersSessionMap, controllerSessionId, parentControllerName],
  );

  return React.useMemo(
    () => ({
      ancestorSessionId: controllerSessionId,
      ancestorControllerNames: parentControllerName
        ? [...ancestorControllerNames, parentControllerName]
        : [],
      ancestorControllersSessionMap: updatedAncestorControllers,
    }),
    [
      controllerSessionId,
      parentControllerName,
      ancestorControllerNames,
      updatedAncestorControllers,
    ],
  );
};

const useUpdateAncestorBusyState = (id: string) => {
  const { addBusyElement, removeBusyElement } =
    useControllerPerformanceContext();
  const parentControllerNames = React.useMemo(
    () => ({
      addBusyChildToAncestor: () => addBusyElement(id, "controller"),
      removeBusyChildFromAncestor: () => removeBusyElement(id, "controller"),
    }),
    [addBusyElement, id, removeBusyElement],
  );

  return parentControllerNames;
};

const useGetDepthAfterViewController = (controllerName: string) => {
  const { depthAfterPageContext } = useControllerPerformanceContext();
  if (controllerName === VIEW_CONTROLLER_DISPLAY_NAME) {
    return 0;
  } else if (
    depthAfterPageContext !== undefined &&
    Number.isInteger(depthAfterPageContext)
  ) {
    return depthAfterPageContext + 1;
  } else {
    return undefined;
  }
};

function useSyncedRef<T>(value: T): React.MutableRefObject<T> {
  const ref = React.useRef(value);
  const stableValue = useDeepMemo(value);
  React.useEffect(() => {
    ref.current = stableValue;
  }, [stableValue]);
  return ref;
}

const useMetricsNames = (
  controllerSessionId: string,
  controllerName: string,
) => {
  const TIMING_PREPEND = "APP.controller";
  return useSyncedRef({
    performanceMarks: {
      controllerMount: controllerMountPerformanceMark(controllerSessionId),
      controllerUnmount: `performanceMarks.${controllerSessionId}.unmount`,
      controllerReady: `performanceMarks.${controllerSessionId}.ready`,
    },
    performanceLabels: {
      controllerMount: `${controllerName}.${controllerSessionId}.mount`,
      controllerSession: `${controllerName}.${controllerSessionId}.session`,
      controllerUnmount: `${controllerName}.${controllerSessionId}.controllerUnmount`,
      controllerReady: `${controllerName}.${controllerSessionId}.ready`,
    },
    timing: {
      mounted: `${TIMING_PREPEND}.${controllerName}.mounted`,
      session: `${TIMING_PREPEND}.${controllerName}.session`,
      unmounted: `${TIMING_PREPEND}.${controllerName}.unmounted`,
      ready: `${TIMING_PREPEND}.${controllerName}.ready`,
    },
    actions: {
      mounted: `${TIMING_PREPEND}.mounted`,
      session: `${TIMING_PREPEND}.session`,
      unmounted: `${TIMING_PREPEND}.unmounted`,
      ready: `${TIMING_PREPEND}.ready`,
    },
  } as const).current;
};

const PerformanceHOCComponentName = "withControllerPerformanceMetrics";

const ensureCleanName = memoize((name: string) => {
  if (name.startsWith(PerformanceHOCComponentName)) {
    return name.slice(PerformanceHOCComponentName.length + 1, -1);
  }
  return name;
});

const AccessingReactFiberFunction: React.FC<AccessingReactFiberProps> =
  React.memo((props) => {
    const {
      ChildrenElement,
      elementProps,
      controllerName,
      setContextControllerName,
    } = props;

    const rootRef = React.useRef<React.Component | null>(null);
    const [childrenControllerName, setChildrenControllerName] =
      React.useState(controllerName);

    React.useEffect(() => {
      // If we already have a "real" component name, we do nothing
      if (childrenControllerName !== "no-display-name") {
        return;
      }
      // If we don't have a "REF" to extract the name from, we do nothing
      if (!rootRef.current) {
        return;
      }

      // if by any chance, we could not extract the name before, we pass the ref
      // into "getControllerName" so we can use in order to properly extrac the
      // Children component name.
      const reactInternals = getReactComponentInternals(
        rootRef.current as any | null,
      );
      // If we the name is already "wrapped" with the HOC, we do nothing
      if (childrenControllerName.startsWith(PerformanceHOCComponentName)) {
        return;
      }
      const newControllerName =
        reactInternals?.pendingProps?.children?.type?.displayName;
      // If we could not extract a name, we do nothing
      if (!newControllerName) {
        return;
      }
      setChildrenControllerName(ensureCleanName(newControllerName));
      setContextControllerName(ensureCleanName(newControllerName));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootRef.current, childrenControllerName, setContextControllerName]);

    // We do this if, to avoid generating a component that has a name like
    // withControllerPerformanceMetrics(withControllerPerformanceMetrics(withControllerPerformanceMetrics(COMPONENT)))
    ChildrenElement.displayName = `${PerformanceHOCComponentName}(${childrenControllerName})`;

    return (
      <PerformanceInternalReactRefComponent ref={rootRef}>
        <ChildrenElement {...elementProps} />
      </PerformanceInternalReactRefComponent>
    );
  });

// Amount of time we use as "idle time" before we mark the controller as ready
const IdleTimeForControllerReady = 250;

const ControllerProvider = React.memo<{
  element: React.ComponentType<any>;
  elementProps: any;
}>(function ControllerProvider({ element: Element, elementProps }) {
  const controllerSessionId = React.useRef(uuid());
  const busyStore = React.useRef(new Map<string, BusyElementType>());
  const setTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const hasBeenMarked = React.useRef(false);
  const isUsingSuspense = React.useRef(false);
  const renderDurationsRef = React.useRef<RenderStats[]>([]);
  const [controllerName, setControllerName] = React.useState<string>(
    ensureCleanName(Element?.displayName || Element?.name || "no-display-name"),
  );
  const hasControllerName = controllerName !== "no-display-name";
  const { performanceMarks, performanceLabels, timing, actions } =
    useMetricsNames(controllerSessionId.current, controllerName);
  const isFirstLoad = useIsFirstLoad();

  const { trackEvent } = useAnalytics();

  // useAncestor allows us to access metadata related for the "ControllerProvider Ancestor"
  // Pretty much we look if the current `ControllerProvider` is nested inside
  // another `ControllerProvider`.
  //
  // If it does, we attach some basic metadata to the current
  // keep track of the same-type-ancestors this 'ControllerProvider' has.
  //
  // With this hook, we grab ancestor functions that are used to add or remove children in a busy state.
  const {
    ancestorControllerNames,
    ancestorControllersSessionMap,
    ancestorSessionId,
  } = useAncestor();

  // With this hook, we grab ancestor functions that are used to add or remove children in a busy state.
  const { addBusyChildToAncestor, removeBusyChildFromAncestor } =
    useUpdateAncestorBusyState(controllerSessionId.current);

  // This keeps track of "how nested" this context is.
  // If it's the first controller being rendered, it'll be 0
  const controllerDepth = ancestorControllerNames.length;

  // This keeps track of "how nested" this context is afther the `__VIEW__`
  // (Referenced via the VIEW_CONTROLLER_DISPLAY_NAME variable) If it's the
  // __VIEW__ controller, this will be 0.
  const depthAfterPageContext = useGetDepthAfterViewController(controllerName);

  const lazyComponentContext = React.useContext(LazyComponentContext);
  const prefetchableContext = React.useContext(PrefetchableViewContext);
  const prefetchedQueries = !!prefetchableContext;

  // This keeps track of the shared information that our ControllerProvider calls should all share
  const commonMetadata = React.useMemo(
    () => ({
      ancestorControllerNames,
      depthAfterPageContext,
      controllerName,
      controllerDepth,
      ancestorSessionId,
      controllerSessionId: controllerSessionId.current,
      prefetchedQueries,
      ...lazyComponentContext,
    }),
    [
      ancestorControllerNames,
      ancestorSessionId,
      controllerDepth,
      controllerName,
      depthAfterPageContext,
      lazyComponentContext,
      prefetchedQueries,
    ],
  );

  // Performance Tracking UseEffect
  React.useEffect(() => {
    if (hasControllerName) {
      window.performance.mark(performanceMarks.controllerMount);
      if (hasPerformanceMark(performanceMarks.controllerMount)) {
        const mountMeasure = window.performance.measure(
          performanceLabels.controllerMount,
          performanceMarks.controllerMount,
        );
        if (mountMeasure) {
          const aggregatedRenderStats = aggregateRenderStats(
            renderDurationsRef.current,
            "mount",
          );

          datadogRum.addTiming(timing.mounted, mountMeasure.duration);

          trackEvent(
            actions.mounted,
            {
              ...commonMetadata,
              ...aggregatedRenderStats,
              measure: mountMeasure.duration, // TODO: remove this
              duration: mountMeasure.duration,
            },
            undefined,
            ["datadogRum"],
          );
        }
      }
    }
    return () => {
      if (hasControllerName) {
        // We use this to calculate the "session time" of a controller
        window.performance.mark(performanceMarks.controllerUnmount);
        if (hasPerformanceMark(performanceMarks.controllerMount)) {
          const sessionMeasure = window.performance.measure(
            performanceLabels.controllerSession,
            performanceMarks.controllerMount,
          );
          if (sessionMeasure) {
            datadogRum.addTiming(timing.session, sessionMeasure.duration);
            datadogRum.addTiming(timing.unmounted);

            trackEvent(
              actions.unmounted,
              {
                ...commonMetadata,
                measure: sessionMeasure.duration, // TODO: remove this
                duration: sessionMeasure.duration,
              },
              undefined,
              ["datadogRum"],
            );
          }
          window.performance.clearMarks(performanceMarks.controllerMount);
          window.performance.clearMeasures(performanceLabels.controllerSession);
          window.performance.clearMeasures(performanceLabels.controllerMount);
        }
      }
    };
    // As we are using "AccessingReactFiber" to ensure extraction of a children's component name to
    // fill in "controllerName" state. We want to only re-generate when the controllerName has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasControllerName]);

  const markControllerAsReady = React.useCallback(() => {
    if (hasBeenMarked.current) {
      return;
    }
    removeBusyChildFromAncestor();

    // We are using the "hasBeenMarked" hook to keep a similarity with 'useEffect'.
    //
    // We want for "markControllerAsReady" to be executed only once on the
    // current "ControllerContext", plus, we want to avoid re-rendering the
    // context when this happens.
    if (hasPerformanceMark(performanceMarks.controllerMount)) {
      hasBeenMarked.current = true;
      window.performance.mark(performanceMarks.controllerReady);
      const measure = window.performance.measure(
        performanceLabels.controllerReady,
        performanceMarks.controllerMount,
        performanceMarks.controllerReady,
      );
      if (measure) {
        const aggregatedRenderStats = aggregateRenderStats(
          renderDurationsRef.current,
          "update",
        );

        datadogRum.addTiming(timing.ready, measure.duration);

        trackEvent(
          actions.ready,
          {
            ...commonMetadata,
            ...aggregatedRenderStats,
            measure: measure.duration,
            duration: measure.duration,
            firstLoad: isFirstLoad,
          },
          undefined,
          ["datadogRum"],
        );

        // Remove the controller mount mark, since we don't need it anymore
        window.performance.clearMarks(performanceMarks.controllerMount);
      }
      // CLEANUP
      // We are removing the marks since these should never be used again.
      window.performance.clearMarks(performanceMarks.controllerReady);
      window.performance.clearMeasures(performanceLabels.controllerReady);
    }
  }, [
    removeBusyChildFromAncestor,
    performanceMarks.controllerMount,
    performanceMarks.controllerReady,
    performanceLabels.controllerReady,
    timing.ready,
    trackEvent,
    actions.ready,
    commonMetadata,
    isFirstLoad,
  ]);

  const clearReadyTimeout = React.useCallback(() => {
    if (setTimeoutRef.current) {
      clearTimeout(setTimeoutRef.current);
    }
  }, []);

  const startReadyTimeout = React.useCallback(
    (elementType?: BusyElementType) => {
      if (busyStore.current.size === 0) {
        // immedately mark as ready if the last busy element was a controller.
        // there will be no chained busy elements in the controller case.
        // this reduces the time to ready when there's deeply nested controllers
        if (elementType === "controller") {
          markControllerAsReady();
        } else {
          setTimeoutRef.current = setTimeout(
            markControllerAsReady,
            IdleTimeForControllerReady,
          );
        }
      }
    },
    [markControllerAsReady],
  );

  const addBusyElementToStore = React.useCallback(
    (elementId: string, type: BusyElementType) => {
      busyStore.current.set(elementId, type);
      clearReadyTimeout();
    },
    [clearReadyTimeout],
  );

  const removeBusyElementFromStore = React.useCallback(
    (elementId: string, type: BusyElementType) => {
      busyStore.current.delete(elementId);
      startReadyTimeout(type);
    },
    [startReadyTimeout],
  );

  const handleRenderStats = React.useCallback((renderStats: RenderStats) => {
    // store controller render stats for aggregation in mount/ready
    renderDurationsRef.current.push(renderStats);
  }, []);

  React.useEffect(() => {
    // __shouldExcludeFromAncestorMetrics prop is to completely exclude a controller
    // from its ancestor metrics. Context: https://github.com/brexhq/credit_card/pull/139968
    if (elementProps.__shouldExcludeFromAncestorMetrics) {
      return;
    }

    // Once the component loads, we will mark it as ready after 250ms
    // happens (Or whatever the time for IdleTimeForControllerReady is).
    //
    // However, if there are network requests or a children is busy in between
    // this component mounting and the timeout executing, we will delete this
    // specific timeout and generate a new one, so we wont create a new.
    startReadyTimeout();
    addBusyChildToAncestor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: ControllerContextValue = React.useMemo(
    () => ({
      markControllerAsReady,
      addBusyElement: addBusyElementToStore,
      removeBusyElement: removeBusyElementFromStore,
      depthAfterPageContext,
      controllerDepth,
      ancestorControllerNames,
      ancestorControllersSessionMap,
      controllerName,
      controllerSessionId: controllerSessionId.current,
      isUsingSuspense: isUsingSuspense.current,
      getIsUsingSuspense: () => isUsingSuspense.current,
      setSuspenseUsageToTrueForController: () => {
        isUsingSuspense.current = true;
      },
    }),
    // As we are using "AccessingReactFiber" to ensure extraction of a children's component name to
    // fill in "controllerName" state. We want to only re-generate when the controllerName has changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [controllerName, ancestorControllerNames],
  );

  return (
    <ControllerPerformanceContext.Provider value={value}>
      <PerformanceControllerErrorBoundary>
        <ControllerProfiler
          name={controllerName}
          onRenderStats={handleRenderStats}
        >
          <AccessingReactFiberFunction
            setContextControllerName={setControllerName}
            controllerName={controllerName}
            elementProps={elementProps}
            ChildrenElement={Element}
          />
        </ControllerProfiler>
      </PerformanceControllerErrorBoundary>
    </ControllerPerformanceContext.Provider>
  );
});

export function withControllerPerformanceMetrics<ComponentProps = {}>(
  WrappedComponent: React.ComponentType<ComponentProps>,
) {
  const WithControllerPerformanceMetrics: React.FC<ComponentProps> = (
    props,
  ) => {
    // Skip performance tracking in test environment
    if (process.env.NODE_ENV === "test") {
      return (
        <WrappedComponent
          {...(props as JSX.IntrinsicAttributes &
            React.PropsWithChildren<ComponentProps>)}
        />
      );
    }
    return (
      <ControllerProvider element={WrappedComponent} elementProps={props} />
    );
  };
  return WithControllerPerformanceMetrics;
}

const criticalDataLoaded = "APP.controller.criticalData.loaded";

export function useCriticalData<Data>(data?: Data) {
  const isFirstLoad = useIsFirstLoad();
  const perfContext = useControllerPerformanceContext();
  const lazyContext = React.useContext(LazyComponentContext);
  const { trackEvent } = useAnalytics();

  const controllerSessionId = React.useMemo(uuid, []);
  const startedMark = `APP.controller.${controllerSessionId}.criticalData.started`;

  const hasData = !!data;

  React.useEffect(() => {
    if (!hasData) {
      window.performance.mark(startedMark);
    } else if (hasPerformanceMark(startedMark)) {
      const measure = window.performance.measure(
        criticalDataLoaded,
        startedMark,
      );

      trackEvent(criticalDataLoaded, {
        ...perfContext,
        ...lazyContext,
        measure: measure.duration,
        duration: measure.duration,
        firstLoad: isFirstLoad,
      });
    }
  }, [hasData, isFirstLoad, lazyContext, perfContext, startedMark, trackEvent]);
}
