import { useColorMode } from "@brexhq/metal-theming";
import { useAtomValue } from "jotai";
import * as React from "react";
import type { CustomBrandingQuery } from "@/features/CustomThemeBranding/data/__generated__/queries.generated";
import { customBrandingQuery } from "@/features/CustomThemeBranding/data/queries";
import { defaultBranding } from "@/features/CustomThemeBranding/helpers/defaultBranding";
import { generateCustomThemes } from "@/features/CustomThemeBranding/helpers/generateCustomThemes";
import type {
  CustomBrandingData,
  MaybeCustomBranding,
} from "@/features/CustomThemeBranding/helpers/types";
import {
  atomsWithQuery,
  selectQueryAtom,
  useAwaitQueryWithAtoms,
} from "@/features/Jotai";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";

export const customBrandingDataAtom = atomsWithQuery<CustomBrandingQuery>({
  suspensePolicy: "initial",
});

export const customBrandingAtom = selectQueryAtom(
  customBrandingDataAtom,
  ({ data }) => data?.user?.customBranding,
);

export const useFetchCustomBrandingData = () => {
  return useAwaitQueryWithAtoms(customBrandingQuery, {
    atomsWithQuery: customBrandingDataAtom,
    fetchPolicy: "cache-and-network",
  });
};

/**
 *
 * @param themeColorMode
 * @returns Branding object with theme, logo, card background, avatars
 */
export const useCustomBranding = (): MaybeCustomBranding => {
  const { customBrandingApi } = useFeatureFlags();
  const customBranding = useAtomValue(customBrandingAtom);
  const colorMode = useColorMode();

  const brandedTheme = React.useMemo((): CustomBrandingData | undefined => {
    if (!customBrandingApi || !customBranding) {
      return undefined;
    }

    const navbarUrl =
      colorMode === "dark"
        ? customBranding.darkModeMainLogo?.presignedDownloadUrl ||
          customBranding.mainLogo.presignedDownloadUrl
        : customBranding.mainLogo.presignedDownloadUrl;

    const avatarUrl =
      colorMode === "dark"
        ? customBranding.darkModeSquareAvatarLogo?.presignedDownloadUrl ||
          customBranding.squareAvatarLogo?.presignedDownloadUrl
        : customBranding.squareAvatarLogo?.presignedDownloadUrl;

    return {
      themeOverride: generateCustomThemes(
        customBranding.primaryColor,
        customBranding.darkModePrimaryColor || undefined,
      ),
      avatarUrl,
      NavbarLogo: navbarUrl
        ? {
            type: "url",
            url: navbarUrl,
          }
        : null,
      // TODO: cards are fetched from elsewhere and not part of custom branding
      card: null,
    };
  }, [customBrandingApi, customBranding, colorMode]);

  if (brandedTheme) {
    return {
      ...brandedTheme,
      colorMode,
      hasCustomBranding: true,
    };
  }

  return {
    ...defaultBranding,
    colorMode,
    hasCustomBranding: false,
  };
};
