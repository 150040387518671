import { useSetAtom } from "jotai";
import * as React from "react";
import {
  getDelegatorUserIdAtom,
  setDelegatorUserIdAtom,
  getDelegateUserIdAtom,
  setDelegateUserIdAtom,
  getIsInCopilotModeAtom,
} from "@/features/Copilot/atoms";
import {
  getLocalStorageDelegatorId,
  removeLocalStorageDelegatorId,
  setLocalStorageDelegatorId,
} from "@/features/Copilot/helpers/localStorageHelpers";
import { useLogoutSpotnana } from "@/features/Travel/hooks/useLogoutSpotnana";
import { urls } from "@/helpers/urls";

export const useInitializeCopilotAtom = () => {
  const [initialized, setInitialized] = React.useState(false);
  const logoutSpotnana = useLogoutSpotnana();
  const updateGetDelegatorUserId = useSetAtom(getDelegatorUserIdAtom);
  const updateSetDelegatorUserId = useSetAtom(setDelegatorUserIdAtom);
  const updateGetDelegateUserId = useSetAtom(getDelegateUserIdAtom);
  const updateSetDelegateUserId = useSetAtom(setDelegateUserIdAtom);
  const updateGetIsInCopilotMode = useSetAtom(getIsInCopilotModeAtom);

  const delegatorUserId = React.useRef(getLocalStorageDelegatorId());
  const delegateUserId = React.useRef<string | undefined>(undefined);

  const getDelegatorUserId = React.useRef(() => delegatorUserId.current);

  const setDelegatorUserId = React.useRef(
    async (userId: string | undefined, reload = true) => {
      delegatorUserId.current = userId;
      if (userId) {
        setLocalStorageDelegatorId(userId);
      } else {
        removeLocalStorageDelegatorId();
      }

      // We must logout from the current session with our travel provider
      // Spotnana, if needed, or else it is possible that the next time
      // the Spotnana iframe is opened, they will inadvertently use the previous
      // user's session. We must await the completion of logout before reloading,
      // to prevent the reload from interfering in the signout process.
      await logoutSpotnana();

      if (reload) {
        window.location.href = urls.primitives.main();
      }
    },
  );

  const getDelegateUserId = React.useRef(() => delegateUserId.current);

  const setDelegateUserId = React.useRef((userId: string | undefined) => {
    delegateUserId.current = userId;
  });

  // (lauren.liu - 8/23/22): getIsInCopilotMode - saved as a ref instead of a boolean to prevent re-renders
  const getIsInCopilotMode = React.useRef(() => !!delegatorUserId.current);

  // On window focus (e.g. looking at an existing tab), sync ref -> local storage, to
  // ensure opening in new tab reflects current tab's copilot state
  const onWindowFocus = React.useCallback(() => {
    if (delegatorUserId.current) {
      setLocalStorageDelegatorId(delegatorUserId.current);
    } else {
      removeLocalStorageDelegatorId();
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("focus", onWindowFocus);

    return () => window.removeEventListener("focus", onWindowFocus);
  }, [onWindowFocus]);

  React.useEffect(() => {
    updateGetDelegatorUserId(getDelegatorUserId.current);
    updateSetDelegatorUserId(setDelegatorUserId.current);
    updateGetDelegateUserId(getDelegateUserId.current);
    updateSetDelegateUserId(setDelegateUserId.current);
    updateGetIsInCopilotMode(getIsInCopilotMode.current);
    setInitialized(true);
  }, [
    updateGetDelegatorUserId,
    updateSetDelegatorUserId,
    updateGetDelegateUserId,
    updateSetDelegateUserId,
    updateGetIsInCopilotMode,
  ]);

  return { initialized };
};
