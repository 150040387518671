import type { JsonObject } from "type-fest";

export enum AnalyticsEvents {
  TrackedAction = "comms.tracked_action",
  CompletedAction = "comms.completed_action",

  // Copilot navigation events due to notification redirect
  CopilotQueryParamChangeSuccess = "copilot.query_param_change.success",
}

// Weak is part of the attribution, but is limited to backends with no one cchno
// or where plumbing relevant metadata is difficult
export enum Attribution {
  Strong = "strong",
  Weak = "weak",
}

export enum OriginService {
  Comms = "comms",
  Tasks = "tasks",
}

type EmptyPayload = {};

type ActionPayload = {
  action: string;
  attribution: Attribution;
  cchno?: string;
  origin_id: string;
  origin_service: OriginService;
  metadata?: JsonObject;
};

export type EventSchemaMap = {
  [AnalyticsEvents.TrackedAction]: ActionPayload;
  [AnalyticsEvents.CompletedAction]: ActionPayload;

  // Copilot navigation events due to notification redirect
  [AnalyticsEvents.CopilotQueryParamChangeSuccess]: EmptyPayload;
};
