import { zIndex } from "@brexhq/metal-foundation-shared";
import { Global as EmotionGlobal, css } from "@emotion/react";
import * as React from "react";

/**
 * We need to change the z-index of the grandchildren of the root div to ensure
 * the chat widget is displayed above the side details panels.
 */
// eslint-disable-next-line @emotion/syntax-preference
export const spotnanaTwilioFlexChatStylesheet = css`
  #twilio-webchat-widget-root > div > div > div {
    z-index: ${zIndex.modal};
  }
`;

const SpotnanaTwilioFlexChatStyles = () => (
  <EmotionGlobal styles={spotnanaTwilioFlexChatStylesheet} />
);

export default SpotnanaTwilioFlexChatStyles;
