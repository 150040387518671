import { capitalize } from "@/helpers/strings";

type VendorNameMap = {
  [key: string]: string;
};

const nameMapping: VendorNameMap = {
  quickbooks: "QuickBooks",
  qbd: "QuickBooks Desktop",
  netsuite: "NetSuite",
  csv: "accounting",
  sage_intacct: "Sage Intacct (direct)",
  oracle_fusion: "Oracle Cloud",
  workday: "Workday",
};

export const formatVendorName = (name: string) => {
  if (nameMapping[name]) {
    return nameMapping[name];
  }
  return name
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
};
