// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { LazyQueryHookExecOptions } from "@apollo/client";
import type { DocumentNode } from "graphql";
import * as React from "react";
import { useControllerPerformanceContext } from "@/domains/App/components/Controller/ControllerContext";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useLazyQuery as useApolloLazyQuery } from "@/features/Apollo/helpers/client";
import type {
  LazyQueryHookOptions,
  OperationVariables,
  LazyQueryExecFunction,
} from "@/features/Apollo/helpers/client";
import { createLoadingInfoFromQueryResult } from "@/features/Apollo/helpers/createLoadingInfoFromQueryResult";
import type { ExtendedQueryResult } from "@/features/Apollo/sharedTypes";

type LazyQueryResultTuple<Data, Vars extends OperationVariables> = [
  LazyQueryExecFunction<Data, Vars>,
  ExtendedQueryResult<Data, Vars>,
];

export function useLazyQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>,
): LazyQueryResultTuple<TData, TVariables> {
  const controllerContext = useControllerPerformanceContext();
  const [execute, result] = useApolloLazyQuery<TData, TVariables>(query, {
    ...options,
    context: {
      ...options?.context,
      controllerContext,
    },
  });

  // Ensures that controllerContext doesn't get overwriten if `context` is passed in as an option to `execute` function
  const executeQuery = React.useCallback(
    (
      executeOptions?:
        | Partial<LazyQueryHookExecOptions<TData, TVariables>>
        | undefined,
    ) => {
      const controllerContextOptions = {
        context: { ...executeOptions?.context, controllerContext },
      };
      return execute({ ...executeOptions, ...controllerContextOptions });
    },
    [controllerContext, execute],
  );

  return [
    executeQuery,
    React.useMemo(
      () => ({ ...result, ...createLoadingInfoFromQueryResult(result) }),
      [result],
    ),
  ];
}
