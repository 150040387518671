import * as React from "react";

export type AnyFunction = (...args: any[]) => any;

export type MockableHookResultTransformer<THook extends AnyFunction> = (
  result: ReturnType<THook>,
) => ReturnType<THook>;

export type MockableHookContextValue<THook extends AnyFunction> =
  | ReturnType<THook>
  | MockableHookResultTransformer<THook>;

export type MockableHook<THook extends AnyFunction> = (
  ...args: Parameters<THook>
) => ReturnType<THook>;

export type MockableHookProviderProps<THook extends AnyFunction> = {
  payload: MockableHookContextValue<THook>;
};

export type MockProvider<THook extends AnyFunction> = React.FC<
  MockableHookProviderProps<THook>
>;

export type MockableHookResult<THook extends AnyFunction> = [
  MockableHook<THook>,
  MockProvider<THook>,
];

/**
 * @experimental
 * This utility is meant to make it easier to mock hooks
 * for testing and storybook.
 *
 * @param hook Any react hook that you want to make mockable
 */
export function createMockableHook<THook extends AnyFunction>(
  hook: THook,
): MockableHookResult<THook> {
  const mockableContext = React.createContext<
    MockableHookContextValue<THook> | undefined
  >(undefined);

  const useMockableHook: MockableHook<THook> = (...args) => {
    // NOTE: In order to respect the rule of hooks, the original
    // hook must always be called and must never be place in or after
    // any conditional logic within this hook.
    const hookResult = hook(...args);
    const context = React.useContext(mockableContext);

    if (typeof context === "function") {
      return (context as MockableHookResultTransformer<THook>)(hookResult);
    }
    if (typeof context !== "undefined") {
      return context;
    }
    return hookResult;
  };

  const MockProvider: MockProvider<THook> = ({ children, payload }) => (
    <mockableContext.Provider value={payload}>
      {children}
    </mockableContext.Provider>
  );

  return [useMockableHook, MockProvider];
}
