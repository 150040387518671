import gql from "graphql-tag";

export const refreshTripInformation = gql`
  mutation RefreshTripInformationWithPnr(
    $input: RefreshTripInformationWithPnrInput
  ) {
    refreshTripInformationWithPnr(input: $input) {
      status
    }
  }
`;

export const generateSpotnanaOauthToken = gql`
  mutation GenerateSpotnanaOauthToken {
    generateSpotnanaOauthToken {
      accessToken
      refreshToken
    }
  }
`;
