import * as React from "react";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import type { UserProperties } from "@/features/Analytics/sharedTypes";
import { useUserIdentity } from "@/features/Authentication/hooks/useUserIdentity";

type IdentificationControllerProps = {
  userPropertyOverrides?: UserProperties;
};

/**
 * This is a single purpose controller and is only responsible for fetching
 * user properties and identifying a user with 3rd party services.
 *
 * DO NOT ADD ANY ADDITIONAL COMPONENTS OR LOGIC!!!
 *
 * If you need to run code post-authentication, but before rendering the
 * Dashboard, add it to the AuthenticatedRouter component in the App domain.
 */
const IdentificationController: React.FC<IdentificationControllerProps> = ({
  userPropertyOverrides,
  children,
}) => {
  useUserIdentity(userPropertyOverrides);

  return <>{children}</>;
};

export default withControllerPerformanceMetrics(IdentificationController);
