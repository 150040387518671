import { CurrencyManager } from "@brexhq/currency-ts";
import * as React from "react";

type CurrencyFunctions = {
  [key in keyof CurrencyManager]: CurrencyManager[key];
};

const CurrencyContext = React.createContext<CurrencyFunctions | null>(null);

export const CurrencyProvider: React.FC<{
  children: React.ProviderProps<CurrencyFunctions>["children"];
}> = React.memo(({ children }) => {
  const [currencyManager] = React.useState(new CurrencyManager());
  const currencyFunctions = React.useMemo<CurrencyFunctions>(
    () => ({
      formatCurrency: (...args) => currencyManager.formatCurrency(...args),
      formatExchangeRate: (...args) =>
        currencyManager.formatExchangeRate(...args),
    }),
    [currencyManager],
  );

  return (
    <CurrencyContext.Provider value={currencyFunctions}>
      {children}
    </CurrencyContext.Provider>
  );
});

export const useCurrency = () => {
  const currencyFunctions = React.useContext(CurrencyContext);
  if (currencyFunctions === null) {
    throw new Error(
      "useCurrency must be used within a CurrencyContext Provider",
    );
  }
  return currencyFunctions;
};
