import { DetailsPane } from "@brexhq/component-library";
import * as React from "react";
import { DefaultError } from "./DefaultError";

type DefaultErrorDetailsPaneProps = {
  onClose: VoidFunction;
  onRetry?: VoidFunction;
  errorTitle?: string;
  errorDescription?: string;
};

export const DefaultErrorDetailsPane: React.VFC<
  DefaultErrorDetailsPaneProps
> = ({ onClose, onRetry, errorTitle, errorDescription }) => (
  <DetailsPane onClose={onClose}>
    <DefaultError
      onClickRetry={onRetry}
      title={errorTitle}
      description={errorDescription}
    />
  </DetailsPane>
);
