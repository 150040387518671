import { useCallback, useRef } from "react";
import { useOriginalLocation } from "@/domains/App/contexts/OriginalLocation";
import { beginOpenIdSession } from "@/features/Authentication/helpers/openIdSession";
import { useFinishLogin } from "@/features/Authentication/hooks/useFinishLogin";
import type { AuthOptions } from "@/features/Authentication/sharedTypes";
import { createMockableHook } from "@/helpers/createMockableHook";
import { internalTrackError } from "@/helpers/errorTracking";
import useQueryParams from "@/hooks/useQueryParams";

const useBeginOpenIDAuthorizationInternal = () => {
  const originalLocation = useOriginalLocation();
  const finishLogin = useFinishLogin();
  const { queryParams } = useQueryParams();
  const { prompt } = queryParams;
  const isInitialized = useRef(false);
  return useCallback(
    async (options?: AuthOptions) => {
      if (isInitialized.current) {
        return Promise.resolve("noop" as "noop");
      }
      isInitialized.current = true;
      try {
        const result = await beginOpenIdSession({
          ...options,
          state: {
            originalLocation,
            ...options?.state,
          },
          prompt: options?.prompt || prompt || undefined,
        });
        const finishLoginResult = await finishLogin(result);
        isInitialized.current = false;
        return finishLoginResult;
      } catch (e) {
        internalTrackError({
          error: e as Error,
          context: {
            origin: "useBeginOpenIDAuthorization",
          },
        });
        // we catch auth errors here for logging purposes, but we rethrow
        // so that it can be properly captured downstream
        throw e;
      }
    },
    [finishLogin, originalLocation, prompt],
  );
};

export const [useBeginOpenIDAuthorization, MockeduseBeginOpenIDAuthorization] =
  createMockableHook(useBeginOpenIDAuthorizationInternal);
