import { Loader } from "@brexhq/component-library";
import styled from "@emotion/styled";
import { size } from "polished";
import * as React from "react";

const Container = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...size("100%"),
  paddingTop: 100,
  paddingBottom: 100,
});

export const LoadingBar: React.FC = () => (
  <Container>
    <Loader />
  </Container>
);
