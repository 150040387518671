import type { FluentError } from "@brexhq/i18n";
import { initReactI18next, FluentI18nextFormatter } from "@brexhq/i18n";
import i18n from "i18next";
import { createElement } from "react";
import { createLocalizationBackend } from "@/domains/App/features/Localization/helpers/createLocalizationBackend";
import { internalTrackError } from "@/helpers/errorTracking";
import namespaces from "@/locales/namespaces.json";

const trackedFluentErrors = new Set<string>();

export const initializeLocalization = () => {
  i18n
    .use(FluentI18nextFormatter)
    .use(createLocalizationBackend())
    .use(initReactI18next)
    .init({
      fallbackLng: "en-US",
      load: "currentOnly",
      debug: false,
      ns: namespaces,
      defaultNS: false,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      i18nFormat: {
        debugRenderer: (
          text: string,
          {
            ns,
            key,
          }: {
            ns: string;
            key: string;
          },
        ) => {
          if (!/<\d+>/.test(text)) {
            return createElement(
              "span",
              {
                title: `${ns}:${key}`,
                style: {
                  backgroundColor: "#ff71d63b",
                  outline: "1px solid #ff8bff",
                },
              },
              text,
            );
          }

          return text;
        },
        onFormatError: (error: FluentError) => {
          const errorKey = `${error.lng}:${error.ns}:${error.key}`;

          // Prevent spamming Datadog with the same error on each render
          if (trackedFluentErrors.has(errorKey)) {
            return;
          }
          trackedFluentErrors.add(errorKey);

          internalTrackError({
            error,
            errorName: "APP.i18n.fluent_error",
            context: {
              key: error.key,
              namespace: error.ns,
              language: error.lng,
            },
          });
        },
      },
    });
};
