import * as React from "react";
import type { OnItemClick } from "@/domains/App/features/Navigation/sharedTypes";
import { useInternalLinkProps } from "@/hooks/useInternalLinkProps";

export const useNavigationLinkProps = ({
  onClick,
  to,
}: {
  to?: string;
  onClick?: OnItemClick;
}) => {
  const { onClick: navigate, href } = useInternalLinkProps({
    href: to,
    shouldUpdateWorkflowId: true,
  });

  const handleOnClick = React.useCallback<OnItemClick>(
    (args) => {
      onClick?.(args);
      navigate(args.event as React.MouseEvent<any>);
    },
    [navigate, onClick],
  );
  return {
    onClick: handleOnClick,
    href,
    type: to ? ("link" as const) : ("button" as const),
  };
};
