import type {
  ASTNode,
  ConstValueNode,
  DocumentNode,
  SelectionNode,
  SelectionSetNode,
  ValueNode,
  VariableNode,
} from "graphql";
import { print } from "graphql";
import type {
  ConcreteRequest,
  NormalizationSelection,
  ReaderLinkedField,
  ReaderSelection,
} from "relay-runtime";
import { getStableStorageKey } from "relay-runtime/lib/store/RelayStoreUtils";
import type {
  ReaderArgument,
  ReaderCondition,
  ReaderInlineFragment,
  ReaderListValueArgument,
  ReaderLiteralArgument,
  ReaderLocalArgument,
  ReaderObjectValueArgument,
  ReaderScalarField,
  ReaderVariableArgument,
} from "relay-runtime/lib/util/ReaderNode";
import { assert, exhaustiveCheck, notEmpty } from "@/helpers/typeHelpers";

function getConstValueNodeValue(valueNode: ConstValueNode): unknown {
  if (valueNode.kind === "IntValue") return parseInt(valueNode.value, 10);
  if (valueNode.kind === "FloatValue") return parseFloat(valueNode.value);
  if (valueNode.kind === "StringValue") return valueNode.value;
  if (valueNode.kind === "BooleanValue") return valueNode.value;
  if (valueNode.kind === "NullValue") return null;
  if (valueNode.kind === "EnumValue") return valueNode.value;
  if (valueNode.kind === "ListValue") {
    return valueNode.values.map(getConstValueNodeValue);
  }
  if (valueNode.kind === "ObjectValue") {
    return Object.fromEntries(
      valueNode.fields.map((field) => [
        field.name.value,
        getConstValueNodeValue(field.value),
      ]),
    );
  }

  return exhaustiveCheck(
    valueNode,
    `Unsupported value node: ${JSON.stringify(valueNode, undefined, 2)}`,
  );
}

function isConstValueNode(valueNode: ValueNode): valueNode is ConstValueNode {
  if (valueNode.kind === "Variable") return false;

  if (valueNode.kind === "ListValue") {
    return valueNode.values.every(isConstValueNode);
  }
  if (valueNode.kind === "ObjectValue") {
    return valueNode.fields.every((field) => isConstValueNode(field.value));
  }

  return true;
}

function convertValueNode(valueNode: ValueNode, name: string): ReaderArgument {
  if (isConstValueNode(valueNode)) {
    return {
      kind: "Literal",
      name,
      value: getConstValueNodeValue(valueNode),
    } satisfies ReaderLiteralArgument;
  }

  if (valueNode.kind === "Variable") {
    return {
      kind: "Variable",
      name,
      variableName: valueNode.name.value,
    } satisfies ReaderVariableArgument;
  }

  if (valueNode.kind === "ListValue") {
    return {
      kind: "ListValue",
      name,
      items: valueNode.values.map((listElementValueNode, index) =>
        convertValueNode(listElementValueNode, `name.${index}`),
      ),
    } satisfies ReaderListValueArgument;
  }

  if (valueNode.kind === "ObjectValue") {
    return {
      kind: "ObjectValue",
      name,
      fields: valueNode.fields.map((field) =>
        convertValueNode(field.value, field.name.value),
      ),
    } satisfies ReaderObjectValueArgument;
  }

  return exhaustiveCheck(
    valueNode,
    `Unsupported value node: ${JSON.stringify(valueNode, undefined, 2)}`,
  );
}

function convertSelectionNodeWithConditions(
  selection: SelectionNode,
  query: DocumentNode,
  data: unknown,
): (ReaderSelection & NormalizationSelection) | null {
  const directives = selection.directives ?? [];
  const hasIncludeFalse = directives.some(
    (directive) =>
      (directive.name.value === "include" &&
        directive.arguments?.some(
          (arg) =>
            arg.name.value === "if" &&
            arg.value.kind === "BooleanValue" &&
            arg.value.value === false,
        )) ??
      false,
  );

  const hasSkipTrue = directives.some(
    (directive) =>
      (directive.name.value === "skip" &&
        directive.arguments?.some(
          (arg) =>
            arg.name.value === "if" &&
            arg.value.kind === "BooleanValue" &&
            arg.value.value === true,
        )) ??
      false,
  );

  if (hasIncludeFalse || hasSkipTrue) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define -- unavoidable because of mutual recursion
  let selectionNode = convertSelectionNode(selection, query, data);

  for (const directive of directives) {
    const ifArgument = directive.arguments?.find(
      (arg) => arg.name.value === "if" && arg.value.kind === "Variable",
    );
    if (
      (directive.name.value === "include" || directive.name.value === "skip") &&
      ifArgument
    ) {
      selectionNode = {
        kind: "Condition",
        passingValue: directive.name.value === "include",
        condition: (ifArgument.value as VariableNode).name.value,
        selections: [selectionNode],
      } satisfies ReaderCondition;
    }
  }

  return selectionNode;
}

function getSelectionData(selection: SelectionNode, data: unknown) {
  if (selection.kind === "Field") {
    const key = selection.alias?.value ?? selection.name.value;
    return Array.isArray(data)
      ? data[0]?.[key]
      : (data as Record<string, unknown> | undefined)?.[key];
  }

  return data;
}

function convertSelectionSet(
  node: ASTNode & { selectionSet?: SelectionSetNode },
  query: DocumentNode,
  data: unknown,
): (ReaderSelection & NormalizationSelection)[] {
  const selections = (node.selectionSet?.selections ?? [])
    .map((selection) =>
      convertSelectionNodeWithConditions(
        selection,
        query,
        getSelectionData(selection, data),
      ),
    )
    .filter(notEmpty);

  return selections;
}

function convertSelectionNode(
  selection: SelectionNode,
  query: DocumentNode,
  data: unknown,
): ReaderSelection & NormalizationSelection {
  if (selection.kind === "Field") {
    const rawArgs =
      selection.arguments
        ?.map(
          (arg): ReaderArgument => convertValueNode(arg.value, arg.name.value),
        )
        .sort((a, b) => a.name.localeCompare(b.name)) ?? null;
    const args = rawArgs?.length === 0 ? null : rawArgs;

    const storageKey =
      selection.arguments &&
      selection.arguments.length > 0 &&
      selection.arguments.every((arg) => isConstValueNode(arg.value))
        ? getStableStorageKey(
            selection.name.value,
            Object.fromEntries(
              selection.arguments.map((arg) => [
                arg.name.value,
                getConstValueNodeValue(arg.value as ConstValueNode),
              ]),
            ),
          )
        : null;

    if (
      selection.selectionSet &&
      selection.selectionSet.selections.length > 0
    ) {
      return {
        kind: "LinkedField",
        name: selection.name.value,
        plural: Array.isArray(data),
        alias: selection.alias?.value ?? null,
        args,
        concreteType: null,
        selections: convertSelectionSet(selection, query, data),
        storageKey,
      } satisfies ReaderLinkedField;
    } else {
      return {
        kind: "ScalarField",
        name: selection.name.value,
        alias: selection.alias?.value ?? null,
        args,
        storageKey,
      } satisfies ReaderScalarField;
    }
  }

  if (selection.kind === "InlineFragment") {
    const typeName = selection.typeCondition?.name.value ?? undefined;
    assert(typeName, "Unreachable condition: inline fragment on unnamed type");

    return {
      abstractKey: null,
      kind: "InlineFragment",
      type: typeName,
      selections: convertSelectionSet(selection, query, data),
    } satisfies ReaderInlineFragment;
  }

  if (selection.kind === "FragmentSpread") {
    // Relay's AST doesn't include fragment definitions — instead, it inlines them. To make it
    // easier, we include them as an `InlineFragment`s instead of fully inlining them
    const fragment = query.definitions.find(
      (definition) =>
        definition.kind === "FragmentDefinition" &&
        definition.name.value === selection.name.value,
    );
    assert(fragment, `Fragment ${selection.name.value} not found`);
    assert(fragment.kind === "FragmentDefinition");

    return {
      kind: "InlineFragment",
      type: fragment.typeCondition.name.value,
      selections: convertSelectionSet(selection, query, data),
    } satisfies ReaderInlineFragment;
  }

  return exhaustiveCheck(
    selection,
    `Unsupported selection kind: ${(selection as SelectionNode).kind}`,
  );
}

export const convertDocumentNodeToRelayAST = (
  query: DocumentNode,
  data: unknown,
): ConcreteRequest => {
  const definition = query.definitions[0];
  if (definition.kind !== "OperationDefinition") {
    throw new Error("Expected OperationDefinition");
  }

  const argumentDefinitions =
    definition.variableDefinitions?.map(
      (varDef): ReaderLocalArgument => ({
        kind: "LocalArgument",
        name: varDef.variable.name.value,
        defaultValue: varDef.defaultValue
          ? getConstValueNodeValue(varDef.defaultValue)
          : null,
      }),
    ) ?? [];

  const type =
    definition.operation === "query"
      ? "Query"
      : definition.operation === "mutation"
        ? "Mutation"
        : definition.operation === "subscription"
          ? "Subscription"
          : exhaustiveCheck(
              definition.operation,
              `Unsupported operation: ${definition.operation}`,
            );
  assert(type, `Type not found for operation ${definition.operation}`);

  const name = `ApolloQuerySync(${
    definition.name?.value ?? "UnnamedOperation"
  })`;

  const selections = convertSelectionSet(definition, query, data);

  return {
    kind: "Request",
    fragment: {
      abstractKey: null,
      metadata: null,
      argumentDefinitions,
      kind: "Fragment",
      type: "Query",
      selections,
      name,
    },
    params: {
      name,
      operationKind: "query",
      text: print(query),
      cacheID: crypto.randomUUID(),
      id: null,
      metadata: {},
    },
    operation: {
      kind: "Operation",
      name,
      selections,
      argumentDefinitions,
    },
  };
};
