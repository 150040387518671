import React from "react";
import type { SkeletonError } from "@/domains/App/features/Skeleton/sharedTypes";

type Props = {
  onError: (error: SkeletonError) => void;
};

export class SkeletonErrorBoundary extends React.Component<Props> {
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.onError({ error, info });
  }

  render() {
    return this.props.children;
  }
}
