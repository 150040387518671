import { parse } from "qs";
import type {
  OAuthErrorResponse,
  OAuthResponse,
} from "@/features/Authentication/sharedTypes";

const oAuthKeys = ["code", "error", "error_description", "state"];

/**
 * Parse OAuth response URL from our OAuth provider
 * Filter out invalid keys
 */
export const parseOAuthResponseUrl = (resultUrl: string) => {
  const params = parse(resultUrl, { ignoreQueryPrefix: true });
  const type = params.error ? "error" : "ok";
  return Object.keys(params)
    .filter((key) => oAuthKeys.includes(key))
    .reduce(
      (filteredParams, key) => ({
        ...filteredParams,
        [key]: params[key],
      }),
      { type } as OAuthResponse,
    );
};

/**
 * Determine if an OAuth response is an error
 */
export const isOAuthErrorResponse = (
  result: OAuthResponse,
): result is OAuthErrorResponse => "error" in result;

/**
 * Cookie based auth (experimental)
 */

// This url query param is used to enable/disable cookie based auth in the backend (Ingress/Skipper)
export const COOKIE_BASED_AUTH_KEY = "cookie-auth";

function persistCookieBasedAuth(value: boolean) {
  localStorage.setItem(COOKIE_BASED_AUTH_KEY, String(value));
}

export function isCookieBasedAuth() {
  return localStorage.getItem(COOKIE_BASED_AUTH_KEY) === "true";
}

export function setCookieBasedAuthQueryParam(value: boolean) {
  const url = new URL(window.location.href);
  url.searchParams.set(COOKIE_BASED_AUTH_KEY, String(value));
  window.location.href = url.href;
}

export function bootstrapAuth() {
  const searchParams = new URLSearchParams(window.location.search);
  const cookieBasedAuthQueryParam = searchParams.get(COOKIE_BASED_AUTH_KEY);

  // Present in the url -> store it in local storage and render the app
  if (cookieBasedAuthQueryParam !== null) {
    persistCookieBasedAuth(cookieBasedAuthQueryParam === "true");
    // Not present in the url, but enabled in local storage -> refresh the page so Skipper takes effect
  } else if (isCookieBasedAuth()) {
    setCookieBasedAuthQueryParam(true);
  }
}
