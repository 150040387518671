export enum KycAnalyticsEvents {
  PeriodicReviewBannerViewed = "risk.periodic_review.banner.viewed",
  PeriodicReviewBannerClicked = "risk.periodic_review.banner.clicked",
  PeriodicReviewBannerOverrideClicked = "risk.periodic_review.banner.override.clicked",
  PeriodicReviewApplicationViewed = "risk.periodic_review.application.viewed",
  PeriodicReviewApplicationClosed = "risk.periodic_review.application.closed",
  PeriodicReviewApplicationCompleted = "risk.periodic_review.application.completed",
  PeriodicReviewBusinessDetailsForm = "risk.periodic_review.form.business",
  PeriodicReviewIndividualDetailsForm = "risk.periodic_review.form.individual",
}

type EmptyPayload = {};

type PeriodicReviewBannerClickedSchema = {
  overridePrompted: boolean;
};

type PeriodicReviewBannerOverrideClickedSchema = {
  overrideAccepted: boolean;
};

type FormEventSchema = {
  action: "view" | "close" | "submit";
};

export type KycEventSchemaMap = {
  [KycAnalyticsEvents.PeriodicReviewBannerViewed]: EmptyPayload;
  [KycAnalyticsEvents.PeriodicReviewBannerClicked]: PeriodicReviewBannerClickedSchema;
  [KycAnalyticsEvents.PeriodicReviewBannerOverrideClicked]: PeriodicReviewBannerOverrideClickedSchema;
  [KycAnalyticsEvents.PeriodicReviewApplicationClosed]: EmptyPayload;
  [KycAnalyticsEvents.PeriodicReviewApplicationCompleted]: EmptyPayload;
  [KycAnalyticsEvents.PeriodicReviewApplicationViewed]: EmptyPayload;
  [KycAnalyticsEvents.PeriodicReviewBusinessDetailsForm]: FormEventSchema;
  [KycAnalyticsEvents.PeriodicReviewIndividualDetailsForm]: FormEventSchema;
};
