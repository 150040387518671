import { datadogRum } from "@datadog/browser-rum";
import * as React from "react";
import type { ClosureStatus, DepositsRole } from "@/__generated__/globalTypes";
import {
  CardAccountType,
  DepositsAccountStatus,
} from "@/__generated__/globalTypes";
import type { UserRole } from "@/data/sharedTypes";
import { AccountStatus } from "@/data/sharedTypes";
import type { CashAccountStateQuery } from "@/domains/App/data/__generated__/queries.generated";
import { cashAccountStateQuery } from "@/domains/App/data/queries";
import { useQuery } from "@/features/Apollo";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { connectionToNodes } from "@/helpers";
import { createMockableHook } from "@/helpers/createMockableHook";

export type AccountStates = {
  cashAccountState: DepositsAccountStatus | null;
  cardAccountState: AccountStatus | null;
  cardAccountFromSuspendedToActive: boolean | null;
  accountClosureStatus: ClosureStatus | null;
  cardAccountType?: CardAccountType | null;
  hasCard?: boolean;
  hasCash?: boolean;
};

type UserRoles = {
  userRole: UserRole | null | undefined;
  userDepositsRole: DepositsRole | null | undefined;
};

const selectAccountStates = (data?: CashAccountStateQuery): AccountStates => {
  if (!data) {
    return {
      cardAccountState: null,
      cashAccountState: null,
      cardAccountFromSuspendedToActive: null,
      accountClosureStatus: null,
      cardAccountType: null,
      hasCard: false,
      hasCash: false,
    };
  }
  const cardAccountState = (data?.account?.status as AccountStatus) ?? null;
  const cardAccountType = data?.account?.cardAccountType;
  const cardAccountFromSuspendedToActive =
    data?.account?.statusFromSuspendedToActive ?? null;
  const accountClosureStatus = data?.accountClosureStatus ?? null;
  // Cash-only account has DEPOSITS_BACKED as cardAccountType
  // https://github.com/brexhq/credit_card/blob/main/onboarding/onboarding_server/lib/helpers/customer.ex#L156
  const hasCard =
    cardAccountState === AccountStatus.ACTIVE &&
    (cardAccountType === CardAccountType.TRADITIONAL_CHARGE ||
      cardAccountType === CardAccountType.TRANSACTIONAL_FLOAT);

  if (!data?.account?.depositsAccounts) {
    return {
      // this isn't typed as an enum on the backend :(
      cardAccountState,
      cardAccountFromSuspendedToActive,
      cashAccountState: null,
      accountClosureStatus,
      cardAccountType,
      hasCard,
      hasCash: false,
    };
  }
  const cashAccounts =
    data.account.depositsAccounts !== null
      ? connectionToNodes(data.account.depositsAccounts)
      : [];
  const depositAccount = cashAccounts[0];
  const cashAccountState = depositAccount?.status ?? null;
  return {
    cashAccountState,
    cardAccountState,
    cardAccountFromSuspendedToActive,
    accountClosureStatus,
    cardAccountType,
    hasCard,
    hasCash: cashAccountState === DepositsAccountStatus.ACTIVE,
  };
};

const selectUserRoles = (data?: CashAccountStateQuery): UserRoles => {
  if (!data) {
    return {
      userRole: null,
      userDepositsRole: null,
    };
  }

  return {
    // casting here because the role isn't a typed enum
    userRole: data.user?.role as UserRole,
    userDepositsRole: data.user?.depositsRole,
  };
};

const useAccountStatesQuery = ({ skip }: { skip?: boolean } = {}) => {
  const { accountStateCacheFirst } = useFeatureFlags();
  const { loading, data, error, refetch } = useQuery<CashAccountStateQuery>(
    cashAccountStateQuery,
    {
      skip,
      fetchPolicy: accountStateCacheFirst ? "cache-first" : "cache-and-network",
    },
  );
  React.useEffect(() => {
    datadogRum.setGlobalContextProperty(
      "accountStateCacheFirst",
      accountStateCacheFirst,
    );
  }, [accountStateCacheFirst]);

  const accountStates = React.useMemo(() => selectAccountStates(data), [data]);
  const userRoles = React.useMemo(() => selectUserRoles(data), [data]);

  return {
    loading: loading && !data,
    error,
    refetch,
    data,
    accountStates,
    userRoles,
  };
};

export const [useAccountStates, MockAccountStatesProvider] = createMockableHook(
  useAccountStatesQuery,
);
