import * as React from "react";
import { computeDateOffsetFromToken } from "@/features/ServerTime/helpers/computeDateOffsetFromToken";
import {
  getServerTimeOffset,
  storeServerTimeOffset,
} from "@/features/ServerTime/helpers/timeOffsetStorage";
import noOp from "@/helpers/noOp";

type ServerTimeType = {
  offset: number;
  setOffset(offset: number): void;
  refresh(): void;
};

export const ServerTimeContext = React.createContext<ServerTimeType>({
  offset: 0,
  setOffset: noOp,
  refresh: noOp,
});

export const useRefreshServerTimeState = () =>
  React.useContext(ServerTimeContext).refresh;

export const useServerTimeState = () => {
  const { offset, setOffset } = React.useContext(ServerTimeContext);
  return [offset, setOffset] as const;
};

export const useServerDateFactory = () => {
  const [offset] = useServerTimeState();
  return React.useCallback(() => new Date(Date.now() + offset), [offset]);
};

export const useComputeAndUpdateServerTime = () => {
  const [, setServerTimeOffset] = useServerTimeState();
  return React.useCallback(
    (...args: Parameters<typeof computeDateOffsetFromToken>) => {
      const serverTimeOffset = computeDateOffsetFromToken(...args);
      storeServerTimeOffset(serverTimeOffset);
      setServerTimeOffset(serverTimeOffset);
    },
    [setServerTimeOffset],
  );
};

export const ServerTimeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [offset, setOffset] = React.useState(0);

  const refresh = React.useCallback(() => {
    setOffset(getServerTimeOffset());
  }, []);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const value = React.useMemo<ServerTimeType>(
    () => ({
      refresh,
      setOffset,
      offset,
    }),
    [refresh, offset],
  );

  return (
    <ServerTimeContext.Provider value={value}>
      {children}
    </ServerTimeContext.Provider>
  );
};
