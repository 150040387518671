import {
  Alert,
  PrimaryButton,
  SecondaryButton,
} from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import { VStack } from "@brexhq/metal-layout";
import { Text } from "@brexhq/metal-text";
import * as React from "react";
import type { To } from "react-router-dom";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { useNavigate } from "@/hooks/useNavigate";

type UnsavedChangesModalProps = {
  bodyText: string;
  blockedLocation: To;
  setIsModalOpen: (b: boolean) => void;
};

const UnsavedChangesModalControllerInternal: React.VFC<
  UnsavedChangesModalProps
> = ({ bodyText, blockedLocation, setIsModalOpen }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleLeave = React.useCallback(() => {
    setIsModalOpen(false);

    navigate(blockedLocation, { bypassUnsavedChangesModal: true });
  }, [setIsModalOpen, navigate, blockedLocation]);

  const handleCancel = React.useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <Alert
      title={t("you-have-unsaved-changes")}
      primaryAction={
        <PrimaryButton key="cancel" onClick={handleCancel}>
          {t("stay-on-this-page")}
        </PrimaryButton>
      }
      secondaryAction={
        <SecondaryButton key="confirm" onClick={handleLeave}>
          {t("leave")}
        </SecondaryButton>
      }
      onClose={handleCancel}
      width={379}
    >
      <VStack spacing={16}>
        <Text>{bodyText}</Text>
      </VStack>
    </Alert>
  );
};

export const UnsavedChangesModalController = withControllerPerformanceMetrics(
  UnsavedChangesModalControllerInternal,
);
