import type { SeenFlagKey } from "@/__generated__/globalTypes";
import type { SeenFlagsHookOptions } from "@/features/SeenFlag/hooks/useSeenFlags";
import { useSeenFlags } from "@/features/SeenFlag/hooks/useSeenFlags";

interface SeenFlagHookOptions extends SeenFlagsHookOptions {
  key: SeenFlagKey;
}

/**
 * @deprecated Use useSeenFlags instead. This hook will be removed in the future.
 *
 * Hook to get the value of a single seenFlag
 */
export const useSeenFlag = ({ key, ...options }: SeenFlagHookOptions) => {
  const { hasError, isLoading, refetch, seenFlags } = useSeenFlags(
    [key],
    options,
  );
  return {
    isLoading,
    hasError,
    value: seenFlags[key]?.value ?? false,
    lastSeenAt: seenFlags[key]?.lastSeenAt,
    fetchLatest: refetch,
  };
};
