import * as React from "react";
import type { To, NavigateOptions } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useLocation, useNavigate as useNavigateBase } from "react-router-dom";
import { useUnsavedChangesModal } from "@/domains/App/contexts/UnsavedChangesContext";
import { useRefCallback } from "@/hooks/useRefCallback";

// NOTE: This key is exported so that it can be used by other
// components to consume the prevRoute.
// Currently, <EntryRouteCheck /> makes use of this key to determine
// if a page is being accessed from an acceptable entry route.
export const previousRouteKey = "prevRoute";
export const previousSearchKey = "prevSearch";

export type AppNavigationState = {
  [previousRouteKey]: string;
  [previousSearchKey]: string;
};

export type NavigateFn = (
  location: To,
  options?: NavigateOptions & { bypassUnsavedChangesModal?: boolean },
) => void;

/** Inject state about the previous route into a state object */
export function makeAppNavigationState(props: {
  pathname: string;
  search: string;
  state?: any;
}): AppNavigationState {
  return {
    ...props?.state,
    [previousRouteKey]: props.pathname,
    [previousSearchKey]: props.search,
  };
}

// Custom useNavigate hook that injects state from the previous route
export function useNavigate() {
  // navigateBase updates its identity upon every route change, which can
  // trigger extra rendering work while navigating. We only need the latest
  // version of this for when navigate is invoked below, so we store it in a ref.
  const navigateBase = useNavigateBase();

  const { pathname, search } = useLocation();
  const { shouldOpenModal, openModal } = useUnsavedChangesModal();

  const navigate: NavigateFn = useRefCallback((location, options) => {
    if (!options?.bypassUnsavedChangesModal && shouldOpenModal(location)) {
      openModal(location);
      return;
    }

    const state = makeAppNavigationState({
      state: options?.state,
      pathname,
      search,
    });

    navigateBase(
      typeof location === "string" ? { pathname: location } : location,
      {
        replace: options?.replace ?? false,
        state,
      },
    );
  });

  return navigate;
}

// We don't want anyone directly importing `useNavigate` from RR, so we
// re-export the alternative use here:
export const useNavigateDelta = () => {
  const navigateBase = useNavigateBase();
  const navigateDelta = React.useCallback(
    (delta: number) => {
      navigateBase(delta);
    },
    [navigateBase],
  );
  return navigateDelta;
};

export const useNavigatePrevious = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigatePrevious = (
    fallback: To,
    options?: NavigateOptions & { bypassUnsavedChangesModal?: boolean },
  ) => {
    const prevRoute = (location?.state as AppNavigationState)?.prevRoute;
    return navigate(prevRoute || fallback, options);
  };

  return navigatePrevious;
};
