import {
  BudgetConfigurationFlagType,
  BudgetConfigurationFlagStatus,
} from "@/__generated__/globalTypes";
import type { GetBudgetConfigurationFlags } from "@/data/__generated__/queries.generated";

const getStatus = (
  flags: GetBudgetConfigurationFlags["budgetConfigurationFlags"] | undefined,
  flagType: BudgetConfigurationFlagType,
): boolean =>
  flags?.find(({ type }) => type === flagType)?.status ===
  BudgetConfigurationFlagStatus.ACTIVE;

export const selectBudgetConfigurationFlags = (
  data?: GetBudgetConfigurationFlags,
): Partial<Record<BudgetConfigurationFlagType, boolean>> => {
  const flags = data?.budgetConfigurationFlags;

  return Object.fromEntries(
    Object.values(BudgetConfigurationFlagType).map((type) => [
      type,
      getStatus(flags, type),
    ]),
  );
};
