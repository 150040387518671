import type { ProductConfigQuery } from "@/data/__generated__/queries.generated";
import { productConfigQuery } from "@/data/queries";
import { useQuery } from "@/features/Apollo";
import { createMockableHook } from "@/helpers/createMockableHook";

// useProductConfigQuery should always have a cache-first policy. Only change the policy if you know what you're doing.
// We should not be calling the backend constantly with this query as it's used extensively throughout the app.
const useProductConfigQuery = () =>
  useQuery<ProductConfigQuery>(productConfigQuery, {
    fetchPolicy: "cache-first",
  });

export const [useMockableProductConfigQuery, MockProductConfigQueryProvider] =
  createMockableHook(useProductConfigQuery);
