import type { FeedbackSource, FeedbackType } from "@/__generated__/globalTypes";

export enum SupportAnalyticsEvents {
  // Suggestion form actions
  SuggestionFormView = "suggestion_form.view",
  SuggestionFormSubjectLineInputBlur = "suggestion_form.subject_line_input.blur",
  SuggestionFormResponseInputBlur = "suggestion_form.response_input.blur",
  SuggestionFormCtaClicked = "suggestion_form.cta.clicked",

  // Qualtrics
  QualtricsIframeMounted = "qualtrics.iframe.mounted",
  QualtricsInterceptReloaded = "qualtrics.intercept.reloaded",
  QualtricsSurveyEvaluated = "qualtrics.survey.evaluated",
  QualtricsSurveyIframeFound = "qualtrics.survey.iframe.found",
  QualtricsSurveyIframeNotFound = "qualtrics.survey.iframe.not.found",
  QualtricsSurveyIframeLoaded = "qualtrics.survey.iframe.loaded",
  QualtricsSurveyIframeView = "qualtrics.survey.iframe.view",
  QualtricsSurveyIframeNotViewed = "qualtrics.survey.iframe.not.viewed",
  QualtricsAsyncSurveyPopoverCloseCtaClicked = "qualtrics.async_survey_popover.close.cta.clicked",

  // NPS survey new banner analytics
  QualtricsNpsBannerView = "qualtrics.nps_banner.view",
  QualtricsNpsBannerCloseCtaClicked = "qualtrics.nps_banner.close.cta.clicked",
  QualtricsNpsBannerSubmitButtonClicked = "qualtrics.nps_banner.submit_button.clicked",
  QualtricsNpsBannerSubmitRetriesExceeded = "qualtrics.nps_banner.submit_retries_exceeded",
  QualtricsNpsBannerSubmitButtonSuccess = "qualtrics.nps_banner.submit_button.success",
  QualtricsNpsBannerThankYouMessageClicked = "qualtrics.nps_banner.thank_you_message.clicked",
  QualtricsApiFetchSurveyError = "qualtrics.api.fetch_survey.error",
  QualtricsApiCreateTransactionError = "qualtrics.api.create_transaction.error",
  QualtricsApiSearchContactError = "qualtrics.api.search_contact.error",
  QualtricsApiCreateSurveyResponseError = "qualtrics.api.create_survey_response.error",
}

type EmptyPayload = {};

type CustomerFeedbackPayload = {
  type: FeedbackType;
  source: FeedbackSource;
};

type InterceptPayload = {
  interceptId: string;
  surveyId: string;
};

type QualtricsNpsSurveyPayload = {
  error?: string;
};

export type SupportEventSchemaMap = {
  // Suggestion form
  [SupportAnalyticsEvents.SuggestionFormView]: CustomerFeedbackPayload;
  [SupportAnalyticsEvents.SuggestionFormSubjectLineInputBlur]: CustomerFeedbackPayload;
  [SupportAnalyticsEvents.SuggestionFormResponseInputBlur]: CustomerFeedbackPayload;
  [SupportAnalyticsEvents.SuggestionFormCtaClicked]: CustomerFeedbackPayload;

  // Qualtrics
  [SupportAnalyticsEvents.QualtricsIframeMounted]: EmptyPayload;
  [SupportAnalyticsEvents.QualtricsInterceptReloaded]: EmptyPayload;
  [SupportAnalyticsEvents.QualtricsSurveyEvaluated]: InterceptPayload;
  [SupportAnalyticsEvents.QualtricsSurveyIframeFound]: InterceptPayload;
  [SupportAnalyticsEvents.QualtricsSurveyIframeNotFound]: InterceptPayload;
  [SupportAnalyticsEvents.QualtricsSurveyIframeLoaded]: InterceptPayload;
  [SupportAnalyticsEvents.QualtricsSurveyIframeView]: InterceptPayload;
  [SupportAnalyticsEvents.QualtricsSurveyIframeNotViewed]: InterceptPayload;
  [SupportAnalyticsEvents.QualtricsAsyncSurveyPopoverCloseCtaClicked]: InterceptPayload;

  // NPS survey new banner
  [SupportAnalyticsEvents.QualtricsNpsBannerView]: EmptyPayload;
  [SupportAnalyticsEvents.QualtricsNpsBannerCloseCtaClicked]: EmptyPayload;
  [SupportAnalyticsEvents.QualtricsNpsBannerSubmitButtonClicked]: EmptyPayload;
  [SupportAnalyticsEvents.QualtricsNpsBannerSubmitButtonSuccess]: EmptyPayload;
  [SupportAnalyticsEvents.QualtricsNpsBannerSubmitRetriesExceeded]: EmptyPayload;
  [SupportAnalyticsEvents.QualtricsNpsBannerThankYouMessageClicked]: EmptyPayload;
  [SupportAnalyticsEvents.QualtricsApiFetchSurveyError]: QualtricsNpsSurveyPayload;
  [SupportAnalyticsEvents.QualtricsApiCreateTransactionError]: QualtricsNpsSurveyPayload;
  [SupportAnalyticsEvents.QualtricsApiSearchContactError]: QualtricsNpsSurveyPayload;
  [SupportAnalyticsEvents.QualtricsApiCreateSurveyResponseError]: QualtricsNpsSurveyPayload;
};
