import { useAtomValue } from "jotai";
import * as React from "react";
import {
  LegalEntityResourceAttribute,
  ResourceAction,
} from "@/__generated__/globalTypes";
import { resourceOperationMappingAtom } from "@/domains/App/features/Permissions/atoms/resourceOperationsQueryAtoms";

export const useLegalEntityResourceOperations = () => {
  const { legalEntity } = useAtomValue(resourceOperationMappingAtom);

  const canViewLegalEntities = React.useMemo(
    () => legalEntity.hasLegalEntityResourceOperation(ResourceAction.READ),
    [legalEntity],
  );

  const canCreateLegalEntities = React.useMemo(
    () => legalEntity.hasLegalEntityResourceOperation(ResourceAction.CREATE),
    [legalEntity],
  );

  const canEditLegalEntities = React.useMemo(
    () => legalEntity.hasLegalEntityResourceOperation(ResourceAction.UPDATE),
    [legalEntity],
  );

  const canDeleteLegalEntities = React.useMemo(
    () => legalEntity.hasLegalEntityResourceOperation(ResourceAction.DELETE),
    [legalEntity],
  );

  const canViewBillingGroups = React.useMemo(
    () =>
      legalEntity.hasLegalEntityResourceOperation(
        ResourceAction.READ,
        LegalEntityResourceAttribute.BILLING_CONFIGURATION,
      ),
    [legalEntity],
  );

  const canCreateBillingGroups = React.useMemo(
    () =>
      legalEntity.hasLegalEntityResourceOperation(
        ResourceAction.CREATE,
        LegalEntityResourceAttribute.BILLING_CONFIGURATION,
      ),
    [legalEntity],
  );

  const canEditBillingGroups = React.useMemo(
    () =>
      legalEntity.hasLegalEntityResourceOperation(
        ResourceAction.UPDATE,
        LegalEntityResourceAttribute.BILLING_CONFIGURATION,
      ),
    [legalEntity],
  );

  const canDeleteBillingGroups = React.useMemo(
    () =>
      legalEntity.hasLegalEntityResourceOperation(
        ResourceAction.DELETE,
        LegalEntityResourceAttribute.BILLING_CONFIGURATION,
      ),
    [legalEntity],
  );

  const canViewKYCDetails = React.useMemo(
    () =>
      legalEntity.hasLegalEntityResourceOperation(
        ResourceAction.READ,
        LegalEntityResourceAttribute.KYC,
      ),
    [legalEntity],
  );

  const canPerformKYC = React.useMemo(
    () =>
      legalEntity.hasLegalEntityResourceOperation(
        ResourceAction.CREATE,
        LegalEntityResourceAttribute.KYC,
      ) ||
      legalEntity.hasLegalEntityResourceOperation(
        ResourceAction.UPDATE,
        LegalEntityResourceAttribute.KYC,
      ),
    [legalEntity],
  );

  return {
    canViewLegalEntities,
    canCreateLegalEntities,
    canEditLegalEntities,
    canDeleteLegalEntities,
    canViewBillingGroups,
    canCreateBillingGroups,
    canEditBillingGroups,
    canDeleteBillingGroups,
    canViewKYCDetails,
    canPerformKYC,
  };
};
