import type { OAuthState, EndSessionOptions, AuthOptions } from "./sharedTypes";
import type { ErrorType } from "@/data/sharedTypes";

export enum AnalyticsEvents {
  LoginError = "app.login_error.viewed",
  LoginErrorRetry = "app.login_error.retry",
  LoginErrorLogout = "app.login_error.logout",
  LoginSuccess = "app.login.success",
  Logout = "app.logout",
  ActivationSubmitForm = "auth.activation.submitForm",
  BeginOpenIDAuthorization = "auth.beginOpenIDAuthorization",
  BeginOpenIDSession = "auth.beginOpenIdSession",
  EndOpenIdSession = "auth.endOpenIdSession",
  StartAuth = "auth.startAuth",
  GetAccessToken = "auth.getAccessToken",
  FinishLoginDelegate = "auth.useFinishLoginDelegate",
  FinishLoginOk = "auth.useFinishLoginOk",
  FinishLoginRedirect = "auth.useFinishLoginRedirect",
  LoginFinishAndRedirect = "auth.useLoginFinishAndRedirect",
  AuthError = "auth.error",
  AuthConfig = "auth.config",
  AuthConfigError = "auth.config.error",
  AuthDelegationEligible = "auth.delegation.eligible",
  AuthDelegationIneligible = "auth.delegation.ineligible",
}

type EmptyPayload = {};

type LoginErrorPayload = {
  errorType: ErrorType;
  errorMessage?: string;
};

type EndOpenIdSessionPayload = Pick<
  OAuthState,
  "originalLocation" | "selectedProduct"
> & {
  hasIdToken?: boolean;
  hasSession?: boolean;
  hasToken?: boolean;
  postLogoutRedirectUri?: EndSessionOptions["postLogoutRedirectUri"];
};

type FinishLoginDelegatePayload = {
  id?: string;
  state?: OAuthState;
};

type FinishLoginOkPayload = {
  id?: string;
  state?: OAuthState;
  tokenSub?: string;
};

type FinishLoginRedirectPayload = {
  id?: string;
  redirectUri?: string;
  state?: OAuthState;
};

type AuthErrorPayload = {
  id?: string;
  origin?: string;
  state?: string;
  message?: string;
};

type AuthConfigPayload = {
  stateId?: string;
};

type AuthDelegatePlatform = "mobile";

type AuthDelegationEligiblePayload = {
  delegatePlatform: AuthDelegatePlatform;
  originPlatform: string;
  userId: string;
};

type AuthDelegationIneligiblePayload = {
  delegatePlatform: AuthDelegatePlatform;
  originPlatform: string;
  role: string;
  userId: string;
};

export type EventSchemaMap = {
  [AnalyticsEvents.LoginError]: LoginErrorPayload;
  [AnalyticsEvents.LoginErrorRetry]: EmptyPayload;
  [AnalyticsEvents.LoginErrorLogout]: EmptyPayload;
  [AnalyticsEvents.LoginSuccess]: EmptyPayload;
  [AnalyticsEvents.Logout]: EmptyPayload;
  [AnalyticsEvents.ActivationSubmitForm]: EmptyPayload;
  [AnalyticsEvents.BeginOpenIDAuthorization]: EmptyPayload;
  [AnalyticsEvents.BeginOpenIDSession]: AuthOptions;
  [AnalyticsEvents.EndOpenIdSession]: EndOpenIdSessionPayload;
  [AnalyticsEvents.StartAuth]: AuthOptions;
  [AnalyticsEvents.GetAccessToken]: EmptyPayload;
  [AnalyticsEvents.FinishLoginDelegate]: FinishLoginDelegatePayload;
  [AnalyticsEvents.FinishLoginOk]: FinishLoginOkPayload;
  [AnalyticsEvents.FinishLoginRedirect]: FinishLoginRedirectPayload;
  [AnalyticsEvents.LoginFinishAndRedirect]: EmptyPayload;
  [AnalyticsEvents.AuthError]: AuthErrorPayload;
  [AnalyticsEvents.AuthConfig]: AuthConfigPayload;
  [AnalyticsEvents.AuthConfigError]: AuthConfigPayload;
  [AnalyticsEvents.AuthDelegationEligible]: AuthDelegationEligiblePayload;
  [AnalyticsEvents.AuthDelegationIneligible]: AuthDelegationIneligiblePayload;
};
