// Naming convention for future additions, feature flags should be prefixed
// with a domain identifier, ex: limit-experience.[feature-flag-name]

/**
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 */
export default [
  ["le-utilization-prepay", false],
  ["le-march-svb-bank-connection", false],
  ["le-silvergate-bank-instability-banner", false],
  ["le-svb-spend-velocity-banner", false],
  ["add_funds_funding_source_migration", false],
  ["le-early-pay-upsell-default-toggle", false],
  ["le-ongoing-fs-collection", false],
  ["le-finicity-fe-redirect", false],
  ["le-new-add-external-account-modal", false],
  ["financials-verification-service-migration", false],
  ["le-microdeposits-notifications", false],
  ["le-creditterms-enabled", false],
  ["gl-limit-drop-pending-modal", false],
  ["gl-enable-reimbursement-domestic-connection-type-select", false],
  ["le-new-credit-limit-increase-request", false],
  ["le-bank-statements-through-fsr", false],
  ["le-early-autopay-improvements", false],
] as const;
