import * as React from "react";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";

const evaluateSampling = (rate: number) => Math.random() < rate;

// Minimum threshold for bad behaviour
// We are interested in outliers where the optimization index is above this
const OPTIMIZATION_THRESHOLD = 0.7;

interface ProfilerDuration {
  // actualDuration: actual duration of render. This indicates how well the subtree makes use of memoization
  actualDuration: number;
  // baseDuration: The number of milliseconds estimating how much time it would take to re-render the entire <Profiler> subtree without any optimizations
  baseDuration: number;
  phase: "mount" | "update" | "nested-update";
}

export type RenderStats = ProfilerDuration & {
  renderOptimizationIndex: number;
};

interface PerformanceProfilerProps {
  enabled: boolean;
  name: string;
  onRenderStats: (stats: RenderStats) => void;
  sampleRate: number;
}

export type ControllerProfilerProps = Pick<
  PerformanceProfilerProps,
  "name" | "onRenderStats"
>;

const calculateAverage = (
  durations: RenderStats[],
  key: keyof Omit<RenderStats, "phase">,
) => durations.reduce((acc, curr) => acc + curr[key], 0) / durations.length;

const getRenderStats = ({
  baseDuration,
  actualDuration,
  phase,
}: ProfilerDuration) => {
  // This is a measure of the effectiveness of memoization within a component tree.
  // It indicates how much a page and its children have been optimized.
  // The values range from 0 to 1 with 0 meaning full optimization and 1 meaning no optimization.
  // A good state is a value closer to 0.
  // However, as there is no optimization at the mount phase, it is expected for this number to
  // be close to 1 during the initial render even for fully optimized pages.
  const renderOptimizationIndex = Math.max(
    Math.min(actualDuration / baseDuration, 1),
    0,
  );

  return {
    actualDuration,
    baseDuration,
    phase,
    renderOptimizationIndex,
  };
};

export const aggregateRenderStats = (
  allRenderStats: RenderStats[],
  profilePhase: ProfilerDuration["phase"],
) => {
  const durations = allRenderStats.filter(
    ({ phase }) => phase === profilePhase,
  );
  // component didn't render in the ready window
  // or component was not profiled likely due to sample rate
  if (!durations.length) {
    return {
      avgRenderOptimization: undefined,
      avgActualRenderDuration: undefined,
      avgBaseRenderDuration: undefined,
      badRenderCount: undefined,
      durations,
    };
  }

  const avgRenderOptimization = calculateAverage(
    durations,
    "renderOptimizationIndex",
  );
  const avgActualRenderDuration = calculateAverage(durations, "actualDuration");
  const avgBaseRenderDuration = calculateAverage(durations, "baseDuration");

  return {
    avgRenderOptimization,
    avgActualRenderDuration,
    avgBaseRenderDuration,
    badRenderCount: durations.length,
    durations,
  };
};

const PerformanceProfiler: React.FC<PerformanceProfilerProps> = ({
  enabled,
  children,
  name,
  sampleRate,
  onRenderStats,
}) => {
  // keep sampled controller consistent per mount
  const shouldSample = React.useRef(evaluateSampling(sampleRate));

  const handleRender = React.useCallback(
    (
      _: string,
      phase: ProfilerDuration["phase"],
      actualDuration: ProfilerDuration["actualDuration"],
      baseDuration: ProfilerDuration["baseDuration"],
    ) => {
      const stats = getRenderStats({ phase, actualDuration, baseDuration });
      if (
        shouldSample.current &&
        stats.renderOptimizationIndex > OPTIMIZATION_THRESHOLD
      ) {
        onRenderStats(stats);
      }
    },
    [onRenderStats],
  );

  if (enabled) {
    return (
      <React.Profiler id={name} onRender={handleRender}>
        {children}
      </React.Profiler>
    );
  }
  return <>{children}</>;
};

export const ControllerProfiler: React.FC<ControllerProfilerProps> = ({
  children,
  name,
  onRenderStats,
}) => {
  const { enableReactProfiler, reactProfilerSampleRate } = useFeatureFlags();
  return (
    <PerformanceProfiler
      name={name}
      enabled={enableReactProfiler}
      sampleRate={reactProfilerSampleRate}
      onRenderStats={onRenderStats}
    >
      {children}
    </PerformanceProfiler>
  );
};
