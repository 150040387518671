// Naming convention for future additions, feature flags should be prefixed
// with a domain identifier, ex: spend.[feature-flag-name]

/**
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 */
export default [
  ["travel.dashboard.booking-search-form-page", false],
  ["travel.trips-page-v2", false],
  ["travel.trip-dropdown-in-form", false],
  // TODO: (TRVLA-1039) Migrate to `travel-group-event-announcements` feature flag
  ["travel-mobile-group-event-announcements", false],
  ["travel.dashboard.details-pane-refactor", false],
  ["travel.enable-booking-approvals", false],
  ["travel.dashboard.budgets-api-bifurcation", false],
  ["travel.dashboard.audit-trail", false],
  ["travel.dashboard.brex-rewards-booking", false],
  ["travel.dashboard.personal-settings.integration", false],
] as const;
