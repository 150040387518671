import * as React from "react";
import {
  HelpshiftContext,
  defaultData,
} from "@/features/Helpshift/contexts/HelpshiftContext";
import type { HelpshiftContextValue } from "@/features/Helpshift/sharedTypes";

const HelpshiftChatStyles = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HelpshiftChatStyles" */
      "@/features/Helpshift/components/HelpshiftChatStyles"
    ),
);

const LazilyInitialedHelpshiftProvider = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LazilyInitializedHelpshift" */
      "@/features/Helpshift/contexts/LazilyInitializedHelpshift"
    ),
);

export const HelpshiftProvider: React.FC = ({ children }) => {
  const [helpshiftContextData, setHelpshiftContextData] =
    React.useState<HelpshiftContextValue>(defaultData);
  const [showProvider, setShowProvider] = React.useState(false);

  const value = React.useMemo(
    () => ({
      ...helpshiftContextData,
      initializeProvider: () => setShowProvider(true),
    }),
    [helpshiftContextData],
  );
  return (
    <HelpshiftContext.Provider value={value}>
      <React.Suspense fallback={null}>
        {helpshiftContextData.customStyles && <HelpshiftChatStyles />}
      </React.Suspense>
      <React.Suspense fallback={null}>
        {showProvider && (
          <LazilyInitialedHelpshiftProvider
            onContextChange={setHelpshiftContextData}
          />
        )}
      </React.Suspense>
      {children}
    </HelpshiftContext.Provider>
  );
};
