import * as React from "react";
import type { SeenFlagKey } from "@/__generated__/globalTypes";
import { useMutation } from "@/features/Apollo";
import type {
  TurnOnSeenFlag,
  TurnOnSeenFlagVariables,
} from "@/features/SeenFlag/data/mutations";
import { turnOnSeenFlag } from "@/features/SeenFlag/data/mutations";
import { mutationInput } from "@/helpers/graphql";

/**
 * Hook to indicate the key has been "seen"
 */
export const useTurnOnSeenFlag = ({
  key,
  onCompleted,
}: {
  key?: SeenFlagKey;
  onCompleted?: (data?: TurnOnSeenFlag) => void;
} = {}) => {
  const [turnOnSeenFlagMutation, { loading, error }] = useMutation<
    TurnOnSeenFlag,
    TurnOnSeenFlagVariables
  >(turnOnSeenFlag, { onCompleted });
  const handleTurnOnSeenFlagInternal = React.useCallback(
    async (turnOnKey?: SeenFlagKey) => {
      if (!turnOnKey) {
        return;
      }
      try {
        await turnOnSeenFlagMutation(mutationInput({ key: turnOnKey }));
      } catch (e) {
        console.error(`Turn on seen flag failed: ${turnOnKey}`, e);
      }
    },
    [turnOnSeenFlagMutation],
  );

  const handleTurnOnSeenFlag = React.useCallback(() => {
    return handleTurnOnSeenFlagInternal(key);
  }, [handleTurnOnSeenFlagInternal, key]);

  const handleTurnOnSeenFlagOverride = React.useCallback(
    (keyOverride: SeenFlagKey) => {
      if (!key) {
        return handleTurnOnSeenFlagInternal(keyOverride);
      }
    },
    [handleTurnOnSeenFlagInternal, key],
  );
  return {
    /**
     * When this hook is passed an optional "key", handleTurnOnSeenFlag
     * will turn on the seen flag with that key.
     */
    handleTurnOnSeenFlag,
    /**
     * When this hook is not passed an optional "key", handleTurnOnSeenFlagOverride
     * takes a parameter called "keyOverride" and turns on the seen flag whose key
     * corresponds to that parameter value.
     */
    handleTurnOnSeenFlagOverride,
    isLoading: loading,
    hasError: !!error,
    error,
  };
};
