import gql from "graphql-tag";

export const verificationRequestConfigFragment = gql`
  fragment VerificationRequestConfigFragment on VerificationRequestConfig {
    ... on SourceOfFundsRequestConfig {
      hasPIFundingSource
      isThirdPartyVerified
    }
    ... on JsonSchemaRequestConfig {
      content
    }
    ... on PersonaRequestConfig {
      individual
      roles
    }
    ... on DefaultRequestConfig {
      _
    }
    ... on TermsRequestConfig {
      termsType
    }
    ... on BORefreshRequestConfig {
      individuals {
        fullName {
          firstName
          lastName
        }
        title
        phoneNumber
        address {
          line1
          line2
          city
          state
          zip
          country
        }
        roles
        ownershipPercentage
        shouldCollectId
      }
    }
    ... on OwnershipRequestConfig {
      collectLast4SSN
    }
  }
`;
