import type { QualtricQuestionType } from "@/features/CustomerFeedback/sharedTypes";
import { getEnvironment } from "@/helpers";
import { urls } from "@/helpers/urls";

type QualtricsIds = {
  surveyId: string;
  interceptId: string;
  customWidth?: number;
  customHeight?: number;
};

type QualtricsSurveyType = "async" | "inline";

type QualtricsSurveyInfo = {
  staging: QualtricsIds;
  production: QualtricsIds;
  type: QualtricsSurveyType;
};

export type InterceptSurveyIds = {
  surveyId: string;
  interceptId: string;
  customWidth?: number;
  customHeight?: number;
};

/**
 * Any new surveys should be added to this type
 */
export type QualtricSurveyKeys =
  | "A1"
  | "A2"
  | "B2"
  | "B3"
  | "C2"
  | "D1"
  | "EL"
  | "NPS"
  | "BillingExperience"
  | "EarlyPayUpsellOptOut"
  | "isEligibleRU"
  | "Banking1"
  | "Banking2";

/**
 * This object is used to store both the Qualtrics Survey Ids and the associated
 * Intercept Ids. Ensure this matches up with the Ids in Qualtrics.
 * It also includes any in-house code eligibility that we set on a specific survey.
 *
 * Any new surveys should be added to this object
 *
 * More details can be found here:
 *
 */
export const qualtricsSurveys: {
  [key in QualtricSurveyKeys]: QualtricsSurveyInfo;
} = {
  A1: {
    staging: {
      surveyId: "SV_5aTs4ILZlzqcdXE",
      interceptId: "SI_bd4obCO3LSw56fQ",
    },
    production: {
      surveyId: "SV_3Rd2CzD7sdqnG3s",
      interceptId: "SI_6llleiukzBmdIuW",
    },
    type: "async",
  },
  A2: {
    staging: {
      surveyId: "SV_ewFBOHpcL2faAHs",
      interceptId: "SI_0BRcn8uCImU0rVY",
    },
    production: {
      surveyId: "SV_2brqCsavVWvjFMq",
      interceptId: "SI_55Aayce1hWoCclw",
    },
    type: "async",
  },
  B2: {
    staging: {
      surveyId: "SV_5aunQwvPjIuSh4a",
      interceptId: "SI_9Sx4SewZbhxRpX0",
    },
    production: {
      surveyId: "SV_6x2ePvgV8CKpLMi",
      interceptId: "SI_6swhKmRrEY4Skqq",
    },
    type: "async",
  },
  B3: {
    staging: {
      surveyId: "",
      interceptId: "",
    },
    production: {
      surveyId: "SV_5yX9cSHkY7Zif2K",
      interceptId: "SI_4GifLGskVWnmJMO",
    },
    type: "async",
  },
  C2: {
    staging: {
      surveyId: "SV_e2SIHZCGs3kCvtk",
      interceptId: "SI_5pvbqlEW19L3X9Q",
    },
    production: {
      surveyId: "SV_1HY1MaEL1uRYajk",
      interceptId: "SI_bCPVvHxC2w3GPpc",
    },
    type: "async",
  },
  D1: {
    staging: {
      surveyId: "SV_0dJ6jfc1IAtzzcG",
      interceptId: "SI_5dn7STNO26wKZ8y",
    },
    production: {
      surveyId: "SV_9pGkXa4msMc1k1g",
      interceptId: "SI_0CiwRUDJti1Ky6W",
    },
    type: "async",
  },
  EL: {
    staging: {
      surveyId: "SV_bfp5sa5sMkgWRfw",
      interceptId: "SI_5dbtVE1eKzBw9vM",
    },
    production: {
      surveyId: "SV_emxxeiGg3YMWXki",
      interceptId: "SI_1CgEO10uEcSF4zA",
    },
    type: "async",
  },
  NPS: {
    staging: {
      surveyId: "SV_ebrTGTgEc52KjJ4",
      interceptId: "SI_1N89cgLKbcpiVSe",
      // TODO: Move this to the root object, so it doesn't have to be applied twice
      customWidth: 720,
      customHeight: 370,
    },
    production: {
      surveyId: "SV_236OWU6jhetJ0sm",
      interceptId: "SI_1YMckCczdUQKj5Q",
      customWidth: 720,
      customHeight: 370,
    },
    type: "async",
  },
  BillingExperience: {
    staging: {
      surveyId: "SV_bqq0uUYaTa3ZtgG",
      interceptId: "SI_eL1luAVv4it6XXM",
    },
    production: {
      surveyId: "SV_cMzzhU9SDxDgONg",
      interceptId: "SI_4GVLuiiB9msrDEi",
    },
    type: "async",
  },
  EarlyPayUpsellOptOut: {
    staging: {
      surveyId: "SV_6rHq9pwC3crao5g",
      interceptId: "SI_0MU9qtmpEYjAhF4",
    },
    production: {
      surveyId: "SV_0pkQYA7ZPYy5bdY",
      interceptId: "SI_cOXikHTbWvJlhjw",
    },
    type: "async",
  },
  isEligibleRU: {
    staging: {
      surveyId: "SV_3abPKNWAz723sRE",
      interceptId: "SI_3mzolKd9iZpwm0e",
    },
    production: {
      surveyId: "SV_bkCAQApUmwdF9hc",
      interceptId: "SI_9KNHMCQzPn8RCv4",
    },
    type: "async",
  },
  Banking1: {
    staging: {
      surveyId: "SV_8pJbh81l3MAteLA",
      interceptId: "SI_bpfogvdonSprkdE",
    },
    production: {
      surveyId: "SV_0kYi29VcJQtSm8K",
      interceptId: "SI_3KRdtYFhETDgY7A",
    },
    type: "inline",
  },
  Banking2: {
    staging: {
      surveyId: "SV_8uApK7FR7YMFNS6",
      interceptId: "SI_cSW5P2m8L1bbBUW",
    },
    production: {
      surveyId: "SV_6Ryqa9bq1qNLs1g",
      interceptId: "SI_afyY7yLhZmhEsS2",
    },
    type: "inline",
  },
};

/**
 * This will be the source of truth for any survey prioritization that will be done in
 * the dashboard.
 *
 * Once a survey is added, ensure the survey is set by priority in this list.
 *
 * More information on survey throttling can be found here:
 */
export const asyncSurveyPriorityList: Array<QualtricSurveyKeys> = [
  "NPS", // Please keep NPS as #1
  "D1",
  "B3",
  "BillingExperience",
  "C2",
  "B2",
  "A2",
  "EL",
  "EarlyPayUpsellOptOut",
  "A1",
  "isEligibleRU",
];

export const getIsBrexUser = (email: string | null | undefined): boolean =>
  /^[A-Za-z0-9._%+-]+@brex.com$/.test(email ?? "");

const envCheck = () => {
  const isProduction = getEnvironment("APP_ENV") === "prod";

  if (isProduction) {
    return "production";
  }
  return "staging";
};

export const getSurveyId = (surveyKey: QualtricSurveyKeys): string | null => {
  const surveyConfig = qualtricsSurveys?.[surveyKey]?.[envCheck()];

  return surveyConfig.surveyId || null;
};

export const getInterceptSurveyIds = (
  surveyList: Array<QualtricSurveyKeys>,
): Array<InterceptSurveyIds> => {
  return surveyList.reduce<Array<InterceptSurveyIds>>(
    (interceptSurveyObj, survey) => {
      const surveyConfig = qualtricsSurveys?.[survey]?.[envCheck()];

      if (!surveyConfig) {
        return interceptSurveyObj;
      }

      const {
        interceptId = "",
        surveyId = "",
        customWidth = undefined,
        customHeight = undefined,
      } = surveyConfig;

      const interceptSurveyObjIds = {
        interceptId,
        surveyId,
        customWidth,
        customHeight,
      };

      return [...interceptSurveyObj, interceptSurveyObjIds];
    },
    [],
  );
};

export const qualtricsReloadPaths = new Set([urls.primitives.creditBilling]);

/**
 *
 * This function checks if the question is a Qualtrics question type that
 * can be used to display the survey to the user
 *
 * @param question unknown - the question id and value from Qualtrics
 * @returns boolean - if the question is a Qualtrics question type
 */
export const isQualtricsQuestionType = (
  question: unknown,
): question is QualtricQuestionType =>
  Boolean(
    typeof question === "object" &&
      question &&
      "DataExportTag" in question &&
      typeof question.DataExportTag === "string",
  );
