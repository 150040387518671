import React from "react";
import type { JsonObject } from "type-fest";
import { useTrackError } from "@/helpers/errorTracking";

type ErrorBoundaryProps = {
  trackErrorHook: (
    errorName: string,
    error: Error,
    extras?: JsonObject,
  ) => void;
};

/**
 * This ErrorBoundary acts as a middleware that tracks unhandled exceptions at
 * the controller level, then re-throws the error to be handled upstream.
 *
 * This allows us to better understand where unhandled exceptions originate so
 * that debugging is easier.
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.trackErrorHook(error.message, error, {
      trackOrigin: "PerformanceControllerErrorBoundary",
      reactComponentStack: info.componentStack,
    });
    throw error;
  }

  // this is just a pass-through component, always render children
  render() {
    return this.props.children;
  }
}

export const PerformanceControllerErrorBoundary: React.FC = React.memo(
  ({ children }) => {
    const { trackError } = useTrackError();
    return (
      <ErrorBoundary trackErrorHook={trackError}>{children}</ErrorBoundary>
    );
  },
);
