import { addToast } from "@brexhq/metal-toast";
import { useCallback, useState } from "react";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import { StepUpMfaAnalyticsEvents } from "@/features/StepUpMfa/analytics";
import { useOktaReauth } from "@/features/StepUpMfa/hooks/useOktaReauth";

export const useOktaReauthWithToast = () => {
  const trackEvent = useTrackEvent();

  const [loading, setLoading] = useState(false);

  const oktaReauth = useOktaReauth();

  const oktaReauthWithToast = useCallback(
    (onSuccess?: () => void, acrValues?: string) => {
      (async () => {
        try {
          setLoading(true);
          trackEvent(StepUpMfaAnalyticsEvents.StepUpMfaPrompted);
          const ok = await oktaReauth(acrValues);
          if (ok && onSuccess) {
            onSuccess();
            trackEvent(StepUpMfaAnalyticsEvents.StepUpMfaVerified);
          }
          if (!ok) {
            addToast({
              type: "error",
              title: "Authentication pending",
              description:
                "You have a pending authentication window. We cannot proceed without verifying your identity there.",
            });
            trackEvent(StepUpMfaAnalyticsEvents.StepUpMfaFailed);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          addToast({
            type: "error",
            title: "Authentication failed",
            description:
              "We could not authenticate your account with the information provided. Please try again or contact your account administrator.",
            cta: {
              actionTitle: "Try again",
              onAction: async () => {
                oktaReauthWithToast(onSuccess);
              },
            },
          });
          trackEvent(StepUpMfaAnalyticsEvents.StepUpMfaFailed);
        }
      })();
    },
    [oktaReauth, trackEvent],
  );

  return {
    loading,
    oktaReauthWithToast,
  };
};
