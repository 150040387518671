import { BillingCycle } from "@/__generated__/globalTypes";

export type NamespacedString<
  Namespace extends string,
  String extends string,
> = `${Namespace}.${String}`;

export enum CardActionType {
  /** Activates a newly issued physical card. */
  ACTIVATE = "Activate card",

  /** For admins, locks another user’s card. */
  ADMIN_LOCK = "Admin lock",

  /** Terminating a request for card. */
  CANCEL = "Cancel",

  /** Updates the billing adress. */
  CHANGE_ADDRESS = "Change address",

  CONTACT_SUPPORT = "Contact support to replace, cancel, or transfer this card.",

  /** Locks a card, preventing any transactions and adding to mobile wallets. */
  LOCK = "Lock",

  MODIFY = "Modify",

  /** Updates the display name of a card. */
  RENAME = "Rename",

  /** Initiates a requests for a replacement to be sent, also canceling the card. */
  REPLACE = "Replace",

  /** Terminates an existing, active card. */
  TERMINATE = "Cancel card",

  /** Transfers ownership of a card to another user, for business continuation. */
  TRANSFER_OWNERSHIP = "Transfer ownership",

  /** Unlocks a previous locked card. */
  UNLOCK = "Unlock",

  /** Sets a 4-digit PIN for physical use when the point of sale requires it (first time action). */
  SET_PIN = "Set PIN",

  /** Resets the previously set PIN and requests the user to create a new one.  */
  RESET_PIN = "Reset PIN",

  /** Navigates to the Expenses table with pre-added filters. */
  VIEW_EXPENSES = "View expenses",

  /** Shows shipping tracking details for a physical card. */
  VIEW_SHIPPING_DETAILS = "View shipping details",
}

export enum BudgetActionType {
  VIEW_EXPENSES = "View expenses",
  REQUEST_LIMIT_INCREASE = "Request limit increase",
  INCREASE_LIMIT = "Increase limit",
  EDIT_BUDGET = "Edit budget",
}

export type PhysicalCardShippingAddress = {
  address1: string;
  address2: string | null;
  city: string;
  country: string;
  state: string;
  zip: string;
};

export type PhysicalCardShippingAddressWithName = {
  firstName: string;
  lastName: string;
  address: PhysicalCardShippingAddress;
};

export enum OnboardingTaskStatus {
  LOADING = "LOADING",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export const LoadingTaskId = "LOADING" as const;

export enum TilesStyle {
  small = "small",
  tall = "tall",
  wide = "wide",
}

export type OfficeAddress = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  country: string | null;
  state: string | null;
  zip: string | null;
};

export enum UserIntent {
  addBankAccounts = "addBankAccounts",
  addExternalAccount = "addExternalAccount",
  manageAutopay = "manageAutopay",
  removeBankConnection = "removeBankConnection",
  reverifyBankAccount = "reverifyBankAccount",
  removeBankAccount = "removeBankAccount",
  fixBankConnection = "fixBankConnection",
  fixBankConnections = "fixBankConnections",
  verifyAllowList = "verifyAllowList",
  addBankConnection = "addBankConnection",
  migrateBankConnection = "migrateBankConnection",
  addPlaidMicrodeposit = "addPlaidMicrodeposit",
  addManualBankAccount = "addManualBankAccount",
  addUnderwritingAccount = "addUnderwritingAccount",
}

export enum AccountStatus {
  ONBOARDING = "onboarding",
  ACTIVE = "active",
  SUSPENDED = "suspended",
  WAITING_APPROVAL = "waiting_approval",
  CLOSED = "closed",
  REJECTED = "rejected",
  WAITING_SUBMISSION = "waiting_submission",
  TERMINATED = "terminated",
}

export enum CardInstrumentType {
  VIRTUAL = "virtual",
  PHYSICAL = "physical",
}

export enum ErrorType {
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  PASSWORD_RESET_USER_NOT_FOUND = "PASSWORD_RESET_USER_NOT_FOUND",
  CREATE_CREDENTIAL_FAILURE = "CREATE_CREDENTIAL_FAILURE",
  PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE",
  FETCH_USER_FAILURE = "FETCH_USER_FAILURE",
  ACTIVATE_USER_FAILURE = "ACTIVATE_USER_FAILURE",
  ENABLE_USER_FAILURE = "ENABLE_USER_FAILURE",
  DISABLE_USER_FAILURE = "DISABLE_USER_FAILURE",
  SET_USER_ROLE_FAILURE = "SET_USER_ROLE_FAILURE",
  SET_USER_LOCATION_FAILURE = "SET_USER_LOCATION_FAILURE",
  SET_USER_DEPARTMENT_FAILURE = "SET_USER_DEPARTMENT_FAILURE",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGIN_FINISH_FAILURE = "LOGIN_FINISH_FAILURE",
  LOGIN_ACCOUNT_DISABLED = "LOGIN_ACCOUNT_DISABLED",
  LOGIN_ACCOUNT_REJECTED = "LOGIN_ACCOUNT_REJECTED",
  LOGIN_USER_DISABLED = "LOGIN_USER_DISABLED",
  LOGIN_INVALID_CREDENTIALS = "LOGIN_INVALID_CREDENTIALS",
  CREATE_SMS_CHANNEL_FAILURE = "CREATE_SMS_CHANNEL_FAILURE",
  BEGIN_VERIFY_CHANNEL_FAILURE = "BEGIN_VERIFY_CHANNEL_FAILURE",
  VERIFY_SMS_FAILURE = "VERIFY_SMS_FAILURE",
  CREATE_VIRTUAL_CARD_FAILURE = "CREATE_VIRTUAL_CARD_FAILURE",
  CREATE_PHYSICAL_CARD_FAILURE = "CREATE_PHYSICAL_CARD_FAILURE",
  REFRESH_FAILURE = "REFRESH_FAILURE",
  REFRESH_REQUIRE_LOGIN = "REFRESH_REQUIRE_LOGIN",
  UPDATE_INSTITUTION_LOGIN_FAILURE = "UPDATE_INSTITUTION_LOGIN_FAILURE",
  FETCH_FINANCIAL_SOURCES_FAILURE = "FETCH_FINANCIAL_SOURCES_FAILURE",
  GENERAL_MFA_FAILURE = "GENERAL_MFA_FAILURE",
  INVALID_MFA_CREDENTIALS = "INVALID_MFA_CREDENTIALS",
  MFA_TIMEOUT = "MFA_TIMEOUT",
  SIGNUP_ERROR = "SIGNUP_ERROR",
  FETCH_APPLICATIONS_FAILURE = "FETCH_APPLICATIONS_FAILURE",
  CREATE_APPLICATION_FAILURE = "CREATE_APPLICATION_FAILURE",
  UPDATE_APPLICATION_FAILURE = "UPDATE_APPLICATION_FAILURE",
  SUBMIT_APPLICATION_FAILURE = "SUBMIT_APPLICATION_FAILURE",
  CONNECT_INSTITUTION_FAILURE = "CONNECT_INSTITUTION_FAILURE",
  CONNECT_INSTITUTION_INVALID_CREDENTIALS = "CONNECT_INSTITUTION_INVALID_CREDENTIALS",
  CONNECT_INSTITUTION_USER_ACTION_REQUIRED = "CONNECT_INSTITUTION_USER_ACTION_REQUIRED",
  CONNECT_INSTITUTION_EXPIRED = "CONNECT_INSTITUTION_EXPIRED",
  CONNECT_INSTITUTION_INVALID_MFA = "CONNECT_INSTITUTION_INVALID_MFA",
  CONNECT_INSTITUTION_LOGIN_REQUIRED = "CONNECT_INSTITUTION_LOGIN_REQUIRED",
  CONNECT_INSTITUTION_NO_ACCOUNT_DATA = "CONNECT_INSTITUTION_NO_ACCOUNT_DATA",
  CONNECT_GENERATE_URL_ERROR = "CONNECT_GENERATE_URL_ERROR",
  CONNECT_CONNECT_ERROR = "CONNECT_CONNECT_ERROR",
  CONNECT_USER_CANCELLED = "CONNECT_USER_CANCLLED",
  CONNECT_FINICITY_ERROR = "CONNECT_FINICITY_ERROR",
  COMPLETE_CHALLENGE_FAILURE = "COMPLETE_CHALLENGE_FAILURE",
  COMPLETE_CHALLENGE_INVALID_CREDENTIALS = "COMPLETE_CHALLENGE_INVALID_CREDENTIALS",
  COMPLETE_CHALLENGE_USER_ACTION_REQUIRED = "COMPLETE_CHALLENGE_USER_ACTION_REQUIRED",
  COMPLETE_CHALLENGE_EXPIRED = "COMPLETE_CHALLENGE_EXPIRED",
  COMPLETE_CHALLENGE_LOGIN_REQUIRED = "COMPLETE_CHALLENGE_LOGIN_REQUIRED",
}

export enum UserRole {
  ADMIN = "admin",
  REGULAR = "regular",
  BOOKKEEPER = "bookkeeper",
}

export enum UserStatus {
  ACTIVE = "active",
  INVITED = "invited",
  DISABLED = "disabled",
  PENDING_ACTIVATION = "pending_activation",
  DELETED = "deleted",
}

export enum DepositsRole {
  USER = "USER",
  ADMIN = "ADMIN",
  BOOKKEEPER = "BOOKKEEPER",
}

export enum RiskEvaluationBlueprint {
  V1 = "risk_evaluation",
  V2 = "risk_evaluation_v2",
}

export enum FileContentType {
  pdf = "pdf",
  image = "image",
}

export enum FileUploadErrorType {
  PREVIEW_INVALID_FILE_TYPE = "PREVIEW_INVALID_FILE_TYPE",
  PREVIEW_UNEXPECTED_ERROR = "PREVIEW_UNEXPECTED_ERROR",
  UPLOAD_INVALID_FILE_TYPE = "UPLOAD_INVALID_FILE_TYPE",
  UPLOAD_UNEXPECTED_ERROR = "UPLOAD_UNEXPECTED_ERROR",
}

export enum UnsafeFlags {
  BETA_GRANDFATHERED_UPSELL_SEEN = "is_beta_grandfathered_upsell_seen",
  IS_EXPENSE_REVIEW_GA_BANNER_SEEN = "is_expense_review_ga_banner_seen",
  IS_GRANDFATHER_BANNER_SEEN = "is_grandfather_banner_seen",
  REIMBURSEMENTS_INTRO_MODAL_SEEN = "is_reimbursements_intro_modal_seen",
  REQUESTS_CARD_SETUP_COMPLETED = "is_requests_card_setup_completed",
  REQUESTS_INTRO_COMPLETED = "is_requests_intro_completed",
  REQUESTS_REIMBURSEMENTS_SETUP_COMPLETED = "is_requests_reimbursements_setup_completed",
  HAS_ACCEPTED_REIMBURSEMENT_TERMS = "has_accepted_reimbursements_terms",
  HAS_SETUP_ORGANIZATION = "has_setup_organiation",
  CASH_MSAA_THRESHOLD_TAKEOVER_SEEN = "is_cash_msaa_threshold_takeover_seen",
  CASH_MSAA_V3_THRESHOLD_TAKEOVER_SEEN = "is_cash_msaa_v3_threshold_takeover_seen",
  HAS_REVIEWED_TRAVEL_POLICIES = "has_reviewed_travel_policies",
  HAS_COMPLETED_BILL_PAY_SETUP_FLOW = "has_completed_bill_pay_setup_flow",
  HAS_DISMISSED_ERP_SYNC_COMPLETE = "has_dismissed_erp_sync_complete",
  HAS_VIEWED_GL_MAPPING_FLOW = "has_viewed_gl_mapping_flow",
  HAS_DISMISSED_ESSENTIALS_BILL_PAY_BUDGET_REVIEW = "has_dismissed_essentials_bill_pay_budget_review",
  HAS_DISMISSED_AMS_OPEN_YOUR_ACCOUNTS_COMPLETION_CONTENT = "has_dismissed_ams_open_your_accounts_completion_content",
  HAS_DISMISSED_AMS_START_SPENDING_COMPLETION_CONTENT = "has_dismissed_ams_start_spending_completion_content",
  HAS_DISMISSED_AMS_EARN_REWARDS_ON_VENDOR_PAYMENTS_COMPLETION_CONTENT = "has_dismissed_ams_earn_rewards_on_vendor_payments_completion_content",
  HAS_DISMISSED_AMS_ADD_YOUR_TEAM_COMPLETION_CONTENT = "has_dismissed_ams_add_your_team_completion_content",
  HAS_DISMISSED_AMS_OPTIMIZE_BOOKKEEPING_COMPLETION_CONTENT = "has_dismissed_ams_optimize_bookkeeping_completion_content",
  HAS_DISMISSED_AMS_COMPLETION_CONTENT = "has_dismissed_ams_completion_content",
  HAS_CONSENTED_TO_TRAVEL_TERMS_OF_SERVICE = "has_consented_to_travel_terms_of_service",
}

export enum PaymentTypes {
  ach = "ACH",
  domesticWire = "Domestic wire",
  internationalWire = "International wire",
  check = "Check",
}

export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export const billingCycleOptions = [
  BillingCycle.EVERY_N_DAYS,
  BillingCycle.WEEKLY,
  BillingCycle.SEMI_WEEKLY,
  BillingCycle.BI_WEEKLY,
  BillingCycle.SEMI_MONTHLY,
  BillingCycle.MONTHLY,
] as const;

export type BillingCycleOption = (typeof billingCycleOptions)[number];

export type SelectedBillingCycle = {
  cycle: BillingCycleOption | undefined;
  daysOfWeek: DayOfWeek[] | undefined;
  dayOfMonth?: number;
};

type MonthlyBillingCycle = {
  cycle: BillingCycle.MONTHLY;
  dayOfMonth: number;
};

type SemiMonthlyBillingCycle = {
  cycle: BillingCycle.SEMI_MONTHLY;
  firstDay: number;
  secondDay: number;
};

type EveryNDaysBillingCycle = {
  cycle: BillingCycle.EVERY_N_DAYS;
  numOfDays: number;
};

type BiweeklyBillingCycle = {
  cycle: BillingCycle.BI_WEEKLY;
  dayOfWeek: DayOfWeek;
};

type SemiWeeklyBillingCycle = {
  cycle: BillingCycle.SEMI_WEEKLY;
  firstDay: DayOfWeek;
  secondDay: DayOfWeek;
};

type WeeklyBillingCycle = {
  cycle: BillingCycle.WEEKLY;
  dayOfWeek: DayOfWeek;
};

export type BillingCycleDetails =
  | MonthlyBillingCycle
  | SemiMonthlyBillingCycle
  | EveryNDaysBillingCycle
  | BiweeklyBillingCycle
  | SemiWeeklyBillingCycle
  | WeeklyBillingCycle;

export type CurrencyCode =
  // United Arab Emirates Dirham
  | "AED"
  // Albanian Lek
  | "ALL"
  // Armenian Dram
  | "AMD"
  // Netherlands Antillean Guilder
  | "ANG"
  // Angolan Kwanza
  | "AOA"
  // Argentine Peso
  | "ARS"
  // Australian Dollar
  | "AUD"
  // Bosnia and Herzegovina Convertible Mark
  | "BAM"
  // Barbadian Dollar
  | "BBD"
  // Bangladeshi Taka
  | "BDT"
  // Bulgarian Lev
  | "BGN"
  // Bahraini Dinar
  | "BHD"
  // Burundian Franc
  | "BIF"
  // Bermudian Dollar
  | "BMD"
  // Brunei Dollar
  | "BND"
  // Bolivian Boliviano
  | "BOB"
  // Brazilian Real
  | "BRL"
  // Bahamian Dollar
  | "BSD"
  // Botswanan Pula
  | "BWP"
  // Belize Dollar
  | "BZD"
  // Canadian Dollar
  | "CAD"
  // Swiss Franc
  | "CHF"
  // Chilean Peso
  | "CLP"
  // Chinese Yuan
  | "CNY"
  // Colombian Peso
  | "COP"
  // Costa Rican Colón
  | "CRC"
  // Cape Verdean Escudo
  | "CVE"
  // Czech Republic Koruna
  | "CZK"
  // Djiboutian Franc
  | "DJF"
  // Danish Krone
  | "DKK"
  // Dominican Peso
  | "DOP"
  // Algerian Dinar
  | "DZD"
  // Egyptian Pound
  | "EGP"
  // Eritrean Nakfa
  | "ERN"
  // Ethiopian Birr
  | "ETB"
  // Euro
  | "EUR"
  // Fijian Dollar
  | "FJD"
  // British Pound
  | "GBP"
  // Georgian Lari
  | "GEL"
  // Ghanaian Cedi
  | "GHS"
  // Gambian Dalasi
  | "GMD"
  // Guinean Franc
  | "GNF"
  // Guatemalan Quetzal
  | "GTQ"
  // Guyanaese Dollar
  | "GYD"
  // Hong Kong Dollar
  | "HKD"
  // Honduran Lempira
  | "HNL"
  // Croatian Kuna
  | "HRK"
  // Haitian Gourde
  | "HTG"
  // Hungarian Forint
  | "HUF"
  // Indonesian Rupiah
  | "IDR"
  // Israeli New Sheqel
  | "ILS"
  // Indian Rupee
  | "INR"
  // Jamaican Dollar
  | "JMD"
  // Jordanian Dinar
  | "JOD"
  // Japanese Yen
  | "JPY"
  // Kenyan Shilling
  | "KES"
  // Kyrgystani Som
  | "KGS"
  // Cambodian Riel
  | "KHR"
  // South Korean Won
  | "KRW"
  // Kuwaiti Dinar
  | "KWD"
  // Kazakhstani Tenge
  | "KZT"
  // Laotian Kip
  | "LAK"
  // Sri Lankan Rupee
  | "LKR"
  // Lesotho Loti
  | "LSL"
  // Moroccan Dirham
  | "MAD"
  // Malagasy Ariary
  | "MGA"
  // Mongolian Tugrik
  | "MNT"
  // Mauritian Rupee
  | "MUR"
  // Maldivian Rufiyaa
  | "MVR"
  // Malawian Kwacha
  | "MWK"
  // Mexican Peso
  | "MXN"
  // Malaysian Ringgit
  | "MYR"
  // Mozambican Metical
  | "MZN"
  // Namibian Dollar
  | "NAD"
  // Nigerian Naira
  | "NGN"
  // Nicaraguan Córdoba
  | "NIO"
  // Norwegian Krone
  | "NOK"
  // Nepalese Rupee
  | "NPR"
  // New Zealand Dollar
  | "NZD"
  // Omani Rial
  | "OMR"
  // Peruvian Nuevo Sol
  | "PEN"
  // Papua New Guinean Kina
  | "PGK"
  // Philippine Peso
  | "PHP"
  // Pakistani Rupee
  | "PKR"
  // Polish Zloty
  | "PLN"
  // Paraguayan Guarani
  | "PYG"
  // Qatari Rial
  | "QAR"
  // Romanian Leu
  | "RON"
  // Serbian Dinar
  | "RSD"
  // Rwandan Franc
  | "RWF"
  // Saudi Riyal
  | "SAR"
  // Solomon Islands Dollar
  | "SBD"
  // Seychellois Rupee
  | "SCR"
  // Swedish Krona
  | "SEK"
  // Singapore Dollar
  | "SGD"
  // Sierra Leonean Leone
  | "SLL"
  // Surinamese Dollar
  | "SRD"
  // Swazi Lilangeni
  | "SZL"
  // Thai Baht
  | "THB"
  // Tunisian Dinar
  | "TND"
  // Tongan Paʻanga
  | "TOP"
  // Turkish Lira
  | "TRY"
  // Trinidad and Tobago Dollar
  | "TTD"
  // New Taiwan Dollar
  | "TWD"
  // Tanzanian Shilling
  | "TZS"
  // Ugandan Shilling
  | "UGX"
  // United States Dollar
  | "USD"
  // Vietnamese Dong
  | "VND"
  // Vanuatu Vatu
  | "VUV"
  // Samoan Tala
  | "WST"
  // Central African CFA Franc
  | "XAF"
  // East Caribbean Dollar
  | "XCD"
  // West African CFA Franc
  | "XOF"
  // CFP Franc (Franc Pacifique)
  | "XPF"
  // South African Rand
  | "ZAR"
  // Zambian Kwacha
  | "ZMW";
