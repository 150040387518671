import { useMemo } from "react";
import type { Variables } from "relay-runtime";
import {
  Store,
  RecordSource,
  Environment,
  Network,
  Observable,
} from "relay-runtime";
import defaultGetDataID from "relay-runtime/lib/store/defaultGetDataID";
import { v4 as uuid } from "uuid";
import { useAnalytics } from "@/features/Analytics/contexts/Analytics";
import type { TrackFunctionType } from "@/features/Analytics/sharedTypes";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import { typePolicies } from "@/features/Apollo/helpers/cache";
import { useGetCustomHeaders } from "@/features/Apollo/helpers/getCustomHeaders";
import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { useBeginOpenIDAuthorization } from "@/features/Authentication/hooks/useBeginOpenIDAuthorization";
import {
  fetchWithRetry,
  type FetchFnAdditionalParams,
} from "@/features/Relay/helpers/network";
import { getEnvironment } from "@/helpers";
import { inSyntheticTest } from "@/helpers/environment";
import { useTrackError } from "@/helpers/errorTracking";
import { useGetErrorHandler } from "@/helpers/graphqlRequests";

type GetRelayEnvironmentParams = FetchFnAdditionalParams & {
  trackEvent: TrackFunctionType;
  /**
   * A callback to be called when a Relay query is resolved. This is currently used to sync Relay's
   * and Apollo's caches
   */
  onRelayQuery?: OnRelayQuery;
};

export type OnRelayQuery = (
  query: string,
  variables: Variables,
  data: unknown,
) => void;

export class BrexRelayEnvironment extends Environment {
  constructor({
    trackEvent,
    onRelayQuery,
    ...fetchParams
  }: GetRelayEnvironmentParams) {
    super({
      requiredFieldLogger: (event) => {
        trackEvent(AllAnalyticsEvents.MissingRequiredField, event);
      },
      store: new Store(new RecordSource()),
      getDataID: scopedGetDataId,
      network: Network.create(
        (params, variables, { metadata }, _uploadables) => {
          try {
            if (metadata?.metadata) {
              // we set the requestId here to make sure it's generated at fetch time
              metadata.metadata.requestId ??= uuid();
            }

            return Observable.from(
              fetchWithRetry({
                params,
                variables,
                metadata,
                ...fetchParams,
              }).then((result) => {
                if (
                  onRelayQuery &&
                  params.text &&
                  result.data &&
                  !result.errors
                ) {
                  onRelayQuery(params.text, variables, result.data);
                }

                return result;
              }),
            );
          } catch (error) {
            console.error("Failed to fetch", error);
            throw error;
          }
        },
      ),
    });
    this.#trackEvent = trackEvent;
  }

  #trackEvent: TrackFunctionType;

  #alreadyLoggedTypeNamesCombinations: Set<string> | undefined;

  /**
   * Looks for duplicate IDs in the store and logs an event if any are found. Only runs in non-prod
   * or during synthetic tests
   */
  checkForDuplicateIds() {
    if (getEnvironment("APP_ENV") === "prod" && !inSyntheticTest()) return;

    this.#alreadyLoggedTypeNamesCombinations ??= new Set();
    const recordIds = this.getStore().getSource().getRecordIDs();
    const typesPerId = recordIds.reduce<Record<string, Set<string>>>(
      (acc, scopedId) => {
        if (scopedId.startsWith("client:")) {
          return acc;
        }

        const match = /^(.*?)\|(.*)$/.exec(scopedId);

        if (match) {
          const [, typeName, baseId] = match;
          acc[baseId] ??= new Set();
          acc[baseId].add(typeName);
        }

        return acc;
      },
      {},
    );

    for (const [id, typeNames] of Object.entries(typesPerId)) {
      if (typeNames.size > 1) {
        /**
         * A unique (through sorting) string representation of a given set of type names, used to
         * avoid logging the same combination multiple times.
         * PS: tuples can't come soon enough — https://github.com/tc39/proposal-record-tuple
         */
        const typeNamesStr = Array.from(typeNames).sort().join(",");
        if (!this.#alreadyLoggedTypeNamesCombinations.has(typeNamesStr)) {
          this.#alreadyLoggedTypeNamesCombinations.add(id);
          this.#trackEvent(AllAnalyticsEvents.DuplicateDataId, {
            typeNames: Array.from(typeNames),
          });
        }
      }
    }
  }
}

export const useGetRelayEnvironment = (onRelayQuery?: OnRelayQuery) => {
  const getCustomHeaders = useGetCustomHeaders();
  const { getAccessToken, refreshToken } = useAuthenticationContext();
  const beginOpenIDAuthorization = useBeginOpenIDAuthorization();
  const { trackError } = useTrackError();
  const { trackEvent } = useAnalytics();
  const handleError = useGetErrorHandler();

  return useMemo(
    () =>
      new BrexRelayEnvironment({
        getCustomHeaders,
        getAccessToken,
        refreshToken,
        beginOpenIDAuthorization,
        trackError,
        trackEvent,
        handleError,
        onRelayQuery,
      }),
    [
      beginOpenIDAuthorization,
      getAccessToken,
      getCustomHeaders,
      handleError,
      onRelayQuery,
      refreshToken,
      trackError,
      trackEvent,
    ],
  );
};

type GetDataIdFn = (
  data: Record<string, unknown>,
  typeName: string,
) => string | null;

/** A custom `getDataID` implementation uses Apollo type policy's `keyFields` when available */
const baseGetDataId: GetDataIdFn = (data, typeName) => {
  const typePolicy = typePolicies[typeName as keyof typeof typePolicies];

  if (typePolicy && "keyFields" in typePolicy) {
    if (typePolicy.keyFields === false) {
      return null;
    }

    return typePolicy.keyFields.map((keyField) => data[keyField]).join(":");
  }

  return defaultGetDataID(data, typeName);
};

/**
 * A custom `getDataID` implementation on top of {@linkcode baseGetDataId} that scopes the data ID
 * to the type name. This prevents issues because our GQL reuses IDs across types, which isn't
 * allowed by {@link https://graphql.org/learn/global-object-identification/#node-interface the spec}
 * (at least for Node types) and causes Relay to merge the data incorrectly — the entity changes
 * type, causing places subscribed to the old type to get an empty object
 */
const scopedGetDataId: GetDataIdFn = (data, typeName) => {
  const baseDataId = baseGetDataId(data, typeName);

  if (!baseDataId) {
    return baseDataId;
  }

  return `${typeName}|${baseDataId}`;
};
