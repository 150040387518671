import type { UserPropertiesQuery } from "@/features/Authentication/data/__generated__/queries.generated";
import { atomsWithQuery, selectQueryAtom } from "@/features/Jotai";

export const userPropertiesQueryAtoms = atomsWithQuery<UserPropertiesQuery>({
  suspensePolicy: "initial",
});

export const userPropertiesDataAtom = selectQueryAtom(
  userPropertiesQueryAtoms,
  ({ data }) => data,
);

export const userIdAtom = selectQueryAtom(
  userPropertiesQueryAtoms,
  ({ data }) => data?.user?.id,
);

export const userLanguagePreferenceAtom = selectQueryAtom(
  userPropertiesQueryAtoms,
  ({ data }) => data?.user?.locale?.languageTag,
);
