import React, { useRef } from "react";

/**
 * This component is used to suspend rendering (throw a Promise), so that the parent Suspense can handle it.
 * Useful when doing early returns with loading conditions:
 *  `
 *    if (loading) return <Suspend />;
 *    return <span>Loaded!</span>
 *  `
 */
// eslint-disable-next-line @brexhq/react-memo/require-memo
export function Suspend() {
  const promiseRef = useRef<Promise<void>>(
    new Promise(() => {
      /* no-op */
    }),
  );

  if (promiseRef.current) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw promiseRef.current;
  }

  return <></>;
}
