import { useAtomValue } from "jotai";
import * as React from "react";
import {
  OrganizationResourceAttribute,
  ResourceAction,
} from "@/__generated__/globalTypes";
import { resourceOperationMappingAtom } from "@/domains/App/features/Permissions/atoms/resourceOperationsQueryAtoms";
import { useMockableProductConfig } from "@/hooks/useProductConfig";
import { useSandbox } from "@/modules/Sandbox/hooks/useSandbox";

export const useOrganizationResourceOperations = () => {
  const { organization, user } = useAtomValue(resourceOperationMappingAtom);
  const {
    config: { hrisIntegrationEnabled: empowerHrisEnabled },
  } = useMockableProductConfig();

  const { isInSandboxMode } = useSandbox();

  const hasOrganizationList = React.useMemo(
    () => organization.hasOrganizationResourceOperation(ResourceAction.LIST),
    [organization],
  );

  const hasUserList = React.useMemo(
    () => user.hasUserResourceOperation(ResourceAction.LIST),
    [user],
  );

  const hasOrganizationUpdate = React.useMemo(
    () => organization.hasOrganizationResourceOperation(ResourceAction.UPDATE),
    [organization],
  );

  // Admin users and/or managers can view the Team page
  const canViewTeamPage = hasOrganizationList || hasUserList;

  // Only admin users with Organization.List can view all of the tabs
  const canReadTeamPageOrganizationAttributes = hasOrganizationList;
  const canReadTeamPageCompany = hasOrganizationList && hasUserList;
  const canReadTeamPageDirectReports = hasUserList;
  const canUpdateCompany = hasOrganizationUpdate;

  const canReadHris = React.useMemo(
    () =>
      empowerHrisEnabled &&
      !isInSandboxMode &&
      organization.hasOrganizationResourceOperation(
        ResourceAction.LIST,
        OrganizationResourceAttribute.HRIS,
      ),
    [organization, empowerHrisEnabled, isInSandboxMode],
  );

  const canUpdateHris = React.useMemo(
    () =>
      empowerHrisEnabled &&
      !isInSandboxMode &&
      organization.hasOrganizationResourceOperation(
        ResourceAction.UPDATE,
        OrganizationResourceAttribute.HRIS,
      ),
    [organization, empowerHrisEnabled, isInSandboxMode],
  );

  const canUpdateSSOSettings = React.useMemo(
    () =>
      organization.hasOrganizationResourceOperation(
        ResourceAction.UPDATE,
        OrganizationResourceAttribute.SSO,
      ),
    [organization],
  );

  const canReadAuditLogs = React.useMemo(
    () =>
      organization.hasOrganizationResourceOperation(
        ResourceAction.READ,
        OrganizationResourceAttribute.AUDIT,
      ),
    [organization],
  );

  return {
    canViewTeamPage,
    canReadAuditLogs,
    canReadTeamPageCompany,
    canReadTeamPageOrganizationAttributes,
    canReadTeamPageDirectReports,
    canReadHris,
    canUpdateHris,
    canUpdateCompany,
    canUpdateSSOSettings,
  };
};
