/** Given an object, this utility returns a union of key-value pairs */
type ObjectEntries<T extends Record<string, unknown>> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];

export function pick<T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  // ReadonlyArray allows us to pass `[some, array] as const` as a key array
  keys: ReadonlyArray<K>,
): Pick<T, K>;

export function pick<T extends Record<string, unknown>>(
  obj: T,
  callback: (entry: ObjectEntries<T>) => boolean,
): Partial<T>;

export function pick<T extends Record<string, unknown>>(
  obj: T,
  callbackOrKeys: unknown,
): Partial<T> {
  let callback: ([key, value]: [key: string, value: unknown]) => boolean;
  if (Array.isArray(callbackOrKeys)) {
    callback = ([key]) => callbackOrKeys.includes(key);
  } else if (typeof callbackOrKeys === "function") {
    // @ts-expect-error function type is not exactly what we want
    callback = callbackOrKeys;
  } else {
    // TODO(meyer) throw error here?
    return obj;
  }
  // @ts-expect-error We know better than the typechecker here
  return Object.fromEntries(Object.entries(obj).filter(callback));
}
