import { ApolloQueuing } from "./queueing";
import type {
  Observable,
  Operation,
  NextLink,
  FetchResult,
} from "@/features/Apollo";
import { ApolloLink } from "@/features/Apollo";
import { isOperationExemptFromAuthentication } from "@/features/Apollo/helpers/authExemptRequests";
import { getTokenState } from "@/features/Authentication/helpers/getTokenState";

type HandleError = (err: Error) => void;

type OnExpired = () => Promise<
  "ok" | "redirect" | "noop" | "response" | "delegate"
>;

const refreshLinkError = (message: string): Error =>
  new Error(`[Token Refresh Link]: ${message}`);

// This class assumes that the accessToken exists and is validated before.
// So that by the time the methods are executed, we need to refresh
export class TokenRefreshLink extends ApolloLink {
  private getAccessToken: () => string | null;

  private getIsRefreshingToken: () => boolean;

  private refreshAccessToken: () => Promise<void>;

  private handleError: HandleError;

  private queue: ApolloQueuing;

  private getTokenState: typeof getTokenState;

  private onExpired: OnExpired;

  constructor(params: {
    getAccessToken: () => string | null;
    getIsRefreshingToken: () => boolean;
    handleError: HandleError;
    refreshAccessToken: () => Promise<void>;
    onExpired: OnExpired;
    queue?: ApolloQueuing;
    getTokenState?: typeof getTokenState;
  }) {
    super();
    this.getAccessToken = params.getAccessToken;
    this.getIsRefreshingToken = params.getIsRefreshingToken;
    this.refreshAccessToken = params.refreshAccessToken;
    this.handleError = params.handleError;
    this.queue = params.queue ?? new ApolloQueuing();
    this.onExpired = params.onExpired;
    this.getTokenState = params.getTokenState ?? getTokenState;
  }

  public request(
    operation: Operation,
    forward: NextLink,
  ): Observable<FetchResult> {
    if (typeof forward !== "function") {
      throw refreshLinkError(
        "[Token Refresh Link]: Token Refresh Link is non-terminating link and should not be the last in the composed chain",
      );
    }
    if (isOperationExemptFromAuthentication(operation)) {
      return forward(operation);
    }

    // We only run this token refresh apollo-link in 2 situations:
    //
    // 1. If token exists (If it does not, it could mean that this request does
    //    not need authentication).
    //    However, of the token is suposed to exist, but
    //    is not present, then we will catch it as a 401 error in another
    //    apollo-link, and proceed to logout the user.
    //
    // 2. If the token is valid and not expired (or to be more precise, about to expire)
    //
    // 3. If it's enabled by the feature flag
    const { expired, needRefresh } = this.getTokenState(this.getAccessToken());
    if (expired) {
      // this.isRefreshingToken is a mutex to prevent multiple token-refresh calls. It also
      // allows us to properly enqueue other apollo requests so can re-execute
      // them once token refresh is done
      this.onExpired().catch((e) => {
        this.handleError(e);
      });
    } else if (!needRefresh) {
      return forward(operation);
    } else if (
      // this.isRefreshingToken is a mutex to prevent multiple token-refresh calls. It also
      // allows us to properly enqueue other apollo requests so can re-execute
      // them once token refresh is done
      !this.getIsRefreshingToken()
    ) {
      /**
       * TODO: Check for deadlocks. What happens if the tab that sets
       * isRefreshingToken = true suspends/closes/dies?
       *
       * What's our lock-removal strategy?
       */
      this.refreshAccessToken() // This funtion also deals with updating the context values with the new token
        .catch(this.handleError)
        .finally(() => {
          // Once the request is done, we proceed to process the queue of
          // pending apollo calls
          this.queue.consumeQueue();
        });
    }

    return this.queue.enqueueRequest({
      operation,
      forward,
    });
  }
}
