import { datadogRum } from "@datadog/browser-rum";
import { GraphQLError } from "graphql";
import type { GraphQLSingularResponse, PayloadError } from "relay-runtime";
import type { JsonArray } from "type-fest";
import { ErrorType } from "@/data/sharedTypes";
import { useTrackEvent } from "@/domains/App";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import type { FetchResult } from "@/features/Apollo";
import {
  getIsControllerReady,
  getLoadingControllerNames,
  getRequestTimeDifferenceFromViewControllerMount,
  getRequestTimeDifferencesFromControllerMount,
} from "@/features/Apollo/helpers/performanceLoggingHelpers";
import type {
  GqlContext,
  GqlFetchTimingInfo,
  GqlMetadata,
} from "@/features/Apollo/sharedTypes";
import { isCookieBasedAuth } from "@/features/Authentication/helpers/oauth";
import { transformUnauthenticatedErrorType } from "@/features/Authentication/helpers/transformUnauthenticatedErrorType";
import { useBeginOpenIDAuthorization } from "@/features/Authentication/hooks/useBeginOpenIDAuthorization";
import { useOktaReauthWithToast } from "@/features/StepUpMfa/hooks/useOktaReauthWithToast";
import { useRefCallback } from "@/hooks/useRefCallback";

const extractTraceId = (headers: Headers | undefined) =>
  headers?.get?.("X-Trace-Id");

export type GqlErrorHandler = ReturnType<typeof useGetErrorHandler>;

export const useGetErrorHandler = () => {
  const beginOpenIDAuthorization = useBeginOpenIDAuthorization();
  const track = useTrackEvent();
  const { oktaReauthWithToast } = useOktaReauthWithToast();
  /** @returns Whether or not the retry function was called */
  return useRefCallback(function handleError({
    headers,
    errors,
    rumMetadata,
    statusCode,
    retry,
    failedToParse,
    isApolloServerError,
  }: {
    headers: Headers | undefined;
    statusCode: number;
    errors: readonly (GraphQLError | PayloadError)[] | undefined;
    rumMetadata: GqlMetadata | undefined;
    retry: () => void;
    failedToParse: boolean;
    /**
     * This is a escape hatch to allow us to catch a particular scenario where Apollo throws a
     * ServerError: when the status code is fine, but the body isn't a valid GQL response.
     **/
    isApolloServerError: boolean;
  }) {
    let retried = false;
    if (errors) {
      const traceId = extractTraceId(headers);

      errors.forEach((error) => {
        // extensions is readonly in GraphQLError, so we rule it out before modifying it
        if (!(error instanceof GraphQLError)) {
          error.extensions ??= {};
        }
        // we know extensions is not undefined here because GraphQLError always has it and otherwise we set it
        error.extensions!.traceId = traceId;
      });
    }

    if (statusCode >= 300) {
      const type = errors?.find(
        ({ extensions }) => extensions?.code === "UNAUTHENTICATED",
      )?.extensions?.type;

      const receivedErrorType = type
        ? transformUnauthenticatedErrorType(type)
        : null;
      // For now, we will limit ourselves to tracking just API error-codes on
      // network failures, as well as the metadata related. This mainly as
      // Brex does not not have a standardized way to surface Graphql errros
      // to consumers. Once a decision on the problem is done (this document
      // for reference
      // https://docs.google.com/document/d/1KhiYc_TJo5nqqg9eOuuNqeWF0I1Nnon14aS2ZbpPxS4/edit#)
      // we should modify this function to properly track error information
      // from extensions.
      if (rumMetadata) {
        if (failedToParse) {
          rumMetadata.typeOfError = "serverparse_error";
        } else if (statusCode >= 300 || isApolloServerError) {
          // /\ same condition as Apollo: https://github.com/apollographql/apollo-client/blob/7819586c42bb0758ed9afdb6931f74bfc836963f/src/link/http/parseAndCheckHttpResponse.ts#L137-L151
          rumMetadata.typeOfError = "server_error";
        } else {
          rumMetadata.typeOfError = "error";
        }
        rumMetadata.errorCode = statusCode;
      }
      datadogRum.addAction("APP.graphql.network_error", rumMetadata);

      // authenticated but requires step-up MFA
      if (statusCode === 401) {
        const found = errors?.find(
          (err) =>
            err.extensions?.code === "UNAUTHENTICATED" &&
            err.extensions?.bearerError,
        );

        if (found) {
          oktaReauthWithToast(() => {
            retry();
          }, found.extensions?.bearerError?.acrValues);
          retried = true;
        }
      }

      // un-authenticated
      if (
        statusCode === 401 &&
        receivedErrorType !== ErrorType.LOGIN_ACCOUNT_REJECTED &&
        !isCookieBasedAuth()
      ) {
        track(AllAnalyticsEvents.BeginOpenIDAuthorization);
        void beginOpenIDAuthorization({
          interactive: true,
        });
      }
    }

    return retried;
  });
};

type AnyGqlResponse = GraphQLSingularResponse | FetchResult;

/**
 * @return A function to be called after the request is complete
 */
export const addDatadogRUMGraphqlRequestMetadata = (
  context: GqlContext | undefined,
) => {
  const timeStamp = Date.now();

  const { controllerContext } = context ?? {};
  const { shouldExcludeFromBusyElements, requestId } = context?.metadata ?? {};
  const rumMetadata = context?.metadata;

  if (rumMetadata) {
    datadogRum.addAction("APP.graphql.request", rumMetadata);
  }

  if (!shouldExcludeFromBusyElements && requestId) {
    controllerContext?.addBusyElement(requestId, "request");
  }

  return function addDatadogRUMGraphqlResponseMetadata({
    response,
    headers,
    fetchTimingInfo,
  }: {
    response: AnyGqlResponse;
    headers: Headers | undefined;
    fetchTimingInfo: GqlFetchTimingInfo | undefined;
  }) {
    if (!shouldExcludeFromBusyElements && requestId) {
      controllerContext?.removeBusyElement(requestId, "request");
    }
    const duration = Date.now() - timeStamp;
    const errors = ("errors" in response && response.errors) || [];

    if (rumMetadata) {
      rumMetadata.traceId = extractTraceId(headers);
      rumMetadata.numberOfErrors = errors.length;
      rumMetadata.duration = duration;
      rumMetadata.loadingControllerNames =
        getLoadingControllerNames(controllerContext);
      rumMetadata.isControllerReady = getIsControllerReady(controllerContext);
      rumMetadata.requestFinishTimesRelativeToControllerMounts =
        getRequestTimeDifferencesFromControllerMount(controllerContext);
      rumMetadata.requestFinishTimeRelativeToViewControllerMount =
        getRequestTimeDifferenceFromViewControllerMount(controllerContext);
    }
    errors.forEach((error) => {
      const errorInfo = {
        message: error.message,
        // FIXME: what _is_ error.name for Apollo's GraphQLError? is it the name of the error's
        // class? if so, isn't it always "GraphQLError"?
        name: "name" in error ? error.name : "RelayGraphQLError",
        extensions: error.extensions,
        source:
          "source" in error
            ? {
                body: error.source?.body,
                name: error.source?.name,
              }
            : // FIXME: is that equivalent to Apollo's source /\?
              {
                body: rumMetadata?.queryText,
                name: rumMetadata?.operationName,
              },
        locations: error.locations as JsonArray | undefined,
      };
      rumMetadata?.graphqlErrors?.push?.(errorInfo);
      datadogRum.addAction("APP.graphql.error", {
        ...errorInfo,
        ...rumMetadata,
      });
    });
    datadogRum.addAction("APP.graphql.response", {
      ...rumMetadata,
      //FIXME: [#incident-4454] tracking inner fetch duration from Apollo link.
      linkStartTime: timeStamp,
      innerFetchStartTime: fetchTimingInfo?.innerFetchStartTime,
      innerFetchDuration: fetchTimingInfo?.innerFetchDuration,
      innerParseDuration: fetchTimingInfo?.innerParseDuration,
      innerParseTextCallDuration: fetchTimingInfo?.innerParseTextCallDuration,
      innerParseJsonBodyCallDuration:
        fetchTimingInfo?.innerParseJsonBodyCallDuration,
      linkAndFetchParseDurationDiff:
        duration -
        (fetchTimingInfo?.innerFetchDuration ?? 0) -
        (fetchTimingInfo?.innerParseDuration ?? 0),
    });
  };
};
