import type {
  SardineContextCreationConfig,
  SardineContextObject,
} from "@/domains/App/helpers/types";
import { getEnvironment } from "@/helpers";
import noOp from "@/helpers/noOp";

// Note sessionKey is used for the backend to query analytics results later
// @see https://docs.sardine.ai/web/
export const initSardine = (
  sessionKey: string,
  userIdHash?: string,
): SardineContextObject => {
  const clientId = getEnvironment("SARDINE_CLIENT_ID");
  if (!clientId) {
    throw new Error("SARDINE_CLIENT_ID is missing from env config");
  }

  const config: SardineContextCreationConfig = {
    clientId,
    sessionKey,
    userIdHash,
    flow: window?.location?.pathname,
    environment: getEnvironment("SARDINE_ENVIRONMENT"),
    parentElement: document.getElementById("sardine") || undefined,
    enableBiometrics: true,
  };

  const fallbackNoOp = { updateConfig: noOp };

  if (!window || !window._Sardine) {
    return fallbackNoOp;
  }

  const sardineContextObj = window._Sardine.createContext(config);
  return sardineContextObj || fallbackNoOp;
};
