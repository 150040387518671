import * as React from "react";
import type { ComplianceContextValue } from "@/features/Compliance/sharedTypes";
import noOp from "@/helpers/noOp";

const LazilyInitializedCompliance = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LazilyInitializedComplianceModals" */
      "./LazilyInitializedCompliance"
    ),
);

const defaultContextData = {
  showFirstTimeComplianceMessage: () => false,
  initializeProvider: noOp,
  showInboxComplianceHotspot: false,
  setShowInboxComplianceHotspot: noOp,
  setHideInboxComplianceHotspot: noOp,
};

const ComplianceContext =
  React.createContext<ComplianceContextValue>(defaultContextData);

export const ComplianceProvider: React.FC = ({ children }) => {
  const [contextData, setContextData] =
    React.useState<ComplianceContextValue>(defaultContextData);
  const [showProvider, setShowProvider] = React.useState(false);

  const value = React.useMemo(
    () => ({ ...contextData, initializeProvider: () => setShowProvider(true) }),
    [contextData],
  );

  return (
    <ComplianceContext.Provider value={value}>
      <React.Suspense fallback={null}>
        {showProvider && (
          <LazilyInitializedCompliance onContextChanged={setContextData} />
        )}
      </React.Suspense>
      {children}
    </ComplianceContext.Provider>
  );
};

export const useComplianceModals = () => {
  const { initializeProvider, ...rest } = React.useContext(ComplianceContext);

  React.useEffect(() => {
    initializeProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return rest;
};
