import { FinancialAsset } from "@brexhq/currency-ts";
import type { AutoMemoType } from "@/__generated__/globalTypes";
import {
  BudgetPeriodType,
  BudgetStatus,
  BudgetTemplateType,
  CardBudgetAssignmentMode,
  CardBudgetOwnershipType,
  CardDesign,
} from "@/__generated__/globalTypes";
import { createMockBudgetAmountsGQL } from "@/features/Budgets/helpers/budgetAmounts";
import type {
  DocumentationRequirementAction,
  ReceiptItemizationConfigAction,
} from "@/features/PolicyState/sharedTypes";

export const ADMIN_ONBOARDING_COOKIE_LABEL = "_shouldAlwaysShowAdminOnboarding";

// The following DEMO_PAYLOAD are default value used in the Education step for demo representation purposes only.
// If user doesn't have an existing budget or budget card during the first-time onboarding
// we still want to show them a graphical representation of what it could look like.
export const POLICY_CATEGORIES_MAP_DEMO_PAYLOAD = new Map([
  ["MEALS_ID", "Meals"],
]);
export const POLICY_CARD_SPEND_CONFIG_DEMO_PAYLOAD = {
  receiptConfig: {
    action: "REQUIRED" as DocumentationRequirementAction,
    amountThreshold: {
      quantity: "0.00",
      instrumentCodeString: "USD",
    },
    exceptions: [],
    receiptItemizationConfig: {
      action: "NEVER" as ReceiptItemizationConfigAction,
    },
    userExceptions: [],
  },
  memoConfig: {
    action: "REQUIRED" as DocumentationRequirementAction,
    amountThreshold: {
      quantity: "0.00",
      instrumentCodeString: "USD",
    },
    exceptions: [],
    generateAutoMemoConfig: {
      enabled: false,
      type: "STANDARD" as AutoMemoType,
      customText: "",
    },
    userExceptions: [],
  },
  reviewChainConfig: {
    defaultReviewChainSteps: [],
    amountBasedReviewChains: [],
    exceptions: [],
  },
};
export const BUDGETS_DEMO_PAYLOAD = [
  {
    id: "1",
    name: "[Example] T&E Design",
    description: "",
    status: BudgetStatus.APPROVED,
    periodType: BudgetPeriodType.PER_MONTH,
    endTime: "2050-01-01T02:46:40Z",
    startTime: "2021-01-01T01:23:20Z",
    timezone: "America/Los_Angeles",
    onlyProvisionedCardSpend: false,
    expenseVisibilityShared: false,
    ...createMockBudgetAmountsGQL("USD", 297.35, 500),
    spendableLimit: new FinancialAsset("500", "USD"),
    currentPeriodSpent: new FinancialAsset("297.35", "USD"),
    currentBudgetPeriodSpentByUser: new FinancialAsset("297.35", "USD"),
  },
  {
    id: "2",
    name: "[Example] WFH Setup",
    description: "",
    onlyProvisionedCardSpend: false,
    status: BudgetStatus.APPROVED,
    periodType: BudgetPeriodType.PER_MONTH,
    endTime: "2050-01-01T02:46:40Z",
    startTime: "2021-01-01T01:23:20Z",
    timezone: "America/Los_Angeles",
    expenseVisibilityShared: false,
    ...createMockBudgetAmountsGQL("USD", 1267, 1500),
    spendableLimit: new FinancialAsset("1500", "USD"),
    currentPeriodSpent: new FinancialAsset("1267.00", "USD"),
    currentBudgetPeriodSpentByUser: new FinancialAsset("1267.00", "USD"),
  },
];
export const BUDGET_SELECTOR_DEMO_PAYLOAD = {
  id: "",
  isCardInactive: false,
  ownerId: "",
  displayName: "",
  ownerName: "",
  last4: "",
  cardBudgetAssignmentMode: CardBudgetAssignmentMode.MANUAL,
  cardBudgetOwnershipType: CardBudgetOwnershipType.BUDGET,
  budget: {
    template: {
      id: "",
      name: "",
      type: BudgetTemplateType.OTHER,
    },
    hasMoreCards: false,
    cards: [],
  },
};
export const CREDIT_CARD_UI_DEMO_PAYLOAD = {
  id: "",
  holderName: "",
  statusView: null,
  design: CardDesign.BUDGET,
  cvv: "•••",
  expiration: "••••",
  branding: null,
};
