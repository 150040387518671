import type { ThemeOverride } from "@brexhq/metal-theming";
import {
  generateBrandColors,
  generateDarkBrandColors,
} from "@/features/CustomThemeBranding/helpers/generateBrandColors";

export const generateCustomThemes = (
  primaryColor: string,
  primaryColorDarkMode = primaryColor,
): Required<ThemeOverride> => {
  const updatedLightTheme: ThemeOverride["light"] = {
    colors: {
      accent: {
        primary: {
          background: generateBrandColors(primaryColor),
        },
      },
    },
  };

  const updatedDarkTheme: ThemeOverride["dark"] = {
    colors: {
      accent: {
        primary: {
          background: generateDarkBrandColors(primaryColorDarkMode),
        },
      },
    },
  };

  return { light: updatedLightTheme, dark: updatedDarkTheme };
};
