import gql from "graphql-tag";

export const policyActionFragment = gql`
  fragment PolicyActionFragment on PolicyActionSet {
    requireAttendees {
      requireCompanyName
      requireIsGovernmentOfficial
      requireTitle
    }
    requireExtendedFields {
      field {
        id
        editableBy
        group
      }
    }
  }
`;

export const policyRulesFragment = gql`
  fragment PolicyRulesFragment on PolicyRuleSet {
    metadata {
      ruleSetKind
    }
    rules {
      actions {
        ...PolicyActionFragment
      }
    }
  }
  ${policyActionFragment}
`;

export const extendedMerchantCategoryFragment = gql`
  fragment ExtendedMerchantCategoryFragment on ExtendedMerchantCategory {
    __typename
    ... on MerchantCategory {
      description
      id
      name
    }
    ... on CompoundMerchantCategory {
      categoryType
      includedCategories {
        description
        id
        name
      }
    }
    ... on ExpenseCategory {
      id
      # this field is aliased because otherwise the type conflicts with MerchantCategory name (String! vs String)
      expenseCategoryName: name
    }
  }
`;

export const categoryAmountLimitRulesFragment = gql`
  fragment CategoryAmountLimitRulesFragment on ExpensePolicyCategoryAmountLimitRules {
    amountLimit
    attendeesRequired {
      requireCompanyName
      requireIsGovernmentOfficial
      requireTitle
    }
    # this field is for the airbnb demo
    attendeesReviewRules {
      shouldFlagManagerAsAttendee
      shouldRunBriberyCheck
    }
    limitType
  }
`;

export const exceptionScopeFragment = gql`
  fragment ExceptionScopeFragment on PolicyExceptionScope {
    costCenters {
      id
      displayName
    }
    departments {
      id
      name
    }
    locations {
      id
      name
    }
    legalEntities {
      id
      displayName
    }
    titles {
      id
      displayTitle
    }
    users {
      id
      displayName
      email
    }
  }
`;

export const expensePolicyMerchantAndCategoryRulesFragment = gql`
  fragment ExpensePolicyMerchantAndCategoryRulesFragment on ExpensePolicyMerchantAndCategoryRules {
    allOthers
    merchantRules {
      __typename
      ... on ExpensePolicyAllowMerchant {
        amountLimit
        name
        notes
        exceptions {
          amountLimit
          notes
          exceptionScope {
            ...ExceptionScopeFragment
          }
        }
      }
      ... on ExpensePolicyBlockMerchant {
        name
        notes
      }
      ... on ExpensePolicyReviewMerchant {
        name
        notes
      }
    }
    categoryRules {
      __typename
      ... on ExpensePolicyAllowCategory {
        amountLimits {
          ...CategoryAmountLimitRulesFragment
        }
        category {
          ...ExtendedMerchantCategoryFragment
        }
        merchantExceptions {
          allOthers
          merchantExceptions {
            name
            reviewAction
          }
        }
        notes
        exceptions {
          amountLimits {
            ...CategoryAmountLimitRulesFragment
          }
          notes
          exceptionScope {
            ...ExceptionScopeFragment
          }
        }
      }
      ... on ExpensePolicyBlockCategory {
        category {
          ...ExtendedMerchantCategoryFragment
        }
        notes
      }
      ... on ExpensePolicyReviewCategory {
        category {
          ...ExtendedMerchantCategoryFragment
        }
        notes
      }
    }
  }
  ${extendedMerchantCategoryFragment}
  ${categoryAmountLimitRulesFragment}
  ${exceptionScopeFragment}
`;

export const expensePolicyLodgingDailyRateRulesFragment = gql`
  fragment ExpensePolicyLodgingDailyRateRulesFragment on ExpensePolicyLodgingDailyRateRules {
    allOthers
    locationRules {
      city
      countryCode
      googlePlacesId
      maxDailyRate
      stateCode
    }
  }
`;

export const expensePolicyMaxCabinClassRulesFragment = gql`
  fragment ExpensePolicyMaxCabinClassRulesFragment on ExpensePolicyMaxCabinClassRules {
    __typename
    ... on ExpensePolicyMaxCabinClassByDestinationTypeRules {
      domestic {
        allOthers
        durationRules {
          maxCabinClass
          maxDurationHours
        }
      }
      international {
        allOthers
        durationRules {
          maxCabinClass
          maxDurationHours
        }
      }
    }
    ... on ExpensePolicyMaxCabinClassByFlightDurationRules {
      allOthers
      durationRules {
        maxCabinClass
        maxDurationHours
      }
    }
  }
`;

export const expensePolicyAmountLimitByFlightDurationRulesFragment = gql`
  fragment ExpensePolicyAmountLimitByFlightDurationRulesFragment on ExpensePolicyAmountLimitByFlightDurationRules {
    allOthers
    durationRules {
      amountLimit
      maxDurationHours
    }
  }
`;

export const expensePolicyMaxRailClassByJourneyDurationRulesFragment = gql`
  fragment ExpensePolicyMaxRailClassByJourneyDurationRulesFragment on ExpensePolicyMaxRailClassByJourneyDurationRules {
    allOthers
    durationRules {
      maxDurationHours
      maxRailClass
    }
  }
`;

export const approvalChainReviewerSetFragment = gql`
  fragment ApprovalChainReviewerSetFragment on PolicyActionTarget {
    hierarchiesHierarchyLevelId
    managerLevelDifference
    requiredUserRole
    budgetRole
    customerUser {
      id
      customerUserId
      displayName
      role
      email
    }
  }
`;

export const approvalChainRuleFragment = gql`
  fragment ApprovalChainRuleFragment on ExpensePolicyApprovalChainRule {
    minAmount
    steps {
      reviews {
        reviewerSet {
          ...ApprovalChainReviewerSetFragment
        }
      }
    }
  }
  ${approvalChainReviewerSetFragment}
`;

export const expensePolicyBrexTravelRulesFragment = gql`
  fragment ExpensePolicyBrexTravelRulesFragment on ExpensePolicyBrexTravelRules {
    carRental {
      allowedClasses
      bookingApprovalType
      maxDailyRate
      exceptions {
        allowedClasses
        bookingApprovalType
        maxDailyRate
        exceptionScope {
          ...ExceptionScopeFragment
        }
      }
    }
    lodging {
      bookingApprovalType
      dailyRateRules {
        ...ExpensePolicyLodgingDailyRateRulesFragment
      }
      maxStars
      minDaysInAdvance
      exceptions {
        bookingApprovalType
        dailyRateRules {
          ...ExpensePolicyLodgingDailyRateRulesFragment
        }
        maxStars
        minDaysInAdvance
        exceptionScope {
          ...ExceptionScopeFragment
        }
      }
    }
    airline {
      allowedCabinClasses {
        ...ExpensePolicyMaxCabinClassRulesFragment
      }
      amountLimitsByDuration {
        ...ExpensePolicyAmountLimitByFlightDurationRulesFragment
      }
      bookingApprovalType
      maxPercentAboveMedianPrice
      maxPercentAboveLowestLogicalFare
      minDaysInAdvance
      ticketChangeability
      ticketRefundability
      exceptions {
        allowedCabinClasses {
          ...ExpensePolicyMaxCabinClassRulesFragment
        }
        amountLimitsByDuration {
          ...ExpensePolicyAmountLimitByFlightDurationRulesFragment
        }
        bookingApprovalType
        maxPercentAboveMedianPrice
        maxPercentAboveLowestLogicalFare
        minDaysInAdvance
        ticketChangeability
        ticketRefundability
        exceptionScope {
          ...ExceptionScopeFragment
        }
      }
    }
    rail {
      minDaysInAdvance
      amountLimitsByDuration {
        ...ExpensePolicyAmountLimitByFlightDurationRulesFragment
      }
      allowedRailClassesByDuration {
        ...ExpensePolicyMaxRailClassByJourneyDurationRulesFragment
      }
      ticketRefundability
      exceptions {
        minDaysInAdvance
        ticketRefundability
        amountLimitsByDuration {
          ...ExpensePolicyAmountLimitByFlightDurationRulesFragment
        }
        allowedRailClassesByDuration {
          ...ExpensePolicyMaxRailClassByJourneyDurationRulesFragment
        }
        exceptionScope {
          ...ExceptionScopeFragment
        }
      }
    }
    defaultApprovalChain {
      reviews {
        reviewerSet {
          ...ApprovalChainReviewerSetFragment
        }
      }
    }
  }
  ${expensePolicyLodgingDailyRateRulesFragment}
  ${expensePolicyMaxCabinClassRulesFragment}
  ${expensePolicyAmountLimitByFlightDurationRulesFragment}
  ${expensePolicyMaxRailClassByJourneyDurationRulesFragment}
  ${exceptionScopeFragment}
  ${approvalChainReviewerSetFragment}
`;

export const expensePolicyReceiptReviewRulesFragment = gql`
  fragment ExpensePolicyReceiptReviewRulesFragment on ReceiptReviewRules {
    receiptReviewByItemCategory {
      lineItemCategories
    }
  }
`;

export const expenseTypeSpecificRulesFragment = gql`
  fragment ExpenseTypeSpecificRulesFragment on ExpensePolicyExpenseTypeSpecificRules {
    generateAutoMemo {
      autoMemoType
      customText
    }
    memos {
      requiredAbove
      merchantExceptions {
        name
        requiredAbove
      }
      categoryExceptions {
        category {
          ...ExtendedMerchantCategoryFragment
        }
        requiredAbove
      }
      exceptions {
        requiredAbove
        merchantExceptions {
          name
          requiredAbove
        }
        categoryExceptions {
          category {
            ...ExtendedMerchantCategoryFragment
          }
          requiredAbove
        }
        exceptionScope {
          ...ExceptionScopeFragment
        }
      }
    }
    receipts {
      receiptsRequired {
        requiredAbove
        itemizedRequirement
      }
      merchantExceptions {
        name
        requiredAbove
        itemizationRequirement
      }
      categoryExceptions {
        category {
          ...ExtendedMerchantCategoryFragment
        }
        requiredAbove
        itemizationRequirement
      }
      exceptions {
        receiptsRequired {
          requiredAbove
          itemizedRequirement
        }
        merchantExceptions {
          name
          requiredAbove
          itemizationRequirement
        }
        categoryExceptions {
          category {
            ...ExtendedMerchantCategoryFragment
          }
          requiredAbove
          itemizationRequirement
        }
        exceptionScope {
          ...ExceptionScopeFragment
        }
      }
    }
    defaultApprovalChain {
      reviews {
        reviewerSet {
          ...ApprovalChainReviewerSetFragment
        }
      }
    }
    approvalChains {
      ...ApprovalChainRuleFragment
    }
    approvalChainsExceptions {
      defaultApprovalChain {
        reviews {
          reviewerSet {
            ...ApprovalChainReviewerSetFragment
          }
        }
      }
      approvalChains {
        ...ApprovalChainRuleFragment
      }
      exceptionScope {
        ...ExceptionScopeFragment
      }
    }
  }
  ${extendedMerchantCategoryFragment}
  ${approvalChainRuleFragment}
  ${approvalChainReviewerSetFragment}
  ${exceptionScopeFragment}
`;

export const policyDefinitionFragment = gql`
  fragment PolicyDefinitionFragment on ExpensePolicy {
    vatRequirement
    expenseCategoryRequirement
    reimbursement {
      ...ExpenseTypeSpecificRulesFragment
    }
    card {
      ...ExpenseTypeSpecificRulesFragment
    }
    merchantAndCategory {
      ...ExpensePolicyMerchantAndCategoryRulesFragment
    }
    brexTravel {
      ...ExpensePolicyBrexTravelRulesFragment
    }
    receiptReview {
      ...ExpensePolicyReceiptReviewRulesFragment
    }
    billPay {
      approvalChains {
        ...ApprovalChainRuleFragment
      }
      invoices {
        requiredAbove
      }
      memos {
        requiredAbove
      }
    }
  }
  ${expenseTypeSpecificRulesFragment}
  ${expensePolicyMerchantAndCategoryRulesFragment}
  ${expensePolicyBrexTravelRulesFragment}
  ${expensePolicyReceiptReviewRulesFragment}
  ${approvalChainRuleFragment}
`;

export const budgetMerchantCategoryConfigFragment = gql`
  fragment BudgetMerchantCategoryConfigFragment on MerchantCategoryConfig {
    controlReference {
      __typename
      ... on MerchantCategoryIdControlReference {
        __typename
        merchantCategoryId
        merchantCategoryName
      }
      ... on MerchantNameControlReference {
        __typename
        merchantName
        categoryId
        categoryName
      }
      ... on MerchantCategoryCodeControlReference {
        __typename
        merchantCategoryCode
        merchantCategoryCodeDescription
      }
    }
    controlType
  }
`;

export const inheritedMerchantCategoryControlsFragment = gql`
  fragment InheritedMerchantCategoryControlsFragment on MerchantCategoryControls {
    currentBudgetConfigs {
      ...BudgetMerchantCategoryConfigFragment
    }
    globalBudgetConfigs {
      ...BudgetMerchantCategoryConfigFragment
    }
  }

  ${budgetMerchantCategoryConfigFragment}
`;

export const policyDocumentFragment = gql`
  fragment PolicyDocumentFragment on Policy {
    id
    status
    displayName
    description
    currency
    eligibleWorkflows
    isDefault
    rules {
      ...PolicyRulesFragment
    }
    definition(rulesFilter: ONLY_RELEVANT_FOR_REQUESTER) {
      ...PolicyDefinitionFragment
    }
  }
  ${policyRulesFragment}
  ${policyDefinitionFragment}
`;
