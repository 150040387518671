import deepEqual from "fast-deep-equal";
import * as React from "react";

/**
 * Memoizes `value` such that the reference returned only updates when `value`
 * is not deeply equal to its prior value.
 *
 * This is useful for literal data structures (e.g., simple objects and arrays)
 * where it is important to retain referntial equality when the contents of the
 * data structure are the same.
 *
 * This function can be used as a normal hook `useDeepMemo`, or simply as
 * `deep` within another hook's dependency list.
 *
 * Note that unlike `useMemo`, `value` is the raw value, not a generator
 * function.
 */
export const useDeepMemo = <T>(value: T) => {
  const ref = React.useRef(value);
  if (!deepEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

/**
 * This is a convenience function that enables you to easily `useDeepMemo` for
 * a dependency to another hook, like such:
 *
 * ```
 * const value = useMemo(() => {
 *   // something expensive
 * }, [userId, deep(options)])
 * ```
 *
 * There are many cases where a dependency to another hook needs to be deeply
 * compared to maintain referential integrity, and wrapping it with `deep` is a
 * bit more terse an convenient than using the drawn out `useDeepMemo` inline.
 *
 * Because it is a hook, it only can be used where hooks are valid; i.e, within
 * a React component.
 */
export const deep = useDeepMemo;
