import gql from "graphql-tag";

export type {
  TurnOnSeenFlag,
  TurnOnSeenFlagVariables,
  TurnOffSeenFlag,
  TurnOffSeenFlagVariables,
} from "./__generated__/mutations.generated";

export const turnOnSeenFlag = gql`
  mutation TurnOnSeenFlag($input: TurnOnSeenFlagInput!) {
    turnOnSeenFlag(input: $input) {
      clientMutationId
      seenFlag {
        value
        id
        key
        lastSeenAt
      }
    }
  }
`;

export const turnOffSeenFlag = gql`
  mutation TurnOffSeenFlag($input: TurnOffSeenFlagInput!) {
    turnOffSeenFlag(input: $input) {
      clientMutationId
      seenFlag {
        value
        id
        key
        lastSeenAt
      }
    }
  }
`;
