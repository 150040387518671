import { parseISO } from "date-fns";
import type { SeenFlagKey } from "@/__generated__/globalTypes";
import type { SeenFlagsQuery } from "@/features/SeenFlag/data/__generated__/queries.generated";
import type { SeenFlags, SeenFlagValue } from "@/features/SeenFlag/sharedTypes";

export const selectSeenFlag = (
  data: SeenFlagsQuery | undefined,
  key: SeenFlagKey,
) => {
  const seenFlag = (data?.user?.seenFlags ?? []).find(
    (flag) => flag?.key === key,
  );
  return {
    value: !!seenFlag?.value,
    lastSeenAt: seenFlag?.lastSeenAt
      ? parseISO(seenFlag.lastSeenAt)
      : undefined,
  };
};

export const selectSeenFlags = (data: SeenFlagsQuery | undefined) =>
  (data?.user?.seenFlags ?? []).reduce(
    (map, seenFlag) =>
      seenFlag
        ? {
            ...map,
            [seenFlag.key]: {
              value: !!seenFlag.value,
              lastSeenAt: seenFlag.lastSeenAt
                ? parseISO(seenFlag.lastSeenAt)
                : undefined,
            },
          }
        : map,
    {} as SeenFlags,
  );

export const getSeenFlagsMap = (data: SeenFlagsQuery | undefined) =>
  (data?.user?.seenFlags ?? []).reduce(
    (map: Map<SeenFlagKey, SeenFlagValue>, seenFlag) => {
      if (seenFlag) {
        map.set(seenFlag.key, {
          value: !!seenFlag.value,
          lastSeenAt: seenFlag.lastSeenAt
            ? parseISO(seenFlag.lastSeenAt)
            : undefined,
        });
      }
      return map;
    },
    new Map<SeenFlagKey, SeenFlagValue>(),
  );
