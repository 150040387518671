import type { ProductSurfaceArea } from "@/features/PolicyState/sharedTypes";

export enum PolicyAnalyticsEvents {
  // Policy - Details pane
  PolicyComponentPolicyDetailsDocumentMounted = "policy.component.policy_details_document.mounted",

  // Policy - Components
  PolicyDetailsPaneEditPolicyClicked = "policy.details_pane.edit_policy.clicked",
  PolicyDetailsPaneActionClicked = "policy.details_pane.action.clicked",
  PolicyDetailsPaneActionFailure = "policy.details_pane.action.failure",
}

export type PolicyDetailsDocumentPayload = {
  location: ProductSurfaceArea;
  policyId?: string;
  budgetId?: string;
  templateId?: string;
};

type PolicyDetailsDocumentActionPayload = PolicyDetailsDocumentPayload & {
  action: "EDIT" | "ARCHIVE" | "UNARCHIVE" | "DUPLICATE" | "SET_DEFAULT";
};

export type PolicyEventSchemaMap = {
  [PolicyAnalyticsEvents.PolicyComponentPolicyDetailsDocumentMounted]: PolicyDetailsDocumentPayload;
  [PolicyAnalyticsEvents.PolicyDetailsPaneEditPolicyClicked]: PolicyDetailsDocumentPayload;
  [PolicyAnalyticsEvents.PolicyDetailsPaneActionClicked]: PolicyDetailsDocumentActionPayload;
  [PolicyAnalyticsEvents.PolicyDetailsPaneActionFailure]: PolicyDetailsDocumentActionPayload;
};
