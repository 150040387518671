import type { ApolloError } from "@/features/Apollo";

export enum KycControllerAnalyticsEvents {
  KycCheckForKycError = "kyc.checkForKyc.error",
  KycControllerPersonaViewed = "kyc.persona.viewed",
  KycControllerPersonaCancelled = "kyc.persona.cancelled",
  KycControllerPersonaCompleted = "kyc.persona.completed",
  KycControllerPersonaError = "kyc.persona.error",
  KycControllerPersonaEvent = "kyc.persona.event",
  KycMutationSuccess = "kyc.mutation.success",
  KycMutationError = "kyc.mutation.error",
}

type EmptyPayload = {};

type KycControllerPersonaErrorPayload = {
  error: string;
};

type KycCheckForKycErrorPayload = { error: ApolloError };

type KycMutationPayload = {
  mutation: string;
  error?: ApolloError;
};

export type KycControllerEventSchemaMap = {
  [KycControllerAnalyticsEvents.KycCheckForKycError]: KycCheckForKycErrorPayload;
  [KycControllerAnalyticsEvents.KycControllerPersonaViewed]: EmptyPayload;
  [KycControllerAnalyticsEvents.KycControllerPersonaCancelled]: EmptyPayload;
  [KycControllerAnalyticsEvents.KycControllerPersonaCompleted]: EmptyPayload;
  [KycControllerAnalyticsEvents.KycControllerPersonaError]: KycControllerPersonaErrorPayload;
  [KycControllerAnalyticsEvents.KycControllerPersonaEvent]: any;
  [KycControllerAnalyticsEvents.KycMutationSuccess]: KycMutationPayload;
  [KycControllerAnalyticsEvents.KycMutationError]: KycMutationPayload;
};
