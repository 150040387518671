import type { UpsellType } from "@/domains/App/features/Settings/features/DeadlineSettings/sharedTypes";

export enum ComplianceAnalyticsEvents {
  FirstTimeComplianceMessageShown = "compliance.first_time_compliance_message.shown",
  ComplianceRatingBannerMounted = "compliance.rating_banner.mounted",
  ComplianceRatingBannerClicked = "compliance.rating_banner.clicked",
  ComplianceRatingBannerRatingChanged = "compliance.rating_banner.rating_changed",
  ComplianceRatingDetailPageMounted = "compliance.rating_detail_page.mounted",
  ComplianceSettingsPageMounted = "compliance.rating_settings_page.mounted",
  ComplainceRatingSettingsToggleClicked = "compliance.rating_settings_toggle.clicked",
  DeadlineSettingFormConfirmButtonClicked = "deadline_setting_form.confirm_button.clicked",
  DeadlineSettingFormDismissed = "deadline_setting_form.dismissed",
  DeadlineSettingFormViewed = "deadline_setting_form.viewed",
  DeadlineSettingFormDisableButtonClicked = "deadline_setting_form.disable_button.clicked",
}

type EmptyPayload = {};

type ComplianceRatingPayload = {
  cohort: string;
};

type ComplianceSettingsPageMountedPayload = {
  isComplianceRatingEnabled: boolean;
};

type ComplianceRatingSettingsTogglePayload = {
  enabled: boolean;
};

type ComplianceRatingChangedPayload = {
  newCohort?: string;
  prevCohort?: string;
};

/**
 * This Payload is used for when the user presses "Confirm" on the Deadline Setting Form.
 * "prev" refers to what the user's settings were prior to pressing "Confirm".
 * "curr" refers to what the user changed them to.
 */
type DeadlineSettingFormPayload = {
  origin: "ALL_EXPENSES" | "EXPENSES_SETTINGS";
  prevDocDeadlinesEnabled?: boolean;
  prevDocDeadlinesDate?: string | null;
  prevReviewerDeadlinesEnabled: boolean | undefined;
  prevReviewerDeadlinesDate: string | null;
  currDocDeadlinesEnabled?: boolean;
  currDocDeadlinesDate?: string;
  currReviewerDeadlinesEnabled: boolean | undefined;
  currReviewerDeadlinesDate: string | null;
};

type DeadlineSettingFormDismissedPayload = {
  origin: "ALL_EXPENSES" | "EXPENSES_SETTINGS";
  upsellType: UpsellType | undefined;
};

type DeadlineSettingFormViewedPayload = {
  origin: "ALL_EXPENSES" | "EXPENSES_SETTINGS";
};

type DeadlineSettingFormDisablePayload = {
  origin: "EXPENSES_SETTINGS";
};

export type ComplianceEventSchemaMap = {
  [ComplianceAnalyticsEvents.FirstTimeComplianceMessageShown]: EmptyPayload;
  [ComplianceAnalyticsEvents.ComplianceRatingBannerMounted]: EmptyPayload;
  [ComplianceAnalyticsEvents.ComplianceRatingBannerClicked]: EmptyPayload;
  [ComplianceAnalyticsEvents.ComplianceRatingBannerRatingChanged]: ComplianceRatingChangedPayload;
  [ComplianceAnalyticsEvents.ComplianceRatingDetailPageMounted]: ComplianceRatingPayload;
  [ComplianceAnalyticsEvents.ComplianceSettingsPageMounted]: ComplianceSettingsPageMountedPayload;
  [ComplianceAnalyticsEvents.ComplainceRatingSettingsToggleClicked]: ComplianceRatingSettingsTogglePayload;
  [ComplianceAnalyticsEvents.DeadlineSettingFormConfirmButtonClicked]: DeadlineSettingFormPayload;
  [ComplianceAnalyticsEvents.DeadlineSettingFormDismissed]: DeadlineSettingFormDismissedPayload;
  [ComplianceAnalyticsEvents.DeadlineSettingFormViewed]: DeadlineSettingFormViewedPayload;
  [ComplianceAnalyticsEvents.DeadlineSettingFormDisableButtonClicked]: DeadlineSettingFormDisablePayload;
};
