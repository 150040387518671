import { atom } from "jotai";
import type { PermissionsQuery } from "@/domains/App/features/Permissions/data/__generated__/queries.generated";
import { selectPermissions } from "@/domains/App/features/Permissions/selectors/selectPermissions";
import type { Permissions } from "@/domains/App/features/Permissions/selectors/selectPermissions";
import { userPropertiesQueryAtoms } from "@/features/Authentication/atoms/userPropertiesQueryAtoms";
import { getDelegatorUserIdAtom } from "@/features/Copilot/atoms";
import { selectQueryAtomWithSuspense } from "@/features/Jotai";
import { selectSandboxQueryAtom } from "@/modules/Sandbox/atoms/sandboxQueryAtoms";

const isInCopilotModeAtom = atom((get) => {
  const getDelegatorUserId = get(getDelegatorUserIdAtom);

  return !!getDelegatorUserId();
});

export const permissionsQueryDataAtom = selectQueryAtomWithSuspense(
  userPropertiesQueryAtoms,
  ({ data }) => data,
);

const permissionsOverrideAtom = atom<Permissions | undefined>(undefined);

export const permissionsForDelegatorAtom = atom<PermissionsQuery | undefined>(
  undefined,
);

export const permissionsAtom = atom(
  async (get) => {
    const override = get(permissionsOverrideAtom);
    if (override) {
      return override;
    }

    const delegatorPermissions = get(permissionsForDelegatorAtom);
    const permissionsDataFromUserProperties = await get(
      permissionsQueryDataAtom,
    );

    // If the user is in copilot mode, we want to use the permissions of the user they're copiloting for.
    // Otherwise, we can just use the permissions from the user properties query.
    const data = delegatorPermissions ?? permissionsDataFromUserProperties;

    const isInCopilotMode = get(isInCopilotModeAtom);
    const { isDemo } = get(selectSandboxQueryAtom);

    return selectPermissions({
      data,
      isInCopilotMode,
      isDemoAccount: isDemo,
      userRole: permissionsDataFromUserProperties.user?.userRole,
    });
  },
  (get, set, nextValue: Permissions) => {
    set(permissionsOverrideAtom, nextValue);
  },
);
