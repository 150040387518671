import * as React from "react";
import type { AdaContextValue } from "@/features/Ada/sharedTypes";
import noOp from "@/helpers/noOp";

export const defaultData = {
  contactSupport: noOp,
  contactSupportOnLoad: noOp,
  identify: noOp,
  isHelpshiftReady: false,
  isAdaReady: false,
  initializeProvider: noOp,
  setCustomIssueFields: noOp,
  updateConfig: noOp,
  setShowLauncher: () => Promise.resolve(),
  setCustomStyles: noOp,
};
export const AdaContext = React.createContext<AdaContextValue>(defaultData);
