export type TravelSupportClient = {
  isInitialized: boolean;
  openChat: () => Promise<void>;
  dismissChat: () => Promise<void>;
  initialize: () => Promise<void>;
  clear: () => Promise<void>;
};

/**
 * Types sent by the Spotnana
 */
export type TwilioConfigState = {
  isMobile?: boolean;
  serverUrl?: string;
  webUrl?: string;
  companyId?: string;
  token?: string;
  impersonationToken?: string;
  shouldShowMinimizeBtn?: boolean;
  shouldHideOtherTravelerReservation?: boolean;
  theme?: {
    isLight?: boolean;
  };
};

export enum TwilioChatEvent {
  Open = "open",
  Close = "close",
  Minimize = "minimize",
  NewMessage = "newMessage",
  UnReadMsgUpdate = "unReadMsgUpdate",
}

type UnsubscribeFn = () => void;

declare global {
  interface Window {
    Twilio: {
      initWebchat: (config: TwilioConfigState) => void;
      show: () => void;
      hide: () => void;
      clear: () => void;
      on: (
        event: TwilioChatEvent,
        handler: (ev: CustomEvent<number> | CustomEvent) => void,
      ) => UnsubscribeFn;
    };
  }
}
