import * as React from "react";
import {
  FreshchatContext,
  defaultData,
} from "@/features/Freshchat/contexts/FreshchatContext";
import type { FreshchatContextValue } from "@/features/Freshchat/sharedTypes";

const FreshchatChatStyles = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FreshchatChatStyles" */
      "@/features/Freshchat/components/FreshchatChatStyles"
    ),
);

const LazilyInitializedFreshchatProvider = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LazilyInitializedFreshchatProvider" */
      "@/features/Freshchat/contexts/LazilyInitializedFreshchatProvider"
    ),
);

export const FreshchatProvider: React.FC = ({ children }) => {
  const [freshchatContextData, setFreshchatContextData] =
    React.useState<FreshchatContextValue>(defaultData);
  const [showProvider, setShowProvider] = React.useState(false);

  const value = React.useMemo(
    () => ({
      ...freshchatContextData,
      initializeProvider: () => setShowProvider(true),
    }),
    [freshchatContextData],
  );
  return (
    <FreshchatContext.Provider value={value}>
      <React.Suspense fallback={null}>
        <FreshchatChatStyles />
      </React.Suspense>
      <React.Suspense fallback={null}>
        {showProvider && (
          <LazilyInitializedFreshchatProvider
            onContextChange={setFreshchatContextData}
          />
        )}
      </React.Suspense>
      {children}
    </FreshchatContext.Provider>
  );
};
