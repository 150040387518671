import * as React from "react";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "@/features/Analytics/contexts/Analytics";
import { useQualtricsIframeReload } from "@/features/CustomerFeedback/subFeatures/Qualtrics/hooks/useQualtricsIframeReload";
import { getEnvironment } from "@/helpers";
import { getPersistedStateValue } from "@/hooks/useLocalPersistedState";
import { LocalPersistedStateKeys } from "@/hooks/useLocalPersistedState/types";

/**
 * Note (kristin - 1/24/22)
 * Anything that watch location should go here to prevent the app content from
 * continuously re-rendering. Do not combine this with <App />
 */
export const AppLocationWatcher: React.VFC = () => {
  const { trackPage } = useAnalytics();
  const [lastTrackedPathname, setLastTrackedPathname] = React.useState("");
  const location = useLocation();
  useQualtricsIframeReload();

  // track page views
  React.useEffect(() => {
    if (
      getEnvironment("APP_ENV") !== "prod" &&
      getPersistedStateValue(
        LocalPersistedStateKeys.DEV_NAVIGATION_LOGGING,
        false,
      )
    ) {
      // eslint-disable-next-line no-console
      console.info(`%c Location changed`, "color: limegreen", location);
    }
    // track page views outside of development, and avoiding tracking if only
    // search/query params change
    if (
      getEnvironment("NODE_ENV") !== "development" &&
      location.pathname !== lastTrackedPathname
    ) {
      trackPage();
      setLastTrackedPathname(location.pathname);
    }
  }, [location, trackPage, lastTrackedPathname, setLastTrackedPathname]);

  return <></>;
};
