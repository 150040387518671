import * as React from "react";
import { AnnouncementsProvider } from "@/domains/App/components/Announcements/AnnouncementsContext";
import { ScrollToTop } from "@/domains/App/components/ScrollToTop";
import { SardineContextProvider } from "@/domains/App/contexts/Sardine";
import { UnsavedChangesProvider } from "@/domains/App/contexts/UnsavedChangesProvider";
import { Authorization } from "@/domains/App/features/Authorization";
import { MainLayoutProvider } from "@/domains/App/features/MainLayout/contexts/MainLayoutContext";
import PermissionsController from "@/domains/App/features/Permissions/PermissionsController";
import { ProductConfigPreloader } from "@/domains/App/features/ProductConfig";
import { ChatBubble } from "@/features/ChatBubble/components/ChatBubble";
import { HasSeenSpotlightProvider } from "@/features/Spotlight/contexts/HasSeenSpotlightContext";
import { SupportHubProvider } from "@/features/SupportHub/contexts/SupportHubContext";
import TravelSupportController from "@/features/TravelSupport/TravelSupportController";

const Spotlight = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Spotlight" */
      "@/features/Spotlight/SpotlightController"
    ),
);
const ReceiptMatchingNotificationController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReceiptsMatchingNotification" */
      "@/domains/Primitives/features/ReceiptEasyUpload/controllers/ReceiptMatchingNotificationController"
    ),
);
const AuthenticatedRouter = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AuthenticatedRouter" */
      /* webpackPreload: true */
      "@/domains/App/components/AuthenticatedRouter"
    ),
);

const SurveyContainerController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AsyncSurveyPopoverController" */
      /* webpackPreload: true */
      "@/features/CustomerFeedback/subFeatures/Qualtrics/controllers/SurveyContainerController"
    ),
);

const AuthenticatedContexts: React.FC = () => (
  <PermissionsController>
    <MainLayoutProvider>
      <SupportHubProvider>
        <Authorization>
          <ProductConfigPreloader>
            <SardineContextProvider>
              <HasSeenSpotlightProvider>
                <React.Suspense fallback={null}>
                  <Spotlight />
                </React.Suspense>
                <React.Suspense fallback={null}>
                  <ReceiptMatchingNotificationController />
                </React.Suspense>
                <SurveyContainerController />
                <AnnouncementsProvider>
                  <UnsavedChangesProvider>
                    {/* Scroll to top of window on location change */}
                    <ScrollToTop />
                    <ChatBubble />
                    <TravelSupportController />

                    <AuthenticatedRouter />
                  </UnsavedChangesProvider>
                </AnnouncementsProvider>
              </HasSeenSpotlightProvider>
            </SardineContextProvider>
          </ProductConfigPreloader>
        </Authorization>
      </SupportHubProvider>
    </MainLayoutProvider>
  </PermissionsController>
);

export default React.memo(AuthenticatedContexts);
