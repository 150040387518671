import { datadogRum } from "@datadog/browser-rum";
import { useAtom, useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { errorAtom } from "@/domains/App/features/Skeleton/atoms/error";
import { mountedAtom } from "@/domains/App/features/Skeleton/atoms/mounted";
import { SkeletonErrorBoundary } from "@/domains/App/features/Skeleton/components/SkeletonErrorBoundary";

export const SkeletonErrorHandler: React.FC = ({ children }) => {
  const mounted = useAtomValue(mountedAtom);
  const [skeletonError, setError] = useAtom(errorAtom);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (skeletonError && !hasError && !mounted) {
      setHasError(true);

      const { error, info, trackOrigin, ...extras } = skeletonError;

      datadogRum.addAction("APP.skeleton.error", {
        errorName: error.message,
        reactComponentStack: info.componentStack,
        trackOrigin,
        ...extras,
      });
    }
  }, [hasError, mounted, skeletonError]);

  return (
    <SkeletonErrorBoundary onError={setError}>{children}</SkeletonErrorBoundary>
  );
};
