import type { BillOrigin } from "@/__generated__/globalTypes";
import type {
  BillPayIntegrationErrorPayload,
  BillPayIntegrationPayload,
  BillPayResyncPayload,
} from "@/domains/Cash/features/AccountsPayable/sharedTypes";
import type { BillPayOnboardingSteps } from "@/domains/Primitives/features/BillPayOnboarding/sharedTypes";

export enum AnalyticsEvents {
  BillPayAssetDownloaded = "bills.asset.downloaded",
  BillPayAssetLightboxViewed = "bills.asset_lightbox.viewed",
  BillPayBulkVendorsUploadTooltipClicked = "bills.vendors.bulk_vendor_upload_tooltip.clicked",
  BillPayCreateBillMutationFailure = "bills.create_bill_mutation.failure",
  BillPayDefaultErpCategorySaved = "bills.default_erp_category.saved",
  BillPayDeleteBillDraftAttempt = "bills.confirm_delete_draft.clicked",
  BillPayDeleteBillDraftFailure = "bills.confirm_delete_draft.failure",
  BillPayDeleteBillDraftSuccess = "bills.confirm_delete_draft.success",
  BillPayDeleteDraftClicked = "bills.delete_draft.clicked",
  BillPayDetailsPaneContactClicked = "bills.draft_bill_details_contact.clicked",
  BillPayDiscardChangesClicked = "bills.pay_discard_changes.clicked",
  BillPayDraftSaved = "bills.pay_draft.saved",
  BillPayEditPaymentDetailsClicked = "bills.pay.edit_payment_details.clicked",
  BillPayEmailForwardingEducationalModalFailed = "bills.email_forwarding_educational_modal.failed",
  BillPayEmailForwardingEducationalModalMounted = "bills.email_forwarding_educational_modal.mounted",
  BillPayExitClicked = "bills.pay_exit.clicked",
  BillPayGetBillQueryFailure = "bills.get_bill_query.failure",
  BillPayInvoiceScanCancelled = "bills.pay.invoice_scan.cancelled",
  BillPayInvoiceScanError = "bills.pay.invoice_scan.error",
  BillPayInvoiceScanSuccess = "bills.pay.invoice_scan.success",
  BillPayInvoiceScanTimeout = "bills.pay.invoice_scan.timeout",
  BillPayInvoiceUploaded = "bills.pay.invoice.uploaded",
  BillPayMutationBillSyncDisable = "bills.mutation_bill_sync.disable",
  BillPayMutationBillSyncEnable = "bills.mutation_bill_sync.enable",
  BillPayMutationBillSyncError = "bills.mutation_bill_sync.error",
  BillPayOnboardingBulkInviteUsersSubmitted = "bill.onboarding_bulk_invite_users.submitted",
  BillPayOnboardingSetupStepSubmitted = "bill.onboarding_setup_step.submitted",
  BillPayOnboardingSetupStepViewed = "bill.onboarding_setup_step.viewed",
  BillPayOnboardingWelcomeModalCtaClicked = "bills.onboarding_welcome_modal_cta.clicked",
  BillPayOnboardingWelcomeModalShown = "bills.onboarding_welcome_modal.shown",
  BillPayPaymentScheduled = "bills.pay_payment.scheduled",
  BillPayRecurringFilter = "bills.recurring_filter.clicked",
  BillPayRecurringPaymentClicked = "bills.pay_recurring_payment.clicked",
  BillPayReviewClicked = "bills.pay.review.clicked",
  BillPayReviewPageMounted = "bills.pay_review_page.mounted",
  BillPaySaveAsDraftClicked = "bills.pay_save_as_draft.clicked",
  BillPaySaveChangesClicked = "bills.pay_save_changes.clicked",
  BillPaySchedulePaymentClicked = "bills.pay_schedule_payment.clicked",
  BillPaySendBillPaymentMutationFailure = "bills.send_bill_payment_mutation.failure",
  BillPaySendDuplicateVendorModalDismissModalButtonClicked = "bills.pay_duplicate_vendor_modal_dismiss_modal.clicked",
  BillPaySendDuplicateVendorModalUseExistingButtonClicked = "bills.pay_duplicate_vendor_modal_use_existing.clicked",
  BillPaySettingsClicked = "bills.settings.clicked",
  BillPaySettingsEditPolicyClicked = "bills.settings-edit-policy.clicked",
  BillPaySettingsL2Clicked = "bills.settings_l2.clicked",
  BillPayStaleSyncBannerErrorMounted = "bills.detail_stale_sync_error_banner_error.mounted",
  BillPayStaleSyncBannerMounted = "bills.detail_stale_sync_error_banner.mounted",
  BillPayStaleSyncBannerResyncFailure = "bills.detail_stale_sync_error_banner_resync.failure",
  BillPayStaleSyncBannerResyncSuccess = "bills.detail_stale_sync_error_banner_resync.success",
  BillPayUpdateBillMutationFailure = "bills.update_bill_mutation.failure",
  BillPayUploadInvoiceCtaClicked = "bills.upload_invoice_cta.clicked",
  BillPayUploadInvoiceFailure = "bills.upload_invoice.failure",
  BillPayUploadInvoiceMaxFilesError = "bills.upload_invoice_modal_max_files.error",
  BillPayUploadInvoiceModalMounted = "bills.upload_invoice_modal.mounted",
  BillPayUploadInvoiceSuccess = "bills.upload_invoice.success",
  BillPayUserAccessTooltipCtaClicked = "bills.user_access_tooltip.clicked",
  BillsDetailGLCategoryUpdateFailure = "bills.detail_gl_category_update.failure",
  BillsDetailGLCategoryUpdateSuccess = "bills.detail_gl_category_update.success",
  BillsVendorNotificationCheckboxToggled = "bills.vendor_notification_checkbox.toggled",

  // Events used in Empower BP
  BillPayPayBillClicked = "bills.pay_bill.clicked",
  BillPayFormMounted = "bills.form.mounted",
  BillPayFormSubmitted = "bills.form.submitted",
  BillPayFormInvoiceProcessingStart = "bills.form.invoice_processing_start",
  BillPayFormInvoiceProcessingFinish = "bills.form.invoice_processing_finish",
  BillPaySubmitBillMutationSuccess = "bills.submit_bill_mutation.success",
  BillPaySubmitBillMutationFailure = "bills.submit_bill_mutation.failure",
  BillPayFormSaveVendorStart = "bills.form.save-contact.start",
  BillPayFormSaveVendorFinish = "bills.form.save-contact.finish",
  BillBulkUploadInvoiceUploadStart = "bills.bulk_upload_invoice.upload_start",
  BillBulkUploadInvoiceUploadFinish = "bills.bulk_upload_invoice.upload_finish",
  BillBulkUploadInvoiceProcessingStart = "bills.bulk_upload_invoice.processing_start",
  BillBulkUploadInvoiceProcessingFinish = "bills.bulk_upload_invoice.processing_finish",
}

type EmptyPayload = {};

type BillIdPayload = {
  billId?: string;
};

type BillContactIdPayload = {
  contactId?: string;
};

type BillIdOrNullPayload = {
  billId: string | null;
};

type BillPayFormMountedPayload = {
  billId?: string | null;
  formEntrypoint?: string;
  origin?: BillOrigin;
  isUsingNewOcrFlow: boolean;
  isDataforms: boolean;
};

type BillPayAssetPayload = {
  billId: string | null;
  assetId?: string;
};

type BillPayInvoiceUploadedPayload = {
  billId: string | null;
  response: boolean;
};

type BillPaySettingsL2ClickedPayload = {
  billSettingsL2?: string;
};

type BillPayErrorPayload = {
  fileName?: string;
  message?: string;
};

type BillPayDefaultErpCategoryPayload = {
  defaultCategoryId?: string;
  billCategoryId: string;
  shouldUpdateDefaultCategory: boolean;
  defaultErpPaymentAccountId: string;
  billErpPaymentAccountId: string;
  shouldUpdateDefaultErpPaymentAccount: boolean;
  defaultErpApAccountId: string;
  billErpApAccountId: string;
  shouldUpdateDefaultErpApAccount: boolean;
  billId: string | null;
};

type BillPayRecurringFilterPayload = {
  isRecurring: boolean;
};

type BillPayRecurringPaymentClickedPayload = BillIdOrNullPayload &
  BillPayRecurringFilterPayload;

type BillPayUploadInvoiceMaxFilesErrorPayload = {
  numberOfFiles: number;
};

type TransactionsAPIPayload = {
  transactionsApiVersion: 1 | 2;
};

type BillPayOnboardingSetupPayload = {
  step: BillPayOnboardingSteps;
  label?: string;
};

type BillPayOnboardingBulkInviteUsers = {
  numberInvited: number;
};

type BillPaySubmitBillMutationSuccessPayload = {
  billId: string;
  canBeAutoApproved: boolean;
  autoApproved: boolean;
  timeToSubmitBill: number;
  origin: BillOrigin;
  usedOcr: boolean;
  numberOfApprovalsRequired: number;
  isUsingNewOcrFlow: boolean;
  formEntrypoint?: string;
  isDataforms: boolean;
};

type BillPaySubmitBillMutationFailurePayload = {
  name: string;
  message: string;
};

type BillPayFormSubmittedPayload = {
  billId: string;
  origin: BillOrigin;
  timeToFinishForm: number;
  touchedFormInputs: string[];
  numberOfTouchedInputs: number;
  submitCount: number;
  usedOcr: boolean;
  isDataforms: boolean;
  isUsingNewOcrFlow: boolean;
  formEntrypoint?: string;
  // analytics used for #proj-decoupling-limits-from-expenses
  isSpendLimitRequired: boolean;
  hasSpendLimit: boolean;
  updateDefaultBudget: boolean | undefined;

  // Form has errors:
  inputsWithErrors?: string[];
  numberOfErrors: number;
};

type BillPayFormInvoiceProcessingStartPayload = {
  assetId?: string;
  isUsingNewOcrFlow: boolean;
};

type BillPayFormInvoiceProcessingFinishedPayload = {
  timeToFinishUpload: number;
  timeToFinishOcr: number;
  timeToFinishProcessing: number;
  error?: string;
  assetId?: string;
  isUsingNewOcrFlow: boolean;
};

type BillPayFormSaveVendorStartPayload = {
  vendorId?: string;
  vendorName?: string;
  createVendorMutationRequired: boolean;
};

type BillPayFormSaveVendorFinishPayload = {
  vendorId?: string;
  vendorVersion?: string;
  isVendorSaved: boolean;
};

type BillBulkUploadInvoiceUploadFinishPayload = {
  numberOfSucessfullyUploadedFiles: number;
  numberOfFailedUploadedFiles: number;
};

type BillBulkUploadInvoiceProcessingFinishPayload = {
  numberOfSucessfullyProcessedFiles: number;
  numberOfTimedOutProcessedFiles: number;
};

export type EventSchemaMap = {
  [AnalyticsEvents.BillPayAssetDownloaded]: BillPayAssetPayload;
  [AnalyticsEvents.BillPayAssetLightboxViewed]: BillPayAssetPayload;
  [AnalyticsEvents.BillPayBulkVendorsUploadTooltipClicked]: EmptyPayload;
  [AnalyticsEvents.BillPayCreateBillMutationFailure]: TransactionsAPIPayload;
  [AnalyticsEvents.BillPayDefaultErpCategorySaved]: BillPayDefaultErpCategoryPayload;
  [AnalyticsEvents.BillPayDeleteBillDraftAttempt]: BillIdPayload;
  [AnalyticsEvents.BillPayDeleteBillDraftFailure]: BillIdPayload;
  [AnalyticsEvents.BillPayDeleteBillDraftSuccess]: BillIdPayload;
  [AnalyticsEvents.BillPayDeleteDraftClicked]: BillIdPayload;
  [AnalyticsEvents.BillPayDetailsPaneContactClicked]: BillContactIdPayload;
  [AnalyticsEvents.BillPayDiscardChangesClicked]: BillIdOrNullPayload;
  [AnalyticsEvents.BillPayDraftSaved]: BillIdPayload;
  [AnalyticsEvents.BillPayEditPaymentDetailsClicked]: BillIdOrNullPayload;
  [AnalyticsEvents.BillPayEmailForwardingEducationalModalFailed]: EmptyPayload;
  [AnalyticsEvents.BillPayEmailForwardingEducationalModalMounted]: EmptyPayload;
  [AnalyticsEvents.BillPayExitClicked]: BillIdOrNullPayload;
  [AnalyticsEvents.BillPayGetBillQueryFailure]: TransactionsAPIPayload;
  [AnalyticsEvents.BillPayInvoiceScanCancelled]: BillIdPayload;
  [AnalyticsEvents.BillPayInvoiceScanError]: BillIdPayload;
  [AnalyticsEvents.BillPayInvoiceScanSuccess]: BillIdPayload;
  [AnalyticsEvents.BillPayInvoiceScanTimeout]: BillIdPayload;
  [AnalyticsEvents.BillPayInvoiceUploaded]: BillPayInvoiceUploadedPayload;
  [AnalyticsEvents.BillPayMutationBillSyncDisable]: BillPayIntegrationPayload;
  [AnalyticsEvents.BillPayMutationBillSyncEnable]: BillPayIntegrationPayload;
  [AnalyticsEvents.BillPayMutationBillSyncError]: BillPayIntegrationErrorPayload;
  [AnalyticsEvents.BillPayOnboardingBulkInviteUsersSubmitted]: BillPayOnboardingBulkInviteUsers;
  [AnalyticsEvents.BillPayOnboardingSetupStepSubmitted]: BillPayOnboardingSetupPayload;
  [AnalyticsEvents.BillPayOnboardingSetupStepViewed]: BillPayOnboardingSetupPayload;
  [AnalyticsEvents.BillPayOnboardingWelcomeModalCtaClicked]: EmptyPayload;
  [AnalyticsEvents.BillPayOnboardingWelcomeModalShown]: EmptyPayload;
  [AnalyticsEvents.BillPayPaymentScheduled]: BillIdPayload;
  [AnalyticsEvents.BillPayRecurringFilter]: BillPayRecurringFilterPayload;
  [AnalyticsEvents.BillPayRecurringPaymentClicked]: BillPayRecurringPaymentClickedPayload;
  [AnalyticsEvents.BillPayReviewClicked]: BillIdOrNullPayload;
  [AnalyticsEvents.BillPayReviewPageMounted]: BillPayFormMountedPayload;
  [AnalyticsEvents.BillPaySaveAsDraftClicked]: BillIdOrNullPayload;
  [AnalyticsEvents.BillPaySaveChangesClicked]: BillIdOrNullPayload;
  [AnalyticsEvents.BillPaySchedulePaymentClicked]: BillIdOrNullPayload;
  [AnalyticsEvents.BillPaySendBillPaymentMutationFailure]: TransactionsAPIPayload;
  [AnalyticsEvents.BillPaySendDuplicateVendorModalDismissModalButtonClicked]: EmptyPayload;
  [AnalyticsEvents.BillPaySendDuplicateVendorModalUseExistingButtonClicked]: EmptyPayload;
  [AnalyticsEvents.BillPaySettingsClicked]: EmptyPayload;
  [AnalyticsEvents.BillPaySettingsEditPolicyClicked]: EmptyPayload;
  [AnalyticsEvents.BillPaySettingsL2Clicked]: BillPaySettingsL2ClickedPayload;
  [AnalyticsEvents.BillPayStaleSyncBannerErrorMounted]: BillPayErrorPayload;
  [AnalyticsEvents.BillPayStaleSyncBannerMounted]: BillIdPayload;
  [AnalyticsEvents.BillPayStaleSyncBannerResyncFailure]: BillPayResyncPayload;
  [AnalyticsEvents.BillPayStaleSyncBannerResyncSuccess]: BillPayResyncPayload;
  [AnalyticsEvents.BillPayUpdateBillMutationFailure]: TransactionsAPIPayload;
  [AnalyticsEvents.BillPayUploadInvoiceCtaClicked]: EmptyPayload;
  [AnalyticsEvents.BillPayUploadInvoiceFailure]: BillPayErrorPayload;
  [AnalyticsEvents.BillPayUploadInvoiceMaxFilesError]: BillPayUploadInvoiceMaxFilesErrorPayload;
  [AnalyticsEvents.BillPayUploadInvoiceModalMounted]: EmptyPayload;
  [AnalyticsEvents.BillPayUploadInvoiceSuccess]: EmptyPayload;
  [AnalyticsEvents.BillPayUserAccessTooltipCtaClicked]: EmptyPayload;
  [AnalyticsEvents.BillsDetailGLCategoryUpdateFailure]: BillIdPayload;
  [AnalyticsEvents.BillsDetailGLCategoryUpdateSuccess]: BillIdPayload;
  [AnalyticsEvents.BillsVendorNotificationCheckboxToggled]: EmptyPayload;
  [AnalyticsEvents.BillPayGetBillQueryFailure]: TransactionsAPIPayload;
  [AnalyticsEvents.BillPaySendBillPaymentMutationFailure]: TransactionsAPIPayload;
  [AnalyticsEvents.BillPayCreateBillMutationFailure]: TransactionsAPIPayload;
  [AnalyticsEvents.BillPayUpdateBillMutationFailure]: TransactionsAPIPayload;

  // Events used in Empower BP
  [AnalyticsEvents.BillPayPayBillClicked]: EmptyPayload;
  [AnalyticsEvents.BillPayFormMounted]: BillPayFormMountedPayload;
  [AnalyticsEvents.BillPayFormInvoiceProcessingStart]: BillPayFormInvoiceProcessingStartPayload;
  [AnalyticsEvents.BillPayFormInvoiceProcessingFinish]: BillPayFormInvoiceProcessingFinishedPayload;
  [AnalyticsEvents.BillPaySubmitBillMutationSuccess]: BillPaySubmitBillMutationSuccessPayload;
  [AnalyticsEvents.BillPaySubmitBillMutationFailure]: BillPaySubmitBillMutationFailurePayload;
  [AnalyticsEvents.BillPayFormSubmitted]: BillPayFormSubmittedPayload;
  [AnalyticsEvents.BillPayFormSaveVendorStart]: BillPayFormSaveVendorStartPayload;
  [AnalyticsEvents.BillPayFormSaveVendorFinish]: BillPayFormSaveVendorFinishPayload;
  [AnalyticsEvents.BillBulkUploadInvoiceUploadStart]: EmptyPayload;
  [AnalyticsEvents.BillBulkUploadInvoiceProcessingStart]: EmptyPayload;
  [AnalyticsEvents.BillBulkUploadInvoiceUploadFinish]: BillBulkUploadInvoiceUploadFinishPayload;
  [AnalyticsEvents.BillBulkUploadInvoiceProcessingFinish]: BillBulkUploadInvoiceProcessingFinishPayload;
};
