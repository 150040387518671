import * as React from "react";
import { createMockableHook } from "@/helpers/createMockableHook";
import { useBifurcatedBudgetFeatureFlags } from "@/hooks/useBifurcatedBudgetFeatureFlags";
import { useMockableProductConfigDevOverrides } from "@/hooks/useProductConfigDevOverrides";
import { useMockableProductConfigQuery } from "@/hooks/useProductConfigQuery";
import { selectProductConfig } from "@/selectors/selectProductConfig";
import { selectProductConfigEntitlements } from "@/selectors/selectProductConfigEntitlements";

/**
 * useProductConfig is the source of truth for managing feature entitlements on Empower.
 *
 * See the {@link https://brexhq.atlassian.net/wiki/spaces/ENGDOC/pages/1991376906/Product+Config+-+Full+User+Guide User Guide} for usage guidance.
 *
 * See the {@link https://docs.google.com/spreadsheets/d/1OskEkQTS9yA8Aad4_Vn1uTtxUYksTrr5kxPnyPMXUVU/edit#gid=106029065 Empower Capabilities Breakdown} for the source of truth.
 *
 * When adding a new entitlement, please add a description to the Proxy for future reader comprehension.
 */
const useProductConfig = () => {
  const {
    data: productConfigData,
    loading,
    error,
  } = useMockableProductConfigQuery();

  const isBifurcated = useBifurcatedBudgetFeatureFlags();

  const {
    multiEntityEnabled,
    advancedExpenseManagementEnabled,
    internationalReimbursementsEnabled,
    basicExpenseManagementEnabled,
    accountingCustomFieldsEnabled,
    accountingFieldValueVisibilityEnabled,
    accountingCustomRulesEnabled,
    advancedAccountingIntegrationsEnabled,
    hrisIntegrationEnabled,
    memoSuggestionsEnabled,
    budgetProgramsEnabled,
    advancedBudgetTemplatesEnabled,
    customBudgetTemplatesEnabled,
    budgetNestingEnabled,
    delegatedBudgetManagementEnabled,
    budgetRequestsEnabled,
    advancedPolicyCustomizationEnabled,
    deadlinesEnabled,
    vatTrackingEnabled,
    enterpriseErpIntegrationsEnabled,
    complianceReportingEnabled,
    advancedAndCustomRolesPermissionsEnabled,
    advancedBudgetRestrictionsEnabled,
    advancedBudgetControlsEnabled,
    payableBillAccess,
    payablePaymentEbaCheck,
    payablePaymentEbaDomesticAch,
    payablePaymentEbaDomesticWire,
    payablePaymentEbaInternationalAch,
    payablePaymentEbaInternationalWire,
    procurementPurchaseOrdersEnabled,
    aiAssistedReviewEnabled,
    brexAssistantEnabled,
    basicReimbursementsEnabled,
    customReimbursementsApprovalChainsEnabled,
    complianceRatingEnabled,
    travelAccessManagementEnabled,
    customCardExpensesApprovalChainsEnabled,
    premiumSupportEnabled,
    bulkBudgetManagementEnabled,
    bulkInviteEnabled,
    aiSmartFilteringEnabled,
    multipleCurrencyPolicyEnabled,
    addEntitiesUnlimitedEnabled,
    addEntitiesRestrictedEnabled,
    locallyBilledCountriesUnlimitedEnabled,
    locallyBilledCountriesLimitedEnabled,
    locallyBilledCountriesRestrictedEnabled,
    usdBillingEnabled,
    accountingSuggestionsEnabled,
    accountingAccrualDateEnabled,
    entitySpendLimitOverrideControlsEnabled,
    auditLogsEnabled,
    autoInviteSettingsEnabled,
    spendLimitsAdvancedTargetingEnabled,
    userLimitEnabled,
    advancedApprovalChainsEnabled,
    walletAccessEnabled,
    metricsAccessEnabled,
    cardsAndLimitsAccessEnabled,
    reportsAccessEnabled,
    accountingAccessEnabled,
    globalSearchEnabled,
    copilotEnabled,
    accountSetupTasksEnabled,
  } = selectProductConfig(productConfigData);

  const {
    travelTripsEntitled,
    basicReimbursementsEntitled,
    payableBillAccessEntitled,
  } = selectProductConfigEntitlements(productConfigData);

  const { overrides: devOverrides } = useMockableProductConfigDevOverrides();

  // Special logic - for bifurcated Essentials, always enable budgetRequests.
  // This FE override is necessary until mobile is able to handle Essentials budget requests, and then we can enable budget requests on the BE for
  // everyone and remove this logic
  const forceEnableBudgetRequests = !budgetNestingEnabled && isBifurcated;

  return React.useMemo(() => {
    const config = new Proxy(
      {
        /**
         * [AVOID USE] - Legacy flag - Multi entity /Legal entity on Empower Full (paid)
         */
        multiEntityEnabled,
        /**
         * [AVOID USE] - Legacy flag - Advanced expense management features like Mileage on Empower Full (paid)
         */
        advancedExpenseManagementEnabled,
        /**
         * Global reimbursements (USD and non-USD funded) Allow employees to request reimbursements to intl bank accounts
         */
        internationalReimbursementsEnabled,
        /**
         * Enables basic expense management features
         */
        basicExpenseManagementEnabled,
        /**
         * Custom accounting fields set-up and employee editing
         */
        accountingCustomFieldsEnabled,
        /**
         * Enables users to customize accounting fields values visibility
         */
        accountingFieldValueVisibilityEnabled,
        /**
         * Advanced accounting mapping rules (customized rules, expense types + addendums + automation)
         *
         * Includes accounts that are grandfathered to use custom rules for empower light
         * Grandfathered accounts found in {@link https://app.launchdarkly.com/default/production/segments/custom-rules-grandfathered/targeting LaunchDarkly}
         */
        accountingCustomRulesEnabled,
        /**
         * [AVOID USE] - use useReimbursementsEnabled instead because that hook
         * takes entitlements and other information into account
         */
        basicReimbursementsEnabled,
        /**
         * Customized reimbursement approval chain policies enabled
         */
        customReimbursementsApprovalChainsEnabled,
        /**
         * Accounting Integrations: Netsuite, Quickbooks Desktop
         */
        advancedAccountingIntegrationsEnabled,
        /**
         * Integration with HRIS to with automated budget and policy provisioning rules for new users
         */
        hrisIntegrationEnabled,
        /**
         * Auto generated suggestions for expense memos
         */
        memoSuggestionsEnabled,
        /**
         * Budget programs (automated budget provisioning rules)
         */
        budgetProgramsEnabled,
        /**
         * Advanced budget templates for spend / travel workflows Eg: Stipend, Travel, procurement templates
         */
        advancedBudgetTemplatesEnabled,
        /**
         * Custom budget templates, including customized templates
         */
        customBudgetTemplatesEnabled,
        /**
         * Budget nesting / budget hierarchy / parent budgets
         */
        budgetNestingEnabled,
        /**
         * Delegated Budget management (selecting a budget owner)
         */
        delegatedBudgetManagementEnabled,
        /**
         * Request a new budget
         */

        budgetRequestsEnabled:
          budgetRequestsEnabled || forceEnableBudgetRequests,
        /**
         * Advanced policy customization (eg per diem, hotel dailies, purchase categories)
         */
        advancedPolicyCustomizationEnabled,
        /**
         * Compliance Deadlines
         */
        deadlinesEnabled,
        /**
         * Automated VAT Tracking
         */
        vatTrackingEnabled,
        /**
         * Enterprise ERP Integrations: Workday, Fusion, D365, SAP, Coupa Expense, Microsoft Dynamics
         */
        enterpriseErpIntegrationsEnabled,
        /**
         * Compliance Reporting
         */
        complianceReportingEnabled,
        /**
         * Advanced/Custom Roles & Permissions
         */
        advancedAndCustomRolesPermissionsEnabled,
        /**
         * Budget advanced restrictions (e.g., budget card only spend)
         */
        advancedBudgetRestrictionsEnabled,
        /**
         * The settings grouped under "Advanced budget controls" in the budget create/edit flow
         */
        advancedBudgetControlsEnabled,
        /**
         * Bill pay on Empower
         */
        payableBillAccess,
        /**
         * Bill pay on Empower - EBA (external bank account) payments using Check rail
         */
        payablePaymentEbaCheck,
        /**
         * Bill pay on Empower - EBA (external bank account) payments using Domestic ACH rail
         */
        payablePaymentEbaDomesticAch,
        /**
         * Bill pay on Empower - EBA (external bank account) payments using International ACH rail
         */
        payablePaymentEbaInternationalAch,
        /**
         * Bill pay on Empower - EBA (external bank account) payments using Domestic Wire rail
         */
        payablePaymentEbaDomesticWire,
        /**
         * Bill pay on Empower - EBA (external bank account) payments using International Wire rail
         */
        payablePaymentEbaInternationalWire,
        /**
         * Procurement Purchase Orders - Enables the ability to attach a purchase order to a bill
         */
        procurementPurchaseOrdersEnabled,
        /**
         * AI Assisted Review
         */
        aiAssistedReviewEnabled,
        /**
         * Brex Assistant
         */
        brexAssistantEnabled,
        /**
         * Compliance Rating user-facing feature, allows users to see a score on how compliant they
         * are being with expenses
         */
        complianceRatingEnabled,
        /**
         * Enables admins to manage travel access for other users
         */
        travelAccessManagementEnabled,
        /**
         * Customized card expense approval chain policies enabled
         */
        customCardExpensesApprovalChainsEnabled,
        /**
         * Access to premium support
         */
        premiumSupportEnabled,
        /**
         * Access to bulk budget management
         */
        bulkBudgetManagementEnabled,
        /**
         * Access to bulk invite
         */
        bulkInviteEnabled,
        /**
         * Access to create rules to automatically invite employees added to Brex
         */
        autoInviteSettingsEnabled,
        /**
         * AI Smart Filtering
         */
        aiSmartFilteringEnabled,
        /**
         * Multiple currency support for budgets and templates
         */
        multipleCurrencyPolicyEnabled,
        /**
         * Customer allowed to add unlimited # of non-primary entities
         */
        addEntitiesUnlimitedEnabled,
        /**
         * Customer allowed to add 1 non-primary entity
         */
        addEntitiesRestrictedEnabled,
        /**
         * Customer allowed to bill unlimited # of countries in local currency
         */
        locallyBilledCountriesUnlimitedEnabled,
        /**
         * Customer allowed to bill a limited # of countries in local currency before overage fees apply
         */
        locallyBilledCountriesLimitedEnabled,
        /**
         * Customer allowed to bill 1 country in local currency
         */
        locallyBilledCountriesRestrictedEnabled,
        /**
         * Customer allowed to bill unlimited # of entities in USD
         */
        usdBillingEnabled,
        /**
         * Enables bulk triggered AJ suggestions, AI powered suggestions in AJ, and AI powered suggestions in FTUX mappings
         */
        accountingSuggestionsEnabled,
        /**
         * Enables viewing and editing accrual date of expenses
         */
        accountingAccrualDateEnabled,
        /**
         * Enables Entity spend limit override controls
         */
        entitySpendLimitOverrideControlsEnabled,
        /**
         * Access to audit trail page
         */
        auditLogsEnabled,
        /**
         * Enables advanced group configuration for spend limit membership using user groups
         */
        spendLimitsAdvancedTargetingEnabled,
        /**
         * Creates a user limit upon invitation to Brex
         */
        userLimitEnabled,
        /**
         * Enables users to use approval chains advanced features like auto approve and nested chains
         */
        advancedApprovalChainsEnabled,
        /**
         * Enables wallet tab
         */
        walletAccessEnabled,
        /**
         * Enables metrics tab
         */
        metricsAccessEnabled,
        /**
         * Enables cards and limits tab
         */
        cardsAndLimitsAccessEnabled,
        /**
         * Enables reports tab
         */
        reportsAccessEnabled,
        /**
         * Enables accounting tab
         */
        accountingAccessEnabled,
        /**
         * Enables global search
         */
        globalSearchEnabled,
        /**
         * Enables copilot
         */
        copilotEnabled,
        /**
         * Enables account setup tasks (ITP, AMS)
         */
        accountSetupTasksEnabled,
      },
      {
        get: (configObj, key: string) =>
          devOverrides?.[key] ?? configObj[key as keyof typeof configObj],
      },
    );

    const entitlementConfig = new Proxy(
      {
        /**
         * Entitled to Basic reimbursements including product enablement item on Plans page
         */
        basicReimbursementsEntitled,
        /**
         * Entitled to Bills including product enablement item on Plans page
         */
        payableBillAccessEntitled,
        /**
         * Entitled to Basic travel including product enablement item on Plans page
         */
        travelTripsEntitled,
      },
      {
        get: (configObj, key: string) =>
          devOverrides?.[key] ?? configObj[key as keyof typeof configObj],
      },
    );

    return {
      loading,
      error,
      config,
      entitlementConfig,
    };
  }, [
    multiEntityEnabled,
    advancedExpenseManagementEnabled,
    internationalReimbursementsEnabled,
    basicExpenseManagementEnabled,
    accountingCustomFieldsEnabled,
    accountingFieldValueVisibilityEnabled,
    accountingCustomRulesEnabled,
    basicReimbursementsEnabled,
    customReimbursementsApprovalChainsEnabled,
    advancedAccountingIntegrationsEnabled,
    hrisIntegrationEnabled,
    memoSuggestionsEnabled,
    budgetProgramsEnabled,
    advancedBudgetTemplatesEnabled,
    customBudgetTemplatesEnabled,
    budgetNestingEnabled,
    delegatedBudgetManagementEnabled,
    budgetRequestsEnabled,
    forceEnableBudgetRequests,
    advancedPolicyCustomizationEnabled,
    deadlinesEnabled,
    vatTrackingEnabled,
    enterpriseErpIntegrationsEnabled,
    complianceReportingEnabled,
    advancedAndCustomRolesPermissionsEnabled,
    advancedBudgetRestrictionsEnabled,
    advancedBudgetControlsEnabled,
    payableBillAccess,
    payablePaymentEbaCheck,
    payablePaymentEbaDomesticAch,
    payablePaymentEbaInternationalAch,
    payablePaymentEbaDomesticWire,
    payablePaymentEbaInternationalWire,
    procurementPurchaseOrdersEnabled,
    aiAssistedReviewEnabled,
    brexAssistantEnabled,
    complianceRatingEnabled,
    travelAccessManagementEnabled,
    customCardExpensesApprovalChainsEnabled,
    premiumSupportEnabled,
    bulkBudgetManagementEnabled,
    bulkInviteEnabled,
    autoInviteSettingsEnabled,
    aiSmartFilteringEnabled,
    multipleCurrencyPolicyEnabled,
    addEntitiesUnlimitedEnabled,
    addEntitiesRestrictedEnabled,
    locallyBilledCountriesUnlimitedEnabled,
    locallyBilledCountriesLimitedEnabled,
    locallyBilledCountriesRestrictedEnabled,
    usdBillingEnabled,
    accountingSuggestionsEnabled,
    accountingAccrualDateEnabled,
    entitySpendLimitOverrideControlsEnabled,
    auditLogsEnabled,
    spendLimitsAdvancedTargetingEnabled,
    userLimitEnabled,
    advancedApprovalChainsEnabled,
    walletAccessEnabled,
    metricsAccessEnabled,
    cardsAndLimitsAccessEnabled,
    reportsAccessEnabled,
    accountingAccessEnabled,
    globalSearchEnabled,
    copilotEnabled,
    accountSetupTasksEnabled,
    basicReimbursementsEntitled,
    payableBillAccessEntitled,
    travelTripsEntitled,
    loading,
    error,
    devOverrides,
  ]);
};

export const [useMockableProductConfig, MockProductConfigProvider] =
  createMockableHook(useProductConfig);
