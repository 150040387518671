import { atomsWithQuery, selectQueryAtom } from "@/features/Jotai";
import type { FspConfig } from "@/features/ProAccess/data/__generated__/queries.generated";

export const fspConfigQueryAtom = atomsWithQuery<FspConfig>();

export const selectFspConfigQueryAtom = selectQueryAtom(
  fspConfigQueryAtom,
  ({ data }) => ({
    isFsp: data?.isFsp ?? false,
    fspDetails: data?.getFSPDetails ?? null,
  }),
);
