import type { TokenExchangeResponse } from "@/features/Authentication/sharedTypes";

export const messageToNotifyRefreshLock = () =>
  ({
    message: "notify_refresh_lock",
  }) as const;

export const messageToNotifyReleaseRefreshLock = () =>
  ({
    message: "notify_release_of_refresh_lock",
  }) as const;

export const messageForOtherFramesToUpdateToken = (
  tokenResponse: TokenExchangeResponse,
  requestInitiationTime: number,
) =>
  ({
    message: "notify_of_token_update",
    tokenResponse,
    requestInitiationTime,
  }) as const;

export const messageForOtherFramesToDeleteTheirTokens = () =>
  ({
    message: "notify_of_token_delete",
  }) as const;

export const messageForOtherFramesToForceLogout = () =>
  ({
    message: "force_logout",
  }) as const;

export type PossibleMessages =
  | ReturnType<typeof messageToNotifyRefreshLock>
  | ReturnType<typeof messageToNotifyReleaseRefreshLock>
  | ReturnType<typeof messageForOtherFramesToUpdateToken>
  | ReturnType<typeof messageForOtherFramesToDeleteTheirTokens>
  | ReturnType<typeof messageForOtherFramesToForceLogout>;
