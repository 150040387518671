import { useAtomValue } from "jotai";
import { AccountStatus } from "@/data/sharedTypes";
import { usePermissions } from "@/domains/App";
import { userPropertiesAtom } from "@/features/Authentication/atoms/userIdentityAtoms";
import { selectFspConfigQueryAtom } from "@/features/ProAccess/atoms/fspConfigQueryAtoms";
import { createMockableHook } from "@/helpers/createMockableHook";
import { useMockableProductConfig } from "@/hooks/useProductConfig";

const useFspConfig = () => {
  const permissions = usePermissions();
  const userProperties = useAtomValue(userPropertiesAtom);
  const isAccountActive =
    userProperties?.accountStatus === AccountStatus.ACTIVE;
  const { isFsp, fspDetails } = useAtomValue(selectFspConfigQueryAtom);
  const {
    config: { accountingAccessEnabled },
  } = useMockableProductConfig();

  return {
    isFsp,
    fspDetails,
    // Clients should only be able to update partner connections if they have permission, they're not an FSP account and their account is active
    clientCanUpdatePartnerConnections:
      accountingAccessEnabled &&
      permissions["fsp.client.connections.update"] &&
      !isFsp &&
      isAccountActive,
    fspCanUpdateClientConnections: permissions["fsp.connections.update"],
    fspCanUpdateTeam: permissions["fsp.team.update"],
  };
};

export const [useMockableFspConfig, MockFspConfigProvider] =
  createMockableHook(useFspConfig);
