import type { Medium } from "@/__generated__/globalTypes";
import type { MfaFlow, MfaOrigin } from "@/features/2FA/sharedTypes";

export enum TwoFactorAuthAnalyticsEvents {
  SetupMFA = "2fa.setup_mfa.state",
  SetupMFAStarted = "2fa.setup_mfa.started",
  SetupMFACompleted = "2fa.setup_mfa.completed",
  SetupMFAError = "2fa.setup_mfa.error",

  SetupMFAFlowTOTPStarted = "2fa.setup_mfa.flow.TOTP.started",
  SetupMFAFlowTOTPCompleted = "2fa.setup_mfa.flow.TOTP.completed",
  SetupMFAFlowSMSStarted = "2fa.setup_mfa.flow.SMS.started",
  SetupMFAFlowSMSCompleted = "2fa.setup_mfa.flow.SMS.completed",

  SetupMFAActionViewTOTPSecret = "2fa.setup_mfa.action.view_TOTP_secret",
  SetupMFAActionHideTOTPSecret = "2fa.setup_mfa.action.hide_TOTP_secret",
  SetupMFAActionDownloadFile = "2fa.setup_mfa.action.download_file",
  SetupMFAActionVerifySMS = "2fa.setup_mfa.action.verify_sms",
  SetupMFAActionVerifyWhatsApp = "2fa.setup_mfa.action.verify_whatsapp",
  SetupMFAActionResendSMS = "2fa.setup_mfa.action.resend_sms",
  SetupMFAActionResendWhatsApp = "2fa.setup_mfa.action.resend_whatsapp",
  SetupMFAActionGoToAuthAppFlow = "2fa.setup_mfa.action.go_to_auth_app_flow",
  SetupMFAActionCommsWhatsAppPreference = "2fa.setup_mfa.action.comms.whatsapp_preference",
}

type SetupMFACommonPayload = {
  origin: MfaOrigin;
};

type SetupMFAPayload = SetupMFACommonPayload & { step: string };
type SetupMFAStartedPayload = SetupMFACommonPayload & {
  step: string;
  totpFlow: MfaFlow | undefined;
  smsFlow: MfaFlow | undefined;
  customFlow: MfaFlow | undefined;
  currentFlow: MfaFlow | undefined;
};
type SetupMFAErrorPayload = SetupMFACommonPayload & {
  step: string;
  error: string;
};

type SetupMFAFlowSMSCompletedPayload = SetupMFACommonPayload & {
  medium: Medium;
};

export type TwoFactorAuthEventSchemaMap = {
  [TwoFactorAuthAnalyticsEvents.SetupMFA]: SetupMFAPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAStarted]: SetupMFAStartedPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFACompleted]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAError]: SetupMFAErrorPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAFlowTOTPStarted]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAFlowTOTPCompleted]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAFlowSMSStarted]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAFlowSMSCompleted]: SetupMFAFlowSMSCompletedPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionViewTOTPSecret]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionHideTOTPSecret]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionDownloadFile]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionVerifySMS]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionVerifyWhatsApp]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionResendSMS]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionResendWhatsApp]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionGoToAuthAppFlow]: SetupMFACommonPayload;
  [TwoFactorAuthAnalyticsEvents.SetupMFAActionCommsWhatsAppPreference]: SetupMFACommonPayload;
};
