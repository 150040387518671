import * as React from "react";
import { FullScreenPageContainer } from "./FullScreenPageContainer";
import { DefaultError } from "@/components/Error/DefaultError";
import type { DefaultErrorProps } from "@/components/Error/DefaultError";

export const FullScreenErrorPage: React.VFC<DefaultErrorProps> = (props) => (
  <FullScreenPageContainer>
    <DefaultError {...props} />
  </FullScreenPageContainer>
);
